import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi,  GetUrlApiWhatsapp, GetUrlApiWhatsappBlinamic } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import { ArqueoDetalleMedio } from './ArqueoDetalleMedio';
import { ArqueoDetallSalida } from './ArqueoDetallSalida';
import swal from 'sweetalert';
import UserProfile from './Globales/PerfilUsuario';
import { GetUserCookie} from './Globales/Utilidades';
import { TecladoNumerico } from './TecladoNumerico';
import { ArqueoModificarMediosDos } from './ArqueoModificarMediosDos';
import Popup from "reactjs-popup";
import CurrencyFormat from "react-currency-format";
import Swal from 'sweetalert2';
import $ from 'jquery';
import { ArqueoDetalleIngreso } from './ArqueoDetalleIngreso';

export class Arqueo extends Component {
    static displayName = Arqueo.name;
    IdGaveta = 0;
    IDcboCkicked = 0;
    LastOptionClicked = 0;
    MediosPorCierre = [];
    TipoUsuario = null;
    NumCaja = GetUserCookie().CajaID;
    CargaInicialTerminada = false;
    PerfilUsuario = {};
    UltMedioClicked = '';
    ObjGavetaSeleccionada = {};
    listaSalidas = [];
    listaIngresos= [];


    StateInicial = {
        cargando: true,
        gavetas: [],
        numCaja: document.cookie,//cambiar
        numGavetas: 0,
        efectivo: 0,
        cargandoEnvio: false,
        mostrarVoucher: false,
        urlVoucherCierreCaja: '',
        cargandoTransacciones: false,
        volverAlIncio: false,
        mediosPago: [],
        total: 0,
        diferencia: 0,//la diferencia,
        montoInicial: 0,
        cargandoCierre: false,
        mediosDePago: [],
        cargandoMedios: false,
        MediosVentas: [],
        redirijirEditar: false,
        preprocesar: false,
        IdGaveta: 0,
        isCajaHabilitada: false,
        idMedioSeleccionado: 0,
        mostrarDetallesMedio: false,
        mostrarDetallesTodosMedios: false,
        ordenes: [],
        totalSalidas: 0,
        totalReembolsos: 0,
        mostrarMensajeNoHabilitado: false,
        //listaSalidas: [],
        mediosArqueoCiego: [],
        isArqueoCiego: false,
        mostrarTeclado: false,
        movimientosDinero:[],
        MontosMediosNC:[]
    }



    constructor(props) {
        super(props);
        this.state = {
            activarSeleccionarGaveta: false,
            cargando: true,
            gavetas: [],
            numCaja: document.cookie,//cambiar
            numGavetas: 0,
            efectivo: 0,
            cargandoEnvio: false,
            mostrarVoucher:false,
            urlVoucherCierreCaja:'',
            cargandoTransacciones: true,
            volverAlIncio: false,
            mediosPago: [],
            total: 0,
            diferencia: 0,//la diferencia,
            montoInicial: 0,
            cargandoCierre: false,
            mediosDePago: [],
            cargandoMedios: false,
            MediosVentas: [],
            redirijirEditar: false,
            preprocesar: false,
            IdGaveta: 0,
            GavetaName: 0,
            isCajaHabilitada: false,
            idMedioSeleccionado: 0,
            mostrarDetallesMedio: false,
            mostrarDetallesTodosMedios: false,
            ordenes: [],
            totalSalidas: 0,
            totalReembolsos: 0,
            mostrarMensajeNoHabilitado: false,
            listaSalidas: [],
            mediosArqueoCiego:[],
            isArqueoCiego: false,
            mostrarTeclado: false,
            montoInicial2: 0,
            nomUserAbrioGaveta: '',
            isGavetaSeleccionada: false,
            mostrarDetallesSalida: false,
            mostrarDetallesIngresos:false,
            idAperturaSalida:0,
            totalIngresos:0,
            movimientosDinero:[],
            confirmoTerminar: false,
            mostrarResumenTotales: false,
            Ambiente: localStorage.getItem('ambiente'),
            MontosMediosNC:[]
        }
        this.AgregarNumero = this.AgregarNumero.bind(this);
        this.ClickBorrarTeclado = this.ClickBorrarTeclado.bind(this);
        this.EfectivoAgregado = this.EfectivoAgregado.bind(this);
        this.ValidarCierre = this.ValidarCierre.bind(this);
        this.setState = this.setState.bind(this);
        this.TraerUltAperturaCaja = this.TraerUltAperturaCaja.bind(this);
        this.CargarPendientes = this.CargarPendientes.bind(this);
        this.DesactivarTeclado = this.DesactivarTeclado.bind(this);
        this.PerfilUsuario = UserProfile.getUser;
        // this.TraerGavetas();
        const gavetaPreviamenteSeleccionada = props.GavetaID;
      
        this.ObjGavetaSeleccionada = props.gavetaSeleccionada;
    }


    async  TraerUltAperturaCaja() {
        this.setState({ cargando: true });
        var respuesta = await fetch(GetUrlApi() + '/api/AperturaCajas/' + this.NumCaja + '?gavetaId=' + this.ObjGavetaSeleccionada.id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.UltApertura = datos;
            
            this.TraerMontosNC(this.NumCaja);
            var url = GetUrlApi() + '/api/orden/GetOrdenCerrar?idGaveta=' + datos.gavetaID;
            
            var respuestaOrdenes = await fetch(url);

            if (respuestaOrdenes.ok) {
                var datosOrdenes = await respuestaOrdenes.json();
                var mediosPorOrdens = [];
                datosOrdenes.forEach((item) => {
                    item.mediosPorOrdens.forEach((value) => {
                        mediosPorOrdens.push(value);
                    });
                });
               // datosOrdenes.push({ total: datos.monto, });
                //mediosPorOrdens.push({ tbMedioID: 8, total: datos.monto, montoPropina: 0, totalReal: datos.monto, CierreCajaID: null });
              
                var totalSalidas = 0;
                datos.salidaDineros.filter(p => p.tipoMovimientoCajaID === 1).forEach((item) => {
                    totalSalidas += item.monto;
                });

                var totalReembolsos = 0;
                datos.salidaDineros.filter(p => p.tipoMovimientoCajaID === 2).forEach((item) => {
                    totalReembolsos += item.monto;
                });

                var totalIngresos = 0;
                datos.movimientosDinero.filter(p => p.tipoMovimiento === 3).forEach((item) => {
                    totalIngresos += item.monto;
                });
                
                await this.TraerMediosDePago();
                var estadoCaja = (datos.isHabilitada || !datos.isCerrada);
                this.setState({
                    ordenes: datosOrdenes
                    , isCajaHabilitada: estadoCaja
                    , cargando: false
                    , MediosVentas: mediosPorOrdens
                    , IdGaveta: datos.gavetaID
                    , mostrarDetallesMedio: false
                    , mostrarDetallesSalida: false
                    , mostrarDetallesIngresos:false
                    , totalSalidas: totalSalidas,
                    totalReembolsos: totalReembolsos,
                    //montoInicial: datos.montoInicial
                    montoInicial: 0,
                    montoInicial2: datos.montoInicial,
                    nomUserAbrioGaveta: datos.usuarios.nombre,
                    totalIngresos: totalIngresos,
                    movimientosDinero: datos.movimientosDinero
                });
            }
        }
        else {
            // alert('error en traer ordenes');
            this.setState({ isCajaHabilitada: false });
        }
    }


    componentDidMount() {
        // console.log('Usuario');
        // console.log(GetUserCookie());
        
        if (GetUserCookie().tipoUsuario.id !== 1 && GetUserCookie().tipoUsuario.id !== 6) {
            this.setState({ mostrarMensajeNoHabilitado: true });
        }
        this.TraerUltAperturaCaja().then(() => {
            this.SelectGavetaPendiente(this.props.GavetaID);
        });

        if (GetUserCookie().tipoUsuario.id === 1){
            this.setState({mostrarResumenTotales: true})
        }        
    }

    async CargarPendientes(gavetaIngresada) {
        if (isNaN(gavetaIngresada)) {
            await this.setState(this.StateInicial);
            this.TraerUltAperturaCaja();
            this.TraerGavetas();
        }
        else {
            this.setState({ cargando: true });
            var respuesta = await fetch(GetUrlApi() + '/api/AperturaCajas/GetAperturaCajaPendiente?idCaja=' + this.NumCaja + '&idGaveta=' + gavetaIngresada, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {
                var datos = await respuesta.json();
                var url = GetUrlApi() + '/api/orden/GetOrdenCerrarPendiente?idGaveta=' + gavetaIngresada + '&caja=' + datos.cajaID;
                var respuestaOrdenes = await fetch(url);


                if (respuestaOrdenes.ok) {

                  

                    var datosOrdenes = await respuestaOrdenes.json();
                    if (datosOrdenes.length>0) {
                        if (datosOrdenes[0].CierreCajaID !== null) {
                           
                            this.TraerMediosCierre(gavetaIngresada);
                        }
                    } 
                    
                    var mediosPorOrdens = [];
                    datosOrdenes.forEach((item) => {
                        item.mediosPorOrdens.forEach((value) => {
                            mediosPorOrdens.push(value);
                        });
                    });
                    var totalSalidasPendientes = 0;
                    //calculo salidas
                    datos.salidaDineros.filter(p=>p.tipoMovimientoCajaID===1).forEach((item) => {
                        totalSalidasPendientes += item.monto;
                    });

                    var totalRembolsosPendientes = 0;
                    //calculo reembolsos
                    datos.salidaDineros.filter(p => p.tipoMovimientoCajaID === 2).forEach((item) => {
                        totalRembolsosPendientes += item.monto;
                    });
                    
                    

                    this.setState({
                        ordenes: datosOrdenes
                        , isCajaHabilitada: datos.caja.isHabilitada
                        , cargando: false
                        , MediosVentas: mediosPorOrdens
                        , IdGaveta: datos.gavetaID
                        , mostrarDetallesMedio: false
                        , mostrarDetallesSalida: false
                        , mostrarDetallesIngresos:false
                        , isCajaHabilitada: true,
                        totalSalidas: totalSalidasPendientes,
                        totalReembolsos: totalRembolsosPendientes,
                        montoInicial2: datos.montoInicial
                    });
                }


            }
            else {
                this.setState({ isCajaHabilitada: false });
                //alert('error en traer ordenes');
            }
        }
    }

    async TraerMontosNC (cajaID){
        
    var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden/GetMedPagosCajaNC?CajaID=' + cajaID , {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {
                try {
                    var datos = await respuesta.json();
                    this.setState({ MontosMediosNC: datos });
                    
                } catch (err) {
                    console.log(err);
                }
            }
    }
    
    async TraerMediosCierre(idGaveta) {

        var respuesta = await fetch(GetUrlApi() + '/api/CierreCajas/GetCierrePendiente?idGaveta=' + idGaveta + '&aperturaID=' + this.props.ultimaAperturaCaja.id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            try {
                var datos = await respuesta.json();
                this.AsignarValorToTxt(datos.mediosPorCierre);
                this.setState({ mediosArqueoCiego: datos, isArqueoCiego: true });
                
                
            } catch (err) {

            }
        }
    }

    AsignarValorToTxt(listaValores) {//si se hizo un arqueo ciego esos valores apareceran en los text input de los medios de pago
        var total = 0;
        listaValores.forEach((item) => {
            document.getElementById(item.descMedio).value = item.monto;
            total += item.monto;
        });
        this.setState({ total: total });

    }

    async CargarDatos(cargarPendientes) {


        if (!this.CargaInicialTerminada) {
            this.setState({ isCajaHabilitada: true });
        }
        await this.TraerMediosDePago();

        await this.TraerUltAperturaCaja();
        await this.TraerGavetas();//listo
        if (cargarPendientes) {
            this.TraerMediosOrdenPendientes();
        }
        else {
            await this.TraerMediosOrden();
        }

        this.setState({ mostrarDetallesMedio: false, mostrarDetallesSalida:false, mostrarDetallesIngresos:false});
    }
    

    async TraerMediosOrden() {
        var traerPendientes = false;
        var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {

            var datos = await respuesta.json();
            datos = datos.filter(p => p.idGavetaFk === this.state.IdGaveta && p.idCierreCajaFk === null);

            this.setState({ MediosVentas: datos, cargandoTransacciones: false, cargando: false });
        }
        else {
            alert('error en traer medios orden');
        }
    }

    async TraerMediosOrdenPendientes() {
        var traerPendientes = false;
        var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {

            var datos = await respuesta.json();
            datos = datos.filter(p => p.idGavetaFk === this.state.IdGaveta && p.isArqueoPendiente === true);

            //agrega el monto inicial al total
            datos.push({ idMedioPagoFk: 1, total: this.state.montoInicial, idCierreCajaFk: null });

            this.setState({ MediosVentas: datos, cargandoTransacciones: false, isCajaHabilitada: true, cargando: false });
        }
        else {
            alert('error medios pendientes');
        }
    }


    ActualizarMontoAcumulado() {
        var elemContar = document.getElementsByName('acumulado');
        var totalAcumulado = 0;
        elemContar.forEach((item) => {
            try {
                if (item.value) { }
                    //totalAcumulado += Number(item.value);
            } catch (err) {

            }
        });

        var totalSalidas = 0;

        var elemContar = document.getElementsByName('salida');
        elemContar.forEach((item) => {
            try {
                if (item.value)
                    totalSalidas -= Number(item.value);
            } catch (err) {

            }
        });
        //totalAcumulado = totalAcumulado + totalSalidas;

        //poner salidas en verde
        //var restoSalida = this.state.totalSalidas - Number(document.getElementById('arqueoSalida').value);
        //if (restoSalida <= 0) {
        //    try {
        //        document.getElementById('pSalidas').style.color = 'green';
        //    } catch (err) {

        //    }
        //}
        //else {
        //    try {
        //        document.getElementById('pSalidas').style.color = 'red';
        //    } catch (err) {

        //    }
        //}

        var restoReembolso = this.state.totalReembolsos - Number(document.getElementById('arqueoReembolso').value);
        if (restoReembolso <= 0) {
            try {
                document.getElementById('pReembolso').style.color = 'green';
            } catch (err) {

            }
        }
        else {
            try {
                document.getElementById('pReembolso').style.color = 'red';
            } catch (err) {

            }
        }
        this.setState({ total: totalAcumulado });
    }

    async TraerMediosDePago() {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/TbMedio');
            if (!categorias.ok) {
                throw 'problema al traer los medios de pago';
            }
            var datos = await categorias.json();

            this.setState({ mediosDePago: datos, cargandoMedios: false });
        } catch (err) {
            console.log(err);
        }
    }


    async TraerGavetas() {
        try {
            const numCaja = GetUserCookie().CajaID;
            const userId = GetUserCookie().id;
            const categorias = await fetch(GetUrlApi() + '/api/Gavetas/GetGavetasPendientes?cajaID=' + this.NumCaja + '&userID=' + userId);
            if (!categorias.ok)
                throw { mensaje: 'problema al traer las gavetas' };

            var datos = await categorias.json();
            if (GetUserCookie().tipoUsuario.id !== 1) {//si no es admin solo muestra las gavetas que abrio el mismo usuario
                datos = datos.filter(p => p.usuarios.id === GetUserCookie().id);
            }

            await this.setState({ gavetas: datos });
            if (datos.length > 0) {
                const tipoUsuario = GetUserCookie().tipoUsuario.id;
                
                this.setState({ activarSeleccionarGaveta:true });
            }
        } catch (err) {
            console.log(err.mensaje);
        }
    }

    async TraerMediosPago() {
        try {
            const medios = await fetch(GetUrlApi() + '/api/tbmedios');
            if (!medios.ok)
                throw { mensaje: 'problema al traer los medios de pagos' };

            var datos = await medios.json();
            await this.setState({ mediosPago: datos[0] });
        } catch (err) {
            console.log(err.mensaje);
        }
    }

    CerrarPopup() {
        try {
            var modales = document.getElementsByclassName("popup-content ");
            if (modales.length > 0)
                modales[modales.length - 1].style.display = "none";
        } catch (err) {
            console.log('ex en cierre de modal');
        }
    }

    AgregarNumero(num, idElem) {
        document.getElementById(idElem).value = document.getElementById(idElem).value + num;
        this.ActualizarMontoAcumulado();
    }

    ClickBorrarTeclado(idElem) {
        try {
            var dato = document.getElementById(idElem).value;
            document.getElementById(idElem).value = dato.slice(0, -1);
            this.ActualizarMontoAcumulado();
        } catch (err) {

        }
    }
    //-------CLICKS

    ClickInputMedioPago(nombreMedio) {
        this.Efectivo = Number(document.getElementById(nombreMedio).value);
        document.getElementById(nombreMedio).value = '';
    }




    async ClickTerminar() {
        //this.CerrarPopup();
        await this.setState({ cargandoCierre: true });
        document.getElementById('btnTerminar').disabled = true;
        if (GetUserCookie().Sucursal.configPOS.validaEtiquetasRfidPendientes == true || GetUserCookie().Sucursal.configPOS.validaInventariosPendientes == true) {
            var resultado = {};
            var respuesta = await fetch(GetUrlApi() + '/api/CierreCajas/ValidaPendientes?SucursalID=' + GetUserCookie().Sucursal.sucursalIDBlinamic, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json())
                .then(res => {
                    resultado = res;

                })
                .catch(function () {
                    //alert("Can't connect to backend try latter");
                });
            //var data = respuesta.json();
            var Alertar = 0;

            var mensaje = "";
            
            if (GetUserCookie().Sucursal.configPOS.validaEtiquetasRfidPendientes) {
                if (resultado.cantidadEtiquetasRfidPendientes > 0) {
                    Alertar++;

                    swal({
                        title: "Tiene Etiquetas(" + resultado.cantidadEtiquetasRfidPendientes + ") Rfid Pendientes de Impresion. Realice esta acción antes de continuar.",
                        //text: "Se cambiara el medio de pago actual",
                        icon: "warning",
                        //buttons: true,
                        dangerMode: true,
                    });
                }
            }

            if (GetUserCookie().Sucursal.configPOS.validaInventariosPendientes) {

                if (resultado.cantidadInventariosPendientes > 0) {
                    Alertar++;

                    swal({
                        title: "Tiene Inventarios (" + resultado.cantidadInventariosPendientes + ") Pendientes de realizar. Realice esta acción antes de continuar.",
                        //text: "Se cambiara el medio de pago actual",
                        icon: "warning",
                        //buttons: true,
                        dangerMode: true,
                    });
                }
            }


            if (Alertar == 0) {

                if (this.GetDiferencia() > 0 || this.GetDiferencia() < 0) {
                    swal('Los montos ingresados no cuadran, por favor ingrese clave de administrador').then(() => { 
                        this.setState({ mostrarTeclado: true }) 
                        document.getElementById('btnTerminar').disabled = false;
                        this.setState({ cargandoCierre: false });
                    });
                }
                else {
                    this.CrearCierre();
                }
            } else {
                return;
            }
        } else {
            if (this.GetDiferencia() > 0 || this.GetDiferencia() < 0) {
                swal('Los montos ingresados no cuadran, por favor ingrese clave de administrador').then(() => { 
                    this.setState({ mostrarTeclado: true }) 
                    document.getElementById('btnTerminar').disabled = false;
                    this.setState({ cargandoCierre: false });
                });
            }
            else {
                this.CrearCierre();
            }
        }


    }

    async ValidarCierre(){
        if (!this.state.preprocesar)
            this.setState({ preprocesar: true, confirmoTerminar: true, mostrarResumenTotales: true });
        else
            this.setState({ preprocesar: false });

        if(GetUserCookie().tipoUsuario.id === 1){
            this.CrearCierre();
        }
    }

    async ClickConfirmarCierre(){
        await this.setState({ cargandoCierre: true });
        document.getElementById('btnConfirmarTerminar').disabled = true;
        this.CrearCierre();
    }

    async CrearCierre() {
        const gavetaSeleccionada = this.state.IdGaveta;
        await this.setState({ cargandoCierre: true });
        //document.getElementById('btnTerminar').disabled = true;
        const comentario = document.getElementById('txtComentario').value;
        this.setState({ cargandoEnvio: true });
        var data = {};



        data.TotCargo = this.state.total;
        data.Reembolsos = 0;
        data.CatTotal = 0;
        data.CajaID = this.NumCaja;
        data.GavetaID = gavetaSeleccionada;
        data.Comentario = comentario;
        data.diferencia = this.GetDiferencia();
        data.TipoArqueoID = 1;
        data.montoInicial = this.state.montoInicial;
        data.ComprobanteDeDeposito = document.getElementById('txtComprobante').value;
        var mediosCierre = document.getElementsByName('acumulado');



        mediosCierre.forEach((item, index) => {
            var monto = item.value;
            monto = monto.replace("$", "");
            monto = monto.replaceAll(",", "");
            if (monto.length === 0) {
                monto = 0;
            } else if (monto === "") {
                monto = "0";
            }
            var newObj = {};
            newObj.id = 0;
            newObj.TbMedioID = Number(item.getAttribute('idmedio'));
            newObj.monto = Number(monto);
            newObj.CierreCajaID = 0;



            newObj.descMedio = item.id;
            this.MediosPorCierre.push(newObj);
        });
        data.MediosPorCierre = this.MediosPorCierre;
        var RecaudacionSalidas = this.state.totalSalidas;
        if (RecaudacionSalidas === "") {
            data.RecaudacionSalida = Number("0");
        } else {
            data.RecaudacionSalida = Number(RecaudacionSalidas);
        }

        var RecaudacionIngresos = this.state.totalIngresos;

        if (RecaudacionIngresos === "") {
            data.RecaudacionIngresos = Number("0");
        } else {
            data.RecaudacionIngresos = Number(RecaudacionIngresos);
        }



        var RecaudacionReembolsos = document.getElementById('arqueoReembolso').value;
        RecaudacionReembolsos = RecaudacionReembolsos.replace("$", "");
        RecaudacionReembolsos = RecaudacionReembolsos.replaceAll(",", "");
        if (RecaudacionReembolsos === "") {
            data.RecaudacionReembolso = Number("0");
        } else {
            data.RecaudacionReembolso = Number(RecaudacionReembolsos);
        }



        // console.log(data);
        var respuesta = await fetch(GetUrlApi() + '/api/CierreCajas', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            // document.getElementById('btnTerminar').disabled = false;
            const data = await respuesta.json();
            const idImprimir = data.id;
            const envioWhatsapp = localStorage.getItem('envioArqueoWhatsapp');

            //se agrega aquí modulo de mensaje para poder avisar cuando se realiza un cierre con diferencias.

            if (data.cierreCaja.diferencia != 0) {
                //var CajaID = this.NumCaja;//cajaid
                var GavetaID = gavetaSeleccionada;//gavetaid
                var SucursalID = GetUserCookie().SucursalID;//sucursalid
                var UsuariosID = GetUserCookie().id; // usuarioid
                var respuesta = await fetch(GetUrlApi() + '/api/Mensajeria/GetEnviarMensaje?ModuloPosID=2&SucursalPosID=' + SucursalID + '&UsuarioPosID=' + UsuariosID + '&GavetaID=' + GavetaID + '&CajaID=' + this.NumCaja + '&ArqueoCaja=0&OrdenId=0', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (respuesta.ok) {
                }
                else {
                    //alert('error');
                }
            }

            if (envioWhatsapp === "true") {


                //var CajaID = data.CajaID//cajaid
                //var GavetaID = UserProfile.getUser().idGaveta;//gavetaid
                //var SucursalID = GetUserCookie().SucursalID;//sucursalid
                //var UsuariosID = UserProfile.getUser().id; // usuarioid

                //var respuesta = await fetch(GetUrlApi() + '/api/Mensajeria/GetEnviarMensajeCierreBoleta?ModuloPosID=6&SucursalPosID=' + SucursalID + '&UsuarioPosID=' + UsuariosID + '&GavetaID=' + GavetaID + '&CajaID=' + CajaID + '&ArqueoCaja=' + idImprimir + '&OrdenId=', {
                //    method: 'GET',
                //    headers: {
                //        'Content-Type': 'application/json',
                //    },
                //});
                //if (!respuesta.ok) {
                //    console.log(respuesta);
                //}





                //var myBody = {
                //    "modulo": parseInt(localStorage.getItem('arqueoModuloWhatsapp')),
                //    "tipomensaje": parseInt(localStorage.getItem('arqueoTipoMensajeWhatsapp')),
                //    "numeroTelefono": localStorage.getItem('arqueoNumeroCelularWhatsapp'),
                //    "urldescarga": GetUrlApi() + '/api/documentos/arqueoCaja?id=' + idImprimir,
                //    "url": GetUrlApiWhatsappBlinamic(),
                //};
                //const response = await fetch(GetUrlApiWhatsapp() + 'enviarwhatsapp', {
                //    method: 'post',
                //    headers: {
                //        'Content-Type': 'application/json',
                //    },
                //    body: JSON.stringify(myBody),

                var CajaID = 0;//cajaid
                var GavetaID = 0;//gavetaid
                var SucursalID = GetUserCookie().SucursalID;//sucursalid
                var UsuariosID = UserProfile.getUser().id; // usuarioid
                const response = await fetch(GetUrlApi() + '/api/Mensajeria/GetEnviarMensajeCierreBoleta?ModuloPosID=5&SucursalPosID=' + SucursalID + '&UsuarioPosID=' + UsuariosID + '&GavetaID=' + GavetaID + '&CajaID=' + CajaID + '&ArqueoCaja=' + idImprimir + '&OrdenId=0', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((data) => { return data.json() });

                if (!response) {
                    await Swal.fire({
                        title: "Error al enviar whatsapp",
                        icon: "error",
                        text: "Vamos a imprimir en papel",
                        dangerMode: true,
                    });
                    //this.imprimirCloud();
                    this.setState({ urlVoucherCierreCaja: idImprimir });
                    this.setState({ mostrarVoucher: true, cargandoEnvio: false });
                    return;
                }
            }


            const usaCustomCloudService = localStorage.getItem('usaCustomCloudService');


            if (usaCustomCloudService === "true") {
                const userData = GetUserCookie();
                const empresa = userData.Sucursal.direccionDeli.split(".")[0];
                const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
                const printer = "printer_" + userData.caja.descripcion;
                const param = "print://arqueo" + idImprimir;



                var myBody = {
                    "company_id": empresa,
                    "store_id": sucursal,
                    "order_id": param,
                    "printer_id": printer,
                    "additional_data": "",
                    "exec_path": "C:\\fbhposprint\\"
                };



                const response = await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(myBody),
                }).then((data) => { return data.json() });
                if (!response) {
                    await Swal.fire({
                        title: "Error al cierre de cajas",
                        icon: "error",
                        text: "Por favor contacte al administrador del sistema ",
                        dangerMode: true,
                    });
                    return;
                }



                this.setState({ urlVoucherCierreCaja: idImprimir });
                this.setState({ mostrarVoucher: true, cargandoEnvio: false });



            }
            else {
                try
                {
                    this.ClickImprimirDirecto(idImprimir);
                }
                catch (err)
                {
                        console.log(err)
                }

                this.setState({ urlVoucherCierreCaja: idImprimir });
                this.setState({ mostrarVoucher: true, cargandoEnvio: false });
            }
        }
        else {
            document.getElementById('btnTerminar').disabled = false;
            alert('error en terminar');
        }
    }

    async ClickImprimirDirecto(option) {
        this.setState({ cargando: true });
        const param = "print://arqueo" + option;
        var myBody = {};
        //myBody.command = "C:\\Program Files\\Sublime Text 3\\sublime_text.exe";
        //myBody.parameter = "";
        //myBody.path = "C:\\Program Files\\Sublime Text 3\\";
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = param;
        myBody.path = "C:\\fbhposprint\\";
        // console.log(myBody);
        //http://192.168.100.193:4741/fbhpos
        try {
            var respuesta = await fetch('http://localhost:4741/fbhpos', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            });
            this.setState({ cargando: false });

        } catch (err) { console.log(err);}

        //console.log(respuesta.json());
    }

    DesactivarModalVoucher() {

        this.setState({ volverAlIncio: true });
            
    }


    GetHtmlPopupVerVoucher() {

        let url = this.state.urlVoucherCierreCaja;

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleConsultaStock}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModalVoucher() }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.DivIframe}>
                    <iframe id="Example2"
                        style={this.Estilos.IFrame}
                        name="Example2"
                        title="Example2"
                        width="100%"
                        height="800px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src={GetUrlApi() + '/api/documentos/arqueoCaja?id='+url}>
                    </iframe>
                </div>

            </Popup>
        );
    }
    async ClickImprimir2(option) {

        const userData = GetUserCookie();
        const empresa = userData.Sucursal.direccionDeli.split(".")[0];
        const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
        const printer = "printer_" + userData.caja.descripcion;
        const param = "print://arqueo" + option

        var myBody = {
            "company_id": empresa,
            "store_id": sucursal,
            "order_id": param,
            "printer_id": printer,
            "additional_data": "",
            "exec_path": "C:\\fbhposprint\\"
        };

        const response = await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then((data) => { return data.json() });
        if (!response) {
            await Swal.fire({
                title: "Error al querer abrir gaveta",
                icon: "error",
                text: "Por favor contacte al administrador del sistema ",
                dangerMode: true,
            });
            return;
        }

    }

    MontoAgregado(nombreElem) {
        
        var totalIngresado = 0;
        document.getElementsByName('acumulado').forEach((item) => {
            var efectivoAgregado = item.value;
            efectivoAgregado = efectivoAgregado.replace("$", "");
            efectivoAgregado = efectivoAgregado.replaceAll(",", "");
            totalIngresado += Number( efectivoAgregado);
        });
        var contSalidas = 0;
        if (this.state.totalSalidas != 0) {
            var efectivoAgregado = this.state.totalSalidas;
            contSalidas += Number(efectivoAgregado);

        }
        if (nombreElem === 'arqueoReembolso') {
            var efectivoAgregado = document.getElementById('arqueoReembolso').value;
            efectivoAgregado = efectivoAgregado.replace("$", "");
            efectivoAgregado = efectivoAgregado.replaceAll(",", "");

            totalIngresado -= Number(efectivoAgregado);
            contSalidas += Number(efectivoAgregado);

        }
        
        this.setState({ total: totalIngresado });
     }

    EfectivoAgregado() {
        var total = this.state.total;
        var efectivoAgregado = document.getElementById('txtEfectivo').value;
        total += Number(efectivoAgregado);
        this.setState({ total: (total - this.Efectivo) });
        this.Efectivo = 0;
    }


    ClickCbo(id) {
        this.IDcboCkicked = id;
    }


    ClickEditar() {
        this.setState({ redirijirEditar: true });
    }

    ClickPreprocesar() {
        if (!this.state.preprocesar)
            this.setState({ preprocesar: true, mostrarResumenTotales: true });
        else
            this.setState({ preprocesar: false });

    }




    //-------FIN CLICKS


    //--------ESTILOS
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        BtnReprocesar: {
            'width': '89%',
            'margin-bottom': '20px'
        },
        BtnStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        }, SpanStyle2:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid',
                'font-size': '12px'
        },
        PStyle:
        {
         'margin-bottom': '0px'
        },
        MitadAncho: {
            'width': '50%'
        },
        TercioAncho: {
            'width': '30%'
        },
        DosTercioAncho: {
            'width': '70%',
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '16%' },
            col2: { 'width': '50%' }
        },
        Popup: {
            'width':'80%',
            'height':'80%',
            'overflowY':'scroll',
        },
        Contenedor: {
            'width': '95%',
            'background-color': 'white',
            'border-radius': '25px',
            'border': '2px solid #73AD21',
            'padding': '1.9%',
            'marginLeft': '2.5%'
        },
        BarraLateral: {
            'background-color': 'white',
            'height ': '100%',
            'width': '100%'
        },
        LetraRoja: {
            'color': 'red',
            'marginLeft': '-18%'

        },
        LetraVerde: {
            'color': 'green',
            'marginLeft': '-18%'

        },
        LetraAzul: {
            'color': 'blue',
            'marginLeft': '-18%'

        },
        AnchoCboGaveta: {
            'width': '15%'
        },
        BtnGroup:
        {
            'height': '100%',
            //'width': '6.1%',
            'borderColor': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px'
        },
        InputNum:
        {
            'width': '130px'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '45%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%'
        },
        MarginTr:
        {
            'margin-bottom': '10%',
            'marginRight': '10%',
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid',
            'cursor':'pointer'
        },
    }

    ClickOption(newval) {
        this.LastOptionClicked = newval;
    }

    async ClickTdMedioPago(id, descMedio) {
        await this.setState({ mostrarDetallesMedio: false, mostrarDetallesSalida:false ,mostrarDetallesIngresos:false });
        this.UltMedioClicked = descMedio;
        this.setState({ idMedioSeleccionado: id, mostrarDetallesMedio: true });
    }
    async ClickTdSalidaDinero() {
        await this.setState({ mostrarDetallesSalida: false,mostrarDetallesMedio:false,mostrarDetallesIngresos:false });
        var idUltApertura = this.UltApertura.id;
        this.setState({ idAperturaSalida: idUltApertura });

        await this.TraerSalidasApertura();

    }

    async ClickTdIngresoDinero() {
        await this.setState({ mostrarDetallesSalida: false,mostrarDetallesMedio:false,mostrarDetallesIngresos:false });
        var idUltApertura = this.UltApertura.id;
        console.log(idUltApertura);
        this.setState({ idAperturaSalida: idUltApertura });

        await this.TraerIngresosApertura();

    }

   async TraerSalidasApertura()
    {
        var respuesta = await fetch(GetUrlApi() + '/api/AperturaCajas/SalidasApertura?AperturaID=' + this.state.idAperturaSalida, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }, this).then(res => res.json()).then((resultado) => {
            this.listaSalidas = resultado;
            console.log(this.listaSalidas);

        });
        //console.log(respuesta.json());
       this.setState({ mostrarDetallesSalida: true });

    }
    async TraerIngresosApertura()
    {
        var respuesta = await fetch(GetUrlApi() + '/api/MovimientoDineros/GetMovimientosDinero?AperturaID=' + this.state.idAperturaSalida + '&TipoMovimiento=3',{
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }, this).then(res => res.json()).then((resultado) => {
            this.listaIngresos = resultado;
            console.log(this.listaIngresos);

        });
        //console.log(respuesta.json());
       this.setState({ mostrarDetallesIngresos: true });

    }
    ChangeNumGaveta() {
        if (document.getElementById('cboGavetas')) {
            const gavetaSelect = Number(document.getElementById('cboGavetas').value);
            this.CargarPendientes(gavetaSelect);
            
        }
    }

    SelectGavetaPendiente(idGavetaSelect) {
        const gavetaSelect = idGavetaSelect;
        const gavetaActual = this.state.IdGaveta;
        if (gavetaSelect === gavetaActual && this.state.isCajaHabilitada) {
            this.setState({ activarSeleccionarGaveta: false });

        } else {
            this.CargarPendientes(gavetaSelect);
            this.setState({ activarSeleccionarGaveta: false, isGavetaSeleccionada:true});
        }
    }

    DireccionEditar() {
        return '/TodosLosMedios?id=' + this.state.IdGaveta;
    }

    GetDiferencia() {
        
        var res = 0;
        this.state.ordenes.forEach((item) => {
            if (item.mediosPorOrdens) {
                item.mediosPorOrdens.forEach((item) => {
                    // se resta vuelto para considerar el efectivo real en caja
                    let vueltoexacto = 0;
                     
                    if (item.exactChange != undefined){
                        vueltoexacto = item.exactChange;
                        if(vueltoexacto < 0){
                            vueltoexacto= vueltoexacto*-1;
                        }
                    }
                    //res += item.totalReal - item.vuelto;
                    res += item.totalReal - vueltoexacto;
                });
            }
        });
        var montoNcs =0;
        this.state.MontosMediosNC.forEach((item) => {
            montoNcs += item.monto;
        });
        const montoInicial = this.state.montoInicial2;
        var salidasAcumuladas =0
        //if (document.getElementById('arqueoSalida')) {
        //    var salidaArqueo = document.getElementById('arqueoSalida').value;
        //    salidaArqueo = salidaArqueo.replace("$", "");
        //    salidaArqueo = salidaArqueo.replaceAll(",", "");
        //    salidasAcumuladas = Number(salidaArqueo);
        //}
        if (this.state.totalSalidas) {
           var salidaArqueo = this.state.totalSalidas;
           //var salidaArqueo = document.getElementById('arqueoSalida').value;
            salidasAcumuladas = Number(salidaArqueo);

        }
        var reembososAcumuladas = 0;
        if (document.getElementById('arqueoReembolso')) {
            var ReembolsoArqueo = document.getElementById('arqueoReembolso').value;
            ReembolsoArqueo = ReembolsoArqueo.replace("$", "");
            ReembolsoArqueo = ReembolsoArqueo.replaceAll(",", "");

            reembososAcumuladas = Number(ReembolsoArqueo);
        }
        var ingresos = this.state.totalIngresos;
        //res -= salidasAcumuladas;//*2;
        res -= reembososAcumuladas;
        //return (res  - this.state.total  - (this.state.totalSalidas - salidasAcumuladas) - (this.state.totalReembolsos - reembososAcumuladas) + montoInicial  ) * -1;//si total no debe incluir propina sacar GetTotalPropinas
        return (res  - this.state.total  - (this.state.totalSalidas) - (this.state.totalReembolsos - reembososAcumuladas) + montoInicial + ingresos +montoNcs ) * -1;//si total no debe incluir propina sacar GetTotalPropinas

        //return (res - this.state.total  + montoInicial) * -1;//si total no debe incluir propina sacar GetTotalPropinas

    }

    GetTotalPropinas() {
        var res = 0;
        this.state.MediosVentas.forEach((item) => {
            if (item.montoPropina)
                res += item.montoPropina;
        });
        return res;
    }

    GetRestoReembolso() {
        var arqueoReembolsoArqueo = document.getElementById('arqueoReembolso').value;
        arqueoReembolsoArqueo = arqueoReembolsoArqueo.replace("$", "");
        arqueoReembolsoArqueo = arqueoReembolsoArqueo.replaceAll(",", "");

        var resto = this.state.totalReembolsos - Number(arqueoReembolsoArqueo);
        if (resto > 0) {
            return (<p id="pReembolso" style={this.Estilos.LetraRoja}> {GetPrecioFormateado(resto)}</p>);
        } else {
            return (<p id="pReembolso" style={this.Estilos.LetraVerde}> {GetPrecioFormateado(resto)}</p>);
        }
    }

    GetRestoSalida() {
        var arqueoSalidaArqueo = document.getElementById('arqueoSalida').value;
        arqueoSalidaArqueo = arqueoSalidaArqueo.replace("$", "");
        arqueoSalidaArqueo = arqueoSalidaArqueo.replaceAll(",", "");
        var resto = this.state.totalSalidas - Number(arqueoSalidaArqueo);
        if (resto > 0) {
            return (<p id="pSalida" style={this.Estilos.LetraRoja}> {GetPrecioFormateado(resto)}</p>);
        } else {
            return (<p id="pSalida" style={this.Estilos.LetraVerde}> {GetPrecioFormateado(resto)}</p>);
        }

    }

    GetTotal() {
        var res = 0;
        var valores = document.getElementsByName('acumulado');
        valores.forEach((item) => {
            var efectivoAgregado = item.value;
            efectivoAgregado = efectivoAgregado.replace("$", "");
            efectivoAgregado = efectivoAgregado.replaceAll(",", "");
            if (Number(efectivoAgregado) > 0) {
                res += Number(efectivoAgregado);
            }
        });

        var salidasAcumuladas = 0
        //if (document.getElementById('arqueoSalida')) {

        //    var efectivoAgregadoSalidas = document.getElementById('arqueoSalida').value;
        //    efectivoAgregadoSalidas = efectivoAgregadoSalidas.replace("$", "");
        //    efectivoAgregadoSalidas = efectivoAgregadoSalidas.replaceAll(",", "");
        //    salidasAcumuladas = Number(efectivoAgregadoSalidas);
        //}
        if (this.state.totalSalidas>0) {
            var efectivoAgregadoSalidas = this.state.totalSalidas;
            //efectivoAgregadoSalidas = efectivoAgregadoSalidas.replace("$", "");
            //efectivoAgregadoSalidas = efectivoAgregadoSalidas.replaceAll(",", "");
            salidasAcumuladas = Number(efectivoAgregadoSalidas);
        }
        var reembososAcumuladas = 0;
        if (document.getElementById('arqueoReembolso')) {

            var efectivoAgregadoReembolsos = document.getElementById('arqueoReembolso').value;
            efectivoAgregadoReembolsos = efectivoAgregadoReembolsos.replace("$", "");
            efectivoAgregadoReembolsos = efectivoAgregadoReembolsos.replaceAll(",", "");

            reembososAcumuladas = Number(efectivoAgregadoReembolsos);
        }
        //res -= salidasAcumuladas;
        //res -= reembososAcumuladas;
        return res;
    }

    GetDiferenciaRedondeo() {
        var res = 0;
        this.state.ordenes.forEach((item) => {
            if (item.mediosPorOrdens) {
                item.mediosPorOrdens.forEach((item) => {
                    res += item.diferencia;
                });
            }
        });
        return res;
    }

    GetHtmlTodosMedio() {

        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.Popup}
                onClose={() => { this.setState({ mostrarDetallesTodosMedios: false }) }}
                closeOnDocumentClick>
                <center>
                    <ArqueoModificarMediosDos
                        gavetaId={this.state.IdGaveta}
                        gavetaName={this.ObjGavetaSeleccionada.descripcion}
                        CargarPendientes={this.CargarPendientes}
                    />
                </center>

            </Popup>
            );
    }

    GetHtmlPopupSeleccionarPendiente() {

        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.PopUpStyle}
                onClose={() => { this.setState({ mostrarDetallesTodosMedios: false }) }}
                closeOnDocumentClick>
                <center>
                    <table className="row" style={this.Estilos.FullAncho}>
                        <tr style={this.Estilos.MarginTr}>

                            {
                                this.state.gavetas.map((item) => {
                                    return (
                                        <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => { this.SelectGavetaPendiente(item.id) }}>
                                            <button className="btn btn-info" style={this.Estilos.BtnStyles}>
                                                <i className="fa fa-bookmark-o fa-2x"></i>
                                            </button>

                                            <p style={this.Estilos.PStyle}><span style={this.Estilos.SpanStyle}>{item.descripcion}</span></p>
                                            <p style={this.Estilos.PStyle}> <span style={this.Estilos.SpanStyle}>Entrada {new Date(item.fechaUltApertura).toLocaleDateString()}</span> </p>
                                            <p style={this.Estilos.PStyle}> <span style={this.Estilos.SpanStyle}>{new Date(item.fechaUltApertura).toLocaleTimeString()}</span></p>
                                            <p style={this.Estilos.PStyle}> <span style={this.Estilos.SpanStyle2}>{item.usuarios.nombre} {item.usuarios.apellido}</span></p>

                                        </td>
                                    );
                                })
                            }
                        </tr>
                        <tr >

                            <td rowspan="3" colSpan="2">
                                <div></div>
                            </td>
                        </tr>

                    </table>
                </center>

            </Popup>
            );
    }

    DesactivarTeclado() {
        this.setState({ mostrarTeclado: false });
    }

    render() {
        return (
            <div style={this.Estilos.Contenedor} >
                {(this.state.mostrarVoucher) ? (
                    this.GetHtmlPopupVerVoucher()

                ) : (null)}
                {
                    (this.state.mostrarDetallesTodosMedios) ? (this.GetHtmlTodosMedio()) : (null)
                }
                {
                    (this.state.mostrarTeclado) ? (<TecladoNumerico IsAutorizacion={true} ValidarCierre={this.ValidarCierre} CerrarModalMaster={this.DesactivarTeclado}/>) : (null)
                }
                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                {
                    (this.state.redirijirEditar) ? (<Redirect to={this.DireccionEditar()} />) : (null)
                }
                {
                    (this.state.activarSeleccionarGaveta) ? (this.GetHtmlPopupSeleccionarPendiente()) : (null)
                }
                
                
                <div>

                   
                    {
                        (this.state.cargando) ? (
                            <div style={this.Estilos.FondoDefault}>
                                <center>
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    <p> Cargando ventas...</p>
                                </center>
                            </div>
                        ) : (null)
                    }
                </div>
                {
                    <div className="row">
                        <div style={this.Estilos.TercioAncho}>
                            {
                                
                                    <div>
                                        <div>
                                            {
                                                (GetUserCookie().tipoUsuario.id === 1) ? (<button id="btnEditarMedios" onClick={() => { this.ClickPreprocesar() }} style={this.Estilos.BtnReprocesar} className="btn btn-dark">Preprocesar</button>
                                                ) : (null)
                                            }  
                                            <table className="table">
                                                <thead></thead>
                                                <tbody>
                                                    {
                                                        this.state.mediosDePago.map((item, i) => {
                                                            var total = 0;
                                                            
                                                            if (item.desmed=='Efectivo') {
                                                                total += this.state.montoInicial2;
                                                                total = total - this.state.totalSalidas;
                                                            }
                                                            var valorIngresado = document.getElementById(item.desmed);
                                                            var mediosSeleccionados = this.state.MediosVentas;
                                                            var mediosNC = this.state.MontosMediosNC;
                                                            if (mediosSeleccionados.length > 0) {
                                                                mediosSeleccionados.filter(p => p.tbMedioID === item.id).forEach((value) => {
                                                                    // se resta el vuelto para el efectivo real en caja
                                                                    let vueltoexacto = 0;
                                                                    
                                                                    if (value.exactChange != undefined){
                                                                        vueltoexacto = value.exactChange;
                                                                        if(vueltoexacto < 0){
                                                                            vueltoexacto= vueltoexacto*-1;
                                                                        }
                                                                    }
                                                                    //total += value.totalReal - value.vuelto;
                                                                    total += value.totalReal - vueltoexacto
                                                                });
                                                            }
                                                            
                                                            if (mediosNC.length>0){
                                                                mediosNC.filter(p=> p.tbMedioId  === item.id).forEach((value)=>{
                                                                    total += value.monto;
                                                                }); 
                                                            }
                                                            //tipomovimiento == 3 --> Entradas de dinero
                                                            if (this.state.movimientosDinero.length>0){
                                                                    this.state.movimientosDinero.filter(p => p.tipoMovimiento === 3).forEach((value) => {
                                                                        if (value.idMedioPago === item.id){
                                                                            total += value.monto;
                                                                        }
                                                                    });
                                                             }
                                                            if (valorIngresado) {
                                                                var ingressvalue = valorIngresado.value;
                                                                ingressvalue = ingressvalue.replace("$", "");
                                                                ingressvalue = ingressvalue.replaceAll(",", "");
                                                                valorIngresado = Number(ingressvalue);
                                                            }
                                                            else {
                                                                valorIngresado = 0;
                                                            }

                                                            return (
                                                                <tr key={'medioDePago' + i} >
                                                                    <td>
                                                                        {
                                                                            (this.state.Ambiente !== 'MAISA') ? (<p style={{ 'cursor': 'pointer' }} onClick={() => { this.ClickTdMedioPago(item.id, item.desmed) }}>{item.desmed}</p>) : (<p style={{ 'cursor': 'pointer' }} >{item.desmed}</p>)
                                                                        }
                                                                    </td>
                                                                    <td>
     
                                                                                    <CurrencyFormat
                                                                                        name="acumulado"
                                                                                        id={item.desmed}
                                                                                        placeholder="$0"
                                                                                        className="form-control"
                                                                                        thousandSeparator={true}
                                                                                        prefix={"$"}
                                                                                        decimalScale={0}
                                                                                        fixedDecimalScale={true}
                                                                                        style={this.Estilos.InputNum}
                                                                                        //onClick={() => { this.ClickInputMedioPago(item.desmed) }}
                                                                                        onChange={() => { this.MontoAgregado(item.desmed) }}
                                                                                        idmedio={item.id}
                                                                                        //value={this.state.total}
                                                                                    />
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            (this.state.preprocesar && (total - valorIngresado) === 0) ? (<p style={this.Estilos.LetraVerde}> {GetPrecioFormateado((total - valorIngresado) * -1)} </p>) : (null)
                                                                        }

                                                                        {
                                                                            (this.state.Ambiente !== 'MAISA' && this.state.preprocesar && (total - valorIngresado) !== 0) ? (<p style={this.Estilos.LetraRoja}> {GetPrecioFormateado((total - valorIngresado) * -1)} </p>) : (null)
                                                                        }

                                                                        {
                                                                            (this.state.Ambiente === 'MAISA' && this.state.preprocesar && (total - valorIngresado) !== 0) ? (<p style={this.Estilos.LetraRoja}> {GetPrecioFormateado((total - valorIngresado) * -1)}  <div style={this.Estilos.LetraAzul}> {GetPrecioFormateado((total))}</div></p>) : (null)
                                                                        }

                                                                        {
                                                                            (this.state.Ambiente === 'MAISA' && !this.state.preprocesar && (total - valorIngresado) !== 0) ? (<p style={this.Estilos.LetraRoja}> {GetPrecioFormateado((total - valorIngresado) * -1)}  <div style={this.Estilos.LetraAzul}> {GetPrecioFormateado((total))}</div></p>) : (null)
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    <tr>
                                                        <td>
                                                        <p style={{ 'cursor': 'pointer' }} onClick={() => { this.ClickTdSalidaDinero() }}>Salidas de dinero: </p>
                                                        </td>
                                                        <td>


                                                        <CurrencyFormat
                                                            hidden='hidden'
                                                            idmedio={0}
                                                            name="salida"
                                                            id="arqueoSalida"
                                                            placeholder="$0"
                                                            className="form-control"
                                                            onChange={() => { this.MontoAgregado('arqueoSalida') }}
                                                            thousandSeparator={true}
                                                            prefix={"$"}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                            value={this.state.totalSalidas}
                                                            //onValueChange={(values) => {
                                                            //    const { value } = values;
                                                            //    dispatch(setAmount(value));
                                                            //}}
                                                        />

                                                        {
                                                            GetPrecioFormateado(this.state.totalSalidas)
                                                            //(this.state.preprocesar) ? (this.GetRestoSalida()) : (null)
                                                        }
                                                        </td>
                                                        <td>

                                                        {
                                                            //GetPrecioFormateado(this.state.totalSalidas)
                                                                //(this.state.preprocesar) ? (this.GetRestoSalida()) : (null)
                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        <p style={{ 'cursor': 'pointer' }} onClick={() => { this.ClickTdIngresoDinero() }}>Ingresos de dinero: </p>
                                                        </td>
                                                        <td>


                                                        <CurrencyFormat
                                                            hidden='hidden'
                                                            idmedio={0}
                                                            name="salida"
                                                            id="arqueoIngreso"
                                                            placeholder="$0"
                                                            className="form-control"
                                                            onChange={() => { this.MontoAgregado('arqueoIngreso') }}
                                                            thousandSeparator={true}
                                                            prefix={"$"}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                            value={this.state.totalIngresos}
                                                            //onValueChange={(values) => {
                                                            //    const { value } = values;
                                                            //    dispatch(setAmount(value));
                                                            //}}
                                                        />

                                                        {
                                                            GetPrecioFormateado(this.state.totalIngresos)
                                                            //(this.state.preprocesar) ? (this.GetRestoSalida()) : (null)
                                                        }
                                                        </td>
                                                        <td>

                                                        
                                                        </td>
                                                    </tr>

                                                    <tr hidden='hidden'>
                                                        <td>
                                                            <p>Reembolsos: </p>
                                                        </td>
                                                        <td>
   

                                                        <CurrencyFormat
                                                            idmedio={0}
                                                            name="salida" id="arqueoReembolso"
                                                            placeholder="$0"
                                                            className="form-control"
                                                            onChange={() => { this.MontoAgregado('arqueoReembolso') }}
                                                            thousandSeparator={true}
                                                            prefix={"$"}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                        //value={amount}
                                                        //onValueChange={(values) => {
                                                        //    const { value } = values;
                                                        //    dispatch(setAmount(value));
                                                        //}}
                                                        />

                                                            
                                                        </td>
                                                        <td>

                                                            {
                                                                (this.state.preprocesar) ? (this.GetRestoReembolso()) : (null)
                                                            }
                                                        </td>
                                                    </tr>
                                                    {
                                                        (this.state.mostrarResumenTotales) ? (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <strong> <p>Monto Inicial: </p> </strong>
                                                                </td>
                                                                <td>
                                                                    <strong>{GetPrecioFormateado(this.state.montoInicial2)}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong> <p>Total recaudado: </p> </strong>
                                                                </td>
                                                                <td>
                                                                    <strong>{GetPrecioFormateado(this.GetTotal())}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong> <p>Diferencias: </p></strong>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (this.state.cargando) ? (
                                                                            <div style={this.Estilos.FondoDefault}>
                                                                                <center>
                                                                                    <div className="spinner-border text-dark" role="status">
                                                                                        <span className="sr-only"></span>
                                                                                    </div>
                                                                                    <p> Cargando...</p>
                                                                                </center>
                                                                            </div>
                                                                        ) : (<p>{GetPrecioFormateado(this.GetDiferencia())}</p>)
                                                                    }

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Total propina  </strong>
                                                                </td>
                                                                <td>{GetPrecioFormateado(this.GetTotalPropinas())}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Total diferencia por redondeo  </strong>
                                                                </td>
                                                                <td>{GetPrecioFormateado(this.GetDiferenciaRedondeo())}</td>
                                                            </tr>
                                                            <tr hidden='hidden'>
                                                                <td>
                                                                    <strong>Total fondo de caja  </strong>
                                                                </td>
                                                                <td>{GetPrecioFormateado(this.state.montoInicial2)}</td>
                                                            </tr>
                                                        </> ) : (null)                                                    
                                                    }                                                        
                                                </tbody>
                                            </table>

                                            <p>Comentario</p>
                                            <textarea id="txtComentario" className="form-control" />
                                            <br />

                                            <p>Comprobante de Depósito</p>
                                            <input type="text"  id="txtComprobante" className="form-control" />
                                            <br />
                                            {/* {
                                                (GetUserCookie().tipoUsuario.id === 6) ? (null) : (
                                                    <button id="btnEditarMedios" onClick={() => { this.ClickPreprocesar() }} style={this.Estilos.FullAncho} className="btn btn-dark">Preprocesar</button>
                                                )
                                            }*/}
                                            {
                                                (this.state.mostrarMensajeNoHabilitado) ? (null) : (
                                                    (this.state.confirmoTerminar) ? (
                                                        <button id="btnConfirmarTerminar" onClick={() => { this.ClickConfirmarCierre() }} style={this.Estilos.FullAncho} className="btn btn-primary">Confirmar Cierre</button>
                                                    ) : (
                                                        <button id="btnTerminar" onClick={() => { this.ClickTerminar() }} style={this.Estilos.FullAncho} className="btn btn-success">Terminar</button>
                                                    )
                                                    
                                                )
                                            }

                                        </div>
                                    </div>
                                
                            }

                            {
                                (this.state.cargandoCierre) ? (<p>Cargando...</p>) : (null)
                            }

                        </div>
                        {
                            (this.state.mostrarDetallesMedio) ? (
                                <div style={this.Estilos.DosTercioAncho}>
                                    <ArqueoDetalleMedio idMedio={this.state.idMedioSeleccionado}
                                        IdGaveta={this.state.IdGaveta}
                                        descMedio={this.UltMedioClicked}
                                        MediosPorOrden={this.state.MediosVentas}
                                        mediosDePago={this.state.mediosDePago}
                                        recargarPagina={this.TraerUltAperturaCaja}
                                        ordenes={this.state.ordenes}
                                        setStateArqueo={this.setState}
                                        CargarPendientes={this.CargarPendientes}
                                        ValorGavetaPendiente={this.ObjGavetaSeleccionada.id} 

                                    />
                                </div>
                            ) : (null)
                        }

                        {
                            (this.state.mostrarDetallesSalida) ? (
                                <div style={this.Estilos.DosTercioAncho}>
                                    <ArqueoDetallSalida idMedio={this.state.idMedioSeleccionado}
                                        IdGaveta={this.state.IdGaveta}
                                        descMedio={this.UltMedioClicked}
                                        MediosPorOrden={this.state.MediosVentas}
                                        mediosDePago={this.state.mediosDePago}
                                        recargarPagina={this.TraerUltAperturaCaja}
                                        ordenes={this.state.ordenes}
                                        setStateArqueo={this.setState}
                                        CargarPendientes={this.CargarPendientes}
                                        ValorGavetaPendiente={this.ObjGavetaSeleccionada.id}
                                        SalidasDetalle={this.listaSalidas}

                                    />
                                </div>
                            ) : (null)
                        }
                         {
                            (this.state.mostrarDetallesIngresos) ? (
                                <div style={this.Estilos.DosTercioAncho}>
                                    <ArqueoDetalleIngreso idMedio={this.state.idMedioSeleccionado}
                                        IdGaveta={this.state.IdGaveta}
                                        descMedio={this.UltMedioClicked}
                                        MediosPorOrden={this.state.MediosVentas}
                                        mediosDePago={this.state.mediosDePago}
                                        recargarPagina={this.TraerUltAperturaCaja}
                                        ordenes={this.state.ordenes}
                                        setStateArqueo={this.setState}
                                        CargarPendientes={this.CargarPendientes}
                                        ValorGavetaPendiente={this.ObjGavetaSeleccionada.id}
                                        IngresosDetalle={this.listaIngresos}

                                    />
                                </div>
                            ) : (null)
                        }
                    </div>


                }
            </div>
        );
    }


}
