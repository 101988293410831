import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import { Logout, GetUserCookie } from './Globales/Utilidades';
export class TrasladarOrden extends Component {
    static displayName = TrasladarOrden.name;
    Orden = {};
    url="/"
    constructor(props) {
        super(props);
        this.state = {
            Mesas: [],
            Ordenes: "",
            filtroMesas: false,
            usuarios: [],
            SelectUsuario: "",
            barSelected: false,
            mesaSelected: false,
            volverRevisar: false

        }
        this.Orden = props.Orden;
        this.TraerMesas();
    }

    componentDidMount() {
        
    }
    CerrarModal() {
        //sobreescrito por prop
        this.props.DesactivarModales();
    }
    async TraerMesas() {
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            const idSucursal = GetUserCookie().Sucursal.id;
            datos = datos.filter(p=>p.sucursalID===idSucursal);
            this.setState({ Mesas: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }
   
    async ClickParaLlevar() {

        //document.getElementById("ParaLlevarBtn").className = "btn btn-ligth";
        //document.getElementById("MesasBtn").className = "btn btn-dark";
        //document.getElementById("BarBtn").className = "btn btn-dark";

        var data = {};
        data.OrdenID = this.Orden.id;
        data.TipoVentaID = 2;
        data.MesaID = 1;
        data.Nombre = 'pepe';
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/CambiarTipoVenta', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            this.setState({ volverRevisar: true });
            Logout();
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

   
    Estilos = {
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        Popup: {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '37%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%',
            'height': '45%'
        },
        BtnStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        MarginTr:
        {
            'margin-bottom': '10%'
        },
        FullAncho: {
            'width': '50%',
            'margin-left': '-20%'
        },
        STextStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'font-size': '13px',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        }, BtnDStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '37%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%'
        },
        PopUpStyleUsuarios:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '37%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%',
            'height': '60%'
        }, BtnDanger:
        {
            'width': '50%',
            'margin-top': '3%',
            'background-color': '#f80303ad'
        },
        MarginDv:
        {
            'margin-bottom': '2%',
            'width': '23%',
            'align-content': 'center',
            'margin-left': '-10%'
        },
        MarginBtn:
        {
            'margin-top': '25%'
        },
        MarginFlow:
        {
            'overflow-y': 'scroll',
            'height': '60%'

        },
        PopUpStyleBarName:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '4px'
        },
        NombreBox:
        {
            'margin-bottom': '3%',
            'width': '70%'
        },
        BtnStyle:
        {
            'margin-right': '4%',
            'width': '30%'
        },
        ComboStyle:
        {
            'width': '65%',
            'text-overflow': 'ellipsis'
        }, BtnStyleM:
        {
            'margin-top': '2%',
            'width': '30%',
            'margin-right': '1%'
        },

    }


    ClickBar() {
        this.setState({ barSelected: true, mesaSelected: false });

        //document.getElementById("ParaLlevarBtn").className = "btn btn-dark";
        //document.getElementById("MesasBtn").className = "btn btn-dark";
        //document.getElementById("BarBtn").className = "btn btn-ligth";

    }
    ClickMesas() {
        this.setState({ barSelected: false, mesaSelected: true });
    }

    async ClickGuardarNombre() {
        var data = {};
        data.OrdenID = this.Orden.id;
        data.Nombre = document.getElementById('txtNomCliente').value;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/CambiarToBar', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            this.setState({ volverRevisar: true });
            Logout();
        }
    }

    async ClicSeleccionar() {
        var data = {};
        data.idOrden = this.Orden.ID;
        data.MesaID = this.Orden.mesaID;
        data.NewMesa = Number(document.getElementById('numMesa').value);
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/Trasladar', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            var urls = GetUserCookie().Sucursal.configPOS.urlRedireccionVentaM;
            this.url = urls;

            this.setState({ volverRevisar: true });

           
        }
    }

    render() {
        return (
            <div>
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.Popup}
                    onClose={() => { this.props.DesactivarModales() }}
                closeOnDocumentClick>
                    <center>
                        {
                            (this.state.volverRevisar) ? (<Redirect to={this.url}/>) : (null)
                        }
                    <h3>Seleccione Opción</h3>

                        <table className="row" style={this.Estilos.FullAncho}>
                            <tr style={this.Estilos.MarginTr}>
                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => { this.ClickMesas() }}>
                                    <button className="btn btn-info" onClick={() => { this.ClickMesas() }} style={this.Estilos.BtnStyles}>
                                        <i class="fa fa-cutlery fa-2x" aria-hidden="true"></i>
                                    </button>

                                    <span style={this.Estilos.SpanStyle}>Mesa</span>

                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => { this.ClickBar() }}>
                                    <button className="btn btn-info" onClick={() => { this.ClickBar() }} style={this.Estilos.BtnStyles}>
                                        <span>
                                            <i class="fa fa-glass fa-2x" aria-hidden="true"></i>
                                        </span>

                                    </button>

                                    <span style={this.Estilos.SpanStyle}>Bar</span>

                                </td>
                            </tr>
                            <tr >
                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => { this.ClickParaLlevar() }}>
                                    <button className="btn btn-info" onClick={() => { this.ClickParaLlevar() }} style={this.Estilos.BtnStyles}>
                                        <i class="fa fa-shopping-bag fa-2x" aria-hidden="true"></i>
                                    </button>

                                    <span style={this.Estilos.SpanStyle}>Para Llevar</span>

                                </td>
                            </tr>

                        </table>

                        <div>
                        <center>
                            <button onClick={() => { this.CerrarModal() }} style={this.Estilos.BtnDanger} className="btn btn-primary">  <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                            </center>
                        </div>
                </center>
                </Popup>
                {(this.state.mesaSelected) ? (
                    <Popup contentStyle={this.Estilos.PopUpStyleBarName}
                    position="top center"
                    modal
                    onClose={() => { this.props.DesactivarModales() }}
                    defaultOpen
                        closeOnDocumentClick>
                        <div>
                            <center>
                            <h3>Seleccione la mesa</h3>
                            <select className="form-control" id="numMesa" style={this.Estilos.ComboStyle}>
                                {
                                    this.state.Mesas.map((item, index) => {
                                        return (<option value={item.id}>Mesa {item.descipcion}</option>);
                                    })
                                }

                            </select>

                                <button style={this.Estilos.BtnStyleM} className="btn btn-danger" onClick={() => { this.props.DesactivarModales() }} data-toggle="tooltip" data-placement="right" title="Volver" >
                                    <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>

                                <button className="btn btn-success" style={this.Estilos.BtnStyleM} onClick={() => {
                                    this.ClicSeleccionar()
                                }}> <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> </button>
                           </center>
                        </div>

                </Popup>) : (null)}
                {

                    (this.state.barSelected) ? (
                <Popup contentStyle={this.Estilos.PopUpStyleBarName}
                    position="top center"
                    modal
                    onClose={() => { this.props.DesactivarModales() }}
                    defaultOpen
                            closeOnDocumentClick>
                        <center>
                            <div>
                                <h3>Ingrese el nombre del cliente</h3>
                                            <input type="text" id="txtNomCliente" className="form-control" style={this.Estilos.NombreBox} />

                                <button style={this.Estilos.BtnStyle} className="btn btn-danger" onClick={() => { this.props.DesactivarModales() }} data-toggle="tooltip" data-placement="right" title="Volver" >
                                                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
                                 </button>
                                <button className="btn btn-success" style={this.Estilos.BtnStyle} onClick={() => {
                                    this.ClickGuardarNombre()
                                }}> <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> </button>
                      
                            </div>
                        </center>

                </Popup>) : (null) 
                }

            </div>
        );
    }
}
