import React, { Component } from 'react';
import { Route } from 'react-router';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

export class PopupEditarPromocion extends Component {
    static displayName = PopupEditarPromocion.name;
    TipoPromoSeleccionado = {};
    CargaIncialLista = false;
    constructor(props) {
        super(props);
        this.state = {
            promociones: [],
            diasSemana: [],
            activarAgregarPromo: false,
            activarEditarPromo: false,
            Categorias: [],
            ProductosSeleccionados: [],
            tiposUsuarios: [],
            sucursales: [],
            todosSelected: false,
            tiposPromocion: [],
            usuarioSeleccionado: {},
            promoSeleccionada: {
                detallePrecioPromocion: {}
            },
            sucursalTodosSelected: false,
            diasTodosSelected: false,
            tipoPromocionSelected: {},
            volverAlIncio: false,
            HoraServidor: ''
        }
    }

    componentDidMount() {
        this.setState(this.props.state);
        //this.props.ClickEditarPromo(this.props.state.promoSeleccionada);
        this.CambioTipoPromo();

        var option_str = document.getElementById('cboDias');
        for (var i = 0; i < option_str.options.length; i++) {
            if (this.props.state.promoSeleccionada.diasPromocion.filter(p => p.diasSemanaID === Number( option_str.options[i].value)).length > 0) {
                option_str.options[i].selected = true;
            }
        }

        option_str = document.getElementById('cboSucursales');
        for (var i = 0; i < option_str.options.length; i++) {
            if (this.props.state.promoSeleccionada.sucursales.filter(p => p.sucursalID === Number(option_str.options[i].value)).length > 0) {
                option_str.options[i].selected = true;
            }
        }
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerCategoriasSucursales2().then(() => {
                this.SeleccionarCategoriaPromo();
            });
        }
        else {
            this.TraerCategoriasSucursales().then(() => {
                this.SeleccionarCategoriaPromo();
            });
        }
    }

    SeleccionarCategoriaPromo() {//selecciona el option correspondiente a la categoria que contenga la promocion
        var option_str = document.getElementById('cboCateg');
        for (var i = 0; i < option_str.options.length; i++) {
            const textoOption = option_str.options[i].innerText;
            console.log(this.state.promoSeleccionada);
            if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
                if (this.state.promoSeleccionada.productos.filter(p => p.tbProducto.grupo.descripcion === textoOption).length > 0) {
                    option_str.options[i].selected = true;
                    this.TraerProductosGrupo().then(() => {
                        this.SeleccionarProductosPromo();
                    });
                    return;
                }
            } else {
                if (this.state.promoSeleccionada.productos.filter(p => p.tbProducto.tbgrupo.desgrup === textoOption).length > 0) {
                    option_str.options[i].selected = true;
                    this.TraerProductos().then(() => {
                        this.SeleccionarProductosPromo();
                    });
                    return;
                }
            }
            
        }
    }

    SeleccionarProductosPromo() {
        var option_str = document.getElementById('cboProd');
        
        for (var i = 0; i < option_str.options.length; i++) {
            const textoOption = option_str.options[i].innerText;
            if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
                if (this.state.promoSeleccionada.productos.filter(p => p.tbProducto.desprod === textoOption).length > 0) {
                    option_str.options[i].selected = true;
                    this.TraerProductosGrupo();
                }
            } else {
                if (this.state.promoSeleccionada.productos.filter(p => p.tbProducto.desprod === textoOption).length > 0) {
                    option_str.options[i].selected = true;
                    this.TraerProductos();
                }
            }
            
        }
    }

    async TraerCategorias() {
        var SucursalID = GetUserCookie().SucursalID;
        const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);
        const datos = await categorias.json();
        this.setState({ Categorias: datos, cargandoCategorias: false, prodSeleccionado: false });
    }
    async TraerGrupos() {//llamada por configuracion
        var SucursalID = GetUserCookie().SucursalID;
        const grupos = await fetch(GetUrlApi() + '/api/Grupos?sucursalID=' + SucursalID);
        const datos = await grupos.json();
        this.setState({ Categorias: datos });
    }



    Estilos = {
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
            'margin-left': '3%'
        },
        Space:
        {
            'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '65%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px'
        }
    }


    async CambioSucursal() {
        var elem = document.getElementById('cboSucursales').value;
        if (elem === '0') {
            await this.setState({ sucursalTodosSelected: true });
        }
        else {

            await this.setState({ sucursalTodosSelected: false });
        }
        this.TraerCategoriasSucursales(elem);
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Sucursals', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ sucursales: datos });
        }
    }

    async TraerCategoriasSucursales() {
        var sucursales = Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos');
        var dataSucursales = [];
        sucursales.forEach((item) => {
            dataSucursales.push(item.value);
        });

        var respuesta = await fetch(GetUrlApi() + '/api/tbgrupoes/GetProductoPorGrupoIdMulti', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataSucursales),
        });
        if (respuesta.ok) {
            var data = await respuesta.json();
            this.setState({ Categorias: data });
        }
    }
    async TraerCategoriasSucursales2() {
        var sucursales = Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos');
        var dataSucursales = [];
        sucursales.forEach((item) => {
            dataSucursales.push(item.value);
        });

        var respuesta = await fetch(GetUrlApi() + '/api/tbgrupoes/GetProductoPorGrupoIdMulti2', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataSucursales),
        });
        if (respuesta.ok) {
            var data = await respuesta.json();
            this.setState({ Categorias: data });
        }
    }

    async ClickListoEditar() {
        var promocion = this.state.promoSeleccionada;
        promocion.Descripcion = document.getElementById('txtDesc').value;
        if (promocion.Descripcion === '') {
            swal('Tiene que ingresar una descripción');
            return;
        }
        var dias = Array.apply(null, document.getElementById('cboDias').options).filter(p => p.selected && p.label !== 'Todos');
        var dataDias = [];
        dias.forEach((item) => {
            dataDias.push({
                DiasSemanaID: item.value,
                PromocionID: 0
            });
        });
        promocion.diasPromocion = dataDias;
        if (promocion.diasPromocion.length === 0) {
            swal('Tiene que seleccionar almenos un día');
            return;
        }
        promocion.HoraDesde = document.getElementById('txtHoraDesde').value;
        promocion.HoraHasta = document.getElementById('txtHoraHasta').value;
        if (promocion.HoraDesde === '' || promocion.HoraHasta === '') {
            swal('La hora seleccionada no es válida');
            return;
        }
        promocion.DetallePrecioPromocion = {
            Descripcion: '',
            DescPorc: this.TipoPromoSeleccionado.isPorc,
            PrecioFijo: this.TipoPromoSeleccionado.isPrecioFijo && (this.TipoPromoSeleccionado.aumentaPrecio || this.TipoPromoSeleccionado.reducePrecio),
            MontoPorc: null,
            MontoPrecioFijo: null
        };
        if (promocion.DetallePrecioPromocion.DescPorc) {//SI ES PORCENTAJE

            promocion.DetallePrecioPromocion.MontoPorc = document.getElementById('txtMonto').value;
            if (this.TipoPromoSeleccionado.reducePrecio) {
                promocion.DetallePrecioPromocion.MontoPorc = promocion.DetallePrecioPromocion.MontoPorc * -1;
            }
        }
        else if (promocion.DetallePrecioPromocion.PrecioFijo) {//PRECIO FIJO EXACTO
            promocion.DetallePrecioPromocion.MontoPrecioFijo = document.getElementById('txtMonto').value;
            if (this.TipoPromoSeleccionado.reducePrecio) {
                if (promocion.DetallePrecioPromocion.MontoPrecioFijo * -1 < 0) {
                    promocion.DetallePrecioPromocion.MontoPrecioFijo = promocion.DetallePrecioPromocion.MontoPrecioFijo * -1; 
                }
                else {
                    promocion.DetallePrecioPromocion.MontoPrecioFijo = promocion.DetallePrecioPromocion.MontoPrecioFijo;
                }

            }
        }
        else {//CAMBIO DE PRECIO POR PRECIO FIJO
            promocion.DetallePrecioPromocion.montoPrecioFijo = document.getElementById('txtMonto').value;
            promocion.DetallePrecioPromocion.DescPorc = false;
            promocion.DetallePrecioPromocion.PrecioFijo = false;
            promocion.DetallePrecioPromocion.PrecioFijoExacto = true;
        }
        if (promocion.DetallePrecioPromocion.montoPrecioFijo === null || promocion.DetallePrecioPromocion.montoPrecioFijo === 0) {
            swal('Monto no válido');
            return;
        }
        promocion.IsHabilitado = true;
        var sucursales = Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos');
        var dataSucursales = [];
        sucursales.forEach((item) => {
            dataSucursales.push({
                SucursalID: item.value,
                PromocionID: 0
            });
        });
        promocion.sucursales = dataSucursales;

        var productos = Array.apply(null, document.getElementById('cboProd').options).filter(p => p.selected && p.label !== 'Todos');
        var dataProds = [];
        productos.forEach((item) => {
            dataProds.push({
                TbProductoID: item.value,
                PromocionID: 0
            });
        });
        promocion.productos = dataProds;
        if (promocion.productos.length === 0) {
            swal('Tiene que seleccionar almenos un producto');
            return;
        }
        promocion.TipoPromocionID = document.getElementById('cboTipoPromo').value;
        var respuesta = await fetch(GetUrlApi() + '/api/Promociones/' + promocion.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(promocion),
        });
        if (respuesta.ok) {
            this.setState({ activarEditarPromo: false });
            swal('Promoción editada con éxito').then(() => {
                this.props.Recargar();
            });
        }
    }

    ToNumberArray(array1) {
        var res = [];
        array1.forEach((item) => {
            res.push(item.value);
        });
        return res;
    }

    async TraerProductos() { 
        var idCategoria = document.getElementById('cboCateg').value;
        var selectedIndex = document.getElementById('cboCateg').selectedIndex;
        var cbo = document.getElementById("cboCateg").options[selectedIndex];
        var sucuID = cbo.getAttribute("sucursalID");
        if (!sucuID) {
            sucuID = GetUserCookie().SucursalID;
        }
        var sucursalesSeleccionadas = this.ToNumberArray(Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos'));
        var data = {};
        data.sucursales = sucursalesSeleccionadas;
        data.id = idCategoria;
        data.SucursalID = sucuID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductosPromocion?id=' + idCategoria + "&SucursalID=" + sucuID, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (categorias.ok) {
            var datos = await categorias.json();
            this.setState({ ProductosSeleccionados: datos, todosSelected: false });
        }
    }

    async TraerProductosGrupo() {
        var idCategoria = document.getElementById('cboCateg').value;
        var selectedIndex = document.getElementById('cboCateg').selectedIndex;
        var cbo = document.getElementById("cboCateg").options[selectedIndex];
        var sucuID = cbo.getAttribute("sucursalID");
        if (!sucuID) {
            sucuID = GetUserCookie().SucursalID;
        }
        var sucursalesSeleccionadas = this.ToNumberArray(Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos'));
        var data = {};
        data.sucursales = sucursalesSeleccionadas;
        data.id = idCategoria;
        data.SucursalID = sucuID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductosPromocion2?id=' + idCategoria + "&SucursalID=" + sucuID, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (categorias.ok) {
            var datos = await categorias.json();

            this.setState({ ProductosSeleccionados: datos, todosSelected: false });
        }
    }


    ClickTodos2() {
        this.CargaIncialLista = true;
        var idCategoria = document.getElementById('cboProd').value;
        if (idCategoria === '0') {//si no es un elemento traido de bd
            this.setState({ todosSelected: true });
        }
        else {
            this.setState({ todosSelected: false });
        }

    }

    CambioTipoPromo() {
        var tipoPromoID = document.getElementById('cboTipoPromo').value;
        this.TipoPromoSeleccionado = this.state.tiposPromocion.find(p => p.id === Number(tipoPromoID));
    }

    GetNumeroPositivo(num) {
        console.log(num);
        if (num < 0) {
            return num * -1;
        }
        return num;
    }

    ChangeCategoria() {
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerProductosGrupo();
        } else {
            this.TraerProductos();
        }
        
    }

    

    render() {
        return (
           
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                contentStyle={{ 'overflow': 'scroll', 'overflow': 'auto', 'width':'80%'}}
                onClose={() => { this.props.CerrarPopups() }}
                closeOnDocumentClick>
                <div style={{ marginLeft: "15px" }} className="row">
                    <div style={{ width: "60%" }}>
                        <form>
                            <div class="form-group">
                                <label for="txtDesc">Descripcion</label>
                                <input defaultValue={this.props.state.promoSeleccionada.descripcion} className="form-control" id="txtDesc" type="text" key={'sasa'} />
                            </div>

                            <div class="form-group">
                                <label for="cboDias">Día o dias</label>
                                <select className="form-control" id="cboDias" multiple >
                                    {
                                        this.props.state.diasSemana.map((dia) => {
                                            var array = this.props.state.promoSeleccionada.diasPromocion.filter(p => p.diasSemanaID === dia.id);
                                            const isSelected = array.length > 0;

                                            return (<option selected={isSelected} value={dia.id}>{dia.descripcion}</option>);
                                        })
                                    }
                                    <option value="0">Todos</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="inputAddress">Hora de inicio</label>
                                <input defaultValue={this.props.state.promoSeleccionada.horaDesde} className="form-control" id="txtHoraDesde" type="time" />
                            </div>
                            <div class="form-group">
                                <label for="inputAddress2">Hora de termino</label>
                                <input defaultValue={this.props.state.promoSeleccionada.horaHasta} className="form-control" id="txtHoraHasta" type="time" />
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputCity">Locales</label>
                                    <select id="cboSucursales" className="form-control" onChange={() => { this.CambioSucursal() }} multiple>
                                        {this.props.state.sucursales.map((item, i) => {
                                            return (<option selected={this.state.sucursalTodosSelected} value={item.id}>{item.descripcion}</option>);
                                        })}
                                        <option value="0">Todos</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">

                                </div>

                            </div>
                            <div class="form-group">

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputCity">Precio</label>
                                        <select id="cboTipoPromo" className="form-control" onChange={() => { this.CambioTipoPromo() }}>
                                            {
                                                this.props.state.tiposPromocion.map((item) => {
                                                    const isSelected = item.id === this.props.state.promoSeleccionada.tipoPromocionID;
                                                    if (isSelected) {
                                                        this.TipoPromoSeleccionado = item; 
                                                    }
                                                    return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="inputState">Monto</label>
                                        <input defaultValue={(this.props.state.promoSeleccionada.detallePrecioPromocion.montoPorc !== null) ? (
                                            this.GetNumeroPositivo(this.props.state.promoSeleccionada.detallePrecioPromocion.montoPorc)
                                        ) : (
                                                this.GetNumeroPositivo(this.props.state.promoSeleccionada.detallePrecioPromocion.montoPrecioFijo)
                                            )} className="form-control" id="txtMonto" type="number" min="0" />
                                    </div>

                                </div>



                            </div>
                        </form>
                    </div>

                    <div style={{ width: "40%" }}>
                        <label>Seleccione los productos que desea agregar</label>
                        <table>
                            <tr>
                                <td rowSpan="5">

                                    <select className="form-control" id="cboCateg" style={{ 'overflow': 'auto', width: '100%', cursor: 'pointer' }} size="15" onChange={() => { this.ChangeCategoria() }}>
                                        <option hidden disabled selected value> -- select an option -- </option>
                                        {
                                            this.state.Categorias.map((item, i) => {
                                                const id = 'optCatef' + i;
                                                try {
                                                    const categoriaID = this.props.state.promoSeleccionada.productos[0].tbProducto.desgrup;
                                                    const isSelected = (GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (item.descripcion === categoriaID) : (item.desgrup === categoriaID);
                                                    if (isSelected) {
                                                        this.TraerProductos2(categoriaID);
                                                    }
                                                    return (<option selected={isSelected} key={id} value={item.id}>{(GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (item.descripcion) : (item.desgrup)}</option>)
                                                } catch (err) {

                                                }

                                            })
                                        }
                                    </select>
                                </td>
                                <td>
                                    {
                                        (this.state.ProductosSeleccionados.length > 0) ? (
                                            <select className="form-control" id="cboProd" style={{ 'overflow': 'auto', width: '100%', float: 'right', cursor: 'pointer' }} size="15" multiple onChange={() => { this.ClickTodos2() }}>

                                                {
                                                    this.state.ProductosSeleccionados.map((item, i) => {
                                                        const id = 'optCatef' + i;
                                                        if (i === this.state.ProductosSeleccionados.length - 1) {
                                                            return (
                                                                <React.Fragment>
                                                                    <option montoAumento={item.precpred} selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>
                                                                    <option value="0">Todos</option>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        else {
                                                            return (<option montoAumento={item.precpred} selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>)
                                                        }
                                                    })
                                                }
                                            </select>
                                        ): (null)
                                    }
                                    

                                </td>
                            </tr>
                        </table>
                        {this.state.HoraServidor}
                    </div>
                </div>


                <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                    <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>


            </Popup>
        );
    }
}
