import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import ReactToExcel from 'react-html-table-to-excel';
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { HorizontalBar } from 'react-chartjs-2';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';

import swal from 'sweetalert';

export class Indicadores extends Component {
    static displayName = Indicadores.name;

    constructor() {
        super();
        this.state = {
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false,
            cargadatos: false,
            verVentas: true,
            verTransacciones: false,
            verDescuentos: false,
            verClientes:false,
            verAnulaciones:false,
            verVentasSuc: true,
            verTransaccionesSuc: false,
            verDescuentosSuc: false,
            verClientesSuc: false,
            verAnulacionesSuc: false,
            verVentasA: true,
            verTransaccionesA: false,
            verDescuentosA: false,
            verClientesA: false,
            verAnulacionesA: false,
            verCantidadRP: true,
            verPrecioRP: false,
            verCantidadRG: true,
            verPrecioRG: false,
            resultado: {},
            CantidadDelivery: 0,
            CantidadParaLlevar: 0,
            resultado: {},
            Ventas: {},
            DataDonhutVentas: {},
            DonhutVentasOpt: {},
            //GraficoXHora
            DataLineChart: {},
            OptionsLinechart: {},
            DataLineChartDscto: {},
            OptionsLinechartDscto: {},
            DataLineChartTrans: {},
            OptionsLinechartTrans: {},
            DataLineChartCliente: {},
            OptionsLinechartCliente: {},
            DataLineChartAnulaciones: {},
            OptionsLinechartAnulaciones: {},
            //Horizontal Datas
            DataLineChartSuc: {},
            OptionsLinechartSuc: {},
            DataLineChartDsctoSuc: {},
            OptionsLinechartDsctoSuc: {},
            DataLineChartTransSuc: {},
            OptionsLinechartTransSuc: {},
            DataLineChartClienteSuc: {},
            OptionsLinechartClienteSuc: {},
            DataLineChartAnulacionesSuc: {},
            OptionsLinechartAnulacionesSuc: {},
            //GraficoAnual
            DataLineChartA: {},
            OptionsLinechartA: {},
            DataLineChartDsctoA: {},
            OptionsLinechartDsctoA: {},
            DataLineChartTransA: {},
            OptionsLinechartTransA: {},
            DataLineChartClientesA: {},
            OptionsLinechartClientesA: {},
            DataLineChartAnulacionesA: {},
            OptionsLinechartAnulacionesA: {},
            //--//
             RankingProd: {},
            RankingCat: {},
            RankingProdPrecio: {},
             RankingCatPrecio: {},
            VentasTotales: 0,
            VentasTotalesSC: 0,
            DescuentosCantidad: 0,
            TransaccionesCantidad: 0,
            TransaccionAvg: 0,
            DescuentoAvg: 0,
            ClienteAvg: 0,
            TransaccionAvgCom: 0,
            DescuentoAvgCom: 0,
            ClienteAvgCom: 0,           
            PDesctoCantidad: 0,
            PTransacCantidad: 0,
            PMDescuento: 0,
            PMTransaccion: 0,
            DescuentoCantCom: 0,
            TransacCantidadCom: 0,
            VerTabla: false,
            ClientesCantidad: 0,
            ClientesCantidadCom: 0,
            PmClienteCantidad: 0,
            PmClienteAvg:0,
            RankingUsuario: {},
            RankingUsuarioPrecio: {},
            verCantidadRU: true,
            verPrecioRU: false,
            verCantidadUsuAnulados: true,
            verPrecioUsuAnulados: false,
            AnulacionAvg: 0,
            AnulacionAvgCom: 0,
            AnulacionCantidad: 0,
            AnulacionCantidadCom: 0,
            PmAnulacionesCantidad:0,
            PmAnulacionesMonto: 0,
            RankingUsuarioAnulaciones: {},
            RankingUsuarioPrecioAnulaciones: {},
            Sucursales: [],
            SucSelected: 0,
            activarRedirigirLogin: false,
            RankingProductoTotales: {},
            RankingCategoriaTotales: {},
            RankingVentasUsuarioTotales: {},
            RankingAnulacionesUsuarioTotales: {},
            TotalGrupoDescuento:0,
            TotalProductoDescuento:0
        }
        

        
    }

    Redirigir(url) {
        this.props.history.push(url);
    }


    Estilos = {
        FondoDefault: {
            'backgroundColor': '#ecf0f5',
            'heigth': '100%',
        },
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '100%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '250px',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '100%',
            'backgroundColor': 'white',
            'height': '200px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        BordesCard:
        {
            'borderRadius': '25px'
        },
        TarjetaEstadisticas2: {
            'width': '250px',
            'backgroundColor': 'white',
            'height': '350px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '50px',
            'marginBottom': '40px'
        },
        tablaTotalesNew: {
            'width': '200px',
            'text-align': 'center'
        },
        QuitarMarginLeft: {
            'marginLeft': '40px',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            'textAlign': 'left'
        },
        TdStyleValues: {
            'textAlign': 'center'
        },
        AnchoBox: {
            'width': '350px'
        },
        AnchoBoxGraf: {
            'width': '100%',
            'borderRadius': '25px'

        },
        AvgStyle:
        {
            'color': 'red',
            'display':'none'
        },
        AvgStylePlus:
        {
            'color': 'Green',
            'display': 'none'
        },
        AvgStyleEquals:
        {
            'color': '#F9810A',
            'display': 'none'
        },
        myCustomScrollbar: {
    'position': 'relative',
    'height': '500px',
     'overflow': 'auto',
      'display': 'block'
        },
        myCustomScrollbarRU: {
    'position': 'relative',
    'height': '330px',
     'overflow': 'auto',
      'display': 'block'
        },
        myCustomScrollbar2: {
    'position': 'relative',
    'height': '500px',
     'overflow': 'auto',
      'display': 'block'
        },
        CardSizeRanking:
        {
            'width': '500px',
            'height': '700px',
            'borderRadius': '25px',
            'margin-left': '-1%'
        },
        CardSizeRanking2:
        {
            'width': '500px',
            'height': '700px',
            'borderRadius': '25px',
            'margin-left': '3%'
        },   
        headerColor:
        {
            'backgroundColor':'#fff'
        },
        TableHeaderFijo: {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': '#fff'
        },
        MarcarBorde:
        {
            'fontColor': '#fff',
            'height':'40px'
        },
        rankingUsuarios:
        {
           'width': '500px',
            'height': '500px',
            'borderRadius': '25px',
            'margin-left': '-1%'
        },
        rankingUsuarios2:
        {
            'width': '500px',
            'height': '500px',
            'borderRadius': '25px',
            'margin-left': '3%'
        }
    }

   async TraerSucursales()
    {
       var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/GetSucursales', {
           method: 'get',
           headers: {
               'Content-Type': 'application/json',
           }
       });
       if (respuesta.ok) {
           var datos = await respuesta.json();
           console.log(datos);
           this.setState({Sucursales:datos});
       }
    }

    CambioPestañaGxH(id)
    {
        if (id==1) {
            this.setState({ verDescuentos: false, verTransacciones: false, verVentas: true, verClientes: false, verAnulaciones: false})
        } else if (id==2) {
            this.setState({ verDescuentos: false, verTransacciones: true, verVentas: false, verClientes: false, verAnulaciones: false })

        } else if (id==3) {
            this.setState({ verDescuentos: true, verTransacciones: false, verVentas: false, verClientes: false, verAnulaciones: false})

        } else if (id==4) {
            this.setState({ verDescuentos: false, verTransacciones: false, verVentas: false, verClientes: true, verAnulaciones: false})
        } else if (id==5) {
            this.setState({ verDescuentos: false, verTransacciones: false, verVentas: false, verClientes: false, verAnulaciones: true})
        }
    }
    CambioPestañaGxS(id)
    {
        if (id==1) {
            this.setState({ verDescuentosSuc: false, verTransaccionesSuc: false, verVentasSuc: true, verClientesSuc: false, verAnulacionesSuc: false})
        } else if (id==2) {
            this.setState({ verDescuentosSuc: false, verTransaccionesSuc: true, verVentasSuc: false, verClientesSuc: false, verAnulacionesSuc: false})

        } else if (id==3) {
            this.setState({ verDescuentosSuc: true, verTransaccionesSuc: false, verVentasSuc: false, verClientesSuc: false, verAnulacionesSuc: false})

        }else if (id==4) {
            this.setState({ verDescuentosSuc: false, verTransaccionesSuc: false, verVentasSuc: false, verClientesSuc: true, verAnulacionesSuc: false})

        }else if (id==5) {
            this.setState({ verDescuentosSuc: false, verTransaccionesSuc: false, verVentasSuc: false, verClientesSuc: false, verAnulacionesSuc: true })

        }
    }
    CambioPestañaGA(id) {
        if (id == 1) {
            this.setState({ verDescuentosA: false, verTransaccionesA: false, verVentasA: true, verClientesA: false, verAnulacionesA: false  })
        } else if (id == 2) {
            this.setState({ verDescuentosA: false, verTransaccionesA: true, verVentasA: false, verClientesA: false, verAnulacionesA: false  })

        } else if (id == 3) {
            this.setState({ verDescuentosA: true, verTransaccionesA: false, verVentasA: false, verClientesA: false, verAnulacionesA: false })

        }else if (id == 4) {
            this.setState({ verDescuentosA: false, verTransaccionesA: false, verVentasA: false, verClientesA: true, verAnulacionesA: false })

        }else if (id == 5) {
            this.setState({ verDescuentosA: false, verTransaccionesA: false, verVentasA: false, verClientesA: false, verAnulacionesA: true })

        }
    }
    CambioPestañaRP(id) {
        if (id == 1) {
            this.setState({ verPrecioRP: false, verCantidadRP: true })
        } else if (id == 2) {
            this.setState({ verPrecioRP: true, verCantidadRP: false  })

        }
    }
    CambioPestañaRU(id) {
        if (id == 1) {
            this.setState({ verPrecioRU: false, verCantidadRU: true })
        } else if (id == 2) {
            this.setState({ verPrecioRU: true, verCantidadRU: false })

        }
    }
    CambioPestañaUsAnuladas(id) {
        if (id == 1) {
            this.setState({ verPrecioUsuAnulados: false, verCantidadUsuAnulados: true })
        } else if (id == 2) {
            this.setState({ verPrecioUsuAnulados: true, verCantidadUsuAnulados: false })
        }
    }

    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day ;
        document.getElementById('fechaFiltro').value = today;
        document.getElementById('fechaFiltroHasta').value = today;
        document.getElementById('fechaini').value = today;
        document.getElementById('fechafin').value = today;
    }

    componentDidMount()
    {
        var user = GetUserCookie();
        if (user.tipoUsuario.id != 1) {
            Logout();
            this.setState({ activarRedirigirLogin: true });
        } else {
            this.TraerSucursales();
            this.cargarFechas();
            this.TraerTotalVentasPorFecha()
            const width = window.screen.availWidth;

            const height = window.screen.availHeight;
            if (width < 1000) {

                this.HacerResponsive();

            }
        }
       

    }

    CambioPestañaRG(id) {
        if (id == 1) {
            this.setState({ verPrecioRG: false, verCantidadRG: true })
        } else if (id == 2) {
            this.setState({ verPrecioRG: true, verCantidadRG: false })

        }
    }

    HacerResponsive() {

        var elements = Array.from(document.getElementsByTagName("*"));
        elements.forEach((item) => {
            item.style.width = '100%';
        });

    }

   
    async TraerTotalVentasPorFecha() {
        try {
            this.setState({ cargadatos: true });
            var data = {};
            var sucID = document.getElementById('SucId').value;


            document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;
            document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
            data.fechaIni = document.getElementById('fechaFiltro').value;
            data.TipoComparativo = "Semana";
            data.fechaFin = document.getElementById('fechaFiltroHasta').value;
            data.Sucursal = sucID;
            var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/PostIndicadores', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                throw { mensaje: 'problema al traer las ventas' };

            } else {
                var datos = await respuesta.json();
                console.log(datos);
                var descuentoAplicado = datos.descuentoAvg;
                var totalGrupo = datos.totalCategoriaRanking.totalvalorTotal;
                var totalProducto = datos.totalProductoRanking.totalvalorTotal;
                console.log(descuentoAplicado);
                console.log(totalGrupo);
                console.log(totalProducto);
                var totalGrupoDescuento = totalGrupo - descuentoAplicado;
                var totalProductoDescuento = totalProducto - descuentoAplicado;
                //donhut
                var dataDonhut = datos.ventas;
                var Labels = [];
                var DatosDona = [];
                for (var i = 0; i < dataDonhut.length; i++) {
                    Labels.push(dataDonhut[i].tipoVenta);
                    DatosDona.push(dataDonhut[i].venta);
                    //DatosDona.push(GetPrecioFormateado(dataDonhut[i].venta));
                }
                //linea
                var LineChart = datos.ventasPorHora;
                var LineChartSuc = datos.ventasPorSucursal;
                var LineChartAño = datos.ventasPorAño;
                //LineChart 
                var options=[];
                var optionsDscto=[];
                var optionsTrans=[];
                var optionsCliente=[];
                var optionsAnulaciones=[];
                var dataLinechart=[];
                var dataLinechartCliente=[];
                var dataLinechartDscto=[];
                var dataLinechartTrans = [];
                var dataLinechartAnulaciones = [];
                //HorizontalChart
                var optionsSuc = [];
                var optionsDsctoSuc = [];
                var optionsTransSuc = [];
                var optionsClientesSuc = [];
                var optionsAnulacionesSuc = [];
                var dataLinechartSuc = [];
                var dataLinechartDsctoSuc = [];
                var dataLinechartTransSuc = [];
                var dataLinechartClientesSuc = [];
                var dataLinechartAnulacionesSuc = [];
                //--//
                //LinchartAño
                var optionsA = [];
                var optionsDsctoA = [];
                var optionsTransA = [];
                var optionsClientesA = [];
                var optionsAnulacionesA = [];
                var dataLinechartA = [];
                var dataLinechartDsctoA = [];
                var dataLinechartTransA = [];
                var dataLinechartClientesA = [];
                var dataLinechartAnulacionesA = [];
                if (LineChart.length==1) {
                    options.push("0");
                    optionsTrans.push("0");
                    optionsDscto.push("0");
                    optionsCliente.push("0");
                    optionsAnulaciones.push("0");
                    dataLinechart.push(0);
                    dataLinechartTrans.push(0);
                    dataLinechartDscto.push(0);
                    dataLinechartCliente.push(0);
                    dataLinechartAnulaciones.push(0);
                }
                for (var i = 0; i < LineChart.length; i++) {
                    options.push(LineChart[i].hora.toString()+"Hr");
                    optionsTrans.push(LineChart[i].hora.toString() + "Hr");
                    optionsDscto.push(LineChart[i].hora.toString() + "Hr");
                    optionsCliente.push(LineChart[i].hora.toString() + "Hr");
                    optionsAnulaciones.push(LineChart[i].hora.toString() + "Hr");
                    dataLinechart.push(LineChart[i].ventaPorHora);
                    dataLinechartTrans.push(LineChart[i].cantidadTransacciones);
                    dataLinechartDscto.push(LineChart[i].descuentosPorHora);
                    dataLinechartAnulaciones.push(LineChart[i].anulacionesPorHora);
                    dataLinechartCliente.push(LineChart[i].clientesPorHora);
                }
                for (var i = 0; i < LineChartSuc.length; i++) {
                    optionsSuc.push(LineChartSuc[i].sucursal);
                    optionsDsctoSuc.push(LineChartSuc[i].sucursal);
                    optionsTransSuc.push(LineChartSuc[i].sucursal);
                    optionsClientesSuc.push(LineChartSuc[i].sucursal);
                    optionsAnulacionesSuc.push(LineChartSuc[i].sucursal);
                    dataLinechartSuc.push(LineChartSuc[i].ventaPorSuc);
                    dataLinechartTransSuc.push(LineChartSuc[i].cantidadTransacciones);
                    dataLinechartDsctoSuc.push(LineChartSuc[i].descuentosSuc);
                    dataLinechartClientesSuc.push(LineChartSuc[i].clientesSuc);
                    dataLinechartAnulacionesSuc.push(LineChartSuc[i].anulacionesSuc);
                }
                for (var i = 0; i < LineChartAño.length; i++) {
                    var mes = "";
                    switch (LineChartAño[i].mesInt) {
                        case 1:
                            mes="Enero"
                            break;
                        case 2:
                            mes = "Febrero"
                            break;
                        case 3:
                            mes = "Marzo"
                            break;
                        case 4:
                            mes = "Abril"
                            break;
                        case 5:
                            mes = "Mayo"
                            break;
                        case 6:
                            mes = "Junio"
                            break;
                        case 7:
                            mes = "Julio"
                            break;
                        case 8:
                            mes = "Agosto"
                            break;
                        case 9:
                            mes = "Septiembre"
                            break;
                        case 10:
                            mes = "Octubre"
                            break;
                        case 11:
                            mes = "Noviembre"
                            break;
                        case 12:
                            mes = "Diciembre"
                            break;
                    }
                    optionsA.push(mes+" " + LineChartAño[i].año.toString());
                    optionsDsctoA.push(mes + " "  + LineChartAño[i].año.toString());
                    optionsTransA.push(mes + " "  + LineChartAño[i].año.toString());
                    optionsClientesA.push(mes + " "  + LineChartAño[i].año.toString());
                    optionsAnulacionesA.push(mes + " "  + LineChartAño[i].año.toString());
                    dataLinechartA.push(LineChartAño[i].ventaPorMes);
                    dataLinechartDsctoA.push(LineChartAño[i].descuentosPorMes);
                    dataLinechartTransA.push(LineChartAño[i].cantidadTransacciones);
                    dataLinechartClientesA.push(LineChartAño[i].clientesPorMes);
                    dataLinechartAnulacionesA.push(LineChartAño[i].anulacionesMes);

                }
                this.setState({
                    DataDonhutVentas: {
                        labels: Labels,
                        datasets:
                            [{
                                data: DatosDona,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    //'rgba(153, 102, 255, 0.2)',
                                    //'rgba(255, 159, 64, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(75, 192, 192, 1)',
                                    //'rgba(153, 102, 255, 1)',
                                    //'rgba(255, 159, 64, 1)'
                                ],
                                borderWidth: 1
                            }]

                    },
                    DonhutVentasOpt: {
                        options: {
                            tooltips: {
                                callbacks: {
                                    title: function (tooltipItem, data) {
                                        return data['labels'][tooltipItem[0]['index']];
                                    },
                                    label: function (tooltipItem, data) {
                                        return data['datasets'][0]['data'][tooltipItem['index']];
                                    },
                                    afterLabel: function (tooltipItem, data) {
                                        var dataset = data['datasets'][0];
                                        var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                                        return '(' + percent + '%)';
                                    }
                                },
                                backgroundColor: '#FFF',
                                titleFontSize: 16,
                                titleFontColor: '#0066ff',
                                bodyFontColor: '#000',
                                bodyFontSize: 14,
                                displayColors: false
                            }
                        }
                    },
                //Horas
                    OptionsLinechart: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ingresos'
                                },
                            }],
                            xAxes: [{
                                labels: options,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Horas'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChart: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Ventas',
                            data: dataLinechart,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartTrans: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Cantidad'
                                },
                            }],
                            xAxes: [{
                                labels: optionsTrans,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Horas'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartTrans: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Transacciones',
                            data: dataLinechartTrans,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartDscto: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Descuentos'
                                },
                            }],
                            xAxes: [{
                                labels: optionsDscto,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Horas'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartDscto: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Descuentos',
                            data: dataLinechartDscto,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartCliente: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Clientes'
                                },
                            }],
                            xAxes: [{
                                labels: optionsCliente,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Horas'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartCliente: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Clientes',
                            data: dataLinechartCliente,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartAnulaciones: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Anulaciones'
                                },
                            }],
                            xAxes: [{
                                labels: optionsAnulaciones,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Horas'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartAnulaciones: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Clientes',
                            data: dataLinechartAnulaciones,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    //Sucursal
                    OptionsLinechartSuc: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Sucursales'
                                },
                            }],
                            xAxes: [{
                                labels: optionsSuc,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ventas'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartSuc: {
                        labels: optionsSuc,
                        datasets: [{
                            label: 'Ventas',
                            data: dataLinechartSuc,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartTransSuc: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ingresos'
                                },
                            }],
                            xAxes: [{
                                labels: optionsTransSuc,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Transacciones'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartTransSuc: {
                        labels:optionsTransSuc,
                        datasets: [{
                            label: 'Transacciones',
                            data: dataLinechartTransSuc,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },

                    OptionsLinechartDsctoSuc: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ingresos'
                                },
                            }],
                            xAxes: [{
                                labels: optionsDsctoSuc,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Descuentos'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartDsctoSuc: {
                        labels: optionsDsctoSuc,
                        datasets: [{
                            label: 'Descuentos',
                            data: dataLinechartDsctoSuc,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartClienteSuc: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Clientes'
                                },
                            }],
                            xAxes: [{
                                labels: optionsClientesSuc,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Clientes'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartClienteSuc: {
                        labels: optionsClientesSuc,
                        datasets: [{
                            label: 'Clientes',
                            data: dataLinechartClientesSuc,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    DataLineChartAnulacionesSuc: {
                        labels: optionsClientesSuc,
                        datasets: [{
                            label: 'Anulaciones',
                            data: dataLinechartAnulacionesSuc,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartClienteSuc: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Anulaciones'
                                },
                            }],
                            xAxes: [{
                                labels: optionsAnulacionesSuc,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Anulaciones'
                                },
                                display: true
                            }]
                        }
                    },
                    //Anual
                    OptionsLinechartA: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ingresos'
                                },
                            }],
                            xAxes: [{
                                labels: optionsA,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Meses'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartA: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Ventas',
                            data: dataLinechartA,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartTransA: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ingresos'
                                },
                            }],
                            xAxes: [{
                                labels: optionsTransA,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Meses'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartTransA: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Transacciones',
                            data: dataLinechartTransA,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartDsctoA: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ingresos'
                                },
                            }],
                            xAxes: [{
                                labels: optionsDsctoA,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Meses'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartDsctoA: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Descuentos',
                            data: dataLinechartDsctoA,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartClientesA: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Ingresos'
                                },
                            }],
                            xAxes: [{
                                labels: optionsClientesA,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Meses'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartClientesA: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Clientes',
                            data: dataLinechartClientesA,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    OptionsLinechartAnulacionesA: {
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Anulaciones'
                                },
                            }],
                            xAxes: [{
                                labels: optionsAnulacionesA,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Meses'
                                },
                                display: true
                            }]
                        }
                    },
                    DataLineChartAnulacionesA: {
                        labels: ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Evento', 'Otros'],
                        datasets: [{
                            label: 'Clientes',
                            data: dataLinechartAnulacionesA,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    //
                    CantidadDelivery: datos.CantidadDelivery,
                    CantidadParaLlevar: datos.CantidadParaLLevar,
                    Ventas: datos.ventas,
                    VentasTotales: datos.ventasTotales,
                    VentasTotalesSC: datos.ventasTotalesSinCS,
                    VerTabla: true,
                    cargadatos: false,
                    DescuentosCantidad: datos.descuentosCantidad,
                    TransaccionesCantidad: datos.transaccionesCantidad,
                    TransaccionAvg: datos.transaccionAvg,
                    ClienteAvg: datos.clienteAvg,
                    DescuentoAvg: datos.descuentoAvg,
                    TransaccionAvgCom: datos.transaccionAvgCom,
                    ClienteAvgCom: datos.clienteAvgCom,
                    DescuentoAvgCom: datos.descuentoAvgCom,
                    RankingProd: datos.rankingProd,
                    RankingCat: datos.rankingCat,
                    RankingProdPrecio: datos.rankProdPrecio,
                    RankingCatPrecio: datos.rankCategoriaPrecio,
                    PTransacCantidad: datos.pTransacCant,
                    PDesctoCantidad: datos.pDescuentoCant,
                    PMDescuento: datos.pmDescuento,
                    PMTransaccion: datos.pmTransaccion,
                    TransacCantidadCom: datos.transacCantidadCom,
                    DescuentoCantCom: datos.descuentosCantidadCom,
                    ClientesCantidad: datos.clientesCantidad,
                    ClientesCantidadCom: datos.clientesCantidadCom,
                    PmClienteAvg: datos.pmAvgCliente,
                    PmClienteCantidad: datos.pmCantidadCliente,
                    RankingUsuario: datos.rankingUsuarios,
                    RankingUsuarioPrecio: datos.rankingUsuarioPrecio,
                    AnulacionAvg: datos.anulacionAvg,
                    AnulacionAvgCom: datos.anulacionAvgCom,
                    AnulacionCantidad: datos.anulacionCantidad,
                    AnulacionCantidadCom: datos.anulacionCantidadCom,
                    PmAnulacionesCantidad: datos.pmCantidadAnulacion,
                    PmAnulacionesMonto: datos.pmPromedioAnulacion,
                    RankingUsuarioAnulaciones: datos.rankingUsuariosA,
                    RankingUsuarioPrecioAnulaciones: datos.rankingUsuarioPrecioA,
                    RankingProductoTotales: datos.totalProductoRanking,
                    RankingCategoriaTotales: datos.totalCategoriaRanking,
                    RankingVentasUsuarioTotales: datos.usuariosVentasTotales,
                    RankingAnulacionesUsuarioTotales: datos.usuariosAnulacionesTotales,
                    TotalGrupoDescuento: totalGrupoDescuento,
                    TotalProductoDescuento: totalProductoDescuento,
                    SucSelected: sucID
                }) 
                console.log(this.state.RankingProd);
       
                
            }
        } catch (err) {
            swal('error en TraerTotalVentasPorFecha ' + err);
        }
    }

    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;
       
    }

    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }
    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <center>
                    <div>
                        {
                            (this.state.activarRedirigirLogin) ? (<Redirect to='/' />): (null)
                        }
                    <table style={this.Estilos.FullAncho} >
                        <thead>
                            <th colSpan="3">Indicadores</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                            </tr>
                            <tr>
                                <td> 
                                        <select id="SucId" className="form-control">
                                            <option value="0">Todas</option>
                                        { 
                                            this.state.Sucursales.map((item, index) => {
                                                    return (<option value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde()}} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta()}} className="form-control" type="date" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </center>


            <hr />
            <div className="row">
                <div className="col-lg-5">
                    <input type="text" id="fechaini"  hidden ></input>
                        <input type="text" id="fechafin" hidden></input>
                </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.TraerTotalVentasPorFecha() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br/>
                <div>
                    <center>
                    {
                        (this.state.cargadatos) ? (
                            <div style={this.Estilos.FondoDefault}>
                                <center>
                                    <div className="spinner-border text-dark" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                    <p> Cargando...</p>
                                </center>
                            </div>
                        ) : (null)
                    }
                </center></div>
                <br />
      
                {
                    (this.state.VerTabla) ? (
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                    <div className="card" style={this.Estilos.TarjetaEstadisticas2}>
                                    <div className="card-body">
                                        <h5 className="card-title">Ventas</h5>
                                            
                                            <table border="0" style={this.Estilos.tablaTotalesNew}>
                                                    <thead>
                                                        <tr>
                                                            <td colSpan="2">Total</td>
                                                            <td colSpan="2">TotalSinCS</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="2"><strong>{GetPrecioFormateado(this.state.VentasTotales)}   </strong></td>
                                                            <td colSpan="2"><strong>   {GetPrecioFormateado(this.state.VentasTotalesSC)}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="4">Ganancias totales</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>


                                            
                                            <Doughnut data={this.state.DataDonhutVentas} options={this.state.DonhutVentasOpt}  height="150px" width="150px"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                        <div className="col-lg-6" style={this.Estilos.ColumnaChart2}>
                                            <div className="card" style={this.Estilos.TarjetaEstadisticas}>
                                            <div className="card-body">
                                                    <h5 className="card-title">Transacciones</h5>
 
                                                    <center>

                                                        <div className="row">
                                                            <div className="col-md-6">


                                                                
                                                                <p>
                                                                    {(this.state.TransaccionesCantidad > this.state.TransacCantidadCom) ? (
                                                                        <strong style={this.Estilos.AvgStylePlus}><i className="fa fa-angle-up fa-1x"></i>%{this.state.PTransacCantidad}</strong>
                                                                    ) : (this.state.TransaccionesCantidad < this.state.TransacCantidadCom) ?
                                                                            (<strong style={this.Estilos.AvgStyle}><i class="fa fa-angle-down fa-1x"></i>%{this.state.PTransacCantidad}</strong>) :
                                                                            (this.state.TransaccionesCantidad == 0 && this.state.TransacCantidadCom == 0) ?
                                                                                (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%{this.state.PTransacCantidad}</strong>) :
                                                                                (this.state.TransaccionesCantidad == this.state.TransacCantidadCom) ?
                                                                                    (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%{this.state.PTransacCantidad}</strong>) :(null)
                                                                           
                                                                        }
                                                                    <br/>
                                                                    <strong> {this.state.TransaccionesCantidad}</strong></p>

                                                                <p>CANTIDAD</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {(this.state.TransaccionAvg > this.state.TransaccionAvgCom) ? (
                                                                        <strong style={this.Estilos.AvgStylePlus}><i className="fa fa-angle-up fa-1x"></i> %{this.state.PMTransaccion}</strong>
                                                                    ) : (this.state.TransaccionAvg < this.state.TransaccionAvgCom) ?
                                                                            (<strong style={this.Estilos.AvgStyle}><i class="fa fa-angle-down fa-1x"></i> %{this.state.PMTransaccion}</strong>):
                                                                            (this.state.TransaccionAvg == 0 && this.state.TransaccionAvgCom == 0) ?
                                                                                (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i> %{this.state.PMTransaccion}</strong>): (null)
                                                                            
                                                                        }

                                                                    <br/>
                                                                    <strong>{GetPrecioFormateado(this.state.TransaccionAvg)}</strong>
                                                                  </p>

                                                                <p>AVG</p>
                                                            </div>
                                                        </div>

                                                    </center>
                                                </div>
                                        </div>
                                    </div>
                                        <div className="col-lg-6" style={this.Estilos.ColumnaChart2}>
                                            <div className="card" style={this.Estilos.TarjetaEstadisticas}>
                                            <div className="card-body">
                                                <h5 className="card-title">Clientes</h5>
                                                    <center>
                                                        <div className="row">



                                                            <div className="col-md-6">
                                                                <p>
                                                                    {(this.state.ClientesCantidad > this.state.ClientesCantidadCom) ? (
                                                                        <strong style={this.Estilos.AvgStylePlus}><i className="fa fa-angle-up fa-1x"></i>%{this.state.PmClienteCantidad}</strong>
                                                                    ) : (this.state.ClientesCantidad < this.state.ClientesCantidadCom) ?
                                                                            (<strong style={this.Estilos.AvgStyle}><i class="fa fa-angle-down fa-1x"></i>%{this.state.PmClienteCantidad}</strong>) :
                                                                            (this.state.TransaccionesCantidad == 0 && this.state.TransacCantidadCom == 0) ?
                                                                                (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%{this.state.PmClienteCantidad}</strong>) :
                                                                                (this.state.ClientesCantidad == this.state.ClientesCantidadCom) ?
                                                                                    (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%{this.state.PmClienteCantidad}</strong>) : (null)


                                                                    }
                                                                    <br />
                                                                    <strong> {this.state.ClientesCantidad}</strong></p>

                                                                <p>CANTIDAD</p>
                                                            </div>

                                                            
                                                            <div className="col-md-6">


                                                                <p>
                                                                    {(this.state.ClienteAvg > this.state.ClienteAvgCom) ? (
                                                                        <strong style={this.Estilos.AvgStylePlus}><i className="fa fa-angle-up fa-1x"></i>%{this.state.PmClienteAvg}</strong>
                                                                    ) : (this.state.ClienteAvg < this.state.ClienteAvgCom) ?
                                                                            (<strong style={this.Estilos.AvgStyle}><i class="fa fa-angle-down fa-1x"></i>%{this.state.PmClienteAvg}</strong>):
                                                                            (this.state.ClienteAvg == 0 && this.state.ClienteAvgCom == 0) ?
                                                                                (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%{this.state.PmClienteAvg}</strong>): (null)

                                                                    }
                                                                    <br />

                                                                 
                                                                    <strong>{GetPrecioFormateado(this.state.ClienteAvg)}</strong>
                                                                  </p>
                                                             <p>AVG</p>
                                                            </div>
                                                        </div>

                                                    </center>
                                                   
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-lg-6" style={this.Estilos.ColumnaChart2}>
                                            <div className="card" style={this.Estilos.TarjetaEstadisticas}>
                                            <div className="card-body">
                                                    <h5 className="card-title">Anulaciones</h5>
                                                    <center>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                             
                                                                   
                                                                    <p>
                                                                        {(this.state.AnulacionCantidad > this.state.AnulacionCantidadCom) ? (
                                                                            <strong style={this.Estilos.AvgStyle}><i className="fa fa-angle-up fa-1x"></i>%{this.state.PmAnulacionesCantidad}</strong>
                                                                        ) : (this.state.AnulacionCantidad < this.state.AnulacionCantidadCom) ?
                                                                                (<strong style={this.Estilos.AvgStylePlus}><i class="fa fa-angle-down fa-1x"></i>%{this.state.PmAnulacionesCantidad}</strong>) :
                                                                                (this.state.AnulacionCantidad == 0 && this.state.AnulacionCantidadCom == 0) ?
                                                                                    (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%{this.state.PmAnulacionesCantidad}</strong>) : (null)

                                                                        }
                                                                        <br />
                                                                        <strong> {this.state.AnulacionCantidad}</strong></p>

                                                                    <p>CANTIDAD</p>
                                                                
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {(this.state.AnulacionAvg > this.state.AnulacionAvgCom) ? (
                                                                        <strong style={this.Estilos.AvgStyle}><i className="fa fa-angle-up fa-1x"></i>%-{this.state.PmAnulacionesMonto}</strong>
                                                                    ) : (this.state.AnulacionAvg < this.state.AnulacionAvgCom) ?
                                                                            (<strong style={this.Estilos.AvgStylePlus}><i class="fa fa-angle-down fa-1x"></i>%{this.state.PmAnulacionesMonto}</strong>):
                                                                            (this.state.AnulacionAvg == 0 && this.state.AnulacionAvgCom == 0) ?
                                                                                (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%0</strong>): (null)

 
                                                                    }
                                                                    <br/>
                                                                    <strong>{GetPrecioFormateado(this.state.AnulacionAvg)}</strong>
                                                                   </p>

                                                                <p>MONTO</p>
                                                            </div>
                                                        </div>

                                                    </center>
                                                  
                                            </div>
                                        </div>
                                    </div>
                                        <div className="col-lg-6" style={this.Estilos.ColumnaChart2}>
                                            <div className="card" style={this.Estilos.TarjetaEstadisticas}>
                                            <div className="card-body">
                                                <h5 className="card-title">Descuentos</h5>

                                                    <center>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                
                                                                <p>
                                                                    {
                                                                        (this.state.DescuentosCantidad > this.state.DescuentoCantCom) ? (
                                                                        <strong style={this.Estilos.AvgStyle}><i className="fa fa-angle-down fa-1x"></i>%{this.state.PDesctoCantidad}</strong>
                                                                        ) : (
                                                                                this.state.DescuentoCantCom > this.state.DescuentosCantidad) ?
                                                                           ( <strong style={this.Estilos.AvgStylePlus}><i class="fa fa-angle-up fa-1x"></i>%{this.state.PDesctoCantidad}</strong>
                                                                            ) : (this.state.DescuentosCantidad == 0 && this.state.DescuentoCantCom==0) ?
                                                                                (<strong style={this.Estilos.AvgStyleEquals}><i class="fa fa-angle-left fa-1x"></i>%{this.state.PDesctoCantidad}</strong>) : (null)
                                                                }
                                                                    <br />
                                                                    <strong> {this.state.DescuentosCantidad}</strong></p>

                                                                <p>CANTIDAD</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                               
                                                                    {(this.state.DescuentoAvg > this.state.DescuentoAvgCom) ? (

                                                                    <p><strong style={this.Estilos.AvgStyle}><i class="fa fa-angle-down fa-1x"></i>%{this.state.PMDescuento}</strong>
                                                                        <br />
                                                                        <strong>-{GetPrecioFormateado(this.state.DescuentoAvg)}</strong> </p>

                                                                ) : (this.state.DescuentoAvg < this.state.DescuentoAvgCom)?(
                                                                        <p>  <strong style={this.Estilos.AvgStylePlus}><i className="fa fa-angle-up fa-1x"></i>%{this.state.PMDescuento}</strong>
                                                                            <br />
                                                                            <strong>{GetPrecioFormateado(this.state.DescuentoAvg)}</strong> </p>

                                                                    ) : (this.state.DescuentoAvg == 0 && this.state.DescuentoAvgCom == 0) ?
                                                                            (<p>  <strong style={this.Estilos.AvgStyleEquals}><i className="fa fa-angle-left fa-1x"></i>%{this.state.PMDescuento}</strong>
                                                                                <br />
                                                                                <strong>{GetPrecioFormateado(this.state.DescuentoAvg)}</strong> </p>): (null)
                                                                        
                                                                        }
 

                                                               

                                                                <p>MONTO</p>
                                                            </div>
                                                        </div>

                                                    </center>
                                                  
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                    <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                        <div className="card-header" style={this.Estilos.headerColor}>
                                            <ul className="nav nav-tabs card-header-tabs">
                                                {(this.state.verVentas) ? (
                                                    <li className="nav-item active">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxH(1) }} role="tab" aria-controls="ventas" aria-selected="true" aria-expanded="true">Ventas</button>
                                                    </li>
                                                ) : (<li className="nav-item"><button className="nav-link" onClick={() => { this.CambioPestañaGxH(1) }} role="tab" aria-controls="ventas" aria-selected="true">Ventas</button>
                                                        </li>
                                                        )}
                                                {(this.state.verTransacciones) ? (
                                                    <li className="nav-item active">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxH(2) }} role="tab" aria-controls="transacciones" aria-selected="true" aria-expanded="true">Transacciones</button>
                                                    </li>
                                                ) : (<li className="nav-item ">
                                                    <button className="nav-link" onClick={() => { this.CambioPestañaGxH(2) }} role="tab" aria-controls="transacciones" aria-selected="true" >Transacciones</button>
                                                </li>)

                                                }
                                                {(this.state.verDescuentos) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxH(3) }} role="tab" aria-controls="descuentos" aria-selected="true" aria-expanded="true">Descuentos</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                    <button className="nav-link" onClick={() => { this.CambioPestañaGxH(3) }} role="tab" aria-controls="descuentos" aria-selected="true">Descuentos</button>
                                                </li>)

                                                }
                                                {(this.state.verClientes) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxH(4) }} role="tab" aria-controls="clientes" aria-selected="true" aria-expanded="true">Clientes</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGxH(4) }} role="tab" aria-controls="clientes" aria-selected="true">Clientes</button>
                                                </li>)

                                                }
                                                {(this.state.verAnulaciones) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxH(5) }} role="tab" aria-controls="clientes" aria-selected="true" aria-expanded="true">Anulaciones</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGxH(5) }} role="tab" aria-controls="clientes" aria-selected="true">Anulaciones</button>
                                                </li>)

                                                }
                                            </ul>
                                        </div>
                                    <div className="card-body">
                                            <h5 className="card-title">Gráfico X Hora</h5>

                                            <div className="tab-content mt-3">
                                                {(this.state.verVentas) ? (
                                               
                                                    <Line data={this.state.DataLineChart} options={this.state.OptionsLinechart} />
                                                ):(null)

                                                }
                                                   
                                                {(this.state.verTransacciones) ? (
                                                    <Line data={this.state.DataLineChartTrans} options={this.state.OptionsLinechartTrans} />
                                                ): (null)

                                                }
                                                {(this.state.verDescuentos) ? (
                                                    <Line data={this.state.DataLineChartDscto} options={this.state.OptionsLinechartDscto} />

                                                ) : (null)

                                                }
                                                {(this.state.verClientes) ? (
                                                    <Line data={this.state.DataLineChartCliente} options={this.state.OptionsLinechartCliente} />

                                                ) : (null)

                                                }
                                                {(this.state.verAnulaciones) ? (
                                                    <Line data={this.state.DataLineChartAnulaciones} options={this.state.OptionsLinechartAnulaciones} />

                                                ) : (null)

                                                }
                                               
                                                
                                            </div>

                                       
                                </div>

                            </div>
                            </div>
                        </div>
                            <br />

                            <div className="row">
                                <div className="col-md-4" style={this.Estilos.CardSizeRanking}>
                                    <div className="card" style={this.Estilos.CardSizeRanking}>
                                        <div className="card-header" style={this.Estilos.headerColor}>

                                        <ul className="nav nav-tabs card-header-tabs">

                                                {(this.state.verCantidadRP) ? (<li className="nav-item">
                                                    <button className="nav-link active" style={this.Estilos.MarcarBorde}  onClick={() => { this.CambioPestañaRP(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>) : (<li className="nav-item">
                                                    <button className="nav-link " onClick={() => { this.CambioPestañaRP(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>)}
                                                {(this.state.verPrecioRP) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde}  onClick={() => { this.CambioPestañaRP(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                    </li>) : (
                                                        <li className="nav-item">
                                                            <button className="nav-link " onClick={() => { this.CambioPestañaRP(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                        </li>)}

                                        </ul>
                                    </div>
                                        <div className="card-body" style={this.Estilos.CardSizeRanking}>
                                        <h5 className="card-title">Ranking Productos</h5>
                                        <div className="tab-content mt-3">

                                                {(this.state.verCantidadRP) ? (
                                                <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                        <table className="table  table-hover" >
                                                        <thead>
                                                            <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Producto</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.RankingProd.map((item, i) => {

                                                                    return (<tr>
                                                                        <td colSpan="3">{item.productName}</td>
                                                                        <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                        <td colSpan="3">{item.cantidadProd}</td>
                                                                        <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                        <td colSpan="3">{item.porcentajePrecio}</td>

                                                                    </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                <td colSpan="3"><b>Totales</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingProductoTotales.totalvalorTotal)}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingProductoTotales.totalCantidad}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingProductoTotales.totalPorcentajeCantidad}%</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingProductoTotales.totalPorcentajePrecio}%</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="3"><b>Totales Con Descuento</b></td>
                                                                    <td colSpan="3"><b>{GetPrecioFormateado(this.state.TotalProductoDescuento)}</b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                </tr>
                                                            </tfoot>
                                                    </table>
                                                </div>
                                            ): (null)}
                                                {(this.state.verPrecioRP) ? (

                                                <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                        <table className="table  table-hover"  >
                                                        <thead>
                                                            <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Producto</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.RankingProdPrecio.map((item, i) => {

                                                                    return (<tr>
                                                                        <td colSpan="3">{item.productName}</td>
                                                                        <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                        <td colSpan="3">{item.cantidadProd}</td>
                                                                        <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                        <td colSpan="3">{item.porcentajePrecio}</td>


                                                                    </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan="3"><b>Totales</b></td>
                                                                    <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingProductoTotales.totalvalorTotal)}</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingProductoTotales.totalCantidad}</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingProductoTotales.totalPorcentajeCantidad}%</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingProductoTotales.totalPorcentajePrecio}%</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="3"><b>Totales Con Descuento</b></td>
                                                                    <td colSpan="3"><b>{GetPrecioFormateado(this.state.TotalProductoDescuento)}</b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                </tr>
                                                            </tfoot>
                                                    </table>
                                                </div>
                                            ): (null)}


                                        </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-4" style={this.Estilos.CardSizeRanking2}>
                                    <div className="card" style={this.Estilos.CardSizeRanking2}>

                                        <div className="card-header" style={this.Estilos.headerColor}>

                                            <ul className="nav nav-tabs card-header-tabs">
                                                {(this.state.verCantidadRG) ? (<li className="nav-item">
                                                    <button className="nav-link active" style={this.Estilos.MarcarBorde}  onClick={() => { this.CambioPestañaRG(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>) : (<li className="nav-item">
                                                    <button className="nav-link " onClick={() => { this.CambioPestañaRG(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>)}
                                                {(this.state.verPrecioRG) ? (<li className="nav-item">
                                                    <button className="nav-link active" style={this.Estilos.MarcarBorde}  onClick={() => { this.CambioPestañaRG(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                </li>) : (<li className="nav-item">
                                                    <button className="nav-link " onClick={() => { this.CambioPestañaRG(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                </li>)}

                                            </ul>
                                        </div>

                                        <div className="card-body" style={this.Estilos.CardSizeRanking2}>
                                            <h5 className="card-title">Ranking de Categoria</h5>
                                            <div className="tab-content mt-3">
                                                {(this.state.verCantidadRG) ? (
                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                        <table className="table  table-hover" >
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Producto</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.RankingCat.map((item, i) => {

                                                                        return (<tr>
                                                                            <td colSpan="3">{item.categoriaName}</td>
                                                                            <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                            <td colSpan="3">{item.cantidadProd}</td>
                                                                            <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                            <td colSpan="3">{item.porcentajePrecio}</td>

                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                <td colSpan="3"><b>Totales</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingCategoriaTotales.totalvalorTotal)}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingCategoriaTotales.totalCantidad}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingCategoriaTotales.totalPorcentajeCantidad}%</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingCategoriaTotales.totalPorcentajePrecio}%</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="3"><b>Totales Con Descuento</b></td>
                                                                    <td colSpan="3"><b>{GetPrecioFormateado(this.state.TotalGrupoDescuento)}</b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                ) : (null)}

                                                {(this.state.verPrecioRG) ? (
                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                        <table className="table  table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Producto</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.RankingCatPrecio.map((item, i) => {

                                                                        return (<tr>
                                                                            <td colSpan="3">{item.categoriaName}</td>
                                                                            <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                            <td colSpan="3">{item.cantidadProd}</td>
                                                                            <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                            <td colSpan="3">{item.porcentajePrecio}</td>

                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan="3"><b>Totales</b></td>
                                                                    <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingCategoriaTotales.totalvalorTotal)}</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingCategoriaTotales.totalCantidad}</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingCategoriaTotales.totalPorcentajeCantidad}%</b></td>
                                                                    <td colSpan="3"><b>{this.state.RankingCategoriaTotales.totalPorcentajePrecio}%</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="3"><b>Totales Con Descuento</b></td>
                                                                    <td colSpan="3"><b>{GetPrecioFormateado(this.state.TotalGrupoDescuento)}</b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                    <td colSpan="3"><b></b></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                ): (null)}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>


                            <br />

                            <div className="row">
                                <div className="col-md-4" style={this.Estilos.rankingUsuarios}>
                                    <div className="card" style={this.Estilos.rankingUsuarios}>
                                        <div className="card-header" style={this.Estilos.headerColor}>

                                            <ul className="nav nav-tabs card-header-tabs">

                                                {(this.state.verCantidadRU) ? (<li className="nav-item">
                                                    <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaRU(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>) : (<li className="nav-item">
                                                    <button className="nav-link " onClick={() => { this.CambioPestañaRU(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>)}
                                                {(this.state.verPrecioRU) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaRU(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                    </li>) : (
                                                        <li className="nav-item">
                                                            <button className="nav-link " onClick={() => { this.CambioPestañaRU(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                        </li>)}

                                            </ul>
                                        </div>
                                        <div className="card-body" style={this.Estilos.rankingUsuarios}>
                                            <h5 className="card-title">Ranking Ventas Usuarios</h5>
                                            <div className="tab-content mt-3">

                                                {(this.state.verCantidadRU) ? (
                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbarRU}>
                                                        <table className="table  table-responsive" >
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Usuario</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.RankingUsuario.map((item, i) => {

                                                                        return (<tr>
                                                                            <td colSpan="3">{item.nombreUsuario}</td>
                                                                            <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                            <td colSpan="3">{item.cantidad}</td>
                                                                            <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                            <td colSpan="3">{item.porcentajePrecio}</td>

                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <td colSpan="3"><b>Totales</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingVentasUsuarioTotales.totalvalorTotal)}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingVentasUsuarioTotales.totalCantidad}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingVentasUsuarioTotales.totalPorcentajeCantidad}%</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingVentasUsuarioTotales.totalPorcentajePrecio}%</b></td>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                ) : (null)}
                                                {(this.state.verPrecioRU) ? (

                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbarRU}>
                                                        <table className="table  table-hover"  >
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Usuario</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.RankingUsuarioPrecio.map((item, i) => {

                                                                        return (<tr>
                                                                            <td colSpan="3">{item.nombreUsuario}</td>
                                                                            <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                            <td colSpan="3">{item.cantidad}</td>
                                                                            <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                            <td colSpan="3">{item.porcentajePrecio}</td>


                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <td colSpan="3"><b>Totales</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingVentasUsuarioTotales.totalvalorTotal)}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingVentasUsuarioTotales.totalCantidad}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingVentasUsuarioTotales.totalPorcentajeCantidad}%</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingVentasUsuarioTotales.totalPorcentajePrecio}%</b></td>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                ) : (null)}


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-4" style={this.Estilos.rankingUsuarios2}>
                                    <div className="card" style={this.Estilos.rankingUsuarios2}>

                                        <div className="card-header" style={this.Estilos.headerColor}>

                                            <ul className="nav nav-tabs card-header-tabs">
                                                {(this.state.verCantidadUsuAnulados) ? (<li className="nav-item">
                                                    <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaUsAnuladas(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>) : (<li className="nav-item">
                                                        <button className="nav-link " onClick={() => { this.CambioPestañaUsAnuladas(1) }} role="tab" aria-controls="Cantidad" aria-selected="true">Cantidad</button>
                                                </li>)}
                                                {(this.state.verPrecioUsuAnulados) ? (<li className="nav-item">
                                                    <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaUsAnuladas(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                </li>) : (<li className="nav-item">
                                                        <button className="nav-link " onClick={() => { this.CambioPestañaUsAnuladas(2) }} role="tab" aria-controls="Precio" aria-selected="true">Precio</button>
                                                </li>)}

                                            </ul>
                                        </div>

                                        <div className="card-body" style={this.Estilos.rankingUsuarios2}>
                                            <h5 className="card-title">Ranking de Anulaciones por Usuario</h5>
                                            <div className="tab-content mt-3">
                                                {(this.state.verCantidadUsuAnulados) ? (
                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbarRU}>
                                                        <table className="table  table-responsive" >
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Usuario</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.RankingUsuarioAnulaciones.map((item, i) => {

                                                                        return (<tr>
                                                                            <td colSpan="3">{item.nombreUsuario}</td>
                                                                            <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                            <td colSpan="3">{item.cantidad}</td>
                                                                            <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                            <td colSpan="3">{item.porcentajePrecio}</td>

                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <td colSpan="3"><b>Totales</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingAnulacionesUsuarioTotales.totalvalorTotal)}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingAnulacionesUsuarioTotales.totalCantidad}</b></td>
                                                                <td colSpan="3"><b>%</b></td>
                                                                <td colSpan="3"><b>%</b></td>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                ) : (null)}

                                                {(this.state.verPrecioUsuAnulados) ? (
                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbarRU}>
                                                        <table className="table  table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Usuario</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">% Precio</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.RankingUsuarioPrecioAnulaciones.map((item, i) => {

                                                                        return (<tr>
                                                                            <td colSpan="3">{item.nombreUsuario}</td>
                                                                            <td colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                            <td colSpan="3">{item.cantidad}</td>
                                                                            <td colSpan="3">{item.porcentajeCantidad}</td>
                                                                            <td colSpan="3">{item.porcentajePrecio}</td>

                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <td colSpan="3"><b>Totales</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.RankingAnulacionesUsuarioTotales.totalvalorTotal)}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingAnulacionesUsuarioTotales.totalCantidad}</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingAnulacionesUsuarioTotales.totalPorcentajeCantidad}%</b></td>
                                                                <td colSpan="3"><b>{this.state.RankingAnulacionesUsuarioTotales.totalPorcentajePrecio}%</b></td>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                ) : (null)}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                           
                        <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                        <div className="card-header" style={this.Estilos.headerColor}>
                                            <ul className="nav nav-tabs card-header-tabs">
                                                {(this.state.verVentasSuc) ? (
                                                    <li className="nav-item active">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxS(1) }} role="tab" aria-controls="ventasSuc" aria-selected="true" aria-expanded="true">Ventas</button>
                                                    </li>
                                                ) : (<li className="nav-item"><button className="nav-link" onClick={() => { this.CambioPestañaGxS(1) }} role="tab" aria-controls="ventasSuc" aria-selected="true">Ventas</button>
                                                </li>
                                                    )}
                                                {(this.state.verTransaccionesSuc) ? (
                                                    <li className="nav-item active">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxS(2) }} role="tab" aria-controls="transaccionesSuc" aria-selected="true" aria-expanded="true">Transacciones</button>
                                                    </li>
                                                ) : (<li className="nav-item ">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGxS(2) }} role="tab" aria-controls="transaccionesSuc" aria-selected="true" >Transacciones</button>
                                                </li>)

                                                }
                                                {(this.state.verDescuentosSuc) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxS(3) }} role="tab" aria-controls="descuentosSuc" aria-selected="true" aria-expanded="true">Descuentos</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGxS(3) }} role="tab" aria-controls="descuentosSuc" aria-selected="true">Descuentos</button>
                                                </li>)

                                                }
                                                {(this.state.verClientesSuc) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxS(4) }} role="tab" aria-controls="clientesSuc" aria-selected="true" aria-expanded="true">Clientes</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                    <button className="nav-link" onClick={() => { this.CambioPestañaGxS(4) }} role="tab" aria-controls="clientesSuc" aria-selected="true">Clientes</button>
                                                </li>)

                                                }
                                                {(this.state.verAnulacionesSuc) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGxS(5) }} role="tab" aria-controls="AnulacionesSuc" aria-selected="true" aria-expanded="true">Anulaciones</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGxS(5) }} role="tab" aria-controls="AnulacionesSuc" aria-selected="true">Anulaciones</button>
                                                </li>)

                                                }
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Gráfico X Sucursal</h5>

                                            <div className="tab-content mt-3">
                                                {(this.state.verVentasSuc) ? (

                                                    <HorizontalBar data={this.state.DataLineChartSuc} options={this.state.OptionsLinechartSuc} />
                                                ) : (null)

                                                }

                                                {(this.state.verTransaccionesSuc) ? (
                                                    <HorizontalBar data={this.state.DataLineChartTransSuc} options={this.state.OptionsLinechartTransSuc} />
                                                ) : (null)

                                                }
                                                {(this.state.verDescuentosSuc) ? (
                                                    <HorizontalBar data={this.state.DataLineChartDsctoSuc} options={this.state.OptionsLinechartDsctoSuc} />

                                                ) : (null)

                                                }
                                                {(this.state.verClientesSuc) ? (
                                                    <HorizontalBar data={this.state.DataLineChartClienteSuc} options={this.state.OptionsLinechartClienteSuc} />

                                                ) : (null)

                                                }
                                                {(this.state.verAnulacionesSuc) ? (
                                                    <HorizontalBar data={this.state.DataLineChartAnulacionesSuc} options={this.state.OptionsLinechartAnulacionesSuc} />

                                                ) : (null)

                                                }


                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                        <div className="card-header" style={this.Estilos.headerColor}>
                                            <ul className="nav nav-tabs card-header-tabs">
                                                {(this.state.verVentasA) ? (
                                                    <li className="nav-item active">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGA(1) }} role="tab" aria-controls="ventas" aria-selected="true" aria-expanded="true">Ventas</button>
                                                    </li>
                                                ) : (<li className="nav-item"><button className="nav-link" onClick={() => { this.CambioPestañaGA(1) }} role="tab" aria-controls="ventas" aria-selected="true">Ventas</button>
                                                </li>
                                                    )}
                                                {(this.state.verTransaccionesA) ? (
                                                    <li className="nav-item active">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGA(2) }} role="tab" aria-controls="transacciones" aria-selected="true" aria-expanded="true">Transacciones</button>
                                                    </li>
                                                ) : (<li className="nav-item ">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGA(2) }} role="tab" aria-controls="transacciones" aria-selected="true" >Transacciones</button>
                                                </li>)

                                                }
                                                {(this.state.verDescuentosA) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGA(3) }} role="tab" aria-controls="descuentos" aria-selected="true" aria-expanded="true">Descuentos</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGA(3) }} role="tab" aria-controls="descuentos" aria-selected="true">Descuentos</button>
                                                </li>)

                                                }
                                                {(this.state.verClientesA) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGA(4) }} role="tab" aria-controls="clientesA" aria-selected="true" aria-expanded="true">Clientes</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                    <button className="nav-link" onClick={() => { this.CambioPestañaGA(4) }} role="tab" aria-controls="clientesA" aria-selected="true">Clientes</button>
                                                </li>)

                                                }
                                                {(this.state.verAnulacionesA) ? (
                                                    <li className="nav-item">
                                                        <button className="nav-link active" style={this.Estilos.MarcarBorde} onClick={() => { this.CambioPestañaGA(5) }} role="tab" aria-controls="AnulacionesA" aria-selected="true" aria-expanded="true">Anulaciones</button>
                                                    </li>

                                                ) : (<li className="nav-item active">
                                                        <button className="nav-link" onClick={() => { this.CambioPestañaGA(5) }} role="tab" aria-controls="AnulacionesA" aria-selected="true">Anulaciones</button>
                                                </li>)

                                                }
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Gráfico Anual</h5>

                                            <div className="tab-content mt-3">
                                                {(this.state.verVentasA) ? (

                                                    <Line data={this.state.DataLineChartA} options={this.state.OptionsLinechartA} />
                                                ) : (null)

                                                }

                                                {(this.state.verTransaccionesA) ? (
                                                    <Line data={this.state.DataLineChartTransA} options={this.state.OptionsLinechartTransA} />
                                                ) : (null)

                                                }
                                                {(this.state.verDescuentosA) ? (
                                                    <Line data={this.state.DataLineChartDsctoA} options={this.state.OptionsLinechartDsctoA} />

                                                ) : (null)

                                                }
                                                {(this.state.verClientesA) ? (
                                                    <Line data={this.state.DataLineChartClientesA} options={this.state.OptionsLinechartClientesA} />

                                                ) : (null)

                                                }
                                                {(this.state.verAnulacionesA) ? (
                                                    <Line data={this.state.DataLineChartAnulacionesA} options={this.state.OptionsLinechartAnulacionesA} />

                                                ) : (null)

                                                }


                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                     </div>
                                      ):(null)
               
               
               
                               }
               
                         
                                        
             </div>
        );
    }

}