import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

import Popup from "reactjs-popup";


export class MantenedorUsuarios extends Component {
    static displayName = MantenedorUsuarios.name;

    StateInicial = {
        usuarios: [],

        //-----estados popup
        activarAgregarUsuario: false,
        activarEditarUsuario: false,
        //------estados popup

        //------estados crear usuario
        tiposUsuarios:[],
        sucursales:[],
        cajas: [],
        cajasFiltradasSucursal: [],

        //------fin estados crear usuario

         //------estados editar usuario
        usuarioSeleccionado: {}
         //------fin estados editar usuario
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerUsuarios();
        
    }
    //-------->INICIO FUNCIONES CRUD
    async TraerUsuarios() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/usuarios/TraerUsuarios?sucursalID=' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ usuarios: datos });
        }
    }

    async CrearUsuario() {
        var usuario = {};
        usuario.Pin = Number( document.getElementById('txtPin').value);
        usuario.Nombre =  document.getElementById('txtnombre').value;
        usuario.Apellido = document.getElementById('txtApellido').value;
        usuario.TipoUsuarioID = Number( document.getElementById('cboTipoUsuario').value);
        usuario.CajaID = Number(document.getElementById('cboCaja').value);
        usuario.SucursalID = Number(document.getElementById('cboSucursal').value);
        usuario.IsHabilitado = true;
        var respuesta = await fetch(GetUrlApi() + '/api/Usuarios' ,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(usuario),
        });
        if (respuesta.ok) {
            swal('Usuario creado con exito').then(() => {
                this.Recargar();
            }); 
        }
    }

    async TraerOpcionesCrear() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/usuarios/TraerOpcionesCrearUsuario', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({
                tiposUsuarios: datos.tiposUsuario,
                sucursales: datos.sucursales,
                cajas: datos.cajas,
                cajasFiltradasSucursal: datos.cajas
            });
            this.CambioSucursal();
        }
    }
    //-------->FIN FUNCIONES CRUD
    
    //-------->INICIO FUNCIONES ONCHANGE
    CambioSucursal() {
        const sucursalSeleccionada = document.getElementById('cboSucursal').value;
        var nuevasCajas = this.state.cajas.filter(p => p.sucursalID === Number(sucursalSeleccionada));
        console.log(nuevasCajas);
        this.setState({ cajasFiltradasSucursal: nuevasCajas });
    }
    //-------->FIN FUNCIONES ONCHANGE
    Estilos= {
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
           'margin-left': '3%'
        },
        Space:
        {
         'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px'
        }
    }

    GetHtmlPopupCrearUser() {

            return (
                <Popup
                    contentStyle={this.Estilos.StylePopUp}
                    defaultOpen
                    modal
                    //contentStyle={}
                    onClose={() => { this.setState({ activarAgregarUsuario: false })}}
                    closeOnDocumentClick>
                    <center>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Pin</td>
                                    <td>
                                        <input className="form-control" id="txtPin" type="number"  />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre</td>
                                    <td>
                                        <input className="form-control" id="txtnombre" type="text" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Apellido</td>
                                    <td>
                                        <input className="form-control" id="txtApellido" type="text" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>Tipo usuario</td>
                                    <td>
                                        <select id="cboTipoUsuario" className="form-control">
                                            {this.state.tiposUsuarios.map((item, i) => {
                                                return (<option  value={item.id}>{item.descripcion}</option>);
                                            })}
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Caja</td>
                                    <td>
                                        <select id="cboCaja" className="form-control">
                                            {this.state.cajasFiltradasSucursal.map((item, i) => {
                                                return (<option value={item.id}>{item.descripcion}</option>);
                                            })}
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Sucursal</td>
                                    <td>
                                        <select id="cboSucursal" onChange={() => { this.CambioSucursal() }}  className="form-control">
                                            {this.state.sucursales.map((item, i) => {
                                                return (<option value={item.id}>{item.descripcion}</option>);
                                            })}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button onClick={() => { this.CrearUsuario() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                            <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                    </center>

                </Popup>
            );
    }

    GetHtmlPopupEditarUser() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.setState({ activarEditarUsuario: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Editar Usuario</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Pin</td>
                                <td>
                                    <input className="form-control" id="txtPin" type="text" defaultValue={this.state.usuarioSeleccionado.pin} />
                                </td>
                            </tr>
                            <tr>
                                <td>Nombre</td>
                                <td>
                                    <input className="form-control" id="txtnombre" defaultValue={this.state.usuarioSeleccionado.nombre} type="text" />
                                </td>
                            </tr>
                            <tr>
                                <td>Apellido</td>
                                <td>
                                    <input defaultValue={this.state.usuarioSeleccionado.apellido} className="form-control" id="txtApellido" type="text" />
                                </td>
                            </tr>

                            <tr>
                                <td>Tipo usuario</td>
                                <td>
                                    <select id="cboTipoUsuario" className="form-control">
                                        {this.state.tiposUsuarios.map((item, i) => {
                                            const isSelected = item.id === this.state.usuarioSeleccionado.tipoUsuarioID;
                                            return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                        })}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>Caja</td>
                                <td>
                                    <select id="cboCaja" className="form-control">
                                        {this.state.cajasFiltradasSucursal.map((item, i) => {
                                            const isSeleccionar = item.id === this.state.usuarioSeleccionado.cajaID;
                                            return (<option selected={isSeleccionar} value={item.id}>{item.descripcion}</option>);
                                        })}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>Sucursal</td>
                                <td>
                                    <select id="cboSucursal" onChange={() => { this.CambioSucursal() }} className="form-control">
                                        {this.state.sucursales.map((item, i) => {
                                            const isSeleccionar = item.id === this.state.usuarioSeleccionado.sucursalID;
                                            return (<option selected={isSeleccionar} value={item.id}>{item.descripcion}</option>);
                                        })}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    //------->inicio onclick
    ClickAgregarUsuario() {
        this.setState({ activarAgregarUsuario:true });
    }

    ClickEditarUsuario(usuario) {
        this.setState({ usuarioSeleccionado: usuario, activarEditarUsuario:true });
    }

    async ClickListoEditar() {
        var usuario = this.state.usuarioSeleccionado;
        usuario.Pin = Number(document.getElementById('txtPin').value);
        usuario.Nombre = document.getElementById('txtnombre').value;
        usuario.Apellido = document.getElementById('txtApellido').value;
        usuario.TipoUsuarioID = Number(document.getElementById('cboTipoUsuario').value);
        usuario.CajaID = Number(document.getElementById('cboCaja').value);
        usuario.SucursalID = Number(document.getElementById('cboSucursal').value);
        var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + usuario.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(usuario),
        });
        if (respuesta.ok) {
            swal('Usuario editado con exito').then(() => {
                this.Recargar();
            });
        }
    }

    async ClickInhabilitarUsuario(usuarioID) {
        swal({
            title: "¿Está seguro que desea dehabilitar este usuario?",
            text: "Si confirma el usuario no podra volver a iniciar sesion",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Usuario deshabilitado", {
                    icon: "success",
                }).then(async () => {
                    var respuesta = await fetch(GetUrlApi() + '/api/usuarios/' + usuarioID, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        this.Recargar();
                    }
                });
            } 
        });
    }
    //------->fin onclick


    Recargar() {
        this.setState(this.StateInicial);
        this.TraerUsuarios();
    }

        render() {
            return (
                <div  >
                    {
                        (this.state.activarAgregarUsuario) ? (this.GetHtmlPopupCrearUser()) : (null)
                    }
                    {
                        (this.state.activarEditarUsuario) ? (this.GetHtmlPopupEditarUser()) : (null)
                    }
                    <div className="alert alert-info" role="alert">
                        <strong> Usuarios</strong>

                    </div>

                    <button onClick={() => {
                        this.ClickAgregarUsuario();
                            this.TraerOpcionesCrear()
                    }} type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                        <i style={this.Estilos.Space} class="fa fa-plus-circle" aria-hidden="true"></i>
                         Agregar usuario
                        </button>

                    
                    <table className="table" style={this.Estilos.TableStyle}>
                        <thead>
                            <tr>
                                <td><b>PIN</b></td>
                                <td><b>Nombre Completo</b></td>
                                <td><b>Tipo de usuario</b></td>
                                <td><b>Caja</b></td>
                                <td><b>Sucursal</b></td>
                                <td><b>Acciones</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.usuarios.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.pin}</td>
                                            <td>{item.nombre} {item.apellido}</td>
                                            <td>{item.tipoUsuario.descripcion}</td>
                                            <td>{item.caja.descripcion}</td>
                                            <td>{item.sucursal.descripcion}</td>
                                            <td>
                                                <button style={this.Estilos.BtnOpt} onClick={() => { this.ClickEditarUsuario(item); this.TraerOpcionesCrear() }} data-toggle="tooltip" data-placement="right" title="Editar" >
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                                <button style={this.Estilos.BtnOpt1}  onClick={() => { this.ClickInhabilitarUsuario(item.id)  }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                    <i class="fa fa-trash " aria-hidden="true"></i></button>
                                                
                                            </td>
                                        </tr>
                                        );
                                })
                            }
                        </tbody>
                    </table>
                    
                </div>
            );
        }


    }
