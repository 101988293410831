import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';
import Popup from "reactjs-popup";
import { PopupAgregarProducto } from './MantenedorProductos/PopupAgregarProducto';

export class MantenedorProductosDos extends Component {
    static displayName = MantenedorProductosDos.name;

    AgregarSubproducto = false;
    StateInicial = {
        activarAgregarProducto: false,
        activarEditarProducto: false,
        activarAgregarCategoria: false,
        categoriasBotones: [],
        cargandoCategorias: true,
        grupos: [],
        productosBotones: [],
        productosSeleccionados: [],
        //---->inicio estados crud categ 
        categoriaSeleccionada: {},
        sucursales: [],
        //----> fin estados crud categ 

        //---->inicio estados crud producto 
        productoClickeado: {},
        zonasImpresion: [],
        defaultIsPesableAgregar: true,
        isCrearProducto: true,
        ProductosSeleccionadosNivSup: [],
        todosSelected: false,
        //----> fin estados crud producto
        isNivelSuperiorSelected: false,
        isStockHabilitado: true,
        agregarNuevoStock: false,
        volverAlIncio: false,
        grupoOriginal: 0
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerCategorias();
        this.TraerSucursales();
        this.TraerZonasImpresion();

        this.CerrarModalMaster = this.CerrarModalMaster.bind(this);
        this.Recargar = this.Recargar.bind(this);
        this.ResetAgregarSubProd = this.ResetAgregarSubProd.bind(this);
        this.TraerGrupos();
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.id === 0) {
            Logout();
            this.setState({ volverAlIncio: true });
        }
    }

    //-------->INICIO FUNCIONES CRUD
    async TraerProductos() {
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ productos: datos });
        }
    }


    async TraerCategorias() {
        try {
            var SucursalID = GetUserCookie().SucursalID;
            const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);

            const datos = await categorias.json();

            this.setState({ categoriasBotones: datos, cargandoCategorias: false });

        } catch (err) {

        }
    }

    async TraerGrupos() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Grupos?sucursalID=' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ grupos: datos });
        }
    }


    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Sucursals', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ sucursales: datos });
        }
    }

    async TraerZonasImpresion() {
        var respuesta = await fetch(GetUrlApi() + '/api/ZonaImpresions', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ zonasImpresion: datos });
        }
    }

    async CrearProducto() {
        var producto = {};
        producto.desprod = document.getElementById('txtDescripcionProd').value;
        producto.DescripcionWeb = document.getElementById('txtDescripcionProdWeb').value;
        if (String(producto.desprod) === '') {
            swal('El nombre del producto no puede estar en blanco', {
                icon: "warning",
            });
            return;
        }
        if (document.getElementById('txtPrecioProd')) {
            producto.Precpred = Number(document.getElementById('txtPrecioProd').value);
        }
        else {
            producto.Precpred = 0;
        }
        if (!this.AgregarSubproducto) {
            producto.tbGrupoID = this.state.categoriaSeleccionada.id;
            if (document.getElementById('isNivelSuperior')) {
                producto.isNivelSuperior = document.getElementById('isNivelSuperior').checked;
            }
        }
        else {
            producto.tbProductoID = this.state.productoClickeado.id;
        }

        producto.sucursalID = Number(document.getElementById('cboSucursalProd').value);
        producto.plu = document.getElementById('txtPlu').value;

        producto.Prodsimil = 0;
        if (document.getElementById('txtPrecioProdMesa')) {
            producto.precmesa = Number(document.getElementById('txtPrecioProdMesa').value);
        }
        else {
            producto.precmesa = 0;
        }

        if (document.getElementById('txtPrecioProdBar')) {
            producto.precbar = Number(document.getElementById('txtPrecioProdBar').value);
        }
        else {
            producto.precbar = 0;
        }
        producto.precllevar = 0;
        producto.precpedido = 0;
        if (document.getElementById('txtPrecioProdDelivery')) {
            producto.precdeliv = Number(document.getElementById('txtPrecioProdDelivery').value);
        }
        else {
            producto.precdeliv = 0;
        }

        producto.Precpedido = 0;

        producto.Permdesct = 0;
        producto.Porcdesct = 0;
        producto.Tipomodif = 0;
        producto.Nivlsuper = 0;
        producto.Nivlsupgr1 = 0;
        producto.Codimpres = 0;
        producto.Estprod = 0;
        producto.Vtacad = 0;
        producto.Codmod = 0;
        producto.Prodrec = 0;
        producto.IsCombo = 0;
        if (GetUserCookie().Sucursal.configPOS.balanzaApp) {
            producto.IsPesable = document.getElementById('ClickChkAddProd').checked;
            producto.PrecioKg = Number(document.getElementById('precioKilo').value);
        }
        else {
            producto.IsPesable = false;
            producto.PrecioKg = 0;
        }


        producto.Disponible = 0;
        producto.Impuesto = 0;
        producto.ImpuestoCant = 0;
        if (document.getElementById('txtCodBarra')) {
            producto.Codbarra = document.getElementById('txtCodBarra').value;
        }

        producto.isHabilitado = true;
        try {

        } catch (err) {

        }
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });


        if (respuesta.ok) {
            const resp = await respuesta.json();
            console.log('el mensaje de respuesta es ' + resp.mensaje);
            if (resp.mensaje) {
                swal(resp.mensaje, {
                    icon: "danger",
                })
                return;
            }
            swal('Producto creado con exito', {
                icon: "warning",
            }).then(() => {
                this.Recargar();
            });
        }

        this.AgregarSubproducto = false;
    }

    async TraerProductosPorCategoria() {
        var idCategoria = document.getElementById('cboCateg').value;
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
        if (categorias.ok) {
            var datos = await categorias.json();
            this.setState({ ProductosSeleccionadosNivSup: datos, todosSelected: false });
        }

    }

    async CrearSubProducto() {
        var producto = {};
        producto.desprod = document.getElementById('txtDescripcionProd').value;
        producto.DescripcionWeb = document.getElementById('txtDescripcionProdWeb').value;

        if (document.getElementById('txtPrecioProd')) {
            producto.Precpred = Number(document.getElementById('txtPrecioProd').value);
        }
        else {
            producto.Precpred = 0;
        }

        //producto.tbGrupoID = this.state.categoriaSeleccionada.id;
        producto.sucursalID = Number(document.getElementById('cboSucursalProd').value);
        producto.plu = document.getElementById('txtPlu').value;

        producto.Prodsimil = 0;
        producto.Precmesa = 0;
        producto.Precbar = 0;
        producto.Precllevar = 0;
        producto.Precpedido = 0;
        producto.Precdeliv = 0;
        producto.Permdesct = 0;
        producto.Porcdesct = 0;
        producto.Tipomodif = 0;
        producto.Nivlsuper = 0;
        producto.Nivlsupgr1 = 0;
        producto.Codimpres = 0;
        producto.Estprod = 0;
        producto.Vtacad = 0;
        producto.Codmod = 0;
        producto.Prodrec = 0;
        producto.IsCombo = 0;
        if (GetUserCookie().Sucursal.configPOS.balanzaApp) {
            producto.IsPesable = document.getElementById('ClickChkAddProd').checked;
            producto.PrecioKg = Number(document.getElementById('precioKilo').value);
        }
        else {
            producto.IsPesable = false;
            producto.PrecioKg = 0;
        }


        producto.Disponible = 0;
        producto.Impuesto = 0;
        producto.ImpuestoCant = 0;
        if (document.getElementById('txtCodBarra')) {
            producto.Codbarra = document.getElementById('txtCodBarra').value;
        }

        producto.isHabilitado = true;
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            swal('Producto creado con exito', {
                icon: "warning",
            }).then(() => {
                this.RecargarProducto();
            });
        }
    }


    //-------->FIN FUNCIONES CRUD

    //-------->INICIO FUNCIONES ONCHANGE

    //-------->FIN FUNCIONES ONCHANGE
    Estilos = {
        Columna1: {
            'height': '600px',
            'overflowY': 'scroll',
            'resize': 'both',
            'overflow': 'auto'
        },
        DivCentralTest: {
            'width': '50%',
            'float': 'left',
            'padding': '10px',
            //'height': '100%'
        },
        MarginLeftCR:
        {
            'marginLeft': '-2.5%'
        },
        BotoneraCategoria:
        {
            'width': '50%',
            'height': '60px',
            'borderRadius': '15px',
            'paddingTop': '10px',
            'paddingRight': '20px',
            'paddingBottom': '10px',
            'overflow': 'hidden',
            'outline': 'none',
            'borderColor': 'grey',
            'fontSize': '80%',
            'text-align': 'center'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'marginTop': '0.5%',
            'marginRight': '1%',
            'marginLeft': '2%'
        },
        BtnSalir: {
            'height': '44px',
            'width': '50px',
            'border-color': 'rgb(110, 152, 135)',
            'border-radius': '10%'
        },
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        BotoneraProductos: {
            'width': '32%',
            'height': '70px',
            'color': 'black',
            'border-color': '#bfbfc0',
            'margin-bottom': '1%',
            'font-size': '85%',
            'margin-right': '0.25%'
        },
    }


    //------->inicio onclick
    async ClickCategoria(idCategoria, idBtn, categoria) {
        var botones = document.getElementsByName('botonesCategorias');
        botones.forEach((value) => {
            value.style.backgroundColor = '';
            value.style.color = '';
        });
        document.getElementById(idBtn).style.backgroundColor = '#6e9887';
        document.getElementById(idBtn).style.color = '#fff';
        await this.setState({ cargandoProductos: true, grupoOriginal: idCategoria });
        try {
            var SucursalID = GetUserCookie().SucursalID;
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            var datos = await categorias.json();

            //#RFD.26112019 Optimiza filtro en API  
            //datos = datos.filter(datos => datos.codgrup === idCategoria);

            this.setState({ productosSeleccionados: datos, cargandoProductos: false, activarEditarProducto: false, categoriaSeleccionada: categoria, productoClickeado: {} });
        } catch (err) {

        }
    }

    async ClickCategoria2(idCategoria) {
        var categoria = this.state.categoriasBotones.find(p => p.id === idCategoria);
        await this.setState({ cargandoProductos: true, activarAgregarProducto: false, activarEditarProducto: false });
        try {
            var SucursalID = GetUserCookie().SucursalID;
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            var datos = await categorias.json();
            //#RFD.26112019 Optimiza filtro en API  
            //datos = datos.filter(datos => datos.codgrup === idCategoria);

            this.setState({ productosSeleccionados: datos, cargandoProductos: false, activarEditarProducto: false, categoriaSeleccionada: categoria, productoClickeado: {} });
        } catch (err) {

        }
    }

    async ClickProducto(producto) {
        await this.setState({ activarEditarProducto: true, productoClickeado: producto });
        console.log(producto);
        document.getElementById('txtDescripcionProd').value = producto.desprod;
        document.getElementById('txtDescripcionProdWeb').value = producto.descripcionWeb;
        if (document.getElementById('txtPrecioProd')) {
            document.getElementById('txtPrecioProd').value = producto.precpred;
        }

        //document.getElementById('txtPlu').value = producto.plu;
        if (document.getElementById('chkPesable')) {
            document.getElementById('chkPesable').checked = producto.isPesable;
        }


        if (producto.codbarra && document.getElementById('txtCodBarra')) {
            document.getElementById('txtCodBarra').value = producto.codbarra;
        }

        if (producto.isPesable) {
            if (document.getElementById('precioKilo')) {
                document.getElementById('precioKilo').value = producto.precioKg;
            }
        }
        if (GetUserCookie().Sucursal.configPOS.isPrecioPorZona) {
            if (document.getElementById('precioKilo')) {
                document.getElementById('precioKilo').value = producto.precioKg;
            }
            if (document.getElementById('txtPrecioProdMesa')) {
                document.getElementById('txtPrecioProdMesa').value = producto.precmesa;
            }
            if (document.getElementById('txtPrecioProdBar')) {
                document.getElementById('txtPrecioProdBar').value = producto.precbar;
            }
            if (document.getElementById('txtPrecioProdDelivery')) {
                document.getElementById('txtPrecioProdDelivery').value = producto.precdeliv;
            }
            if (document.getElementById('precioKiloMesas')) {
                document.getElementById('precioKiloMesas').value = producto.precioKgMesa;
            }
            if (document.getElementById('precioKiloBar')) {
                document.getElementById('precioKiloBar').value = producto.precioKgBar;
            }
            if (document.getElementById('precioKiloDelivery')) {
                document.getElementById('precioKiloDelivery').value = producto.precioKgDelivery;
            }
            if (document.getElementById('precioKiloDelivery')) {
                document.getElementById('precioKiloDelivery').value = producto.precioKgDelivery;
            }
        }
        if (GetUserCookie().Sucursal.configPOS.isStockHabilitado) {
            if (document.getElementById('txtStockActual')) {
                document.getElementById('txtStockActual').value = producto.stock;
            }
        }

        if (GetUserCookie().Sucursal.configPOS.isProdDisponibleHabilitado) {
            if (document.getElementById('isDisponible')) {
                document.getElementById('isDisponible').checked = producto.disponible;
            }
        }
    }

    async GetStockProducto(productoID) {
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockProducto?id=' + productoID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            const prod = await respuesta.json();
            return prod.res;
        }
        else {
            return 'Error al traer stock';
        }
    }

    ClickAgregarProducto() {
        this.setState({ activarAgregarProducto: true, activarEditarProducto: false });
    }

    AlertaCambioCategoria() {
        console.log(this.state.categoriasBotones);
        let Datos = this.state.categoriasBotones;
        let categoriaOriginal = this.state.grupoOriginal;
        let catSelected = Number(document.getElementById('cboCategoriaProd').value);
        if (categoriaOriginal != catSelected) {

            let catoriginal = "";
            let catNueva = "";
            Datos.forEach(function (index) {
                if (index.id == categoriaOriginal) {
                    catoriginal = index.desgrup;
                } else if (index.id == catSelected) {
                    catNueva = index.desgrup;
                }
            })
            let msje = "El producto se movera desde la categoria " + catoriginal + " a la categoria " + catNueva;
            swal({
                icon: 'info',
                text: msje,
                confirmButtonText: 'OK'
            })

        }

    }



    async ClickEditarProducto() {
        var producto = this.state.productoClickeado;
        producto.Desprod = document.getElementById('txtDescripcionProd').value;
        producto.DescripcionWeb = document.getElementById('txtDescripcionProdWeb').value;
        producto.Prodsimil = 0;
        if (document.getElementById('txtPrecioProd')) {
            producto.Precpred = document.getElementById('txtPrecioProd').value;
        }
        if (document.getElementById('cboGrupoProd')) {
            producto.grupoID = document.getElementById('cboGrupoProd').value;
            if (producto.grupoID == '-1') {
                producto.grupoID = null;
            }
        }
        if (document.getElementById('txtPrecioProdMesa')) {
            producto.precmesa = Number(document.getElementById('txtPrecioProdMesa').value);
        }
        else {
            producto.precmesa = 0;
        }

        if (document.getElementById('txtPrecioProdBar')) {
            producto.precbar = Number(document.getElementById('txtPrecioProdBar').value);
        }
        else {
            producto.precbar = 0;
        }
        producto.precllevar = 0;
        producto.precpedido = 0;
        if (document.getElementById('txtPrecioProdDelivery')) {
            producto.precdeliv = Number(document.getElementById('txtPrecioProdDelivery').value);
        }
        else {
            producto.precdeliv = 0;
        }
        producto.Precpedido = 0;

        producto.Permdesct = 0;
        producto.Porcdesct = 0;
        producto.Tipomodif = 0;
        producto.Nivlsuper = 0;
        producto.Nivlsupgr1 = 0;
        producto.Codimpres = 0;
        producto.Estprod = 0;
        producto.Vtacad = 0;
        producto.Codmod = 0;
        if (document.getElementById('txtCodBarra')) {
            producto.Codbarra = document.getElementById('txtCodBarra').value;
        }
        producto.Prodrec = 0;
        producto.IsCombo = false;
        let catSelected = Number(document.getElementById('cboCategoriaProd').value);
        if (this.state.grupoOriginal != catSelected) {

            producto.tbGrupoID = Number(document.getElementById('cboCategoriaProd').value);
        }
        if (GetUserCookie().Sucursal.configPOS.balanzaApp) {
            if (document.getElementById('chkPesable')) {
                producto.IsPesable = document.getElementById('chkPesable').checked;
                producto.PrecioKg = Number(document.getElementById('precioKilo').value);
                if (document.getElementById('precioKiloMesas')) {
                    producto.PrecioKgMesa = Number(document.getElementById('precioKiloMesas').value);
                }
                if (document.getElementById('precioKiloBar')) {
                    producto.PrecioKgBar = Number(document.getElementById('precioKiloBar').value);
                }
                if (document.getElementById('precioKiloDelivery')) {
                    producto.PrecioKgDelivery = Number(document.getElementById('precioKiloDelivery').value);
                }

            }
            else {
                producto.IsPesable = false;
                producto.PrecioKg = 0;
            }


        }
        else {
            producto.IsPesable = false
            producto.PrecioKg = 0;
        }

        if (document.getElementById('isDisponible')) {
            producto.Disponible = document.getElementById('isDisponible').checked;
        }
        else {
            producto.Disponible = true;
        }

        producto.Impuesto = false;
        producto.ImpuestoCant = null;
        //producto.PLU = document.getElementById('txtPlu').value;
        producto.PLU = 0;
        if (document.getElementById('cboZonaImpresion')) {
            producto.ZonaImpresionID = Number(document.getElementById('cboZonaImpresion').value);
        }
        else {
            producto.ZonaImpresionID = null;
        }

        producto.sucursalID = Number(document.getElementById('cboSucursalProd').value);

        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/' + producto.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            swal('Producto actualizado con exito', {
                icon: "success",
            });
            this.RecargarProducto();
        }
    }

    async ClickEliminarProducto(prod) {


    }

    ClickChkAddProd() {
        if (this.state.defaultIsPesableAgregar) {
            this.setState({ defaultIsPesableAgregar: false })
        } else {
            this.setState({ defaultIsPesableAgregar: true })
        }

    }

    RevertirPrecioKiloEditar() {

        var valActual = this.state.productoClickeado;
        if (valActual.isPesable) {
            valActual.isPesable = false;
        }
        else {
            valActual.isPesable = true;
        }
        this.setState({ productoClickeado: valActual });
    }

    //------->fin onclick


    async Recargar() {
        this.setState(this.StateInicial);
        this.TraerCategorias();
        this.TraerSucursales();
        this.TraerZonasImpresion();
        this.TraerGrupos();
    }

    async RecargarProducto() {
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + this.state.productoClickeado.tbgrupoID + "&SucursalID=" + SucursalID);
        if (!categorias.ok) {
            throw 'problema al traer las categorias';
        }
        var datos = await categorias.json();
        const newProd = datos.find(p => p.id === this.state.productoClickeado.id);
        if (!newProd) {
            this.Recargar();
            return;
        }
        await this.setState({ productoClickeado: {}, activarEditarProducto: false });
        await this.setState({ productoClickeado: newProd, activarEditarProducto: true, productosSeleccionados: datos, activarAgregarProducto: false });
        await this.ClickProducto(newProd);
    }

    async RecargarCategoria() {
        var categoriaSeleccionada = this.state.categoriaSeleccionada;
        // this.Recargar();
        this.TraerCategorias();
        //this.TraerProductosPorCategoria();
        this.ClickCategoria2(categoriaSeleccionada.id);

    }



    ClickCheckNivelSuper() {
        if (!this.state.isNivelSuperiorSelected) {
            this.setState({ isNivelSuperiorSelected: true });
        }
        else {
            this.setState({ isNivelSuperiorSelected: false });
        }
    }

    ClickAgregarSubProd() {
        this.AgregarSubproducto = true;
        this.setState({ activarAgregarProducto: true });
    }

    async ClickEditarSubProd(item) {
        if (item.productosInferiores === null) {
            item.productosInferiores = [];
        }
        await this.setState({ activarEditarProducto: false });
        await this.setState({ productoClickeado: item });
        await this.setState({ activarEditarProducto: true });
    }

    ClickCambioIsCrearProducto() {
        if (this.state.isCrearProducto) {
            this.setState({ isCrearProducto: false });
        } else {
            this.setState({ isCrearProducto: true });
        }

    }

    ClickTodos() {
        var idCategoria = document.getElementById('cboProd').value;
        if (idCategoria === '0') {//si no es un elemento traido de bd
            this.setState({ todosSelected: true });
        }
        else {
            this.setState({ todosSelected: false });
        }

    }

    ResetAgregarSubProd() {
        this.AgregarSubproducto = false;
    }

    CerrarModalMaster() {
        this.setState({ activarAgregarProducto: false });
    }

    async ClickEliminarSubProducto(prod) {
        if (prod.productosInferiores === null) {
            prod.productosInferiores = [];
        }
        var modifsToDelete = document.getElementsByName('chkBorrarProd');
        modifsToDelete = Array.from(modifsToDelete);
        modifsToDelete = modifsToDelete.filter(p => p.checked);
        if (modifsToDelete.length > 1) {
            swal({
                title: "¿Está seguro que desea dehabilitar los productos seleccionados?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    this.ClickBorradoMultiple().then(() => {
                        this.RecargarCategoria();
                    });
                }

            });
        }
        else {
            await this.setState({ productoClickeado: prod });
            //if (this.IsProductoNivSuper(prod.id)) {

            //    return;
            //}
            swal({
                title: "¿Está seguro que desea dehabilitar este producto?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    const idSeleccionado = this.state.productoClickeado.id;
                    var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/' + idSeleccionado, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        swal('Producto dehabilitado con exito', {
                            icon: "success",
                        });
                        this.RecargarCategoria();
                    }
                    else {
                        const mensaje = await respuesta.json().mensaje;
                        swal(mensaje, {
                            icon: "success",
                        });
                    }
                }
            });
        }
    }

    async IsProductoNivSuper(productoID) {
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/' + productoID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            const prod = await respuesta.json();
            return prod.isNivelSuperior;
        }
        else {
            return false;
        }
    }

    async ClickBorradoMultiple() {
        var modifsToDelete = document.getElementsByName('chkBorrarProd');
        modifsToDelete = Array.from(modifsToDelete);
        modifsToDelete = modifsToDelete.filter(p => p.checked);

        for (var i = 0; i < modifsToDelete.length; i++) {
            var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/' + modifsToDelete[i].value, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        }

    }

    ClickSeleccionarTodos() {
        var chkPrincipal = document.getElementById('chkSeleccionarTodos');
        var prodsToDelete = document.getElementsByName('chkBorrarProd');
        if (chkPrincipal.checked) {
            prodsToDelete.forEach((item) => {
                item.checked = true;
            });
        } else {
            prodsToDelete.forEach((item) => {
                item.checked = false;
            });
        }

    }

    ClickMasCrearProducto() {
        this.AgregarSubproducto = false;
        this.setState({ activarAgregarProducto: true, activarEditarProducto: false, productoClickeado: {} });

    }

    ClickCkeckEditarProdNs() {
        var producto = this.state.productoClickeado;
        if (producto.isNivelSuperior) {
            // producto.isNivelSuperior = false;
            return;
        } else {
            producto.isNivelSuperior = true;
        }
        this.setState({ productoClickeado: producto });
    }

    async ClickAgregarStock() {
        const montoAgregar = document.getElementById('txtAgregarStock').value;
        const productoID = this.state.productoClickeado.id;
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/AgregarStock?productoID=' + productoID + '&montoAgregar=' + montoAgregar, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            //body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            swal('Stock Agregado con éxito', {
                icon: "success",
            }).then(async () => {
                await this.setState({ agregarNuevoStock: false });
                document.getElementById('txtStockActual').value = await this.GetStockProducto(productoID);
            });
        }
        else {

        }
    }

    render() {
        return (
            <div className="row">
                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                <div style={this.Estilos.Columna1} className="column left">
                    {
                        (this.state.activarAgregarProducto) ? (
                            <PopupAgregarProducto state={this.state}
                                CerrarModalMaster={this.CerrarModalMaster}
                                Recargar={this.Recargar}
                                AgregarSubproducto={this.AgregarSubproducto}
                                ResetAgregarSubProd={this.ResetAgregarSubProd}
                                TraerProductosPorCategoria={this.TraerProductosPorCategoria}
                                RecargarProducto={this.RecargarProducto.bind(this)}
                                RecargarCategoria={this.RecargarCategoria.bind(this)}
                            />
                        ) : (null)
                    }
                    {
                        (this.state.cargandoCategorias) ? (
                            <div className="spinner-border text-dark" role="status">
                                <span className="sr-only"></span>
                            </div>
                        ) : (null)
                    }
                    {
                        this.state.categoriasBotones.map((item, i) => {
                            const prefijo = 'categorias';
                            return (
                                <button name="botonesCategorias"
                                    className="btn"
                                    id={prefijo + i}
                                    style={this.Estilos.BotoneraCategoria} onClick={() => { this.ClickCategoria(item.id, prefijo + i, item) }} key={prefijo + i}><strong> {item.desgrup}</strong> </button>
                            );
                        })
                    }

                </div>
                <div id="divCentral" style={this.Estilos.DivCentralTest}>
                    {
                        this.state.productosSeleccionados.map((item, i) => {
                            return <button className="btn " style={this.Estilos.BotoneraProductos}
                                onClick={() => { this.ClickProducto(item) }}
                                key={i}> {item.desprod} </button>
                        })

                    }
                    {
                        (this.state.categoriaSeleccionada.id > 0) ? (
                            <button onClick={() => { this.ClickMasCrearProducto() }} name="botonesCategorias"
                                className="btn"
                                style={this.Estilos.BotoneraProductos}>
                                <strong>+</strong>
                            </button>
                        ) : (null)
                    }


                </div>
                <div className="column right" style={this.Estilos.MarginLeftCR}>
                    {
                        (this.state.activarEditarProducto) ? (
                            <center>
                                <h3>{this.state.productoClickeado.desprod}</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Descripcion</td>
                                            <td>
                                                <input className="form-control" id="txtDescripcionProd" type="text" defaultValue={this.state.productoClickeado.desprod} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Descripcion Web</td>
                                            <td>
                                                <input className="form-control" id="txtDescripcionProdWeb" type="text" defaultValue={this.state.productoClickeado.descripcionWeb} />
                                            </td>
                                        </tr>
                                        {
                                            (!this.state.productoClickeado.isNivelSuperior) ? (
                                                <tr>
                                                    <td>Precio para llevar</td>
                                                    <td>
                                                        <input className="form-control" id="txtPrecioProd" type="number" defaultValue={this.state.productoClickeado.precpred} />
                                                    </td>
                                                </tr>
                                            ) : (null)
                                        }
                                        {
                                            (GetUserCookie().Sucursal.configPOS.isPrecioPorZona && !this.state.productoClickeado.isNivelSuperior) ? (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>Precio Mesa</td>
                                                        <td>
                                                            <input className="form-control" id="txtPrecioProdMesa" type="number" defaultValue={this.state.productoClickeado.precmesa} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Precio Bar</td>
                                                        <td>
                                                            <input className="form-control" id="txtPrecioProdBar" type="number" defaultValue={this.state.productoClickeado.precbar} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Precio Delivery</td>
                                                        <td>
                                                            <input className="form-control" id="txtPrecioProdDelivery" type="number" defaultValue={this.state.productoClickeado.precdeliv} />
                                                        </td>
                                                    </tr>
                                                </React.Fragment>

                                            ) : (null)
                                        }

                                        {
                                            (GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (
                                                <tr>
                                                    <td>Grupo</td>
                                                    <td>
                                                        <select className="form-control" id="cboGrupoProd">
                                                            <option value="-1">--</option>
                                                            {
                                                                this.state.grupos.map((grupo) => {
                                                                    const isSelected = grupo.id === this.state.productoClickeado.grupoID;
                                                                    return (<option selected={isSelected} value={grupo.id}>{grupo.descripcion}</option>);
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            ) : (null)
                                        }

                                        <tr>
                                            <td>Sucursal</td>
                                            <td>
                                                <select id="cboSucursalProd" className="form-control">
                                                    {this.state.sucursales.map((item, i) => {
                                                        const isSelected = this.state.productoClickeado.sucursalID === item.id;
                                                        return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                                    })}
                                                </select>
                                            </td>
                                        </tr>

                                        {
                                            (GetUserCookie().Sucursal.configPOS.isZonaImpresionHabilitado && !this.state.productoClickeado.isNivelSuperior) ? (
                                                <tr>
                                                    <td>Impresora</td>
                                                    <td>
                                                        <select id="cboZonaImpresion" className="form-control">
                                                            {this.state.zonasImpresion.map((item, i) => {
                                                                const isSelected = this.state.productoClickeado.zonaImpresionID === item.id;
                                                                return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                            ) : (null)
                                        }


                                        <tr>
                                            <td>Categoria</td>
                                            <td>
                                                <select id="cboCategoriaProd" className="form-control" onChange={() => { this.AlertaCambioCategoria() }}>
                                                    {
                                                        this.state.categoriasBotones.map((item, i) => {
                                                            const isSelected = this.state.productoClickeado.tbgrupoID === item.id;

                                                            return (<option selected={isSelected} value={item.id}>{item.desgrup}</option>);
                                                        })}
                                                </select>
                                            </td>
                                        </tr>


                                        {
                                            (GetUserCookie().Sucursal.configPOS.balanzaApp && !this.state.productoClickeado.isNivelSuperior) ? (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>¿Es pesable?</td>
                                                        <td>
                                                            <input onClick={() => { this.RevertirPrecioKiloEditar() }} id="chkPesable" type="checkbox" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Precio por kilo</td>
                                                        <td>
                                                            <input disabled={!this.state.productoClickeado.isPesable} id="precioKilo" type="number" className="form-control" />
                                                        </td>
                                                    </tr>
                                                    {
                                                        (false) ? (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td>Precio por kilo mesas</td>
                                                                    <td>
                                                                        <input disabled={!this.state.productoClickeado.isPesable} id="precioKiloMesas" type="number" className="form-control" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Precio por kilo bar</td>
                                                                    <td>
                                                                        <input disabled={!this.state.productoClickeado.isPesable} id="precioKiloBar" type="number" className="form-control" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Precio por kilo delivery</td>
                                                                    <td>
                                                                        <input disabled={!this.state.productoClickeado.isPesable} id="precioKiloDelivery" type="number" className="form-control" />
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ) : (null)
                                                    }
                                                </React.Fragment>
                                            ) : (null)
                                        }

                                        {
                                            (GetUserCookie().Sucursal.configPOS.isStockHabilitado) ? (
                                                <React.Fragment>
                                                    {
                                                        (!this.state.agregarNuevoStock) ? (//muestra el stock actual
                                                            <tr>
                                                                <td>Stock actual</td>
                                                                <td> <input disabled={this.state.isStockHabilitado} id="txtStockActual" type="text" className="form-control" /> </td>
                                                                <td> <button onClick={() => { this.setState({ agregarNuevoStock: true }) }}> <i class="fa fa-plus fa-1x" aria-hidden="true"></i> </button> </td>
                                                            </tr>
                                                        ) : (//agrega stock al existente
                                                                <tr>
                                                                    <td>Stock a agregar</td>
                                                                    <td> <input id="txtAgregarStock" type="number" className="form-control" /> </td>
                                                                    <td> <button onClick={() => { this.ClickAgregarStock() }}> <i class="fa fa-check-square-o fa-1x" aria-hidden="true"></i> </button> </td>
                                                                </tr>
                                                            )
                                                    }

                                                </React.Fragment>
                                            ) : (null)
                                        }

                                        {
                                            (GetUserCookie().Sucursal.configPOS.isProdDisponibleHabilitado) ? (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>Disponible</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input defaultChecked={this.state.productoClickeado.disponible} type="checkbox" id="isDisponible" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ) : (null)
                                        }

                                    </tbody>
                                </table>

                                <React.Fragment>
                                    <p>Es un producto de nivel superior <label className="switch">
                                        <input type="checkbox" id="isNivelSuperior" checked={this.state.productoClickeado.isNivelSuperior} onClick={() => { this.ClickCkeckEditarProdNs() }} />
                                        <span className="slider round"></span>
                                    </label>
                                    </p>

                                    {
                                        (this.state.productoClickeado.isNivelSuperior) ? (
                                            <p> <button onClick={() => { this.ClickAgregarSubProd() }} className="btn btn-info">
                                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                Agregar nuevo o existente</button> </p>
                                        ) : (null)
                                    }

                                    <table>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                {
                                                    (this.state.productoClickeado.productosInferiores.filter(p => p.isHabilitado).length > 0) ? (
                                                        'Todos'
                                                    ) : (null)
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (this.state.productoClickeado.productosInferiores.filter(p => p.isHabilitado).length > 0) ? (
                                                        <React.Fragment>
                                                            <input type="checkbox" id="chkSeleccionarTodos" onClick={() => { this.ClickSeleccionarTodos() }} />
                                                        </React.Fragment>
                                                    ) : (null)
                                                }
                                            </td>
                                        </tr>
                                        {
                                            this.state.productoClickeado.productosInferiores.filter(p => p.isHabilitado).map((subProducto) => {
                                                return (
                                                    <tr>
                                                        <td>{subProducto.desprod}</td>
                                                        <td>
                                                            <button onClick={() => { this.ClickEditarSubProd(subProducto) }}>  <i class="fa fa-pencil-square-o" aria-hidden="true"></i> </button>
                                                        </td>
                                                        <td>
                                                            <button onClick={() => { this.ClickEliminarSubProducto(subProducto) }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                                <i class="fa fa-trash " aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td><input defaultValue={subProducto.id} type="checkbox" name="chkBorrarProd" /></td>
                                                    </tr>
                                                );
                                            })
                                        }


                                    </table>
                                </React.Fragment>

                                <button onClick={() => { this.ClickEditarProducto() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                                    <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                                <button onClick={() => { this.ClickEliminarSubProducto(this.state.productoClickeado) }} className="btn btn-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={this.Estilos.BtnSalir}>
                                    <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>

                            </center>
                        ) : (null)
                    }




                </div>
            </div>
        );
    }


}
