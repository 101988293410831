export const GetPrecioFormateado = (precio) => {
    try {
        return '$' + precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } catch (err) {
        return '$ 0';
    }
}
export const GetNumeroMiles = (numero) => {
    try {
        return   numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } catch (err) {
        return '0';
    }
}

export const GetNumero  = (numero) => {
    try {
        var num =    numero.toString().replaceAll('$','');
        num = num.replaceAll('$','');
    } catch (err) {
        return '0';
    }
}
export const GetUserCookie = () => {
    try {
        if (sessionStorage.getItem('userProfile') === null) {
            return {
                id: 0,
                username: '',
                tipoUsuario: 0,
                idGaveta: 0,
                CajaID: 1,
                caja: {},
                SucursalID: 1,
                Sucursal: {},
                Empresa:'',
                EmpresaID:0,
                CajaExternaID: 0
            };
        }
        return JSON.parse(sessionStorage.getItem('userProfile'));
    } catch (err) {

        return {
            id: 0,
            username: '',
            tipoUsuario: 0,
            idGaveta: 0,
            CajaID: 1,
            caja: {},
            SucursalID: 1,
            Sucursal: {},
            Empresa:'',
            EmpresaID:0,
            CajaExternaID: 0
        };
    }
}

export const Logout = () => {
    sessionStorage.clear();
}

export const Imprimir = (idOrden) => {
    const url = "print://" + idOrden;
    window.location.href = url;
}

