import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer'
import { Fbhpos } from './components/Fbhpos';
import { Logout } from './components/Globales/Utilidades';

export default class App extends Component {
    static displayName = App.name;
    constructor() {
        super();
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
    }

    render () {
        return (
            <div>
                  <Fbhpos />
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={10800000} />
                  
                 
            </div>     
        );
     }
     
    _onAction(e) {
        //console.log('user did something', e)
    }

    _onActive(e) {
        //console.log('user is active', e)
        //console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    _onIdle(e) {
        Logout();
        window.location.href = '/';
        //alert('se deberia deslogear');
        //console.log('last active', this.idleTimer.getLastActiveTime())
    }
}
