import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import ReactToExcel from 'react-html-table-to-excel';
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { HorizontalBar } from 'react-chartjs-2';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';

import swal from 'sweetalert';

export class ReporteProdVentas extends Component {
    static displayName = ReporteProdVentas.name;

    constructor() {
        super();
        this.state = {
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false,
            VerTabla: false,
            cargadatos: false,
            Productos: [],
            Sucursales: [],
            SucSelected: 0,
            activarRedirigirLogin: false
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }


    Estilos = {
        FondoDefault: {
            'backgroundColor': '#ecf0f5',
            'heigth': '100%',
        },
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '100%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '1200px'
            //'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '250px',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '100%',
            'backgroundColor': 'white',
            'height': '200px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        BordesCard:
        {
            'borderRadius': '25px'
        },
        TarjetaEstadisticas2: {
            'width': '250px',
            'backgroundColor': 'white',
            'height': '350px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '50px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '40px',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            'textAlign': 'left'
        },
        TdStyleValues: {
            'textAlign': 'center'
        },
        AnchoBox: {
            'width': '350px'
        },
        AnchoBoxGraf: {
            'width': '100%',
            'borderRadius': '25px'

        },
        AvgStyle:
        {
            'color': 'red'
        },
        AvgStylePlus:
        {
            'color': 'Green'
        },
        AvgStyleEquals:
        {
            'color': '#F9810A'
        },
        myCustomScrollbar: {
            'position': 'relative',
            'height': '500px',
            'overflow': 'auto',
            'display': 'block'
        },
        myCustomScrollbar2: {
            'position': 'relative',
            'height': '500px',
            'overflow': 'auto',
            'display': 'block'
        },
        CardSizeRanking:
        {
            'width': '500px',
            'height': '700px',
            'borderRadius': '25px',
            'margin-left': '-1%'
        },
        CardSizeRanking2:
        {
            'width': '500px',
            'height': '700px',
            'borderRadius': '25px',
            'margin-left': '3%'
        },
        headerColor:
        {
            'backgroundColor': '#fff'
        },
        TableHeaderFijo: {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': '#fff'
        },
        MarcarBorde:
        {
            'fontColor': '#fff',
            'height': '40px'
        },
        rankingUsuarios:
        {
            'width': '500px',
            'height': '500px',
            'borderRadius': '25px',
            'margin-left': '-1%'
        },
        rankingUsuarios2:
        {
            'width': '500px',
            'height': '500px',
            'borderRadius': '25px',
            'margin-left': '3%'
        },
        BtnExcel:
        {
            'width': '50px',
            'height': '50px',
            'border-radius': '8px',
            'background': '#6e9887',
            'color': '#fff'
        }
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/GetSucursales', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ Sucursales: datos });
        }
    }



    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('fechaFiltro').value = today;
        document.getElementById('fechaFiltroHasta').value = today;
        document.getElementById('fechaini').value = today;
        document.getElementById('fechafin').value = today;
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuario.id != 1) {
            Logout();
            this.setState({ activarRedirigirLogin: true });
        } else {
            this.TraerSucursales();
            //this.cargarFechas();
            this.TraerProdsVentas()
            const width = window.screen.availWidth;

            const height = window.screen.availHeight;
            if (width < 1000) {

                this.HacerResponsive();

            }
        }


    }

    HacerResponsive() {

        var elements = Array.from(document.getElementsByTagName("*"));
        elements.forEach((item) => {
            item.style.width = '100%';
        });

    }


    async TraerProdsVentas() {
        try {
            this.setState({ cargadatos: true });
            var data = {};
            var sucID = document.getElementById('SucId').value;
            data.Sucursal = sucID;
            var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/PostProductosReport', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                throw { mensaje: 'problema al traer las ventas' };

            } else {
                var datos = await respuesta.json();


                this.setState({
                    VerTabla: true,
                    cargadatos: false,
                    SucSelected: sucID,
                    Productos: datos
                })
                console.log(datos);


            }
        } catch (err) {
            swal('error en TraerTotalVentasPorFecha ' + err);
        }
    }

    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;

    }

    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }
    async ExportarExcel(e) {

        var tmpElemento = document.createElement('a');
        // obtenemos la información desde el div que lo contiene en el html
        // Obtenemos la información de la tabla
        var data_type = 'data:application/vnd.ms-excel';
        var tabla_div = document.getElementsByName('ProdsVentas');
        var tabla_html = "\n";
        tabla_div.forEach(function (n) {
            tabla_html = tabla_html + n.outerHTML.replace(/ /g, '%20') + "\n";
        })
        //var tabla_html = tabla_div.outerHTML.replace(/ /g, '%20');
        tmpElemento.href = data_type + ', ' + tabla_html;
        //Asignamos el nombre a nuestro EXCEL
        tmpElemento.download = 'ReporteProdsVentas.xls';
        // Simulamos el click al elemento creado para descargarlo
        tmpElemento.click();

    }

    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <center>
                    <div>
                        {
                            (this.state.activarRedirigirLogin) ? (<Redirect to='/' />) : (null)
                        }
                        <table style={this.Estilos.FullAncho} >
                            <thead>
                                <th colSpan="3">Productos de Venta</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sucursal</td>
                                </tr>
                                <tr>
                                    <td>
                                        <select id="SucId" className="form-control">
                                            <option value="0">Todas</option>
                                            {
                                                this.state.Sucursales.map((item, index) => {
                                                    return (<option value={item.id}>{item.descripcion}</option>);
                                                })
                                            }
                                        </select>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </center>


                <hr />
                <div className="row">
                    <div className="col-lg-5">

                    </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.TraerProdsVentas() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br />
                <div>
                    <center>
                        {
                            (this.state.cargadatos) ? (
                                <div style={this.Estilos.FondoDefault}>
                                    <center>
                                        <div className="spinner-border text-dark" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                        <p> Cargando...</p>
                                    </center>
                                </div>
                            ) : (null)
                        }
                    </center></div>
                <br />
                <div style={this.Estilos.Bordes}>
                    {
                        (this.state.VerTabla) ?
                            (
                                <div className="container" style={this.Estilos.FullAncho} >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                                <div className="card-body">
                                                    <div className="card-title text-left">
                                                        <button className="btn" style={this.Estilos.BtnExcel} onClick={() => { this.ExportarExcel() }}><i class="fa fa-file-excel-o fa-2x" aria-hidden="true"></i>
                                                        </button></div>

                                                    <table border="0" className="table table-responsive" id="ProdsVentas" name="ProdsVentas" >
                                                        <thead>
                                                            <tr><th colSpan="10" style={this.Estilos.TitlethStyle}> </th></tr>
                                                            <tr>
                                                                <th style={this.Estilos.ThStyle}>Codigo Sku</th>
                                                                <th style={this.Estilos.ThStyle}>Articulo Menú</th>
                                                                <th style={this.Estilos.ThStyle}>Descripción Web</th>
                                                                <th style={this.Estilos.ThStyle}>$ Por Unidad</th>
                                                                <th style={this.Estilos.ThStyle}>$ Bar</th>
                                                                <th style={this.Estilos.ThStyle}>$ Mesa</th>
                                                                <th style={this.Estilos.ThStyle}>$ Llevar</th>
                                                                <th style={this.Estilos.ThStyle}>$ Delivery</th>
                                                                <th style={this.Estilos.ThStyle}>Unidad Medida</th>

                                                                <th style={this.Estilos.ThStyle}>Grupo</th>
                                                                <th style={this.Estilos.ThStyle}>Disp.</th>
                                                                <th style={this.Estilos.ThStyle}>Impue</th>
                                                                <th style={this.Estilos.ThStyle}>Sucursal</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.Productos.map((item, i) => {

                                                                    return (
                                                                        <tr>
                                                                            <td className="text-rigth">{item.plu}</td>
                                                                            <td className="text-rigth">{item.desprod}</td>
                                                                            <td className="text-rigth">{item.desprodWeb}</td>
                                                                            <td className="text-rigth">{GetPrecioFormateado(item.precio)}</td>
                                                                            <td className="text-rigth">{GetPrecioFormateado(item.precioBar)}</td>
                                                                            <td className="text-rigth">{GetPrecioFormateado(item.precioMesa)}</td>
                                                                            <td className="text-rigth">{GetPrecioFormateado(item.precioLlevar)}</td>
                                                                            <td className="text-rigth">{GetPrecioFormateado(item.precioDelivery)}</td>
                                                                            <td className="text-rigth">{item.unidadMedida}</td>
                                                                            <td className="text-rigth">{item.desrup}</td>
                                                                            <td className="text-rigth">YES</td>
                                                                            <td className="text-rigth">YES</td>
                                                                            <td className="text-rigth">{item.sucursal}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            ) : (null)

                    }
                </div>

            </div>
        );
    }
}