import React, { Component } from 'react';
//import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { Logout } from './Globales/Utilidades';
//import { Redirect } from 'react-router';
import swal from 'sweetalert';

export class CombinarOrden extends Component {
    static displayName = CombinarOrden.name;
    urlParams;
    OrdenID = 0;
    OrdenSeleccionada = {};

    constructor(props) {
        super(props);
        this.state = {
            orden: {
                productosOrdens : [],
                
            },
            OrdenSeleccionada: {
                productosOrdens: [],
            },
            ordenes: [],
            IsOrdenSeleccionada: false
        }
        this.urlParams = new URLSearchParams(this.props.location.search);
        this.TraerOrdenes();
    }

    componentDidMount() {
        this.OrdenID = Number(this.urlParams.get('id'));
        this.TraerOrden();
    }

    async TraerOrden() {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + this.OrdenID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ orden: datos });
        }
        else {
            alert('error al traer la orden');
        }
    }

    async TraerOrdenes() {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdenesConMesa', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ ordenes: datos });
        }
        else {
            alert('error al traer la orden');
        }
    }

    Estilos = {
        AnchoTabla: {
            'width': '100%',
        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '25%' },
            col2: { 'width': '50%' }
        },
        Card: {
            'width': '6rem',
            'height': '8rem',
            'cursor':'pointer'
        },
        Orden: {
            'width':'33%'
        },
        TextoCard: {
            'fontSize': '10px'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        }
    }
    Redirigir(url) {
        this.props.history.push(url);
    }

    ClickOrden(orden) {
        this.setState({ OrdenSeleccionada: orden, IsOrdenSeleccionada: true });
    }

    async ClickCombinar() {
        var data = {};
        data.idOrdenDesde = this.state.orden.id;
        data.idOrdenHasta = this.state.OrdenSeleccionada.id;
        if (!data.idOrdenDesde || !data.idOrdenHasta) {
            swal('Debe seleccionar una orden de destino');
        } else {
            var respuesta = await fetch(GetUrlApi() + '/api/Orden/Combinar', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (respuesta.ok) {
                Logout();
                this.Redirigir('/');
            }
        }
    }


    render() {
        return (
            <div>
                <div className="row">
                    <div style={this.Estilos.Columnas.col1}>
                        <table style={this.Estilos.FondoGris} className="table">
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>Mesero:</td>
                                    <td>Gonzalo</td>
                                    <td>Estacion:</td>
                                    <td>{ this.state.orden.cajaID }</td>
                                </tr>
                                {
                                    this.state.orden.productosOrdens.map((value) => {
                                        return (
                                            <tr>
                                                <td>{value.cantidad}</td>
                                                <td>{value.tbProducto.desprod}</td>
                                                <td colSpan="2">{value.total}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="row" style={this.Estilos.Columnas.col2}>
                        {
                            this.state.ordenes.map((item, index) => {
                                if (item.id !== this.OrdenID) {
                                    return (
                                        <div onClick={() => { this.ClickOrden(item) }} class="card" style={this.Estilos.Card}>
                                            <div class="card-body">
                                                <p style={this.Estilos.TextoCard}>Orden {item.id}</p>
                                                <p style={this.Estilos.TextoCard}>${item.total} {item.hora}</p>
                                                <p style={this.Estilos.TextoCard}>{item.tiposVenta.descripcion}{item.mesaID}</p>
                                            </div>
                                        </div>
                                    );
                                }
                            })
                    }
                    </div>
                    <div style={this.Estilos.Columnas.col1}>
                        <table style={this.Estilos.FondoGris} className="table">
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>Mesero:</td>
                                    <td>Gonzalo</td>
                                    <td>Estacion:</td>
                                    <td>{this.state.OrdenSeleccionada.cajaID}</td>
                                </tr>
                                {
                                    this.state.OrdenSeleccionada.productosOrdens.map((value) => {
                                        return (
                                            <tr>
                                                <td>{value.cantidad}</td>
                                                <td>{value.tbProducto.desprod}</td>
                                                <td colSpan="2">{value.total}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>


                </div>
                {
                    (this.state.IsOrdenSeleccionada) ? (<button onClick={() => { this.ClickCombinar() }}>Combinar</button>) : (null)
                }
                
            </div >

        );
    }
}
