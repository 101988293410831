import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import swal from 'sweetalert';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';

export class RSalidaDeCajero extends Component {
    static displayName = RSalidaDeCajero.name;
    constructor() {
        super();
        this.state = {
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false
        }
    }
    Redirigir(url) {
        this.props.history.push(url);
    }
    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuarioID != 1) {
            Logout();
            this.Redirigir('/');
        }
    }

    Estilos = {
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '80%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '40%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '0',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }

    }
    async TraerTotalVentasPorFecha() {
        try {
            var data = {};
            document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;
            document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;

        } catch (err) {
            swal('error en TraerTotalVentasPorFecha ' + err);
        }
    }
    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        const desde = this.state.fechaDesdeIngresada;
        const hasta = this.state.fechaHastaIngresada;
        if (desde && hasta) {
            this.TraerTotalVentasPorFecha();
        }
    }

    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        const desde = this.state.fechaDesdeIngresada;
        const hasta = this.state.fechaHastaIngresada;
        if (desde && hasta) {
            this.TraerTotalVentasPorFecha();
        }
    }


    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <div>
                    <table style={this.Estilos.FullAncho} >
                        <thead>
                            <th colSpan="3">Sucursales</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                            </tr>
                            <tr>
                                <td> <input className="form-control" type="text" /> </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde() }} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta() }} className="form-control" type="date" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



            <hr />
            <div className="row">
                    <div className="col-lg-5">
                        <input type="text" id="fechaini"  ></input>
                        <input type="text" id="fechafin"></input>
                    </div>
                <div className="col-lg-6"><button className="btn btn-success">GENERAR REPORTE</button>  </div>
                </div>
            </div>
        );
    }

}