import React, { Component } from 'react';
import { Route } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { Redirect } from 'react-router';
import UserProfile from './Globales/PerfilUsuario';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import Popup from 'reactjs-popup';
import swal from 'sweetalert';

export class NavbarDefault extends Component {
    static displayName = NavbarDefault.name;
    StateInicial = {
        activarPopupSuperUser: false,
        volverLogin: false,
        redirectReportes: false,
        redirectEntrada: false,
        redirectSalida: false,
        redirectRevisar: false,
        redirectOtrosPagos: false,
        redirectSalidasDinero: false,
        redirectIngresosDinero: false,
        redirectAdmin: false,
        redirectDashboard: false,
        redirectDelivery: false,
        redirectProductoVentas: false,
        redirectResumenVenta: false,
        redirectCortesCajas: false,
        redirectParaLlevar: false,
        redirectVenta:false,
        redirectMesas: false,
        redirectBar: false,
        redirectMusuarios: false,
        redirectMotivos: false,
        redirectMcat: false,
        redirectMprod: false,
        redirigirMCuentaCasa: false,
        redirigirMDescuentos: false,
        redirigirMModifs: false,
        redirigirMMesas: false,
        redirigirModifsMaestro: false,
        redirigirMPromo: false,
        redirigirReportePlatillo: false,
        redirigirFamilias: false,
        redirigirGrupos: false,
        redirectReporteOrdenes: false,
        redirectNotaCredito: false,
        url: '/revisar',
        dropdownOpen: false,
        Sucursales: [],
        Ambiente: localStorage.getItem('ambiente'),
        cajaAbierta: false
    }
    constructor() {
        super();
        this.state = this.StateInicial;
        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {
        let horario;
        if (!horario) {
            horario = setInterval(() => {
                const date = new Date();
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const hour = date.getHours();
                const min = date.getMinutes();
                const sec = date.getSeconds();

                const dateString = (day <= 9 ? '0' + day : day) + '-' + (month <= 9 ? '0' + month : month) + '-' + year + ' ' +
                    (hour <= 9 ? '0' + hour : hour) + ':' + (min <= 9 ? '0' + min : min) + ':' + (sec <= 9 ? '0' + sec : sec);
                this.setState({
                    curTime: dateString
                })
            }, 1000);
        }

      /*  if (this.state.Ambiente === 'MAISA') {
            this.setState({cajaID: GetUserCookie().CajaExternaID});
        }else{
            this.setState({cajaID: GetUserCookie().CajaID});
        }*/
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Sucursals', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();

            this.setState({ Sucursales: datos, cargando: false });
        }
        else {
            alert('error');
        }
    }


    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    async AnularRedirects() {
        await this.setState(this.StateInicial);
    }

    async redirigirReportes() {
        await this.AnularRedirects();
        this.setState({ redirectReportes: true });
        this.closeNav();
    }

    async redirigirRevisar() {
        await this.AnularRedirects();
        this.setState({ redirectRevisar: true });
        this.closeNav();
    }

    async redirigirMesas() {

        await this.AnularRedirects();
        this.setState({ redirectMesas: true });
        this.closeNav();
    }

    async redirigirParaLlevar() {
        const rutaActual = String(window.location.pathname);

        if (rutaActual.includes('ParaLlevarDos')) {
            window.location.href = '/ParaLlevarDos';
        }
        await this.AnularRedirects();
        this.setState({ redirectParaLlevar: true });
        this.closeNav();
    }

    async redirigirVenta() {
        const rutaActual = String(window.location.pathname);

        if (rutaActual.includes('Venta')) {
            window.location.href = '/Venta';
        }
        await this.AnularRedirects();
        this.setState({ redirectVenta: true });
        this.closeNav();
    }
    async redirigirNotaCredito() {
        const rutaActual = String(window.location.pathname);

        if (rutaActual.includes('NotaCredito')) {
            window.location.href = '/NotaCredito';
        }
        await this.AnularRedirects();
        this.setState({ redirectNotaCredito: true });
        this.closeNav();
    }

    async redirigirBar() {
        const rutaActual = String(window.location.pathname);

        if (rutaActual.includes('ParaLlevarDos')) {
            window.location.href = '/ParaLlevarDos?tipoV=4';
        }
        await this.AnularRedirects();
        this.setState({ redirectBar: true });
        this.closeNav();
    }
    async redirigirDelivery() {
        const rutaActual = String(window.location.pathname);

        if (rutaActual.includes('ParaLlevarDos')) {
            window.location.href = '/ParaLlevarDos?tipoV=3';
        }
        await this.AnularRedirects();
        this.setState({ redirectDelivery: true });
        this.closeNav();
    }

    async redirigirSalida() {
        await this.AnularRedirects();
        this.setState({ redirectSalida: true });
        this.closeNav();
    }

    async redirigirEntrada() {
        await this.AnularRedirects();
        this.setState({ redirectEntrada: true });
        this.closeNav();
    }

    async redirigirAdministracion() {
        await this.AnularRedirects();
        this.setState({ redirectAdmin: true });
        this.closeNav();
    }



    async redirigirOtrosPagos() {
        await this.AnularRedirects();
        this.setState({ redirectOtrosPagos: true });
        this.closeNav();
    }

    async getEstadoGaveta(){
   
        var SucursalID = GetUserCookie().SucursalID;
        var CajaID = GetUserCookie().CajaID;

        var respuesta = await fetch(GetUrlApi() + '/api/Gavetas/ObtenerEstadoGaveta?SucursalID=' + SucursalID + '&CajaID=' + CajaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (respuesta.ok) {
            var data = await respuesta.json();

            if (data.estado === 1/*'Habilitada'*/) {
                this.setState({cajaAbierta: true});
            }
        }
    }

    async redirigirSalidasDinero(){
        await this.AnularRedirects();
        await this.getEstadoGaveta();
        
        if (this.state.cajaAbierta === true){
            this.setState({ redirectSalidasDinero: true });
            this.closeNav();
        }else{
            swal('No es posible realizar un egreso de dinero, ya que no existe una caja abierta para la fecha de hoy.').then(() => {
                this.closeNav();
            });
        }
    }
    async redirigirIngresosDinero () {
        await this.AnularRedirects();
        await this.getEstadoGaveta();
        
        if (this.state.cajaAbierta === true){
            this.setState({ redirectIngresosDinero: true });
            this.closeNav();
        }else{
            swal('No es posible realizar un ingreso de dinero, ya que no existe una caja abierta para la fecha de hoy.').then(() => {
                this.closeNav();
            });
        }        
    }
    async redirigirDashboard() {
        await this.AnularRedirects();
        this.OpenDropdown();
        this.setState({ redirectDashboard: true });
        this.closeNav();
    }
    async redirigirResumenVentas() {
        await this.AnularRedirects();
        this.OpenDropdown();
        this.setState({ redirectResumenVenta: true });
        this.closeNav();
    }
    async redirigirCierreCaja() {
        await this.AnularRedirects();
        this.OpenDropdown();

        this.setState({ redirectCortesCajas: true });
        this.closeNav();
    }
    async redirectReporteOrdenes() {
        await this.AnularRedirects();
        this.OpenDropdown();

        this.setState({ redirectReporteOrdenes: true });
        this.closeNav();
    }
    async redirigirProductosVenta() {
        await this.AnularRedirects();
        this.OpenDropdown();

        this.setState({ redirectProductoVentas: true });
        this.closeNav();
    }

    async redirigirMusuarios() {
        await this.AnularRedirects();

        this.setState({ redirectMusuarios: true });
        this.closeNav();
    }

    async redirigirMotivoDev() {
        await this.AnularRedirects();

        this.setState({ redirectMotivos: true });
        this.closeNav();
    }

    async redirigirMCat() {
        await this.AnularRedirects();

        this.setState({ redirectMcat: true });
        this.closeNav();
    }

    async redirigirMproductos() {
        await this.AnularRedirects();

        this.setState({ redirectMprod: true });
        this.closeNav();
    }

    async redirigirMCuentaCasa() {
        await this.AnularRedirects();

        this.setState({ redirigirMCuentaCasa: true });
        this.closeNav();
    }

    async redirigirMDescuentos() {
        await this.AnularRedirects();

        this.setState({ redirigirMDescuentos: true });
        this.closeNav();
    }

    async redirigirMModifs() {
        await this.AnularRedirects();

        this.setState({ redirigirMModifs: true });
        this.closeNav();
    }
    async redirigirModifsMaestro() {
        await this.AnularRedirects();

        this.setState({ redirigirModifsMaestro: true });
        this.closeNav();
    }
    async redirigirMMesasAmb() {
        await this.AnularRedirects();

        this.setState({ redirigirMMesas: true });
        this.closeNav();
    }

    async redirigirMPromociones() {
        await this.AnularRedirects();

        this.setState({ redirigirMPromo: true });
        this.closeNav();
    }
    async redirigirReportePlatillo() {
        await this.AnularRedirects();

        this.setState({ redirigirReportePlatillo: true });
        this.closeNav();
    }

    async redirigirMFamilias() {
        await this.AnularRedirects();

        this.setState({ redirigirFamilias: true });
        this.closeNav();
    }

    async redirigirMGrupos() {
        await this.AnularRedirects();

        this.setState({ redirigirGrupos: true });
        this.closeNav();
    }
    async redirigirKds() {
        window.open('kds', '_blank');
    }

    openNav() {
        if (document.getElementById("mySidenav")) {
            document.getElementById("mySidenav").style.width = "250px";
        }

    }

    closeNav() {
        if (document.getElementById("mySidenav")) {
            document.getElementById("mySidenav").style.width = "0";
            document.getElementById("dropmenu").style.display = "none";
        }

    }

    GetUsername() {

        try {
            var nombreUser = sessionStorage.getItem('userProfile');
            var nom = GetUserCookie().username;
            return nom;
        } catch (err) {

            return 'No esta logeado';
        }
    }
    OpenDropdown() {
        var dropdown = document.getElementById("dropmenu");
        var i;
        if (dropdown.style.display === "block") {
            dropdown.style.display = "none";
        } else {
            dropdown.style.display = "block";
        }

    }


    OpenDropdownAdministracion() {
        var dropdown = document.getElementById("dropAdmin");
        var i;
        if (dropdown.style.display === "block") {
            dropdown.style.display = "none";
        } else {
            dropdown.style.display = "block";
        }

    }

    OpenDropdownOtrosPagos() {
        var dropdown = document.getElementById("dropOtrosPagos");
        var i;
        if (dropdown.style.display === "block") {
            dropdown.style.display = "none";
        } else {
            dropdown.style.display = "block";
        }

    }
    Estilos = {
        EstiloMain: {
            //'fontSize': '30px',
            'cursor': 'pointer'
        },
        FullAlto: {
            'backgroundColor': '#111',
            'borderColor': '#111',
            'color': '#818181',
            'padding': '8px 8px 8px 32px',
            'textDecoration': 'none',
            'fontSize': '19px',
            'display': 'block',
            'transition': '0.3s'
        },
        ButtonChat:
        {
            'display': 'block',
            'borderRadius': '8px',
            'color': 'white',
            'background': 'rgb(113, 175, 150)'
        },
        BtnGroup:
        {
            'borderColor': '#d8e1de',
            'marginLeft': '3%',
            'marginBottom': '1%',
            'width': '45%',
            'fontSize': '80%',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'height': '50px'
        },
        Brand:
        {
            'fontSize': 'calc(-10% + 1vw + 1vh)',
        },
        button: {
            'padding': '10',
           
            'background': 'linear-gradient(180deg, #65bce8 0%, #306485 100%)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 5px',
            'font-size': '14px',
            'color': 'white',
            'text-shadow': '0px 0.5px 1px rgba(0, 0, 0, 0.15)',
            'outline': 'none',
            'cursor': 'pointer',
          },
          red: {
            'background-color': '#ff4059'
          },
          
          black: {
            'background-color': '#2e3434'
          }
    }

    ClickAbrir() {
        this.openNav();
    }
    Soporte() {
        window.open("https://chat.whatsapp.com/BzLsfV8kO3g4YJNQQZkAn8", "_blank");
        //window.open("https://api.whatsapp.com/send?phone=56986584333", "_blank");
    }
    ClickCerrar() {
        this.closeNav();
    }

    async ClickCerrarSesion() {
        sessionStorage.removeItem('userProfile');
        UserProfile.resetUser();
        await this.AnularRedirects();
        this.setState({ volverLogin: true });
    }

    render() {
        return (
            <div id="navBar">
                {
                    (this.state.redirectReportes) ? (
                        <Redirect to="/reportes" />
                    ) : (null)
                }
                {
                    (this.state.redirectRevisar) ? (
                        <Redirect to="/revisar" />
                    ) : (null)
                }
                {
                    (this.state.redirectSalida) ? (
                        <Redirect to="/SalidaCajero" />
                    ) : (null)
                }
                {
                    (this.state.redirectEntrada) ? (
                        <Redirect to="/EntradaAlCajero" />
                    ) : (null)
                }
                {
                    (this.state.redirectAdmin) ? (
                        <Redirect to="/administracion" />
                    ) : (null)
                }
                {
                    (this.state.volverLogin) ? (
                        <Redirect to="/" />
                    ) : (null)
                }
                {
                    (this.state.redirectOtrosPagos) ? (
                        <Redirect to="/OtrosPagos" />
                    ) : (null)
                }
                {
                    (this.state.redirectSalidasDinero) ? (
                        <Redirect to="/SalidasDinero" />
                    ) : (null)
                }
                {
                    (this.state.redirectIngresosDinero) ? (
                        <Redirect to="/IngresosDinero" />
                    ) : (null)
                }
                {
                    (this.state.redirectDashboard) ? (
                        <Redirect to="/Indicadores" />
                    ) : (null)
                }
                {
                    (this.state.redirectResumenVenta) ? (
                        <Redirect to="/ResumenVentas" />
                    ) : (null)
                }
                {
                    (this.state.redirectCortesCajas) ? (
                        <Redirect to="/CortesCajas" />
                    ) : (null)
                }
                {
                    (this.state.redirectReporteOrdenes) ? (
                        <Redirect to="/ReporteOrdenes" />
                    ) : (null)
                }
                {
                    (this.state.redirectProductoVentas) ? (
                        <Redirect to="/ProductosVenta" />
                    ) : (null)
                }

                {
                    (this.state.redirectParaLlevar) ? (
                        <Redirect to="/ParaLlevarDos" />
                    ) : (null)
                }
                 {
                    (this.state.redirectVenta) ? (
                        <Redirect to="/Venta" />
                    ) : (null)
                }
                {
                    (this.state.redirectMesas) ? (
                        <Redirect to="/Mesas" />
                    ) : (null)
                }
                {
                    (this.state.redirectBar) ? (
                        <Redirect to="/ParaLlevarDos?tipoV=4" />
                    ) : (null)
                }

                {
                    (this.state.redirectDelivery) ? (
                        <Redirect to="/ParaLlevarDos?tipoV=3" />
                    ) : (null)
                }

                {
                    (this.state.redirectMusuarios) ? (
                        <Redirect to="/Usuarios" />
                    ) : (null)
                }
                {
                    (this.state.redirectMotivos) ? (
                        <Redirect to="/MantenedorMotivosNotaCredito" />
                    ) : (null)
                }

                {
                    (this.state.redirectMcat) ? (
                        <Redirect to="/Categorias" />
                    ) : (null)
                }

                {
                    (this.state.redirectMprod) ? (
                        <Redirect to="/Productos" />
                    ) : (null)
                }

                {
                    (this.state.redirigirMCuentaCasa) ? (
                        <Redirect to="/CuentasCasa" />
                    ) : (null)
                }
                {
                    (this.state.redirigirMDescuentos) ? (
                        <Redirect to="/Descuentos" />
                    ) : (null)
                }
                {
                    (this.state.redirigirMModifs) ? (
                        <Redirect to="/Modificadores" />
                    ) : (null)
                }
                {
                    (this.state.redirigirMMesas) ? (
                        <Redirect to="/MesasAmbientes" />
                    ) : (null)
                }
                {
                    (this.state.redirigirMPromo) ? (
                        <Redirect to="/promociones" />
                    ) : (null)
                }
                {
                    (this.state.redirigirModifsMaestro) ? (
                        <Redirect to="/MaestroModificadores" />
                    ) : (null)
                }
                {
                    (this.state.redirigirReportePlatillo) ? (
                        <Redirect to="/reporteVentasPlatillo" />
                    ) : (null)
                }
                {
                    (this.state.redirigirGrupos) ? (
                        <Redirect to="/Grupos" />
                    ) : (null)
                }                {
                    (this.state.redirectNotaCredito) ? (
                        <Redirect to="/NotaCredito" />
                    ) : (null)
                }
                {
                    (this.state.redirigirFamilias) ? (
                        <Redirect to="/Familias" />
                    ) : (null)
                }
                {
                    (this.state.activarPopupSuperUser) ? (
                        this.GetHtmlCambioSucursal()
                    ) : (null)
                }


                {this.NavbarBootstrap()}
                {
                    (GetUserCookie().id > 0) ? (
                        <div id="mySidenav" className="sidenav">
                            <a href="javascript:void(0)" className="closebtn" onClick={() => { this.ClickCerrar() }}>&times;</a>
                            <a href="javascript:void(0)" onClick={() => { this.redirigirMesas() }} hidden='hidden'>Mesas</a>
                            {/*<a href="javascript:void(0)" onClick={() => { this.redirigirParaLlevar() }}>Vender</a>*/}
                            <a href="javascript:void(0)" onClick={() => { this.redirigirVenta() }}>Vender</a>
                            <a href="javascript:void(0)" onClick={() => { this.redirigirNotaCredito() }}>Nota de Credito</a>
                            <a href="javascript:void(0)" onClick={() => { this.redirigirBar() }} hidden='hidden'>Bar</a>
                            {
                                (GetUserCookie().Sucursal.configPOS.isDeliveryHabilitado) ? (
                                    <a href="javascript:void(0)" onClick={() => { this.redirigirDelivery() }} hidden='hidden'>Delivery</a>
                                ) : (null)
                            }

                            <a href="javascript:void(0)" onClick={() => { this.redirigirRevisar() }} hidden='hidden'>Revisar Venta</a>
                            <a href="javascript:void(0)" onClick={() => { this.redirigirEntrada() }}>Entrada Cajero</a>
                            <a href="javascript:void(0)" onClick={() => { this.redirigirSalida() }}>Salida Cajero</a>
                            <button className="dropdown-btn" onClick={() => { this.OpenDropdownOtrosPagos() }} >Otros Pagos <i className="fa fa-caret-down"></i></button>
                            <div className="dropdown-container" id="dropOtrosPagos">
                            {
                                        <React.Fragment>
                                            <a href="javascript:void(0)" onClick={() => { this.redirigirSalidasDinero() }}>Egresos de Dinero</a>
                                            <a href="javascript:void(0)" onClick={() => { this.redirigirIngresosDinero() }}>Ingresos de Dinero</a>
                                             
                                        </React.Fragment>
                                }
                            </div>
                             
                            {
                                (GetUserCookie().tipoUsuario.id === 1) ? 
                                (
                                    <button className="dropdown-btn" onClick={() => { this.OpenDropdownAdministracion() }} /*hidden='hidden'*/>Administracion      <i className="fa fa-caret-down"></i></button>
                                )
                            : (null)
                            }

                            <div className="dropdown-container" id="dropAdmin">
                                {
                                   (GetUserCookie().tipoUsuario.id === 1) ?
                                        (
                                        <React.Fragment>
                                            <a href="javascript:void(0)" onClick={() => { this.redirigirAdministracion() }}>Configuración General</a>
                                            <a href="javascript:void(0)" onClick={() => { this.redirigirMusuarios() }}>Usuarios</a>
                                            <a href="javascript:void(0)" onClick={() => { this.redirigirMotivoDev() }}>Motivos Nota de Crédito</a>
                                            <a href="javascript:void(0)" onClick={() => { this.redirigirMCat() }}>Categorías</a>
                                            <a href="javascript:void(0)" onClick={() => { this.redirigirMproductos() }}>Productos</a>
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isCrudCuentaCasa) ? (
                                                    <a href="javascript:void(0)" onClick={() => { this.redirigirMCuentaCasa() }}>Cuentas Casa</a>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isCrudDescuentos) ? (
                                                    <a href="javascript:void(0)" onClick={() => { this.redirigirMDescuentos() }}>Descuentos</a>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isCrudModif) ? (
                                                    <React.Fragment>
                                                        <a href="javascript:void(0)" onClick={() => { this.redirigirMModifs() }}>Modificadores Forzados/Combos</a>
                                                        <a href="javascript:void(0)" onClick={() => { this.redirigirModifsMaestro() }}>Maestro Modificadores</a>
                                                    </React.Fragment>

                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isCrudMesaAmbiente) ? (
                                                    <a href="javascript:void(0)" onClick={() => { this.redirigirMMesasAmb() }}>Mesas y Ambientes</a>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isPromociones) ? (
                                                    <a href="javascript:void(0)" onClick={() => { this.redirigirMPromociones() }}>Promociones</a>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (
                                                    <a href="javascript:void(0)" onClick={() => { this.redirigirMFamilias() }}>Familias</a>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (
                                                    <a href="javascript:void(0)" onClick={() => { this.redirigirMGrupos() }}>Grupos</a>
                                                ) : (null)
                                            }
                                        </React.Fragment>
                                    ) 
                                : (null)
                                }
                            </div>


                            <button hidden='hidden' className="dropdown-btn" onClick={() => { this.OpenDropdown() }}>Reportes      <i className="fa fa-caret-down"></i></button>
                            <div className="dropdown-container" id="dropmenu">
                                <a href="javascript:void(0)" onClick={() => { this.redirigirDashboard() }}>Dashboard</a>
                                <a href="javascript:void(0)" onClick={() => { this.redirigirResumenVentas() }}>Resumen de Ventas</a>
                                <a href="javascript:void(0)" onClick={() => { this.redirigirCierreCaja() }}>Cierre de Caja</a>
                                <a href="javascript:void(0)" onClick={() => { this.redirigirProductosVenta() }}>Productos Venta</a>
                                {
                                    (GetUserCookie().Sucursal.configPOS.isReporteVentasPorProducto) ? (
                                        <a href="javascript:void(0)" onClick={() => { this.redirigirReportePlatillo() }}>Reporte venta por producto</a>
                                    ) : (null)
                                }
                                <a href="javascript:void(0)" onClick={() => { this.redirectReporteOrdenes() }}>Reporte Ordenes Pagadas</a>

                            </div>
                            {
                                (GetUserCookie().Sucursal.configPOS.isKitchenDisplay) ? (
                                    <a href="javascript:void(0)" onClick={() => { this.redirigirKds() }}>S-KDS</a>
                                ) : (null)
                            }

                        </div>
                    ) : (null)
                }
            </div>
        );
    }
    Redirigir(url) {
        this.context.history.push(url)
    }

    NavbarBootstrap() {
        return (
            <nav className="pt-0 pb-0 navbar navbar-expand-lg navbar-light bg-light">
                {this.GetMainMenu()}
                <div className="col-lg-12 col-md-10 col-sm-8 col-10 d-flex d-inline-flex justify-content-end">
                    {
                        (this.state.Ambiente == 'MAISA')?
                        (<span className='nav-link' style={this.Estilos.Brand}>Caja: {GetUserCookie().CajaExternaID}</span>)
                        :
                        (<span className='nav-link' style={this.Estilos.Brand}>Caja: {GetUserCookie().CajaID}</span>)

                    }
                    
                    <span className="nav-link" style={this.Estilos.Brand}>{this.state.curTime}</span>
                  
                    {
                        (GetUserCookie().IsSuperAdmin) ?
                            (<label style={this.Estilos.Brand} className="nav-link mb-0" onClick={() => { this.ClickCambiarSucursal() }}>{this.GetUsername()} - Sucursal: {GetUserCookie().SucursalID} - {GetUserCookie().Sucursal.descripcion} - Empresa: {GetUserCookie().Empresa}</label>)
                            : (this.GetUserNav())
                    }

                    {this.GetCerrarSesion()}
                </div>

            </nav>
        );
    }

    GetMainMenu() {
        if (GetUserCookie().username != "") {
            return (
                <span style={this.Estilos.EstiloMain} onClick={() => { this.ClickAbrir() }}>&#9776; </span>
            );
        } else {
            return "";
        }
    }

    GetCerrarSesion() {
        if (GetUserCookie().username != "") {
            return (
                <button id="cerrarSesion" style={this.Estilos.button} 
                className="btn" onClick={() => { this.ClickCerrarSesion() }} href="javascript:void(0)">
                    Cerrar Sesion 
                    
                    <span className="sr-only">(current)</span>
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                </button>
            );
        } else {
            return "";
        }
    }


    GetUserNav() {
        if (GetUserCookie().username != "") {
            return (
                <label style={this.Estilos.Brand} className="nav-link mb-0" >Usuario: {GetUserCookie().username} - Sucursal: {GetUserCookie().Sucursal.descripcion} - Empresa: {GetUserCookie().Empresa}</label>
            );
        } else {
            return "";
        }

    }

    /**
     *
     * <a className="navbar-brand" href="javascript:void(0)" hidden="hidden"><img onClick={() => { this.ClickCerrarSesion() }} height="40" width="120" src={require('./Imagenes/TodoBikes.png')} />  </a>
     * <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto" >
                        <button hidden="hidden"  style={this.Estilos.ButtonChat} onClick={() => { this.Soporte() }}><i className="fa fa-whatsapp fa-2x"></i></button>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">

                        {
                            (GetUserCookie().IsSuperAdmin) ?
                                (<label onClick={() => { this.ClickCambiarSucursal() }}>{this.GetUsername()} - {GetUserCookie().Sucursal.descripcion}</label>)
                                : (<label >{GetUserCookie().username} - {GetUserCookie().Sucursal.descripcion}</label>)
                        }

                        <a id="cerrarSesion" className="nav-link" onClick={() => { this.ClickCerrarSesion() }} href="javascript:void(0)">Cerrar Sesion <span className="sr-only">(current)</span></a>
                    </form>
                </div>*/

    async ClickCambiarSucursal() {
        await this.TraerSucursales();
        this.setState({ activarPopupSuperUser: true });
    }

    GetHtmlCambioSucursal() {

        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupName}
                modal
                onClose={() => { this.setState({ activarPopupSuperUser: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Seleccione el local</h3>
                    <hr />
                    {
                        this.state.Sucursales.map((item) => {
                            return (
                                <button onClick={() => { this.CambiarSucursal(item) }} value={item.id} style={this.Estilos.BtnGroup} className="btn btn-dark">{item.descripcion}</button>
                            );
                        })
                    }
                </center>

            </Popup>
        );
    }

    async CambiarSucursal(sucursal) {
        var usuarioM2 = await this.GetUsuarioParalelo(sucursal.id);
        var usuarioActual = UserProfile.getUser();
        usuarioActual.SucursalID = sucursal.id;
        usuarioActual.Sucursal = sucursal;

        usuarioActual.CajaID = usuarioM2.cajaID;
        usuarioActual.Caja = usuarioM2.caja;

        usuarioActual.id = usuarioM2.id;
        usuarioActual.username = usuarioM2.nombre + " " + usuarioM2.apellido;
        usuarioActual.tipoUsuario = usuarioM2.tipoUsuario;
        usuarioActual.IsSuperAdmin = usuarioM2.isSuperAdmin;


        UserProfile.setUser(usuarioActual);
        const newCookie = JSON.stringify(usuarioActual);
        sessionStorage.setItem('userProfile', newCookie);
        this.setState({ activarPopupSuperUser: false });
        window.location.reload();
    }



    async GetUsuarioParalelo(sucursalID) {
        var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/GetUsuarioParalelo?sucursalID=' + sucursalID + '&nombrecompleto=' + GetUserCookie().username, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            return datos;
        }
        console.log('usuario retorno null');
        return null;

    }

}
