import React, { Component } from 'react';
//import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
//import { Redirect } from 'react-router';
import swal from 'sweetalert';
import IdleTimer from 'react-idle-timer'
import { GetUserCookie } from './Globales/Utilidades';

export class Mesas extends Component {
    static displayName = Mesas.name;
    urlParams;
    IsSelectMesa = false;
    OrdenMoverID = 0;
    MesaMoverID = 0;
    FiltroAmbiente =0;
    FiltroAmbienteString ="";

    MesasIniciales = [];
    constructor(props) {
        super(props);
        this.state = {
            Mesas: [],
            Ambientes: [],
            IsSeleccionar:false
        }
        this.TraerAmbientes();
        this.urlParams = new URLSearchParams(this.props.location.search);
     
    }

    componentDidMount() {
        //this.IsSelectMesa = this.urlParams.get('select') === 'true';
        //const IdordenMover = Number(this.urlParams.get('ordenID') === 'true');
        //this.MesaMoverID = Number(this.urlParams.get('mesaid'));
        //this.OrdenMoverID = Number(this.urlParams.get('ordenID'));
        //if (this.IsSelectMesa && this.OrdenMoverID > 0) {
        //    this.setState({ IsSeleccionar: true });
        //}
        this.TraerMesas();
        
    }

    async TraerAmbientes() {
        var SucursalID = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas/GetAmbientes?SucursalID=' + SucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ Ambientes: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }


    async TraerMesas() {
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas/GetMesasDos', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            var usuario = GetUserCookie();
            
            datos = datos.filter(p => p.sucursalID === usuario.SucursalID);
            this.MesasIniciales = datos;
            this.setState({ Mesas: datos });
            this.FiltrarDatos();

        }
        else {
            alert('error al traer las mesas');
        }
    }

    async getUsuarioMesa(MesaID)
    {
        var data = {};
        data.MesaID = MesaID;

        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetUsuarioMesa', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            var datos = await respuesta;
            console.log(datos);

            //this.setState({ Mesas: datos });

            return datos;
        }
        else {
            return "";
        }

    }
    FiltrarAmbiente(idAmbiente)
    {
        this.FiltroAmbiente = idAmbiente;
        this.FiltrarDatos();
    }
    async FiltrarDatos() {
        var mesas = this.MesasIniciales
        
        var ambientes = this.state.Ambientes;
        var FiltroAmbientes = this.FiltroAmbiente
        if (FiltroAmbientes === 0) {
            if (ambientes.length > 0) {
                FiltroAmbientes = ambientes[0].id;
            }
        }
        console.log(FiltroAmbientes);
        if (FiltroAmbientes !== 0) {
            if (FiltroAmbientes!==5001) {
                mesas = mesas.filter(p => p.ambienteID === FiltroAmbientes);
            }
            
        }
        console.log("Filtro");
        console.log(mesas);
        this.setState({ Mesas: mesas });
    }

    async ClickTrasladarMesa(idMesaClic) {
        var data = {};
        data.idOrden = this.OrdenMoverID;
        data.MesaID = this.MesaMoverID;
        data.NewMesa = idMesaClic;
        
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/Trasladar', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            this.Redirigir('/');
        }
        else {
            swal('error al trasladar mesa');
        }
        
    }

    Estilos = {
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        ContentPrincipal:
        {
            'margin-top': '5%'
        },
        columnaCentral: {
            'width': '100%',
            'background-color': '#fff',
            'overflow-x': 'hidden',
            'padding-top': '20px',
            'height': '100%',
        },
        Content:
        {
            'margin-left': '0%',
            'top': '0',
            'background-color': '#fff',
            'overflow-x': 'hidden',
            'padding-top': '20px'
        },
        DivCenter:
        {
            'margin-right': '0%',
            
        },
        Laterales:
        {
            'height': 'auto',
            'width': '10%',
            'position': 'fixed',
            'top': '92px',

            'background-color': '#525050',
            'overflow-x': 'hidden',
            'padding-top': '20px'
        },
        BodyCardStyle:
        {
            'margin-left': '-15px'
        },
        MesaOcupada:
        {
            'background': '#BFC205',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display':'block'
        },
        MesaLibre:
        {
            'background': '#529CE6',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display': 'block'
        },
        MesaPagada:
        {
            'background': 'rgb(200, 3, 3)',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display': 'block'
        },
        MesaCtaImpresa:
        {
            'background': 'rgb(199, 64, 220)',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display': 'block'
        },
        NroMesa:
        {
            'font-size': '23px',
            'text-decoration': 'solid',
            'padding-bottom': '0px',
            'margin-left': '45%',
            'border-radius': '4px',
            'border-color': '#fff !important',
            'border': '2px solid',
            'width': '35px',
            'height': '35px',
            'text-align': 'center'
        },
        NroMesaO:
        {
            'font-size': '27px',
            'text-decoration': 'solid',
            'padding-bottom': '4px',
            'margin-left': '-20%',
            'border-radius': '4px',
            'border-color': '#fff !important',
            'border': '2px solid',
            'width': '41px',
            'height': '41px',
            'text-align': 'center',
            'margin-top': '-10%'
        },
        IconSize:
        {
            'font-size': '28px',
            'padding-top': '13%'
        },
        CapacidadSize:
        {
            'font-size': '17px',
            'margin-left': '-5%',
            'margin-top': '-6%'
        },
        CapacidadSizeO:
        {
            'font-size': '20px',
            'margin-left': '-5%',
            'margin-top': '-31%'
        },
        BtnStyle:
        {
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px'
        },
        DivIcon:
        {
            'font-size': '10px',
            'margin-left': '-35%',
            'margin-bottom': '4%'
        },
        DivIcon2:
        {
            'font-size': '10px',
            'margin-left': '-35%'
        },
        DivIcon3:
        {
            'font-size': '10px',
            'margin-left': '-35%',
            'margin-top': '-9%'
        },
        DivIcon4:
        {
            'font-size': '16px',
            'margin-left': '-35%',
            'margin-top': '11%',
            'margin-bottom': '-12%'
        },
        UserSize:
        {
            'font-size': '12px',
            //'margin-right': '-6%',
            'margin-left': '-74%',
            'margin-top': '-11%'
        },
        HoraSize:
        {
            'font-size': '12px',
            'margin-right': '10%',
            'padding-top': '6%',
            'margin-left': '-55%'
        },
        MarginTopC:
            {
                'margin-top': '-14%',
                'margin-left': '-11%'
        },
        BtnStyleDemoA:
        {
            'background': '#529CE6',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius':' 5px'
        },
        BtnStyleDemoC:
        {
            'background': '#BFC205',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius':' 5px'
        },
        BtnStyleDemoI:
        {
            'background': '#C740DC',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius':' 5px'
        },
        BtnStyleDemoS:
        {
            'background': '#20B119',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius':' 5px'
        },
        BtnStyleDemoP:
        {
            'background': '#C80303',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius':' 5px'
        },
        NumMesaMarco:
        {
            'border-radius': '4px',
            'border-color': '#fff !important',
            'border': '2px solid',
            'margin-left': '2%'
        },
        SideNav:
        {
            'height': '100%',
            'width': '118%',
            'top': '92px',

            'background-color': '#fff',
            'overflow-x': 'hidden',
            'padding-top': '20px',
            'z-index': '1',
            'position': 'inherit'
        },
        DivS:
        {
                'width': '10%',
                'margin-left': '-1%'
        },
        ButtonStyle:
        {
            'borderRadius': '10px',
            'height': '50px',
            'width': '85px',
            'border-color': 'cadetblue',
            'background-color': '#f2f2f2',
            'margin-bottom': '3%'
        },
        DivContent:
        {
            'background': 'transparent',
            'width': '100%',
            'height': '600px'
        },
        Footer:
        {
            'background-color': 'white',
            'position': 'absolute',
            'bottom': '0',
            'width': '100%',
            'height': '40px',
            'color': 'black',
            'margin-left': '2%'            
        },
        Separacion:
        {
         //'margin-top': '-26.5%',
         'margin-top': '-10.5%',
         'margin-left': '11%',
         'width': '90%'

        },
        UlStyle:
        {
            'list-style-type': 'none',
            'margin': '0',
            'padding': '0',
            'width': '25%',
            'background-color': '#f1f1f1',
            'position': 'fixed',
            'height': '100%',
            'overflow': 'auto'
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }


    ClickVerMesaDesocupada(id) {
        if (!this.state.IsSeleccionar)
            this.Redirigir('/paraLlevarDos?tipoV=1&nmesa=' + id);
        else {
            this.ClickTrasladarMesa(id);
        }
    }

    ClickVerMesaOcupada(id,TipoVentaID) {
        if (!this.state.IsSeleccionar)
            this.Redirigir('/OrdenesMesa?id=' + id + '&TipoVentaID=' + TipoVentaID);
        else {
            this.ClickTrasladarMesa(id);
        }
    }

    async ClickMesa(id) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdenByMesa?idMesa=' + id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            if (datos.length > 0) {
                this.ClickVerMesaOcupada(id);
            } else {
                this.ClickVerMesaDesocupada(id);
            }
            
        }
        else {
            alert('error al traer las mesas');
        }
    }

    render() { 
        var c = 0;
        return (

            <div style={this.Estilos.DivContent}>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={100}
                    timeout={3000} />
                <div style={this.Estilos.DivS}>
                    <div style={this.Estilos.SideNav}>
                        <center>
                            {
                                this.state.Ambientes.map((item) => {
                                    return (
                                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title={item.descipcion}
                                            onClick={() => { this.FiltrarAmbiente(item.id) }}
                                        >

                                            <b> {item.descipcion}</b></button>
                                    );
                                })
                            }
                           <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title="Todos" onClick={() => { this.FiltrarAmbiente(5001) }}>

                                <b>Todos</b></button>
                        </center>
                    </div>
                </div>
                <div  style={this.Estilos.Separacion}>

                    <div className="row" style={this.Estilos.DivCenter}>
                        {
                            this.state.Mesas.map((item) => {
                                c++;
                                if (item.isOcupada === true && item.isPagada === false && item.isImpresa === false) {
                                    //Comandada
                                    return (
                                        <div className="mb-3 ml-2 mr-1" style={this.Estilos.MesaOcupada}>
                                            <button className="btn" style={this.Estilos.BtnStyle}
                                                onClick={() => { this.ClickVerMesaOcupada(item.id,1) }} >
                                                <div className="text-left" style={this.Estilos.CapacidadSize} ><b>{item.capacidad}</b> </div>

                                                <div className="row" style={this.Estilos.DivIcon}>
                                                    <div className="col-sm-6" >
                                                        <div>  <i className="fa fa-cutlery fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6 text-right" style={this.Estilos.MarginTopC}>
                                                        <div style={this.Estilos.NroMesa}>
                                                            <b>{item.descipcion}</b>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row" style={this.Estilos.DivIcon2}>
                                                    <div className="col-sm-6">
                                                        <div className="card-text" >  <i className="fa fa-user-circle-o fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="card-text " style={this.Estilos.UserSize} ><b>{item.usuario}</b> </div>

                                                    </div>
                                                </div>

                                                <div className="row" style={this.Estilos.DivIcon3}>
                                                    <div className="col-sm-6">
                                                        <div className="card-text" >  <i className="fa fa-clock-o fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="card-text " style={this.Estilos.HoraSize} ><b>{item.timeOcupada}</b> </div>

                                                    </div>
                                                </div>
                                            </button>
                                        </div>);
                                } else if (item.isOcupada === true && item.isPagada === false && item.isImpresa === true)
                                {//Cta Impresa
                                    return (
                                        <div className="mb-3 ml-2 mr-1" style={this.Estilos.MesaCtaImpresa}>
                                            <button className="btn" style={this.Estilos.BtnStyle}
                                                onClick={() => { this.ClickVerMesaOcupada(item.id,1) }} >
                                                <div className="text-left" style={this.Estilos.CapacidadSize} ><b>{item.capacidad}</b> </div>

                                                <div className="row" style={this.Estilos.DivIcon}>
                                                    <div className="col-sm-6" >
                                                        <div>  <i className="fa fa-cutlery fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6 text-right" style={this.Estilos.MarginTopC}>
                                                        <div style={this.Estilos.NroMesa}>
                                                            <b>{item.descipcion}</b>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row" style={this.Estilos.DivIcon2}>
                                                    <div className="col-sm-6">
                                                        <div className="card-text" >  <i className="fa fa-user-circle-o fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="card-text " style={this.Estilos.UserSize} ><b>{item.usuario}</b> </div>

                                                    </div>
                                                </div>

                                                <div className="row" style={this.Estilos.DivIcon3}>
                                                    <div className="col-sm-6">
                                                        <div className="card-text" >  <i className="fa fa-clock-o fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="card-text " style={this.Estilos.HoraSize} ><b>{item.timeOcupada}</b> </div>

                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    );
                                }
                                else if (item.isOcupada === true && item.isPagada === true && item.isImpresa === true)
                                {//Pagado
                                    return (
                                        <div className="mb-3 ml-2 mr-1" style={this.Estilos.MesaPagada}>
                                            <button className="btn" style={this.Estilos.BtnStyle}
                                                onClick={() => { this.ClickVerMesaOcupada(item.id, 1) }} >
                                                <div className="text-left" style={this.Estilos.CapacidadSize} ><b>{item.capacidad}</b> </div>

                                                <div className="row" style={this.Estilos.DivIcon}>
                                                    <div className="col-sm-6" >
                                                        <div>  <i className="fa fa-cutlery fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6 text-right" style={this.Estilos.MarginTopC}>
                                                        <div style={this.Estilos.NroMesa}>
                                                            <b>{item.descipcion}</b>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row" style={this.Estilos.DivIcon2}>
                                                    <div className="col-sm-6">
                                                        <div className="card-text" >  <i className="fa fa-user-circle-o fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="card-text " style={this.Estilos.UserSize} ><b>{item.usuario}</b> </div>

                                                    </div>
                                                </div>

                                                <div className="row" style={this.Estilos.DivIcon3}>
                                                    <div className="col-sm-6">
                                                        <div className="card-text" >  <i className="fa fa-clock-o fa-2x"></i> </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="card-text " style={this.Estilos.HoraSize} ><b>{item.timeOcupada}</b> </div>

                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        );
                                }
                                else if(item.isOcupada===false){
                                    //Disponible
                                    return (
                                        <div className="mb-3 ml-2 mr-1" style={this.Estilos.MesaLibre} onClick={() => { this.ClickVerMesaDesocupada(item.id) }}>
                                            <button className="btn" style={this.Estilos.BtnStyle}
                                                onClick={() => { this.ClickVerMesaDesocupada(item.id) }} >
                                                <div className="card-text text-left" style={this.Estilos.CapacidadSizeO} ><b>{item.capacidad}</b> </div>

                                                <div className="row" style={this.Estilos.DivIcon4} >
                                                    <div className="col-sm-6">
                                                        <i className="fa fa-cutlery fa-2x"></i>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div style={this.Estilos.NroMesaO}>
                                                            <b>{item.descipcion}</b>
                                                        </div>

                                                    </div>
                                                </div>



                                            </button>

                                        </div>);
                                }

                            })
                        }

                    </div>
                </div>

                <footer style={this.Estilos.Footer}>
                    <table>

                        <tbody>
                            <tr>
                                <td><button className="btn" style={this.Estilos.BtnStyleDemoA}></button> </td>
                                <td><span>Disponible</span></td>
                                <td><button className="btn" style={this.Estilos.BtnStyleDemoS}></button> </td>
                                <td><span>Sentados</span></td>
                                <td><button className="btn" style={this.Estilos.BtnStyleDemoC}></button>  </td>
                                <td><span>Comandada</span></td>
                                <td> <button className="btn" style={this.Estilos.BtnStyleDemoI}></button> </td>
                                <td><span>Cuenta</span></td>
                                <td> <button className="btn" style={this.Estilos.BtnStyleDemoP}></button> </td>
                                <td><span>Pagada</span></td>
                            </tr>
                        </tbody>
                    </table>
                </footer>
            </div>

        );
    }
}
