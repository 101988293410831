import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import { GetUserCookie } from './Globales/Utilidades';

export class TransferirOrdenes extends Component {
    static displayName = TransferirOrdenes.name;
    Orden = {};
    constructor(props) {
        super(props);
        this.state = {
            Mesas: [],
            Ordenes: "",
            filtroUsuario: false,
            usuarios: [],
            SelectUsuario:""
        }
        this.Orden = props.Orden;
        this.TraerUsuarios();
    }

    componentDidMount() {
        
    }
    CerrarModal() {
        //sobreescrito por prop
        this.props.CerrarModal();
    }
    async TraerUsuarios() {
        const sucursalUser = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/usuarios/TraerUsuarios?sucursalID=' + sucursalUser, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ usuarios: datos });
        }
        else {
            alert('error');
        }
    }
    async TraerMesas() {
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ Mesas: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }
    async TransferirOrdenes(id)
    {
 
        var data = {};
        data.idOrden = this.state.Ordenes;
        data.idUsuario = this.Orden.usuariosID;
        data.idUsuarioTransferir = id;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/TransferirOrden', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            
            this.props.ClickBorrarOrden();
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

    PorTransferir(id)
    {
        
        this.setState({ filtroUsuario: true, Ordenes: id });

    }
    MUsuarioClose(estado) {
        this.setState({ filtroUsuario: false });
    }
    Estilos = {
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        Popup:{
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '37%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%'
        },
        BtnStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        MarginTr:
        {
            'margin-bottom': '10%'
        },
        FullAncho: {
            'width': '50%',
            'margin-left': '-20%'
        },
        STextStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'font-size': '13px',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        }, BtnDStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '37%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%'
        },
        PopUpStyleUsuarios:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '37%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%',
            'height': '60%'
        }, BtnDanger:
        {
            'width': '50%',
            'margin-top': '-3%',
            'background-color': '#f80303ad'
        },
        MarginDv:
        {
            'margin-bottom': '2%',
            'width': '23%',
            'align-content': 'center',
            'margin-left': '-10%'
        },
        MarginBtn:
        {
            'margin-top': '25%'
        },
        MarginFlow:
        {
            'overflow-y': 'scroll',
            'height': '60%'

        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

    async ClicSeleccionar() {
        var data = {};
        data.idOrden = this.Orden.id;
        data.MesaID = this.Orden.mesaID;
        data.NewMesa = Number( document.getElementById('numMesa').value);
        var respuesta =await fetch(GetUrlApi() + '/api/Orden/Trasladar', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            this.props.ClickBorrarOrden();
        }
    }

    render() {
        return (
            <div>
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.Popup}
                    onClose={() => { this.props.DesactivarModales() }}
                closeOnDocumentClick>
                <center>
                    <h3>Seleccione Opción</h3>
                        <table className="row" style={this.Estilos.FullAncho}>
                            <tr style={this.Estilos.MarginTr}>
                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.PorTransferir(this.props.Orden.id)}>
                                <button className="btn btn-info" onClick={() => this.PorTransferir(this.props.Orden.id)} style={this.Estilos.BtnStyles}>
                                        <i class="fa fa-file-o fa-2x" aria-hidden="true"></i>
                                    </button>

                                <span style={this.Estilos.SpanStyle}>Esta Orden ({this.props.Orden.id})</span>

                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.PorTransferir("todas")}>
                                <button className="btn btn-info" onClick={() => this.PorTransferir("todas")} style={this.Estilos.BtnStyles}>
                                        <span>
                                            <i class="fa fa-files-o fa-2x" aria-hidden="true"></i>
                                        </span>

                                    </button>

                                    <span style={this.Estilos.SpanStyle}>Todas</span>

                                </td>
                            </tr>
                        </table>
                        <div>
                        <center>
                            <button onClick={() => { this.CerrarModal() }} style={this.Estilos.BtnDanger} className="btn btn-primary">  <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                            </center>
                        </div>
                </center>
                </Popup>
                {(this.state.filtroUsuario) ? (<div className="col-sm-1">
                    <Popup contentStyle={this.Estilos.PopUpStyleUsuarios}
                        position="top center"
                        modal
                        onClose={() => { this.props.DesactivarModales() }}
                        defaultOpen
                        closeOnDocumentClick>
                        <center>

                            <div style={this.Estilos.MarginFlow}>
                            {this.state.usuarios.map((item, index) => {
                                return (
                                    <div style={this.Estilos.MarginDv}>
                                        <div style={this.Estilos.STextStyle} onClick={() => this.TransferirOrdenes(item.id)}>
                                            <button className="btn btn-info" onClick={() => this.TransferirOrdenes(item.id)} style={this.Estilos.BtnDStyles}>
                                                <i class="fa fa-user-circle-o fa-2x" aria-hidden="true"></i>
                                            </button>

                                            <span style={this.Estilos.STextStyle}>{item.nombre} {item.apellido}</span>

                                        </div>
                                    </div>
                                );
                            })

                            }
                           </div>
              
                            <div style={this.Estilos.MarginBtn}>

                            <button onClick={() => { this.CerrarModal() }} style={this.Estilos.BtnDanger} className="btn btn-primary">  <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>

                            </div>
                        </center>
                    </Popup>
                </div>) : (null)}
            </div>
        );
    }
}
