import React, { Component, createRef, useDebugValue, useRef } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetUrlBlinamicApi } from './Globales/VariableGlobales';
import { EspecificarPedido } from './EspecificarPedido';
import { ProdPesables } from './ProdPesables';
import { DetallesProducto } from './DetallesProducto';
import { Modificadores } from './Modificadores';
import { CambioPrecioPopUp } from './CambioPrecioPopUp';
import { CancelarProd } from './CancelarProd';
import { RegalarProd } from './RegalarProd';
import { DescuentosParaLlevar } from './DescuentosParaLlevar';
import { OfertaConsultar } from './OfertaConsultar';
import { Pagar } from './Pagar';
import { DescuentoPuntos } from './DescuentoPuntos';
import { Misc } from './Misc';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { GetNumeroMiles } from './Globales/Utilidades';
import UserProfile from './Globales/PerfilUsuario';
import { TecladoNumerico } from './TecladoNumerico';
import swal from 'sweetalert';
import { TrasladarOrden } from './TrasladarOrden';
import { TransferirOrdenes } from './TransferirOrdenes';
import { PopupMotivoAnulacion } from './PopupMotivoAnulacion';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';
import Popup from "reactjs-popup";
import { PopupPesoProducto } from './PopupPesoProducto';
import { Redirect } from 'react-router';
import { Card, Button, CardBody, CardHeader, Table, Form,CardFooter } from 'reactstrap';
import Keyboard from "./Keyboard";
import { ActivacionGiftcard } from './ActivacionGiftcard';

import {
    isBrowser,
    isMobile
} from "react-device-detect";
import MySpinner from './MySpinner';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { validate, res } from 'react-email-validator';
import MySlider from './Galeria';
import Swal2 from 'sweetalert2';

import $ from 'jquery';
import Swal from "sweetalert2"; 

export class Venta extends Component {
    static displayName = Venta.name;
    IdVenta = 0;
    NumeroNewOrden = 0;
    indice = 0;
    IdGavetaActual = 0;
    UserLogeado = {};
    Caja = GetUserCookie().CajaID;
    urlParams;
    IsReadOnly = false;
    IsPagarHabilitado = false;
    UltimoItemClickeado = null;
    IsParaLlevar = false;
    PayForm = "Gaveta No Habilitada";
    Propina = '';
    Descuentos = '';
    FechaOrder = '';
    PrecioTotal = 0;
    Mesero = '';
    Estacion = '';
    NumOrden = '';
    NumMesa = '';
    TipoVentaIdent = 0;
    NumCliente = '';
    NomCliente = '';
    indicecolorOS = 0;
    indicecolorCL = 0;
    InidiceRemover = 0;//usado para conectar el teclado de autorizacion a cancela producto
    isNivelSuperiorSelected = false;//flag para saber si esta activado un producto de nivel superior para asi poder volver al estado original 29-04-2020 -jg
    IdUltCategoriaSeleccionada = 0;
    TextoIngresado = '';
    CorrelativoIngresado = '';
    ProductosAPedirCorrelativo = [];
    ProductosTienenCorrelativo = [];
    productosCorrelativosObj = { codigo: '', productoscorrelativoslist: [] };
    ProductosCorrelativosLista = [];
    ProductosVerCorrelativosLista = [];
    ListaCorrelativosIngresados = [];
    ProgramasPuntos=[];
    datosGiftcard =[];
    Puntos  = 0;
    SaldoPuntosUsable = 0;
    FactorPuntos = 0;
    PuntosUtilizados = 0
    Numero ;
    SetEfectivo = false;
    NotasDeCredito= [];
    ProductosNC = [];
    ProductosNCOrig = [];
    UsedNotaCreditoID = 0;
    indicecolorCL=0;
    MsgNotaCredito ='';
    MontoNC = 0;
    FolioNC = 0;
    //NCVieneDesdePantallaVenta = false;
    isSelectedGiftcard=false;
    IDserieGiftcard = 0;
    //BotonDifNCActivo = false;
    
    constructor() {
        super();
        this.state = {
            orden: {
                carroProductos: [], descEfectivo: 0
                , numeroDeClientes: 1, descuentoPorcentaje: 0, clientesModel: [], vendedorID: 0, enabledOffer: false, offerPaymentMethodId: 0, puntosXCanje:0
            },
            btnDescCajaDisabled: false,
            cargandoCategorias: true,
            cargandoProductos: false,
            categoriasBotones: [],
            productosSeleccionados: [],
            carroProductos: [],
            precioTotal: 0,
            totalDescuento: 0,
            totalDescuentoOferta: 0,
            cantidad: 1,
            textoBoleta: '',
            TextareaBoletaEncabezado: '',
            dctoOrden: false,
            dctoArt: false,
            dctoEfec: false,
            dctoPorc: 0,
            verDetalle: false,
            activarVerDetalle: false,
            productoClickeado: null,
            activarMisc: false,
            activarModif: false,
            activarCPrecio: false,
            activarCancelar: false,
            activarRegalo: false,
            activarPagar: false,
            activarDescuentos: false,
            activarCantidad: false,
            activarModForz: false,
            activarPopTransferir: false,
            activarPopAnular: false,
            activarTecladoNumCliente: false,
            activarPopNomCliente: false,
            activarTransferir: false,
            activarPesable: false,
            activarPopEmpresaDeliv: false,
            activarPopUpConsultaDeServicio: false,
            activarPopUpReimpresion: false,
            ActivarPopUpNotasCredito:false,
            propinaPorc: 0,
            descEfectivo: 0,
            parametros: { indiceInicio: null },//hasta ahora solo llamado desde cancelarProd.js
            totalPropina: 0,
            isPagado: false,
            mostrarTecladoAut: false,
            NombCliente: '',
            activarPeso: false,
            isAnulada: false,
            isPesable: false,
            volverAlIncio: false,
            mesaSeleccionada: {},
            listoClickeado: false,
            pagadoDelivery: false,
            empresasDelivery: [],
            activarPopUpConsultaStock: false,
            activarPopUpReimprimirHten: false,
            activarPopUpStatusQR: false,
            activarPopUpStock: false,
            activarPopUpStockSucursales: false,
            activarPopUpConsultaOrdenServicio: false,
            OrdenesDeServicio: [],
            activarPopUpPdfOS: false,
            activarPopUpNotaVenta: false,
            activarPopUpConsultaCliente: false,
            activarPopUpConsultaConvenio: false,

            activarPopUpLoginVendedor: false,
            activarPopUpProductoDuplicado: false,
            activarPopUpDireccionRazonSocial: false,
            clienteRut: '',
            TicketID: 0,
            NotaVentaID: 0,
            ReservaID: 0,
            ConsultaClientes: [],
            ProductosDuplicados: [],
            ProductosDuplicadosFiltro: '',
            Vendedores: [],
            VendedoresCarga: false,
            DataCliente: {
                id: '', nombre: '', rut: '', telefono: '', email: '', fechaUltServicio: '', montoUltServicio: '0', fechaUltCompra: '', montoUltCompra: '0', estrellas: 0,
                ciudad: '', comuna: '', direccion: '', girocomercial: '', cantidadNotasCredito: '', tipoCliente: '', razonSocial: '', NombreClasif: '', PosicionClasif:'', comunaID:0,
                tipoClienteID:0, ciudadID : 0, regionID :0 , nombres:'' , apellidos:'',usaConvenio:0,idConvenio:0,limiteCreditoConvenio:0,CantidadDisponible:0,NombreConvenio:'' 
            },
            DataClienteConvenio: {
                usaConvenio: 0, idConvenio: 0, limiteCreditoConvenio: 0, CantidadDisponible: 0, NombreConvenio: '(Sin Convenio)', EsBeneficioInterno:0
            },
            DataVendedor: {
                id: '', nombre: ''
            },
            VendedorActive: true,
            ProdActive: false,
            CrearCliente: false,
            CrearClienteEmpresa: false,
            ModificarPrograma:false,
            IsClienteObligatorio: false,
            SeleccionoCliente: false,
            IsDescuentoRealizado: false,
            IsBoletaSelected: false,
            IsFacturaSelected: false,
            activarPopUpConsultaEcommerce: false,
            activarPopUpConsultaReserva: false,
            NotasVenta: [],
            Reservas: [],
            DetalleReservas: [],
            EsReserva: false,
            DetalleMediosReserva: [],
            DetalleMediosEcommerce: [],
            IsEcommerce: false,
            NeedCorrelativos: false,
            MostrarCorrelativos: false,
            prodsCorrelativos: [],
            productosCorrelativos: { codigo: '', productoscorrelativoslist: [] },
            idCarro: 0,
            TineCorrelativos: false,
            PasePorAsignar: false,
            Iniciando: true,
            TextoIngresado: '',
            showSpinner: false,
            ordenesQty: 0,
            ecommerceQty: 0,
            reservasQty: 0,
            disableOrders: false,
            disableOrdersEcommerce: false,
            disableOrdersReserva: false,
            OrdenDisplay: '',
            estacionStyle: 'text-center ',
            isNumericKeyEntered: false,
            showSpinnerGeneral: false,
            montoAbonado: 0,
            isSale: true,
            identificarClienteForzado: localStorage.getItem('identificarClienteForzado'),
            identificarClienteParaOfertas: localStorage.getItem('identificarClienteParaOfertas'),
            enviarComprobranteWhatsapp: localStorage.getItem('enviarComprobranteWhatsapp'),
            altaClienteForzado: localStorage.getItem('altaClienteForzado'),
            pideNroCelular: localStorage.getItem('pideNroCelular'),
            identificarClienteGenericoEnModal: localStorage.getItem('identificarClienteGenericoEnModal'),
            identificaryCargarClienteGenerico: localStorage.getItem('identificaryCargarClienteGenerico'),
            //identificarConvenioCliente: localStorage.getItem('identificarConvenioCliente'),
            puntosPorProductos:0,
            montoProductosSinPuntos:0,
            DataClientePuntos: { 
                rut:0, idProgramaPuntos:'',descripcionPrograma:'(Sin Programa)',fechaVencimiento:'',
                duracionPuntos:'',saldoPuntos:0,saldoPesos:0, factorCanje:0
            },
            consultarOfertas: false,
            consultarGiftcard: false,
            consultarMovsGiftcard: false,
            DatosMovimientosGiftcard:[],
            consultarDescOffers:false,
            regaloPorcentajeValor : 0,
            Regiones:[],
            Comunas:[],
            CodigoPluStock:0,
            precioTotalDescuentoOferta:0,
            Ambiente: localStorage.getItem('ambiente'),
            tipoHtenSelected: 'pago',
            programaPuntosGenerico: false,
            clienteNuevo: false,
            configAddCliente :{},
            activaDescuentoPuntos : false,
            ErrorExists: false,
            ConsultaConvenios: [],
            activaActGiftcard:false,
            GiftcardSucursal:[],
            EsMetodoDesplegarDatosClienteOfertas:false,
            descItem: false,
            descItemObj: {},
            descCajaEnabled: true,
            descItemConfig: localStorage.getItem('descItem'),
            descGlobalConfig: localStorage.getItem('descGlobal'),
            itemXCantConfig : localStorage.getItem('itemXCantidad'),
            NumDocVenta: 0,
            BotonDifNCActivo: false,
            reqAuthVal: false,
            reqAuth: false,
            isVisibleTeclado: localStorage.getItem('teclado'),
            QREntitySelected: 'BCI',
            diasUsoNC : localStorage.getItem('diasUsoNC'),
            descItemEfec:false,
            usePoints: localStorage.getItem('usePoints'),
            AmountOfPoints:0

        }
        this.onChangeValueHtenTipo = this.onChangeValueHtenTipo.bind(this)
       
        this.handler = this.handler.bind(this)
        this.CerrarModalMaster = this.CerrarModalMaster.bind(this)
        this.ClickEliminar = this.ClickEliminar.bind(this);
        this.ActualizarCarroMaster = this.ActualizarCarroMaster.bind(this);
        this.ActualizarTexto = this.ActualizarTexto.bind(this);
        this.setState2 = this.setState2.bind(this);
        this.ClickBorrarOrden = this.ClickBorrarOrden.bind(this);

        this.setState = this.setState.bind(this);
        this.DesactivarModales = this.DesactivarModales.bind(this);
        this.RemoverItemOrden = this.RemoverItemOrden.bind(this);
        this.AgregarProductoBarcode = this.AgregarProductoBarcode.bind(this);
        this.TraerProducto = this.TraerProducto.bind(this);
        this.EnviarGiftcard = this.EnviarGiftcard.bind(this);
        this.TraerGaveta();
        this.TraerCaja();
       // this.TraerVendedores();
        this.TraerMediosDePago();
       // this.TraerVendedores();

    
        this.myOnChange = this.myOnChange.bind(this);

        if (GetUserCookie().username === "") {
            window.location.href = "/";
        }
       
        
        this.keyBoardControl = this.keyBoardControl.bind(this);
        
    }
    
    
    async  TraerComunas() {
        var region=1;
        try{
            region = document.getElementById('ddlRegion').value;
        }catch(e){
            region=1;
        }
       
   
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetComunas?RegionID=' + region + '&ProvinciaID=0', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var comuns = await respuesta.json();
            this.setState({ Comunas: comuns });

        }
    }
    async  TraerRegiones() {
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetRegiones', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var regions = await respuesta.json();
            this.setState({ Regiones: regions });

        }
    }
    async  TraerConfigAddCliente() {
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/ConfiguracionModalIngresoCliente?SucursalID=' + sucursalID, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var data = await respuesta.json();
            this.setState({ configAddCliente: data });
        }
    }

    DescItemClickHandler(item) {
        
        Swal.fire({
            title: '¿Como desea aplicar el descuento?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Monto',
            denyButtonText: 'Porcentaje',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({descItemEfec : true});
            } else if (result.isDenied) {
                this.setState({descItemEfec : false});
            }else if(result.isDismissed){
                return;
            }
            
            this.setState({ descItemObj: item })
            this.setState({ descItem: true })
          })
     
    }
    addDescItem(descOfertaUpdate) {
        console.log('***************************')
        console.log('****** addDescItem ********')
        console.log('***************************')
        const totaldescOffer = this.state.precioTotalDescuentoOferta
        descOfertaUpdate = isNaN(descOfertaUpdate) ? 0 : descOfertaUpdate
        this.setState({ precioTotalDescuentoOferta: totaldescOffer + descOfertaUpdate })
        // console.log(item)
        // this.QuitarDescuento()
        // const element = document.getElementById('btnDescCaja')
        // element.disabled = true
    }
    validateDescItem() {
        let isDescItemLocal = false
        this.state.orden.carroProductos.map((item) => {
            if(item.isDescItem)
                isDescItemLocal = true
        })
       // const element = document.getElementById('btnDescCaja')
       // element.disabled = isDescItemLocal
    }
    keyBoardControl(e) {
    
        var element = document.getElementById('EntradaText');
        
       // console.log(e.key)
        if (element === null) {
            return;
        }
        
    
        if (e.key==="*" && this.state.itemXCantConfig==='false'){
            return;
        }
        if (e.key === 'Enter' && this.state.CrearCliente){
            this.AgregarCliente();
        }
         
        if (e.key === 'Enter' && this.state.activarPopUpConsultaStock){
            this.EnterFunction(this.state.TextoIngresado);
            return;
        }
        if (this.state.activarPagar || this.state.CrearCliente ||  this.state.activarDescuentos || this.state.ActivarPopUpNotasCredito || this.state.activaActGiftcard){
            return;
        }
        if (e.key === 'Enter' && !this.state.activarPopUpProductoDuplicado && !this.state.activarPopUpLoginVendedor && !this.state.activarDescuentos && !this.state.activarPagar   && !this.state.reqAuth) {
            this.EnterFunction(this.state.TextoIngresado);
            return;
        }
        if (this.state.activarPopUpConsultaOrdenServicio) {
            this.TextoIngresado = "";
            this.setState({ TextoIngresado: this.TextoIngresado });
            element.value = "";
            return;
        }
        if (this.state.activarPopUpConsultaEcommerce) {
            this.TextoIngresado = "";
            this.setState({ TextoIngresado: this.TextoIngresado });
            element.value = "";
            return;
        }
        if (this.state.activarPopUpConsultaCliente) {
            this.TextoIngresado = "";
            this.setState({ TextoIngresado: this.TextoIngresado });
            element.value = "";
            return;
        }
        if (this.state.activarPopUpLoginVendedor) {
            switch (e.key) {
                case 'Escape':
                    this.ClickClear();
                    break;
                case 'Backspace':
                    this.BorrarChar();
                    break;
                case 'Enter':
                    this.LoginVendedor(this.state.TextoIngresado);
                    this.TextoIngresado = "";
                    this.setState({ TextoIngresado: this.TextoIngresado });
                    element.value = "";
                    break;
                case '-':
                case '#':
                case '*':
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    this.TextoIngresado += e.key;
                    element.value = this.TextoIngresado;
                    this.setState({ TextoIngresado: this.TextoIngresado });
                    break;
            }

            
            return;
        }

        if (this.state.reqAuth) {
            var pinboxaut = document.getElementById('PINboxDosAut');
            switch (e.key) {
               case 'Escape':
                    this.ClickClear();
                    break;
                case 'Backspace':
                    this.BorrarChar();
                    break;
                case 'Enter':
                    this.CodAut=this.state.TextoIngresado;
                    this.ValidarUsuario();
                    this.TextoIngresado = "";
                    this.CodAut="";
                    this.setState({ TextoIngresado: this.TextoIngresado });
                    pinboxaut.value = "";
                    break;
                case '-':
                case '#':
                case '*':
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    this.TextoIngresado += e.key;
                    this.CodAut = this.TextoIngresado;
                    pinboxaut.value = this.TextoIngresado;
                    this.setState({ TextoIngresado: this.TextoIngresado });
                    break;
            }

            
            return;
        }

        if (this.state.activaDescuentoPuntos){
            var element = document.getElementById('PINbox');
            if (element!=null) {
                if (e.key === 'd' || e.key==='D'){
                    this.DeleteChar();
                    document.getElementById('PINbox').focus();
                    return;
                   }
                var val = document.getElementById('PINbox').value;
                if (val.length >= 6 && val.length <= 9) {
                    document.getElementById('PINbox').style.fontSize = "3em";
                } else if (val.length > 10 && val.length <= 20) {
                    document.getElementById('PINbox').style.fontSize = "1.5em";

                }
                else if (val.length > 20 && val.length) {
                    document.getElementById('PINbox').style.fontSize = "0.5em";

                } else if (val.length < 6) {
                    document.getElementById('PINbox').style.fontSize = "4em";
                }

                if (e.key === 'Enter') {
                    this.ClickSettingPoints();                  
                }
            }
            switch (e.key) {
                case '-':
                case '#':
                case '*':
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    this.Numero += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
                    break;
            
            }
            return; 
        }
        if(this.state.activarDescuentos){
            if(e.key === 'Escape'){
                this.setState({ activarDescuentos: false })
                this.QuitarDescuento()
            }
                
        }

        if (this.state.activarPopUpConsultaReserva) {
            this.TextoIngresado = "";
            this.setState({ TextoIngresado: this.TextoIngresado });
            element.value = "";
        }
        if(this.state.descItem)
            return

        if (!this.state.NeedCorrelativos) {
            if (!this.state.isSale) {
                return;
            }
            switch (e.key) {
                case 'Escape':
                    this.ClickClear();
                    break;
                case 'Backspace':
                    this.BorrarChar();
                    break;
                case '-':
                case '#':
                case '*':
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    let textInput = document.getElementById('EntradaText').value;
                    this.TextoIngresado += e.key;
                    element.value = this.TextoIngresado;
                    this.setState({ TextoIngresado: this.TextoIngresado });
                    break;
            }
            let iValue = e.keyCode;
            switch (true) {
                case iValue >= 65 && iValue <= 90:
                case iValue === 184:
             
                    let textInput = document.getElementById('EntradaText').value;
                    
                    if (e.ctrlKey){
                        if (e.key==='v' || e.key === 'V'){  //accion de pegar con teclado
                            this.TextoIngresado = textInput;
                            element.value = this.TextoIngresado;
                            this.setState({ TextoIngresado: this.TextoIngresado });
                        }
                    }else{
                        this.TextoIngresado += e.key;
                        element.value = this.TextoIngresado;
                        this.setState({ TextoIngresado: this.TextoIngresado });
                    }

                    break;
            }
         
            if (e.altKey) {
                switch (e.code) {
                    case 'KeyS':
                        this.ClickConsultarStock();
                        break;
                    case 'KeyC':
                        this.ClickConsultaCliente();
                        break;
                    case 'KeyB':
                        this.setBoletaClick();
                        break;
                    case 'KeyF':
                        this.setFacturaClick();
                        break;
                    case 'KeyV':
                        this.ClickLoginVendedor();
                        break;
                    case 'KeyR':
                        this.BotonRegalo();
                        break;   
                    case 'KeyG':
                        this.ClickGiftcardConsultar();
                        break;  
                    case 'KeyO':
                        this.ClickDescOffers();
                        break;     
                    case 'KeyV':
                        this.LimpiarVentana();
                        break;  
                }
            }
        }
    }

    async EnterFunction(textIngresado) {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        if (textIngresado === "") {
            return;
        }

        let codeHexa = this.hex_to_ascii(textIngresado);
        let esRfid = false;
        if (textIngresado.startsWith('#')) {
            textIngresado = textIngresado.replace("#", "");
            if (textIngresado.length <= 11) {
                let ceroAdd = 11 - textIngresado.length;
                for (let i = 0; i < ceroAdd; i++) {
                    textIngresado = "0" + textIngresado;
                }
                textIngresado = "#" + textIngresado;
                document.getElementById('EntradaText').value = textIngresado;
                await this.TraerProductoCorrelativo(textIngresado);
                return;
            }
            return;
        }
        if (codeHexa.startsWith('#') && textIngresado.length === 24) {
            esRfid = true;
            textIngresado = codeHexa;
        } else {
            esRfid = false;
        }

        if (esRfid) {
            //Ir a Buscar Producto Correlativo
            document.getElementById('EntradaText').value = textIngresado;
            await this.TraerProductoCorrelativo(textIngresado);
            return;
        }
        if (this.state.activarPopUpConsultaCliente) {
            this.BuscarCliente();
            return;
        }
        if (this.state.activarPopUpConsultaEcommerce) {
            this.BuscarNotaVenta();
            return;
        }
        if (this.state.activarPopUpConsultaOrdenServicio) {
            this.BuscarOrdenServicio();
            return;
        }
        else {
            if (this.state.activarPopUpConsultaStock) {
                this.ClickEnviar(textIngresado, true);
            }
            else if (this.state.activarPopUpReimprimirHten) {
                this.ReimprimirHten(textIngresado, this.state.tipoHtenSelected);
            } else if(this.state.activarPopUpStatusQR){
                this.getStatusQr(textIngresado, this.state.QREntitySelected)
            }
            else {
                this.TraerProdPorBarcode(textIngresado);
            }
            setTimeout(() => {
                if (document.getElementById('EntradaText')) {
                    document.getElementById('EntradaText').value = '';
                    this.setState({ TextoIngresado: '' });
                }
            }, 500);
        }

    }
    async getStatusQr(id, entity){
        const endpoint = 'wallet/payment/' + id + '?entity=' + entity
        let retorno = false
        const ipHten = localStorage.getItem('ipHten')
        // this.setState({ qrController: controller, qrSignal: signal })

        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'get',
            dataType: 'jsonp',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(respuesta => respuesta.json()).then(async (data) => {
            console.log(data, 'calling statusqr')
            const statusResult = data.payment?data.payment.status:'NOT FOUND'
            if(statusResult === 'APPROVED')
                Swal.fire({
                    title: '<strong> Autorizado </strong>',
                    text: 'El pago ha sido autorizado',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'success',
                }).then(() => { })
            else if(statusResult === 'REJECTED') {
                Swal.fire({
                    title: '<strong>No Autorizado</strong>',
                    text: 'El pago NO ha sido autorizado',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'warning',
                })
            }
            else if(statusResult === 'ERROR') {
                Swal.fire({
                    title: '<strong>Error</strong>',
                    text: 'El pago se encuentra en estado ERROR',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'warning',
                })
            } else if(statusResult === 'CANCELED') {
                Swal.fire({
                    title: '<strong>Cancelado</strong>',
                    text: 'El pago se encuentra en estado Cancelado (No se ha realizado Cobro)',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'warning',
                })
            }else {
                Swal.fire({
                    title: '<strong>Problemas con el pago</strong>',
                    text: 'Ocurrió un problema con el pago, verifique si se le hizo cobro',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'warning',
                });
            }
        })

        

        return retorno

    }
    async ReimprimirHten(numFolio, tipoOrden) {
        const usaCustomCloudService = localStorage.getItem('usaCustomCloudService')
        // const tipoOrden = localStorage.getItem('tipoUltimaOrden')?localStorage.getItem('tipoUltimaOrden'):'pago'
        let comando = "ImpresionVoucherNC";
        if (tipoOrden === "pago") {
            comando = "voucherHten";
        }
        if (usaCustomCloudService === "true") {
            this.setState({ cargando: true });
            const userData = sessionStorage.getItem('userProfile');
            const empresa = userData.Sucursal.direccionDeli.split(".")[0];
            const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
            const printer = "printer_" + userData.caja.descripcion;
            var myBody = {
                "company_id": empresa,
                "store_id": sucursal,
                "order_id": "",
                "printer_id": printer,
                "additional_data": "print://"+comando+"?" + numFolio,
                "exec_path": "C:\\fbhposprint\\"
            };

            await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(data => data.json()).then(data => {
                console.log(data);
                swal({
                    title: "Enviado a imprimir con código " + data.hten_unique_id,
                    icon: "success",
                    text: "Guarde ese código para consultar estado.",
                }).then(() => {
                    this.setState({ cargando: false });
                });
            }).catch(error => {
                swal({
                    title: "Error al querer imprimir",
                    icon: "error",
                    text: "Por favor contacte al administrador del sistema " + error,
                    dangerMode: true,
                }).then(() => {
                    this.setState({ cargando: false });
                });
            });
        } else {
            this.ClickImprimirDirectoHten(numFolio, tipoOrden);
        }
    }

    async ClickImprimirDirectoHten(numFolio, tipoOrden) {
        this.setState({ cargando: true });
        // const tipoOrden = localStorage.getItem('tipoUltimaOrden')?localStorage.getItem('tipoUltimaOrden'):'pago'
        let comando = "ImpresionVoucherNC";
        
        if (tipoOrden === "pago") {
            comando = "voucherHten";
        }
        var myBody = {};
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = "print://"+comando+"?" + numFolio;
        myBody.path = "C:\\fbhposprint\\";
        console.log("VOUCHER HTEN", myBody);

        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then(data => {
            swal({
                title: "Enviado a imprimir correctamente",
                icon: "success",
                text: "Verifique impresión.",
            }).then(() => {
                this.setState({ cargando: false });
            });
        }).catch(() => {
            this.setState({ cargando: false });
            swal({
                title: "No se pudo enviar a imprimir",
                icon: "error",
                text: "Llame a soporte técnico.",
            })
        });
        this.setState({ cargando: false });

    }


    DeleteChar() {
        
        var dato = document.getElementById('PINbox').value;
        var result =  dato.slice(0, -1);
        document.getElementById('PINbox').value = Number(result.toString().replaceAll('.',''));
        this.TextoIngresadoNum = Number(result.toString().replaceAll('.',''));
        this.Numero =Number(result.toString().replaceAll('.',''));

        try {
              document.getElementById('PINbox').value= Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');        
           } catch (error) {    }
          
    }
    async TraerProductoCorrelativo(correlativo) {
        var quitaAlm = correlativo.replace("#", "");
        this.CorrelativoIngresado = correlativo;

        let idxExisteCorrelativo = this.ListaCorrelativosIngresados.indexOf(correlativo);

        if (idxExisteCorrelativo !== -1) {
            swal({
                title: "El correlativo ingresado ya se encuentra asignado.",
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();
            });
            return;
        }
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        let resultado = "001";
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoByCorrelativo?Correlativo=' + quitaAlm + '&sucursalID=' + sucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json())
            .then(res => {
                resultado = res.codigo;
            })
            .catch(function () {
            });

        if (resultado !== "001") {
            document.getElementById('EntradaText').value = resultado;
            await this.ClickEnviarTres(resultado, correlativo);
        }
        else {
            swal({
                title: "El producto no posee stock, no existe o el correlativo no corresponde a la sucursal. ",
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();

            });
        }
    }

    LimpiarVentana() {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });
        this.isSelectedGiftcard=false;
        this.IDserieGiftcard = 0;
        document.getElementById('EntradaText').value = "";
        document.getElementById('BtnBoletaP').style.backgroundColor = 'rgb(222, 235, 249)';
        document.getElementById('BtnFacturaP').style.backgroundColor = 'rgb(222, 235, 249)';
        this.Descuentos = '';
        this.setState({
            orden: {
                carroProductos: [], descEfectivo: 0
                , numeroDeClientes: 1, descuentoPorcentaje: 0, clientesModel: [], vendedorID: 0
            },
            productosSeleccionados: [],
            carroProductos: [],
            cantidad: 1,
            textoBoleta: '',
            TextareaBoletaEncabezado: '',
            dctoOrden: false,
            dctoArt: false,
            dctoEfec: false,
            dctoPorc: 0,
            verDetalle: false,
            activarVerDetalle: false,
            productoClickeado: null,
            activarMisc: false,
            activarModif: false,
            activarCPrecio: false,
            activarCancelar: false,
            activarPagar: false,
            activarDescuentos: false,
            activarCantidad: false,
            activarModForz: false,
            activarPopTransferir: false,
            activarPopAnular: false,
            activarTecladoNumCliente: false,
            activarPopNomCliente: false,
            activarTransferir: false,
            activarPesable: false,
            activarPopEmpresaDeliv: false,
            activarPopUpConsultaDeServicio: false,
            activarPopUpReimpresion: false,
            precioTotal: 0,
            totalDescuento: 0,
            propinaPorc: 0,
            descEfectivo: 0,
            totalPropina: 0,
            parametros: { indiceInicio: null },//hasta ahora solo llamado desde cancelarProd.js
            isPagado: false,
            mostrarTecladoAut: false,
            NombCliente: '',
            activarPeso: false,
            isAnulada: false,
            isPesable: false,
            volverAlIncio: false,
            mesaSeleccionada: {},
            listoClickeado: false,
            pagadoDelivery: false,
            empresasDelivery: [],
            activarPopUpConsultaStock: false,
            activarPopUpReimprimirHten: false,
            activarPopUpStock: false,
            activarPopUpStockSucursales: false,
            activarPopUpConsultaOrdenServicio: false,
            OrdenesDeServicio: [],
            activarPopUpPdfOS: false,
            activarPopUpNotaVenta: false,
            activarPopUpConsultaCliente: false,
            activarPopUpConsultaConvenio: false,
            activarPopUpLoginVendedor: false,
            activarPopUpDireccionRazonSocial: false,
            ActivarPopUpNotasCredito:false,
            clienteRut: '',
            TicketID: 0,
            NotaVentaID: 0,
            ReservaID: 0,
            ConsultaClientes: [],
            ProductosDuplicados: [],
            ProductosDuplicadosFiltro: '',
            Vendedores: [],
            VendedoresCarga: false,
            DataCliente: {
                id: '', nombre: '', rut: '', telefono: '', email: '', fechaUltServicio: '', montoUltServicio: '0', fechaUltCompra: '', montoUltCompra: '0', estrellas: 0,
                ciudad: '', comuna: '', direccion: '', girocomercial: '', cantidadNotasCredito: '', tipoCliente: '', razonSocial: '', NombreClasif: '', PosicionClasif: '',comunaID:0,
                tipoClienteID: 0, ciudadID: 0, regionID: 0, nombres: '', apellidos: '', usaConvenio: 0, idConvenio: 0, limiteCreditoConvenio: 0, CantidadDisponible: 0, NombreConvenio: ''
            },
            //DataClienteConvenio: {
            //    usaConvenio: 0, idConvenio: 0, limiteCreditoConvenio: 0, CantidadDisponible: 0, NombreConvenio: ''
            //},
            DataClienteConvenio: {
                usaConvenio: 0, idConvenio: 0, limiteCreditoConvenio: 0, CantidadDisponible: 0, NombreConvenio: '(Sin Convenio)', EsBeneficioInterno: 0
            },
            DataVendedor: {
                id: '', nombre: ''
            },
            ProdActive: false,
            CrearCliente: false,
            CrearClienteEmpresa: false,
            ModificarPrograma:false,
            IsClienteObligatorio: false,
            SeleccionoCliente: false,
            IsDescuentoRealizado: false,
            IsBoletaSelected: false,
            IsFacturaSelected: false,
            activarPopUpConsultaEcommerce: false,
            activarPopUpConsultaReserva: false,
            NotasVenta: [],
            Reservas: [],
            DetalleReservas: [],
            EsReserva: false,
            DetalleMediosReserva: [],
            DetalleMediosEcommerce: [],
            IsEcommerce: false,
            NeedCorrelativos: false,
            MostrarCorrelativos: false,
            prodsCorrelativos: [],
            productosCorrelativos: { codigo: '', productoscorrelativoslist: [] },
            idCarro: 0,
            TineCorrelativos: false,
            PasePorAsignar: false,
            ordenesQty: 0,
            ecommerceQty: 0,
            reservasQty: 0,
            disableOrders: false,
            disableOrdersEcommerce: false,
            disableOrdersReserva: false,
            OrdenDisplay: '',
            estacionStyle: 'text-center ',
            isNumericKeyEntered: false,
            montoAbonado: 0,
            isSale: true,
            identificarClienteForzado: localStorage.getItem('identificarClienteForzado'),
            identificarClienteParaOfertas: localStorage.getItem('identificarClienteParaOfertas'),
            enviarComprobranteWhatsapp: localStorage.getItem('enviarComprobranteWhatsapp'),
            altaClienteForzado: localStorage.getItem('altaClienteForzado'),
            pideNroCelular: localStorage.getItem('pideNroCelular'),
            identificarClienteGenericoEnModal: localStorage.getItem('identificarClienteGenericoEnModal'),
            identificaryCargarClienteGenerico: localStorage.getItem('identificaryCargarClienteGenerico'),
            //identificarConvenioCliente: localStorage.getItem('identificarConvenioCliente'),
            datosD: {},
            TagStock: {},
            puntosPorProductos:0,
            montoProductosSinPuntos:0,
            DataClientePuntos: { 
                rut: 0, idProgramaPuntos:'',descripcionPrograma:'(Sin Programa)',fechaVencimiento:'',
                duracionPuntos:'',saldoPuntos:0,saldoPesos:0, factorCanje:0
            } ,
            regaloPorcentajeValor : 0,
            activaDescuentoPuntos:false,
            ErrorExists: false,
            ConsultaConvenios: [],
            GiftcardSucursal:[],
            activaActGiftcard:false,
            EsMetodoDesplegarDatosClienteOfertas: false,
            isVisibleTeclado: localStorage.getItem('teclado'),
            diasUsoNC : localStorage.getItem('diasUsoNC'),
            descItemEfec:false,
            usePoints: localStorage.getItem('usePoints'),
            AmountOfPoints:0
        });

        this.IdVenta = 0;
        this.NumeroNewOrden = 0;
        this.CorrelativoIngresado = '';
        this.ProductosAPedirCorrelativo = [];
        this.ProductosTienenCorrelativo = [];
        this.productosCorrelativosObj = { codigo: '', productoscorrelativoslist: [] };
        this.ProductosCorrelativosLista = [];
        this.ProductosVerCorrelativosLista = [];
        this.ListaCorrelativosIngresados = [];
        this.NotasDeCredito= [];
        this.ProductosNC = [];
        this.ProductosNCOrig=[];
        this.UsedNotaCreditoID = 0;
        this.MsgNotaCredito='';
        this.setBoletaClick();
        if (!this.state.Iniciando) {
            this.ActualizarTexto();

        }
        this.setState({ Iniciando: false });
        setTimeout(() => {
            this.setState({
                precioTotal: 0,
                totalDescuento: 0,
                propinaPorc: 0,
                descEfectivo: 0,
                totalPropina: 0,
                precioTotalDescuentoOferta: 0,
            });
        }, 100);
         
        //this.NCVieneDesdePantallaVenta = false;
        if(this.state.descGlobalConfig === 'true'){
            const element = document.getElementById('btnDescCaja')
            element.disabled = false
        }
        //document.getElementById('EntradaText').focus();
        document.getElementById('LimpiarVentana').blur();

         this.getNumDocVenta();
         if (this.state.identificaryCargarClienteGenerico == 'true') { this.MontajeyCargaDatosClienteGenericoParaVenta(); }

    }


    QuitarDescuento() {
        this.Descuentos = '';
        delete this.state.orden.dctoOrden;
        delete this.state.orden.descEfectivo;
        this.state.orden.puntosXCanje=0;
        this.SetEfectivo=false;
        this.setState({
            IsDescuentoRealizado: false
        });
        this.DesactivarModales()
    }

    focusChangeState(camp) {
        if (camp === 1) {
            this.setState({ VendedorActive: true, ProdActive: false });
        } else if (camp === 2) {
            this.setState({ VendedorActive: false, ProdActive: true });
        }
    }

   async setBoletaClick() {
        document.getElementById('BtnBoletaP').style.backgroundColor = 'rgb(178 234 182)';
        document.getElementById('BtnFacturaP').style.backgroundColor = 'rgb(222, 235, 249)';
        await this.setState({ IsBoletaSelected: true, IsFacturaSelected: false });
        if (this.state.Ambiente === 'MAISA') {
            this.getNumDocVenta();
        }
    }
    async setFacturaClick() {
        let isOk = true
        if (this.state.Ambiente=='MAISA'){
            //Valida si tiene folios disponibles para procesar una factura
            var validaFolios = await fetch(GetUrlApi() + "/api/Cajas/ValidaCajaFolios/?tipoDocumento=F&sucursalID=" + GetUserCookie().SucursalID + "&cajaID=" + GetUserCookie().CajaExternaID, {
                headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                },
            });

            if (validaFolios.ok) {
                var datos = await validaFolios.json();
                
                if (datos.codError != 0) {
                    await Swal.fire({
                        title: datos.descError,
                        icon: "error"
                    }).then(() => {
                        isOk =  false
                        return;                       
                    });
                    
                    return;
                }
                 
         
         }    
        }
        document.getElementById('BtnFacturaP').style.backgroundColor = 'rgb(178 234 182)';
        document.getElementById('BtnBoletaP').style.backgroundColor = 'rgb(222, 235, 249)';
    
        if (!this.state.IsFacturaSelected) {
             if (this.state.DataCliente.id !== '') {
                 await this.setState({ IsBoletaSelected: false, IsFacturaSelected: true, activarPopUpConsultaCliente: false });
                 if ((this.state.DataCliente.direccion === '' || this.state.DataCliente.razonSocial === '') && this.state.DataCliente.id !== '') {
                     await  this.setState({ activarPopUpDireccionRazonSocial: true });
                     document.getElementById('direccionAddCliente').value = this.state.DataCliente.direccion;
                }
            } 
        } 
        if(isOk){
            document.getElementById('BtnFacturaP').style.backgroundColor = 'rgb(178 234 182)';
            document.getElementById('BtnBoletaP').style.backgroundColor = 'rgb(222, 235, 249)';
            if (!this.state.IsFacturaSelected) {
                if (this.state.DataCliente.id !== '') {
                   await this.setState({ IsBoletaSelected: false, IsFacturaSelected: true, activarPopUpConsultaCliente: false });
                    if ((this.state.DataCliente.direccion === '' || this.state.DataCliente.razonSocial === '') && this.state.DataCliente.id !== '') {
                        this.setState({ activarPopUpDireccionRazonSocial: true });
                    }
                } else {
                    await this.setState({ IsBoletaSelected: false, IsFacturaSelected: true, activarPopUpConsultaCliente: true });
                    setTimeout(function () {
                        $('#RutCliente').focus();
                        }, 50);
            }

           
         }
              
         this.getNumDocVenta();
    }
}
        


    CancelarAsignacion() {
        this.setState({ NeedCorrelativos: false, TineCorrelativos: false });
    }

    TerminarAsignacion() {

        document.getElementById('EntradaText').value = '';
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });


        let cantidadCorrelativosIngresados = 0;

        this.ProductosVerCorrelativosLista.map((item) => {
            cantidadCorrelativosIngresados += 1;
        })


        if (this.ProductosAPedirCorrelativo.length === 0) {
            this.ProductosAPedirCorrelativo = [];
            this.setState({ NeedCorrelativos: false, TineCorrelativos: false, PasePorAsignar: true });
            this.ClickPagar();
        }

    }

    CancelarVerAsignacion() {
        document.getElementById('EntradaText').value = '';
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        //this.ProductosTienenCorrelativo = [];
        this.setState({ MostrarCorrelativos: false });
    }

    TerminarVerAsignacion() {

        document.getElementById('EntradaText').value = '';
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        this.ProductosTienenCorrelativo = [];
        this.setState({ MostrarCorrelativos: false, TineCorrelativos: false });
        this.ClickPagar();
    }


    async TraerProdPorBarcode(barcode) {
        this.ClickEnviar(barcode, true);
    }


    async TraerGaveta() {
        var SucursalID = GetUserCookie().SucursalID;
        var CajaID = GetUserCookie().CajaID;
        var UsuarioID = GetUserCookie().id;
        
        //Maisa
        if (this.state.Ambiente == 'MAISA'){
            var respuesta = await fetch(GetUrlApi() + '/api/Gavetas/ObtenerEstadoGaveta?SucursalID=' + SucursalID + '&CajaID=' + CajaID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (respuesta.ok) {
                var data = await respuesta.json();

                 if (data.estado === 1 /*'Habilitada'*/) {
                    this.IsPagarHabilitado = true;
                    this.PayForm = "Pagar";
                } else if (data.estado === 2 /*'NoHabilitada'*/) {
                    this.IsPagarHabilitado = false;
                    this.PayForm = "Caja No Habilitada"
                } else if (data.estado === 3 /*'CierrePendiente'*/){
                    this.IsPagarHabilitado = false;
                    let fechaCierre = new Date(data.fecha);
                    this.PayForm = "Falta salida cajero (cierre caja) del día " + (fechaCierre.getDate() < 10 ? ('0' + fechaCierre.getDate().toString()) : fechaCierre.getDate().toString()) + '-' + ((fechaCierre.getMonth() + 1) < 10 ? ('0' + (fechaCierre.getMonth() + 1).toString()) : (fechaCierre.getMonth() + 1).toString()) + '-' + (fechaCierre.getFullYear().toString())
                }else{
                    this.IsPagarHabilitado = false;
                    this.PayForm = "Caja No Habilitada"
                }   
            } else {

                this.IsPagarHabilitado = false;
    
                document.getElementById('btnPagar').disabled = true;
                this.PayForm = "Caja No Habilitada"
                document.getElementById('btnPagar').innerHTML = '<i class="fa fa-ban fa-2x" aria-hidden="true"></i>';
            }
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/Gavetas/ObtenerGavetaAbierta?SucursalID=' + SucursalID + '&CajaID=' + CajaID + '&UsuarioID=' + UsuarioID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var data = await respuesta.json();
            if (data) {
                this.IsPagarHabilitado = true;
                if (document.getElementById('btnPagar')) {
                    //document.getElementById('btnPagar').disabled = false;
                }
                this.PayForm = "Pagar";
                //document.getElementById('btnPagar').innerHTML = '<i className="fa fa-usd fa-2x" aria-hidden="true"></i>';
            } else {
                this.IsPagarHabilitado = false;

                if (document.getElementById('btnPagar'))
                    //document.getElementById('btnPagar').disabled = true;
                    //document.getElementById('btnListo').disabled = true;
                    this.PayForm = "Gaveta No Habilitada"
                //document.getElementById('btnPagar').innerHTML = '<i className="fa fa-ban fa-2x" aria-hidden="true"></i>';
            }

        } else {
            this.IsPagarHabilitado = false;

            document.getElementById('btnPagar').disabled = true;
            //document.getElementById('btnListo').disabled = true;
            this.PayForm = "Gaveta No Habilitada"
            document.getElementById('btnPagar').innerHTML = '<i className="fa fa-ban fa-2x" aria-hidden="true"></i>';
        }
    }


    async TraerVendedores() {
        var SucursalID = GetUserCookie().SucursalID;
        var CajaID = GetUserCookie().CajaID;
        var UsuarioID = GetUserCookie().id;
        var respuesta = await fetch(GetUrlApi() + '/api/Vendedor/TraerVendedor', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var data = await respuesta.json();
            if (data.length > 0) {
                this.setState({ Vendedores: data, VendedoresCarga: true });
            } else {
                this.setState({ Vendedores: data, VendedoresCarga: false });
            }
        }
    }

    async TraerCaja() {
        var respuesta = await fetch(GetUrlApi() + '/api/Cajas/' + GetUserCookie().CajaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.Caja = datos;
            this.IdGavetaActual = datos.idUltimaGavetaFk;
            //#26112019.RFD no va a la api a buscar usuario
            var orden = this.state.orden;
            orden.usuariosID = UserProfile.getUser().id;
            orden.numCaja = this.Caja.id;
            this.setState({ orden: orden });
            this.ActualizarTexto();
        }
        else {
            alert('error');
        }
    }


    async TraerNotasDeVenta() {
        try {
            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetNotasVentaEcommerce?RutCliente=' + "" + '&NombreCliente=' + "" + '&NumeroNotaVenta=' + "", {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    this.setState({ NotasVenta: result });
                }
            );

        } catch (err) {
            if (!this.state.volverAlIncio)
                swal('No se pudo traer las ordenes de Servicio verifique su conexion a internet');
        }
    }

    async setState2(obj) {
        await this.setState(obj);
        this.ActualizarTexto();
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuario.id !== 1 && user.tipoUsuario.id !== 6) {
            this.setState({ volverAlIncio: true });
        }
        this.LimpiarVentana();

        this.urlParams = new URLSearchParams(this.props.location.search);
        var tipoVenta = Number(this.urlParams.get('tipoV'));
        localStorage.setItem('tipoVentaID', tipoVenta);
        this.TipoVentaIdent = tipoVenta;
        if (!Number(this.urlParams.get('nmesa'))) {
            this.IsParaLlevar = true;
        }
        if (document.getElementById('btnImprimir')) {
            document.getElementById('btnImprimir').disabled = true;
        }

        this.CargarParametros().then(() => {
            var numMesa = Number(this.urlParams.get('nmesa'));
            if (numMesa > 0 || this.state.orden.mesaID) {
                if (numMesa > 0) {
                    this.TraerMesa(numMesa);
                } else {
                    this.TraerMesa(this.state.orden.mesaID);
                }

            }
            if (numMesa > 0 && this.IdVenta === 0) {

                this.setState({ activarTecladoNumCliente: true });
            }
        });
        this.setBoletaClick();
        document.addEventListener("keyup", this.keyBoardControl, false);

        const loginSeller = localStorage.getItem('loginSeller');

        if (loginSeller === 'true') {
            this.ClickLoginVendedor();
        }else{
            // Nueva config para identificar cliente forzado antes de vender.
            if (this.state.identificarClienteForzado == 'true') {
                this.setState({ activarPopUpConsultaCliente: true });
            }
        }

        if(this.state.Ambiente === 'MAISA'){
            this.setState({programaPuntosGenerico:true})
     

        }                             
        this.TraerConfigAddCliente();
        this.TraerRegiones();
        this.TraerComunas();
        this.BuscaProgramasPuntos();
        this.getNumDocVenta();
        if (this.state.identificarClienteGenericoEnModal == 'true') { this.MontajeDatosenModalClienteGenericoParaVenta(); }

        if (this.state.identificaryCargarClienteGenerico == 'true') { this.MontajeyCargaDatosClienteGenericoParaVenta(); }
    }

    async TraerMediosDePago() {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/TbMedio');
            if (!categorias.ok) {
                throw 'problema al traer los medios de pago';
            }
            var datos = await categorias.json();
            var MediosPagoVisibles = datos.filter(t => t.isHabilitado);
            this.setState({ MediosPago: datos, MediosPagoVisibles: MediosPagoVisibles, cargandoMedios: false });
        } catch (err) {
            console.log(err);
        }
        
    }


    
    async GetProductosNC (notaID, folio, monto, fecha){
        try {
            var sucID = GetUserCookie().SucursalID;
             
            var d1 = new Date();
            d1.setHours(0,0,0,0);
            
            const [day, month, year] = fecha.split('-');
            var d2 = new Date(+year, month - 1, +day);

            var days = Number(this.state.diasUsoNC);

            var fechaMaxUso = new Date(d2);
            fechaMaxUso.setDate(fechaMaxUso.getDate() + days);

            if (fechaMaxUso < d1){
                swal({
                    title: "No se puede utilizar esta Nota de Crédito, ya que ya expiró el tiempo de su devolución.",
                    icon: "error",
                    dangerMode: true,
                });
                return;
            }
            else{
                var respuesta = await fetch(GetUrlApi() + '/api/NotasDeCredito/GetProductosNotaCredito?NotaCreditoID=' + notaID + '&SucursalID=' + sucID, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json()).then(
                    (result) => {
                      
                        if(result.length>0){
                             
                            if (folio != "" && folio != 'undefined'){
                                this.ProductosNC = result;
                                //se ocupa este array para que result no quede enlazado al eliminar reg (splice ProductosNC)
                                let arr =[];
                                result.forEach((value) => {
                                    arr.push(value);
                                });
                                this.ProductosNCOrig = arr; // productos de la nc
                                this.UsedNotaCreditoID = notaID;
                                this.MsgNotaCredito = 'Nota de crédito : ' + folio + ' - Monto: $ ' + GetNumeroMiles(monto) ;
                                this.MontoNC = monto;
                                this.FolioNC =folio;
                                //this.NCVieneDesdePantallaVenta = true;
                                this.setState({ActivarPopUpNotasCredito:false});
                            }
                            else{
                                swal({
                                    title: "La Nota de Credito ID: " + notaID + ", con monto: $" + GetNumeroMiles(monto) +", fecha: "+ fecha + ", fue generada de forma incorrecta, por favor contacte a soporte.",
                                    icon: "warning",
                                    dangerMode: true,
                                });
                                this.setState({ActivarPopUpNotasCredito:false});
                                return;
                            }
                           
                        }
                        else{
                            swal({
                                title: "No se pudieron obtener los productos asociados a la Nota de crédito seleccionada.",
                                icon: "warning",
                                dangerMode: true,
                            }); 
                            this.setState({ActivarPopUpNotasCredito:false});
                        }
                        return;
                    });
    
                     var neworden = this.state.orden;
                     await this.GetPointsCanjeNC(notaID);
                    neworden.puntosXCanje = this.PuntosUtilizados;
                    this.setState ({orden : neworden});
            }
           
        }
        catch(e){}
    }  

    //busca los puntos si es que existen que fueron revertidos en la nc (utilizados como canje), para volver a utilizarlos en la venta
    async   GetPointsCanjeNC  (notaID){
        try {
            var respuesta = await fetch(GetUrlApi() + '/api/NotasDeCredito/GetPointsCanjeNC?NotaCreditoID=' + notaID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(
                (result) => {
                   this.PuntosUtilizados = result;
                });

        }
        catch(e){
            return 0;
        }
    }

    GetHtmlPopUpNotasDeCredito() {
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleNotasCredito}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.StyleDivOrdenService}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={this.Estilos.ThStyle1}>N°</th>
                                <th style={this.Estilos.ThStyle1}>Rut</th>
                                <th style={this.Estilos.ThStyle2}>Nombre</th>
                                <th style={this.Estilos.ThStyle1}>OrdenID</th>
                                <th style={this.Estilos.ThStyle3}>Numero Comprobante</th>
                                <th style={this.Estilos.ThStyle3}>Folio </th>
                                <th style={this.Estilos.ThStyle3}>Id </th>
                                <th style={this.Estilos.ThStyle3}>Fecha</th>
                                <th style={this.Estilos.ThStyle3}>Total</th>
                                <th style={this.Estilos.ThStyle3}>SubTotal</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.NotasDeCredito.map((item, index) => {
                                    if (this.indicecolorCL === index) {
                                        this.indicecolorCL = index;
                                        return (<tr style={this.Estilos.ColorTrOSPrimary} onClick={() => { this.GetProductosNC(item.id, item.folioBsale, item.totalNC,item.fechaDevolucion) }}>
                                            <td>{item.id}</td>
                                            <td>{item.rut}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.ordenID}</td>
                                            <td>{item.numeroComprobante}</td>
                                            <td>{item.folioBsale}</td>
                                            <td>{item.idBsale}</td>
                                            <td>{item.fechaDevolucion}</td>
                                            <td>{item.totalNC}</td>
                                            <td>{item.subTotalNC}</td>
                                        </tr>);
                                    } else if (this.indicecolorCL !== index) {
                                        this.indicecolorCL = index + 1;
                                        return (<tr style={this.Estilos.ColorTrOSSecond} onClick={() => { this.GetProductosNC(item.id,item.folioBsale, item.totalNC,item.fechaDevolucion) }}>
                                            <td>{item.id}</td>
                                            <td>{item.rut}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.ordenID}</td>
                                            <td>{item.numeroComprobante}</td>
                                            <td>{item.folioBsale}</td>
                                            <td>{item.idBsale}</td>
                                            <td>{item.fechaDevolucion}</td>
                                            <td>{item.totalNC}</td>
                                            <td>{item.subTotalNC}</td>
                                        </tr>);
                                    }

                                })
                            }
                        </tbody>
                    </table>

                </div>

            </Popup>
        );
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.keyBoardControl, false);
    }

    async TraerMesa(idMesa) {
        const url = GetUrlApi() + '/api/Mesas/' + idMesa;
        const mesa = await fetch(url);


        if (mesa.ok) {
            var datos = await mesa.json();
            this.setState({ mesaSeleccionada: datos });
        }
    }

    async CargarParametros() {

        const idOrden = this.urlParams.get('id');
        const isPagar = this.urlParams.get('pagar') === 'true';
        const isEditar = this.urlParams.get('editar') === 'true';
        const tipoVenta = this.urlParams.get('tipoV') === 'true';
        if (isEditar) {
            await this.CargarOrden(idOrden);
        }
        else {
            if (this.TipoVentaIdent === 4 && !this.state.orden.nombreCliente) {
                this.setState({ activarPopNomCliente: true });

            }
        }
        this.ActualizarTexto();
        if (isPagar) {
            await this.setState({ activarPagar: true });
        }
    }

    ActualizarCarroMaster(nuevoCarro) {
        this.setState({ carroProductos: nuevoCarro });
    }

    handler(someValue, mod, isPorKilo, montoAumento) {
        this.AgregarProducto(someValue, mod, false, montoAumento);
    }

    CerrarModalMaster() {
        
        this.CerrarModal();
        this.DesactivarModales();
        document.getElementById('EntradaText').focus()

    }


    async TraerCategorias() {
        try {
            var SucursalID = GetUserCookie().SucursalID;
            const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            const datos = await categorias.json();
            this.setState({ categoriasBotones: datos, cargandoCategorias: false });
            this.TraerProductos(this.state.categoriasBotones[0].id, 'categorias0')

        } catch (err) {
            if (!this.state.volverAlIncio)
                swal('No se pudo traer las categorias verifique su conexion a internet');
        }
    }

    async TraerOrdenesDeServicio() {
        try {
            let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
            const OrdenesServicio = await fetch(GetUrlApi() + '/api/Orden/TraerOrdenesDeServicio?SucursalID=' + sucursalID);
            if (!OrdenesServicio.ok) {
                throw 'problema al traer las ordenes de servicio';
            }
            const datos = await OrdenesServicio.json();
            this.setState({ OrdenesDeServicio: datos });
        } catch (err) {
            if (!this.state.volverAlIncio)
                swal('No se pudo traer las ordenes de Servicio verifique su conexion a internet');
        }
    }

    async TraerReservasCliente(idCliente) {
        try {
            var SucursalID = GetUserCookie().SucursalID;
            const reservas = await fetch(GetUrlApi() + '/api/Clientes/GetReservasCliente?clienteId=' + idCliente);
            if (!reservas.ok) {
                throw 'problema al traer las ordenes de servicio';
            }
            const datos = await reservas.json();
            this.setState({ Reservas: datos, reservasQty: datos.length });

        } catch (err) {
            if (!this.state.volverAlIncio)
                swal('No se pudo traer las reservas verifique su conexion a internet');
        }
    }
    async TraerOrdenesDeServicioCliente(idCliente) {
        try {
            var SucursalID = GetUserCookie().SucursalID;
            const OrdenesServicio = await fetch(GetUrlApi() + '/api/Orden/TraerOrdenesDeServicioCliente?IdCliente=' + idCliente);
            if (!OrdenesServicio.ok) {
                throw 'problema al traer las ordenes de servicio';
            }
            const datos = await OrdenesServicio.json();
            this.setState({ OrdenesDeServicio: datos, ordenesQty: datos.length });

        } catch (err) {
            if (!this.state.volverAlIncio)
                swal('No se pudo traer las ordenes de Servicio verifique su conexion a internet');
        }
    }


    async TraerProductos(idCategoria, idBtn) {
        this.IdUltCategoriaSeleccionada = idCategoria;
        var botones = document.getElementsByName('botonesCategorias');
        botones.forEach((value) => {
            value.style.backgroundcolor = '';
            value.style.color = '';
        });
        await this.setState({ cargandoProductos: true });
        try {
            var SucursalID = GetUserCookie().SucursalID;
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            var datos = await categorias.json();
            if (GetUserCookie().Sucursal.configPOS.isStockHabilitado) {
                datos.forEach((producto) => {
                    producto.stock -= this.ContProductosSeleccionado(producto.id);
                });
            }

            this.setState({ productosSeleccionados: datos, cargandoProductos: false });
        } catch (err) {

        }
    }

    async TraerProductosNivSupr(idCategoria) {

        try {
            var SucursalID = GetUserCookie().SucursalID;
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            var datos = await categorias.json();

            this.setState({ productosSeleccionados: datos, cargandoProductos: false });
        } catch (err) {

        }
    }

    async TraerProducto(id) {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorId?id=' + id);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            var datos = await categorias.json();

            return datos;
        } catch (err) {
            return -1;
        }
        return 0;
    }

    async GenerarOrden() {
        var data = this.state.orden;
        const a = 0;
        data.Pagado = false;
        data.TiposVentaID = Number(this.urlParams.get('tipoV'));
        if (data.TiposVentaID === 0 || !data.TiposVentaID) {
            data.TiposVentaID = 2;
        }
        data.MesaID = Number(this.urlParams.get('nmesa'));
        if (!data.MesaID || data.MesaID === 0) {
            data.MesaID = null;
        }
        data.descuentoPorc = this.state.orden.descuentoPorc;
        data.DescEfectivo = this.state.orden.dctoEfec;

        //Agregar Lista De Correlativos Producto
        this.state.orden.carroProductos.map((items) => {
            let ProdSplit = items.desprod.split(";");
            let codigoProd = ProdSplit[0];
            items.Correlativos = [];
            this.ProductosVerCorrelativosLista.map((itemsDos) => {

                if (items.idCarro === itemsDos.idCarro) {
                    items.Correlativos.push(itemsDos.correlativo);
                }
            })

        })
        data.carroProductos = this.state.orden.carroProductos;
        data.propinaPorc = this.state.orden.propinaPorc;
        data.DctoOrden = this.state.orden.dctoOrden;
        data.DctoArt = this.state.orden.dctoArt;
        data.Total = this.state.precioTotal;
        data.Subtotal = this.state.precioTotal;
        data.SubTotalOffers = this.GetTotalWithOffers();
        data.GavetaID = this.IdGavetaActual;
        data.usuariosID = GetUserCookie().id;
        data.CajaID = this.Caja.id;
        data.clientesModel = null;
        data.vendedorID = document.getElementById('CodigoVendedor').value;
        if (GetUserCookie().Sucursal.configPOS.isCodigoVendedorActivo) { }
        if (data.vendedorID === "") {
            data.vendedorID = null;
        } else {
            let datosFiltrados = this.state.Vendedores.filter(d => d.codigoVendedor === data.vendedorID);
            if (datosFiltrados.length > 0) {
                data.vendedorID = datosFiltrados.id;
            } else {
                data.vendedorID = null;
            }
        }

        if (this.state.TicketID !== 0) {
            data.TicketID = this.state.TicketID.toString();
        } else {
            data.TicketID = null;
        }

        if (this.state.NotaVentaID !== 0) {
            data.NotaVentaID = this.state.NotaVentaID.toString();
        } else {
            data.NotaVentaID = null;
        }

        if (this.state.ReservaID !== 0) {
            data.ReservaID = this.state.ReservaID.toString();
        } else {
            data.ReservaID = null;
        }

        if (this.state.DataCliente.rut !== '') {
            data.NombreCliente = this.state.DataCliente.rut;
        }
        data.SucursalID = GetUserCookie().SucursalID;
        if (data.usuariosID === 0) {
            data.usuariosID = 1;
        }
        data.UsuarioVendedorPosID = this.state.orden.UsuarioVendedorPosID;
        let jsons = JSON.stringify(data);
        let mensajes = "";
        var respuesta = await fetch(GetUrlApi() + '/api/Orden', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            const res = await respuesta.json();
            if (!res.respuestaStock) {
                return;
            }
            mensajes = res.respuestaStock.mensaje;
            if (res.respuestaStock.mensaje === 'Ok') {
                data.ID = res.id;

                this.IdVenta = res.id;
                
                this.NumeroNewOrden = res.numOrden;
                if(this.state.Ambiente="MAISA"){
                    data.ventaID = res.ventaID;
                }
                


                if (document.getElementById('btnImprimir')) {
                    document.getElementById('btnImprimir').disabled = false;
                }
                this.setState({ orden: data });
                this.ActualizarTexto();
                this.RemoverProdComanda();
            }
        }
        else {
            alert('error');
        }
        return mensajes;
    }

    RemoverProdComanda() {//cambia a false el campo is comanda, para no crear productos de comanda repetidos
        if (!GetUserCookie().Sucursal.configPOS.isKitchenDisplay) {
            return;
        }
        var nuevoCarro = this.state.carroProductos;
        nuevoCarro.forEach((item) => {
            item.isComanda = false;
        });
        this.setState({ carroProductos: nuevoCarro });
    }

    async ActualizarOrden() {
        var data = this.state.orden;
        const a = 0;
        var urlParams = new URLSearchParams(this.props.location.search);
        data.ID = this.IdVenta;
        data.Pagado = false;
        try {
            data.TiposVentaID = this.state.orden.tiposVenta.id;
        } catch (err) {
            data.TiposVentaID = Number(this.urlParams.get('tipoV'));
            if (!data.TiposVentaID) {
                data.TiposVentaID = 1;
            }
        }
        if (this.state.orden.mesaID) {
            data.MesaID = this.state.orden.mesaID;
        } else if (Number(this.urlParams.get('nmesa'))) {
            data.MesaID = Number(this.urlParams.get('nmesa'));
        }
        else if (!data.MesaID || data.MesaID === 0) {
            data.MesaID = null;
        }

        this.state.orden.carroProductos.map((items) => {
            let ProdSplit = items.desprod.split(";");
            let codigoProd = ProdSplit[0];
            items.Correlativos = [];
            this.ProductosVerCorrelativosLista.map((itemsDos) => {

                if (items.idCarro === itemsDos.idCarro) {
                    items.Correlativos.push(itemsDos.correlativo);
                }
            })

        })


        data.carroProductos = this.state.orden.carroProductos;



        data.descuentoPorc = this.state.orden.descuentoPorc;
        data.DescEfectivo = this.state.orden.dctoEfec;
        data.carroProductosDos = this.state.orden.carroProductos;
        data.propinaPorc = this.state.orden.propinaPorc;
        data.DctoOrden = this.state.orden.dctoOrden;
        data.DctoArt = this.state.orden.dctoArt;
        data.Total = this.GetTotal();
        data.SubTotalOffers = this.GetTotalWithOffers();
        data.Subtotal = this.state.precioTotal;
        data.GavetaID = this.IdGavetaActual;
        data.usuariosID = GetUserCookie().id;
        data.CajaID = this.Caja.id;
        data.SucursalID = GetUserCookie().SucursalID;
        data.clientesModel = null;
        if (this.state.DataCliente.rut !== '') {
            data.NombreCliente = this.state.DataCliente.rut;
        }
        data.vendedorID = this.state.orden.vendedorID;


        if (this.state.TicketID !== 0) {
            data.TicketID = this.state.TicketID.toString();
        } else {
            data.TicketID = null;
        }

        if (this.state.NotaVentaID !== 0) {
            data.NotaVentaID = this.state.NotaVentaID.toString();
        } else {
            data.NotaVentaID = null;
        }

        if (this.state.ReservaID !== 0) {
            data.ReservaID = this.state.ReservaID.toString();
        } else {
            data.ReservaID = null;
        }
        data.UsuarioVendedorPosID = this.state.orden.UsuarioVendedorPosID;        

        var textoData = JSON.stringify(data);
        var mensaje = "";

      /*  var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + this.IdVenta, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(res => res.json()).then((resDos) => {
            mensaje = resDos.mensaje;
        });
        */

        var respuesta = await fetch(GetUrlApi() + '/api/Orden/ActualizaOrden?id=' + this.IdVenta, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(res => res.json()).then((resDos) => {
            mensaje = resDos.mensaje;
        });


        if (document.getElementById('btnImprimir')) {
            document.getElementById('btnImprimir').disabled = false;
        }
        return mensaje;

    }

    async CargarOrden(ordenId) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + ordenId, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            const resOrden = await respuesta.json();
            if (!resOrden.nombreCliente && resOrden.tiposVentaID === 4) {
                this.setState({ activarPopNomCliente: true });
            }

            if (resOrden.pagadoDelivery === true) {
                this.setState({ pagadoDelivery: true });
            }
            var isPagado2 = false;
            this.IdVenta = resOrden.id;
            this.NumeroNewOrden = resOrden.numOrden;
            if (resOrden.pagado) {
                this.BloquearCambios();
                isPagado2 = true;
                this.IsReadOnly = true;
            }
            else if (resOrden.isAnulada) {
                document.getElementsByName('blokCambios').forEach((item) => {
                    item.disabled = true;
                });
                document.getElementsByName('botonesCategorias').forEach((item) => {
                    item.disabled = true;
                });
                this.setState({ isAnulada: true });
            }
            this.setState({ orden: resOrden, isPagado: isPagado2 });

            if (document.getElementById('btnImprimir')) {
                document.getElementById('btnImprimir').disabled = false;
            }
            if (resOrden.clientesModel) {
                this.setState({
                    DataCliente: {
                        id: resOrden.clientesModel.id,
                        nombre: resOrden.clientesModel.nombre,
                        rut: resOrden.clientesModel.rut,
                        telefono: resOrden.clientesModel.telefono,
                        email: resOrden.clientesModel.email,
                        fechaUltServicio: resOrden.clientesModel.fechaUltServicio,
                        montoUltServicio: resOrden.clientesModel.montoUltServicio,
                        fechaUltCompra: resOrden.clientesModel.fechaUltCompra,
                        montoUltCompra: resOrden.clientesModel.montoUltCompra,
                        estrellas: parseInt(resOrden.clientesModel.estrellas, 10),
                        ciudad: resOrden.clientesModel.ciudad,
                        comuna: resOrden.clientesModel.comuna,
                        direccion: resOrden.clientesModel.direccion,
                        girocomercial: resOrden.clientesModel.giroComercial,
                        cantidadNotasCredito: resOrden.clientesModel.cantidadNotasCredito,
                        tipoCliente: resOrden.clientesModel.tipoCliente,
                        razonSocial: resOrden.clientesModel.razonSocial,
                        NombreClasif: resOrden.clientesModel.NombreClasif,
                        PosicionClasif: resOrden.clientesModel.PosicionClasif,
                        tipoClienteID: resOrden.clientesModel.tipoClienteID,
                        ciudadID: resOrden.clientesModel.ciudadID
                    },
                    SeleccionoCliente: true
                });
            }
            this.ActualizarTexto();
        }
    }

    BloquearCambios() {//llamado cuando la orden abierta ya esta pagada
        document.getElementsByName('blokCambios').forEach((item) => {
            item.disabled = true;
        });
        document.getElementsByName('botonesCategorias').forEach((item) => {
            item.disabled = true;
        });
    }

    ActualizarTexto() {
        const mesero = GetUserCookie().username;
        var texto = 'Cajero: ' + mesero + ' estación ' + this.Caja.id + '\n';
        this.Estacion = '' + this.Caja.id;
        this.Mesero = '' + mesero;
        var precio = 0;
        var descuento = 0;
        var totalPropina = 0;
        const numOrden = (this.IdVenta > 0) ? (this.NumeroNewOrden.toString()) : ('N/A');
        this.NumOrden = numOrden;
        var numMesa = Number(this.urlParams.get('nmesa'));
        var precioConOferta = 0;

        if (!numMesa || numMesa === 0) {
            numMesa = this.state.orden.mesaID;
        }
        this.NumMesa = numMesa;
        texto += '-------------------------------\n';
        texto += '# de Orden: ' + numOrden + '\n';
        if (numMesa > 0) {
            texto += '# de Mesa: ' + numMesa + '- Clientes: ' + this.state.orden.numeroDeClientes + '\n';
            const nomCliente = (this.state.orden.nombreCliente) ? (this.state.orden.nombreCliente) : ('-');
            this.NomCliente = nomCliente;
        }
        else {
            const nomCliente = (this.state.orden.nombreCliente) ? (this.state.orden.nombreCliente) : ('-');
            const numcliente = (this.state.orden.numeroDeClientes > 0) ? (this.state.orden.numeroDeClientes) : (1);
            this.NumCliente = numcliente;
            this.NomCliente = nomCliente;
            texto += 'Clientes: ' + numcliente + '  Nombre: ' + nomCliente + '\n';
        }
        texto += '-------------------------------\n';
        var puntosProdTotal = 0 ;
        var montoSinPuntosProd=0;

        this.state.orden.carroProductos.forEach(function (currentValue) {
            precio += currentValue.precpred * currentValue.cantidad;
            let precioProd = (currentValue.precpred * currentValue.cantidad) - (currentValue.valueOffer ? currentValue.valueOffer : 0);
            precioConOferta += (currentValue.precpred * currentValue.cantidad) - (currentValue.valueOffer ? currentValue.valueOffer : 0);
            puntosProdTotal += currentValue.puntosAcumula * currentValue.cantidad;    
            if (currentValue.puntosAcumula == 0){
                montoSinPuntosProd += precioProd;//currentValue.precpred * currentValue.cantidad;
            }
        });
        if (this.state.orden.descEfectivo > 0){
            montoSinPuntosProd = montoSinPuntosProd-this.state.orden.descEfectivo;
        }
        if(this.state.orden.dctoOrden > 0 ){
            var totalT = Number(precio);
            let montoEnPesos =  Math.round((totalT *  this.state.orden.dctoOrden) / 100);
            montoSinPuntosProd = montoSinPuntosProd-montoEnPesos;
        }
        this.setState({puntosPorProductos: puntosProdTotal, montoProductosSinPuntos:montoSinPuntosProd});

        var fecha = new Date();
        this.FechaOrder = fecha.getDate() + '/' + (fecha.getMonth() + 1) + '/' + fecha.getFullYear() + ' ' + fecha.getHours() + ':' + fecha.getMinutes();
        //--------FIN DESCUENTOS
        this.setState({ textoBoleta: texto, precioTotal: precio, totalDescuento: descuento, totalPropina: totalPropina, precioTotalDescuentoOferta: precioConOferta });
    }

    GetValorPropina() {
        const propinaPorc = this.state.orden.propinaPorc;
        const propinaCant = this.state.orden.propinaCant;
        const propinaDiff = this.state.orden.propinaDiff;
        const propinaPorc2 = this.state.propinaPorc;
        if (propinaPorc > 0 && propinaCant > 0) {
            console.log('conflicto entre propinas');
        }
        else if (propinaPorc > 0) {
            var res = this.state.precioTotal * (this.state.orden.propinaPorc / 100);
            return Math.round(res);
        }
        else if (propinaCant > 0) {
            var res = this.state.orden.propinaCant;
            return Math.round(res);
        }
        else if (propinaDiff > 0) {
            const total = Number(this.state.precioTotal);
            var res = total - this.state.orden.propinaDiff;
            res = res * -1;
            return Math.round(res);
        }
        else if (propinaPorc2 > 0) {
            var res = this.state.precioTotal * (this.state.propinaPorc / 100);
            return Math.round(res);
        }
        return 0;
    }

    async NetearNCConVentaBajoMonto()
    {
        this.setState({ reqAuth: true }); 
        $('#PINboxDosAut').focus();
        //this.setState({ BotonDifNCActivo: true });

        //var ValorAOtorgarAproductoDifCAMBIO = this.GetDiferenciaTotalvsNotaCredito() + 1;
        //if (this.state.reqAuthVal) {
            
        //}
        //this.setState({ BotonDifNCActivo: false });

        //this.AgregarProducto(codigoproducto);
    }


    async AgregarProducto(idProducto, mod, isPrecPorKilo, montoModAumento) {

        if (!montoModAumento) {
            montoModAumento = 0;
        }
        var seleccionados = Object.create(this.state.productosSeleccionados);
        var carrito = Object.create(this.state.orden.carroProductos).__proto__;
        var nuevoTotal = this.state.precioTotal;
        var nuevoTotalConOferta = this.state.precioTotalDescuentoOferta;
        const cant = Number(this.state.cantidad);

        var productoSeleccionado = Object.create(seleccionados.filter(seleccionados => seleccionados.id === idProducto));

        productoSeleccionado = productoSeleccionado[productoSeleccionado.length - 1];
        if (mod) {
            productoSeleccionado.modificadores = mod;
        }
        //#RFD.26112019 Modifica por cambio modelo
        if (GetUserCookie().Sucursal.configPOS.isPrecioPorZona) {
            var tipoVentaID = Number(this.urlParams.get('tipoV'));
            if (!tipoVentaID || tipoVentaID === 0) {//!data.MesaID || data.MesaID === 0
                tipoVentaID = 2;
            }
            if (tipoVentaID === 1) {
                productoSeleccionado.precpred = productoSeleccionado.precmesa;
            }
            else if (tipoVentaID === 3) {
                productoSeleccionado.precpred = productoSeleccionado.precdeliv;
            }
            else if (tipoVentaID === 4) {
                productoSeleccionado.precpred = productoSeleccionado.precbar;
            }

        }
        /*let productopuntos=0 ; --> se comenta los parametros se incluyen en la vista view_productos 01-06-22
        let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
        if (SwProgramaOfertas === 'S'){
                 //////ini programa puntos
                let token =sessionStorage.getItem('jwtToken');
                var productoProgramaPuntos = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/getProductoParametrosPuntos?IDProducto='+ idProducto + '&CodigoPlu=0' , {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization" : "Bearer " + token + ""
                    }
                }).then(result => result).catch(error => {
                    console.log('Error!!!!: ' + error)
                    swal({
                        title: "Error al conectarse al server central.",
                        icon: "error",
                        dangerMode: true,
                    }).then(() => {
                        this.ClickClear();
                    });
                });  
                
                if (productoProgramaPuntos){
                    try{
                        var datosProductoPuntos = await productoProgramaPuntos.json();
                        productopuntos = datosProductoPuntos[0].puntosAcumula;
                    }
                    catch (e){
                        productopuntos=0;
                    }
                }
         }
        /////// fin programa puntos///
         */

        //inicio Regalo --> se comenta los parametros se incluyen en la vista view_productos 01-06-22
      /*  let productoregalo = 0;
        let productoregaloValor = 0;
        let tokenRegalo = await sessionStorage.getItem('jwtToken');
        var productoRegalo = await fetch(GetUrlBlinamicApi() + '/api/RegaloProducto/getConsultaProductoRegalo?CodProducto=' + idProducto + '', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + tokenRegalo + ""
            }
        }).then(result => result).catch(error => {
            console.log('Error!!!!: ' + error)
            swal({
                title: "Error al conectarse al server central.",
                icon: "error",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();
            });
        });

        if (productoRegalo) {
            try {
                var datosProductoRegalo = await productoRegalo.json();
                productoregalo = datosProductoRegalo['isRegalo'];
                productoregaloValor = datosProductoRegalo['valorTotal'];
            }
            catch (e) {
                productoregalo = 0;
            }
        }*/

        //solo realizar la consulta si el valor es 0, para no repetir consulta x cada producto
        //let regaloPorcentajeValor = 0;
        if (this.state.regaloPorcentajeValor == 0){
            let tokenRegalo = await sessionStorage.getItem('jwtToken');
                let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
                var productoRegaloPorcentaje = await fetch(GetUrlBlinamicApi() + '/api/RegaloProducto/getConsultaPorcentajeRegalo?SucID=' + sucursalID + '', {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + tokenRegalo + ""
                    }
                }).then(result => result).catch(error => {
                    console.log('Error!!!!: ' + error)
                    swal({
                        title: "Error al conectarse al server central.",
                        icon: "error",
                        dangerMode: true,
                    }).then(() => {
                        this.ClickClear();
                    });
                });

                if (productoRegaloPorcentaje) {
                    try {
                        var datosProductoRegaloPorcentaje = await productoRegaloPorcentaje.json();
                        //regaloPorcentajeValor = datosProductoRegaloPorcentaje['regaloPorcentaje'];
                        this.setState({regaloPorcentajeValor :  datosProductoRegaloPorcentaje['regaloPorcentaje']});
                    }
                    catch (e) {
                        //regaloPorcentajeValor = 0;
                        this.setState({regaloPorcentajeValor :  0});
                    }
                }
            }
        //fin Regalo
        var productoAgregar = {};
        productoAgregar.cantidadCorrelativos = productoSeleccionado.cantidadCorrelativos;
        productoAgregar.puntosAcumula = productoSeleccionado.puntosAcumula;// productopuntos;
        productoAgregar.IsRegalo = productoSeleccionado.isRegalo;//productoregalo;
        productoAgregar.IsUsoRegalo = 0;
        productoAgregar.ProductoRegaloValor = productoSeleccionado.precpred;//productoregaloValor;
        productoAgregar.regaloPorcentaje = this.state.regaloPorcentajeValor;//regaloPorcentajeValor;
        productoAgregar.cantidad = cant;
        productoAgregar.isCombo = productoSeleccionado.isCombo;
        productoAgregar.codgrup = productoSeleccionado.tbgrupoid;
        productoAgregar.id = productoSeleccionado.id;//jg codprod cambiado por id
        productoAgregar.desprod = productoSeleccionado.desprod;
        productoAgregar.precioFinal = productoSeleccionado.precioFinal;
        productoAgregar.idCarro = this.state.idCarro;
        //nuevo atributo para identificar que si utiliza o no el convenio.
        productoAgregar.isConvenioUsado = false;



        productoAgregar.isGiftcard = 0;
        let numIdCarro = this.state.idCarro + 1;
        this.setState({ idCarro: numIdCarro });
        if (!isPrecPorKilo)
            productoAgregar.precpred = productoSeleccionado.precpred;
        else {
            productoAgregar.precpred = isPrecPorKilo;
            productoAgregar.isPesable = true;

        }
        productoAgregar.tipomodif = productoSeleccionado.tipomodif;
        productoAgregar.modificadores = productoSeleccionado.modificadores;
        productoAgregar.isGuardado = true;
        productoAgregar.isPesable = productoSeleccionado.isPesable;
        if (GetUserCookie().Sucursal.configPOS.isBarCode) {
            productoAgregar.isComanda = true;
        }

        if (GetUserCookie().Sucursal.configPOS.isPromociones) {
            var respuesta = await fetch(GetUrlApi() + '/api/Promociones/GetPromocionesProducto?productoID=' + idProducto, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {

                var datos = await respuesta.json();
                productoAgregar.precpred += datos.descuentoTotal;
            }
        }

        //Busco ofertas por producto y le asigno cero al clientID cuando aún no tengo el cliente registrado.
        let clienteID = 0;
        if (this.state.DataCliente.id != '') {
            clienteID = this.state.DataCliente.id;
        }
        let token = sessionStorage.getItem('jwtToken')
        let offerProducts = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOfertaPorProducto?productoID=' + productoSeleccionado.id + '&cantidad=' + cant + '&clienteID=' + clienteID + '&sucursalID=' + GetUserCookie().Sucursal.sucursalIDBlinamic, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer " + token + ""
            }
        }).then(res => res.json()).then(
            (result) => { return result.result.products }
        )

        /*en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
        /*y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/

        
        var productoID = 0;
        var esBeneficiado = false;
        var porcDescuento = 0;
        var calculoPorcentajeDescuentoConvenio = 0;

        //if (this.state.identificarConvenioCliente && this.state.DataClienteConvenio.usaConvenio) {
        if (this.state.DataClienteConvenio.usaConvenio ) {

            let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
            //let token = sessionStorage.getItem('jwtToken')
            let url = GetUrlBlinamicApi() + '/api/Convenio/GetBeneficioProductoConvenio?SucursalID=' + sucursalID + '&ConvenioID=' + this.state.DataClienteConvenio.idConvenio + '&ProductoID=' + productoSeleccionado.id


            const ConvenioProducts = await fetch
                (url, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + token + ""
                    }
                }).then(res => res.json()).then(
                    (result) => {
                        if (result.data.length > 0) {

                            productoID = result.data[0].productoID;
                            esBeneficiado = result.data[0].esBeneficiado;
                            porcDescuento = result.data[0].porcDescuento;

                        }
                    });
 
            if (esBeneficiado) {
                  
                calculoPorcentajeDescuentoConvenio = Math.round((productoAgregar.precpred * porcDescuento) / 100);
                if (offerProducts.length > 0)
                {
                    if ((offerProducts[0].offerDisc/productoAgregar.cantidad) < calculoPorcentajeDescuentoConvenio)
                    {
                        offerProducts[0].offerDisc = calculoPorcentajeDescuentoConvenio;
                        offerProducts[0].valueOffer = (productoAgregar.precpred * productoAgregar.cantidad) - (calculoPorcentajeDescuentoConvenio * productoAgregar.cantidad);
                        offerProducts[0].isValid = true;
                        
                    }
                }
                else
                {   
                    let value =  (productoAgregar.precpred * productoAgregar.cantidad) - (calculoPorcentajeDescuentoConvenio * productoAgregar.cantidad);
                    offerProducts = [{ offerDisc: calculoPorcentajeDescuentoConvenio, productID: productoSeleccionado.id, valueOffer: value, isValid: true }];

                }

                productoAgregar.isConvenioUsado = true;

            }
            else {
                productoAgregar.isConvenioUsado = false;
            }
        }

        /*FIN   en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
        /*FIN  y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/

             
        const prodInOffer = productoAgregar.inOffer?productoAgregar.inOffer:false
        offerProducts.forEach(function(offerProd) {
            const isValid = offerProd.isValid?offerProd.isValid:true
             
            if (productoAgregar.id === offerProd.productID && isValid && !prodInOffer) {
                productoAgregar.valueOffer = productoAgregar.precpred * productoAgregar.cantidad - offerProd.valueOffer
                //productoAgregar.valueOfferUnitario = productoAgregar.precpred * productoAgregar.cantidad - offerProd.valueOffer
                productoAgregar.valueOfferUnitario = productoAgregar.precpred - (offerProd.valueOffer/productoAgregar.cantidad)
                offerProd.isValid=false
                productoAgregar.inOffer = true
            }
        })                         

        let ifexists = false;
      
        //validar el producto si existe una NC seleccionada
    
        let notProductNC = false;
        productoAgregar.isProductNC = false;
        if (productoSeleccionado.codigoProveedor === 'DIF.CAMBIO'){
            productoAgregar.isDifcambio = true;
        }
        else{
            productoAgregar.isDifcambio=false;
        }
         
        if (this.state.SeleccionoCliente){
            if (this.UsedNotaCreditoID != 0 && this.state.Ambiente==='MAISA'){
                carrito.forEach(function(item){
                    if (item.precpred === 1 && item.isProductNC){
                        item.precpred = 0;
                    }
                });

                if (this.ProductosNC != undefined && this.ProductosNC.length !=0){
                    let idx = this.ProductosNC.findIndex(t => t.codigoProductoPadre === productoSeleccionado.codigoProductoPadre);
                    if (idx != -1){
                       
                        if (this.ProductosNC[idx].cantidad === 1 ){
                            productoAgregar.precpred = this.ProductosNC[idx].valorVenta;
                            productoAgregar.cantidad = this.ProductosNC[idx].cantidad;
                            this.ProductosNC.splice(idx, 1);
                        }
                        else{
                            productoAgregar.precpred = this.ProductosNC[idx].valorVenta; 
                            this.ProductosNC[idx].cantidad -= 1;
                        }
                        productoAgregar.isProductNC = true;
                        productoAgregar.valueOffer=0;
                        productoAgregar.valueOfferUnitario = 0;
                    }
                    else{
                      
                        let ind  = this.ProductosNCOrig.findIndex(t => t.codigoProductoPadre === productoSeleccionado.codigoProductoPadre);
                        if (ind != -1){
                            swal({
                                title: "No puede agregar más productos de este tipo por estar incluidos en la Nota de Crédito.",
                                icon: "error",
                                dangerMode: true,
                            })
                            return;
                        }
                        //prod extra no nc
                         notProductNC = true;
                        
                    }
                }
                else{
                    let ind  = this.ProductosNCOrig.findIndex(t => t.codigoProductoPadre === productoSeleccionado.codigoProductoPadre);
                    if (ind != -1){
                        swal({
                            title: "No puede agregar más productos de este tipo por estar incluidos en la Nota de Crédito.",
                            icon: "error",
                            dangerMode: true,
                        })
                        return;
                    }
                    notProductNC = true;
                }
            }
           
        }

    /*
        if (this.state.SeleccionoCliente){
            if (this.UsedNotaCreditoID != 0 && this.state.Ambiente==='MAISA'){
                if (this.ProductosNC != undefined && this.ProductosNC.length !=0){
                    let idx = this.ProductosNC.findIndex(t => t.codigoProductoPadre === productoSeleccionado.codigoProductoPadre);
                    if (idx === -1){
                        swal({
                            title: "Solo puede agregar productos similares, relacionados a la Nota de Crédito seleccionada.",
                            icon: "error",
                            dangerMode: true,
                        })
                        return;
                    }
                    else{
                        if (this.ProductosNC[idx].cantidad === 1 ){
                            productoAgregar.precpred = this.ProductosNC[idx].valorVenta;
                            productoAgregar.cantidad = this.ProductosNC[idx].cantidad;
                            this.ProductosNC.splice(idx, 1);
                        }
                        else{
                            productoAgregar.precpred = this.ProductosNC[idx].valorVenta; 
                            this.ProductosNC[idx].cantidad -= 1;
                        }
                        productoAgregar.valueOffer=0;
                        productoAgregar.valueOfferUnitario = 0;
                    }
                }
                else{
                    swal({
                        title: "Ya no puede agregar mas productos a esta Venta, al estar asociada a una Nota de Crédito.",
                        icon: "error",
                        dangerMode: true,
                    })
                    return;
                }
                
            }
        }
        */
        
        for (var i = 0; i< carrito.length;i++){
           // if (this.UsedNotaCreditoID ===0 || notProductNC){
                if (carrito[i].id==productoSeleccionado.id && carrito[i].IsUsoRegalo==0){
                    carrito[i].cantidad +=1;
                    carrito[i].valueOffer = carrito[i].valueOffer + productoAgregar.valueOfferUnitario;
                    ifexists=true;
                }
            //}
        }

        if (!ifexists){
            carrito.push(productoAgregar);
        }

        console.log(productoAgregar);
        nuevoTotal += productoSeleccionado.precpred * this.state.cantidad;
        nuevoTotalConOferta += (productoSeleccionado.precpred * this.state.cantidad) - (productoAgregar.valueOffer ? productoAgregar.valueOffer : 0);
        productoSeleccionado.precioFinal = productoSeleccionado.precpred * this.state.cantidad;
        var newOrden = this.state.orden;
        newOrden.carroProductos = carrito;
        this.CorrelativoIngresado = '';
        await this.setState({ carroProductos: carrito, precioTotal: nuevoTotal, cantidad: 1, orden: newOrden, PrecioTotalConDescuento: nuevoTotalConOferta });
        this.ActualizarTexto();
        if (this.isNivelSuperiorSelected) {
            this.TraerProductosNivSupr(this.IdUltCategoriaSeleccionada);
            this.isNivelSuperiorSelected = false;
        }
    }

    AgregarProductoBarcode(producto, mod, isPrecPorKilo, resetCant) {
        var seleccionados = Object.create(this.state.productosSeleccionados);
        var carrito = Object.create(this.state.orden.carroProductos).__proto__;

        var nuevoTotal = this.state.precioTotal;
        var nuevoTotalConOferta = this.state.precioTotalDescuentoOferta;
        const cant = Number(this.state.cantidad);

        var productoSeleccionado = producto;
        if (mod) {
            productoSeleccionado.modificadores = mod;
        }
        //#RFD.26112019 Modifica por cambio modelo

        if (GetUserCookie().Sucursal.configPOS.isPrecioPorZona) {
            var tipoVentaID = Number(this.urlParams.get('tipoV'));
            if (!tipoVentaID || tipoVentaID === 0) {//!data.MesaID || data.MesaID === 0
                tipoVentaID = 2;
            }
            if (tipoVentaID === 1) {
                productoSeleccionado.precpred = productoSeleccionado.precmesa;
            }
            else if (tipoVentaID === 3) {
                productoSeleccionado.precpred = productoSeleccionado.precdeliv;
            }
            else if (tipoVentaID === 4) {
                productoSeleccionado.precpred = productoSeleccionado.precbar;
            }

        }

        var productoAgregar = producto;
        productoAgregar.cantidad = cant;
        productoAgregar.isCombo = productoSeleccionado.isCombo;
        productoAgregar.codgrup = productoSeleccionado.tbgrupoid;
        productoAgregar.id = productoSeleccionado.id;//jg codprod cambiado por id
        productoAgregar.desprod = productoSeleccionado.desprod;
        productoAgregar.precioFinal = productoSeleccionado.precioFinal;
        if (!isPrecPorKilo)
            productoAgregar.precpred = productoSeleccionado.precpred;
        else {
            productoAgregar.precpred = isPrecPorKilo;
            productoAgregar.isPesable = true;

        }
        productoAgregar.tipomodif = productoSeleccionado.tipomodif;
        productoAgregar.modificadores = productoSeleccionado.modificadores;
        productoAgregar.isGuardado = true;
        if (GetUserCookie().Sucursal.configPOS.isBarCode) {
            productoAgregar.isComanda = true;
        }

        carrito.push(productoAgregar);
        nuevoTotal += productoSeleccionado.precpred * this.state.cantidad;
        nuevoTotalConOferta += (productoSeleccionado.precpred * this.state.cantidad) - (productoSeleccionado.valueOffer ? productoSeleccionado.valueOffer : 0);
        productoSeleccionado.precioFinal = productoSeleccionado.precpred * this.state.cantidad;
        var newOrden = this.state.orden;
        newOrden.carroProductos = carrito;

        var cant2 = cant;

        this.setState({ carroProductos: carrito, precioTotal: nuevoTotal, cantidad: cant2, orden: newOrden, precioTotalDescuentoOferta: nuevoTotalConOferta });
        this.ActualizarTexto();

    }


    GetOrden() {
        var res = this.state.orden;
        return res;
    }

    CerrarModal() {
        try {
            var modales = document.getElementsByClassName("popup-overlay");
            if (modales.length > 0)
                modales[modales.length - 1].style.visibility = "hidden";
            this.setState({ verDetalle: false, activarVerDetalle: false });
        } catch (err) {
            console.log('ex en cierre de modal');
        }
    }


    ClickDescuentos() {

        if (this.UsedNotaCreditoID != 0){
            swal({
                title: "No puede agregar descuentos cuando se tiene una Nota de Crédito seleccionada.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }

        if (!this.state.IsDescuentoRealizado) {
            this.setState({ activarDescuentos: true ,consultarDescOffers:false});
        } else {
            swal('Ya hay descuentos aplicados, no se puede aplicar otro');
        }
    }
    ClickOfertaConsultar() {
        this.setState({ consultarOfertas: true,consultarDescOffers:false })
    }
    ClickGiftcardConsultar(){
        this.setState({ consultarGiftcard: true })
    }
    ClickActGiftcard(){
        if (!this.isSelectedGiftcard){
            const productos = this.state.orden.carroProductos;

            if (productos.length >0){
                swal({
                    title: "Solo puede activar una Giftcard sin productos en la Venta.",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            this.setState({ activaActGiftcard: true })
        }
        else{ 
            swal({
                title: "Solo puede activar una Giftcard a la vez.",
                icon: "warning",
                dangerMode: true,
            })
        }
    }
    ClickDescOffers(){
        this.setState({consultarDescOffers:true})
    }


    async ValidarStockProductos() {
        var tieneStock = 0;
        this.state.carroProductos.forEach(async (producto) => {
            var desprodSplit = producto.desprod.split(';');
            var CodigoProducto = desprodSplit[0];

            var stockprod = await this.TraerStockPorProducto(CodigoProducto);
            if (producto.cantidad <= stockprod) {
                tieneStock++;
            } else {
                tieneStock--;
            }

        });
        if (tieneStock > 0 || tieneStock < 0) {

            if (tieneStock === this.state.carroProductos.length) {
                return true;
            } else {
                return false;
            }
        }

    }

    async TraerStockPorProducto(codigoProducto) {

        var StockProdResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockProductoByCodigo?Codigo=' + codigoProducto, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!StockProdResult.ok) {
            throw 'problema al traer Stock';
        }
        var datosD = await StockProdResult.json();
        return datosD;
    }

    BotonModificar() {
        if (this.state.orden.carroProductos.length==0){
            swal({
                title: "Debe ingresar al menos un producto para editar el carrito.",
                icon: "warning",
                dangerMode: true,
            }); 
            return;
        }
        if (this.state.TicketID !== 0 || this.state.NotaVentaID !== 0 || this.state.ReservaID !== 0) {

            swal({
                title: "No se puede Modificar una Orden de Servicio,Ecommerce o Reserva, Puede realizarlo desde Blinamic.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        this.setState({ activarCancelar: true });
    }

    BotonRegalo() {
        if (this.state.orden.carroProductos.length==0){
            swal({
                title: "Debe ingresar al menos un producto para validarlo como Regalo.",
                icon: "warning",
                dangerMode: true,
            }); 
            return;
        }
        if (this.state.TicketID !== 0 || this.state.NotaVentaID !== 0 || this.state.ReservaID !== 0) {

            swal({
                title: "No se puede Modificar una Orden de Servicio,Ecommerce o Reserva, Puede realizarlo desde Blinamic.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        this.setState({ activarRegalo: true });
    }

    async ClickPagar() {

        var mensajeRes = "";
        var mensajeResD = "";

        if (this.state.orden.carroProductos.length==0){
            swal({
                title: "Debe ingresar al menos un producto para proceder con el Pago.",
                icon: "warning",
                dangerMode: true,
            }); 
            return;
        }
        if(this.state.DataCliente.rut == '66666666-6' && this.state.IsFacturaSelected){
            swal({
                title: "Verifique el Cliente seleccionado, para proceder con la venta con Factura.",
                icon: "warning",
                dangerMode: true,
            }); 
            return;
        }
       /* if (this.UsedNotaCreditoID !=0){
            if (this.ProductosNC.length > 0){
                swal({
                    title: "Faltan productos asociados a la Nota de Crédito, para proceder con el Pago.",
                    icon: "warning",
                    dangerMode: true,
                }); 
                return;
            }
        }*/

        if (this.UsedNotaCreditoID!=0){
            var carrito = Object.create(this.state.orden.carroProductos).__proto__;
 
            let sumProductsNC = 0;
            let idxLastNc = 0;
            let count = 0;
            carrito.forEach(function(item){
                if (item.isProductNC ){
                 
                    sumProductsNC += item.precpred;
                    idxLastNc = count;
                }
                count +=1;
            });

            if (sumProductsNC === 0) {
                if (idxLastNc > 0)
                {
                    carrito[idxLastNc].precpred = 1; //seteamos 1 peso al ultimo producto
                }
                //carrito[idxLastNc].precpred  = 1; //seteamos 1 peso al ultimo producto
       
                var newOrden = this.state.orden;
                newOrden.carroProductos = carrito;
                await this.setState({ carroProductos: carrito, orden: newOrden});
                this.ActualizarTexto();
            }
            
        }


        /*codigo para poder advertir cuando se supera la cuota de credito de un convenio.*/

        if (this.state.DataClienteConvenio.usaConvenio) {

            if ((this.state.DataClienteConvenio.CantidadDisponible < this.GetTotal() && this.state.DataClienteConvenio.limiteCreditoConvenio != 0) || (this.state.DataClienteConvenio.CantidadDisponible < 0 && this.state.DataClienteConvenio.limiteCreditoConvenio != 0) ) {

                swal({
                    title: "No se puede comprar más del Crédito Disponible para compra por Convenio.",
                    icon: "warning",
                    dangerMode: true,
                });
                return;

            }
        }

        await this.ValidarProductosEnLista();


        if (this.state.TineCorrelativos && this.ProductosAPedirCorrelativo.length === 0) {
            this.setState({ MostrarCorrelativos: true });
            return;
        }
        else if (this.ProductosAPedirCorrelativo.length > 0) {
            this.setState({ NeedCorrelativos: true });
            return;
        } else if (this.ProductosTienenCorrelativo.length > 0 && this.state.PasePorAsignar === false) {
            this.setState({ MostrarCorrelativos: true });
            return;
        }
        
        const loginSeller = localStorage.getItem('loginSeller');

        if (loginSeller === 'true' && this.state.DataVendedor.id === '' && this.state.DataVendedor.nombre === '') {
            this.ClickLoginVendedor();
            return;
        } else if (this.state.IsClienteObligatorio && this.state.identificarClienteForzado == 'true') {
            this.setState({ activarPopUpConsultaCliente: true });
            $('#RutCliente').focus();
            return;
        }
        if (this.state.isPagado) {
            return;
        }
        if (this.IsPagarHabilitado) {
            const nMesa = this.state.orden.MesaID;
            var urlRedirect;
            if (nMesa) {
                urlRedirect = '/Venta?tipoV=1&nmesa=' + nMesa;
            }
            else {
                urlRedirect = '/Venta';
            }
            localStorage.setItem('urlRedirectVuelto', urlRedirect);//es la url a la que redirige la ventana vuelto
            document.getElementById('btnPagar').disabled = true;
            //document.getElementById('btnPagar').innerText = 'Procesando';
            if (!this.IdVenta > 0) {
                mensajeRes = await this.GenerarOrden();
            }
            else {
                mensajeRes = await this.ActualizarOrden();
            }
            if (mensajeRes === 'Ok') {
                 
                if (this.state.usePoints === 'true' && this.state.DataClienteConvenio.idConvenio === 0 && this.UsedNotaCreditoID === 0 && this.state.DataClientePuntos.saldoPuntos > 0 && (this.state.orden.puntosXCanje === 0 || this.state.orden.puntosXCanje === undefined)){
                    await this.showDescuentoPuntos();
                    return;
                }
                else{
                    this.setState({ activarPagar: true });
                    if (document.getElementById('btnPagar')) {
                        document.getElementById('btnPagar').disabled = false;
                    // document.getElementById('btnPagar').innerHTML = '<i class="fa fa-usd fa-2x" aria-hidden="true"></i>';
                    // document.getElementById('btnPagar').innerHTML = 'Pagar ' + GetPrecioFormateado(this.GetTotal()) +'<i className="fa fa-angle-right" aria-hidden="true"> </i>';
                    }
                }
                
            } else {
                if (mensajeRes)
                    swal(mensajeRes);
                if (document.getElementById('btnPagar')) {
                    document.getElementById('btnPagar').disabled = false;
                    //document.getElementById('btnPagar').innerHTML = '<i class="fa fa-usd fa-2x" aria-hidden="true"></i>';
                   // document.getElementById('btnPagar').innerHTML = 'Pagar ' + GetPrecioFormateado(this.GetTotal()) +'<i className="fa fa-angle-right" aria-hidden="true"> </i>';
                }
            }
        }
    }

    async showDescuentoPuntos(){
        await this.setState({activaDescuentoPuntos:true})

        const restoPuntos = this.state.DataClientePuntos.saldoPuntos % 10;
        this.SaldoPuntosUsable = this.state.DataClientePuntos.saldoPuntos - restoPuntos;
        this.FactorPuntos= this.state.DataClientePuntos.factorCanje;

        let saldoInPoints = this.GetTotal() / this.FactorPuntos ;
       // const restoPuntosT = saldoInPoints % 10;
       // saldoInPoints = saldoInPoints - restoPuntosT;
        let montoEnPesos = 0 

            if (saldoInPoints <= this.state.DataClientePuntos.saldoPuntos ){
                //document.getElementById('inpPuntos').text= saldoInPoints;
                this.PuntosUtilizados=saldoInPoints ;
                montoEnPesos  = Math.round(saldoInPoints * this.FactorPuntos);
                if (montoEnPesos===this.GetTotal()){
                    montoEnPesos = montoEnPesos - 1;
                }
            }else{
               // document.getElementById('inpPuntos').text= this.SaldoPuntosUsable;
                this.PuntosUtilizados=this.SaldoPuntosUsable;
                montoEnPesos  = this.SaldoPuntosUsable * this.FactorPuntos;
            }
        
            this.setState({ AmountOfPoints:montoEnPesos});
            
            document.getElementById('PINbox').value = montoEnPesos;
            
            try {
                var val =document.getElementById('PINbox').value;
          
            if (val.length>=6 && val.length<=9) {
                document.getElementById('PINbox').style.fontSize = "3em";
               // document.getElementById('lbPuntosEnPesos').style.fontSize = "3em";
            } else if (val.length > 10 && val.length <= 20) {
                document.getElementById('PINbox').style.fontSize = "1.5em";
                //document.getElementById('lbPuntosEnPesos').style.fontSize = "1.5em";
            }
            else if (val.length > 20 && val.length) {
                document.getElementById('PINbox').style.fontSize = "0.5em";
                //document.getElementById('lbPuntosEnPesos').style.fontSize = "0.5em";
    
            } else if (val.length < 6) {
                document.getElementById('PINbox').style.fontSize = "4em";
                //document.getElementById('lbPuntosEnPesos').style.fontSize = "4em";
            }
                this.Numero=document.getElementById('PINbox').value ;
                this.PuntosUtilizados=  GetNumeroMiles(this.RedondearValor(this.Numero / this.FactorPuntos));
               } catch (error) {    }
    
          try{document.getElementById('PINbox').focus()}catch(e){}     
    }

    async ValidarProductosEnLista() {

         
        this.state.orden.carroProductos.map((item)=>{
            let codProd = item.desprod.split(';')[0];
            let countProd=0;
            let countIng =0;
             for (var i=0; i < this.ProductosAPedirCorrelativo.length; i++){
                if(this.ProductosAPedirCorrelativo[i].id == codProd){
                   countProd +=1;
                   //this.ProductosAPedirCorrelativo[i].cantidad= item.cantidad;
                }
            }
            for (var i=0; i < this.ProductosVerCorrelativosLista.length; i++){
                if(this.ProductosVerCorrelativosLista[i].codigo == codProd){
                    countIng +=1; 
                }
            }
                   //validamos si se edito la cantidad de productos en el carrito (para que solicite los rfid correspondientes)
                   //solo solicita correlativos cuando la cantidad del carro es mayor a los correlativos ingresados y solicitados
                    let cantidad = item.cantidad; 
                    if (cantidad > (countIng + countProd)){
                            if(item.cantidadCorrelativos>0){ //solo agrega los que solicitan rfid
                                let cantdiff = cantidad - (countIng+countProd);
                                let objeto = {
                                    id:  item.desprod.split(';')[0],
                                    nombre:  item.desprod.split(';')[2],
                                    cantidad: 1,
                                    cantidadCorrelativo:  item.cantidadCorrelativos,
                                    valor: 0,
                                    idCarro: this.state.idCarro
                                };
                                
                                for (let k = 0; k < cantdiff; k++) {
                                    this.ProductosAPedirCorrelativo.push(objeto);
                                }
                            }
                       // }
                    }
                  

            countProd=0;

            //volvemos a contar los rfid solicitados
            for (var x=0; x < this.ProductosAPedirCorrelativo.length; x++){
                if(this.ProductosAPedirCorrelativo[x].id == codProd){
                   countProd +=1;
                }
            }
            if (item.cantidad < countProd){ //valida si se quitaron productos 
                let diff = countProd - item.cantidad;
                let erased = 0;
                try{
                    
                    for (var y = 0; y <= this.ProductosAPedirCorrelativo.length;y++){
                        if(this.ProductosAPedirCorrelativo[y].id == codProd){
                            this.ProductosAPedirCorrelativo.splice(y, 1);
                            erased +=1;
                            y -=1;
                        }
                        if (erased==diff){
                            break; 
                        }
                    } 
                 }  
                catch(e){}  
            }
           
        })

       /* this.ProductosAPedirCorrelativo.map((item) => {
            let idx = this.state.orden.carroProductos.findIndex(t => t.idCarro === item.idCarro);
            if (idx === -1) {
                let idxDos = this.ProductosAPedirCorrelativo.findIndex(t => t.idCarro === item.idCarro);
                this.ProductosAPedirCorrelativo.splice(idxDos, 1);
            }
        })

         

        this.ProductosVerCorrelativosLista.map((item) => {
            let idx = this.state.orden.carroProductos.findIndex(t => t.idCarro === item.idCarro);
            if (idx === -1) {
                let idxDos = this.ProductosVerCorrelativosLista.findIndex(t => t.idCarro === item.idCarro);
                this.ProductosVerCorrelativosLista.splice(idxDos, 1);
            }

        })*/
        
    }


    async DejarPendiente() {

        await this.ValidarProductosEnLista();
        if (this.state.TineCorrelativos && this.ProductosAPedirCorrelativo.length === 0) {
            this.setState({ MostrarCorrelativos: true });
            return;
        }
        else if (this.ProductosAPedirCorrelativo.length > 0) {
            this.setState({ NeedCorrelativos: true });
            return;
        }
        else if (this.ProductosTienenCorrelativo.length > 0) {
            this.setState({ MostrarCorrelativos: true });
            return;
        }

        if (this.state.IsClienteObligatorio && this.state.identificarClienteForzado == 'true') {
            this.setState({ activarPopUpConsultaCliente: true });
            return;
        }
        if (this.state.isPagado) {
            return;
        }
        if (this.state.listoClickeado) {
            return;
        }
        else {
            this.setState({ listoClickeado: true });
        }
        var url = GetUserCookie().Sucursal.configPOS.urlRedireccionVentaM;
        if (!this.IdVenta > 0) {
            await this.GenerarOrden();
            if (GetUserCookie().Sucursal.configPOS.isKitchenDisplay) {
                this.ImprimirZonaImpresion(this.IdVenta);
            }
            if (this.TipoVentaIdent === 1) {
                //document.getElementById('btnListo').disabled = false;
                this.Redirigir(url);
            } else if (this.TipoVentaIdent === 2) {
                const urlReidirigir = GetUserCookie().Sucursal.configPOS.urlRedireccionParaLlevar;

                window.location.href = urlReidirigir;
            } else {
                const urlReidirigir = GetUserCookie().Sucursal.configPOS.urlRedireccionParaLlevar;

                window.location.href = urlReidirigir;
            }
        }

        else {
            await this.ActualizarOrden();
            if (this.TipoVentaIdent === 1) {
                //document.getElementById('btnListo').disabled = false;
                this.Redirigir(url);
            } else if (this.TipoVentaIdent === 2) {
                const urlReidirigir = GetUserCookie().Sucursal.configPOS.urlRedireccionParaLlevar;

                window.location.href = urlReidirigir;
            } else {
                const urlReidirigir = GetUserCookie().Sucursal.configPOS.urlRedireccionParaLlevar;

                window.location.href = urlReidirigir;
            }
        }
    }

    async ClickEliminar(index) {
        if (this.state.TicketID !== 0 || this.state.NotaVentaID !== 0 || this.state.ReservaID !== 0) {

            swal({
                title: "No se puede Modificar una Orden de Servicio,Ecommerce o Reserva, Puede realizarlo desde Blinamic.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        /*no se habilita boton para producto nc 
        if (this.UsedNotaCreditoID != 0){
            swal({
                title: "No puede quitar un producto cuando se tiene una Nota de Crédito seleccionada.",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }*/
        try {
            const prodSeleccionado = this.state.orden.carroProductos[index];
            if (!prodSeleccionado.isGuardado) {
                this.setState({ mostrarTecladoAut: true });
                this.InidiceRemover = index;
            }
            else {
                this.RemoverItemOrden(index, null);
                if(this.state.orden.carroProductos.length === 0)
                    this.QuitarDescuento()
            }
            this.ValidarProductoRegalo()
            this.validateDescItem()
        }
        catch (err) {
            console.log(err);
        }
    }

    async ValidarProductoRegalo() {
        console.log('ValidarProductoRegalo')
        const sumall = this.state.carroProductos.map(item => item.precpred).reduce((prev, curr) => prev + curr, 0);
        console.log(sumall)
        this.state.carroProductos.forEach(async (producto) => {
            if (producto.IsUsoRegalo) {
                if (!(((sumall - producto.precpred) * producto.regaloPorcentaje) / 100 > producto.ProductoRegaloValor)) {
                    producto.precpred = parseInt(producto.ProductoRegaloValor);
                    producto.IsUsoRegalo = 0;
                }
            }

        });


    }

    async RemoverItemOrden(index, motivo) {
        var contenidoCarro = this.state.orden.carroProductos;
        if (!index) {
            index = 0;
        }
            //quita elemento del arreglo correlativos necesarios
            var what,  L = this.ProductosAPedirCorrelativo.length, ax; 
            var cant = 0;
            what= this.state.orden.carroProductos[index].desprod.split(';')[0];
            cant= this.state.orden.carroProductos[index].cantidad;
            for (var i = 0; i < L;i++){
                ax= this.ProductosAPedirCorrelativo.findIndex(t => t.id === what)
                if (ax != -1){
                    this.ProductosAPedirCorrelativo.splice(ax, 1);
                    cant = cant - 1;
                    if (cant < 1){
                        break;
                    }
                }
            }
            //quita elemento del arreglo correlativos ingresados
            
            L = this.ProductosVerCorrelativosLista.length;
            for (var i = 0; i < L;i++){
                if (this.ProductosVerCorrelativosLista[i].codigo == what){
                    let ix = this.ListaCorrelativosIngresados.indexOf(this.ProductosVerCorrelativosLista[i].correlativo)
                    if (ix != -1){
                        this.ListaCorrelativosIngresados.splice(ix,1);
                    }
                }
            }
            for (var i = 0; i < L;i++){
                ax= this.ProductosVerCorrelativosLista.findIndex(t => t.codigo === what)
                if (ax != -1){
                    this.ProductosVerCorrelativosLista.splice(ax, 1);
                    //break; //exit for
                }
            }

        var nuevoTotal = this.state.precioTotal - this.state.orden.carroProductos[index].precioFinal;
        var nuevoTotalConOferta = this.state.PrecioTotalConDescuento - (this.state.orden.carroProductos[index].precioFinal - (this.state.orden.carroProductos[index].valueOffer ? this.state.orden.carroProductos[index].valueOffer : 0));
        if (index > -1) {
            if (!contenidoCarro[index].isGuardado) {
                var data = {};
                data.OrdenID = this.IdVenta;
                data.Motivo = motivo;
                data.TbProductoID = contenidoCarro[index].id;
                var respuesta = await fetch(GetUrlApi() + '/api/ProductosOrden/AnularProducto', {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

            }
            contenidoCarro.splice(index, 1);
            var newOrdenes = this.state.orden;
            newOrdenes.carroProductos = contenidoCarro;
            
            if (this.isSelectedGiftcard){
                this.IDserieGiftcard = 0;
                this.isSelectedGiftcard=false;
            }

            await this.setState({ carroProductos: contenidoCarro, precioTotal: nuevoTotal, precioTotalDescuentoOferta: nuevoTotalConOferta });
            this.DesactivarModalesAut();
            this.ActualizarTexto();
        }
        else {
            console.log('no entro');
        }
    }

    async ClickBorrarOrden() {
        this.Redirigir('/');
        Logout();


    }

    Redirigir(url) {
        this.props.history.push(url);
    }
    ClickAddNombre() {
        this.setState({ activarPopNomCliente: true });

    }
    ClickTecladoCantidad(num) {
        document.getElementById('txtCantidad').value = document.getElementById('txtCantidad').value + num;

    }
    ClickSelectCantidad() {
        const cantidad = document.getElementById('txtCantidad').value;
        this.setState({ cantidad: cantidad });
    }

    async ClickVerDetallesDos(codProd) {
        if (this.state.verDetalle) {
            //#RFD.26112019 cambio codprod por id
            var prodClicked = this.state.productosSeleccionados.filter(productosSeleccionados => productosSeleccionados.id === codProd)[0];
            this.setState({ activarVerDetalle: true, productoClickeado: prodClicked });
        }
        else {
            var prodClicked = this.state.productosSeleccionados.filter(productosSeleccionados => productosSeleccionados.id === codProd)[0];
            if (prodClicked.isNivelSuperior) {
                this.isNivelSuperiorSelected = true;
                if (prodClicked.productosInferiores === null) {
                    prodClicked.productosInferiores = [];
                }
                this.setState({ productosSeleccionados: prodClicked.productosInferiores });

            }
            else {
                this.AgregarProducto(codProd);
                if (GetUserCookie().Sucursal.configPOS.isStockHabilitado) {
                    this.SimularReduccionStock(codProd);
                }
            }
        }
    }

    ClickVerDetalles(codProd) {
        if (this.state.verDetalle) {
            //#RFD.26112019 cambio codprod por id
            var prodClicked = this.state.productosSeleccionados.filter(productosSeleccionados => productosSeleccionados.id === codProd)[0];
            this.setState({ activarVerDetalle: true, productoClickeado: prodClicked });
        }
        else {
            var prodClicked = this.state.productosSeleccionados.filter(productosSeleccionados => productosSeleccionados.id === codProd)[0];
            if (prodClicked.isNivelSuperior) {
                this.isNivelSuperiorSelected = true;
                if (prodClicked.productosInferiores === null) {
                    prodClicked.productosInferiores = [];
                }
                this.setState({ productosSeleccionados: prodClicked.productosInferiores });

            }
            else {
                this.AgregarProducto(codProd);
                if (GetUserCookie().Sucursal.configPOS.isStockHabilitado) {
                    this.SimularReduccionStock(codProd);
                }
            }
        }
    }

    async SimularReduccionStock(id) {
        var productos = this.state.productosSeleccionados;
        var indiceProdEditar = productos.findIndex(p => p.id === id);
        productos[indiceProdEditar].stock -= /*await this.GetStockProducto(id)-*/ this.state.cantidad;
        this.ContProductosSeleccionado();
        this.setState({ productosSeleccionado: productos });
    }

    ContProductosSeleccionado(id) {//cuenta cuantas veces producto seleccionado esta agregado a la orden

        var res = 0;
        this.state.carroProductos.forEach((producto) => {
            if (producto.id === id) {
                res++;
            }
        });
        return res;
    }

    async GetStockProducto(productoID) {
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockProductoByCodigo?Codigo=' + productoID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            return res;
        });
    }


    ClickDctoPorc(porc) {
        this.setState({ dctoPorc: porc });

        this.CerrarModal();
    }
    ClickTablaPedido(indiceProdSelec) {
        this.setState({ activarModif: true, parametros: { indiceInicio: indiceProdSelec } });
    }
    //Inicio Clicks mesas
    async ClickTransferir() {
        //document.getElementById('btnListo').disabled = true;
        if (!this.IdVenta > 0)
            await this.GenerarOrden();
        else {
            await this.ActualizarOrden();
        }
        //document.getElementById('btnListo').disabled = false;
        if (this.IdVenta > 0)
            this.setState({ activarPopTransferir: true });
        else
            swal('La orden no esta creada');
    }

    ClickTransferirReal() {
        if (this.IdVenta > 0)
            this.setState({ activarTransferir: true });
        else
            swal('La orden no esta creada');
    }

    ClickAnular() {
        if (this.IdVenta > 0) {

            this.setState({ activarPopAnular: true });
        }
        else
            swal('La orden no esta creada');

    }
    //Fin Clicks mesas
    DesactivarModales() {
        document.getElementById('EntradaText').focus()
        if (this.state.DataCliente.id === '') {
            this.setBoletaClick();
        }
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        this.setState({
            activarMisc: false,
            activarVerDetalle: false,
            activarModif: false,
            activarCancelar: false,
            activarRegalo: false,
            activarPagar: false,
            activarDescuentos: false,
            activarCantidad: false,
            activarModForz: false,
            activarPopTransferir: false,
            activarPopAnular: false,
            activarTransferir: false,
            mostrarTecladoAut: false,
            activarPesable: false,
            activarCPrecio: false,
            activarPopUpConsultaStock: false,
            activarPopUpReimprimirHten: false,
            activarPopUpStock: false,
            activarPopUpStockSucursales: false,
            activarPopUpConsultaOrdenServicio: false,
            activarPopUpPdfOS: false,
            activarPopUpNotaVenta: false,
            activarPopUpConsultaCliente: false,
            activarPopUpLoginVendedor: false,
            activarPopUpDireccionRazonSocial: false,
            activarPopUpConsultaEcommerce: false,
            activarPopUpConsultaReserva: false,
            activarPopUpConsultaDeServicio: false,
            activarPopUpReimpresion: false,
            ActivarPopUpNotasCredito:false,
            CrearCliente: false,
            CrearClienteEmpresa: false,
            ModificarPrograma:false,
            isSale: true,
            identificarClienteForzado: localStorage.getItem('identificarClienteForzado'),
            identificarClienteParaOfertas: localStorage.getItem('identificarClienteParaOfertas'),
            enviarComprobranteWhatsapp: localStorage.getItem('enviarComprobranteWhatsapp'),
            altaClienteForzado: localStorage.getItem('altaClienteForzado'),
            pideNroCelular: localStorage.getItem('pideNroCelular'),
            activarPopUpProductoDuplicado : false,
            consultarOfertas: false,
            consultarGiftcard:false,
            consultarMovsGiftcard:false,
            DatosMovimientosGiftcard:[],
            consultarDescOffers:false,
            phoneAdded:'',
            activaDescuentoPuntos:false,
            activaActGiftcard:false,
            descItem: false,
            activaActGiftcard: false,
            activarPopUpConsultaConvenio: false,
        });

        if (this.state.IsFacturaSelected) {
            if ((this.state.DataCliente.direccion === '' || this.state.DataCliente.razonSocial === '') && this.state.DataCliente.id !== '') {
                this.setState({ activarPopUpDireccionRazonSocial: true });
            }
        }

        $('#EntradaText').attr('placeholder','Código');

        //// Nueva config para identificar cliente forzado antes de vender.

        //if (this.state.identificarClienteForzado === 'true' && this.state.DataCliente.id === '') {
        //    setTimeout(() => {
        //        this.setState({ activarPopUpConsultaCliente: true });
        //    }, 500);
        //}
    }

    DesactivarModalStockSucursal() {
        this.setState({
            activarPopUpStockSucursales: false
        });
    }

    DesactivarModalesAut() {
        this.setState({
            activarMisc: false,
            activarVerDetalle: false,
            activarModif: false,
            activarPagar: false,
            activaDescuentoPuntos:false,
            activarDescuentos: false,
            activarCantidad: false,
            activarModForz: false,
            activarPopTransferir: false,
            activarPopAnular: false,
            activarTransferir: false,
            mostrarTecladoAut: false,
            activarPesable: false,
            activarCPrecio: false,
            activarPopUpConsultaStock: false,
            activarPopUpReimprimirHten: false,
            activarPopUpStock: false,
            activarPopUpStockSucursales: false
        });
        
    }

    //ofertas
    setProductList = (order) => {
        this.setState({ orden: order })
    }
    closeConsultarOferta = () => {
        this.setState( {consultarOfertas: false} )
    }
    closeConsultarGiftcard = () => {
        this.setState( {consultarGiftcard: false} )
    }
    closeCorrelativoModal = () => {
        this.setState( {MostrarCorrelativos: false} )
    }

    closeActGiftcard = () => {
        this.setState( {activaActGiftcard: false} )
    }
    //--------ESTILOS
    row = {
        'display': 'flex'
    }

    column1 = {
        'width': '10%',
        'height': '100%',
        'marginTop': '1%',
        'display': 'none'
    }
    column2 = {
        'minHeight': '35rem',
        'position': 'absolute',
        'backgroundColor': '#000000d9',
        'zIndex': '99',
        'paddingTop': '4rem',
        'minWidth': '100%'
    }
    column3 = {
    }
    column4 = {
    }
    FullScreen = {
        'height': '100%',
        'width': '100%',
        'marginTop': '4%',
        'marginLeft': '16%'
    }
    buttonCategoriaSize = {
        'width': '50%',
        'height': '70px',
        'borderRadius': '15px',
        'paddingTop': '10px',
        'paddingRight': '20px',
        'paddingBottom': '10px',
        'overflow': 'hidden',
        'outline': 'none',
        'borderColor': 'grey',
        'fontSize': '12px'
    }
    buttonProductosSize = {
        'width': '19%',
        'height': '55px',
        'color': 'black',
        'fontweight': 'bold',
        'margin': '1px',
    }
    FullAncho = {
        'width': '100%'
    }
    myFixedHeight = {
        //'height': '20.5rem',
        'height': '20rem',
        'overflowX': 'hidden',
    }
    myFixedWidth = {
        'minWidth': '30rem',
        'height': '15.5rem',
    }
    myFixedHeightReserva = {
        'height': '17.5rem',
        'overflowX': 'hidden',
    }
    myFixedWidthReserva = {
        'minWidth': '30rem',
        'height': '11.7rem',
    }
    myFixedWidthServices = {
        'minWidth': '30rem',
        'height': '5rem',
    }
    TextareaBoleta = {
    }
    BotonesTeclado = {
        'width': '33%'
    }
    AlturaTotales = {
        'height': '15%'
    }
    HightAuto = {
        'height': 'auto'
    }
    BtnSalir = {
        'minHeight': '3.2rem',
        'minWidth': '4rem',
        'marginLeft': '0.25rem',
        'opacity': '90%',
        'borderColor': 'rgb(110, 152, 135)',
        'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px'
    }
    Estilos = {
        badge: {
        },
        buttonWeb: {
        },
        FooterStyles:
        {
        },
        btnOptInferior: {
            'minHeight': '3.2rem',
            'maxWidth': '7rem',
            'marginLeft': '0.25rem',
            'fontSize': '0.8rem',
            'background': 'rgb(233, 234, 234)',
            'color': 'black',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px'
        },
        ProdDiv: {
            'width': '490px',
            'overflow': 'auto',
            'height': '500px'
        },
        DivCentralTest: {
            'width': '50%',
            'float': 'left',
            'padding': '10px',
        },
        FondoBlanco: {
        },
        LetraBlanca: {
            'color': 'white'
        },
        TablaPedido: {
            'backgroundColor': 'white',
            'width': '100%',
            'height': '100%'
        },
        FuenteRoja: {
            'color': 'gray',
            'fontWeight': 'bold'
        },
        FuenteTotal: {
            'fontweight': 'bold',
            'fontSize': '100%',
            'marginBottom': '1%',
            'marginLeft': '5%'
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden',
            'overflowY': 'hidden',
        },
        Columna1: {
            'height': '600px',
            'overflowY': 'scroll',
            'resize': 'both',
            'overflow': 'auto'
        },
        ResizeDiv: {
            'resize': 'both',
            'overflow': 'auto'
        },
        SelloPagado: {
            'width': '24%',
            'height': '24%'
        },
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        ContentPrincipal:
        {
            'marginTop': '5%'
        },
        columnaCentral: {
            'width': '100%',
            'backgroundColor': '#525050',
            'overflowX': 'hidden',
            'paddingTop': '20px',
            'height': '100%',
        },
        Content:
        {
            'marginLeft': '0%',
            'top': '0',
            'backgroundColor': '#525050',
            'overflowX': 'hidden',
            'paddingTop': '20px'
        },
        DivCenter:
        {
            'marginRight': '-17%',
            'marginLeft': '5%'
        },
        Laterales:
        {
            'height': 'auto',
            'width': '10%',
            'position': 'fixed',
            'top': '92px',

            'backgroundColor': '#525050',
            'overflowX': 'hidden',
            'paddingTop': '20px'

        },
        ColIzq: {
            'width': '13%',
            'paddingTop': '50px'
        },
        ColCenter: {
            'width': '13%',
            'paddingTop': '12px',
            'marginLeft': '7%'
        },
        ColDer: {
            'width': '30%',
            'paddingTop': '50px'
        },
        btnOk: {
            'Background': '#47cf73'
        },
        estiloSpan: {
            'pointerEvents': 'none'
        },
        AlertaPinInvalido: {
            'display': 'none'
        },
        AnchoMitad: {
            'width': '50%'
        },
        BotonesDerecha: {
            'float': 'right'
        },
        DivBotonera: {
            'width': '100%'
        },
        BotoneraCategoria:
        {
            'width': '50%',
            'height': '60px',
            'borderRadius': '15px',
            'paddingTop': '10px',
            'paddingRight': '20px',
            'paddingBottom': '10px',
            'overflow': 'hidden',
            'outline': 'none',
            'borderColor': 'grey',
            'fontSize': '80%',
            'textAlign': 'center'
        },
        BotoneraSuperior:
        {
            'borderRadius': '5px',
            'height': '50px',
            'width': '90px',
            'borderColor': '#6e9887',
            'boxShadow': '2px 2px 10px #666',
            'marginLeft': '0%',
            'fontSize': '90%',
            'background': 'rgba(199, 202, 202, 0.19)',
            'marginRight': '0.7%'
        },
        BotonesCliente:
        {
            'border': 'none',
            'borderRadius': '6px',
            'background': 'transparent',
            'fontSize': '10px'
        },
        SpanFontSizeClientes:
        {
            'fontSize': '20px'
        },
        BotoneraProductos:
        {
            'width': '32%',
            'height': '70px',
            'color': 'black',
            'borderColor': '#bfbfc0',
            'marginBottom': '1%',
            'fontSize': '85%',
            'marginRight': '0.25%'
        },
        BotoneraProductosPesables:
        {
            'width': '32%',
            'height': '70px',
            'background': 'rgb(149, 199, 179)',
            'color': 'white',
            'borderColor': '#bfbfc0',
            'marginBottom': '1%',
            'fontSize': '85%',
            'marginRight': '0.25%'
        },
        ButtonOptions:
        {
            'float': 'right',
            'borderRadius': '10%',
            'borderColor': 'gray'
        },
        BtnGroup:
        {
            'minHeight': '3.2rem',
            'minWidth': '4rem',
            'marginLeft': '0.25rem',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px'
        },
        BtnPagar:
        {
            'width': '90%',
            'height':'60px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '2%',
            'background': 'linear-gradient(180deg, #7FEB45 0%, #568039 100%)',
            'font-weight': '500',
            'font-size':'21px',
            //'fontSize':'black',
            'margin': '2px'
        },
        BtnNetear:
        {
            'width': '50px',
            'height': '35px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '2%',
            'background': 'linear-gradient(180deg, #4587eb 0%, #568039 100%)',
            'font-weight': '500',
            'font-size': '7px',
            //'fontSize':'black',
            'margin': '2px'
        },
        btnEditarCarrito:{
            'width':'90%',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '2%',
            'background': 'linear-gradient(180deg, #5EB6CC 0%, #224C57 100%)',
            'fontSize':'bold',
            'margin': '2px'
        },

        


        BtnGroup1:
        {
            'minHeight': '3.2rem',
            'minWidth': '4rem',
            'marginLeft': '0.25rem',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'fontSize': '25px'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '34%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        PopUpStyleActGiftcard: {
            'background': 'rgb(255, 255, 255)',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'borderRadius': '8px',
            'width': '50%',
            'height': '85%'
        }, 
        ContentSubtotal:
        {
        },
        EstiloPopupName:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'borderRadius': '4px'
        },
        EstiloPopup: {
            'width': '38%',
            'height': '70%',
            'border-radius': '8px',
            'margin-left':'30%'
           //'margin': 'initial'
        },
        EstiloPopupAut: {
            'width': '30%',
            'height': '65%',
            'border-radius': '8px',
            'marginLeft': '25%'
            //'margin': 'initial'
        },
        InputSizeDosAut:
        {
            'width': '80%',
            'height': '60px',
            'font-size': '4em',
            'text-align': 'center'
        },
        NombreBox:
        {
            'width': '60%',
            'marginBottom': '1%',
        },
        NombreBoxSave:
        {
            'width': '25%',
            'height': '45px',
            'borderRadius': '4px'
        },
        BtnSuc:
        {
            'width': '50%',
        },
        ModSeparacion:
        {
            'marginBottom': '-4%',
            'fontSize': '90%'
        },
        ModSeparacionFoot:
        {
            'marginTop': '2%',
            'marginBottom': '3%'
        },
        ModSeparacionFP:
        {
            'marginBottom': '-1%',
            'fontSize': '90%'
        },
        ProdSeparacion:
        {
            'marginBottom': '0%',
            'fontSize': '12.0px'
        },
        linea :{
            'border-top': '1px solid black',
            'height': '2px',
            'padding': '0',
            'margin': '10px auto 0 auto'
          },
        DivProd:
        {
            'fontSize': 'calc(-10% + 1vw + 1vh)',
        },
        MarginLeftCR:
        {
            'marginLeft': '-2.5%'
        },
        BtnEmpresasDeliv:
        {
            'borderColor': '#d8e1de',
            'marginLeft': '3%',
            'marginBottom': '1%',
            'width': '45%',
            'fontSize': '80%',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'height': '50px'
        },
        NewMenuBoton: {
            'width': '100%',
            'height': '60px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'marginBottom': '3%'

        },
        NewMenuDiv: {
            'height': '100%',
            'width': '100%',
            'marginTop': '4%',
            'marginLeft': '15%'

        },
        DivNewColData: {
        },
        DivContentData: {
        },
        DivContentDataReserva: {
        },
        DivContentDataDos: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'width': '100%',
            'height': '415px',
            'border': '1px solid #a9a9a9',
            'borderRadius': '8px'
        },
        DivContentDataDosNV: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'width': '55%',
            'height': '415px',
            'border': '1px solid rgb(169, 169, 169)',
            'borderRadius': '8px',
            'marginRight': '3%',
            'marginTop': '-9%'
        },
        MenuConsultas: {
        },
        MenuConsultaBoton: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'marginLeft': '.25rem',
            'background': 'rgb(222, 235, 249)',
            'fontSize': '.80rem'
        },
        MenuConsultaBotonSpecial: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'marginLeft': '.25rem',
            'fontSize': '.80rem'
        },
        MenuConsultaBotonReservaActive: {
            'width': '70%',
            'height': '55px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'marginBottom': '3%',
            'marginLeft': '15%',
            'background': 'rgb(243 136 136)',
            'fontSize': '18px'
        },
        Buttons: {
            'width': '4rem',
            'height': '4rem',
           // 'background': 'rgb(158 103 83)',
            'fontSize': '2rem',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'fontWeight': 'bold'
        },
        ButtonsChar: {
            'width': '4rem',
            'height': '4rem',
            'background': 'rgb(188 227 77)',
            'fontSize': '2rem',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'fontWeight': 'bold',
            'color': 'white',
            'border': '0',
        },
        ButtonsDes: {
            'width': '4rem',
            'height': '4rem',
            'background': 'rgb(144 181 220)',
            'color': 'white',
            'fontSize': '19px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'border': '0',
        },
        ButtonsEnt: {
            'width': '4rem',
            'height': '4rem',
            'background': 'rgb(224 74 88)',
            'color': 'white',
            'fontSize': '19px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'border': '0',
        },
        TdBackground: {
            'width': '30px',
            'background': 'white',
            'borderRadius': '8px'
        },
        TextInput: {
            'width': '14rem',
            'height': '2.5rem',
            'borderRadius': '1.25rem',
            'fontSize': '1.5rem',
            'backgroundColor': '#fbf3b7'
        },
        TextInputVendedor: {
            'width': '14rem',
            'height': '2.5rem',
            'borderRadius': '1.25rem',
            'fontSize': '1.5rem',
            'backgroundColor': '#fbf3b7',
            'WebkitTextSecurity': 'disc'
        },
        DivContent: {
        },  
        DivContentTecladoH: {
            'visibility' : 'hidden'
        },
        ButtonsOpt: {
            'width': '52px',
            'background': '#ceceda',
            'fontSize': '19px',
            'text-decoration-style': 'solid'
        },
        PopUpStyleConsultaStock:
        {
            'height': '40rem',
            'width': '48%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px'
        },
        DivIframe: {
            //'overflow': 'auto',
            //'width': '100%',
            //'height': '100%'
        },
        IFrame: {
            'overflow': 'auto!important'
        },
        IFrameNV: {
            'overflow': 'auto!important'
        },
        StyleDivOrdenServiceP: {
            'overflow': 'auto',
            'width': '100%',
            'height': '85%',
            'marginBottom': '0.5%'
        },
        StyleDivOrdenService: {
            'overflow': 'auto',
            'width': '100%',
            'height': '100%'
        },
        StyleDivNotaVenta: {
            'overflow': 'auto',
            'width': '100%',
            'height': '100%'
        },
        ColorTrOSPrimary: {
            background: '#e8eef3'
        },
        ColorTrOSSecond: {
            background: '#e8f3f2'

        },
        CerrarOs: {

            'height': '5%'
        },
        PopUpStyleOrdenService:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '97%',
            'height': '85%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        PopUpStyleLoginVendedor:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            //'width': '97%',
            //'height': '85%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            //'padding': '50px',
            'borderRadius': '8px'
        },
        PopUpStyleReserva:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '80%',
            'height': '85%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        PopUpStyleNotaVenta:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '80%',
            'height': '80%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        ThStyle0:
        {
        },
        ThStyle1:
        {
        },
        ThStyle2:
        {
        },
        ThStyle3:
        {
        },
        ThStyle0Reserva:
        {
            'position': 'sticky',
            'top': '0',
            'width': '10%'
        },
        ThStyle1Reserva:
        {
            'position': 'sticky',
            'top': '0',
            'width': '15%'
        },
        ThStyle2Reserva:
        {
            'position': 'sticky',
            'top': '0',
            'width': '20%'
        },
        ThStyle3Reserva:
        {
            'position': 'sticky',
            'top': '0',
            'width': '30%'
        },
        BtnAPagar: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'marginTop': '5px',
            'marginBottom': '5px',
            'backgroundColor': '#8abbde',
            'width': '15%',
            'height': '60px',
            'color': 'white'
        },
        BtnCerrarAPagar: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'marginTop': '5px',
            'marginBottom': '5px',
            'width': '15%',
            'height': '60px'
        },
        BotonCerrarOSList: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'width': '15%',
            'height': '60px'
        },
        MarginCCliente: {
        },
        MarginCClienteNV2: {
            'marginLeft': '4%',
            'marginRight': '-10%'
        },
        MarginCClienteNV: {
            'marginLeft': '4%',
            'marginRight': '-1%'
        },
        MarginDivCliente: {
        },
        MarginDivClienteD: {
        },
        MarginDivOfferLeft: {
            'marginLeft' : 'auto'
        },
        MarginDivOfferRight: {
            'marginRight' : 'auto'
        },
        BtnConsultaCliente: {
        },
        BtnConsultaClienteD: {
        },
        PopUpStyleAddCliente: {
            'background': 'rgb(255, 255, 255)',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'borderRadius': '8px'
        },
        SeparacionAddCliente: {
            'margin':'2%'
        },
        PopUpStyleOffers: {
            'width': '20%',
            'background': 'rgb(255, 255, 255)',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'borderRadius': '8px'
        },
        BotonCerrarOSListDs: {
        },
        DesProdStyle: {
            'marginLeft': '-3.5%'
        },
        DesProdStyleDC: {
            'marginLeft': '-4.5%'
        },
        SkuProdStyleDC: {
            'marginLeft': '-6%'
        },
        UnitarioProdStyleDC: {
            'marginLeft': '-7%'
        },
        TotalProdStyleDC: {
            'marginLeft': '-4%'
        },
        PrecPredFormat: {
            'marginRight': '6%',
            'marginLeft': '-4%'

        },
        Style1Text: {
        },
        Style2Text: {
        },
        Style3Text: {
        },
        Style4Text: {
        },
        Style5Text: {
        },
        Style6Text: {
        },
        EstiloPopupCorrelativos: {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '60%',
            'height': '60%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'borderRadius': '4px'
        },
        SeparacionCorrelativos: {
            'paddingRight': '30px'
        },
        BotonAsignarCorr: {
            'backgroundColor': 'rgb(222, 235, 249)',
        },
        BackgroundRfidComplete: {
            'backgroundColor': 'rgb(178, 234, 182)',

        },
        BtnOpcionesL: {
            'width': '20%',
            'margin': '1px',
            'height': '50px',
            'borderColor': '#d8e1de',
            'color': 'white',
            'fontSize': '80%',
        },
        BtnOpcionesL2: {
            'width': '20%',
            'margin': '1px',
            'height': '50px',
            'borderColor': '#d8e1de',
            'color': 'white',
            'fontSize': '80%',
        },
        DivNeedEcommerce:
        {
            'height': '330px',
            'overflowY': 'scroll'
        },
        EditCarrito: {
            'margin' : '5%'
        },
        TitleTextColorStyle:
        {
            'color': 'orange'
        },
        ThStyleLG: {
            'width': '30%',
            // 'paddingRight': '0%'
        } ,
        ThStyleSM: {
            'width': '10%',
            // 'paddingRight': '3%'
        },
        ThStyleM: {
            'width': '20%',
            // 'paddingRight': '0%'
        },
        TableData:{
            'height' : '200px' 
        },
        DivProdGC:{
            'overflow' : 'auto' ,
            'height' : '250px' 
        } 
       
    }

    //--------ESTILOS
    ClickMasCliente() {
        var orden = this.state.orden;
        orden.numeroDeClientes++;
        this.setState({ orden: orden });
        this.ActualizarTexto();
    }

    hex_to_ascii(str1) {
        var hex = str1.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    }

    async AsignarCorrelativo(codigoProd, idinputcorr, trid, buttonid, items) {

        let correlativoVal = document.getElementById('correlativoInput' + idinputcorr).value;
        let largoProd = this.ProductosVerCorrelativosLista.length;

        let asciiCode = "";
        if (GetUserCookie().Sucursal.configPOS.isHexaConvert) {
            if (correlativoVal.startsWith("#")) {
                correlativoVal = correlativoVal.replace("#", "");
                if (correlativoVal.length !== 11) {
                    let ceroAdd = 11 - correlativoVal.length;
                    for (let i = 0; i < ceroAdd; i++) {
                        correlativoVal = "0" + correlativoVal;
                    }
                    correlativoVal = "#" + correlativoVal;
                    asciiCode = correlativoVal;
                }
            } else {
                asciiCode = this.hex_to_ascii(correlativoVal);
            }
        } else {
            asciiCode = correlativoVal;
        }
        asciiCode = asciiCode.replace('\u0000', '');

        let idxExisteCorrelativo = this.ListaCorrelativosIngresados.indexOf(asciiCode);

        if (idxExisteCorrelativo !== -1) {
            swal({
                title: "El correlativo ingresado ya se encuentra asignado.",
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                document.getElementById('correlativoInput' + idinputcorr).value = '';
            });
            return;
        }

        let replaceAsciiCode = asciiCode.replace("#", "");
        document.getElementById('correlativoInput' + idinputcorr).value = asciiCode;
        //Validar Correlativo
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        let validacionRfid = true;
        
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/ValidarCorrelativo?CodigoProd=' + codigoProd + '&TagRfid=' + replaceAsciiCode + '&SucursalID=' + sucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => { validacionRfid = result; });

        let itemadd = { codigo: '', productoscorrelativoslist: [] };
        let itemaddVer = { codigo: '', correlativo: '', idCarro: 0 };

        if (validacionRfid === true) {
            this.ListaCorrelativosIngresados.push(asciiCode);
            itemaddVer.codigo = codigoProd;
            itemaddVer.correlativo = asciiCode;
            itemaddVer.idCarro = items.idCarro;
            this.ProductosVerCorrelativosLista.push(itemaddVer);
            let idxAquitar = this.ProductosAPedirCorrelativo.findIndex(p => p.id === items.id);
            this.ProductosAPedirCorrelativo.splice(idxAquitar, 1);

            document.getElementById('correlativoInput' + idinputcorr).disabled = true;
            document.getElementById('buttonidcorr' + idinputcorr).disabled = true;
            document.getElementById('trRfid' + idinputcorr).style.backgroundColor = "rgb(178, 234, 182)";

            swal({
                title: "Se asignó el correlativo: " + asciiCode + "  al producto: " + codigoProd + ".",
                icon: "success",
                dangerMode: true,
            })


        } else if (validacionRfid === false) {

            document.getElementById('correlativoInput' + idinputcorr).value = '';

            swal({
                title: "Correlativo (" + asciiCode + ") inválido para el Producto : " + codigoProd + ".",
                icon: "error",
                dangerMode: true,
            })

        }
    }

    KeyPressInput(buttonID, e) {
        if (e.key === 'Enter') {
            document.getElementById('buttonidcorr' + buttonID).click();
        }

    }

    GetHtmlPopupNeedCorrelativo() {
        var correlativoInput = "";
        var trid = "";
        var buttonid = "";
        var counts = 0;
        return (
            <Popup
                className=""
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupCorrelativos}
                modal
                onClose={() => { this.CancelarAsignacion() }}
            >
                <center>
                    <h4>Ingrese los correlativos necesarios</h4>
                    <div style={this.Estilos.DivNeedEcommerce}>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan="3" style={this.Estilos.SeparacionCorrelativos} >Codigo</th>
                                    <th colSpan="3" style={this.Estilos.SeparacionCorrelativos} >Producto</th>
                                    <th colSpan="3" style={this.Estilos.SeparacionCorrelativos} >Correlativo</th>
                                    <th style={this.Estilos.SeparacionCorrelativos} ></th>
                                </tr>
                            </thead>
                            {

                                this.ProductosAPedirCorrelativo.map((item) => {
                                    let cant=item.cantidad;
                                  /*  let arrCorrelativos = [];
                                    for (var i=0; i < cant; i++){
                                        var num = counts;
                                        correlativoInput = "correlativoInput" + num;
                                        trid = "trRfid" + num;
                                        buttonid = "buttonidcorr" + num;
                                        counts++;
                                        arrCorrelativos.push(
                                                        <tr key={trid} id={trid} >
                                                        <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{item.id}</td>
                                                        <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{item.nombre}</td>
                                                        <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>
                                                            <input type='tel' id={correlativoInput} onKeyUp={(e) => { this.KeyPressInput(num, e) }} />
                                                        </td>
                                                        <td><button className="btn" id={buttonid} style={this.Estilos.BotonAsignarCorr} onClick={() => { this.AsignarCorrelativo(item.id, num, trid, buttonid, item) }} >Asignar Correlativo </button></td>
                                                    </tr>
                                                    );
                                    }*/
                                    
                                    //for (var i=0; i < cant; i++){
                   
                                        var num = counts;
                                        correlativoInput = "correlativoInput" + num;
                                        trid = "trRfid" + num;
                                        buttonid = "buttonidcorr" + num;
                                        counts++;

                                        return (
                                            <tr key={trid} id={trid} >
                                                <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{item.id}</td>
                                                <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{item.nombre}</td>
                                                <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>
                                                    <input type='tel' id={correlativoInput} onKeyUp={(e) => { this.KeyPressInput(num, e) }} />
                                                </td>
                                                <td><button className="btn" id={buttonid} style={this.Estilos.BotonAsignarCorr} onClick={() => { this.AsignarCorrelativo(item.id, num, trid, buttonid, item) }} >Asignar Correlativo </button></td>
                                            </tr>
                                        )
                                        
                                   // }    
                                  // return   arrCorrelativos;    
                                })

                            }

                            {


                                this.ProductosVerCorrelativosLista.map((item) => {
                                    let idx = this.state.orden.carroProductos.findIndex(p => p.desprod.includes(item.codigo));
                                    let desProducto = this.state.orden.carroProductos[idx].desprod;
                                    let resDes = desProducto.split(";");

                                    return (
                                        <tr key={idx} style={this.Estilos.BackgroundRfidComplete}>
                                            <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{resDes[0]}</td>
                                            <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{resDes[2]}</td>
                                            <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{item.correlativo}
                                            </td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>



                    <button className="btn btn-danger" style={this.Estilos.BtnOpcionesL2} onClick={() => { this.CancelarAsignacion() }}><i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                    <button className="btn btn-success" style={this.Estilos.BtnOpcionesL} onClick={() => { this.TerminarAsignacion() }}
                        data-toggle="tooltip" data-placement="right" title="Listo"><i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupMostrarCorrelativo() {
        var correlativoInput = "";
        var trid = "";
        var buttonid = "";
        var counts = 0;
        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupCorrelativos}
                modal
            >
                <center>
                    <h4>Ver los correlativos Ingresados</h4>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="3" style={this.Estilos.SeparacionCorrelativos} >Codigo</th>
                                <th colSpan="3" style={this.Estilos.SeparacionCorrelativos} >Producto</th>
                                <th colSpan="3" style={this.Estilos.SeparacionCorrelativos} >Correlativo</th>
                                <th style={this.Estilos.SeparacionCorrelativos} ></th>
                            </tr>
                        </thead>
                        {


                            this.ProductosVerCorrelativosLista.map((item) => {
                                let idx = this.state.orden.carroProductos.findIndex(p => p.desprod.includes(item.codigo));
                                let desProducto = this.state.orden.carroProductos[idx].desprod;
                                let resDes = desProducto.split(";");

                                return (
                                    <tr key={item} style={this.Estilos.BackgroundRfidComplete}>
                                        <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{resDes[0]}</td>
                                        <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{resDes[2]}</td>
                                        <td colSpan="3" style={this.Estilos.SeparacionCorrelativos}>{item.correlativo}
                                        </td>
                                        <td></td>
                                    </tr>
                                )
                            })
                        }
                    </table>

                    <button className="btn" style={this.Estilos.BotonAsignarCorr} onClick={() => { this.TerminarAsignacion() }} hidden='hidden'>
                        Terminar Asignación
                    </button>
                    <button className="btn btn-danger" style={this.Estilos.BtnOpcionesL2} onClick={() => { this.CancelarVerAsignacion() }}><i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                    <button className="btn btn-success" style={this.Estilos.BtnOpcionesL} onClick={() => { this.TerminarVerAsignacion() }}
                        data-toggle="tooltip" data-placement="right" title="Listo"><i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    async AgregarClienteAdic() {

        const razonSocial = document.getElementById('razonSocialAddCliente').value;
        const direccion = document.getElementById('direccionAddCliente').value;
        const clienteId = this.state.DataCliente.id;

        if (razonSocial !== '' &&
            direccion !== ''
        ) {
            await fetch(GetUrlApi() + '/api/Clientes/AgregarDatosCliente?RazonSocial=' + razonSocial + '&Direccion=' + direccion + '&ClienteId=' + clienteId, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((respuesta) => respuesta.json(), (error) => {
                if (error) {
                    swal({
                        title: "ERROR !",
                        text: "Error al enviar datos",
                        icon: "error",
                        button: "Entendido !",
                    });
                }
            }).then((data) => {
                if (data) {
                    swal({
                        title: "¡Todo ok!",
                        text: "El cliente fue actualizado correctamente.",
                        icon: "success",
                        button: "OK",
                    }).then(() => {
                        var DataCliente = this.state.DataCliente;
                        DataCliente.razonSocial = razonSocial;
                        DataCliente.direccion = direccion;
                        this.setState({ DataCliente })
                        setTimeout(() => {
                            this.DesactivarModales();
                        }, 700);
                    });
                    
                } else {
                    swal({
                        title: "ERROR !",
                        text: "No se pudo actualizar el cliente",
                        icon: "error",
                        button: "Entendido !",
                    });
                }
            });
        } else {
            swal({
                title: "ERROR !",
                text: "Todos los campos son obligatorios!",
                icon: "error",
                button: "Entendido !",
            });
        }
    }

    async AgregarCliente() {
        this.setState({showSpinner:true});
        let rutCl = document.getElementById('rutAddCliente').value;
        let telefonoCl = this.state.phoneAdded === undefined ? '0' : this.state.phoneAdded;
        let region;
        let comuna;
        let descComuna;
        if (this.state.configAddCliente.inputComuna && this.state.configAddCliente.inputRegion){
             region = document.getElementById('ddlRegion').value;
             comuna = document.getElementById('ddlComuna').value;
             let t = document.getElementById("ddlComuna");
             descComuna = t.options[t.selectedIndex].text
        }
        else{
            region=13;
            comuna=13101;
            descComuna='SANTIAGO';
        }

        let programaCliente  = 0;
        if (this.state.configAddCliente.inputProgramaPuntos){
            programaCliente=document.getElementById('ddlProgramaAddCliente').value;
        }
        let direccion = '';
        if (this.state.configAddCliente.inputDireccion){
            direccion=document.getElementById('inpDireccion').value;
            if (direccion===''){
                swal({
                    title: "ERROR !",
                    text: "Debe ingresar la dirección",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }
        }
        let emailCl = ''; 
        if (this.state.configAddCliente.inputEmail){
            emailCl=document.getElementById('emailAddCliente').value;
            if (emailCl===''){
                swal({
                    title: "ERROR !",
                    text: "Debe ingresar el Email",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }else{
                validate(emailCl);
                if (!res) {
                    swal({
                        title: "ERROR !",
                        text: "Dirección de email incorrecta!",
                        icon: "error",
                        button: "Entendido !",
                    });
                    this.setState({showSpinner:false});
                    return;
                }
            }
        }

        let nombreCl = '';
        if (this.state.configAddCliente.inputNombre){
            nombreCl=document.getElementById('nombreAddCliente').value;
            if (nombreCl===''){
                swal({
                    title: "ERROR !",
                    text: "Debe ingresar el Nombre del Cliente",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }
        }
        let apellidoCl = '';
        if (this.state.configAddCliente.inputApellido){
            apellidoCl=document.getElementById('apellidoAddCliente').value;
            if (nombreCl===''){
                swal({
                    title: "ERROR !",
                    text: "Debe ingresar Apellidos del Cliente",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }
        }


        var infoCliente = {};
       /* if (rutCl !== '' &&
            nombreCl !== ''  && 
            apellidoCl !== '' &&
            emailCl !== '' &&
            direccion !==''
        ) {*/

            if (!this.checkRut(rutCl)) {
                swal({
                    title: "ERROR !",
                    text: "RUT Inválido!",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }

        


            infoCliente.Rut= rutCl;
            infoCliente.Nombre= nombreCl;
            infoCliente.Apellidos = apellidoCl;
            infoCliente.Telefono = telefonoCl;
            infoCliente.Email = emailCl;
            infoCliente.Comuna = descComuna
            infoCliente.ComunaID = comuna;
            infoCliente.Direccion =direccion;
            infoCliente.SucursalID = GetUserCookie().SucursalID;
            infoCliente.Usuario=GetUserCookie().id;

            let empresa = GetUserCookie().EmpresaID;

            await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + rutCl + '&NombreCliente=' + nombreCl + '&EmpresaID=' + empresa, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.length===0){
                        this.setState({ clienteNuevo: true });
                    }else{
                        this.setState({ clienteNuevo: false });
                    }
                }
            );

            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/CrearCliente', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(infoCliente)
            });

           //mods agregar cliente nuevo 22-06-22     
           /* var respuesta = await fetch(GetUrlApi() + '/api/Clientes/AgregarCliente?Nombre=' + nombreCl + '&Apellido=' + apellidoCl + '&Rut=' + rutCl + '&Telefono=' + telefonoCl + '&Email=' + emailCl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            });*/

            if (respuesta.ok) {
                let result = await respuesta.json();

                if (result) {
                    await this.BuscarClienteRut(rutCl, nombreCl);

                    if(this.state.clienteNuevo){
                        if(this.state.Ambiente==='MAISA'){
                            var url = "http://190.196.5.70:6507/ConsumeWS/EnviarMailConfirmacion?texto=NUEVOCLI&cliEmail=" + emailCl + "&locncode=LOC" + GetUserCookie().SucursalID + "&textType=NUEVOCLI&CaseTy=UPDATECLIENTE&lineaTda=" + GetUserCookie().Empresa + "&clienteID=" + rutCl.replace('-', '') + "&when=SI&nombre=" + nombreCl + '+' + apellidoCl;

                            await fetch(url, {
                                method: 'get',
                            })
                            .then((response) => {
                                swal({
                                    title: "¡Todo ok!",
                                    text: "El cliente fue agregado correctamente.",
                                    icon: "success",
                                    button: "OK",
                                });
                            })
                            .catch((error) => {
                                swal({
                                    title: "¡Error!",
                                    text: "Problemas en el envío de correo",
                                    icon: "error",
                                    button: "OK",
                                });
                            });
                        }
                        else {
                            swal({
                                title: "¡Todo ok!",
                                text: "El cliente fue agregado correctamente.",
                                icon: "success",
                                button: "OK",
                            });
                        }
                    }else{
                        if(this.state.Ambiente==='MAISA'){
                            var url = "http://190.196.5.70:6507/ConsumeWS/EnviarMailConfirmacion?texto=CONFIRMACION&cliEmail=" + emailCl + "&locncode=LOC" + GetUserCookie().SucursalID + "&textType=CONFIRMACION&CaseTy=UPDATECLIENTE&lineaTda=" + GetUserCookie().Empresa + "&clienteID=" + rutCl.replace('-', '') + "&when=SI&nombre=" + nombreCl + '+' + apellidoCl;

                            await fetch(url, {
                                method: 'get',
                            })
                            .then((response) => {
                                swal({
                                    title: "¡Todo ok!",
                                    text: "El cliente fue agregado correctamente.",
                                    icon: "success",
                                    button: "OK",
                                });
                            })
                            .catch((error) => {
                                swal({
                                    title: "¡Error!",
                                    text: "Problemas en el envío de correo",
                                    icon: "error",
                                    button: "OK",
                                });
                            });
                        } else {
                            swal({
                                title: "¡Todo ok!",
                                text: "El cliente fue agregado correctamente.",
                                icon: "success",
                                button: "OK",
                            });
                        }
                    }                    

                    // si el cliente queda grabado correctamente, asignamos el programa de puntos
                    if (programaCliente > 0)
                    {
                    //selecciono un programa
                        this.GuardarProgramaCliente(rutCl,programaCliente);
                    }

                    this.setState({showSpinner:false});
                    this.DesactivarModales();
                }else {
                    await swal({
                        title: "¡Error!",
                        text: "Problemas al intentar registrar el cliente",
                        icon: "error",
                        button: "OK",
                    });
                }                
            }
      /*  } else {
            swal({
                title: "ERROR !",
                text: "Todos los campos son obligatorios!",
                icon: "error",
                button: "Entendido !",
            });
            
        }*/
        this.setState({showSpinner:false});
    }
    async onChangeValueHtenTipo(event) {
        this.setState({ tipoHtenSelected: event.target.value })
        
        // console.log(this.state.tipoHtenSelected);
    }
    async onChangeValueQREntity(event) {
        console.log(event)
        this.setState({ QREntitySelected: event.target.value })
    }
    async AgregarClienteEmpresa() {
        this.setState({showSpinner:true});
        let nombreCl = document.getElementById('nombreAddClienteEmpresa').value;
        // let apellidoCl = document.getElementById('apellidoAddCliente').value;
        let rutCl = document.getElementById('rutAddClienteEmpresa').value;
        let telefonoCl = this.state.phoneAdded;
        let emailCl = document.getElementById('emailAddCliente').value;
        let direccion = document.getElementById('inpDireccion').value;

        // let programaCliente  = document.getElementById('ddlProgramaAddCliente').value;
        let region = document.getElementById('ddlRegion').value;
        let comuna = document.getElementById('ddlComuna').value;
        let t = document.getElementById("ddlComuna");
        let descComuna = t.options[t.selectedIndex].text

        let razonSocial = document.getElementById('nombreAddClienteEmpresa').value;
        let giroComercial = document.getElementById('giroComercialAddClienteEmpresa').value;

        var infoCliente = {};
        if (rutCl !== '' &&
            razonSocial !== ''&& 
            telefonoCl !== '' &&
            emailCl !== ''
        ) {

            if (!this.checkRut(rutCl)) {
                swal({
                    title: "ERROR !",
                    text: "RUT Inválido!",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }

            validate(emailCl);
            if (!res) {
                swal({
                    title: "ERROR !",
                    text: "Dirección de email incorrecta!",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }


            infoCliente.Rut= rutCl;
            infoCliente.Nombre= nombreCl;
            infoCliente.Apellidos = "";
            infoCliente.Telefono = telefonoCl;
            infoCliente.Email = emailCl;
            infoCliente.Comuna = descComuna
            infoCliente.ComunaID = comuna;
            infoCliente.Direccion =direccion;
            infoCliente.SucursalID = GetUserCookie().SucursalID;
            infoCliente.SucursalIDBlinamic = GetUserCookie().Sucursal.sucursalIDBlinamic;
            infoCliente.Usuario=GetUserCookie().id;
            infoCliente.RazonSocial = razonSocial;
            infoCliente.GiroComercial = giroComercial;


            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/CrearClienteEmpresa', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(infoCliente)
            });

           //mods agregar cliente nuevo 22-06-22     
           /* var respuesta = await fetch(GetUrlApi() + '/api/Clientes/AgregarCliente?Nombre=' + nombreCl + '&Apellido=' + apellidoCl + '&Rut=' + rutCl + '&Telefono=' + telefonoCl + '&Email=' + emailCl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            });*/

            if (respuesta.ok) {
                await this.BuscarClienteRut(rutCl, razonSocial);
                await swal({
                    title: "¡Todo ok!",
                    text: "El cliente fue agregado correctamente.",
                    icon: "success",
                    button: "OK",
                });
                   // si el cliente queda grabado correctamente, asignamos el programa de puntos
                //    if (programaCliente > 0)
                //     {
                //     //selecciono un programa
                //     //   this.GuardarProgramaCliente(rutCl,programaCliente);    

                //     }
                 
                 this.setState({showSpinner:false});

                this.DesactivarModales();
            }
        } else {
            swal({
                title: "ERROR !",
                text: "Todos los campos son obligatorios!",
                icon: "error",
                button: "Entendido !",
            });
            
        }
        this.setState({showSpinner:false});
    }
    async ModificarProgramaCliente(){
        let rut  = this.state.DataCliente.rut.split('-')[0];
        let programa = document.getElementById('ddlModPrograma').value;

        if (rut !== '' || rut > 0){
            if (programa > 0 ){
                await this.GuardarProgramaCliente(rut,programa);
                await this.BuscaProgramaPuntosCliente(rut);
                await swal({
                    title: "¡Todo ok!",
                    text: "Programa modificado correctamente.",
                    icon: "success",
                    button: "OK",
                });
            
            }
             else {
                swal({
                    title: "ERROR !",
                    text: "Debe seleccionar un Programa",
                    icon: "error",
                    button: "Entendido !",
                });
        }
          
        }
        this.DesactivarModales();
    }
    async GuardarProgramaCliente (rut,programa){
        let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
        if (SwProgramaOfertas === 'N'){
            return;
        }
        try{
            var rutsindv = rut.split('-')[0];
            var usuario = GetUserCookie().username;
            var myBody = {
                "rutCliente": rutsindv,
                "idPrograma": programa,
                "vigencia" : 'S',
                "duracion" : 12, //12 meses por defecto - cliente nuevo-mod POS  
                "usuario" : usuario
            };
            //console.log("Programa cliente nuevo puntos ", myBody);
            let token =sessionStorage.getItem('jwtToken');
            const response = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/GuardaProgramaCliente', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                },
                body: JSON.stringify(myBody),
            }).then((data) => { return data.json() });
            console.log(response);
            if (response.mensajeError!='OK') {
                console.log("Error al guardar programa cliente")
                return;
            }
        }catch(e){
            console.log("Error al guardar programa cliente: " + e);
        }
        
    }
    ElementBlur(e) {
        document.getElementById(e).blur();
    }

    ClickMenosCliente() {
        var orden = this.state.orden;
        orden.numeroDeClientes--;
        this.setState({ orden: orden });
        this.ActualizarTexto();
    }
    GetHtmlTablaPedido() {
        var parrafoPropina = null;
        var orden = this.state.orden;
        var parrafoDescuento = null;
        if (orden.propinaPorc > 0) {
            const total = GetPrecioFormateado(Math.round(this.state.precioTotal * (orden.propinaPorc / 100)));
            parrafoPropina =
                <tr>
                    <td>
                        <p> Propina</p>
                    </td>
                    <td>{orden.propinaPorc}%</td>
                    <td>${total}</td>
                </tr >
        }
        else if (orden.propinaCant > 0) {
            parrafoPropina = <tr>
                <td>
                    Propina
                </td>
                <td></td>
                <td>${orden.propinaCant}</td>
            </tr >
        }
        else if (orden.propinaDiff > 0) {
            parrafoPropina = <tr>
                <td>
                    Propina
                </td>
                <td></td>
                <td>${(this.state.precioTotal - orden.propinaDiff) * -1}</td>
            </tr >
        }

        //fin propina


        //Descuentos GLOBALES
        if (orden.dctoOrden > 0) {
            const valorDesc = Math.round(this.state.precioTotal * (orden.dctoOrden / 100));
            parrafoDescuento =
                <tr id="seccionDescuentosGlobales">
                    <td>
                        <p style={this.Estilos.FuenteRoja}>Descuento </p>
                    </td>
                    <td>
                        <p style={this.Estilos.FuenteRoja}>  {orden.dctoOrden}% </p>
                    </td>
                    <td>
                        <p style={this.Estilos.FuenteRoja}>  {valorDesc} </p>
                    </td>
                </tr>
        }
        else if (orden.dctoEfec > 0) {
            parrafoDescuento =
                <tr>
                    <td id="seccionDescuentosGlobales">
                        <p style={this.Estilos.FuenteRoja}>Descuento</p>
                    </td>
                    <td>
                        <p> ${orden.descEfectivo} </p>
                    </td >
                    <td>
                    </td>
                </tr >
        }
        //fin descuentos GLOBALES
        return (
            <table style={this.Estilos.TablaPedido}>
                <thead>

                </thead>
                <tbody>
                    {

                        this.state.carroProductos.map((item, i) => {
                            var descPorc = '';
                            var modificadores = '';
                            var parrafoDescuento = null;
                            if (item.modificadores) {
                                item.modificadores.forEach((item) => {
                                    modificadores += '\n';
                                    modificadores += item;

                                });
                            }
                            //descuentos
                            if (item.descuentoPorc > 0) {
                                descPorc = item.descuentoPorc;
                                const valorDesc = Math.round(item.precpred * (descPorc / 100));

                                parrafoDescuento = <p style={this.Estilos.FuenteRoja}>{descPorc}% {valorDesc}</p>
                            }

                            //fin descuentos

                            var prefix = 'tablaProds';
                            return (
                                <tr onClick={() => { this.ClickTablaPedido(i) }} key={prefix + i}>
                                    <td>{item.cantidad} </td>
                                    <td>{item.desprod}<br />{modificadores}

                                    </td>
                                    {parrafoDescuento}
                                    <td>{GetPrecioFormateado(item.precpred)}</td>
                                </tr>
                            )
                        })

                    }
                    {parrafoPropina}
                    {parrafoDescuento}
                </tbody>
            </table>
        );
    }

    myOnChange(input) {
        //input=="" se presiona borrar en teclado virtual
        
        if (input==="*" && this.state.itemXCantConfig === 'false'){
            return;
        }
        if (input==""){
            this.BorrarChar();
        }
        else{
            var element = document.getElementById('EntradaText');
                         
            if (input.length>1){
                if (!element.value.includes(input)){
                    if (element.value !== input){
                        element.value += input;
                    }
                    else{
                        element.value=input;
                    }
                }
          
            }
            else{
                element.value += input;
            }
           
        }
    };

    GetVirtualKeyBoard() {
        return (<div id="myVirtualKeyboard">
            <Keyboard
                type="text"
                onChange={(value) => {
                    console.log("VKEYB ", value);
                    this.myOnChange(value);
                }}
               
                value={''}
                inputClassName="hiddenKeyboardInput"
            />
        </div>)
    }

    ShowVirtualKeyBoard() {
        if (isBrowser) {
            $('.hiddenKeyboardInput').focus();
        }
        
    }
   
    GetHtmlTecladoRetail() {
     
        
        return (
            <div className="mt-2 mb-2 text-center" >
                <div className="text-center d-flex d-inline-flex ">
                    <input className="text-center" style={this.Estilos.TextInput} id='EntradaText' placeholder='C&oacute;digo' type="text"  
                        inputMode="" name='EntradaText'
                        onChange={()    => { this.focusChangeState(2) }}
                        onClick={() => { this.ShowVirtualKeyBoard() }} /> 
                </div>
                {
                    (this.state.isVisibleTeclado === 'true') ? 
                    (<div className="text-center" id="padNumerico" tabIndex="-1" style={this.Estilos.DivContent}>
                    <table className="m-auto text-white">
                        <tbody>
                         
                            <tr>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(1) }} type="button" name="1" id="1" value="1"/></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(2) }} type="button" name="2" id="2" value="2"/></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(3) }} type="button" name="3" id="3" value="3"/></td>
                            </tr>
                            <tr>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(4) }} type="button" name="4" id="4" value="4" /></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(5) }} type="button" name="5" id="5" value="5" /></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(6) }} type="button" name="6" id="6" value="6" /></td>

                            </tr>
                            <tr>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(7) }} type="button" name="7" id="7" value="7" /></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(8) }} type="button" name="8" id="8" value="8" /></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(9) }} type="button" name="9" id="9" value="9" /></td>

                            </tr>
                            <tr>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn('-') }} type="button" name="-" id="-" value="-" /></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(0) }} type="button" name="0" id="0" value="0" /></td>
                                <td><input className="btn" style={this.Estilos.Buttons} onClick={() => { this.ClickBtn('*') }} type="button" name="*" id="*" value="*" /></td>

                            </tr>
                        </tbody>
                    </table>
                </div>) 
                : 
                (<div><br></br></div>)
      
                }
                

                <div className="text-center" style={this.Estilos.DivContent}>
                    <table className="m-auto">
                        <tbody>
                            <tr> 
                                <td><button className="btn" style={this.Estilos.ButtonsDes} onClick={() => { this.ClickClear() }} type="button" name="Clear" id="Clear"><i className="fa fa-undo " aria-hidden="true"></i></button></td>
                                <td><button className="btn" style={this.Estilos.ButtonsEnt} onClick={() => { this.BorrarChar() }} type="button" name="Borrar" id="Borrar"><i className="fa fa-long-arrow-left " aria-hidden="true"></i></button></td>
                                <td><button className="btn" style={this.Estilos.ButtonsChar} onClick={() => { this.ClickEnviar(document.getElementById('EntradaText').value, true) }} type="button" name="Enviar" id="Enviar"><i className="fa fa-paper-plane " aria-hidden="true"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    GetAbonado() {
        return Math.round(Number(this.state.montoAbonado));
    }

    GetTotal() {
        var ValorDescuento = this.GetDescuento();
        const descPromo = this.getDescPromo()
        const valorProp = Number(this.GetValorPropina());
        const total = Number(this.state.precioTotal);
        const abonado = Number(this.state.montoAbonado);

        if (!ValorDescuento) {
            ValorDescuento = 0;
        }
        return Math.round((total - ValorDescuento - abonado)-descPromo /*+ valorProp*/);
    }

    GetTotalWithOffers() {

        const descPromo = this.getDescPromo()
        const valorProp = Number(this.GetValorPropina());
        const total = Number(this.state.precioTotal);
        const abonado = Number(this.state.montoAbonado);

       

        return Math.round((total - abonado)-descPromo /*+ valorProp*/);
    }

    GetCantidadProductosTotales()
    {
        var cont = 0;
        if (this.state.orden.carroProductos.length > 0) {
            this.state.orden.carroProductos.forEach(function (producto) {
                cont += producto.cantidad
            });
        }
        return Math.round(cont);
    }

    GetDiferenciaTotalvsNotaCredito()
    {
        var totalcompra = this.GetTotal();
        //if ((totalcompra - this.MontoNC) <= 0)
        //{
        //    return 0;
        //}
        //else
        //{
            return Math.round(totalcompra - this.MontoNC);
/*        }*/
        
    }

    GetSubTotal() {
        const total = Number(this.state.precioTotal);


        return Math.round(total);
    }

    GetIVA() {
        var totalConIVA = this.GetTotal();
        var CostoNeto = (totalConIVA / 1.19);
        var IVA = Number(CostoNeto * 0.19);
        return Math.round(IVA);
    }

    GetDescuento() {
        var cont = 0;
        //console.log('***************getdescuento()************')
        if (this.state.orden.dctoOrden > 0) {
            cont += (Number(this.state.precioTotalDescuentoOferta)-this.getDescItem())* (this.state.orden.dctoOrden / 100);
        }
        //console.log(cont, 'primer cont')
        if (this.state.orden.descEfectivo > 0) {
            cont += this.state.orden.descEfectivo;
        }
        if (this.state.orden.carroProductos.length > 0) {
            this.state.orden.carroProductos.forEach(function (producto) {
                if(producto.isDescItem)
                    cont+=producto.descItem
                if (producto.descuentoPorc > 0) {
                    
                    const descuento = ((producto.precpred * producto.cantidad) - (producto.valueOfferUnitario ? producto.valueOfferUnitario : 0)) * (producto.descuentoPorc / 100);
                    cont += descuento;
                }
            });
        }
        return Math.round(cont);
    }
    getDescItem() {
        let cont = 0
        this.state.orden.carroProductos.forEach(function (producto) {
            cont += producto.isDescItem? producto.descItem:0
        })
        return Math.round(cont)

    }
    getDescPromo() {
        let cont = 0
        this.state.orden.carroProductos.forEach(function (producto) {
            cont += producto.valueOffer? producto.valueOffer:0
        })
        return Math.round(cont)
    }
    ClickModificador(item) {
        this.UltimoItemClickeado = item;
        this.setState({ activarModForz: true });
    }
    ClickPesable(item) {
        this.UltimoItemClickeado = item;
        this.setState({ activarPesable: true });
    }
    GetEspecificarPedido() {
        if (!this.UltimoItemClickeado) {
            return null;
        }
        else {
            return (
                <EspecificarPedido AgregarProductoBarcode={this.AgregarProductoBarcode.bind(this)}
                    CerrarModalMaster={this.CerrarModalMaster}
                    DesactivarModales={this.DesactivarModales}
                    carrito={this.state.carroProductos}
                    handler={this.handler}
                    prod={this.UltimoItemClickeado}

                    traerProducto={this.TraerProducto.bind(this)}

                />
            );
        }
    }
    descItemNormalize() {

    }
    ClickNuevaOrden() {
        if (this.state.IsClienteObligatorio && this.state.identificarClienteForzado == 'true') {
            this.setState({ activarPopUpConsultaCliente: true });
            return;
        }

        //document.getElementById('btnListo').disabled = true;
        if (!this.IdVenta > 0)
            this.GenerarOrden();
        else {
            this.ActualizarOrden();
        }
        //document.getElementById('btnListo').disabled = false;
        const nMesa = this.state.orden.MesaID;
        if (nMesa) {
            window.location.href = '/Venta?tipoV=1&nmesa=' + nMesa;
        }
        else {
            window.location.href = '/Venta';
        }


    }
    async ClickDividir() {
        //document.getElementById('btnListo').disabled = true;
        if (!this.IdVenta > 0)
            await this.GenerarOrden();
        else {
            await this.ActualizarOrden();
        }
        var data = {};
        data.OrdenID = this.IdVenta;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/DesmenuzarOrden', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        //document.getElementById('btnListo').disabled = false;
        this.Redirigir('/OrdenesMesa?ordenID=' + this.IdVenta);
    }
    // ClickClear() {
    //     document.getElementById('1').style.backgroundcolor = '#bbb';
    //     document.getElementById('2').style.backgroundcolor = '#bbb';
    //     document.getElementById('3').style.backgroundcolor = '#bbb';
    //     document.getElementById('4').style.backgroundcolor = '#bbb';
    //     this.Numero = '';
    //     document.getElementById('PINbox').value = '';
    //     this.setState({ isNumericKeyEntered: false });

    // }
    GetHtmlPopupComensales() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                closeOnDocumentClick>
                <center>
                    <div className="row" >
                        <div style={this.Estilos.ColCenter}>
                            <table id="tablaBotones">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="3">
                                            <h5>Seleccione el numero de comensales</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input id="PINbox" type="number" />
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>
                                    </tr>

                                    <tr>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>
                                    </tr>
                                    <tr>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                        <td> <input onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>
                                    </tr>
                                    <tr>
                                        <td><input onClick={() => { this.ClickClear() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                        <td> <input onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                        <td><button onClick={() => { this.ClickEnterNumClientes() }} className='PINbutton enter' name='+' id='+'>
                                            <span style={this.Estilos.estiloSpan} className="glyphicon glyphicon-ok"></span>Enter
                                                </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>


                </center>
            </Popup>
        );
    }



    GetHtmlPopupConsultaDeStock() {
        // console.log('Galeria ', this.state.productImages);
        // console.log("STOCK ", this.state.stockByStore);
        // console.log("SUC ", GetUserCookie().Sucursal.sucursalIDBlinamic);

        const localStock = this.state.stockByStore.filter(item => item.sucursalID === GetUserCookie().Sucursal.sucursalIDBlinamic);
        // console.log("local ", localStock);
        return (
            <Popup
                className="consultaStock"
                // contentStyle={this.Estilos.PopUpStyleConsultaStock}
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                <Table className="table table-sm table-striped table-hover table-dark">
                    <thead>
                        <tr>
                            <th>PLU</th>
                            <th>Codigo Proveedor</th>
                            <th>Descripci&oacute;n</th>
                            <th>Valor</th>
                            <th>Sucursal</th>
                            <th>Stock</th>
                            <th>Otras sucursales</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            localStock.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.plu}</td>
                                        <td>{item.codigoProveedor}</td>
                                        <td>{item.descripcion}</td>
                                        <td>${item.valor}</td>
                                        <td>{item.sucursal}</td>
                                        <td>{item.stock}</td>
                                        <td><Button className="btn btn-info " onClick={() => {
                                            this.setState({ activarPopUpStockSucursales: true , CodigoPluStock: item.plu});
                                            }}>+</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }                       
                    </tbody>
                </Table>
                </div>
                {/* <MySlider images={this.state.productImages} /> */}
                <div className="row pt-2 justify-content-center">
                    <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Volver</strong></button>
                </div>
            </Popup>
        );
    }

    GetHtmlPopupStockSucursales(codigoProducto) {
        // console.log('Galeria 2 ', this.state.productImages);
        // console.log("STOCK 2 ", this.state.stockByStore);
        // console.log("SUC 2 ", GetUserCookie().Sucursal.sucursalIDBlinamic);
        // console.log("Prod 2 ", this.state.CodigoPluStock);
        const localStock = this.state.stockByStore.filter(item => item.sucursalID !== GetUserCookie().Sucursal.sucursalIDBlinamic && item.plu == this.state.CodigoPluStock);
        // console.log("local 2 ", localStock);
        return (
            <Popup
                className="consultaStock"
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                // contentStyle={this.Estilos.PopUpStyleConsultaStock}
                
                defaultOpen
                modal
                onClose={() => { this.DesactivarModalStockSucursal() }}
                closeOnDocumentClick>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                <Table bordered dark striped size="sm" className="text-center">
                {/* <Table className="table table-sm table-striped table-hover table-dark"> */}
                    <thead>
                        <tr>
                            <th>PLU</th>
                            <th>Codigo Proveedor</th>
                            <th>Descripci&oacute;n</th>
                            <th>Valor</th>
                            <th>Sucursal</th>
                            <th>Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            localStock.map((item, index) => {
                                console.log('item ', item);
                                return (
                                    <tr key={index}>
                                        <td>{item.plu}</td>
                                        <td>{item.codigoProveedor}</td>
                                        <td>{item.descripcion}</td>
                                        <td>${item.valor}</td>
                                        <td>{item.sucursal}</td>
                                        <td>{item.stock}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                </div>
                <div className="row pt-2 justify-content-center">
                    <div className="d-inline-flex ml-2 mr-2 mb-2">
                        <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-primary" onClick={() => { this.DesactivarModalStockSucursal() }}><strong>Volver</strong></button>
                    </div>
                    <div className="d-inline-flex ml-2 mr-2 mb-2">
                        <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Salir</strong></button>
                    </div>    
                </div>
            </Popup>
        );
    }


    GetHtmlPopupConsultaDeServicios() {

        let url = "https://todobikes.blinamic.cl/TipoServicios";

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleConsultaStock}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.DivIframe}>
                    <iframe id="Example2"
                        style={this.Estilos.IFrame}
                        name="Example2"
                        title="Example2"
                        width="100%"
                        height="800px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src={url}>
                    </iframe>
                </div>

            </Popup>
        );
    }


    GetHtmlPopUpDesplegarPdfOS() {
        let url = 'https://todobikes.blinamic.cl/Tickets/OrdenTrabajoBikes/' + this.state.TicketID + '?verPdf=True&envioMail=False&es_Tecnico=False';
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>

                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-danger" style={this.Estilos.BtnCerrarAPagar} onClick={() => { this.DesactivarModales() }}><strong>Cerrar</strong></button>
                        <button className="btn" style={this.Estilos.BtnAPagar} onClick={() => { this.IrAPagar() }}><strong>Ir a Pagar</strong></button>
                    </div>

                </div>
                <div id="ContainWeb" style={this.Estilos.StyleDivOrdenService}>
                    <iframe id="Example2"
                        style={this.Estilos.IFrame}
                        name="Example2"
                        title="Example2"
                        width="100%"
                        height="550px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src={url}>
                    </iframe>

                </div>

            </Popup>
        );
    }


    GetHtmlPopUpDesplegarNotaVenta() {
        let url = 'https://todobikes.blinamic.cl/Emisors/NotaVenta?emisorId=' + this.state.NotaVentaID + '&enviarCorreo=false';
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-danger" style={this.Estilos.BtnCerrarAPagar} onClick={() => { this.DesactivarModales() }}><strong>Cerrar</strong></button>
                        <button className="btn" style={this.Estilos.BtnAPagar} onClick={() => { this.IrAPagarNotaVenta() }}><strong>Ir a Pagar</strong></button>
                    </div>

                </div>
                <div id="ContainWeb" style={this.Estilos.StyleDivOrdenService}>
                    <iframe id="Example2"
                        style={this.Estilos.IFrameNV}
                        name="Example2"
                        title="Example2"
                        width="100%"
                        height="550px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src={url}>
                    </iframe>

                </div>

            </Popup>
        );
    }

    GetNombreConvenioSinLimite(limiteValorConvenio)
    {
        if (limiteValorConvenio==0) { return 'Sin Límite'; }
        else { return limiteValorConvenio; }
    }

    GetNombreConvenioCreditoDisponible(limiteValorConvenio, creditoDisponible) {

        if (limiteValorConvenio == 0) {
            if (creditoDisponible <= 0) { return 'Sin Límite'; }
            else { return creditoDisponible; }
        }
        else
        {
            return creditoDisponible;
        }
    }

    DesplegarOS(OSID) {
        this.setState({ TicketID: OSID, activarPopUpPdfOS: true });
        this.setState({ OrdenDisplay: 'Orden de servicio ' + OSID, estacionStyle: 'text-center bg-primary text-white' });
    }
    DesplegarNotaVenta(NotaVentaID) {
        this.LimpiarVentana();
        this.setState({ NotaVentaID: NotaVentaID, activarPopUpNotaVenta: true });
        this.DesplegarDetallePagosEcommerse(NotaVentaID);
        this.setState({ OrdenDisplay: 'ID venta Ecommerce ' + NotaVentaID, estacionStyle: 'text-center bg-dark text-white' });
    }
    async IrAPagar() {
        var arr = [];
        this.setState({ disableOrders: true, disableOrdersEcommerce: true, disableOrdersReserva: true });
        var result = await fetch(GetUrlApi() + '/api/Orden/TraerProductosOrdenesDeServicio?TicketID=' + this.state.TicketID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then((result) => { arr = result; });
        this.TraerClienteOS();

        await this.ClickEnviarUno(arr);
        this.DesactivarModales();
    }

    async IrAPagarNotaVenta() {
        var arr = [];
        this.setState({ disableOrders: true, disableOrdersEcommerce: true, disableOrdersReserva: true });
        var result = await fetch(GetUrlApi() + '/api/Orden/TraerProductosNotaVenta?NotaVentaID=' + this.state.NotaVentaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then((result) => { arr = result; });
        this.setState({ IsEcommerce: true });
        this.TraerClienteNV();
        await this.ClickEnviarDos(arr);
        this.DesactivarModales();
    }

    async TraerClienteOS() {
        var result = await fetch(GetUrlApi() + '/api/Clientes/GetClientesOS?TicketID=' + this.state.TicketID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then((result) => {
            this.DesplegarDatosClienteD(result.id);
        }, this);
    }


    async TraerClienteNV() {
        var result = await fetch(GetUrlApi() + '/api/Clientes/GetClientesNV?NotaVentaID=' + this.state.NotaVentaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then((result) => {
            this.DesplegarDatosClienteD(result.id);
        }, this);
    }

    GetHtmlPopupConsultaOrdenDeServicio() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <table className="table table-sm table-striped table-hover table-dark">
                        <thead>
                            <tr className="">
                                <th className="col-lg-1 col-md-1 col-sm-1 col-1">Número Orden</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Nombre Cliente</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Servicio</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Rut</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Email</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Teléfono</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Total</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Mecanico</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Bicicleta</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Fecha Inicio</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Fecha Termino</th>
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Detalle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.OrdenesDeServicio.map((item, index) => {
                                    return (<tr key={index} onClick={() => { this.DesplegarOS(item.id) }}>
                                        <td className="col-lg-1 col-md-1 col-sm-1 col-1">{item.id}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.nombre}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1 text-truncate">{item.nombreServicio}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.rut}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.email}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.telefono}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{GetPrecioFormateado(item.total)}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.mecanico}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1 text-truncate">{item.bicicleta}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.fechaIngreso}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.fechaTermino}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1 text-truncate">{item.detalle}</td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div style={this.Estilos.CerrarOs}><button style={this.Estilos.BotonCerrarOSList} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cerrar</strong></button></div>

            </Popup>
        );
    }

    async BuscarCliente() {
        document.getElementById('msgerror').style.display = 'block';
        let RutCliente = document.getElementById('RutCliente').value;
        let validaRut = RutCliente!=''? this.checkRut(RutCliente):true;
        if (validaRut){
            let NombreCliente = document.getElementById('NombreCliente').value;
            if (RutCliente == '' && NombreCliente==''){
                swal('Ingrese al menos un parámetro de búsqueda');
                return;
            }
            this.setState({ showSpinner: true });
            this.setState({ disableOrders: false });

        
            
            var rutAux = RutCliente;
            // Despejar Puntos
            var valor = rutAux.replaceAll('.', '');
            // Despejar Guión
            valor = valor.replaceAll('-', '');
            // Aislar Cuerpo y Dígito Verificador
            const cuerpo = valor.slice(0, -1);
            let dv = valor.slice(-1).toUpperCase();
            // Formatear RUN
            rutAux = cuerpo + '-' + dv

            var myBody = {};
            myBody.rut = rutAux;
            myBody.nombre = NombreCliente;

            let empresa = GetUserCookie().EmpresaID;
     
            let url = GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + rutAux + '&NombreCliente=' + NombreCliente +'&EmpresaID=' + empresa;
         
           
            var respuesta  = await fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.length===0){
                        swal({
                            title: 'No se encontraron coincidencias.',
                            icon: "warning",
                            dangerMode: true,
                        })
                    }
                    this.setState({ ConsultaClientes: result });
                    this.setState({ showSpinner: false });

                }
            );
        }else{
           // $("#msgerror").html("El Rut no es válido.");
            await Swal.fire(
                'Error!',
                'El RUT no es válido' ,
                'error'
              )
        }
    }

    

    async BuscarConvenios(rut)
    {
        //ConsultaConvenios


        // document.getElementById('msgerror').style.display = 'block';

        var valor = rut.replaceAll('-', '');
        const cuerpo = valor.slice(0, -1);


        let url = GetUrlBlinamicApi() + '/api/Convenio/GetConvenioByRutSucursal?rut=' + cuerpo + '&sucursalID=' + GetUserCookie().Sucursal.sucursalIDBlinamic;


            let token = sessionStorage.getItem('jwtToken')
            var respuesta = await fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.data.length === 0) {
                        //swal({
                        //    title: 'No se encontraron Convenios.',
                        //    icon: "warning",
                        //    dangerMode: true,
                        //})

                        if (this.state.EsMetodoDesplegarDatosClienteOfertas == true)
                        {
                            this.setMontOfertaFidelizacion();
                        }

                    }
                    else {

                        this.setState({ ConsultaConvenios: result.data });
                        this.setState({ showSpinner: false });
                        this.setState({ activarPopUpConsultaConvenio: true });
                    }
                }
            );
    }



    async LoginVendedor(pin) {
    
        if (pin !== ''){
            this.setState({ showSpinner: true });
            const urlApi = GetUrlApi() + '/api/Usuarios/vendedor/' + pin + '/' + GetUserCookie().SucursalID;
            fetch(urlApi).then((usuario) => usuario.json())
                .then((datos) => {
                        const nombre = datos.nombre + ' ' + datos.apellido;
    
                        const vendedor = this.state.DataVendedor;
                        vendedor.nombre = nombre;
                        vendedor.id = datos.id;
    
                    const orden = this.state.orden;
                    orden.UsuarioVendedorPosID = vendedor.id;
    
                    this.setState({ DataVendedor: vendedor });
                    this.setState({ orden: orden });
                    this.setState({ showSpinner: false });
                    this.setState({ activarPopUpLoginVendedor: false });
                    
                    if (!this.state.SeleccionoCliente && this.state.identificarClienteForzado == 'true') {
                        this.setState({ activarPopUpConsultaCliente: true });
                       $('#RutCliente').focus();
                        return;
                    } else {
                        if (this.state.SeleccionoCliente) {
                            this.ClickPagar();
                            document.getElementById('EntradaText').value = '';
                        }
                        else {
                            //this.DesplegarDatosClienteGenericoParaVenta();
                            this.ClickPagar();
                            document.getElementById('EntradaText').value = '';
                        }
                        
                        
                    }
                    
                                    
                    
                }).catch(() => {
                    this.setState({ showSpinner: false });
                    this.setState({ TextoIngresado: ''});
                    document.getElementById('EntradaText').value = '';
    
                    swal({
                        title: "ERROR !",
                        text: "Código Inválido!",
                        icon: "error",
                        button: "Entendido !",
                    });
                    return;
                });
        }
        
    }

    async BuscarNotaVenta() {
        let RutCliente = document.getElementById('RutClienteNotaVenta').value;
        let NombreCliente = document.getElementById('NombreClienteNotaVenta').value;
        let NumeroNotaVenta = document.getElementById('NumeroNotaVenta').value;

        var myBody = {};
        myBody.rut = RutCliente;
        myBody.nombre = NombreCliente;
        myBody.NumeroNotaVenta = NumeroNotaVenta;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetNotasVentaEcommerce?RutCliente=' + RutCliente + '&NombreCliente=' + NombreCliente + '&NumeroNotaVenta=' + NumeroNotaVenta, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.setState({ NotasVenta: result });
            }
        );
    }

    async BuscarNotaVentaCliente(clienteId) {
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetNotasVentaEcommerceCliente?clienteId=' + clienteId, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.setState({ NotasVenta: result, ecommerceQty: result.length });
            }
        );
    }



    async BuscarOrdenServicio() {
        let RutCliente = document.getElementById('RutClienteOS').value;
        let NombreCliente = document.getElementById('NombreClienteOS').value;
        let TicketId = document.getElementById('TicketId').value;

        var myBody = {};
        myBody.rut = RutCliente;
        myBody.nombre = NombreCliente;
        myBody.TicketId = TicketId;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetOrdenesServicio?RutCliente=' + RutCliente + '&NombreCliente=' + NombreCliente + '&TicketId=' + TicketId, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.setState({ OrdenesDeServicio: result });
            }
        );
    }


    async BuscarClienteRut(rut, NombreCliente) {
        let empresa = GetUserCookie().EmpresaID;

        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + rut + '&NombreCliente=' + NombreCliente + '&EmpresaID=' + empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                if (result.length > 0) {
                    this.DesplegarDatosClienteD(result[0].id);
                    return;
                }
            }
        ).catch(error => {
            swal({
                title: "Error al recuperar datos del cliente",
                text: "Por favor ingrese a 'Identificar Cliente' y busque ingresando los datos recientemente guardados.",
                icon: "warning",
                button: "OK",
            });
        });
    }
    async BuscaProgramaPuntosCliente(rutcliente){
        let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
        if (SwProgramaOfertas === 'N'){
            return;
        }
        let token =sessionStorage.getItem('jwtToken'); 
        let empresa= GetUserCookie().EmpresaID;
        
        try{
            var rutsindv = rutcliente.split('-')[0];
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/getConsultaPuntosCliente?RutCliente=' + rutsindv + '&EmpresaID=' + empresa, {
                method: 'get',
                headers: {
                    "Content-Type": "application/json",
                      "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {

                     if (result.length > 0){
                        this.setState({
                            DataClientePuntos: {
                                rut: rutsindv,
                                idProgramaPuntos: result[0].idProgramaPuntos,
                                descripcionPrograma: result[0].descripcionPrograma,
                                fechaVencimiento: result[0].fechaVencimiento,
                                duracionPuntos: result[0].duracionPuntos,
                                saldoPuntos: result[0].saldoPuntos,
                                saldoPesos: result[0].saldoPesos,
                                factorCanje :result[0].factorCanje 
                            }
                    
                        }); 
                }
                else{
                    this.setState({DataClientePuntos:{ 
                        rut: 0, idProgramaPuntos:'',descripcionPrograma:'(Sin Programa)',fechaVencimiento:'',
                        duracionPuntos:'',saldoPuntos:0,saldoPesos:0, factorCanje:0
                    } })
                }
                    return;
                });
        }
       catch (e){
           console.log(e);
       }
    }

    async BuscaProgramasPuntos(){
        let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
        if (SwProgramaOfertas === 'N' || SwProgramaOfertas=='' || SwProgramaOfertas == undefined ){
            return;
        }
        let empresa= GetUserCookie().EmpresaID;
        let token = await sessionStorage.getItem('jwtToken');
        try{ 
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/getProgramasPuntos?EmpresaID='+ empresa, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.length > 0){
                        this.ProgramasPuntos = result;
                }
                    return;
                });
        }
       catch (e){
                   //console.log(e);
       }
       return;
    }

    async DesplegarDatosClienteD(idCliente) {



        let empresa = GetUserCookie().EmpresaID;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetDataClientes?IdCliente=' + idCliente + '&EmpresaID='+ empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
              this.setState({
                    DataCliente: {
                        id: result.id,
                        nombre: result.nombre,
                        rut: result.rut,
                        telefono: result.telefono,
                        email: result.email,
                        fechaUltServicio: result.fechaUltServicio,
                        montoUltServicio: result.montoUltServicio,
                        fechaUltCompra: result.fechaUltCompra,
                        montoUltCompra: result.montoUltCompra,
                        estrellas: parseInt(result.estrellas, 10),
                        ciudad: result.ciudad,
                        comuna: result.comuna,
                        direccion: result.direccion,
                        girocomercial: result.giroComercial,
                        cantidadNotasCredito: result.cantidadNotasCredito,
                        tipoCliente: result.tipoCliente,
                        razonSocial: result.razonSocial,
                        NombreClasif: result.nombreClasif,
                        PosicionClasif: result.posicionClasif,
                        comunaID :result.comunaID,
                        regionID : result.regionID,
                        nombres:result.nombres,
                        apellidos: result.apellidos
                    },
                    DataClienteConvenio: {
                        usaConvenio: 0, idConvenio: 0, limiteCreditoConvenio: 0, CantidadDisponible: 0, NombreConvenio: '(Sin Convenio)', EsBeneficioInterno: 0
                    },
                    SeleccionoCliente: true
                });
               
                this.BuscaProgramaPuntosCliente(this.state.DataCliente.rut);


                //if (this.state.identificarConvenioCliente == 'true') {
                this.BuscarConvenios(this.state.DataCliente.rut);
                    
                //}


                

                return;
            });
    }

    

    async ProductoDuplicadoSeleccion(producto) {
        if (producto.stockDisponible < 1) {
            console.log(producto.stockDisponible);
            swal({
                title: "El producto no posee stock.",
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();

            });
        } else {
            await this.ClickEnviar(producto.plu, false);

            this.setState({
                ProductosDuplicadosFiltro : ''
            });
            this.DesactivarModales();
        }

    }

    async DesplegarDatosCliente(idCliente) {

        this.setState({ showSpinner: true })

        this.setState({ EsMetodoDesplegarDatosClienteOfertas:true })

        this.TraerOrdenesDeServicioCliente(idCliente);
        this.TraerReservasCliente(idCliente);
        this.BuscarNotaVentaCliente(idCliente);
        let empresa = GetUserCookie().EmpresaID;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetDataClientes?IdCliente=' + idCliente + '&EmpresaID=' + empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.setState({
                    DataCliente: {
                        id: result.id,
                        nombre: result.nombre,
                        rut: result.rut,
                        telefono: result.telefono,
                        email: result.email,
                        fechaUltServicio: result.fechaUltServicio,
                        montoUltServicio: result.montoUltServicio,
                        fechaUltCompra: result.fechaUltCompra,
                        montoUltCompra: result.montoUltCompra,
                        estrellas: parseInt(result.estrellas, 10),
                        ciudad: result.ciudad,
                        comuna: result.comuna,
                        direccion: result.direccion,
                        girocomercial: result.giroComercial,
                        cantidadNotasCredito: result.cantidadNotasCredito,
                        tipoCliente: result.tipoCliente,
                        razonSocial: result.razonSocial,
                        NombreClasif: result.nombreClasif,
                        PosicionClasif: result.posicionClasif,
                        comunaID : result.comunaID,
                        tipoClienteID: result.tipoClienteID,
                        ciudadID: result.ciudadID,
                        regionID : result.regionID,
                        nombres:result.nombres,
                        apellidos: result.apellidos
                    },
                    DataClienteConvenio: {
                        usaConvenio: 0, idConvenio: 0, limiteCreditoConvenio: 0, CantidadDisponible: 0, NombreConvenio: '(Sin Convenio)', EsBeneficioInterno: 0
                    },
                   
                    SeleccionoCliente: true
                });
            });
            //solo obtiene nc para maisa -- flujo de uso diferente
            if (this.state.carroProductos.length ===0){
                if(this.state.DataCliente.cantidadNotasCredito > 0 && this.state.Ambiente==='MAISA'){
                    this.TraerNotasCredito();
                }   
            }
        await this.BuscaProgramaPuntosCliente(this.state.DataCliente.rut);

        //if (this.state.identificarConvenioCliente == 'true') {
        this.BuscarConvenios(this.state.DataCliente.rut);
            //this.setState({ activarPopUpConsultaConvenio: true });
        //}

        /*esta llamada se está realizando en funcion de que el metodo buscarConvenios sea ejecutado despues de que se buscaron productos*/
        /*antes de identificar cliente, de esta forma quedó dentro del metodo BuscarConvenios para que el convenio estuviese escogido a la hora de hacer el proceso*/
        /*this.setMontOfertaFidelizacion();*/


        this.setState({ IsClienteObligatorio: false, showSpinner: false });
        this.DesactivarModales();
    }


    async DesplegarDatosConvenio(ConvenioID) {

            var valor = this.state.DataCliente.rut.replaceAll('-', '');
            //// Aislar Cuerpo y Dígito Verificador
            const cuerpo = valor.slice(0, -1);


            this.setState({ showSpinner: true })
            let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;

            let token = sessionStorage.getItem('jwtToken')
            

            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/Convenio/GetDataConvenio?rut=' + cuerpo + '&SucursalID=' + sucursalID + '&ConvenioID=' + ConvenioID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
            (result) => {
                
                    if (result.data.length > 0) {
                        this.setState({
                            DataClienteConvenio: {
                                usaConvenio: result.data[0].esValidoHoy,
                                idConvenio: result.data[0].convenioID,
                                limiteCreditoConvenio: result.data[0].limiteCredito,
                                CantidadDisponible: result.data[0].creditoDisponible,
                                NombreConvenio: result.data[0].descripcionConvenio,
                                EsBeneficioInterno: result.data[0].esBeneficioInterno
                            },
                        });
                        if (ConvenioID == 0) {
                            this.setState({
                                DataClienteConvenio: {
                                    usaConvenio: 0,
                                    idConvenio: 0,
                                    limiteCreditoConvenio: 0,
                                    CantidadDisponible: 0,
                                    NombreConvenio: '(Sin Convenio)',
                                    esBeneficioInterno:0
                                },
                            });
                        }
                    }
                    else
                    {
                        this.setState({
                            DataClienteConvenio: {
                                usaConvenio: 0,
                                idConvenio: 0,
                                limiteCreditoConvenio: 0,
                                CantidadDisponible: 0,
                                NombreConvenio: '(Sin Convenio)',
                                esBeneficioInterno:0
                            },
                        });
                    }
            });


        if (this.state.EsMetodoDesplegarDatosClienteOfertas == true) {
            this.setMontOfertaFidelizacion();
        }

        //solo obtiene nc para maisa -- flujo de uso diferente

        //this.setMontOfertaFidelizacion();
        this.setState({ IsClienteObligatorio: false, showSpinner: false });
        this.setState({ activarPopUpConsultaConvenio: false });
        this.setState({ EsMetodoDesplegarDatosClienteOfertas: false });

        this.DesactivarModales();
    }





    async TraerNotasCredito() {
        var Rut = this.state.DataCliente.rut;
        var datos = [];
        let EmpresaID = GetUserCookie().EmpresaID;   
        try {
            await fetch(GetUrlApi() + '/api/NotasDeCredito/NotasDeCreditoCliente?Rut=' + Rut + '&EmpresaID=' + EmpresaID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    datos = result;
                }
            );
            if (datos.length>0){
                this.NotasDeCredito = datos;
                swal({
                    title: 'Cliente posee notas de crédito pendientes,¿desea seleccionarla para continuar con la Venta?', 
                    icon: "warning", 
                    dangerMode: true,
                    buttons: {
                       
                        cancel: {
                            text: "No",
                            value: false,
                            visible: true,
                            className: "",
                            closeModal: true,
                        },
                        confirm: {
                            text: "Si",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        },
                    }
                }).then((willOk) => {
                    if (willOk) {
                        this.setState({ActivarPopUpNotasCredito:true});
                    }  
                });
            }
        
        } catch (err) {
            swal('No se pudo traer las Notas de Crédito verifique su conexion a internet');
        }

    }

    async MontajeyCargaDatosClienteGenericoParaVenta() {
        // aquí se obliga a traer un cliente que sería el cliente que se escoge cuando el cliente no quiere dar sus datos.

        let RutCliente = '66666666';
        let NombreCliente = '';
        this.setState({ showSpinner: true });
        this.setState({ disableOrders: false });
 
            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + RutCliente + '&NombreCliente=' + NombreCliente + '&EmpresaID=0', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.length === 0) {
                        this.setState({ ConsultaClientes: result });
                        this.setState({ showSpinner: false });
                        return;
                    }
                    this.setState({ ConsultaClientes: result });
                    this.setState({ showSpinner: false });

                    let idCliente = this.state.ConsultaClientes[0].id

                    //proceso que monta los datos del cliente generico 66.... en la parte superior de la pantalla de venta.

                    this.setState({ showSpinner: true })
                   
                    var respuesta2 = fetch(GetUrlApi() + '/api/Clientes/GetDataClientes?IdCliente=' + idCliente + '&EmpresaID=0', {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => res.json()).then(
                        (result) => {
                            this.setState({
                                DataCliente: {
                                    id: result.id,
                                    nombre: result.nombre,
                                    rut: result.rut,
                                    telefono: result.telefono,
                                    email: result.email,
                                    fechaUltServicio: result.fechaUltServicio,
                                    montoUltServicio: result.montoUltServicio,
                                    fechaUltCompra: result.fechaUltCompra,
                                    montoUltCompra: result.montoUltCompra,
                                    estrellas: parseInt(result.estrellas, 10),
                                    ciudad: result.ciudad,
                                    comuna: result.comuna,
                                    direccion: result.direccion,
                                    girocomercial: result.giroComercial,
                                    cantidadNotasCredito: result.cantidadNotasCredito,
                                    tipoCliente: result.tipoCliente,
                                    razonSocial: result.razonSocial,
                                    NombreClasif: result.nombreClasif,
                                    PosicionClasif: result.posicionClasif,
                                    comunaID: result.comunaID,
                                    tipoClienteID: result.tipoClienteID,
                                    ciudadID: result.ciudadID,
                                    regionID: result.regionID,
                                    nombres: result.nombres,
                                    apellidos: result.apellidos
                                },
                                SeleccionoCliente: true
                            });
                        });

                   // this.BuscaProgramaPuntosCliente(this.state.DataCliente.rut);
                    this.setMontOfertaFidelizacion();
                    this.setState({ IsClienteObligatorio: false, showSpinner: false });
                    this.DesactivarModales();

                }
            );
    }

    async MontajeDatosenModalClienteGenericoParaVenta() {
        // aquí se obliga a traer un cliente que sería el cliente que se escoge cuando el cliente no quiere dar sus datos.

        let RutCliente = '66666666';
        let NombreCliente = '';
         
            this.setState({ showSpinner: true });
            this.setState({ disableOrders: false });

            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + RutCliente + '&NombreCliente=' + NombreCliente + '&EmpresaID=0', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    this.setState({ ConsultaClientes: result });
                    this.setState({ showSpinner: false });
                }
            );
    }




    GetHtmlPopupProductoDuplicado() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}
                <CardHeader className="bg-danger text-white"><h3>Se ha encontrado más de un producto para el código
                    ingresado, porfavor selecciona el producto correcto</h3></CardHeader>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <div className="row text-center">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                            <div className="d-inline-flex col-lg-4 col-md-4 col-sm-4 col-4" style={this.Estilos.MarginCCliente}>
                                <input className="form-control" type="text" onKeyUp={async (e) => {
                                    if (this.state.ProductosDuplicados.filter((element) => element.plu.includes(this.state.ProductosDuplicadosFiltro)).length == 1 &&
                                        e.key === "Enter") {

                                        await this.ProductoDuplicadoSeleccion(this.state.ProductosDuplicados.filter((element) => element.plu.includes(this.state.ProductosDuplicadosFiltro))[0]);
                                    }
                                }} onChange={() => {
                                    this.setState({
                                        ProductosDuplicadosFiltro: document.getElementById('ProductoDuplicado').value})
                                }} id="ProductoDuplicado" placeholder="Código producto" />
                            </div>
                            
                        </div>
                    </div>
                    <table className="table table-sm table-striped table-hover table-dark">
                        <thead>
                            <tr className="d-flex">
                                <th className="col-lg-1 col-md-1 col-sm-1 col-1">Código</th>
                                <th className="col-lg-1 col-md-1 col-sm-1 col-1">Código Proveedor</th>
                                <th className="col-lg-5 col-md-5 col-sm-5 col-5">Producto</th>
                                <th className="col-lg-2 col-md-2 col-sm-2 col-2">Stock</th>
                                <th className="col-lg-2 col-md-2 col-sm-2 col-2">Precio</th>
                                <th className="col-lg-1 col-md-1 col-sm-1 col-1">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.ProductosDuplicados.filter((element) => element.plu.includes(this.state.ProductosDuplicadosFiltro)).map((item, index) => {
                                    return (<tr className="d-flex" key={index} onClick={() => { this.ProductoDuplicadoSeleccion(item) }}>
                                        <td className="col-lg-1 col-md-1 col-sm-1 col-1">{item.plu}</td>
                                        <td className="col-lg-1 col-md-1 col-sm-1 col-1">{item.codigoProveedor}</td>
                                        <td className="col-lg-5 col-md-5 col-sm-5 col-5 text-truncate">{item.producto}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-2 col-2 text-truncate">{item.stockDisponible}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-2 col-2 text-truncate">{GetPrecioFormateado(item.precpred)}</td>
                                        <td className="col-lg-1 col-md-1 col-sm-1 col-1"><button type="button" className="btn btn-success">Seleccionar</button></td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );
    }

    GetHtmlPopupLoginVendedor() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleLoginVendedor}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}

                <div className={this.state.estacionStyle}><strong>Ingresar Código de Vendedor</strong></div>

                <div className="mt-2 mb-2 text-center" >
                    <div className="text-center d-flex d-inline-flex ">
                        <input className="text-center" style={this.Estilos.TextInputVendedor} id='EntradaText' placeholder='Código Vendedor' type="tel" value={this.state.TextoIngresado}
                            inputMode="" name='EntradaText'
                            onChange={() => { this.focusChangeState(2) }}
                            /*onFocus={() => { this.ShowVirtualKeyBoard() }}*/ />
                    </div>
                    <div className="text-center" id="padNumerico" tabIndex="-1" style={this.Estilos.DivContent}>
                        <table className="m-auto text-white">
                            <tbody>
                                <tr>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(1) }} type="button" name="1" id="1" value="1" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(2) }} type="button" name="2" id="2" value="2" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(3) }} type="button" name="3" id="3" value="3" /></td>
                                </tr>
                                <tr>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(4) }} type="button" name="4" id="4" value="4" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(5) }} type="button" name="5" id="5" value="5" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(6) }} type="button" name="6" id="6" value="6" /></td>
                                </tr>
                                <tr>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(7) }} type="button" name="7" id="7" value="7" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(8) }} type="button" name="8" id="8" value="8" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(9) }} type="button" name="9" id="9" value="9" /></td>
                                </tr>
                                <tr>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn('-') }} type="button" name="-" id="-" value="-" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn(0) }} type="button" name="0" id="0" value="0" /></td>
                                    <td style={this.Estilos.TdBackground} ><input style={this.Estilos.Buttons} onClick={() => { this.ClickBtn('*') }} type="button" name="*" id="*" value="*" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="text-center" style={this.Estilos.DivContent}>
                        <table className="m-auto">
                            <tbody>
                                <tr>
                                    <td style={this.Estilos.TdBackground} ><button style={this.Estilos.ButtonsDes} onClick={() => { this.ClickClear() }} type="button" name="Clear" id="Clear"><i className="fa fa-undo " aria-hidden="true"></i></button></td>
                                    <td style={this.Estilos.TdBackground} ><button style={this.Estilos.ButtonsEnt} onClick={() => { this.BorrarChar() }} type="button" name="Borrar" id="Borrar"><i className="fa fa-long-arrow-left " aria-hidden="true"></i></button></td>
                                    <td style={this.Estilos.TdBackground} ><button style={this.Estilos.ButtonsChar} onClick={() => { this.LoginVendedor(document.getElementById('EntradaText').value) }} type="button" name="Enviar" id="Enviar"><i className="fa fa-paper-plane " aria-hidden="true"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Popup>            
        );
    }

    GetHtmlPopupConsultaCliente() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}

                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <div className="row text-center">
                        <div className="col-sm-12" style={this.Estilos.TitleTextColorStyle}>
                            <h3>
                                Venta
                            </h3>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <h4>
                                Seleccione Cliente.
                            </h4>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                            <p class="text-danger" id="msgerror"></p>
                            <div className="d-inline-flex col-lg-4 col-md-4 col-sm-4 col-4">
                                <input className="form-control" type="text"
                                    onKeyUp={(e) =>
                                        {
                                            if (e.key === "Enter") {
                                                this.BuscarCliente();
                                            } else {
                                                this.KeyUpRutValidator();
                                            }
                                        }
                                    }
                                    onKeyPress={(e) =>
                                        {
                                            this.onlyNumbersWithK(e);
                                        }
                                    }
                                    onChange={() => { this.LimpiarMensajeValidarRut() }}


                                    id="RutCliente" placeholder="RUT ej: 11111111-1" maxLength="13" minLength="9" />
                            </div>
                            <div className="d-inline-flex col-lg-4 col-md-4 col-sm-4 col-4">
                                <input className="form-control" type="text" onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        this.BuscarCliente();
                                    }
                                }} id="NombreCliente" placeholder="NOMBRE" />
                            </div>
                            <div className="d-inline-flex col-lg-2 col-md-2 col-sm-2 col-2 text-center">
                                <button className="btn btn-primary mr-4" onClick={() => { this.BuscarCliente() }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </button>
                                <button className="btn btn-info" style={this.Estilos.BtnConsultaClienteD} onClick={() => { this.state.IsFacturaSelected ? this.ClickAddClienteEmpresa() : this.ClickAddCliente()}}>
                                    <i className="fa fa-plus fa-1x">
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <table className="table table-sm table-striped table-hover table-dark">
                        <thead>
                            <tr className="d-flex">
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Cliente</th>
                                <th className="col-lg-2 col-md-2 col-sm-2 col-2">Rut</th>
                                <th className="col-lg-3 col-md-3 col-sm-2 col-2">Nombre</th>
                                <th className="col-lg-3 col-md-3 col-sm-3 col-3">Email</th>
                                <th className="col-lg-2 col-md-2 col-sm-2 col-2">Telefono</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.ConsultaClientes.map((item, index) => {
                                    return (<tr className="d-flex" key={index} onClick={() => { this.DesplegarDatosCliente(item.id) }}>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.tipoCliente}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-2 col-2 text-truncate">{item.rut}</td>
                                        <td className="col-lg-3 col-md-3 col-sm-2 col-2 text-truncate">{item.nombre}</td>
                                        <td className="col-lg-3 col-md-3 col-sm-3 col-3 text-truncate">{item.email}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-2 col-2 text-truncate">{item.telefono}</td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );
    }


    GetHtmlPopUpSeleccionConvenio() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales(); }}
                closeOnDocumentClick>
                {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}

                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <div className="row text-center">
                        <div className="col-sm-12" style={this.Estilos.TitleTextColorStyle}>
                            <h3>
                                Convenios Asociados a Cliente
                            </h3>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <h4>
                                Seleccione Convenio.
                            </h4>
                        </div>
                    </div>

                    <table className="table table-sm table-striped table-hover table-dark">
                        <thead>
                            <tr className="d-flex">
                                <th className="col-lg-6 col-md-6 col-sm-6 col-1">ID Convenio</th>
                                <th className="col-lg-6 col-md-6 col-sm-6 col-1">Descripción Convenio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="d-flex" onClick={() => { this.DesplegarDatosConvenio(0) }}>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-1">0</td>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-2">No Usar Convenio</td>
                            </tr>

                            {

                                this.state.ConsultaConvenios.map((item, index) => {
                                    return (<tr className="d-flex" key={index} onClick={() => { this.DesplegarDatosConvenio(item.convenioID) }}>
                                        <td className="col-lg-6 col-md-6 col-sm-6 col-1">{item.convenioID}</td>
                                        <td className="col-lg-6 col-md-6 col-sm-6 col-2">{item.descripcionConvenio}</td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );

    }


    KeyUpRutValidator()
    {
        let cadena = $("#RutCliente").val();
        cadena = cadena.replace(/[.]/gi, "").replace("-", "");
        if (cadena.length > 9) {
            cadena = cadena.substr(0, 9);
        }
        let concatenar = "";
        let i = cadena.length - 1;
        for (; i >= 0;) {
            concatenar = cadena[i] + concatenar;
            if (i + 1 == (cadena.length) && i > 0) {
                concatenar = "-" + concatenar;
            }
            if (concatenar.length == 9 && cadena.length > 7) {
                concatenar = "." + concatenar;
            }
            if (concatenar.length == 5 && cadena.length > 4) {
                concatenar = "." + concatenar;
            }
            i--;
        }
        $("#RutCliente").val(concatenar);
    }

    onlyNumbersWithK(e)
    {
        if (e.which == 75 || e.which == 107 || e.which == 48 || e.which == 49 || e.which == 50 || e.which == 51 || e.which == 52 || e.which == 53
            || e.which == 54 || e.which == 55 || e.which == 56 || e.which == 57) {
            return;
        } else {
            e.preventDefault();
        }
    }

    GetHtmlPopupDireccionRazonSocial() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick={ false }>
                <CardHeader className="bg-info">Datos Faltantes</CardHeader>
                <form>
                    <CardBody id="altaAdicCliente" className="form form-group">
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2 col-12">
                                <input className="form-control" type="text" placeholder="RAZÓN SOCIAL" id="razonSocialAddCliente" />
                            </div>
                            
                        </div>
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 col-12">
                                <input className="form-control" type="text" placeholder="DIRECCIÓN" id="direccionAddCliente" />
                            </div>
                        </div>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveClienteAdic" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarClienteAdic() }} ><strong>Agregar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </form>
            </Popup>
        );
    }

    DesplegarReserva(idReserva) {
        var indiceReserva = this.state.Reservas.findIndex(p => p.id === idReserva);
        var datosReserva = this.state.Reservas[indiceReserva];
        //Buscar Medios de Pago
        var cantidadInt = parseInt(datosReserva.cantidadProducto);
        if (cantidadInt > 0) {
            //Cargar Productos
            this.IrAPagarReserva(idReserva);
        }
        this.setState({ DetalleReservas: datosReserva, EsReserva: true, ReservaID: idReserva, montoAbonado: datosReserva.totalAbonos });
        this.setState({ OrdenDisplay: 'ID Reserva ' + idReserva, estacionStyle: 'text-center bg-success text-white' });

        this.DesplegarDatosCliente(datosReserva.clienteID);
        this.DesplegarDetallePagosReserva(datosReserva.id)
        this.DesactivarModales();
    }

    async IrAPagarReserva(idReserva) {
        var arr = [];
        this.setState({ disableOrders: true, disableOrdersEcommerce: true, disableOrdersReserva: true });
        var result = await fetch(GetUrlApi() + '/api/Orden/TraerProductosReserva?ReservaID=' + idReserva, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then((result) => { arr = result; });
        await this.ClickEnviarDos(arr);
    }

    async DesplegarDetallePagosReserva(ReservaID) {
        //DetalleMediosReserva

        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetDetalleMedioPagoReservas?ReservaID=' + ReservaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.setState({
                    DetalleMediosReserva: result
                });

            });
    }

    async DesplegarDetallePagosEcommerse(NotaVentaID) {
        //DetalleMediosEcommerce

        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetDetalleMedioPagoEcommerce?NotaVentaID=' + NotaVentaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.setState({
                    DetalleMediosEcommerce: result
                });

            });
    }

    async ConsultaGiftcard(){
        let serie = document.getElementById('SerieGiftcard').value;

        if (serie =='' || serie == undefined){
            swal('Debe ingresar un número de serie válido de Giftcard.');
            return;
        }
        try {
            let token =sessionStorage.getItem('jwtToken');
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/GiftCard/GetGiftCardInfoBySerie?GiftCardSerie=' + serie, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    
                    if (result.data.length > 0 ){
                        let nowDate = new Date();
                        let activa = result.data[0].activated;
                        let monto = result.data[0].remainingAmount;
                        const str = result.data[0].expirationDate;
                        const [day, month, year] = str.split('-');
                        const expired = new Date(+year, month - 1, +day);
                       
                        this.datosGiftcard = result.data;

                        document.getElementById("initialValue").value = result.data[0].initialValue;
                        document.getElementById("remainingAmount").value =monto;
                        document.getElementById("creationUser").value = result.data[0].creationUser;
                        document.getElementById("creationDate").value = result.data[0].creationDate;
                        document.getElementById("lastUpdate").value = result.data[0].lastUpdate;
                        document.getElementById("labelDescription").value = result.data[0].labelDescription;
                        document.getElementById("typeDescription").value = result.data[0].typeDescription;
                        document.getElementById("expirationDate").value = result.data[0].expirationDate;

                        if (activa == 1){
                            document.getElementById("activated").checked=true;
                        }
                        else{
                            document.getElementById("activated").checked=false;
                        }

                        //this.setState ({actTecladoGiftcard:true,consultarGiftcard:false});
                        return;
                    }
                    else{
                        swal('No se encontro información de Giftcard.');
                        return;
                    }
                });
        }
        catch (e){
            console.log (e);
        }
    }

    async MovimientosGiftcard(){
      
        if(this.datosGiftcard.length === 0){
            swal('Debe consultar la Giftcard antes de ver los movimientos.');
            return;
        }
        try {  
            let id = this.datosGiftcard[0].id;
            let token =sessionStorage.getItem('jwtToken');
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/GiftCard/GetGiftCardsTransactionsList?GiftCardID=' + id, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
              
                    if (result.data.length > 0 ){
                       
                        this.setState ({DatosMovimientosGiftcard:result.data,consultarMovsGiftcard:true});
                        return;
                    }
                    else{
                        swal('No se encontro información de Giftcard.');
                        return;
                    }
                });
         }
        catch(e){
            console.log('error al consultar movs giftcard ' + e)
        }
       
                
        return;
    }
    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }


      checkMail (email){
        this.upperCaseInput(email);
        let valueMail = document.getElementById(email).value;
        if (!this.isValidEmail(valueMail)) {
            swal({
                title: "ERROR !",
                text: "Email Inválido!",
                icon: "error",
                button: "Entendido !",
            });
            document.getElementById(email).value = '';
          }
      }
    GetHtmlPopupConsultaEcommerce() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <table className="table table-sm table-striped table-hover table-dark table-responsive" style={this.Estilos.DivProd} >
                        <thead>
                            <tr>
                                <th hidden="hidden">Nota Venta ID</th>
                                <th >Nota Venta</th>
                                <th >Rut</th>
                                <th >Nombre</th>
                                <th >Direccion</th>
                                <th >Email</th>
                                <th >Telefono</th>
                                <th >Fecha</th>
                                <th >Medio Pago</th>
                                <th >Tipo Despacho</th>
                                <th >Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.NotasVenta.map((item, index) => {
                                    return (<tr key={index} onClick={() => { this.DesplegarNotaVenta(item.numNotaVenta) }}>
                                        <td hidden="hidden">{item.id}</td>
                                        <td>{item.numNotaVenta}</td>
                                        <td>{item.rut}</td>
                                        <td>{item.nombre}</td>
                                        <td>{item.direccion}</td>
                                        <td>{item.email}</td>
                                        <td>{item.telefono}</td>
                                        <td>{item.fechaRecepcion}</td>
                                        <td>{item.metodoPago}</td>
                                        <td>{item.tipoEnvio}</td>
                                        <td>{GetPrecioFormateado(item.subTotal)}</td>
                                    </tr>);

                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );
    }

    GetHtmlPopupConsultaReserva() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <table className="table table-sm table-striped table-hover table-dark table-responsive" style={this.Estilos.DivProd} >
                        <thead>
                            <tr>
                                <th >Id Reserva</th>
                                <th >Nombre Cliente</th>
                                <th >Rut</th>
                                <th >Telefono</th>
                                <th >Email</th>
                                <th >Descripcion</th>
                                <th >Valor Reserva</th>
                                <th >Total Abonos</th>
                                <th >Fecha Reserva</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.Reservas.map((item, index) => {
                                    return (<tr key={index} onClick={() => { this.DesplegarReserva(item.id) }}>
                                        <td >{item.id}</td>
                                        <td >{item.nombre}</td>
                                        <td >{item.rut}</td>
                                        <td >{item.telefono}</td>
                                        <td >{item.email}</td>
                                        <td >{item.descripcion}</td>
                                        <td >{GetPrecioFormateado(item.valorReserva)}</td>
                                        <td >{GetPrecioFormateado(item.totalAbonos)}</td>
                                        <td >{item.fechaReserva}</td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );
    }

    inputValid(val, id) {
        if (val !== "") {
            $('#' + id).removeClass('invalid');
        } else {
            $('#' + id).addClass('invalid');
        }
    }

    GetHtmlPopupModPrograma() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info">Modificar Programa Puntos</CardHeader>
                <Form>
                    <CardBody id="modCliente" className="form form-group">
                    
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>     
                        <div className="form-group">     
                            <label>Programa Puntos: </label>
                            <select name="ddlModPrograma" id="ddlModPrograma"className="form-control">
                            {this.ProgramasPuntos.map((prog) => {                   //recorro el state y muestro datos
                               return (
                                <option key={prog.id} value={prog.id}>
                                  {prog.descripcion}
                                </option>
                              );
                            })}
                            </select>     
                            </div>       
                        </div>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="ModsCliente" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.ModificarProgramaCliente() }} ><strong>Guardar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }

    GetHtmlPopupDescOffers() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOffers}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info">Descuentos - Ofertas</CardHeader>
                <Form>
                                           
                        <br></br>
                      
                            <div className="row">
                                    
                                    {/* <div className="col" >                                     
                                        <button name="btnOfertas" title='Seleccione una forma de pago en esta opción, para consultar si existen ofertas asociadas' className="btn btn-dark" onClick={() => { this.ClickOfertaConsultar() }} style={this.Estilos.btnOptInferior} >Consultar Ofertas <i class="fa fa-money" aria-hidden="true"></i></button>
                                    </div> */}
                                    <div className="col">
                                    {(this.state.IsDescuentoRealizado) ?
                                        (<button name="btnQuitarDescuento" className="btn btn-dark" onClick={() => { this.QuitarDescuento() }} style={this.Estilos.btnOptInferior}  >Quitar Descuentos</button>)
                                        :(<button name="blokCambios" title='Ingrese un % de descuento o un monto a descontar' className="btn btn-dark" onClick={() => { this.ClickDescuentos() }} style={this.Estilos.btnOptInferior} >Descuento Caja <i class="fa fa-percent" aria-hidden="true"></i></button>)}
                                    </div>
                                </div>
                            
                       
                 
                </Form>
            </Popup>
        );
    }

    GetHtmlPopupGiftcard() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info">Consultar Giftcard</CardHeader>
                <Form>
                    <CardBody id="modCliente" className="form form-group">
                    
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>     
                            <div className="form-group">     
                                <label>Serie N° Giftcard: </label>
                                <input type="text" id="SerieGiftcard" placeholder="Serie Giftcard" />
                            </div>       
                        </div>
                        <br></br>
                        <Card className="form form-group">
                            <div className="row  pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                                <div className="col">
                                    <label>Valor Inicial: </label>
                                    <input type="text" id="initialValue" readOnly />
                                </div>
                                <div className="col">
                                    <label>Saldo: </label>
                                    <input type="text" id="remainingAmount" readOnly />
                                </div>
                                <div className="col">
                                    <label>Activada: </label> 
                                    <br></br>
                                    <input className='pt-2 justify-content-center' type="checkbox" id="activated" disabled  />
                                </div>
                                
                            </div>
                            <div className="row  pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                                 <div className="col">
                                    <label>Usuario Creación: </label>
                                    <input type="text" id="creationUser" readOnly />
                                </div>
                                <div className="col">
                                    <label>Fecha Creación: </label>
                                    <input type="text" id="creationDate" readOnly />
                                </div>
                                <div className="col">
                                    <label>Fecha Modificación: </label>
                                    <input type="text" id="lastUpdate" readOnly />
                                </div> 
                               
                            </div>
                            <div className="row  pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="col">
                                    <label>Descripción: </label>
                                    <input type="text" id="labelDescription" readOnly />
                                </div>
                                <div className="col">
                                    <label>Tipo: </label>
                                    <input type="text" id="typeDescription" readOnly />
                                </div>
                                <div className="col">
                                    <label>Fecha Expiración: </label>
                                    <input type="text" id="expirationDate" readOnly />
                                </div> 
                            </div>
                        </Card>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="ConsultaGiftcard" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.ConsultaGiftcard() }} ><strong>Consultar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="VerMovtosGiftcard" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-info form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.MovimientosGiftcard() }} ><strong>Ver Movimientos</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }

    GetHtmlPopupMovimientosGiftcard() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <table className="table table-sm table-striped table-hover table-dark table-responsive" style={this.Estilos.DivProd} >
                        <thead>
                            <tr>
                                <th >Id</th>
                                <th >Fecha Transacción</th>
                                <th >Monto Transacción</th>
                                <th >Referencia</th>
                                <th >Tipo Referencia</th>
                                <th >Tipo Transacción</th>
                                <th >Usuario</th>
                                <th >Descripción</th>
                                <th >Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.DatosMovimientosGiftcard.map((item, index) => {
                                    return (<tr key={index}>
                                        <td >{item.id}</td>
                                        <td >{item.transDate}</td>
                                        <td >{GetPrecioFormateado(item.transAmount)}</td>
                                        <td >{item.transReferenceNumber}</td>
                                        <td >{item.transReferenceType}</td>
                                        <td >{item.transactionTypeDescription}</td>
                                        <td >{item.transUser}</td>
                                        <td >{item.labelDescription}</td>
                                        <td >{GetPrecioFormateado(item.remainingAmount)}</td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );
    }

    GetHtmlPopupAddCliente() {
        
   
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                    {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}
                <CardHeader className="bg-info">Alta de cliente</CardHeader>
                <Form>
                    <CardBody id="altaCliente" className="form form-group">
                        <div className="row mb-3"  >
                    
                            {(this.state.configAddCliente.inputNombre) ? 
                                (     <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Nombre: </label>
                                <input className="form-control" type="text"   id="nombreAddCliente"
                                    onBlur = {(val)=>
                                    {this.upperCaseInput('nombreAddCliente');}} />
                                 </div>):
                                 
                                 (null)
                            }
                           {(this.state.configAddCliente.inputApellido)?
                                (<div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Apellidos: </label>
                               <input className="form-control" type="text"  id="apellidoAddCliente" 
                                   onBlur = {(val)=>
                                       {this.upperCaseInput('apellidoAddCliente');}} />
                                 </div>): (null)
                           }
                            {(this.state.configAddCliente.inputRut)?
                            ( <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                            <label>RUT: </label>
                           <input className="form-control" type="text" placeholder="RUT (12345678-9)"
                               onChange={(val) => {
                                   this.formateaRut('rutAddCliente');
                               }}
                               onBlur = {(val)=>{
                                   this.checkRutAddClient('rutAddCliente');
                               }}
                               id="rutAddCliente" maxLength="10" minLength="9" />
                             </div>) : (null)
                            }
                           
                        </div>
                        <div className="row" >
                          {(this.state.configAddCliente.inputTelefono)?
                            (<div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                            <label>Teléfono: </label>
                                <PhoneInput
                                    className="form-control"
                                    value={this.state.phoneAdded}
                                    onChange={phoneAdded => this.setState({ phoneAdded })}
                                    //onlyCountries={['cl', 'ar']}
                                    id="telefonoAddCliente"
                                    country={'cl'}
                                />
                            </div>)  : (null)
                             }
                            {(this.state.configAddCliente.inputEmail)?
                                ( <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Email: </label>
                                <input className="form-control" type="text" placeholder="EMAIL" id="emailAddCliente" 
                                    onBlur = {(val)=>
                                        {this.checkMail('emailAddCliente');}} />
                                
                                 </div>) :(null)
                            }
                           { (this.state.configAddCliente.inputRegion && this.state.configAddCliente.inputComuna)?
                                ( <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>     
                                <label>Region: </label>
                                <select name="ddlRegion" id="ddlRegion" className="form-control" onChange={() => { this.TraerComunas() }}>
                                {this.state.Regiones.map((reg) => {                   //recorro el state y muestro datos
                                    return (
                                        <option key={reg.id} value={reg.id}>
                                        {reg.nombre}
                                        </option>
                                    );
                                })}
                                </select>     
                                </div>  ) : (null)
                            }
                           
                        </div>

                       

                        <div className="row mb-3" > 
                            { (this.state.configAddCliente.inputRegion && this.state.configAddCliente.inputComuna)?  
                                (   <div className="form-group col" style={this.Estilos.SeparacionAddCliente} >     
                                                <label>Comuna: </label>
                                                <select name="ddlComuna" id="ddlComuna"className="form-control">
                                                {this.state.Comunas.map((com) => {                   //recorro el state y muestro datos
                                                    return (
                                                        <option key={com.id} value={com.id}>
                                                        {com.nombre}
                                                        </option>
                                                    );
                                                })}
                                                </select>     
                                    </div>  ):(null)
                            }
                                {
                                    (this.state.configAddCliente.inputDireccion)?
                                    (<div className="form-group col-5" style={this.Estilos.SeparacionAddCliente}>
                                    <label>Dirección: </label>
                                    <input className="form-control" type="text" placeholder=" " id="inpDireccion" 
                                        onBlur = {(val)=>
                                        {this.upperCaseInput('inpDireccion');}} />
                                     </div>):(null)
                                }
                                
                                {
                                    (this.state.configAddCliente.inputProgramaPuntos)?
                                    (<div className="form-group col" style={this.Estilos.SeparacionAddCliente}>        
                                    <label>Programa Puntos: </label>
                                    <select name="ddlProgramaAddCliente" id="ddlProgramaAddCliente"className="form-control" disabled={this.state.programaPuntosGenerico}>
                                    {this.ProgramasPuntos.map((prog) => {                   //recorro el state y muestro datos
                                    return (
                                        <option key={prog.id} value={prog.id}>
                                        {prog.descripcion}
                                        </option>
                                    );
                                    })}
                                    </select>     
                                    </div>):(null)
                                }
                                    
                                </div>
                        <div className="row mb-3"> 
                        
                        </div>       
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveCliente" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarCliente() }} ><strong>Agregar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }

    GetHtmlPopupAddClienteEmpresa() {
        
   
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                    {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}
                <CardHeader className="bg-info">Alta de empresa</CardHeader>
                <Form>
                    <CardBody id="altaCliente" className="form form-group">
                        <div className="row mb-3"  >
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Razon social: </label>
                                <input className="form-control" type="text"   id="nombreAddClienteEmpresa" 
                                    onBlur = {(val)=>
                                    {this.upperCaseInput('nombreAddClienteEmpresa');}} />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                 <label>RUT: </label>
                                <input className="form-control" type="text" placeholder="RUT (12345678-9)"
                                    onChange={(val) => {
                                        this.formateaRut('rutAddClienteEmpresa');
                                    }}onBlur = {(val)=>{
                                        this.checkRutAddClient('rutAddClienteEmpresa');
                                    }}
                                    id="rutAddClienteEmpresa" maxLength="9" minLength="9" />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Giro comercial: </label>
                                <input className="form-control" type="text"   id="giroComercialAddClienteEmpresa" 
                                onBlur = {(val)=>
                                    {this.upperCaseInput('giroComercialAddClienteEmpresa');}} />
                            </div>
                        </div>
                        <div className="row" >
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                            <label>Teléfono: </label>
                                <PhoneInput
                                    className="form-control"
                                    value={this.state.phoneAdded}
                                    onChange={phoneAdded => this.setState({ phoneAdded })}
                                    //onlyCountries={['cl', 'ar']}
                                    id="telefonoAddCliente"
                                    country={'cl'}
                                />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Email: </label>
                                <input className="form-control" type="text" placeholder="cliente@blinamic.cl" id="emailAddCliente" 
                                onBlur = {(val)=>
                                {this.upperCaseInput('emailAddCliente');}} />
                                
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>     
                                    <label>Region: </label>
                                    <select name="ddlRegion" id="ddlRegion" className="form-control" onChange={() => { this.TraerComunas() }}>
                                    {this.state.Regiones.map((reg) => {                   //recorro el state y muestro datos
                                        return (
                                            <option key={reg.id} value={reg.id}>
                                            {reg.nombre}
                                            </option>
                                        );
                                    })}
                                    </select>     
                                </div>  
                        </div>

                       

                        <div className="row mb-3" >   
                                <div className="form-group col" style={this.Estilos.SeparacionAddCliente} >     
                                            <label>Comuna: </label>
                                            <select name="ddlComuna" id="ddlComuna"className="form-control">
                                            {this.state.Comunas.map((com) => {                   //recorro el state y muestro datos
                                                return (
                                                    <option key={com.id} value={com.id}>
                                                    {com.nombre}
                                                    </option>
                                                );
                                            })}
                                            </select>     
                                        </div>    
                                        <div className="form-group col-5" style={this.Estilos.SeparacionAddCliente}>
                                        <label>Dirección: </label>
                                        <input className="form-control" type="text" placeholder=" " id="inpDireccion"
                                            onBlur = {(val)=>
                                            {this.upperCaseInput('inpDireccion');}} />
                                    </div>
                                </div>
                        <div className="row mb-3"> 
                        
                        </div>       
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveCliente" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarClienteEmpresa() }} ><strong>Agregar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }

    ClickEnterNumClientes() {
        const numClientes = Number(document.getElementById('PINbox').value);
        var newOrden = this.state.orden;
        newOrden.numeroDeClientes = numClientes;
        this.setState({ orden: newOrden, activarTecladoNumCliente: false });
        this.ActualizarTexto();
    }

    ClickTecladoCantidad2(num) {
        const numComoTexto = Number(num).toString();
        this.Numero = this.Numero.toString().replaceAll('.','') +  num;

        var element = document.getElementById('PINbox');
        if (element!=null) {
            var val = document.getElementById('PINbox').value;
            if (val.length >= 6 && val.length <= 9) {
                document.getElementById('PINbox').style.fontSize = "3em";
            } else if (val.length > 10 && val.length <= 20) {
                document.getElementById('PINbox').style.fontSize = "1.5em";

            }
            else if (val.length > 20 && val.length) {
                document.getElementById('PINbox').style.fontSize = "0.5em";

            } else if (val.length < 6) {
                document.getElementById('PINbox').style.fontSize = "4em";
            }
        }

        document.getElementById('PINbox').value = Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
        const valorPinBox = document.getElementById('PINbox').value;
    }

    ClickGuardarNombre() {
        var nomCli = document.getElementById('txtNomCliente').value;
        var newStateOrden = this.state.orden;
        newStateOrden.nombreCliente = nomCli;
        this.setState({ orden: newStateOrden, activarPopNomCliente: false });
        this.ActualizarTexto();
    }

    ClickGuardarNombreEmpresaDeliv() {
        var nomCli = document.getElementById('txtNomEmpresaDeliv').value;
        var newStateOrden = this.state.orden;
        newStateOrden.InfoDelivery = {};
        newStateOrden.InfoDelivery.tipoDelivery = nomCli;

        this.setState({ orden: newStateOrden, activarPopEmpresaDeliv: false });
        this.ActualizarTexto();
    }

    ClickConsultarStock() {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });
        setTimeout(() => {
            $('#EntradaText').attr('placeholder','Código/Descripción');
            $('#padNumerico').focus();
        }, 350);
        this.setState({ activarPopUpConsultaStock: true });
    }
    async ClickReimprimirHten(e) {
        document.removeEventListener("keyup", this.keyBoardControl, false);
        if ((await this.Auth()) === false) {
            document.addEventListener("keyup", this.keyBoardControl, false);
            setTimeout(() => {
                document.getElementById("EntradaText").click();
            }, 350);
            return;
        }
        document.addEventListener("keyup", this.keyBoardControl, false);


        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });
        setTimeout(() => {
            $('#padNumerico').focus();
        }, 350);
        this.setState({ activarPopUpReimprimirHten: true });
        document.getElementById("tipoHtenPago").checked=true;
        this.setState({ tipoHtenSelected: 'pago' })
    }

    async ClickReimprimirUltimaHten(e) {
        document.removeEventListener("keyup", this.keyBoardControl, false);
        if ((await this.Auth()) === false) {
            document.addEventListener("keyup", this.keyBoardControl, false);
            setTimeout(() => {
                document.getElementById("EntradaText").click();
            }, 350);
            return;
        }
        document.addEventListener("keyup", this.keyBoardControl, false);


        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });
        setTimeout(() => {
            $('#padNumerico').focus();
        }, 350);
        const endpoint = "obtenerUltimaTransaccion";
        this.callToHten(endpoint)
    }

    async ClickCierreTBK() {
        document.removeEventListener("keyup", this.keyBoardControl, false);
        if ((await this.Auth()) === false) {
            document.addEventListener("keyup", this.keyBoardControl, false);
            setTimeout(() => {
                document.getElementById("EntradaText").click();
            }, 350);
            return;
        }
        document.addEventListener("keyup", this.keyBoardControl, false);
        setTimeout(() => {
            document.getElementById("EntradaText").click();
        }, 350);
        this.callToHten("cierreBatch");
    }
    async ClickStatusQR() {
        document.removeEventListener("keyup", this.keyBoardControl, false);
        if ((await this.Auth()) === false) {
            document.addEventListener("keyup", this.keyBoardControl, false);
            setTimeout(() => {
                document.getElementById("EntradaText").click();
            }, 350);
            return;
        }
        document.addEventListener("keyup", this.keyBoardControl, false);


        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });
        setTimeout(() => {
            $('#padNumerico').focus();
        }, 350);
        this.setState({ activarPopUpStatusQR: true });
        document.getElementById("tipoQREntity").checked=true;
        this.setState({ QREntitySelected: 'BCI' })
    }
    

    async Auth() {

        const { value: passwd } = await Swal2.fire({
            title: 'Ingrese clave supervisor',
            input: 'password',
            inputLabel: 'Clave',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'La clave no puede estar vacía.'
                }
            }
        });
        if (await this.ValidarUsuarioSupervisor(passwd)) {
            return true;
        }else{
            await Swal2.fire({
                title: '<strong>ERROR</strong>',
                text: 'Perfil de usuario no valido.',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'VOLVER',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'error',
            }).then((result) => {
                return
            })

            return false
        }
    }

    processResponse(data) {
        let jsonObj = {}
        if (data.constructor === ({}).constructor) {
            jsonObj = data;
        } else {
            jsonObj = JSON.parse(data);
        }
        let dataFinal = jsonObj;
        const tempJson = jsonObj //dataFinal[Object.keys(dataFinal)[0]];
        if (tempJson.voucherDuplicado) {
            if (typeof tempJson.datosTransaccion.glosaRespuesta === 'undefined' || tempJson.datosTransaccion.glosaRespuesta === null) {
                if (tempJson.datosTransaccion.codigoRespuesta >= 0 && tempJson.datosTransaccion.codigoRespuesta <= 9) {
                    tempJson.datosTransaccion.glosaRespuesta = "APROBADO";
                } else {
                    tempJson.datosTransaccion.GlosaRespuesta = "RECHAZADO";
                }
            }
            dataFinal.glosaRespuesta = tempJson.datosTransaccion.glosaRespuesta;
            dataFinal.codigoRespuesta = tempJson.datosTransaccion.codigoRespuesta;
            dataFinal.monto = tempJson.datosTransaccion.monto;
            dataFinal.voucher = tempJson.voucherDuplicado
        }
        this.setState({ htenResponse: data });
        this.setState({ htenJsonResponse: dataFinal });
        const codRta = parseInt(dataFinal.codigoRespuesta);
        if (codRta >= 0 && codRta <= 9) {
            return true;
        } else {
            return false;
        }
    }
    processResponseReimprimir(data) {
        let jsonObj = {}
        if (data.constructor === ({}).constructor) {
            jsonObj = data;
        } else {
            jsonObj = JSON.parse(data);
        }
        
        let dataFinal = jsonObj;
        const tempJson = jsonObj['ns2: ultimaTransaccion'] //dataFinal[Object.keys(dataFinal)[0]];
        
        if (tempJson.voucherDuplicado) {
            if (typeof tempJson.DatosTransaccion.GlosaRespuesta === 'undefined' || tempJson.DatosTransaccion.GlosaRespuesta === null) {
                if (tempJson.DatosTransaccion.CodigoRespuesta >= 0 && tempJson.DatosTransaccion.CodigoRespuesta <= 9) {
                    tempJson.DatosTransaccion.GlosaRespuesta = "APROBADO";
                } else {
                    tempJson.DatosTransaccion.GlosaRespuesta = "RECHAZADO";
                }
            }
            dataFinal.GlosaRespuesta = tempJson.DatosTransaccion.GlosaRespuesta;
            dataFinal.CodigoRespuesta = tempJson.DatosTransaccion.CodigoRespuesta;
            dataFinal.Monto = tempJson.DatosTransaccion.Monto;
            dataFinal.voucher = tempJson.voucherDuplicado
        }
        this.setState({ htenResponse: data });
        this.setState({ htenJsonResponse: dataFinal });
        const codRta = parseInt(dataFinal.CodigoRespuesta);
        if (codRta >= 0 && codRta <= 9) {
            return true;
        } else {
            return false;
        }
    }
    async registerTbkClosing(data) {
        const apertura = await this.getApertura()
        const stringConstructor = "test".constructor;
        const objectConstructor = ({}).constructor;
        
        let newData = {}
        if(data.constructor === stringConstructor){
            newData = JSON.parse(data)
        } else if(data.constructor === objectConstructor) {
            newData = data
        }
        newData.aperturaCajaId = apertura
        newData.user_created = GetUserCookie().username
        const token = sessionStorage.getItem('jwtToken')
        const result = await fetch(GetUrlApi() + '/TbkClosing/create', {
            method: 'post',
            body: JSON.stringify(newData),            
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer " + token + ""
            }
        }).then(res => res.json()).then(
            (result) => { 
                return result
            }
        )

    }
    async getApertura() {
        const cajaID = GetUserCookie().CajaID
        var result = await fetch(GetUrlApi() + '/tbkClosing/getApertura?cajaId=' + cajaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return result.json()
    }
    async callToHten(endpoint) {
        const ipHten = localStorage.getItem('ipHten');
        const userData = JSON.parse(sessionStorage.getItem('userProfile'));
        var myBody = {};
        let title = "Ultima transacción";
        if (endpoint !== "obtenerUltimaTransaccion") {
            myBody.numeroEmpleado = userData.id + "";
            title = "CIERRE TBK";
        }       

        if (localStorage.getItem('htenDemo') != null && localStorage.getItem('htenDemo') == "SI") {
            myBody.ultimos4 = "demo";
        }

        console.log("Body ", myBody);
        const exitStatus = fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'post',
            dataType: 'jsonp',
            async: false,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then(respuesta => respuesta.json()).then((data) => {
            console.log(data);
            let icon = 'error';
            let codResp = ''
            let glosaResp = ''
            if(endpoint === 'obtenerUltimaTransaccion'){
                icon = this.processResponseReimprimir(data)?'success':'error'
                codResp = data['ns2: ultimaTransaccion'].DatosTransaccion.CodigoRespuesta
                glosaResp = data['ns2: ultimaTransaccion'].DatosTransaccion.GlosaRespuesta
            }
            else{
                icon = this.processResponse(data)?'success':'error'
                codResp = this.state.htenJsonResponse.codigoRespuesta
                glosaResp = this.state.htenJsonResponse.glosaRespuesta
            }
                

            if (endpoint === "cierreBatch") {
                this.registerTbkClosing(data)
            }
            console.log("resp llevar ", data);
            console.log("resp llevar 2 ", this.state.htenJsonResponse);
            Swal2.fire({
                title: '<strong>'+title+'</strong>',
                text: codResp + '-' + glosaResp,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: icon,
            }).then(async (result) => {
                if (icon === "error") {
                    return;
                }

                if (endpoint === "cierreBatch") {
                    return;
                }

                // guardar el voucher en medio de pago de la orden (patch), para imprimir.

                let orderId = localStorage.getItem("ultimaOrden")
                let order = { }
                if (orderId === "") {
                    Swal2.fire({
                        title: '<strong>ERROR</strong>',
                        text: 'No se puede leer el ultimo id de orden.',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'VOLVER',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'error',
                    }).then((result) => {
                            return;
                    });
                }
                let baseUrl = "MediosPorOrden"
                let url =baseUrl+"/GetMediosPorOrdenOrderId?orderId="
                if (localStorage.getItem("tipoUltimaOrden") === "nc") {
                    baseUrl = "MediosPorNc"
                    url = baseUrl + "/GetMediosPorNCOrderId?orderId="
                    order = await this.getNc(orderId)
                    order.numFolio = order.folioBsale
                } else{
                    order = await this.getOrder(orderId)
                }
                    
                fetch(GetUrlApi() + '/api/' + url + orderId, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(data => data.json()).then((resultado) => {
                    console.log(resultado);
                    for (let i = 0; i < resultado.length; i++) {
                        console.log(resultado[i]);
                        const medio = resultado[i];
                        // if (((medio.totalReal?medio.totalReal.toString():0) === this.state.htenJsonResponse.monto || medio.total.toString() === this.state.htenJsonResponse.monto) && medio.numTarjeta !== "") {
                        if(medio.numTarjeta !== ""){
                            let myData = {
                                numTarjeta: JSON.stringify(this.state.htenJsonResponse),
                            }
                            fetch(GetUrlApi() + '/api/' + baseUrl +'/'+medio.id, {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myData),
                            }).then(async data => {
                                // Enviar a imprimir
                                console.log("ENVIAR !!!! ");
                                const tipoOrden = localStorage.getItem('tipoUltimaOrden')?localStorage.getItem('tipoUltimaOrden'):'pago'
                                this.ReimprimirHten(order.numFolio, tipoOrden);
                            }).catch(error => {
                                swal({
                                    title: "Error al querer actualizar para imprimir",
                                    icon: "error",
                                    text: "Por favor contacte al administrador del sistema " + error,
                                    dangerMode: true,
                                });
                            });

                        }
                    }
                }).catch((err) => {
                    console.log(err);
                });

            });
        }).catch((reason) => {
            console.log(reason);
            Swal2.fire({
                title: '<strong>ERROR</strong>',
                text: 'No logramos conectar con HTEN ' + reason,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'error',
            });
        });
    }
    async getOrder(orderId){
        let order = {}
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + orderId, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (respuesta.ok) {
            const resOrden = await respuesta.json()
            order = resOrden
        }
        return order
    }
    async getNc(ncId){
        let nc = {}
        var respuesta = await fetch(GetUrlApi() + '/api/NotasDeCredito/' + ncId, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (respuesta.ok) {
            const resOrden = await respuesta.json()
            nc = resOrden
        }
        return nc
    }
    ClickConsultarOrdenServicio() {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        if (this.state.DataCliente.rut !== '') {
            this.TraerOrdenesDeServicioCliente(this.state.DataCliente.id);
            this.setState({ activarPopUpConsultaOrdenServicio: true });

        } else {
            this.TraerOrdenesDeServicio();
            this.setState({ activarPopUpConsultaOrdenServicio: true });
        }
    }

    ClickConsultaCliente() {
        this.TextoIngresado = '';
        this.BuscaProgramasPuntos();
        this.setState({ TextoIngresado: this.TextoIngresado });
        if (this.state.OrdenDisplay !== '') {
            this.LimpiarVentana();
        }
        this.setState({ activarPopUpConsultaCliente: true });
        setTimeout(function () {
            $('#RutCliente').focus();
        }, 50);
    }

    ClickLoginVendedor() {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });
        if (this.state.OrdenDisplay !== '') {
            this.LimpiarVentana();
        }
        this.setState({ activarPopUpLoginVendedor: true });
        setTimeout(function () {
            $('#EntradaText').focus();
        }, 50);
    }

    ClickConsultaEcommerce() {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        this.TraerNotasDeVenta();
        this.setState({ activarPopUpConsultaEcommerce: true });

    }

    ClickConsultaReservas() {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        this.setState({ activarPopUpConsultaReserva: true });
    }

    ClickAddCliente() {
        this.setState({ CrearCliente: true });
        setTimeout(function () {
            $('#nombreAddCliente').focus();
        }, 50);
    }

    ClickAddClienteEmpresa() {
        this.setState({ CrearClienteEmpresa: true });
        setTimeout(function () {
            $('#nombreAddClienteEmpresa').focus();
        }, 50);
    }

    ClickModPrograma() {
        if ( this.state.DataCliente.rut !==''){          
            this.setState({ ModificarPrograma: true });
            setTimeout(function () {
                $('#ddlModPrograma').focus();
            }, 50);
        }
        else{
            swal({
                title: "ERROR !",
                text: "Solo puede modificar Programa a Cliente identificado",
                icon: "error",
                button: "Entendido !",
            });
        }
    }
    async ClickModCliente() {
        if (this.state.DataCliente.rut!==''){
            
            await this.setState({CrearCliente:true});
            if(this.state.configAddCliente.inputNombre){
                document.getElementById('nombreAddCliente').value = this.state.DataCliente.nombres; //.split(' ')[0];
            }
            
          /*  try{
                var ArrApellidos = this.state.DataCliente.nombre.split(' ');
                var apellidos ='';
                for (var i = 1; i  < ArrApellidos.length;i++){
                    apellidos = apellidos + ArrApellidos[i]  + ' ' ;
                }
                document.getElementById('apellidoAddCliente').value = this.state.DataCliente.apellidos;//apellidos;

            }catch(e){}*/
            document.getElementById('rutAddCliente').value =this.state.DataCliente.rut;
            document.getElementById('rutAddCliente').readOnly=true;
            if (this.state.configAddCliente.inputApellido){
                document.getElementById('apellidoAddCliente').value = this.state.DataCliente.apellidos;
            }
            if (this.state.configAddCliente.inputEmail){
                document.getElementById('emailAddCliente').value = this.state.DataCliente.email;
            }
            if (this.state.configAddCliente.inputDireccion){
                document.getElementById('inpDireccion').value = this.state.DataCliente.direccion;
            }
            if (this.state.configAddCliente.inputTelefono){
                this.setState({phoneAdded:this.state.DataCliente.telefono});
            }
            if (this.state.configAddCliente.inputProgramaPuntos){
                document.getElementById('ddlProgramaAddCliente').value = this.state.DataClientePuntos.idProgramaPuntos;
            }
            if (this.state.configAddCliente.inputRegion && this.state.configAddCliente.inputComuna){
                document.getElementById('ddlRegion').value = this.state.DataCliente.regionID;
                await this.TraerComunas();
                document.getElementById('ddlComuna').value = this.state.DataCliente.comunaID;
            }
            //document.getElementById('telefonoAddCliente').value = this.state.DataCliente.telefono;
          
        }
    }

    ClickGuardarNombreEmpresaDeliv2(nombreEmpresa) {
        var newStateOrden = this.state.orden;
        newStateOrden.InfoDelivery = {};
        newStateOrden.InfoDelivery.tipoDelivery = nombreEmpresa;

        this.setState({ orden: newStateOrden, activarPopEmpresaDeliv: false });
        this.ActualizarTexto();
    }

    GetHtmlPopupNomCliente() {

        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupName}
                modal
                onClose={() => { this.setState({ activarPopNomCliente: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Ingrese el nombre del cliente</h3>
                    <input type="text" id="txtNomCliente" className="form-control" style={this.Estilos.NombreBox} />
                    <button className="btn btn-success" style={this.Estilos.BtnSuc} onClick={() => {
                        this.ClickGuardarNombre()
                    }}> <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i> </button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupEmpresaDeliv() {
        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupName}
                modal
                onClose={() => { this.setState({ activarPopEmpresaDeliv: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Ingrese el nombre de la empresa</h3>
                    {
                        this.state.empresasDelivery.map((item) => {
                            return (
                                <a key={item} onClick={() => { this.ClickGuardarNombreEmpresaDeliv2(item.descripcion) }} style={{ marginRight: '5%' }} href="javascript:void(0)" >
                                    <img width="40%" height="40%" src={require('./Imagenes/' + item.nombreImagen)} />
                                </a>
                            )
                        })
                    }
                </center>

            </Popup>
        );
    }

    GetHtmlPopupEmpresaDeliv2() {
        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupName}
                modal
                onClose={() => { this.setState({ activarPopEmpresaDeliv: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Ingrese el nombre de la empresa de delivery</h3>
                    <input type="text" id="txtNomEmpresaDeliv" className="form-control" style={this.Estilos.NombreBox} />
                    <button className="btn btn-success" style={this.Estilos.BtnSuc} onClick={() => {
                        this.ClickGuardarNombreEmpresaDeliv()
                    }}> <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i> </button>
                </center>

            </Popup>
        );
    }

    GetHtmlAutorizado() {

        return (
               <Popup
                contentStyle={this.Estilos.EstiloPopupAut}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales(); }}
                closeOnDocumentClick={false}
            >
                <center>
                    <div>
                        <p><strong>Ingrese Código de Administrador</strong></p>
                        <p style={this.Estilos.FuenteAdvertencia}><strong>El descuento será notificado al Supervisor correspondiente</strong></p>
                        <input id='PINboxDosAut' style={this.Estilos.InputSizeDosAut} type="password" name='PINboxDosAut' value={this.CodAut}  />


                        <table id="tablaBotones">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>

                                </tr>
                                <tr>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>

                                </tr>
                                <tr>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                    <td> <input onClick={() => { this.ClickTecladoCantidadAut(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                    <td><input onClick={() => { this.ClickTecladoCantidadAut(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>

                                </tr>
                                <tr>
                                    <td><input onClick={() => { this.ClickCED() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                    <td> <input onClick={() => { this.ClickTecladoCantidadAut(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                    <td><input onClick={() => { this.ValidarUsuario() }} type='button' className='PINbutton enter' name='+' value='enter' id='+' /></td>

                                </tr>
                                <tr>
                                    <td colspan='3' align='center'>
                                        <button onClick={() => { this.CerrarModal2() }} style={this.Estilos.BtnDanger} className="btn btn-secondary"> <small> <i className="fa fa-times-circle fa-2x" aria-hidden="true">&nbsp; Cancelar </i></small></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </center>
            </Popup>
        );

    }

    ClickTecladoCantidadAut(num) {
        
        var val = document.getElementById('PINboxDosAut').value;
        val += num;
        document.getElementById('PINboxDosAut').value = val;
        this.CodAut = val;
        this.setState({TextoIngresado:val});
       
        document.getElementById('PINboxDosAut').focus();
    }

    ClickCED() {
        this.CodAut = "";
        this.setState({TextoIngresado:this.CodAut});
        document.getElementById('PINboxDosAut').value = '';
    }
    CerrarModal2() {
        this.setState({ reqAuth: false });
    }

    async ValidarUsuario() {
        this.setState({ reqAuthVal: true })
        if (this.CodAut === '') {
            await swal({
                title: 'Perfil de usuario no valido',
                text: 'Usuario no válido',
                icon: 'error',
                dangerMode: true
            }).then((result) => {
                this.setState({ reqAuthVal: false })
                this.ClickCED()
            })
        } else {
            var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + this.CodAut + '?pin=true', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {
                var user = await respuesta.json();
                if (user.tipoUsuarioID === 1 || user.tipoUsuarioID === 2) {
                    document.getElementById('PINboxDosAut').value = '';
                    this.setState({ reqAuth: false })
                    this.setState({ TextoIngresado: ''});
                    this.CodAut='';
                    document.getElementById('EntradaText').value = '';
                        //this.autorizeDescItem()
                    var codigoproducto = "DIF.CAMBIO";
                    this.ClickEnviar(codigoproducto, true, true)

                }
                else {
                    this.setState({ TextoIngresado: ''});
                    document.getElementById('EntradaText').value = '';
                    this.CodAut='';
                    swal({
                        title: 'Perfil de usuario no valido',
                        text: 'Usuario no válido',
                        icon: 'error',
                        dangerMode: true
                    }).then((result) => {
                        this.setState({ reqAuthVal: false })
                        this.ClickCED()
                    })
                }

            }
            else {
                this.setState({ TextoIngresado: ''});
                document.getElementById('EntradaText').value = '';
                this.CodAut='';
                swal({
                    title: 'Perfil de usuario no valido',
                    text: 'Usuario no válido',
                    icon: 'error',
                    dangerMode: true
                }).then((result) => {
                    this.setState({ reqAuthVal: false })
                    this.ClickCED()
                })
            }
        }
    }
    async ValidarUsuarioSupervisor(pin) {
        let result = false
        if (pin === '') {
            result = false
        } else {
            var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + pin + '?pin=true', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            if (respuesta.ok) {
                var user = await respuesta.json();
                if (user.tipoUsuarioID === 1 || user.tipoUsuarioID === 2) {
                    result = true
                }
                else {
                    result = false
                }

            }
            else {
                result = false
            }
        }
        return result
    }

    ClickEnterNumClientes() {
        const numClientes = Number(document.getElementById('PINbox').value);
        var newOrden = this.state.orden;
        newOrden.numeroDeClientes = numClientes;
        this.setState({ orden: newOrden, activarTecladoNumCliente: false });
        this.ActualizarTexto();
    }

    GetNumeroClientes() {
        var res = (this.state.orden.numeroDeClientes) ? (this.state.orden.numeroDeClientes) : (1);
        return res;
    }

    async ClickImprimir2(id) {
        this.setState({ cargandoProductos: true });
        const userData = GetUserCookie();
        const empresa = userData.Sucursal.direccionDeli.split(".")[0];
        const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
        const printer = "printer_" + userData.caja.descripcion;
        var myBody = {
            "company_id": empresa,
            "store_id": sucursal,
            "order_id": "print://"+id,
            "printer_id": printer,
            "additional_data": "",
            "exec_path": "C:\\fbhposprint\\"
        };


        await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then(data => data.json()).then(data => {
            console.log(data);
            sessionStorage.setItem(id, data.hten_unique_id);
            swal({
                title: "Orden " +id+ " enviada a imprimir con código " + data.hten_unique_id,
                icon: "success",
                text: "Si es necesario consulte estado de impresión por nro de Orden.",
            }).then(() => {
                this.setState({ cargandoProductos: false });
            });
        }).catch(error => {
            swal({
                title: "Error al querer imprimir",
                icon: "error",
                text: "Por favor contacte al administrador del sistema " + error,
                dangerMode: true,
            }).then(() => {
                this.setState({ cargandoProductos: false });
            });
        });
    }

    async ImprimirZonaImpresion(id) {
        var myBody = {};
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = 'printZonaImpresion' + id;
        myBody.path = "C:\\fbhposprint\\";
        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        });
        if (respuesta.ok) {
            const res = await respuesta.json();
            this.setState({ cargandoProductos: false });
        } else {
            alert('Fallo en llamada al servicio, favor Contacte al Administrador');
        }

    }

    async ClickEnviarUno(arr) {
 
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        arr.forEach(async function (item) {
            var productoResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoByCodigo?Codigo=' + item.id + '&SucursalID=' + sucursalID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!productoResult.ok) {
                throw 'problema al traer Producto';
            }
            var datos = await productoResult.json();

            let indiceArr = arr.findIndex(i => i.id === item.id);
            if (indiceArr !== -1) {
                this.setState({ cantidad: arr[indiceArr].cantidad });
            }
            let itemadd = { codigo: '', productoscorrelativoslist: [] };
            let itemaddVer = { codigo: '', correlativo: '', idCarro: 0 };

            if (item.correlativo !== "") {
                this.ListaCorrelativosIngresados.push(item.correlativo);

                itemadd.codigo = item.id;
                itemadd.productoscorrelativoslist.push(item.correlativo);

                itemaddVer.codigo = item.id;
                itemaddVer.correlativo = item.correlativo;
                itemaddVer.idCarro = this.state.idCarro;
                this.ProductosVerCorrelativosLista.push(itemaddVer);
                this.ProductosTienenCorrelativo.push(itemaddVer);
            }

            if (datos.length > 0) {
                this.setState({ productosSeleccionados: datos });
                this.CorrelativoIngresado = item.correlativo;
                let id = datos[0].id;
                this.ClickClear();
                await this.ClickVerDetallesDos(id);
            } else {
                swal({
                    title: "El producto no posee stock o no existe.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();
                });
            }
        }, this);

    }

    async ClickEnviarDos(arr) {
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        arr.forEach(async function (item) {
            var productoResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoByCodigo?Codigo=' + item.id + '&SucursalID=' + sucursalID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!productoResult.ok) {
                throw 'problema al traer Producto';
            }
            var datos = await productoResult.json();

            let indiceArr = arr.findIndex(i => i.id === item.id);
            if (indiceArr !== -1) {
                this.setState({ cantidad: arr[indiceArr].cantidad });
            }

            if (item.cantidadCorrelativo > 0) {
                for (let i = 0; i < item.cantidad; i++) {
                    item.idCarro = this.state.idCarro;
                    this.ProductosAPedirCorrelativo.push(item);
                }

            }


            if (datos.length > 0) {
                this.setState({ productosSeleccionados: datos });
                this.CorrelativoIngresado = item.correlativo;
                let id = datos[0].id;
                this.ClickClear();
                await this.ClickVerDetallesDos(id);
            } else {
                swal({
                    title: "El producto no posee stock o no existe.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();

                });
            }
        }, this);


    }

    async ClickEnviarTres(barcode, correlativo) {
        let codProd = barcode + "";
        let includeText = codProd.includes('*');
        if (includeText) {
            let spli = codProd.split('*');
            codProd = spli[1];
            this.setState({ cantidad: spli[0] });
        }
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        //Validar Stock
        var StockProdResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockProductoByCodigo?Codigo=' + codProd + '&SucursalID=' + sucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!StockProdResult.ok) {
            throw 'problema al traer Stock';
        }

        //Validar Stock TagRfid
        var StockTagProdResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockTagProductoByCodigo?Codigo=' + codProd + '&SucursalID=' + sucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!StockTagProdResult.ok) {
            throw 'problema al traer Stock';
        }

        var productoResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoByCodigo?Codigo=' + codProd + '&SucursalID=' + sucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!productoResult.ok) {
            throw 'problema al traer Producto';
        }
        var datos = await productoResult.json();
        var datosD = await StockProdResult.json();
        var TagStock = await StockTagProdResult.json();

        if (this.state.orden.carroProductos.length > 0) {
            let cantidadProductos = 0;
            this.state.orden.carroProductos.map((item) => {
                if (item.desprod.includes(codProd)) {
                    cantidadProductos += item.cantidad;
                }

            })
            datosD = datosD - cantidadProductos;
            TagStock = TagStock - cantidadProductos;

        }
        if (this.state.cantidad <= datosD) {


            let SplitCode = datos[0].desprod.split(";");


            let itemadd = { codigo: '', productoscorrelativoslist: [] };
            let itemaddVer = { codigo: '', correlativo: '', idCarro: 0 };
            if (TagStock > 0) {
                if (correlativo !== "") {
                    this.ListaCorrelativosIngresados.push(correlativo);

                    itemaddVer.codigo = SplitCode[0];
                    itemaddVer.correlativo = correlativo;
                    itemaddVer.idCarro = this.state.idCarro;
                    this.ProductosVerCorrelativosLista.push(itemaddVer);
                }
            } else {
                swal({
                    title: "El producto no posee stock de Tags Rfid suficientes.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();

                });
                return;
            }

            if (datos.length > 0) {
                this.setState({ productosSeleccionados: datos });
                if (datos[0].clienteObligatorio && this.state.SeleccionoCliente === false) {
                    this.setState({ IsClienteObligatorio: true });
                }
                let id = datos[0].id;
                this.setState({ TineCorrelativos: true });
                this.ClickClear();
                await this.ClickVerDetallesDos(id);
            } else {
                swal({
                    title: "El producto no posee stock o no existe.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();
                });
            }
        } else {
            swal({
                title: "El producto no posee stock o no existe.",
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();

            });
        }

        if (this.state.IsClienteObligatorio && this.state.identificarClienteForzado == 'true') {
            this.setState({ activarPopUpConsultaCliente: true });
        }

    }

    async ClickEnviarConsultaStock(sucursalID, codProd, EmpresaID) {
        
        let sucid =GetUserCookie().Sucursal.sucursalIDBlinamic;
        const productoResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockImagenProductoByCodigo?Codigo=' + codProd +'&empresaID=' + EmpresaID + '&sucursalID=' + sucid, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!productoResult.ok) {
            throw new Error('problema al traer Producto');
        }

        const stockByStore = await productoResult.json();
        console.log("Producto stock ", stockByStore);
        if (stockByStore.length === 0) {
            swal({
                title: "El producto no fue encontrado en el sistema.",
                icon: "warning",
                dangerMode: true,
            });
            this.setState({
                showSpinnerGeneral: false,
            });
            $('#EntradaText').attr('placeholder','Código');
            return;
        }

        this.setState({ stockByStore });
        console.log(stockByStore);
        let jsonImg = JSON.parse(stockByStore[0].src);
        this.setState({ productImages: jsonImg.results });

        this.setState({ showSpinnerGeneral: false, activarPopUpStock: true, activarPopUpConsultaStock: false });
    }
    
    async ClickEnviar(barcode, conCodigoProveedor, DifNC) {
        if (this.state.activarPopUpReimprimirHten) {
            await this.EnterFunction(barcode);
            return;
        }
        else if(this.state.activarPopUpStatusQR){
            await this.EnterFunction(barcode)
            return
        }
 
       
        this.setState({ showSpinnerGeneral: true });
   
        if (!this.state.activarPopUpConsultaStock) {
            this.setState({ isNumericKeyEntered: true });
        }
        if (!this.IsPagarHabilitado){
            this.setState({ showSpinnerGeneral: false })
            swal({
                title: this.PayForm,
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        if (this.isSelectedGiftcard){
            this.setState({ showSpinnerGeneral: false })
            swal({
                title: "No puede agregar productos, si esta activando una Giftcard.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }

        if (this.state.TicketID !== 0 || this.state.NotaVentaID !== 0 || this.state.ReservaID !== 0) {
            this.setState({ showSpinnerGeneral: false })
            swal({
                title: "No se puede Modificar una Orden de Servicio,Ecommerce o Reserva, Puede realizarlo desde Blinamic.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        let codProd = barcode;
        let includeText = codProd.includes('*');
        if (includeText) {
            let spli = codProd.split('*');
            codProd = spli[1];
            this.setState({ cantidad: spli[0] });
        }
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        let EmpresaID = GetUserCookie().EmpresaID

        if (this.state.activarPopUpConsultaStock) {
            this.setState({ showSpinnerGeneral: true, activarPopUpConsultaStock: false });
            await this.ClickEnviarConsultaStock(sucursalID, codProd, EmpresaID);
            this.setState({ isNumericKeyEntered: false });
            return;
        }
        //Validar Stock --> se comenta esta validacion ya que los datos estan en el metodo GetProductoByCodigo 31/05/22
      /*  var StockProdResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockProductoByCodigo?Codigo=' + codProd + '&SucursalID=' + sucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(result => { return result; }).catch(error => {
            swal({
                title: "Error al conectarse al server central.",
                icon: "error",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();
            });
        });
        if (StockProdResult && !StockProdResult.ok) {
            this.setState({ isNumericKeyEntered: false });
            throw new Error('problema al traer Stock');
        }

        //Validar Stock TagRfid
        var StockTagProdResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetStockTagProductoByCodigo?Codigo=' + codProd + '&SucursalID=' + sucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(result => result).catch(error => {
            swal({
                title: "Error al conectarse al server central.",
                icon: "error",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();
            });
        });
        if (StockTagProdResult && !StockTagProdResult.ok) {
            this.setState({ isNumericKeyEntered: false });
            throw new Error('problema al traer Stock');
        }
        */
        var productoResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoByCodigo?Codigo=' + codProd + '&SucursalID=' + sucursalID + '&conCodigoProveedor=' + conCodigoProveedor, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(result => result).catch(error => {
            swal({
                title: "Error al conectarse al server central.",
                icon: "error",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();
            });
        });
       
        if (productoResult && !productoResult.ok) {
            this.setState({ isNumericKeyEntered: false });
            this.setState({ showSpinnerGeneral: false });
            //throw new Error('problema al traer Producto');
            swal({
                title: "El producto no posee stock o no existe.",
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();
            });
            return;
        } 
        
        
        //if (productoResult && StockProdResult && StockTagProdResult) {
        if (productoResult) {
            
            var datos = await productoResult.json();
            if (datos.length==0){
                swal({
                    title: "El producto no posee stock o no existe.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();
                });
                return;
            }
            let isgiftcard = datos[0].isGiftcard;
 
            if (isgiftcard >0 ){
                swal({
                    title: "El producto es una Giftcard, debe utilizar la opción de Activación Giftcard.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();
                   
                });
                return;
            }
            var datosD = datos[0].stockDisponible; //await StockProdResult.json();
            var TagStock = datos[0].cantidadCorrelativos;//await StockTagProdResult.json();

            //codigo para agregar producto con la diferencia de saldo para completar NC
            if (DifNC) {
                datos[0].precpred = (this.GetDiferenciaTotalvsNotaCredito())*-1;
            }

            if (this.state.orden.carroProductos.length > 0) {
                let cantidadProductos = 0;
                this.state.orden.carroProductos.map((item) => {
                    if (item.desprod.includes(codProd)) {
                        cantidadProductos += item.cantidad;
                    }

                })
                datosD = datosD - cantidadProductos;
                TagStock = TagStock - cantidadProductos;

            }
        }


        if (datos.length > 1) {
            this.setState({
                ProductosDuplicados: datos,
                activarPopUpProductoDuplicado: true
            });
        } else {
            if (this.state.cantidad <= datosD) {
                if (datos[0].cantidadCorrelativos > 0) {
                    if (this.state.cantidad <= TagStock) {
                        let SplitCode = datos[0].desprod.split(";");
                        let objeto = {
                            id: SplitCode[0],
                            nombre: SplitCode[2],
                            cantidad: this.state.cantidad,
                            cantidadCorrelativo: datos[0].cantidadCorrelativos,
                            valor: 0,
                            idCarro: this.state.idCarro
                        };

                        for (let i = 0; i < this.state.cantidad; i++) {
                            this.ProductosAPedirCorrelativo.push(objeto);
                        }
                    } else {
                        swal({
                            title: "El producto no posee stock de Tags Rfid suficientes.",
                            icon: "warning",
                            dangerMode: true,
                        }).then(() => {
                            this.ClickClear();

                        });
                        this.setState({ isNumericKeyEntered: false });

                        return;
                    }
                }

                if (datos.length > 0) {
                    this.setState({ productosSeleccionados: datos });
                    if (datos[0].clienteObligatorio && this.state.SeleccionoCliente === false) {
                        this.setState({ IsClienteObligatorio: true });
                    }
                    let id = datos[0].id;
                  
                    
                    await this.ClickVerDetallesDos(id);
                    this.ClickClear();
                    
                } else {
                    swal({
                        title: "El producto no posee stock o no existe.",
                        icon: "warning",
                        dangerMode: true,
                    }).then(() => {
                        this.ClickClear();

                    });
                }
            } else {
                swal({
                    title: "El producto no posee stock o no existe.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();
                });
            }

            if (this.state.IsClienteObligatorio && this.state.identificarClienteForzado == 'true') {
                this.setState({ activarPopUpConsultaCliente: true });
                $('#RutCliente').focus();
            }
            setTimeout(() => {
                this.setState({ isNumericKeyEntered: false });
            }, 100);
        }

        this.setState({ showSpinnerGeneral: false });

        

    }

    BorrarChar() {
        if (this.state.reqAuth){
            var val = document.getElementById('PINboxDosAut').value;
            val = val.slice(0, -1);
            document.getElementById('PINboxDosAut').value = val;
            this.TextoIngresado = document.getElementById('PINboxDosAut').value;
            this.setState({ TextoIngresado: this.TextoIngresado });
            this.CodAut=this.state.TextoIngresado;
        }else{
            var val = document.getElementById('EntradaText').value;
            val = val.slice(0, -1);
            document.getElementById('EntradaText').value = val;
            this.TextoIngresado = document.getElementById('EntradaText').value;
            this.setState({ TextoIngresado: this.TextoIngresado });
        }
      
    }

    ClickClear() {
        this.TextoIngresado = '';
        this.setState({ TextoIngresado: this.TextoIngresado });

        document.getElementById('EntradaText').value = '';
        if (this.state.reqAuth){
            document.getElementById('PINboxDosAut').value='';
            this.CodAut=this.TextoIngresado;
        }
        this.setState({ showSpinnerGeneral: false });
       
    }

    ClickBtn(tecla) {
        var val = document.getElementById('EntradaText').value;
        val += tecla;
        document.getElementById('EntradaText').value = val;
        this.setState({TextoIngresado:val});
       
        document.getElementById('EntradaText').focus();
        
       
    }

    LimpiarMensajeValidarRut() {
        $("#msgerror").html("");
    }

    formateaRut(id) {
        var rut = document.getElementById(id).value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            document.getElementById(id).value = rutSinPuntosD;
            return rutPuntos;
        }
    }

    GetFilledStar() {
        let HTML = [];
        // Media estrella para mas adelante.
        //<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-half" viewBox="0 0 16 16"><path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" /></svg >
        for (let a = 0; a < 5; a++) {
            if (a < this.state.DataCliente.estrellas) {
                HTML.push(<svg key={a} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="yellow" className="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" /></svg>);
            } else {
                HTML.push(<svg key={a} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" /></svg>);
            }
        }
        return HTML;
    }

    GetFilledAward()
    {
        let HTML = [];
        // Media estrella para mas adelante.
        //<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16"><path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" /></svg >
        for (let a = 0; a < 5; a++) {
            if (a < this.state.DataCliente.PosicionClasif) {
                HTML.push(<svg key={a} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="yellow" className="bi bi-award" viewBox="0 0 16 16"><path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" /></svg>);
            } else {
                
                HTML.push(<svg key={a} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-award-fill" viewBox="0 0 16 16"><path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" /></svg>);
            }
        }
        return HTML;
    }

    GetOrdersWeb() {
        return (<Button outline color="dark" style={this.Estilos.MenuConsultaBotonSpecial} data-toggle="tooltip" data-placement="top" title="Ecommerce"
            onClick={() => { this.setState({ activarPopUpConsultaEcommerce: true }) }}
            disabled={this.state.ecommerceQty > 0 && !this.state.disableOrdersEcommerce ? false : true}>
            <strong>Ecommerce</strong>
            <div>
                <span className="badge badge-pill badge-dark mr-2" style={this.Estilos.badge}>{this.state.ecommerceQty}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-check" viewBox="0 0 16 16">
                    <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"></path>
                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                </svg>
            </div>
        </Button>)
    }

    GetOrders() {
        return (<Button outline color="primary" style={this.Estilos.MenuConsultaBotonSpecial} onClick={() => { this.setState({ activarPopUpConsultaOrdenServicio: true }) }}
            data-toggle="tooltip" data-placement="top" title="Ordenes" disabled={this.state.ordenesQty > 0 && !this.state.disableOrders ? false : true}>
            <strong>Servicios</strong>
            <div>
                <span className="badge badge-pill badge-primary mr-2" style={this.Estilos.badge}>{this.state.ordenesQty}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tools" viewBox="0 0 16 16">
                    <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z" />
                </svg>
            </div>
        </Button>)
    }

    GetOrdersReserva() {
        return (<Button outline color="success" style={this.Estilos.MenuConsultaBotonSpecial} data-toggle="tooltip" data-placement="top" title="Reservas"
            onClick={() => { this.setState({ activarPopUpConsultaReserva: true }) }}

            disabled={this.state.reservasQty > 0 && !this.state.disableOrdersReserva ? false : true}>
            <strong>Reservas</strong>
            <div>
                <span className="badge badge-pill badge-success mr-2" style={this.Estilos.badge}>{this.state.reservasQty}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-basket" viewBox="0 0 16 16">
                    <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
                </svg>
            </div>
        </Button>)
    }

    upperCaseInput(inputInfo){
        let valueRut = document.getElementById(inputInfo).value;
        document.getElementById(inputInfo).value=valueRut.toUpperCase();

    }

    checkRutAddClient(rut){
        let valueRut = document.getElementById(rut).value;
        if(!this.checkRut(valueRut)){
            swal({
                title: "ERROR !",
                text: "RUT Inválido!",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }
        if(valueRut.indexOf("-") == -1){
            valueRut = valueRut.substring(0,valueRut.length-1) + "-" + valueRut.substring(valueRut.length,valueRut.length-1);
            document.getElementById(rut).value=valueRut;
        }
    }
    

    scrollToBottom = () => {
        if (this.state.isNumericKeyEntered)
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    Reimprimir = () => {
        this.DesactivarModales();
        this.setState({ activarPopUpReimpresion: true, isSale: false });
        setTimeout(() => {
            $("#orderId").focus();
        }, 500);
    }

    async BuscarOrderStorage() {
        const order = document.getElementById('orderId').value;
        if (order) {
            const htenId = sessionStorage.getItem(order);
            if (!htenId) {
                swal({
                    title: "Nro de orden no encontrada en esta sesión de usuario.",
                    icon: "warning",
                    text: "Puede reimprimir sin consultar estado.",
                    dangerMode: true,
                });
                return;
            }
            var myBody = {
                "hten_unique_id": htenId
            };

            await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/status', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(response => response.json()).then((data) => {
                let status = "error";
                if (data === null) {
                    swal({
                        title: "Error al consultar estado",
                        icon: "error",
                        text: "Por favor contacte al administrador del sistema ",
                        dangerMode: true,
                    });
                    return;
                }

                if (data.statusResponse !== "ERROR") {
                    status = "success";
                }
                swal({
                    title: "Orden: " +order+" Estado de impresión " + data.id,
                    icon: status,
                    text: "Estado: " + data.statusResponse + " \r\nDetalle: " + data.descriptionResponse + " \r\nFecha de inicio: " + data.requestDate + " \r\nFecha de respuesta: " + data.responseDate,
                });
            }).catch(error => {
                swal({
                    title: "Error al consultar estado",
                    icon: "error",
                    text: "Por favor contacte al administrador del sistema " + error,
                    dangerMode: true,
                });
            });
        } else {
            swal({
                title: "Error orden no encontrada",
                icon: "error",
                text: "Por favor ingrese una orden válida.",
                dangerMode: true,
            });
        }
            
    }
    checkRut(rut) {
        // Despejar Puntos
        var valor = rut.replaceAll('.', '');
        // Despejar Guión
        valor = valor.replace('-', '');

        // Aislar Cuerpo y Dígito Verificador

        const cuerpo = valor.slice(0, -1);
        let dv = valor.slice(-1).toUpperCase();

        // Formatear RUN
        rut = cuerpo + '-' + dv

        // Si no cumple con el mínimo ej. (n.nnn.nnn)
        if (cuerpo.length < 7) { return false; }

        // Calcular Dígito Verificador
        let suma = 0;
        let multiplo = 2;

        // Para cada dígito del Cuerpo
        let index = 0;
        for (let i = 1; i <= cuerpo.length; i++) {

            // Obtener su Producto con el Múltiplo Correspondiente
            index = multiplo * valor.charAt(cuerpo.length - i);

            // Sumar al Contador General
            suma = suma + index;

            // Consolidar Múltiplo dentro del rango [2,7]

            if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

        }

        // Calcular Dígito Verificador en base al Módulo 11
        const dvEsperado = 11 - (suma % 11);

        // Casos Especiales (0 y K)
        dv = (dv == 'K') ? 10 : dv;
        dv = (dv == 0) ? 11 : dv;

        // Validar que el Cuerpo coincide con su Dígito Verificador
        if (dvEsperado != dv) { return false; }

        // Si todo sale bien, eliminar errores (decretar que es válido)

        return true;
    }

    async setMontOfertaFidelizacion(){
        this.resetOffer()
        // console.log('consultar oferta')
        let productsOrder = []

        //setting de datos básicos para definir los convenios
        let productsOrderCarroProductos = [];
        let productsOrderCarroProductosEncabezado = {};
        let productosConvenioAux = {};

        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        var usaConvenioAux = this.state.DataClienteConvenio.usaConvenio;
        var idConvenioAux = this.state.DataClienteConvenio.idConvenio;

        this.state.orden.carroProductos.forEach(function (item) {
            if (!item.isDescItem) {
                productsOrderCarroProductos.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred, SucursalID: sucursalID, ConvenioID: idConvenioAux })
            }
        })

        productsOrderCarroProductosEncabezado.unCarroProductos = productsOrderCarroProductos;


        //fin setting de datos básicos para definir los convenios


        this.state.orden.carroProductos.forEach(function(item) {
            productsOrder.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred })
        })


        const venta = {
            total: this.GetTotal(),
            clientId: this.state.DataCliente.id,
            sucursalId: GetUserCookie().Sucursal.sucursalIDBlinamic,
            //paymentMethodId: 3,
            products: productsOrder
        }
        let token = sessionStorage.getItem('jwtToken')
        const offerProducts = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOferta?venta=' + encodeURIComponent(JSON.stringify(venta)), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer " + token + ""
            }
        }).then(res => res.json()).then(
            (result) => { return result.result.products }
        )

       

        //var esOfertaConvExistente = false;

        //var productoID = 0;
        //var esBeneficiado = false;
        //var porcDescuento = 0;
        //var calculoPorcentajeDescuentoConvenio = 0;


        //this.state.orden.carroProductos.forEach(function (prod) {

            /*logica que trae los productos con sus descuentos por convenio para poder realizar comparación de cual es mejor*/
            /*en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
            /*y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/

            //esOfertaConvExistente = false;

            //productoID = 0;
            //esBeneficiado = false;
            //porcDescuento = 0;
            //calculoPorcentajeDescuentoConvenio = 0;

            //if (usaConvenioAux) {
                let salidadatosjson = JSON.stringify(productsOrderCarroProductosEncabezado);
                //let url2 = GetUrlBlinamicApi() + '/api/Convenio/GetBeneficioProductoConvenio?SucursalID=' + sucursalID + '&ConvenioID=' + idConvenioAux + '&ProductoID=' + prod.id;
                const ConvenioProducts2 = await fetch(GetUrlBlinamicApi() + '/api/Convenio/GetBeneficioProductoConvenioMasivo',
                    {
                        method: 'POST',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            "Authorization": "Bearer " + token + ""
                        }
                        , body: salidadatosjson
                    }).then(res => res.json()).then(
                        (result) => {
                            if (result.data.length > 0)
                            {
                                productosConvenioAux = result.data;
                                return result.data;
                            }
                        }
                    )
            //}

            /*FIN   en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
            /*FIN  y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/


        //})






        this.state.orden.carroProductos.forEach(function (prod) {

            if (!prod.isDescItem) {
                ///*logica que trae los productos con sus descuentos por convenio para poder realizar comparación de cual es mejor*/
                ///*en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
                ///*y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/

                var esOfertaTradicionalExistente = false;

                var productoID = 0;
                var esBeneficiado = false;
                var porcDescuento = 0;
                var calculoPorcentajeDescuentoConvenio = 0;

                if (usaConvenioAux) {
                    productosConvenioAux.forEach(function (productoConvenio) {

                        if (prod.id == productoConvenio.productoID) {
                            productoID = productoConvenio.productoID;
                            esBeneficiado = productoConvenio.esBeneficiado;
                            porcDescuento = productoConvenio.porcDescuento;
                        }
                    })

                }
                ///*FIN   en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
                ///*FIN  y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/

 
                const carroInOffer = prod.inOffer ? prod.inOffer : false
                offerProducts.forEach(function (offerProd) {

                    const isValid = offerProd.isValid ? offerProd.isValid : true

                    if (prod.desprod.split(';')[0] === offerProd.productCode && isValid && !carroInOffer) {

                        esOfertaTradicionalExistente = true;
                        /* codigo que es capaz de cruzar si hay o no convenio y/o oferta y ver cual es mejor */

                        if (usaConvenioAux) {
                            if (esBeneficiado) {
                                calculoPorcentajeDescuentoConvenio = Math.round(((prod.precpred * porcDescuento) / 100) * prod.cantidad)
                                /*if (offerProducts.length > 0) {*/
                                if (offerProd.offerDisc < calculoPorcentajeDescuentoConvenio) {
                                    offerProd.offerDisc = calculoPorcentajeDescuentoConvenio;

                                    //especulacion en base a análisis de que este cálculo está incorrecto
                                    offerProd.valueOffer = (prod.precpred * prod.cantidad) - calculoPorcentajeDescuentoConvenio;
                                    //offerProd.valueOffer = calculoPorcentajeDescuentoConvenio * prod.cantidad;

                                    offerProd.isValid = true;
                                    prod.isConvenioUsado = true;
                                }
                                else {
                                    prod.isConvenioUsado = false;
                                }
                                //}
                                //else {
                                //    offerProd = [{ offerDisc: calculoPorcentajeDescuentoConvenio, productID: prod.id, valueOffer: (prod.precpred - calculoPorcentajeDescuentoConvenio), valueOfferUnitario: (prod.precpred - calculoPorcentajeDescuentoConvenio), isValid: true }];
                                //}

                            }
                            else {
                                prod.isConvenioUsado = false;
                            }
                        }

                        /*FIN codigo que es capaz de cruzar si hay o no convenio y/o oferta y ver cual es mejor */

                        prod.valueOffer = prod.precpred * prod.cantidad - offerProd.valueOffer
                        prod.valueOfferUnitario = prod.valueOffer / prod.cantidad
                        offerProd.isValid = false
                        prod.inOffer = true




                    }
                })

                if (!esOfertaTradicionalExistente && usaConvenioAux) {
                    calculoPorcentajeDescuentoConvenio = Math.round(((prod.precpred * porcDescuento) / 100) * prod.cantidad)
                    prod.valueOffer = prod.precpred * prod.cantidad - ((prod.precpred * prod.cantidad) - (calculoPorcentajeDescuentoConvenio))
                    prod.valueOfferUnitario = (prod.valueOffer) / prod.cantidad
                    prod.inOffer = true
                }

            }


        })
        
        if(offerProducts.length > 0)
            this.enabledOffer()
        else
            this.disabledOffer()
            
        this.setProductList(this.state.orden)
        this.ActualizarTexto();
    }

    resetOffer() {
        this.state.orden.carroProductos.forEach(function (item) {
            item.valueOffer = 0
            item.valueOfferUnitario = 0
            item.inOffer = false
        })
    }

    enabledOffer() {
        this.state.orden.enabledOffer = true
        this.state.orden.offerPaymentMethodId = this.state.paymentMethodId
    }
    disabledOffer() {
        this.state.orden.enabledOffer = false
        this.state.orden.offerPaymentMethodId = 0
    }

    GetHtmlPopupReimprimir() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info">Búsqueda y reimpresión</CardHeader>
                <Form>
                    <CardBody id="altaCliente" className="form form-group">
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <input className="form-control" type="text" onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.BuscarOrderStorage();
                                    }
                                }} id="orderId" placeholder="Nro de orden" maxLength="9" />
                            </div>
                            <div className="d-inline-flex col-lg-2 col-md-2 col-sm-2 col-2 text-center">
                                <button className="btn btn-primary mr-4" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.BuscarOrderStorage() }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                     
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="reimprimir" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.ClickImprimir2(document.getElementById('orderId').value) }} ><strong>Reimprimir</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }
    
    ClickMas(indiceProd) {
        if (this.isSelectedGiftcard){
            swal({
                title: "No puede modificar la cantidad, solo puede activar una Giftcard a la vez.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        if (this.state.TicketID !== 0 || this.state.NotaVentaID !== 0 || this.state.ReservaID !== 0) {

            swal({
                title: "No se puede Modificar una Orden de Servicio,Ecommerce o Reserva, Puede realizarlo desde Blinamic.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }

       /* no se habilita boton para producto nc 
        if (this.UsedNotaCreditoID != 0){
            swal({
                title: "No se puede modificar manualmente la cantidad de productos cuando se tiene una Nota de Crédito seleccionada.",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }*/
        const productos = this.state.orden.carroProductos;
        //console.log(productos);
        //console.log('el ultimo elemento es ');
        //console.log(productos);
        let isRegalo =  productos[indiceProd].IsUsoRegalo;
        if (isRegalo>0){
            Swal.fire({
                title: "La cantidad del producto regalo no puede ser mayor a 1.",
                icon: "error"
            });
            return;
        }
        productos[indiceProd].cantidad = Number(productos[indiceProd].cantidad) + 1;
        productos[indiceProd].valueOffer = Number((productos[indiceProd].valueOfferUnitario ? productos[indiceProd].valueOfferUnitario : 0)) * Number(productos[indiceProd].cantidad);
        var orden = this.state.orden;
        orden.carroProductos = productos;
        this.setState2({ carroProductos: productos, orden: orden });
    }

    ClickMenos(indiceProd) {
        if (this.isSelectedGiftcard){
            swal({
                title: "No puede modificar la cantidad, solo puede activar una Giftcard a la vez.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        if (this.state.TicketID !== 0 || this.state.NotaVentaID !== 0 || this.state.ReservaID !== 0) {

            swal({
                title: "No se puede Modificar una Orden de Servicio,Ecommerce o Reserva, Puede realizarlo desde Blinamic.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }
        /*no se habilita boton para producto nc 
        if (this.UsedNotaCreditoID != 0){
            swal({
                title: "No se puede modificar manualmente la cantidad de productos cuando se tiene una Nota de Crédito seleccionada.",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }
        */
        const productos = this.state.orden.carroProductos;
        const cantidad = productos[indiceProd].cantidad;
        if (cantidad > 1) {
            productos[indiceProd].cantidad = cantidad - 1;
            productos[indiceProd].valueOffer = Number((productos[indiceProd].valueOfferUnitario ? productos[indiceProd].valueOfferUnitario : 0)) * Number(productos[indiceProd].cantidad);
            this.setState2({ carroProductos: productos });
        }
    }
    async CerrarModalDescPuntos (){
         await this.setState({activaDescuentoPuntos:false});
         this.ClickPagar();
    }

    isHten() {
        return JSON.parse(sessionStorage.getItem('userProfile')).Sucursal.hten;
    }
    getHtmlHtenUltimo(){
        return this.isHten() ? <button name="blokCambios" id="ReimprimirUltimaHten" className="btn btn-dark" onClick={(e) => { this.ClickReimprimirUltimaHten(e) }} style={this.Estilos.btnOptInferior} >Reimprimir Ultima HTEN</button> : ''
    }
    getHtmlHtenImprimir() {
        return this.isHten() ? <button name="blokCambios" id="ReimprimirHten" className="btn btn-dark" onClick={(e) => { this.ClickReimprimirHten(e) }} style={this.Estilos.btnOptInferior} >Reimprimir HTEN</button>:''
    }
    getHtmlCierreTbk() {
        return this.isHten() ? <button name="blokCambios" id="Cierre TBK" className="btn btn-dark" onClick={() => { this.ClickCierreTBK() }} style={this.Estilos.btnOptInferior} >Cierre TBK</button> : ''
    }
    getHtmlStatusQR() {
        return this.isHten() ? <button name="blokCambios" id="StatusQR" className="btn btn-dark" onClick={() => { this.ClickStatusQR() }} style={this.Estilos.btnOptInferior} >Estado QR</button> : ''
    }

    RedondearValor(monto) {
        if (this.IsEfectivo) {
            if (monto > 0) {
                const resto = monto % 10;
                if (resto > 0 && resto <= 5) {
                    return monto - resto;
                }
                else if (resto > 5 && resto < 10) {
                    return monto + (10 - resto);
                }
            }
            else {
                return monto;
            }
        }
        if (monto < 1){
            return monto;
        }
        else {
            monto = Math.round(monto * 10)/10;
        }
        return monto
    }

    ClickCE() {
        this.Numero = "0";
        this.TextoIngresadoNum = '';
 //       document.getElementById('lbPuntosEnPesos').innerHTML = '';
        document.getElementById('PINbox').value = '';
        document.getElementById('-').blur();
    }

    async ClickSettingPoints(){
        if(this.state.ErrorExists){
            return;
        }

        let montoEnPesos = 0;

         
        montoEnPesos=  Number(this.Numero.toString().replaceAll('.',''));
       
        const cantidad =montoEnPesos / this.FactorPuntos;
  

        console.log('la cantidad de puntos' + cantidad);
        if (cantidad > this.SaldoPuntosUsable /*this.Puntos*/){
            this.setState({ErrorExists:true});
            swal({
                title: 'La cantidad de puntos no puede superar el Saldo de Puntos Usables del Cliente (Sin unidades. Ej.: 501 => 500).',
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.setState({ErrorExists:false});
                document.getElementById('PINbox').focus();
            })
            return;
        }

        if (cantidad < 1){
            this.setState({ErrorExists:true});
            swal({
                title: 'Debe ingresar un monto válido para puntos.',
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.setState({ErrorExists:false});
                document.getElementById('PINbox').focus();
            })
            return;
        }
        if (montoEnPesos >= this.GetTotal()){
            this.setState({ErrorExists:true});
            swal({
                title: 'El monto a cubrir con puntos no puede ser superior o igual al Saldo a pagar de la Venta.',
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                this.setState({ErrorExists:false});
                document.getElementById('PINbox').focus();
            })
            return;
        }

        if ((this.GetTotal() - montoEnPesos)===1){
            this.SetEfectivo = true;
        }
        var neworden = this.state.orden;
             

            if (neworden.puntosXCanje === undefined || neworden.puntosXCanje === 0){
                var descEfec  = neworden.descEfectivo === undefined ? 0 : neworden.descEfectivo;
                descEfec += montoEnPesos;
                neworden.descEfectivo=descEfec;
          
                neworden.dctoEfec = descEfec;
                neworden.puntosXCanje = cantidad;
                await this.setState({orden: neworden, IsDescuentoRealizado:true});
                this.ActualizarTexto();
            }
            this.setState({activaDescuentoPuntos:false});
            await this.setState({ activarPagar: true });
            if (document.getElementById('btnPagar')) {
                document.getElementById('btnPagar').disabled = false;
            }  

    }

    async ClickNoPoints(){
        var neworden = this.state.orden;
             
        let montoEnPesos = 0;

         
        montoEnPesos=  Number(this.Numero.toString().replaceAll('.',''));
       
        const cantidad =this.RedondearValor(montoEnPesos / this.FactorPuntos);
        this.SetEfectivo = false;

        if (neworden.puntosXCanje === undefined || neworden.puntosXCanje===0){
            this.setState({ activarPagar: true });
        }
        else{
            var descEfec  = neworden.descEfectivo === undefined ? 0 : neworden.descEfectivo;
            descEfec = descEfec - montoEnPesos;
            neworden.descEfectivo=descEfec;

            neworden.dctoEfec = descEfec;
            neworden.puntosXCanje = 0;
            await this.setState({orden: neworden});
            this.ActualizarTexto();

            await this.setState({ activarPagar: true });
        }
        if (document.getElementById('btnPagar')) {
            document.getElementById('btnPagar').disabled = false;
        }  
        this.setState({activaDescuentoPuntos:false});
    }

     //INI ADD GIFTCARD LIKE PRODUCT
 
    async EnviarGiftcard(barcode, conCodigoProveedor,precioGiftcard,serie) {
    
        this.setState({ showSpinnerGeneral: true });

        if (!this.state.activarPopUpConsultaStock) {
            this.setState({ isNumericKeyEntered: true });
        }
   
        /*if (this.state.TicketID !== 0 || this.state.NotaVentaID !== 0 || this.state.ReservaID !== 0) {
            this.setState({ showSpinnerGeneral: false })
            swal({
                title: "No se puede Modificar una Orden de Servicio,Ecommerce o Reserva, Puede realizarlo desde Blinamic.",
                icon: "warning",
                dangerMode: true,
            })

            return;
        }*/
        let codProd = barcode;
        let includeText = codProd.includes('*');
        if (includeText) {
            let spli = codProd.split('*');
            codProd = spli[1];
            this.setState({ cantidad: spli[0] });
        }
        let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
        let EmpresaID = GetUserCookie().EmpresaID


        var productoResult = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoByCodigo?Codigo=' + codProd + '&SucursalID=' + sucursalID + '&conCodigoProveedor=' + conCodigoProveedor, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(result => result).catch(error => {
            swal({
                title: "Error al conectarse al server central.",
                icon: "error",
                dangerMode: true,
            }).then(() => {
                this.ClickClear();
            });
        });
   
        if (productoResult && !productoResult.ok) {
            this.setState({ isNumericKeyEntered: false });
            this.setState({ showSpinnerGeneral: false });
            throw new Error('problema al traer Producto');
        }
    
    
        //if (productoResult && StockProdResult && StockTagProdResult) {
        if (productoResult) {
            var datos = await productoResult.json();
            if (datos.length==0){
                swal({
                    title: "El producto no posee stock o no existe.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();
                });
                return;
            }
            var datosD = datos[0].stockDisponible; //await StockProdResult.json();
            var TagStock = datos[0].cantidadCorrelativos;//await StockTagProdResult.json();
            if (this.state.orden.carroProductos.length > 0) {
                let cantidadProductos = 0;
                this.state.orden.carroProductos.map((item) => {
                    if (item.desprod.includes(codProd)) {
                        cantidadProductos += item.cantidad;
                    }

                })
                datosD = datosD - cantidadProductos;
                TagStock = TagStock - cantidadProductos;

            }
        }
     if (datos.length > 1) {
            this.setState({
                ProductosDuplicados: datos,
                activarPopUpProductoDuplicado: true
            });
        } else {
            if (this.state.cantidad <= datosD) {
                if (datos.length > 0) {
                    this.setState({ productosSeleccionados: datos });
                    if (datos[0].clienteObligatorio && this.state.SeleccionoCliente === false) {
                        this.setState({ IsClienteObligatorio: true });
                    }
                    let id = datos[0].id;
                    await this.VerDetallesGC(id,precioGiftcard,serie);
                    this.ClickClear();
                
                } else {
                    swal({
                        title: "El producto no posee stock o no existe.",
                        icon: "warning",
                        dangerMode: true,
                    }).then(() => {
                        this.ClickClear();

                    });
                }
            } else {
                swal({
                    title: "El producto no posee stock o no existe.",
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.ClickClear();
                });
            }

            if (this.state.IsClienteObligatorio && this.state.identificarClienteForzado == 'true') {
                this.setState({ activarPopUpConsultaCliente: true });
                $('#RutCliente').focus();
            }
            setTimeout(() => {
                this.setState({ isNumericKeyEntered: false });
            }, 100);
        }

        this.setState({ showSpinnerGeneral: false });
        this.setState({activaActGiftcard:false});
    }

    async VerDetallesGC(codProd,precioGiftcard,serie) {
    
        if (this.state.verDetalle) {
            var prodClicked = this.state.productosSeleccionados.filter(productosSeleccionados => productosSeleccionados.id === codProd)[0];
            this.setState({ activarVerDetalle: true, productoClickeado: prodClicked });
        }
        else {
            var prodClicked = this.state.productosSeleccionados.filter(productosSeleccionados => productosSeleccionados.id === codProd)[0];
            if (prodClicked.isNivelSuperior) {
                this.isNivelSuperiorSelected = true;
                if (prodClicked.productosInferiores === null) {
                    prodClicked.productosInferiores = [];
                }
                this.setState({ productosSeleccionados: prodClicked.productosInferiores });

            }
            else {
                this.AgregarProductoGiftcard(codProd,precioGiftcard,serie);
                if (GetUserCookie().Sucursal.configPOS.isStockHabilitado) {
                    this.SimularReduccionStock(codProd);
                }
            }
        }
    }


    async AgregarProductoGiftcard(idProducto, precioGiftcard,serie) {
    
        var seleccionados = Object.create(this.state.productosSeleccionados);
        var carrito = Object.create(this.state.orden.carroProductos).__proto__;
        var nuevoTotal = this.state.precioTotal;
        var nuevoTotalConOferta = this.state.precioTotalDescuentoOferta;
        const cant = Number(this.state.cantidad);

        var productoSeleccionado = Object.create(seleccionados.filter(seleccionados => seleccionados.id === idProducto));

        productoSeleccionado = productoSeleccionado[productoSeleccionado.length - 1];
     
    
        var productoAgregar = {};
        productoAgregar.cantidadCorrelativos = productoSeleccionado.cantidadCorrelativos;
        productoAgregar.puntosAcumula = productoSeleccionado.puntosAcumula;// productopuntos;
        productoAgregar.IsRegalo = productoSeleccionado.isRegalo;//productoregalo;
        productoAgregar.IsUsoRegalo = 0;
        productoAgregar.ProductoRegaloValor = productoSeleccionado.precpred;//productoregaloValor;
        productoAgregar.regaloPorcentaje = this.state.regaloPorcentajeValor;//regaloPorcentajeValor;
        productoAgregar.cantidad = cant;
        productoAgregar.isCombo = productoSeleccionado.isCombo;
        productoAgregar.codgrup = productoSeleccionado.tbgrupoid;
        productoAgregar.id = productoSeleccionado.id;//jg codprod cambiado por id
        productoAgregar.desprod = productoSeleccionado.desprod;
        productoAgregar.precioFinal = productoSeleccionado.precioFinal;
        productoAgregar.idCarro = this.state.idCarro;
        let numIdCarro = this.state.idCarro + 1;
        this.setState({ idCarro: numIdCarro });
  
        productoAgregar.precpred = precioGiftcard;
 
        productoAgregar.tipomodif = productoSeleccionado.tipomodif;
        productoAgregar.modificadores = productoSeleccionado.modificadores;
        productoAgregar.isGuardado = true;
        productoAgregar.isPesable = productoSeleccionado.isPesable;
        productoAgregar.isGiftcard = 1;
        productoAgregar.serieGiftcard = serie;
    
        if (GetUserCookie().Sucursal.configPOS.isBarCode) {
            productoAgregar.isComanda = true;
        }

        if (GetUserCookie().Sucursal.configPOS.isPromociones) {
            var respuesta = await fetch(GetUrlApi() + '/api/Promociones/GetPromocionesProducto?productoID=' + idProducto, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {

                var datos = await respuesta.json();
                productoAgregar.precpred += datos.descuentoTotal;
            }
        }

        //Busco ofertas por producto y le asigno cero al clientID cuando aún no tengo el cliente registrado.
        let clienteID = 0;
        if (this.state.DataCliente.id != '') {
            clienteID = this.state.DataCliente.id;
        }
        let token = sessionStorage.getItem('jwtToken')
        const offerProducts = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOfertaPorProducto?productoID=' + productoSeleccionado.id + '&cantidad=' + cant + '&clienteID=' + clienteID + '&sucursalID=' + GetUserCookie().Sucursal.sucursalIDBlinamic, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer " + token + ""
            }
        }).then(res => res.json()).then(
            (result) => { return result.result.products }
        )

        const prodInOffer = productoAgregar.inOffer?productoAgregar.inOffer:false
        offerProducts.forEach(function(offerProd) {
            const isValid = offerProd.isValid?offerProd.isValid:true
            if (productoAgregar.id === offerProd.productID && isValid && !prodInOffer) {
                productoAgregar.valueOffer = productoAgregar.precpred * productoAgregar.cantidad - offerProd.valueOffer
                //productoAgregar.valueOfferUnitario = productoAgregar.precpred * productoAgregar.cantidad - offerProd.valueOffer
                productoAgregar.valueOfferUnitario = productoAgregar.precpred - (offerProd.valueOffer/productoAgregar.cantidad)
                offerProd.isValid=false
                productoAgregar.inOffer = true
            }
        })                         

        let ifexists = false;
  
    //validar el producto si existe una NC seleccionada

    if (this.state.SeleccionoCliente){
        if (this.UsedNotaCreditoID != 0 && this.state.Ambiente==='MAISA'){
            if (this.ProductosNC != undefined && this.ProductosNC.length !=0){
                let idx = this.ProductosNC.findIndex(t => t.codigoProductoPadre === productoSeleccionado.codigoProductoPadre);
                if (idx === -1){
                    swal({
                        title: "Solo puede agregar productos similares, relacionados a la Nota de Crédito seleccionada.",
                        icon: "error",
                        dangerMode: true,
                    })
                    return;
                }
                else{
                    if (this.ProductosNC[idx].cantidad === 1 ){
                        productoAgregar.precpred = this.ProductosNC[idx].valorVenta;
                        productoAgregar.cantidad = this.ProductosNC[idx].cantidad;
                        this.ProductosNC.splice(idx, 1);
                    }
                    else{
                        productoAgregar.precpred = this.ProductosNC[idx].valorVenta; 
                        this.ProductosNC[idx].cantidad -= 1;
                    }
                    productoAgregar.valueOffer=0;
                    productoAgregar.valueOfferUnitario = 0;
                }
            }
            else{
                swal({
                    title: "Ya no puede agregar mas productos a esta Venta, al estar asociada a una Nota de Crédito.",
                    icon: "error",
                    dangerMode: true,
                })
                return;
            }
            
        }
    }
    
    
    for (var i = 0; i< carrito.length;i++){
        if (this.UsedNotaCreditoID ===0){
            if (carrito[i].id==productoSeleccionado.id && carrito[i].IsUsoRegalo==0){
                carrito[i].cantidad +=1;
                carrito[i].valueOffer = carrito[i].valueOffer + productoAgregar.valueOfferUnitario;
                ifexists=true;
            }
     
            }
        }
        this.IDserieGiftcard = serie;
        this.isSelectedGiftcard =true;
        if (!ifexists){
            carrito.push(productoAgregar);
        }

        console.log(productoAgregar);
        nuevoTotal += productoSeleccionado.precpred * this.state.cantidad;
        nuevoTotalConOferta += (productoSeleccionado.precpred * this.state.cantidad) - (productoAgregar.valueOffer ? productoAgregar.valueOffer : 0);
        productoSeleccionado.precioFinal = productoSeleccionado.precpred * this.state.cantidad;
        var newOrden = this.state.orden;
        newOrden.carroProductos = carrito;
        this.CorrelativoIngresado = '';
        await this.setState({ carroProductos: carrito, precioTotal: nuevoTotal, cantidad: 1, orden: newOrden, PrecioTotalConDescuento: nuevoTotalConOferta });
        this.ActualizarTexto();
        if (this.isNivelSuperiorSelected) {
            this.TraerProductosNivSupr(this.IdUltCategoriaSeleccionada);
            this.isNivelSuperiorSelected = false;
        }
    }	

    uppercaseName(inputInfo){
        let valueRut = document.getElementById(inputInfo).value;
        document.getElementById(inputInfo).value=valueRut.toUpperCase();

    
    }

//END ADD GIFTCARD LIKE PRODUCT	



    maxLengthCheck(id) {
        var object =  document.getElementById(id);
        if (object.value.length > object.maxLength)
          object.value = object.value.slice(0, object.maxLength)
      }
    GetHtmlPopupDescuentoPuntos(){
        return (
            <Popup
                id='DescuentoPuntos'
                onClose={() => { this.DesactivarModales() }}
                contentStyle={this.Estilos.EstiloPopup}
                defaultOpen
                modal
                closeOnDocumentClick>
                <div>
                                <div>
                                    <div className="row">   
                                          {/*<div className="col"><strong>Tienes disponible:  {GetNumeroMiles(this.state.DataClientePuntos.saldoPuntos)}  Puntos, equivalentes a $:{GetNumeroMiles(this.RedondearValor(this.state.DataClientePuntos.saldoPuntos * this.state.DataClientePuntos.factorCanje))}</strong> </div>*/}
                                          <div className="col"><strong>Tienes disponible para comprar con puntos un maximo de : ${GetNumeroMiles(this.state.AmountOfPoints)} </strong> </div>
                                     </div>
                                    <div>
                                         <div className="row">
                                         <div className="col"><strong>Saldo a Pagar: $ {GetNumeroMiles(this.GetTotal())}</strong>
                                         </div>
                                     </div>
                                  
     
                                  
                                     <div className="row">
                                           <div className="col" ><strong>¿Que monto deseas ocupar?</strong></div>
                                     </div>
                                     <div className="row">
                                        
                                             <input id='PINbox' onChange={(val) => {
                                                     this.maxLengthCheck('PINbox');
                                                }} 
                                                maxlength = "10"   
                                                onFocus={() => { this.ElementBlur('PINbox') }} type={this.Numero}   name='PINbox' />
                                          
                                     </div>
                                   </div>  
                              
                               
                                
                             
                                <table id="tablaBotones">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className="btn"  name='7' value='7' id='7' /></td>
                                            <td> <input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className="btn"  name='8' value='8' id='8' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className="btn"  name='9' value='9' id='9' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className="btn"  name='4' value='4' id='4' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className="btn"  name='5' value='5' id='5' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className="btn"  name='6' value='6' id='6' /></td>
                                            <td><button className="btn" style={this.Estilos.ButtonsEnt} onClick={() => { this.DeleteChar() }} type="button" name="Borrar" id="Borrar"><i className="fa fa-long-arrow-left " aria-hidden="true"></i></button></td>
                                        </tr>
                                        <tr>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className="btn"  name='1' value='1' id='1' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className="btn"  name='2' value='2' id='2' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className="btn"  name='3' value='3' id='3' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input style={this.Estilos.ButtonsDes} onClick={() => { this.ClickCE() }} type='button' className='btn' name='-' value='clear' id='-' ></input></td>
                                            <td> <input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className="btn"  name='0' value='0' id='0' /></td>
                                            <td></td>
                                            <td>¿Deseas ocupar tus puntos?</td>
                                            <td><input onClick={() => { this.ClickNoPoints() }} type='button' className='PINbutton clear' name='-' value='No' id='-' ></input></td>
                                            <td><input onClick={() => { this.ClickSettingPoints() }} type='button' className='PINbutton enter' name='+' value='Si' id='+'></input></td>

                                        </tr>
                                        <tr>
                                       
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                       
                </div>
            </Popup>
        );
    }

     
    GetHtmlDescItem() {

        return (<Popup
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                    <TecladoNumerico
                        DescItem = { this.state.descItemObj }
                        limitDescItem = { this.state.descItemObj.precpred * this.state.descItemObj.cantidad }
                        CerrarModal={this.CerrarModal}
                        CerrarModalMaster={this.CerrarModalMaster}
                        title={ 'Monto Descuento' }
                        TotalOrdenDesc={0}
                        addDescItem = { this.addDescItem.bind(this) }
                        descItemEfec = {this.state.descItemEfec}
                    />
                </Popup>);
        
    }
    itemDescCLickHandle() {
        this.setState()
    }


    async getNumDocVenta(){

        if (this.state.Ambiente=='MAISA'){  
            
            let suc = GetUserCookie().SucursalID;
            let caja = GetUserCookie().CajaExternaID;
            let tipo ='B';
            if (this.state.IsFacturaSelected){
                tipo='F';
            }
            else if(this.state.IsBoletaSelected){
                tipo='B';
            }
            else if(this.isSelectedGiftcard){
                tipo ='TR';
            }

            try {
                var respuesta = await fetch(GetUrlApi() + '/api/GetNumDocVenta?SucursalID=' + suc +'&CajaID=' +caja +'&TipoDoc=' + tipo, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (respuesta.ok) {
    
                   var datos = await respuesta.json();
                   if (datos.data > 0){
                         this.setState({NumDocVenta:datos.data});
                   }
                   else {
                    this.setState({NumDocVenta:0});
                   }
                }
            }catch(e){
                console.log(e);
            }

           
        }
    }



    render() {
        const loginSeller = localStorage.getItem('loginSeller');

        return (
            <div style={this.Estilos.EstiloPaginaCompleta}>
                {this.state.showSpinnerGeneral ? (<MySpinner></MySpinner>) : (null)}
                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                {
                    (this.state.mostrarTecladoAut) ? (<TecladoNumerico IsAutorizacion={true} CrearCierre={this.RemoverItemOrden} param={(this.InidiceRemover + 1)} param2={this.state.orden.carroProductos[this.InidiceRemover]} />) : (null)
                }
                {(this.state.activarTecladoNumCliente) ? (
                    this.GetHtmlPopupComensales()

                ) : (null)}


                {(this.state.activarPopUpConsultaDeServicio) ? (
                    this.GetHtmlPopupConsultaDeServicios()

                ) : (null)}

                {(this.state.activarPopUpReimpresion) ? (
                    this.GetHtmlPopupReimprimir()

                ) : (null)}

                {(this.state.NeedCorrelativos) ? (
                    this.GetHtmlPopupNeedCorrelativo()

                ) : (null)}

                {(this.state.MostrarCorrelativos) ? (
                    this.GetHtmlPopupMostrarCorrelativo()

                ) : (null)}

                {(this.state.activarPopUpConsultaOrdenServicio) ? (
                    this.GetHtmlPopupConsultaOrdenDeServicio()

                ) : (null)}

                {(this.state.activarPopUpConsultaCliente) ? (
                    this.GetHtmlPopupConsultaCliente()

                ) : (null)}

                {(this.state.activarPopUpConsultaConvenio) ? (
                    this.GetHtmlPopUpSeleccionConvenio()

                ) : (null)}


                {(this.state.reqAuth) ? (
                    this.GetHtmlAutorizado()

                ) : (null)}

                {(this.state.activarPopUpLoginVendedor) ? (
                    this.GetHtmlPopupLoginVendedor()

                ) : (null)}
                {(this.state.ActivarPopUpNotasCredito) ? (
                    this.GetHtmlPopUpNotasDeCredito()
                ): (null)}

                {(this.state.activarPopUpProductoDuplicado) ? (
                    this.GetHtmlPopupProductoDuplicado()

                ) : (null)}

                {(this.state.activarPopUpDireccionRazonSocial) ? (
                    this.GetHtmlPopupDireccionRazonSocial()
                
                ) : (null)}

                {(this.state.activarPopUpConsultaEcommerce) ? (
                    this.GetHtmlPopupConsultaEcommerce()

                ) : (null)}


                {(this.state.activarPopUpConsultaReserva) ? (
                    this.GetHtmlPopupConsultaReserva()

                ) : (null)}

                {(this.state.CrearCliente) ? (
                    this.GetHtmlPopupAddCliente()
                ) : (null)}

                {(this.state.CrearClienteEmpresa) ? (
                    this.GetHtmlPopupAddClienteEmpresa()
                ) : (null)}

                {(this.state.ModificarPrograma) ? (
                    this.GetHtmlPopupModPrograma()
                ) : (null)}

                {(this.state.activarPopUpPdfOS) ? (
                    this.GetHtmlPopUpDesplegarPdfOS()

                ) : (null)}

                {(this.state.activarPopUpNotaVenta) ? (
                    this.GetHtmlPopUpDesplegarNotaVenta()

                ) : (null)}
                {
                    (this.state.activarModForz) ? (
                        <EspecificarPedido
                            CerrarModalMaster={this.CerrarModalMaster}
                            DesactivarModales={this.DesactivarModales}
                            carrito={this.state.carroProductos}
                            handler={this.handler}
                            prod={this.UltimoItemClickeado}
                            AgregarProductoModif={this.AgregarProductoBarcode}
                        />
                    ) : (null)
                }
                {
                    (this.state.activarPesable) ? (
                        <ProdPesables
                            CerrarModalMaster={this.CerrarModalMaster}
                            DesactivarModales={this.DesactivarModales}
                            carrito={this.state.carroProductos}
                            handler={this.handler}
                            prod={this.UltimoItemClickeado}
                            AgregarProductoSeleccionado={this.AgregarProducto.bind(this)}
                            TipoVentaID={Number(this.urlParams.get('nmesa'))}
                        />
                    ) : (null)
                }
                {
                    (this.state.activarVerDetalle) ? (<div> <DetallesProducto CerrarModalMaster={this.CerrarModalMaster} prod={this.state.productoClickeado} /> </div>) : (null)
                }

                {
                    (this.state.activarMisc) ? (<div> <Misc ActualizarEstado={this.setState2}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        prod={this.state.productoClickeado} /> </div>) : (null)
                }
                {
                    (this.state.activarModif) ? (<div> <Modificadores
                        ActualizarCarroMaster={this.ActualizarCarroMaster}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        productos={this.state.orden.carroProductos}
                        parametros={this.state.parametros}
                        ActualizarEstadoMaster={this.setState2} /> </div>) : (null)
                }
                {
                    (this.state.activarCPrecio) ? (<div> <CambioPrecioPopUp
                        ActualizarCarroMaster={this.ActualizarCarroMaster}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        productos={this.state.orden.carroProductos}
                        parametros={this.state.parametros}
                        orden={this.state.orden}
                        ActualizarEstadoMaster={this.setState2} /> </div>) : (null)
                }
                {
                    (this.state.activarCancelar) ? (<div> <CancelarProd
                        ActualizarEstado={this.setState2}
                        ClickEliminar={this.ClickEliminar}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        orden={this.state.orden}
                        productos={this.state.orden.carroProductos} /> </div>) : (null)
                }
                {
                    (this.state.activarRegalo) ? (<div> <RegalarProd
                        ActualizarEstado={this.setState2}
                        ClickEliminar={this.ClickEliminar}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        orden={this.state.orden}
                        productos={this.state.orden.carroProductos} /> </div>) : (null)
                }
                {
                    (this.state.activarPagar) ? (<div> <Pagar
                        idOrden={this.IdVenta}
                        NumOrden={this.NumeroNewOrden}
                        ActualizarEstado={this.setState2}
                        orden={this.GetOrden()}
                        productos={this.state.carroProductos}
                        CerrarModalMaster={this.CerrarModalMaster}
                        idGaveta={this.IdGavetaActual}
                        TipoVenta={Number(this.urlParams.get('tipoV'))}
                        MesaID={Number(this.urlParams.get('nmesa'))}
                        TicketID={Number(this.state.TicketID)}
                        IsBoletaSelected={this.state.IsBoletaSelected}
                        IsFacturaSelected={this.state.IsFacturaSelected}
                        ClientData={this.state.DataCliente}
                        NotaVentaID={this.state.NotaVentaID}
                        ReservaID={this.state.ReservaID}
                        DetalleMediosReserva={this.state.DetalleMediosReserva}
                        DetalleMediosEcommerce={this.state.DetalleMediosEcommerce}
                        IsEcommerce={this.state.IsEcommerce}
                        AddCliente={this.GetHtmlPopupAddCliente}
                        dataClientePuntos={this.state.DataClientePuntos}
                        MediosPago={this.state.MediosPago}
                        MediosPagoVisibles={this.state.MediosPagoVisibles}
                        puntosPorProductos={this.state.puntosPorProductos}
                        montoProductosSinPuntos={this.state.montoProductosSinPuntos}
                        montoAbonado={this.state.montoAbonado}
                        SetEfectivo={this.SetEfectivo}
                        UsedNotaCreditoID={this.UsedNotaCreditoID}
                        FolioNC={this.FolioNC}
                        MontoNC={this.MontoNC}
                        DataClienteConvenio={this.state.DataClienteConvenio}
                        isSelectedGiftcard={this.isSelectedGiftcard}
                        IDserieGiftcard={this.IDserieGiftcard}
                        NumDocVenta={this.state.NumDocVenta}
                        

                    /> </div>) : (null)
                }
                {(this.state.activaDescuentoPuntos) ? (this.GetHtmlPopupDescuentoPuntos()) : (null)
                }

                {
                    (this.state.activarDescuentos) ? (<DescuentosParaLlevar ActualizarEstado={this.setState2} orden={this.state.orden} CerrarModal={this.DesactivarModales} TotalOrden={this.GetTotal()} />) : (null)
                }
                {
                    (this.state.consultarOfertas) ? (<OfertaConsultar orden={this.state.orden} closeModal={this.closeConsultarOferta} TotalOrden={this.GetTotal()} ClientData={this.state.DataCliente} setOrder={this.setProductList} />) : (null)
                }

                {
                    (this.state.activaActGiftcard) ? ( 
                        (<ActivacionGiftcard
                            ActualizarEstado={this.setState2}
                            CerrarModal={this.DesactivarModales}
                            closeModal={this.closeActGiftcard} 
                            orden={this.state.orden}
                            EnviarGiftcard = {this.EnviarGiftcard}
                            ParentComp="Venta" />)
                        
                    ): (null)
                }
                {(this.state.consultarDescOffers) ? (
                             this.GetHtmlPopupDescOffers()
                    ) : (null)}
                {(this.state.consultarGiftcard) ? (
                             this.GetHtmlPopupGiftcard()
                    ) : (null)}


              {(this.state.consultarMovsGiftcard) ? (
                             this.GetHtmlPopupMovimientosGiftcard()
                    ) : (null)}
                {
                    (this.state.activarCantidad) ? (<TecladoNumerico ActualizarEstado={this.setState2} CerrarModalMaster={this.DesactivarModales} cantidad={true} CerrarModal={this.DesactivarModales} />) : (null)
                }
                {
                    (this.state.activarPopTransferir) ? (

                        <TrasladarOrden DesactivarModales={this.DesactivarModales}
                            Orden={this.state.orden}
                            ClickBorrarOrden={this.ClickBorrarOrden}
                            ActualizarMaster={this.setState}
                        />
                    ) : (null)
                }

                {
                    (this.state.activarTransferir) ? (
                        <TransferirOrdenes DesactivarModales={this.DesactivarModales}
                            Orden={this.state.orden}
                            ClickBorrarOrden={this.ClickBorrarOrden}
                            CerrarModal={this.DesactivarModales}
                        />
                    ) : (null)
                }


                {
                    (this.state.activarPopAnular) ? (
                        <PopupMotivoAnulacion DesactivarModales={this.DesactivarModales}
                            Orden={this.state.orden}
                            ClickBorrarOrden={this.ClickBorrarOrden}
                        />
                    ) : (null)
                }

                {
                    (this.state.activarPeso) ? (
                        <PopupPesoProducto DesactivarModales={this.DesactivarModales} />
                    ) : (null)
                }

                {(this.state.activarPopNomCliente) ? (this.GetHtmlPopupNomCliente()) : (null)}

                {(this.state.activarPopEmpresaDeliv) ? (this.GetHtmlPopupEmpresaDeliv()) : (null)}

                {(this.state.activarPopUpConsultaStock) ? (
                    <div className="modoConsulta text-center text-white col-12 h4" style={this.column2}>
                        <div>
                            INGRESE EL CODIGO PARA CONSULTAR STOCK
                        </div>
                        <Button className="btn-lg btn-danger mt-5" onClick={() => {
                            this.setState({ activarPopUpConsultaStock: false });
                            $('#EntradaText').attr('placeholder','Código');
                        }}>FINALIZADO</Button>
                    </div>
                ) : (null)}

                {(this.state.activarPopUpReimprimirHten && this.isHten()) ? (
                    <div className="modoConsulta text-center text-white col-12 h4" style={this.column2}>
                        <div>
                            INGRESE TRX A REIMPRIMIR 
                        </div>
                        <div>
                            <p className="h5 mt-3"><input id='tipoHtenPago' onFocus={this.onChangeValueHtenTipo} type="radio" value="pago" name="tipoHten" /> Pago
                            <input className="ml-3" onFocus={this.onChangeValueHtenTipo} type="radio" value="nc" name="tipoHten" /> Nota Credito </p>
                        </div>
                        <Button className="btn-lg btn-danger mt-5" onClick={() => {
                            this.setState({ activarPopUpReimprimirHten: false });
                        }}>FINALIZADO</Button>
                    </div>
                ) : (null)}
                {(this.state.activarPopUpStatusQR && this.isHten()) ? (
                    <div className="modoConsulta text-center text-white col-12 h4" style={this.column2}>
                        <div>
                            INGRESE ID TRANSACCIÓN
                        </div>
                        <div>
                            <p className="h5 mt-3"><input id='tipoQREntity' onFocus={this.onChangeValueQREntity} type="radio" value="BCI" name="tipoHten" /> MACH-BCI </p>
                        </div>
                        <Button className="btn-lg btn-danger mt-5" onClick={() => {
                            this.setState({ activarPopUpStatusQR: false });
                        }}>FINALIZADO</Button>
                    </div>
                ) : (null)}

                {(this.state.activarPopUpStock) ? (
                    this.GetHtmlPopupConsultaDeStock()
                ) : (null)}

                {(this.state.activarPopUpStockSucursales) ? (
                    this.GetHtmlPopupStockSucursales()
                ) : (null)}
                { this.state.descItem }
                {
                    (this.state.descItem) ? ( this.GetHtmlDescItem()):(null)
                }
                <Card>
                    <div className="row d-flex d-inline-flex justify-content-center">
                        <div className="ml-1 mr-1 col-lg-9 col-md-12 col-sm-12 col-12" >
                            <div style={this.TextareaBoleta}>
                             
                                
                                {/*<div className={this.state.estacionStyle}><strong>Estación:</strong>{this.Estacion} - {this.state.OrdenDisplay}</div>*/}
                                <div className={this.state.estacionStyle}> {this.state.OrdenDisplay}</div>
                                {(this.NumMesa > 0) ? (<div>#Mesa {this.state.mesaSeleccionada.descipcion} - Clientes: {this.GetNumeroClientes()} </div>) : (null)}
                                <div>
                                    {(this.state.VendedoresCarga) ? (<strong>Código Vendedor:  </strong>) : (<strong hidden='hidden'>Código Vendedor:  </strong>)}

                                    {(this.state.VendedoresCarga) ?
                                        (
                                            <input type="text" placeholder="Ingrese Código Vendedor" id="CodigoVendedor" onChange={() => { this.focusChangeState(1) }} />
                                        )
                                        :
                                        (<input type="text" hidden='hidden' placeholder="Ingrese Código Vendedor" id="CodigoVendedor" onChange={() => { this.focusChangeState(1) }} />)}
                                </div>


                            </div>
                            <Card className="shadow-lg bg-secondary text-white" style={this.state.EsReserva ? this.myFixedWidthReserva : this.myFixedWidth }>
                                <div style={this.Estilos.DivNewColData}>
                                    <div className="p-2" style={this.Estilos.DivContentData}>
                                        { (this.state.Ambiente === 'MAISA') ?
                                        (<div className="row">
                                                <div className="col-5"> {(this.state.NumDocVenta > 0) ? (<div><strong>NumDoc:</strong> # {this.state.NumDocVenta} </div>) : (<div><strong>NumDoc:</strong> - </div>)}</div>
                                        </div>):(null)
                                        }

                                        <div className="row justify-content-center">
                                            <div className="col-5"><strong>Nombre:</strong> {this.state.DataCliente.nombre}
                                            {
                                                (this.state.DataCliente.rut !== '' && this.state.DataCliente.rut !== '66666666-6') ?
                                                <button className="btn btn-info" title='Modificar Cliente' style={this.Estilos.BtnConsultaClienteD} onClick={() => { this.ClickModCliente() }}>
                                                    <i className="fa fa-edit fa-1x"></i>
                                                </button>:null
                                            }
                                            </div>
                                            <div className="col-3"><strong>Rut:</strong> {this.state.DataCliente.rut}  
                                           
                                            </div>
                                            <span className="d-inline-flex col-4">{this.GetFilledStar()}</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-5"><strong>Telefono:</strong> {this.state.DataCliente.telefono}</div>
                                            <div className="col-3"><strong>Email:</strong> {this.state.DataCliente.email}</div>
                                            
                                            <div className="d-inline-flex col-4 ">{this.GetFilledAward()}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5"><strong>Ultimo Servicio:</strong> {this.state.DataCliente.fechaUltServicio}</div>
                                            <div className="col-3"><strong>Monto:</strong> {GetPrecioFormateado(this.state.DataCliente.montoUltServicio)}</div>
                                            <div className="d-inline-flex col-4 "><strong>Clasificación:</strong> {this.state.DataCliente.NombreClasif}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5"><strong>Ultima Compra:</strong> {this.state.DataCliente.fechaUltCompra}</div>
                                            <div className="col-3"><strong>Monto: </strong>{GetPrecioFormateado(this.state.DataCliente.montoUltCompra)}</div>
                                            <div className="col-4"><strong>Puntos acum. por Produc.: </strong>{GetNumeroMiles(this.state.puntosPorProductos)}</div>

                                        </div>

                                        <div className="row">
                                            {/*<div className="col-5"><strong>Saldo Puntos: </strong>{GetNumeroMiles(this.state.DataClientePuntos.saldoPuntos)} - <strong>Saldo Pesos: </strong> {GetPrecioFormateado(this.state.DataClientePuntos.saldoPesos)} -*/}
                                            <div className="col-5"><strong>Saldo Puntos: </strong>{GetNumeroMiles(this.state.DataClientePuntos.saldoPuntos)} -
                                                <strong>{this.state.DataClientePuntos.descripcionPrograma} </strong>
                                                {(this.state.DataCliente.rut!=='' && this.state.DataCliente.rut !== '66666666-6')?
                                                <button className="btn btn-info" title='Modificar Programa Puntos Cliente' style={this.Estilos.BtnConsultaClienteD} onClick={() => { this.ClickModPrograma() }}>
                                                    <i className="fa fa-edit fa-1x">
                                                </i>
                                                </button>:null}
                                            </div>

                                            {/*usaConvenio: result.data[0].esValidoHoy,*/}
                                            {/*idConvenio: result.data[0].convenioID,*/}
                                            {/*limiteCreditoConvenio: result.data[0].limiteCredito,*/}
                                            {/*CantidadUtilizadaConvenio: result.data[0].creditoDisponible,*/}
                                            {/*NombreConvenio: result.data[0].descripcionConvenio*/}
                                            <div className="col-6"><strong>Convenio Seleccionado: </strong>{this.state.DataClienteConvenio.NombreConvenio} - <strong>Total de Crédito: </strong> {this.GetNombreConvenioSinLimite(this.state.DataClienteConvenio.limiteCreditoConvenio)}  - <strong>Crédito Disponible: </strong> {this.GetNombreConvenioCreditoDisponible(this.state.DataClienteConvenio.limiteCreditoConvenio,this.state.DataClienteConvenio.CantidadDisponible)  }

                                                {/*<strong>{this.state.DataClientePuntos.descripcionPrograma} </strong>*/}
                                                {/*{(this.state.DataCliente.rut !== '') ?*/}
                                                {/*    <button className="btn btn-info" title='Modificar Programa Puntos Cliente' style={this.Estilos.BtnConsultaClienteD} onClick={() => { this.ClickModPrograma() }}>*/}
                                                {/*        <i className="fa fa-edit fa-1x">*/}
                                                {/*        </i>*/}
                                                {/*    </button> : null}*/}
                                            </div>
                                            


                                        </div>
                                        {(this.state.EsReserva === true) ? (
                                            <div className="row">
                                                <div className="col-6"><strong>Deuda Actual:</strong> {GetPrecioFormateado(this.state.DetalleReservas.deudaActual)}</div>
                                                <div className="col-6"><strong>Glosa Reserva:</strong> {this.state.DetalleReservas.descripcion}</div>
                                            </div>
                                        ) : (null)}
                                        {(this.state.EsReserva === true) ? (
                                            <div className="row">
                                                <div className="col-6"><strong>Abonado Total:</strong> {GetPrecioFormateado(this.state.DetalleReservas.totalAbonos)}</div>
                                                <div className="col-6"><strong>Fecha Reserva:</strong> {this.state.DetalleReservas.fechaReserva}</div>

                                            </div>
                                        ) : (null)}
                                        <div className="row">
                                            <div className="col-5"><strong>Nombre Vendedor:</strong> {this.state.DataVendedor.nombre.toUpperCase()}</div>
                                            <div className="col-6" style={{ color: 'orange' }}><strong>{this.MsgNotaCredito}</strong></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5"><strong>Cantidad Total Productos: </strong>{this.GetCantidadProductosTotales()}</div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="shadow-lg" style={this.state.EsReserva ? this.myFixedHeightReserva : this.myFixedHeight} >
                                <div className="ml-2" style={this.Estilos.DivProd}>
                                    {(GetUserCookie().Sucursal.configPOS.columnasExtraOrden === 0) ?
                                        (
                                            <div className="row">
                                                <div className="col-sm-1" style={this.Estilos.Style4Text}><strong>Cant</strong></div>
                                                <div className="col-sm-2" style={this.Estilos.Style5Text}><strong>Codigo</strong></div>
                                                <div className="col-sm-2" style={this.Estilos.Style1Text}><strong>Producto</strong></div>
                                                <div className="col-sm-3" style={this.Estilos.Style2Text}><strong>Unitario</strong></div>
                                                <div className="col-sm-2" style={this.Estilos.Style3Text}><strong>Total</strong></div>
                                            </div>
                                        )
                                        :
                                        (GetUserCookie().Sucursal.configPOS.columnasExtraOrden === 1) ?
                                            (
                                                <div className="row bg-info text-white">
                                                    <div className="col" ><strong>Cant</strong></div>
                                                    <div className="col"><strong>Codigo</strong></div>
                                                    {/*<div className="col"><strong>Sku</strong></div>*/}
                                                    <div className="col-3" ><strong>Producto</strong></div>
                                                    <div className="col"><strong>Unitario</strong></div>
                                                    <div className="col"><strong>Desc</strong></div>
                                                    <div className="col"><strong>Total</strong></div>
                                                    <div className="col"><strong>Puntos</strong></div>    
                                                    <div className="col"></div>
                                                    
                                                </div>
                                            )
                                            :

                                            (GetUserCookie().Sucursal.configPOS.columnasExtraOrden === 2) ?
                                                (<div className="row">
                                                    <div className="col-sm-2" style={this.Estilos.Style4Text}><strong>Cant</strong></div>
                                                    <div className="col-sm-2" style={this.Estilos.Style5Text}><strong>Codigo</strong></div>
                                                    <div className="col-sm-2" style={this.Estilos.Style6Text}><strong>Sku</strong></div>
                                                    <div className="col-sm-2" style={this.Estilos.Style5Text}><strong>Sku2</strong></div>
                                                    <div className="col-sm-2" style={this.Estilos.Style1Text}><strong>Producto</strong></div>
                                                    <div className="col-sm-3" style={this.Estilos.Style2Text}><strong>Unitario</strong></div>
                                                    <div className="col-sm-2" style={this.Estilos.Style3Text}><strong>Total</strong></div>
                                                </div>)
                                                :
                                                (null)

                                    }


                                    {
                                        this.state.orden.carroProductos.map((item, index) => {
                                            if (item.precpred === 0 && !item.isPesable && this.UsedNotaCreditoID ===0) {
                                                return;
                                            }
                                            
                                            var descripciones = item.desprod.split(';');
                                            var codigoProductoS = "";
                                            var skuProductoS = "";
                                            var descripProductoS = "";
                                            if (descripciones.length >= 3) {
                                                codigoProductoS = descripciones[0];
                                                skuProductoS = descripciones[1];
                                                descripProductoS = descripciones[2];
                                            } else {
                                                codigoProductoS = descripciones[0];
                                                descripProductoS = descripciones[1];
                                            }
                                            
                                            const valueOffer = item.valueOffer
                                            
                                            var precioTotal = (precioTotal + item.precpred * item.cantidad) - (item.valueOffer? item.valueOffer:0)
                                            var cantidad = item.cantidad;
                                            var desprod = item.desprod;

                                             
                                            
                                            var precioFormateadoAux = ((item.precpred * item.cantidad) - (item.isDescItem?item.descItem:item.valueOffer? item.valueOffer:0));
                                            var PrecioFormateadoRound = precioFormateadoAux > 0 ? GetNumeroMiles(Math.round(precioFormateadoAux)) : 0;
                                            var PrecioFormateado = GetPrecioFormateado(PrecioFormateadoRound);
                                            var PrecioPredFormateado = GetPrecioFormateado(item.precpred);
                                            var descuentoPor = '';
                                            var PropPor = '';
                                            var DescPor = '';
                                            var PropTot = 0;
                                            var DescTot = 0;
                                            var modificadores = [];
                                            var puntos = GetNumeroMiles(item.puntosAcumula);
                                            
                                            if (item.descuentoPorc > 0) {
                                                descuentoPor = ' ' + item.descuentoPorc + '% (';
                                                descuentoPor = descuentoPor + GetPrecioFormateado(Math.round((item.precpred * item.cantidad) * (item.descuentoPorc / 100))) + ')';
                                            }
                                            if (item.modificadores) {
                                                item.modificadores.forEach(function (valor) {
                                                    modificadores.push(valor);
                                                });
                                            }
                                            if (this.state.orden.propinaPorc) {
                                                PropPor = this.state.orden.propinaPorc + '% propina' + GetPrecioFormateado(Math.round((this.state.precioTotal * (this.state.orden.propinaPorc / 100))));
                                                PropTot = GetPrecioFormateado(Math.round((this.state.precioTotal * (this.state.orden.propinaPorc / 100))));
                                            } else if (this.state.propinaPorc) {
                                                PropPor = this.state.propinaPorc + '% propina ' + GetPrecioFormateado(Math.round((this.state.precioTotal * (this.state.propinaPorc / 100))));
                                                PropTot = GetPrecioFormateado(Math.round((this.state.precioTotal * (this.state.propinaPorc / 100))));
                                            } else if (this.state.orden.propinaCant) {
                                                PropPor = 'propina ' + GetPrecioFormateado(this.state.orden.propinaCant);
                                                PropTot = GetPrecioFormateado(this.state.orden.propinaCant);
                                            } else if (this.state.orden.propinaDiff) {
                                                PropPor = 'propina $' + this.state.orden.propinaDiff - this.GetTotal();
                                                PropTot = GetPrecioFormateado(this.state.orden.propinaDiff - this.GetTotal());
                                            }
                                            if (this.state.orden.dctoOrden > 0) {
                                                console.log(this.state.precioTotalDescuentoOferta, 'preciototaldescuentooferta')
                                                DescPor = this.state.orden.dctoOrden + '%  descuento  ($' + Math.round((this.state.precioTotalDescuentoOferta - this.getDescItem()) * (this.state.orden.dctoOrden / 100)) + ')';
                                                DescTot = Number(Math.round(this.state.precioTotalDescuentoOferta * (this.state.orden.dctoOrden / 100)));
                                            }
                                            this.Propina = PropPor;
                                            this.Descuentos = DescPor;
                                            this.PrecioTotal = precioTotal;
                                             
                                            if (GetUserCookie().Sucursal.configPOS.columnasExtraOrden === 0) {
                                                return (

                                                    <div className="row" style={this.Estilos.ProdSeparacion} key={index}>
                                                        <div className="col-1">{cantidad}</div>
                                                        <div className="col-2">{codigoProductoS}</div>
                                                        <div className="col-5" style={this.Estilos.DesProdStyle}>
                                                            {descripProductoS}

                                                            {(descuentoPor !== "") ? (<p style={this.Estilos.ModSeparacion}>{descuentoPor}</p>) : (null)}
                                                        </div>
                                                        <div className="col-2 text-right" >{PrecioPredFormateado}</div>

                                                        <div className="col-2 text-right">{PrecioFormateado} </div>
                                                    </div>

                                                );
                                            } else if (GetUserCookie().Sucursal.configPOS.columnasExtraOrden === 1) {
                                                return (
                                                    <div>
                                                    <div className="row" style={this.Estilos.ProdSeparacion} key={index}>
                                                            <div className="col">{(item.isProductNC) ? (<strong>{cantidad}</strong>) : (cantidad)}</div>
                                                            <div className="col">{(item.isProductNC) ? (<strong>{codigoProductoS}</strong>) : (codigoProductoS)}</div>
                                                        {/*<div className="col">{skuProductoS}</div>*/}
                                                            <div className="col-3">
                                                                {(item.isProductNC) ? (<strong>{descripProductoS}</strong>) : (descripProductoS)}
                                                            

                                                            {(descuentoPor !== "") ? (<p style={this.Estilos.ModSeparacion}>{descuentoPor}</p>) : (null)}
                                                        </div>
                                                            <div className="col">{(item.isProductNC) ? (<strong>{PrecioPredFormateado}</strong>) : (PrecioPredFormateado)}</div>
                                                        <div className="col">

                                                                { item.isDescItem? GetNumeroMiles(Math.round(item.descItem) * -1) : valueOffer>0? GetNumeroMiles(Math.round(valueOffer)*-1):0 }
                                                                {  
                                                                
                                                                   item.isDifcambio || item.isProductNC || this.state.descItemConfig === 'false'? 
                                                                    (null):(<a style={{marginLeft: 5 + 'px'}} onClick={(e) => { e.preventDefault(); this.DescItemClickHandler(item)}} href>
                                                                            <i class="fa fa-edit fa-1x" data-toggle="tooltip" data-placement="right" title="Aplica Descuento a Total de Item Seleccionado (Total = precio de producto * cantidad)"></i>
                                                                    </a>) 
                                                                 }
                                                        </div>

                                                            <div className="col">{(item.isProductNC) ? (<strong>{PrecioFormateado}</strong>) : (PrecioFormateado)}</div>

                                                        <div className="col">{puntos} </div>
                                                        
                                                        {
                                                        item.isProductNC? (<div className="col"></div>):

                                                            (<div className="col">
                                                    
                                                             {
                                                                item.isDifcambio ? (null):
                                                                (<a style={this.Estilos.EditCarrito}  onClick={(e) => { e.preventDefault(); this.ClickMas(index) }} href>
                                                                <i className="fa fa-plus-circle fa-2x text-dark" aria-hidden="true"></i>
                                                                </a> )
                                                             }   
                                                            
                                                        
                                                            {
                                                                item.isDifcambio ? (null):
                                                                ( <a style={this.Estilos.EditCarrito}  onClick={(e) => { e.preventDefault(); this.ClickMenos(index) }} href>
                                                                <i className="fa fa-minus-circle fa-2x text-danger" aria-hidden="true"></i>
                                                                  </a>  )
                                                            }
                                                           
                                                   
                                                             <a style={this.Estilos.EditCarrito}  title='Eliminar producto' onClick={(e) => { e.preventDefault(); this.DesactivarModales(); this.ClickEliminar(index) }} href="">
                                                                <i className="fa fa-trash-o fa-2x text-danger" aria-hidden="true"></i>
                                                            </a>   


                                                        </div>)
                                                        
                                                        }                   
                                                        
                                                    </div>
                                                    <div style={this.Estilos.linea}></div>
                                                    </div>

                                                );
                                            } else if (GetUserCookie().Sucursal.configPOS.columnasExtraOrden === 2) {
                                                return (

                                                    <div className="row" style={this.Estilos.ProdSeparacion} key={index}>
                                                        <div className="col-1">{cantidad}</div>
                                                        <div className="col-2">{codigoProductoS}</div>
                                                        <div className="col-2">{skuProductoS}</div>
                                                        <div className="col-2">{skuProductoS}</div>
                                                        <div className="col-5" style={this.Estilos.DesProdStyle}>
                                                            {descripProductoS}

                                                            {(descuentoPor !== "") ? (<p style={this.Estilos.ModSeparacion}>{descuentoPor}</p>) : (null)}
                                                        </div>
                                                        <div className="col-2 text-right" >{PrecioPredFormateado}</div>

                                                        <div className="col-2 text-right">{PrecioFormateado} </div>
                                                    </div>

                                                );
                                            }


                                        })
                                    }

                                    <div style={this.Estilos.ModSeparacionFoot}>
                                        <p style={this.Estilos.ModSeparacionFP}>{this.Propina}</p>
                                        <p style={this.Estilos.ModSeparacionFP}>{this.Descuentos}</p>
                                        {
                                            (this.state.orden.descEfectivo) ? (<p style={this.Estilos.ModSeparacion}>Descuento {GetPrecioFormateado(this.state.orden.descEfectivo)}</p>) : (null)
                                        }
                                    </div>
                                    <div style={{ float: "left", clear: "both" }}
                                        ref={(el) => { this.messagesEnd = el; }}>
                                    </div>

                                </div>

                            </Card>
                            {/* <Card className="align-middle" style={this.myFixedWidthServices}> */}
                            <Card>
                                {/* <div className="align-self-center" style={this.column4}> */}
                                    {/* <div className="d-flex d-inline-flex" style={this.Estilos.MenuConsultas}> */}
                                    <div className="text-center mt-2" style={this.Estilos.MenuConsultas}>
                                        <button className="btn" style={this.Estilos.MenuConsultaBoton} onClick={() => { this.ClickConsultaCliente() }}><strong>Identificar <u>C</u>liente</strong>
                                            <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-bounding-box" viewBox="0 0 16 16">
                                                <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                            </svg></div>
                                        </button>
                                    {(this.state.ordenesQty == 0) ? null : this.GetOrders()}
                                    {(this.state.ecommerceQty == 0) ? null : this.GetOrdersWeb()}
                                    {(this.state.reservasQty == 0) ? null : this.GetOrdersReserva()}

                                    {/*ordenesQty: 0,*/}
                                    {/*ecommerceQty: 0,*/}
                                    {/*reservasQty: 0,*/}
                                    {/*{this.GetOrdersWeb()}*/}
                                    {/*{this.GetOrdersReserva()}*/}

                                        <button className="btn" style={this.Estilos.MenuConsultaBoton} onClick={() => { this.ClickConsultarStock() }}><strong>Consulta <u>S</u>tock</strong>
                                            <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grid-3x3-gap" viewBox="0 0 16 16">
                                                <path d="M4 2v2H2V2h2zm1 12v-2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm5 10v-2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM9 2v2H7V2h2zm5 0v2h-2V2h2zM4 7v2H2V7h2zm5 0v2H7V7h2zm5 0h-2v2h2V7zM4 12v2H2v-2h2zm5 0v2H7v-2h2zm5 0v2h-2v-2h2zM12 1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zm-1 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2z" />
                                            </svg></div>
                                        </button>
                                        <button className="btn" id="BtnBoletaP" style={this.Estilos.MenuConsultaBoton} onClick={() => { this.setBoletaClick() }}><strong><u>B</u>oleta </strong>
                                            <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                                                <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                                                <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z" />
                                            </svg></div>
                                        </button>
                                        <button className="btn" id="BtnFacturaP" style={this.Estilos.MenuConsultaBoton} onClick={() => { this.setFacturaClick() }}><strong><u>F</u>actura</strong>
                                            <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-receipt" viewBox="0 0 16 16">
                                                <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                                                <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                                            </svg></div>
                                        </button>
                                    </div>
                                {/* </div> */}
                            </Card>

                        </div>
                        <div className="col-lg-2 col-md-5 col-sm-6 pr-0 pl-0">
                            
                            <Card className={(this.state.activarPopUpConsultaStock || this.state.activarPopUpReimprimirHten || this.state.activarPopUpStatusQR)  ? 'shadow-lg mt-1 primerPlano' : 'shadow-lg mt-1'}>
                                <div>
                                    {this.GetHtmlTecladoRetail()}
                                </div>
                            </Card>
                            <Card className="shadow-lg">
                                <div className="mt-2 mb-2" style={this.Estilos.FondoBlanco} >
                                    <div style={this.Estilos.ContentSubtotal}>
                                        <div className="">
                                            <div className="ml-2 d-inline-flex text-left col-6" ><strong> IVA: </strong></div>
                                            <div className="d-inline-flex text-right"><strong>{GetPrecioFormateado(this.GetIVA())}</strong></div>
                                        </div>
                                        <div className="">
                                            <div className="ml-2 d-inline-flex text-left col-6" ><strong> SubTotal: </strong></div>
                                            <div className="d-inline-flex text-right"><strong>{GetPrecioFormateado(this.GetSubTotal())}</strong></div>
                                        </div>
                                        <div className="">
                                            <div className="ml-2 d-inline-flex text-left col-6" ><strong> Desc Caja: </strong></div>
                                            <div className="d-inline-flex text-right"><strong>{GetPrecioFormateado(this.GetDescuento())}</strong></div>
                                        </div>
                                        <div className="">
                                            <div className="ml-2 d-inline-flex text-left col-6" ><strong> Desc Oferta: </strong></div>
                                            <div className="d-inline-flex text-right"><strong>{GetPrecioFormateado(this.getDescPromo())}</strong></div>
                                        </div>
                                        <div className="">
                                            <div className="ml-2 d-inline-flex text-left col-6" ><strong> Abonado: </strong></div>
                                            <div className="d-inline-flex text-right"><strong>{GetPrecioFormateado(this.GetAbonado())}</strong></div>
                                        </div>
                                        <div className="text-align: center"  >
                                            {/*<div className="ml-2 d-inline-flex text-left col-6" ><strong> Total: </strong></div>
                                            <div className="d-inline-flex text-right"><strong>{GetPrecioFormateado(this.GetTotal())}</strong></div>*/}

                                                {
                                                    (this.IsPagarHabilitado) ?
                                                        (
                                                            <div>
                                                              <button name="blokCambios" className="btn btn-dark" hidden="hidden" onClick={() => { this.BotonModificar() }} style={this.Estilos.btnEditarCarrito} id="BotonModificar">Editar Carrito <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                                                             
                                                              <button name="blokCambios" id="btnPagar" onClick={() => { this.ClickPagar() }} className="btn btn-success"  style={this.Estilos.BtnPagar}
                                                                 data-toggle="tooltip"  title={this.PayForm}><strong>Pagar {GetPrecioFormateado(this.GetTotal())} </strong><i className="fa fa-angle-right" aria-hidden="true"> </i></button>
                                                            </div>
                                                        )
                                                        : (
                                                            <button name="blokCambios" id="btnPagar" onClick={() => { this.ClickPagar() }} disabled='disabled' className="btn btn-success" style={this.Estilos.BtnGroup}
                                                                data-toggle="tooltip" data-placement="right" title={this.PayForm}><i className="fa fa-ban fa-2x" aria-hidden="true"></i></button>

                                                        )
                                            }


                                        </div>
                                        {
                                            (this.UsedNotaCreditoID != 0) ?
                                            (<div className="">
                                                <div className="ml-2 d-inline-flex text-left col-3" ><strong>Dif. NC:</strong></div>
                                                <div className="d-inline-flex text-right  col-5"><strong>{GetPrecioFormateado(this.GetDiferenciaTotalvsNotaCredito())}</strong></div>
                                                    <div className="d-inline-flex text-right  col-3">
                                                        {(this.GetCantidadProductosTotales() > 0 && this.GetDiferenciaTotalvsNotaCredito() < 0) ?
                                                        (<button name="blokCambios" id="btnNetearNC" onClick={() => { this.NetearNCConVentaBajoMonto() }} className="btn btn-success" style={this.Estilos.BtnNetear}
                                                            data-toggle="tooltip" title={this.PayForm}><i className="fa fa-level-up fa-2x" aria-hidden="true"> </i></button>):(null)
                                                        }
                                                    </div>
                                            </div>
                                            ) :
                                            (null)
                                        }

                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                </Card>
                <Card className="text-center myVirtualKeyboard">
                    {this.GetVirtualKeyBoard()}
                </Card>
                <Card>
                    <div className="text-center mt-2" style={this.Estilos.FooterStyles}>
                        <button className="btn btn-danger" onClick={() => { this.ClickBorrarOrden() }} style={this.BtnSalir}><i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                        <button name="blokCambios" className="btn btn-dark" onClick={() => { this.setState({ activarCPrecio: true }) }} style={this.Estilos.btnOptInferior} hidden="hidden">Cambiar Precio</button>
                        
                        {(this.state.Ambiente!= 'MAISA')?
                             (<button name="blokCambios" className="btn btn-dark" onClick={() => { this.setState({ activarPopUpConsultaDeServicio: true }) }} style={this.Estilos.btnOptInferior} >Consultar Servicios <i class="fa fa-wrench" aria-hidden="true"></i></button>) :
                              null
                        }
                        
                        
                        <button name="blokCambios" id="LimpiarVentana" className="btn btn-dark" onClick={() => { this.LimpiarVentana() }} style={this.Estilos.btnOptInferior} >Limpiar <u>V</u>entana <i class="fa fa-recycle" aria-hidden="true"></i></button>
                        
                        { this.getHtmlHtenImprimir() }
                        { this.getHtmlHtenUltimo() }
                        { this.getHtmlCierreTbk() }
                        { this.getHtmlStatusQR() }

                        <button name="blokCambios" className="btn btn-dark" onClick={() => { this.ClickTransferirReal() }} style={this.Estilos.btnOptInferior} hidden="hidden">Transferir</button>
                        <button name="blokCambios" className="btn btn-dark" onClick={() => { this.setState({ activarCantidad: true }) }} style={this.Estilos.btnOptInferior} hidden="hidden" >Cantidad</button>
                        <button name="blokCambios" className="btn" style={this.Estilos.btnOptInferior} onClick={() => { this.ClickAnular() }} hidden="hidden" >Anular</button>
                        {/* <button name="blokOfertas" className="btn btn-dark" onClick={() => { this.ClickDescOffers() }} style={this.Estilos.btnOptInferior}  >Descuentos - <u>O</u>fertas <i class="fa fa-money" aria-hidden="true"></i></button>                                 */}
                        {(this.state.descGlobalConfig === 'true')?
                            (this.state.IsDescuentoRealizado) ?
                            (<button name="blokCambios" className="btn btn-dark" onClick={() => { this.QuitarDescuento() }} style={this.Estilos.btnOptInferior}  >Quitar Descuentos</button>)
                            :
                            (<button id='btnDescCaja' name="blokCambios" className="btn btn-dark" onClick={() => { this.ClickDescuentos() }} style={this.Estilos.btnOptInferior}  >Descuento Caja <i class="fa fa-percent" aria-hidden="true"></i></button>)
                            : (null)
                        }

                        {/* <button name="blokOfertas" className="btn btn-dark" onClick={() => { this.ClickOfertaConsultar() }} style={this.Estilos.btnOptInferior}  >Consultar Ofertas <i class="fa fa-money" aria-hidden="true"></i></button> */}
                        <button name="blokActGiftcard" className="btn btn-dark" onClick={() => { this.ClickActGiftcard() }} style={this.Estilos.btnOptInferior}  >Activar Giftcard <i class="fa fa-credit-card" aria-hidden="true"></i></button>
                        <button name="blokGiftcard" className="btn btn-dark" onClick={() => { this.ClickGiftcardConsultar() }} style={this.Estilos.btnOptInferior}  >Consultar <u>G</u>iftcard <i class="fa fa-credit-card" aria-hidden="true"></i></button>
                        <button name="blokCambios" className="btn btn-dark" onClick={() => { this.BotonRegalo() }} style={this.Estilos.btnOptInferior} id="BotonRegalo"><u>R</u>egalo <i class="fa fa-gift" aria-hidden="true"></i></button>

                        <button name="blokCambios" id="btnImprimir" className="btn btn-dark" onClick={() => { this.Reimprimir() }} style={this.Estilos.BtnGroup1}
                            data-toggle="tooltip" data-placement="right" title="Listo">
                            <i class="fa fa-print" aria-hidden="true"></i></button>
                        {/*<button name="blokCambios" id="btnListo" className="btn btn-success" onClick={() => { this.DejarPendiente() }} style={this.Estilos.BtnGroup}
                            data-toggle="tooltip" data-placement="right" title="Listo">
                        <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>

                        {
                            (this.IsPagarHabilitado) ?
                                (<button name="blokCambios" id="btnPagar" onClick={() => { this.ClickPagar() }} className="btn btn-success" style={this.Estilos.BtnGroup}
                                    data-toggle="tooltip" data-placement="right" title={this.PayForm}><i className="fa fa-usd fa-2x" aria-hidden="true"></i></button>
                                )
                                : (
                                    <button name="blokCambios" id="btnPagar" onClick={() => { this.ClickPagar() }} disabled='disabled' className="btn btn-success" style={this.Estilos.BtnGroup}
                                        data-toggle="tooltip" data-placement="right" title={this.PayForm}><i className="fa fa-ban fa-2x" aria-hidden="true"></i></button>

                                )
                        }*/}

                        <button name="blokCambios" id="btnNuevaOrden" onClick={() => { this.ClickNuevaOrden() }} className="btn btn-success" style={this.Estilos.BtnGroup}
                            data-toggle="tooltip" data-placement="right" title="Nueva Orden" hidden="hidden"><i className="fa fa-plus-square-o fa-2x" aria-hidden="true"></i></button>
                        <button name="blokCambios" id="btnImprimir" onClick={() => { this.ClickImprimir2(this.IdVenta) }} className="btn btn-success" style={this.Estilos.BtnGroup}
                            data-toggle="tooltip" data-placement="right" title="Imprimir" hidden="hidden"><i className="fa fa-print fa-2x" aria-hidden="true"></i></button>
                       
                    </div>
                </Card>
            </div>
        );
        
    }
}
