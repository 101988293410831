import React, { Component } from 'react';
import { Route } from 'react-router';
import { EntradaCajero } from './EntradaCajero';
import { ParaLlevar } from './ParaLlevar';
import { ParaLlevarDos } from './ParaLlevarDos';
import { SalidaCajero } from './SalidaCajero';
import { ReporteProdVentas } from './ReporteProdVentas';
import { Reportes } from './Reportes';
import { OtrosPagos } from './OtrosPagos';
import { SalidasDinero } from './SalidasDinero';
import { IngresosDinero } from './IngresosDinero';
import { Config } from './Config';
import { NavbarDefault } from './NavbarDefault';
import { LoginPin } from './LoginPin';
import { Mesas } from './Mesas';
import { OrdenesDos } from './OrdenesDos';
import { ResumenInformeVentas } from './ResumenInformeVentas';
import { Indicadores } from './Indicadores';
import { CorteDeCaja } from './CorteDeCaja';
import { OrdenMesa } from './OrdenMesa';
import { CombinarOrden } from './CombinarOrden';
import { ArqueoModificarMedios } from './ArqueoModificarMedios';
import { KitchenDisplay } from './KitchenDisplay';
import { MantenedorUsuarios } from './MantenedorUsuarios';
import { MantenedorProductosDos } from './MantenedorProductosDos';
import { MantenedorCategorias } from './MantenedorCategorias';
import { MantenedorCuentasCasa } from './MantenedorCuentasCasa';
import { MantenedorDescuentos } from './MantenedorDescuentos';
import { MesasAmbientes } from './MesasAmbientes';
import { MantenedorModificadores } from './MantenedorModificadores';
import { MantenedorModificadoresMaestro } from './MantenedorModificadoresMaestro';
import { MantenedorPromociones } from './MantenedorPromociones';
import { ReporteVentasPorPlatillo } from './ReporteVentasPorPlatillo';
import { MantenedorFamilias } from './MantenedorFamilias/MantenedorFamilias';
import { MantenedorGrupos } from './MantenedorGrupos/MantenedorGrupos';
import { ReporteListadoDeOrdenes } from './ReporteListadoDeOrdenes';
import { NotaCredito } from './NotaCredito';
import { NotaCreditoDos } from './NotaCreditoDos';
import { MantenedorMotivosNotaCredito } from './MantenedorMotivosNotaCredito';
import { Venta } from './Venta';
import { GetUserCookie } from './Globales/Utilidades';
export class Fbhpos extends Component {//wraper para todos los componentes 
    static displayName = Fbhpos.name;
    constructor() {
        super();
        this.state = {
            mostrarTeclado: true
        }
        this.CerrarModalMaster = this.CerrarModalMaster.bind(this);
    }


    FullScreen = {
        'height ': '100%',
        'width': '100%'
    }

    CerrarModalMaster() {
        this.setState({ mostrarTeclado: false });
    }

    render() {
        return (
            <div style={this.FullScreen}>
              { (GetUserCookie().id > 0) ? ( <NavbarDefault />) : null}
                <Route exact path='/' component={LoginPin} />
                <Route exact path='/ParaLlevar' component={ParaLlevar} />
                <Route exact path='/ParaLlevarDos' component={ParaLlevarDos} />
                <Route exact path='/EntradaAlCajero' component={EntradaCajero} />
                <Route exact path='/SalidaCajero' component={SalidaCajero} />
                <Route exact path='/ProductosVenta' component={ReporteProdVentas} />
                <Route exact path='/Reportes' component={Reportes} />
                <Route exact path='/revisar' component={OrdenesDos} />
                <Route exact path='/OtrosPagos' component={OtrosPagos} />
                <Route exact path='/SalidasDinero' component={SalidasDinero} />
                <Route exact path='/IngresosDinero' component={IngresosDinero} />
                <Route exact path='/administracion' component={Config} />
                <Route exact path='/Indicadores' component={Indicadores} />
                <Route exact path='/ResumenVentas' component={ResumenInformeVentas} />
                <Route exact path='/CortesCajas' component={CorteDeCaja} />
                <Route exact path='/Mesas' component={Mesas} />
                <Route exact path='/OrdenesMesa' component={OrdenMesa} />
                <Route exact path='/Combinar' component={CombinarOrden} />
                <Route exact path='/TodosLosMedios' component={ArqueoModificarMedios} />

                <Route exact path='/Usuarios' component={MantenedorUsuarios} />
                <Route exact path='/Productos' component={MantenedorProductosDos} />
                <Route exact path='/Categorias' component={MantenedorCategorias} />
                <Route exact path='/CuentasCasa' component={MantenedorCuentasCasa} />
                <Route exact path='/Descuentos' component={MantenedorDescuentos} />
                <Route exact path='/MesasAmbientes' component={MesasAmbientes} />
                <Route exact path='/Modificadores' component={MantenedorModificadores} />
                <Route exact path='/MaestroModificadores' component={MantenedorModificadoresMaestro} />
                <Route exact path='/Promociones' component={MantenedorPromociones} />
                <Route exact path='/Familias' component={MantenedorFamilias} />
                <Route exact path='/Grupos' component={MantenedorGrupos} />
                <Route exact path='/ReporteOrdenes' component={ReporteListadoDeOrdenes} />
                <Route exact path='/NotaCredito' component={NotaCreditoDos} />
                <Route exact path='/MantenedorMotivosNotaCredito' component={MantenedorMotivosNotaCredito} />


                <Route exact path='/kds' component={KitchenDisplay} />
                <Route exact path='/reporteVentasPlatillo' component={ReporteVentasPorPlatillo} />

                <Route exact path='/Venta' component={Venta} />
            </div>
        );
    }
}
