import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import swal from 'sweetalert';
import ReactToExcel from 'react-html-table-to-excel';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';


export class CorteDeCaja extends Component {
    static displayName = CorteDeCaja.name;

    constructor() {
        super();
        this.state = {
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false,
            cargadatos: false,
            resultado: [],
            VerTabla: false,
            SucursalesOpt: [],
            SucSelected: 0
        }
        this.TraerSucursales();
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/GetSucursales', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ SucursalesOpt: datos });
        }
    }

    Estilos = {
        FondoDefault: {
            'backgroundColor': '#ecf0f5',
            'heigth': '100%',
        },
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '100%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '250px',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '100%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        BordesCard:
        {
            'borderRadius': '25px'
        },
        TarjetaEstadisticas2: {
            'width': '250px',
            'backgroundColor': 'white',
            'height': '350px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '50px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '40px',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '100%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            'textAlign': 'left'
        },
        TdStyleValues: {
            'textAlign': 'center'
        },
        AnchoBox: {
            'width': '350px'
        }, AvgStyle:
        {
            'color': 'red'
        },
        AvgStylePlus:
        {
            'color': 'Green'
        },
        AvgStyleEquals:
        {
            'color': '#F9810A'
        },
        AnchoBoxGraf: {
            'width': '1000px',
            'borderRadius': '25px',
            'margin-left': '-3%'

        },
        BtnExcel:
        {
            'width': '50px',
            'height': '50px',
            'border-radius': '8px',
            'background': '#6e9887',
            'color': '#fff'
        },
        BtnExcelDos:
        {
            'width': '50px',
            'height': '50px',
            'border-radius': '8px',
            'background':'rgb(166 204 191)',
            'color': 'rgb(255, 255, 255)',
            'marginLeft': '1%'
        }
    }
    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('fechaFiltro').value = today;
        document.getElementById('fechaFiltroHasta').value = today;
        document.getElementById('fechaini').value = today;
        document.getElementById('fechafin').value = today;
    }
    Redirigir(url) {
        this.props.history.push(url);
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuario.id != 1) {
            Logout();
            this.Redirigir('/');
        } else {
            this.cargarFechas();
            this.TraerTotalVentasPorFecha()
            const width = window.screen.availWidth;

            const height = window.screen.availHeight;

            if (width < 1000) {

                this.HacerResponsive();

            }
        }
        

    }



    HacerResponsive() {

        var elements = Array.from(document.getElementsByTagName("*"));
        elements.forEach((item) => {
            item.style.width = '100%';
        });

    }
    async LimpiarState()
    {
        this.setState({ resultado:[], VerTabla: false, cargadatos: true })

    }
    async TraerTotalVentasPorFecha() {
        try {

            await this.LimpiarState();
            //this.setState({ cargadatos: true });
            var data = {};
            var sucID = document.getElementById('SucId').value;
            document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;
            document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
            data.fechaIni = document.getElementById('fechaFiltro').value;
            data.TipoComparativo = "Semana";
            data.fechaFin = document.getElementById('fechaFiltroHasta').value;
            data.Sucursal = sucID;
            var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/PostCorteCaja', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                throw { mensaje: 'problema al traer las ventas' };

            } else {
                var datos = await respuesta.json();
                this.setState({ resultado: datos.corteDeCaja, VerTabla: true, cargadatos: false })
                console.log(datos);
            }
        } catch (err) {
            swal('error en TraerTotalVentasPorFecha ' + err);
        }
    }
    GenerarPdfCierre(IDCierre)
    {
        window.open(GetUrlApi() + '/api/documentos/arqueoCaja?id=' + IDCierre, '_blank');
    }
    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;

    }
    convertToCsv(fName, rows) {
        var csv = '';
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            for (var j = 0; j < row.length; j++) {
                var val = row[j] === null ? '' : row[j].toString();
                val = val.replace(/\t/gi, " ");
                if (j > 0)
                    csv += '\t';
                csv += val;
            }
            csv += '\n';
        }
        var cCode, bArr = [];
        bArr.push(255, 254);
        for (var i = 0; i < csv.length; ++i) {
            cCode = csv.charCodeAt(i);
            bArr.push(cCode & 0xff);
            bArr.push(cCode / 256 >>> 0);
        }

        // for UTF-16


        var blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fName);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                var url = window.URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        }
    }
    async ExportarExcel(idApertura,idCierre) {
        var s = idApertura;
        var a = idCierre;
        console.log(s);
        console.log(a);
        var data = {};
        data.idApertura = idApertura;
        data.idCierre = idCierre;
        var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/GetMediosPagoCierre', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!respuesta.ok) {
            throw { mensaje: 'problema al traer las ventas' };

        } else {
            var datos = await respuesta.json();
            var arr = [["Numero de orden", "Descripcion medio pago", "Fecha","Total","Propina","Descuento"]];
            //var arr = [];
            console.log(datos);
            datos.forEach(function (item) {
                var nuevoArray = [];
                nuevoArray.push(item.numOrden);
                nuevoArray.push(item.desmed);
                nuevoArray.push(new Date(item.fecha).toLocaleDateString());
                nuevoArray.push(String( item.totalReal));
                nuevoArray.push(String(item.montoPropina));
                nuevoArray.push(String(item.descOrden));
                arr.push(nuevoArray);

            });

            console.log(arr);
            const nombreArchivoExport = "MediosDePagoCierre" + "_n° " + idCierre + "_" + new Date().toLocaleDateString() + ".csv";
            this.convertToCsv(nombreArchivoExport, arr);
          
            
            
        }
    }


 
    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }
    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <div>
                    <table style={this.Estilos.FullAncho} >
                        <thead>
                            <th colSpan="3">Ventas Diarias</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                            </tr>
                            <tr>
                                <td>
                                    <select id="SucId" className="form-control">
                                        <option value="0">Todas</option>
                                        {
                                            this.state.SucursalesOpt.map((item, index) => {
                                                return (<option value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde() }} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta() }} className="form-control" type="date" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
 


            <hr />
            <div className="row">
                    <div className="col-lg-5">
                        <input type="text" id="fechaini" hidden ></input>
                        <input type="text" id="fechafin" hidden></input>
                    </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.TraerTotalVentasPorFecha() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br />
                <div>
                    <center>
                        {
                            (this.state.cargadatos) ? (
                                <div style={this.Estilos.FondoDefault}>
                                    <center>
                                        <div className="spinner-border text-dark" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <p> Cargando...</p>
                                    </center>
                                </div>
                            ) : (null)
                        }
                    </center></div>

                <br />

                    <div style={this.Estilos.Bordes}>
                        {  (this.state.VerTabla) ? (
                        <div className="container">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card" style={this.Estilos.AnchoBoxGraf}>

                                        <div className="card-body">
                                       
                                            {
                                                this.state.resultado.map((item, i) => {

                                                    return (

                                                        <div className="tab-content mt-3">
                                                            <div className="card-title text-left">
                                                                <button className="btn" style={this.Estilos.BtnExcel} onClick={() => { this.ExportarExcel(item.idApertura, item.idCierre) }}>
                                                                    <i className="fa fa-file-excel-o fa-2x" aria-hidden="true"></i></button>
                                                                <button className="btn" style={this.Estilos.BtnExcelDos} onClick={() => { this.GenerarPdfCierre(item.idCierre) }}>
                                                                    <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                                                                </button>

                                                            </div>

                                                            <table  className="table table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="3">Sucursal: {item.sucursal}</th>
                                                                        <th colSpan="3">Fecha Apertura: {item.fechaApertura}  {item.horaApertura} </th>
                                                                        <th colSpan="3">Fecha Cierre: {item.fechaCierre}  {item.horaCierre}</th>
                                                                        <th colSpan="3">Gaveta: {item.gaveta} </th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                            <br />
                                                            <table className="table table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Fecha</th>
                                                                        <th>Cajero</th>
                                                                        <th>Inicio</th>
                                                                        <th>Fin</th>
                                                                        <th>Fondo Caja</th>
                                                                        <th>Cobrado</th>
                                                                        <th>Propina</th>
                                                                        <th>Venta</th>
                                                                        <th>Recaudado</th>
                                                                        <th>Diferencia</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{item.fechaApertura}</td>
                                                                        <td>{item.cajero}</td>
                                                                        <td>{item.horaApertura}</td>
                                                                        <td>{item.horaCierre}</td>
                                                                        <td>{GetPrecioFormateado(item.fondoCaja)}</td>
                                                                        <td>{GetPrecioFormateado(item.cobrado)}</td>
                                                                        <td>{GetPrecioFormateado(item.propina)}</td>
                                                                        <td>{GetPrecioFormateado(item.venta)}</td>
                                                                        <td>{GetPrecioFormateado(item.declarado)}</td>

                                                                        <td>{GetPrecioFormateado(item.diferencia)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table className="table table-responsive">
                                                                <thead>
                                                                    <th>Pagos</th>
                                                                    <th>Cobrado</th>
                                                                    <th>Propina</th>
                                                                    <th>Ventas</th>
                                                                    <th>Recaudado</th>
                                                                    <th>Diferencia</th>
                                                                </thead>
                                                            {
                                                                item.mediosCierre.map((items, n) => {
                                                                    return (
         
                                                                            <tbody>
                                                                                <td>{items.pagos}</td>
                                                                                <td>{GetPrecioFormateado(items.cobrado)}</td>
                                                                                <td>{GetPrecioFormateado(items.propina)}</td>
                                                                                <td>{GetPrecioFormateado(items.venta)}</td>
                                                                                <td>{GetPrecioFormateado(items.declarado)}</td>
                                                                            {(items.diferencia < 0) ?
                                                                                (<td style={this.Estilos.AvgStyle}>{GetPrecioFormateado(items.diferencia)}</td>)
                                                                                : (<td>{GetPrecioFormateado(items.diferencia)}</td>)}
                                                                            
                                                                            </tbody>

                                                                        
                                                                        
                                                                        );

                                                                })

                                                                }
                                                                <tfoot>
                                                                    <td><b>Totales</b></td>
                                                                    <td><b>{GetPrecioFormateado(item.mediosCierreTotales.totalCobrado)}</b></td>
                                                                    <td><b>{GetPrecioFormateado(item.mediosCierreTotales.totalPropina)}</b></td>
                                                                    <td><b>{GetPrecioFormateado(item.mediosCierreTotales.totalVenta)}</b></td>
                                                                    <td><b>{GetPrecioFormateado(item.mediosCierreTotales.totalDeclarado)}</b></td>
                                                                    <td><b>{GetPrecioFormateado(item.mediosCierreTotales.totalDiferencia)}</b></td>
                                                                </tfoot>
                                                            </table>
                                                            <br/>
                                                        </div>





                                                    );
                                                })
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
 
                        ) : (null)}</div> 
            </div>



        );
    }

}