import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetUserCookie, GetPrecioFormateado } from './Globales/Utilidades';



export class ProdPesables extends Component {
    static displayName = ProdPesables.name;
    idGrupo;
    Producto = {};
    isTraerPeso = false;
    IntervalPeso = null;
    codigoBalanza;
    urlParams;
    constructor(props) {
        super(props);
        this.state = {
            indiceModif: 0,
            listaModfSeleccionados: [],
            cargandoModificadores: true,
            PesoProd: 0,
            desprod:""
        }
        this.CerrarModalMaster = props.CerrarModalMaster;
    }

    componentDidMount() {
        this.isTraerPeso = true;
        this.Producto = this.props.prod;//contene el producto que fue clickeado
        this.setState({ desprod: this.Producto.desprod });
        this.codigoBalanza = GetUserCookie().Sucursal.configPOS.balanzaApp;
        this.TraerPesoWraper();
        
    }

    TraerPesoWraper() {
        if (this.codigoBalanza === 'E01') {
            this.IntervalPeso = setInterval(() => { this.TraerPesoNotificacion() }, 1000);
        }
        else {
            this.IntervalPeso = setInterval(() => { this.TraerPesoBD() }, 1000);
        }
        
    }


    async TraerPesoNotificacion() {
        //console.log('entro');
        try {
            //const Caja = GetUserCookie().caja;
            //const categorias = await fetch(GetUrlApi() + '/api/PesajeBalanzas/GetPesoBalanza?PuntoName=' + Caja.descripcion);
            //if (!categorias.ok) {
            //    let errorMessage = { message: 'problema al traer los modificadores' };
            //    throw errorMessage;
            //}
            //var datos = await categorias.json();
            var peso = localStorage.getItem('mensaje');
            
            //await this.setState({ PesoProd: datos.pesoGramos });
            await this.setState({ PesoProd: peso });
            //setTimeout(800, function(){
            //    localStorage.setItem("mensaje", 0);
            //});
        } catch (err) {
            console.log(err.message);
        }
    }

    async TraerPesoBD() {
        //console.log('entro');
        try {
            const Caja = GetUserCookie().caja;
            const categorias = await fetch(GetUrlApi() + '/api/PesajeBalanzas/GetPesoBalanza?PuntoName=' + Caja.descripcion);
            if (!categorias.ok) {
                let errorMessage = { message: 'problema al traer los modificadores' };
                throw errorMessage;
            }
            var datos = await categorias.json();

            await this.setState({ PesoProd: datos.pesoGramos });
           
        } catch (err) {
            console.log(err.message);
        }
    }
  
    CerrarModal() {
        try {
            var modales = document.getElementsByClassName("popup-overlay");
            if (modales.length > 0)
                modales[modales.length - 1].style.visibility = "hidden";
        } catch (err) {
            console.log('ex en cierre de modal '+err);
        }
    }


    //-----INICIO Estilos
    FullAncho = {
        'width': '100%'
    }
    BtnOptionStyle=
        {
            'width': '40%',
            'margin-right': '5%',
            'margin-bottom': '1%',
            'background-color': 'rgb(118, 158, 142)',
            'height': '48px'
        }
    BtnOptionStyle2 =
        {
            'width': '40%',
            'margin-right': '3%',
            'margin-bottom': '1%',
            'height': '48px',
            'margin-left': '6%'
        }
    Estilos =
        {
            PopUpStyle:
            {
                'position': 'relative',
                'background': 'rgb(255, 255, 255)',
                'width': '50%',
                'margin': 'auto',
                'border': '1px solid rgb(187, 187, 187)',
                'padding': '5px',
                'border-radius': '8px',
                'height':'45%'
            }
        }
    

    //-----FIN ESTILOS

    

    

    async Resetear() {
        await this.setState({
            indiceModif: 0,
            listaModfSeleccionados: [],

        });
        this.TraerModificadores();
    }

    CerrarModalMaster() {
        //sera reescrita por una prop
    }

    GetPrecioTotal() {
        //if (GetUserCookie().Sucursal.configPOS.isPrecioPorZona) {
        //    var tipoVentaID = Number( localStorage.getItem('tipoVentaID'));//valor seteado en el componentDidMount de la toma de pedido
        //    if (!tipoVentaID || tipoVentaID === 0) {//!data.MesaID || data.MesaID === 0
        //        tipoVentaID = 2;
        //    }
        //    if (tipoVentaID === 1) {
        //        this.Producto.precioKg = this.Producto.precioKgMesa;
        //    }
        //    else if (tipoVentaID === 3) {
        //        this.Producto.precioKg = this.Producto.precioKgDelivery;
        //    }
        //    else if (tipoVentaID === 4) {
        //        this.Producto.precioKg = this.Producto.precioKgBar;
        //    }

        //}
        return Math.round((this.state.PesoProd * this.Producto.precioKg) / 1000);
    }
    render() {
       
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                onClose={() => { clearInterval(this.IntervalPeso); this.props.DesactivarModales(); }}
                closeOnDocumentClick>
                
                <div>
                    <center>
                        {
                            (this.state.PesoProd>0) ? (
                                <div>
                                    <p><b>Producto: {this.state.desprod}</b></p>
                                    <p><b>Precio por kilo: {GetPrecioFormateado( this.Producto.precioKg)}</b></p>
                                    <p>{this.state.PesoProd / 1000} Kilos</p>
                                    <p>El precio final es {GetPrecioFormateado( this.GetPrecioTotal())}</p>
                                </div>
                            ) : (<p>Coloque producto en balanza</p>)
                        }
                        {this.state.PesoProd}
                    </center>
                </div>
                <br />
                <hr />

                <button style={this.BtnOptionStyle2} className="btn btn-success" onClick={() => { this.ClickAgregarProducto() }}> <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>

                <button style={this.BtnOptionStyle2} className="btn btn-danger" onClick={() => { clearInterval(this.IntervalPeso); this.props.CerrarModalMaster(); }}> <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
            </Popup>


        );

    }

    ClickAgregarProducto() {
        //if (this.GetPrecioTotal() === 0) {
        //    swal('No puede ingresar peso 0, por favor comprueve la balanza');
        //    return;
        //}
        clearInterval(this.IntervalPeso);
        this.props.AgregarProductoSeleccionado(this.Producto.id, null, this.GetPrecioTotal());
        this.props.CerrarModalMaster()
    }
}
