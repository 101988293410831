import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from '../Globales/VariableGlobales';
import { GetUserCookie } from '../Globales/Utilidades';
import swal from 'sweetalert';
import Popup from "reactjs-popup";

export class PopupAgregarProducto extends Component {
    static displayName = PopupAgregarProducto.name;


    stateInicial = {
        //---->inicio estados crud categ 
        categoriaSeleccionada: {},
        sucursales: [],
        grupos:[],
        //----> fin estados crud categ 

        //---->inicio estados crud producto 
        productoClickeado: {},
        zonasImpresion: [],
        defaultIsPesableAgregar: true,
        isCrearProducto: true,
        ProductosSeleccionadosNivSup: [],
        todosSelected: false,
        //----> fin estados crud producto
        isNivelSuperiorSelected: false,

    }
    constructor(props) {
        super(props);
        this.state = this.stateInicial;
        this.CerrarModalMaster = props.CerrarModalMaster;
        this.TraerGrupos();
    }

    componentDidMount() {
        this.setState(this.props.state);
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerGrupos();
        } else {
            this.TraerCategorias();
        }
    }

    Estilos = {
        Columna1: {
            'height': '600px',
            'overflowY': 'scroll',
            'resize': 'both',
            'overflow': 'auto'
        },
        DivCentralTest: {
            'width': '50%',
            'float': 'left',
            'padding': '10px',
            //'height': '100%'
        },
        MarginLeftCR:
        {
            'marginLeft': '-2.5%'
        },
        BotoneraCategoria:
        {
            'width': '50%',
            'height': '60px',
            'borderRadius': '15px',
            'paddingTop': '10px',
            'paddingRight': '20px',
            'paddingBottom': '10px',
            'overflow': 'hidden',
            'outline': 'none',
            'borderColor': 'grey',
            'fontSize': '80%',
            'text-align': 'center'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'marginTop': '0.5%',
            'marginRight': '1%',
            'marginLeft': '2%'
        },
        BtnSalir: {
            'height': '44px',
            'width': '50px',
            'border-color': 'rgb(110, 152, 135)',
            'border-radius': '10%'
        },
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        BotoneraProductos: {
            'width': '32%',
            'height': '70px',
            'color': 'black',
            'border-color': '#bfbfc0',
            'margin-bottom': '1%',
            'font-size': '85%',
            'margin-right': '0.25%'
        },
    }

    ClickCambioIsCrearProducto() {
        if (this.state.isCrearProducto) {
            document.getElementById('tabCrear').className = 'nav-link';
            document.getElementById('tabExistente').className = 'nav-link active';
            this.setState({ isCrearProducto: false });
        } else {
            document.getElementById('tabCrear').className ='nav-link active';
            document.getElementById('tabExistente').className ='nav-link';
            this.setState({ isCrearProducto: true });
        }

    }

    async CrearProducto() {
        var producto = {};
        producto.desprod = document.getElementById('txtDescripcionProd').value;
        if (String(producto.desprod) === '') {
            swal('El nombre del producto no puede estar en blanco', {
                icon: "warning",
            });
            return;
        }
        if (document.getElementById('txtPrecioProd')) {
            producto.Precpred = Number(document.getElementById('txtPrecioProd').value);
        }
        else {
            producto.Precpred = 0;
        }
        if (!this.props.AgregarSubproducto) {
            producto.tbGrupoID = this.state.categoriaSeleccionada.id;
            if (document.getElementById('isNivelSuperior')) {
                producto.isNivelSuperior = document.getElementById('isNivelSuperior').checked;
            }
        }
        else {
            if (this.state.isNivelSuperiorSelected) {
                //producto.tbGrupoID = this.state.categoriaSeleccionada.id;
                if (document.getElementById('isNivelSuperior')) {
                    producto.isNivelSuperior = document.getElementById('isNivelSuperior').checked;
                }
                producto.tbProductoID = this.state.productoClickeado.id;
            }
            else {
                producto.tbProductoID = this.state.productoClickeado.id;
            }
            
        }

        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            if (document.getElementById('cboGrupoProd')) {
                producto.grupoID = document.getElementById('cboGrupoProd').value;
                if (producto.grupoID === "0") {
                    swal('Debe seleccionar un grupo', {
                        icon: "warning",
                    });
                    return;
                }
            }
        }

        producto.sucursalID = GetUserCookie().SucursalID;

        producto.Prodsimil = 0;
        if (document.getElementById('txtPrecioProdMesa')) {
            producto.Precmesa = Number(document.getElementById('txtPrecioProdMesa').value);
        }
        else {
            producto.Precmesa = 0;
        }

        if (document.getElementById('txtPrecioProdBar')) {
            producto.Precbar = Number(document.getElementById('txtPrecioProdBar').value);
        }
        else {
            producto.Precbar = 0;
        }
        producto.Precllevar = 0;
        producto.Precpedido = 0;
        if (document.getElementById('txtPrecioProdDelivery')) {
            producto.Precdeliv = Number(document.getElementById('txtPrecioProdDelivery').value);
        }
        else {
            producto.Precdeliv = 0;
        }
        producto.Permdesct = 0;
        producto.Porcdesct = 0;
        producto.Tipomodif = 0;
        producto.Nivlsuper = 0;
        producto.Nivlsupgr1 = 0;
        producto.Codimpres = 0;
        producto.Estprod = 0;
        producto.Vtacad = 0;
        producto.Codmod = 0;
        producto.Prodrec = 0;
        producto.IsCombo = 0;
        if (GetUserCookie().Sucursal.configPOS.balanzaApp && !this.state.isNivelSuperiorSelected) {
            //producto.IsPesable = document.getElementById('ClickChkAddProd').checked;
            producto.IsPesable = !this.state.defaultIsPesableAgregar;
            producto.PrecioKg = Number(document.getElementById('precioKilo').value);
            if (document.getElementById('precioKiloMesas')) {
                producto.PrecioKgMesa = Number(document.getElementById('precioKiloMesas').value);
            }
            if (document.getElementById('precioKiloBar')) {
                producto.PrecioKgBar = Number(document.getElementById('precioKiloBar').value);
            }
            if (document.getElementById('precioKiloDelivery')) {
                producto.PrecioKgDelivery = Number(document.getElementById('precioKiloDelivery').value);
            }

            if (producto.IsPesable && !producto.PrecioKg) {
                swal('El precio por kilo es obligatorio');
                return;
            }
        }
        else {
            producto.IsPesable = false;
            producto.PrecioKg = 0;
        }


        producto.Disponible = 0;
        producto.Impuesto = 0;
        producto.ImpuestoCant = 0;
        if (this.state.isNivelSuperiorSelected) {
            producto.zonaImpresionID = null;
        }
        else {
            if (document.getElementById('cboZonaImpresion')) {
                producto.zonaImpresionID = document.getElementById('cboZonaImpresion').value;
            }
            
        }
        
        if (producto.zonaImpresionID === '0') {
            swal("Debe seleccionar una impresora", {
                icon: "warning",
            });
            return;
        }
        if (document.getElementById('txtCodBarra')) {
            producto.Codbarra = document.getElementById('txtCodBarra').value;
        }

        producto.isHabilitado = true;
        var stockInicial = null;
        var urlApi = GetUrlApi() + '/api/tbproductoes/';
        if (GetUserCookie().Sucursal.configPOS.isStockHabilitado) {
            if (document.getElementById('txtStockInicial')) {
                stockInicial = document.getElementById('txtStockInicial').value;
                urlApi = GetUrlApi() + '/api/tbproductoes/?stockInicial=' + stockInicial;
                if (stockInicial==='') {
                    swal('Stock Inicial no válido', {
                        icon: "warning",
                    });
                    return;
                }
            }
        }

        var respuesta = await fetch(urlApi, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            const resp = await respuesta.json();
            if (resp.mensaje) {
                swal(resp.mensaje, {
                    icon: "error",
                })
                return;
            }
            swal('Producto creado con exito', {
                icon: "success",
            }).then(() => {
                this.props.RecargarCategoria();
            });
        }
        this.props.ResetAgregarSubProd();
    }


    async TraerProductosPorCategoria() {
        var idCategoria = document.getElementById('cboCateg').value;
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
        if (categorias.ok) {
            var datos = await categorias.json();
            this.setState({ ProductosSeleccionadosNivSup: datos, todosSelected: false });
        }

    }

    async TraerGrupos() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Grupos?sucursalID=' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ grupos: datos });
        }
    }



    ClickTodos() {
        var idCategoria = document.getElementById('cboProd').value;
        if (idCategoria === '0') {//si no es un elemento traido de bd
            this.setState({ todosSelected: true });
        }
        else {
            this.setState({ todosSelected: false });
        }
    }

    async IsProductoYaAsociado(id,idProdDestino) {//terminar luego
        var respuesta = await fetch(GetUrlApi() + '/api/Tbproductoes/IsProductoYaAsociado?id=' + id + '&idProdNivSuper=' + idProdDestino, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            return datos.res;
        }
    }

    async ClickAsociarMultiplesProductos() {
        var productos = Array.apply(null, document.getElementById('cboProd').options).filter(p => p.selected && p.label !== 'Todos');
        var procesoExitoso = false;
        for (var i = 0; i < productos.length; i++) {

            const id = productos[i].value;
            var idObjectivo = this.state.productoClickeado.id;
            var categoriaID = null;
            var urlApi = GetUrlApi() + '/api/tbproductoes/SetToNivelSuperior?id=' + id + '&idProdNivSuper=' + idObjectivo ;
            if (!idObjectivo) {
                idObjectivo = 0;
                categoriaID = this.state.categoriaSeleccionada.id;
                urlApi = GetUrlApi() + '/api/tbproductoes/SetToNivelSuperior?id=' + id + '&idProdNivSuper=' + idObjectivo + '&categoriaID=' + categoriaID;
            }
            var respuesta = await fetch(urlApi, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });
            if (respuesta.ok) {
                procesoExitoso = true;
            }
        }
        if (procesoExitoso) {
            swal('Productos agregados con éxito', {
                icon: "success",
            }).then((item) => {
                
                this.props.RecargarProducto();
                this.props.RecargarCategoria();
            });
        }
        else {
            
        }
        
    }

    ClickCheckNivelSuper() {
        if (!this.state.isNivelSuperiorSelected) {
            this.setState({ isNivelSuperiorSelected: true });
        }
        else {
            this.setState({ isNivelSuperiorSelected: false });
        }
    }

    ClickChkAddProd() {//borrar
        if (this.state.defaultIsPesableAgregar) {
            this.setState({ defaultIsPesableAgregar: false })
        } else {
            this.setState({ defaultIsPesableAgregar: true })
        }

    }

    habilitarIsPesable() {
        this.setState({ defaultIsPesableAgregar: true })

    }

    deshabilitarIsPesable() {
        this.setState({ defaultIsPesableAgregar: false })

    }

    async TraerGrupos() {//llamada por configuracion
        var SucursalID = GetUserCookie().SucursalID;
        const grupos = await fetch(GetUrlApi() + '/api/Grupos?sucursalID=' + SucursalID);
        const datos = await grupos.json();
        this.setState({ categoriasBotones: datos });
    }

    async TraerProductosPorGrupo() {//llamada por configuracion
        var idCategoria = document.getElementById('cboCateg').value;
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoGrupo?id=' + idCategoria + "&SucursalID=" + SucursalID);
        if (categorias.ok) {
            var datos = await categorias.json();
            console.log(datos);
            this.setState({ ProductosSeleccionadosNivSup: datos, todosSelected: false });
        }
    }

    ChangeCategoria() {
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerProductosPorGrupo();
        }
        else {
            this.TraerProductosPorCategoria();
        }
        
    }

    async TraerCategorias() {
        try {
            var SucursalID = GetUserCookie().SucursalID;
            const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);

            const datos = await categorias.json();

            this.setState({ categoriasBotones: datos, cargandoCategorias: false });

        } catch (err) {

        }
    }

    render() {

        return (
            <Popup
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.props.CerrarModalMaster() }}
                closeOnDocumentClick>
                <center>
                    {
                        (this.state.productoClickeado.isNivelSuperior || GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (
                            <ul className="nav nav-tabs">
                                <li className="nav-item" style={{ 'cursor': 'pointer' }} onClick={() => { this.ClickCambioIsCrearProducto() }}>
                                    <a id="tabCrear" className="nav-link active">Crear</a>
                                </li>
                                <li className="nav-item" style={{ 'cursor': 'pointer' }} onClick={() => { this.ClickCambioIsCrearProducto() }}>
                                    <a id="tabExistente" className="nav-link" >Seleccionar existente</a> 
                                </li>

                            </ul>
                        ) : (null)
                    }
                    {
                        (this.state.isCrearProducto) ? (//crear nuevo subproducto
                            <React.Fragment>
                                <h3>Ingrese la infomación requerida</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Descripción</td>
                                            <td>
                                                <input className="form-control" id="txtDescripcionProd" type="text" autocomplete="off"/>
                                            </td>
                                        </tr>
                                        {
                                            (!this.state.isNivelSuperiorSelected) ? (
                                                <tr>
                                                    <td>Precio para llevar</td>
                                                    <td>
                                                        <input className="form-control" id="txtPrecioProd" type="number" />
                                                    </td>
                                                </tr>
                                            ) : (null)
                                        }
                                        {
                                            (GetUserCookie().Sucursal.configPOS.isPrecioPorZona && !this.state.isNivelSuperiorSelected) ? (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>Precio Mesa</td>
                                                        <td>
                                                            <input className="form-control" id="txtPrecioProdMesa" type="number" defaultValue={this.state.productoClickeado.precmesa} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Precio Bar</td>
                                                        <td>
                                                            <input className="form-control" id="txtPrecioProdBar" type="number" defaultValue={this.state.productoClickeado.precbar} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Precio Delivery</td>
                                                        <td>
                                                            <input className="form-control" id="txtPrecioProdDelivery" type="number" defaultValue={this.state.productoClickeado.precdeliv} />
                                                        </td>
                                                    </tr>
                                                </React.Fragment>

                                            ) : (null)
                                        }
                                        {
                                            (GetUserCookie().Sucursal.configPOS.isGruposFamilias ) ? (
                                                <tr>
                                                    <td>Grupo</td>
                                                    <td>
                                                        <select className="form-control" id="cboGrupoProd">
                                                            <option value="0">Seleccione una opción</option>
                                                            {
                                                                this.state.grupos.map((grupo) => {
                                                                    return (<option value={grupo.id}>{grupo.descripcion}</option>);
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            ) : (null)
                                        }


                                        
                                        {
                                            (GetUserCookie().Sucursal.configPOS.isZonaImpresionHabilitado && !this.state.isNivelSuperiorSelected) ? (
                                                <tr>
                                                    <td>Impresora</td>
                                                    <td>
                                                        <select id="cboZonaImpresion" className="form-control">
                                                            <option value="0">-Seleccione una impresora</option>
                                                            {this.state.zonasImpresion.map((item, i) => {
                                                                const isSelected = this.state.productoClickeado.zonaImpresionID === item.id;
                                                                return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                            ) : (null)
                                        }
                                        

                                        {
                                            (GetUserCookie().Sucursal.configPOS.balanzaApp && !this.state.isNivelSuperiorSelected) ? (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>¿Es pesable?</td>
                                                        <td>
                                                            <p style={{ 'margin': '0' }}>
                                                                <label>
                                                                    Si 	&nbsp; <input name="rbtIsPesable" onClick={() => { this.deshabilitarIsPesable() }} type="radio" checked={!this.state.defaultIsPesableAgregar}/>
                                                                </label>
                                                            </p>
                                                            <p style={{'margin': '0' }}>
                                                                <label>
                                                                    No  <input name="rbtIsPesable" onClick={() => { this.habilitarIsPesable() }} type="radio" checked={this.state.defaultIsPesableAgregar} />
                                                                </label>
                                                            </p>
                                                           
                                                            
                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Precio por kilo </td>
                                                        <td>
                                                            <input disabled={this.state.defaultIsPesableAgregar} id="precioKilo" type="number" className="form-control" />
                                                        </td>
                                                    </tr>
                                                    {
                                                        (false) ? (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td>Precio por kilo mesas</td>
                                                                    <td>
                                                                        <input disabled={!this.state.productoClickeado.isPesable} id="precioKiloMesas" type="number" className="form-control" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Precio por kilo bar</td>
                                                                    <td>
                                                                        <input disabled={!this.state.productoClickeado.isPesable} id="precioKiloBar" type="number" className="form-control" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Precio por kilo delivery</td>
                                                                    <td>
                                                                        <input disabled={!this.state.productoClickeado.isPesable} id="precioKiloDelivery" type="number" className="form-control" />
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ) : (null)
                                                    }
                                                </React.Fragment>

                                            ) : (null)
                                        }
                                        {
                                            (GetUserCookie().Sucursal.configPOS.isStockHabilitado) ?
                                                (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td>Stock inicial</td>
                                                            <td> <input id="txtStockInicial" type="number" className="form-control" /> </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                                : (null)
                                        }
                                    </tbody>
                                </table>
                                {
                                    (true) ? (
                                        <p>
                                            <br/>
                                            Nivel Superior
                                            <label className="switch">
                                                <input type="checkbox" id="isNivelSuperior" onClick={() => { this.ClickCheckNivelSuper() }} />
                                                <span className="slider round"></span>
                                            </label>

                                            </p>
                                    ) : (null)
                                }

                                

                                <button onClick={() => { this.CrearProducto() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                                    <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                            </React.Fragment>
                        ) : (//seleccionar subproducto de uno existente
                                <React.Fragment>
                                    <table style={{ width: '100%', height: '100%' }}>
                                        <tr>
                                            <td>
                                                <h3>{(GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? ('Grupos') : ('Categorías')}</h3>
                                            </td>
                                            <td>
                                                <h3>Productos</h3>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <select id="cboCateg" style={{ 'overflow': 'auto', width: '100%', cursor: 'pointer' }} size="15" onChange={() => { this.ChangeCategoria() }} className="form-control">
                                                    <option hidden disabled selected value> -- select an option -- </option>
                                                    {
                                                        this.state.categoriasBotones.map((item, i) => {
                                                            const id = 'optCatef' + i;
                                                            return (<option key={id} value={item.id}>{(GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (item.descripcion) : (item.desgrup)}</option>)
                                                        })
                                                    }
                                                </select>
                                            </td>
                                            <td>
                                                <select id="cboProd" style={{ 'overflow': 'auto', width: '100%', float: 'right', cursor: 'pointer' }} size="15" multiple onChange={() => { this.ClickTodos() }} className="form-control">

                                                    {
                                                        this.state.ProductosSeleccionadosNivSup.map((item, i) => {
                                                            const id = 'optCatef' + i;
                                                            if (i === this.state.ProductosSeleccionadosNivSup.length - 1) {
                                                                return (
                                                                    <React.Fragment>
                                                                        <option selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>
                                                                        <option value="0">Todos</option>
                                                                    </React.Fragment>
                                                                    )
                                                            }
                                                            else {
                                                                return (<option selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>)
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                    <button onClick={() => { this.ClickAsociarMultiplesProductos() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                                </React.Fragment>
                            )
                    }





                </center>

            </Popup>
        );
    }
}
