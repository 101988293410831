import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import { GetUrlApi } from './Globales/VariableGlobales';
import UserProfile from './Globales/PerfilUsuario';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';
import { GetNumeroMiles } from './Globales/Utilidades';

export class TecladoGiftcard extends Component {
    static displayName = TecladoGiftcard.name;
    ParentComp = '';
    TotalPagarMaster;
    Numero;
    IngresoUsuario = false;
    Url = '';
    TipoTexto = "number";
    IsCodOpSelected = false;
    IsMontoSelected = true;
    IsEfectivo = false;
    NumCaja = 1;
    CodigoOperacion = '';
    IsContPersonas = false;
    IsAutorizacion = false;
    MotivoAnulacion = '';
    isGarantiaEvento = false;
    UltimoMedioAgregado = {};
    TotalOrdenDesc = 0;
    TextoIngresadoNum = '';
    DatosGiftcard = {};
    RemainingAmount=0;
    constructor(props) {
        super(props);
        this.state = {
            mostrarTotal: false,
            total: 0,
            mensaje: '',
            activarRedireccion: false,
            redirigirToRevisar: false,
            redirigirToMesas: false,
            activosMotivosAnulacion: false,
            Motivos: [],
            medioIngresado: {},
            bancos:[],
            ErrorExists:false
        }
        this.Numero = '';
        this.IsEfectivo = props.IsEfectivo;
        this.TotalOrdenDesc = props.TotalOrdenDesc;
        

        this.DatosGiftcard = props.DatosGiftcard
        this.RemainingAmount = this.DatosGiftcard[0].remainingAmount;

        this.IngresoUsuario = props.ingresoUsuario;
        if (this.IngresoUsuario) {
            this.Estilos.EstiloPopup.width = '45%';
            this.TipoTexto = 'password';
        }
        URL = props.url;
        if (this.props.CerrarModalMaster)
            this.CerrarModalMaster = this.props.CerrarModalMaster;
        if (this.props.total) {
            this.TotalPagarMaster = this.props.total;
            
         //   document.getElementById('PINbox').value = this.TotalPagarMaster;
        }
        if (this.props.ActualizarEstadoPagar) 
            this.ActualizarEstadoPagar = this.props.ActualizarEstadoPagar;
        if (this.props.VolverToPagar)
            this.VolverToPagar = this.props.VolverToPagar;
        if (this.props.ActualizarTexto)
            this.ActualizarTexto = this.props.ActualizarTexto;
        if (this.props.isGarantiaEvento)
            this.isGarantiaEvento = this.props.isGarantiaEvento;
        this.IsCodOpSelected = false;
        this.IsCodOpSelected = props.IsContPersonas;
        this.UltimoMedioAgregado = props.UltimoMedioAgregado;
        if (this.UltimoMedioAgregado) {
            if (this.UltimoMedioAgregado.isCheque)
             this.TraerBancos();
        }
        this.IsAutorizacion = props.IsAutorizacion;


        document.onkeypress = (e) => {
            var element = document.getElementById('PINbox');
            if (element!=null) {
                if (e.key === 'd' || e.key==='D'){
                    this.DeleteChar();
                    document.getElementById('PINbox').focus();
                    return;
                   }
                var val = document.getElementById('PINbox').value;
                if (val.length >= 6 && val.length <= 9) {
                    document.getElementById('PINbox').style.fontSize = "3em";
                } else if (val.length > 10 && val.length <= 20) {
                    document.getElementById('PINbox').style.fontSize = "1.5em";

                }
                else if (val.length > 20 && val.length) {
                    document.getElementById('PINbox').style.fontSize = "0.5em";

                } else if (val.length < 6) {
                    document.getElementById('PINbox').style.fontSize = "4em";
                }

                if (e.key === 'Enter') {
                    console.log(this.Numero);
                    //if (val!="") {
                    //    var dreplace = val.replaceAll('.', '');
                    //    this.Numero = dreplace;
                    //}
                    //setTimeout(() => { this.ClickSelectCantidad() }, 1000);
                    this.ClickSelectCantidad();

                    
                }
                else if (e.key === '1') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '2') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '3') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '4') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '5') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '6') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '7') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '8') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '9') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '0') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;

                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === 'c' || e.key === 'C') {
                    this.ClickCE();
                }

            }
            
        };



    }
    ElementBlur(e) {
        console.log(e);
        document.getElementById(e).blur();
    }
    componentDidMount() {
        console.log(GetUserCookie().Sucursal.configPOS);

         
        if (this.UltimoMedioAgregado) {
            this.setState({ medioIngresado: this.UltimoMedioAgregado });
        }
        if (this.IsEfectivo) {
            this.Estilos.EstiloPopup.width = '30%';
        }
        if (this.IsAutorizacion) {
            document.getElementById('PINbox').type = 'password';
            this.setState({ mensaje:'El gerente debe autorizar esta operación' });
        }
        if (this.props.descEfectivo) {
            this.setState({ mensaje:'Ingrese Monto en Efectivo a Descontar' });
        }
        if (this.TotalPagarMaster > 0) {
            //document.getElementById('PINbox').value = this.RedondearValor(this.TotalPagarMaster).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            this.Estilos.EstiloPopup.height = '70%';
            //document.getElementById('inSaldoPago').value = this.RedondearValor(this.TotalPagarMaster).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            
        }
        if (this.props.mostrarTotal) {
            //this.Numero = this.RedondearValor( this.props.total);
            const montoInicio = this.props.total.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            this.setState({ mostrarTotal: true, total: montoInicio });
        }
        if (this.props.propinaDif) {
            this.setState({ mensaje: 'Total recibido' });
        }
        if (this.props.DatosGiftcard){
            this.setState({ mensaje: 'Ingrese monto a utilizar' });
        }

        if (this.RemainingAmount >= this.TotalPagarMaster){
            document.getElementById('PINbox').value = this.TotalPagarMaster;
        }
        else{
            document.getElementById('PINbox').value = this.RemainingAmount;
        }
        var val = document.getElementById('PINbox').value;
        this.Numero=val;
        if (val.length>=6 && val.length<=9) {
            document.getElementById('PINbox').style.fontSize = "3em";
        } else if (val.length > 10 && val.length <= 20) {
            document.getElementById('PINbox').style.fontSize = "1.5em";
        }
        else if (val.length > 20 && val.length) {
            document.getElementById('PINbox').style.fontSize = "0.5em";

        } else if (val.length < 6) {
            document.getElementById('PINbox').style.fontSize = "4em";
        }
         try{document.getElementById('PINbox').focus()}catch(e){}     
    }

   async  TraerBancos() {
        var respuesta = await fetch(GetUrlApi() + '/api/Bancos/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var bancos = await respuesta.json();
            this.setState({ bancos: bancos });

        }
    }

    RedondearValor(monto) {
        if (this.IsEfectivo) {
            if (monto > 0) {
                const resto = monto % 10;
                if (resto > 0 && resto <= 5) {
                    return monto - resto;
                }
                else if (resto > 5 && resto < 10) {
                    return monto + (10 - resto);
                }
            }
            else {
                return monto;
            }
        }
        if (monto < 1){
            return monto;
        }
        else {
            monto = Math.round(monto * 10)/10;
        }
        return monto
    }

    ClickTecladoCantidad2(num) {
        var val =document.getElementById('PINbox').value;
      
        if (val.length>=6 && val.length<=9) {
            document.getElementById('PINbox').style.fontSize = "3em";
        } else if (val.length > 10 && val.length <= 20) {
            document.getElementById('PINbox').style.fontSize = "1.5em";
        }
        else if (val.length > 20 && val.length) {
            document.getElementById('PINbox').style.fontSize = "0.5em";

        } else if (val.length < 6) {
            document.getElementById('PINbox').style.fontSize = "4em";
        }


        if (this.IsCodOpSelected) {
            this.CodigoOperacion += num;
            document.getElementById('numOperacion').value = Number(this.CodigoOperacion).toString();
        }
        else {
            this.Numero = this.Numero.toString().replaceAll('.','') +  num;
            //document.getElementById('PINbox').type = 'text';
           document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
                  
        }

    }
    
    ClickTxtMonto() {
        this.IsCodOpSelected = false;
        this.IsMontoSelected = true;
    }

    DeleteChar() {

        var dato = document.getElementById('PINbox').value;
        var result =  dato.slice(0, -1);
        this.TextoIngresadoNum =  Number(result.toString().replaceAll('.',''));;
        this.Numero = Number(result.toString().replaceAll('.',''));;
        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
                  
       
        
    }

    async ClickSelectCantidad() {
        const cantidad = document.getElementById('PINbox').value;
        if (this.state.ErrorExists){
            return;
        }
        var codOperacion;
        if (document.getElementById('numOperacion'))
         codOperacion = document.getElementById('numOperacion').value;
        if (this.props.isNumOperacion && codOperacion === '') {
            swal('Debe ingresar el código de operación');
        }
        else {
 
            const monto = Number(this.Numero);
            if (monto < 1){
                this.setState({ErrorExists:true});
                swal({
                    title: 'Debe ingresar un monto válido para Giftcard.',
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.setState({ErrorExists:false});
                    document.getElementById('PINbox').focus();
                })
                return;
            }

            if (monto > this.RemainingAmount){
 
                this.setState({ErrorExists:true});
                swal({
                    title: 'El monto a utilizar no puede ser mayor al saldo restante de la Giftcard.',
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.setState({ErrorExists:false});
                    document.getElementById('PINbox').focus();
                })
                return;
            }
            if (monto > this.TotalPagarMaster){
                this.setState({ErrorExists:true});
                swal({
                    title: 'El monto a utilizar no puede ser mayor al Total a Pagar.',
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.setState({ErrorExists:false});
                    document.getElementById('PINbox').focus();
                })
                return;
                
            }

            if (this.props.cantidad) {
                this.props.ActualizarEstado({ cantidad: cantidad });
                this.CerrarModalMaster();
            }
            else if (this.props.propinaCant) {
                var neworden = this.props.orden;
                neworden.propinaCant = cantidad;
                neworden.propinaPorc = 0;
                neworden.propinaDiff = 0;

                //     if (this.props.ActualizarEstado)
                //       this.props.ActualizarEstado({ orden: neworden });//actualiza paraLlevar
                if (this.props.ActualizarEstadoPagar)
                    this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar

            }
            else if (this.props.propinaDif) {
                var neworden = this.props.orden;
                neworden.propinaCant = 0;
                neworden.propinaPorc = 0;
                neworden.propinaDiff = cantidad;

                // this.props.ActualizarEstado({ orden: neworden });//actualiza paraLlevar
                this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar
            }
            else if (this.props.descEfectivo) {
                var porcDescuento = 0;
                if (this.TotalOrdenDesc!=0) {
                    //porcDescuento = Math.Round(Number((cantidad * 100) / this.TotalOrdenDesc));
                    var totalT = Number(this.TotalOrdenDesc);
                    var cantidadT = Number(cantidad);

                    porcDescuento = (cantidadT * 100) / totalT;
                    porcDescuento = Math.round(porcDescuento);
                }
                var neworden = this.props.orden;
                neworden.descEfectivo = cantidad;
                neworden.dctoEfec = cantidad;
                this.props.ActualizarEstado({ orden: neworden, IsDescuentoRealizado: true });//actualiza paraLlevar
                this.ActualizarEstadoPagar({ orden: neworden, IsDescuentoRealizado: true  });//actualiza pagar
                if (this.VolverToPagar)
                    this.VolverToPagar();
                if (this.props.CerrarModal) {
                    this.props.CerrarModal();
                }
            }
            else if (this.IngresoUsuario) {
                var ruta = this.props.url;
                //this.Url = ruta;
                this.Url = "/revisar";
                const urlApi = GetUrlApi() + '/api/Usuarios/' + cantidad + '?pin=true';
                const usuario = await fetch(urlApi);
                if (usuario.ok) {
                    var datos = await usuario.json();
                    const nombre = datos.nombre + ' ' + datos.apellido;
                    ruta += '?user=' + datos.id;
                    URL += '?user=' + datos.id;

                    var usuarioSesion = UserProfile.getUser;
                    usuarioSesion.id = datos.id;
                    usuarioSesion.username = datos.nombre + " " + datos.apellido;
                    usuarioSesion.tipoUsuario = datos.tipoUsuarioID;
                    usuarioSesion.CajaID = 1;
                    
                    

                    var data = await fetch(GetUrlApi() + '/api/Cajas/' + this.NumCaja);
                    data =await data.json();
                    data.idUsuarioFk = datos.id;
                    var respuesta = await fetch(GetUrlApi() + '/api/Cajas/' + this.NumCaja, {
                        method: 'put',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });
                    if (respuesta.ok) {
                        usuarioSesion.idGaveta = data.idUltimaGavetaFk;
                    }

                    UserProfile.setUser(usuarioSesion);
                    
                    this.setState({ redirigirToRevisar: true });
                    this.props.CerrarModalMaster();
                    //this.props.actualizarEstado({ urlRedirijir: URL, activarRedireccion: true, usuarioIngresado: datos.id });
                }
                else {
                    swal('Usuario no valido');
                }
            }
            else if (this.IsAutorizacion) {
                
                var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + cantidad + '?pin=true', {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (respuesta.ok) {
                    var user = await respuesta.json();
                    if (user.tipoUsuarioID === 1 || user.tipoUsuarioID === 2) {
                        if (this.props.param) {
                            var productoEnviado = this.props.param2;//contiene el item a borrar
                            if (!productoEnviado.isGuardado) {
                                this.setState({ activosMotivosAnulacion: true });
                            }
                            else {
                                const parametroRecibido = (this.props.param - 1);
                                this.props.CrearCierre(parametroRecibido, this.MotivoAnulacion);
                            }
                        }
                        else {
                            this.props.CrearCierre();
                        }

                    }
                    else {
                        swal('Perfil de usuario no valido');
                    }

                }
                else {
                    swal({
                        title: "Notificación",
                        text: "Usuario invalido",
                        icon: "danger",
                        dangerMode: true,
                    })
                }
               
            }
            else if (this.isGarantiaEvento) {
                const tieneFactura = this.props.tieneFactura;
                const numInfo = this.props.numInfo;
                this.props.AgregarPago(Number(cantidad), null, null, tieneFactura, numInfo);
                this.props.CerrarModalMaster();
            }
            else if (this.props.cambioPrecio) {
                var neworden = this.props.orden;
                console.log(neworden);

                neworden.carroProductos[this.props.IndiceProducto].precpred = cantidad;
                this.props.ActualizarEstado({ orden: neworden });//actualiza pagar
                this.props.CerrarModalMaster();

            }
            else {
                try {//si se agrega un pago en Pagar.js entra aqui
                    var numOp;
                    var numCheque = null;
                    var idBanco = null;
                    var numTarjeta = null;
                    if (this.state.medioIngresado.isCheque) {
                        numCheque = document.getElementById('TxtNumCheque').value;
                        idBanco = Number( document.getElementById('txtBanco').value);
                    }

                    if (this.state.medioIngresado.isTarjeta) {
                        numTarjeta = document.getElementById('txtNumTarjeta').value;
                    }
                  
                    if (document.getElementById('PINbox')) {
                        const totalDeLaVenta = this.props.total;
                        //document.getElementById('PINbox').value = totalDeLaVenta;
                    }
                    if (document.getElementById('numOperacion')) {
                        numOp = document.getElementById('numOperacion').value;
                    }
                    

                    if (!numOp) {
                        numOp = null;
                    }
                    numOp  = this.DatosGiftcard[0].serie;
                    var cuentaCasaID = this.props.cuentaCasaID;
                    if (!this.props.cuentaCasaID) {
                        cuentaCasaID = null;
                    }
                    console.log(' '+cantidad+', '+ numOp+', '+ cuentaCasaID+', null, null, '+numCheque+', '+ numTarjeta+', '+idBanco);
                    this.props.AgregarPago(monto, numOp, cuentaCasaID, null, null, numCheque, numTarjeta, idBanco);
                } catch (err) {

                    console.log(err);
                }
                try {
                    this.props.ActualizarEstado({ propinaPorc: cantidad });
                    this.props.ActualizarEstadoPagar({ propinaPorc: cantidad });
                } catch (err) {

                }
                try {
                    var neworden = this.props.orden;
                    neworden.propinaPorc = cantidad;
                    this.props.ActualizarEstado({ orden: neworden });
                } catch (err) {

                }
            }
            this.ActualizarTexto();
            this.CerrarModalMaster();
        }
    }

    

    //--------ESTILOS
 

    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '100%'
        },TercioAncho: {
            'width': '33%'
        },
        EstiloPopup: {
            'width': '31%',
            'height': '70%',
            'border-radius': '8px',
            'margin-left':'25%'
           //'margin': 'initial'
        },
        
        ColorFondoTeclado: {
            'background': '#3498db'
        },
        
        BtnGroup:
        {
            'borderColor': '#d8e1de',
            'margin-left': '3%',
            'margin-bottom': '1%',
            'width': '45%',
            'font-size': '80%',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'height': '50px'
        }, PopUpStyle: {
             'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px'
        }, InputSize:
        {
            'width': '80%',

        },
        ButonGroupAproved:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'background': '#dc3545',
            'borderColor': '#6e9887',
            'margin-left': '16%'
        },
        ButonGroupAproved2:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'borderColor': '#6e9887',
            'margin-left': '16%'
        },
        ButtonsEnt: {
            'width': '4rem',
            'height': '4rem',
            'background': 'rgb(224 74 88)',
            'color': 'white',
            'fontSize': '19px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'border': '0',
        }
     

    }

    Numero = {
        numm: {
            'size': '15px'
        }
    }
    //--------ESTILOS

    //---------FUNCIONES SOBRESCRIBIR
    CerrarModalMaster() {

    }

    ActualizarEstadoPagar() {

    }
    ActualizarTexto() {
        //console.log('ActualizarTexto no sobreescribio');
    }

    PagarMontoRapido(monto) {
        this.Numero = monto;
        this.ClickSelectCantidad();
    }

    PagarTotal() {
        this.Numero = this.TotalPagarMaster;
        this.ClickSelectCantidad();
    }

    ClickCE() {
        this.Numero = "0";
        this.TextoIngresadoNum = '';
        document.getElementById('PINbox').value = '';
        document.getElementById('-').blur();
    }

    ClickCodigoOperacion() {
        this.IsCodOpSelected = true;
        this.IsMontoSelected = false;
    }
    VolverToPagar() { }
    //---------FIN FUNCIONES SOBRESCRIBIR

    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivoAnulacions', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ Motivos: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }

    CerrarModal() {
        this.setState({ activosMotivosAnulacion: false });
    }

    GetHtmlPopupAnulacion() {
        this.TraerMotivos();
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                onClose={() => { this.CerrarModal() }}
                closeOnDocumentClick>
                <center>
                    <h3>Motivo de Anulación de la Orden</h3>
                    <div className="row">
                        {
                            this.state.Motivos.map((item, index) => {
                                const id = 'btnMotivo' + index;
                                return (<button id={id} name="btnMotivo" className="btn " style={this.Estilos.BtnGroup} onClick={() => { this.ClickSeleccionarMotivoBtn(item.descripcion, id) }}>{item.descripcion}</button>);
                            })
                        }
                        
                    </div>
                    <hr />
                    <h3> Otra razón </h3>
                    <input id="motivoPesonalizado" style={this.Estilos.InputSize} onClick={() => { this.DesactivarSelecciones() }} className="form-control" type="text" />
                    <hr />
                    <button className="btn btn-danger" style={this.Estilos.ButonGroupAproved} onClick={() => { this.CerrarModal() }}>
                        <i className="fa fa-times-circle fa-2x" aria-hidden="true"></i>
                    </button>

                    <button className="btn btn-success" style={this.Estilos.ButonGroupAproved2} onClick={() => { const parametroRecibido = (this.props.param-1); this.props.CrearCierre(parametroRecibido, this.MotivoAnulacion); }} data-toggle="tooltip" data-placement="right" title="Listo">
                        <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i>
                    </button>
                </center>
            </Popup>
        );
    }

    ClickSeleccionarMotivoBtn(texto, idElem) {
        this.DesactivarSelecciones();
        this.MotivoAnulacion = texto;
        document.getElementById(idElem).className = 'btn btn-light';
        document.getElementById(idElem).style.background = 'white';
        document.getElementById(idElem).style.color = 'black';
    }
    DesactivarSelecciones() {
        this.MotivoAnulacion = '';
        document.getElementsByName('btnMotivo').forEach((item) => {
            item.className = 'btn btn-dark';
            document.getElementById(item.id).style.background = 'black';
            document.getElementById(item.id).style.color = 'white';
        });
    }
    render() {
        return (
            <Popup
                modal
                id='TecladoGiftcard'
                onClose={() => { this.CerrarModalMaster() }}
                defaultOpen
                contentStyle={this.Estilos.EstiloPopup}
                closeOnDocumentClick>
                <div>
              
                    {
                        (this.TotalPagarMaster) ? (//si debe mostrar pago
                            <div>
                                <div className="row">
                                    <div className="col">Saldo a Pagar: <strong>$ {GetNumeroMiles(this.TotalPagarMaster)}</strong>
                                    </div>
                                </div>
                                <div className="row">   
                                     <div className="col">Saldo Giftcard:  <strong>{GetNumeroMiles(this.RemainingAmount)}</strong></div>
                                </div>
                                <div className="row">
                                    <div className="col" ><strong>Ingrese Monto</strong></div>
                                </div>
                                <div className="row">
                                    <div className="col" >
                                        <input id='PINbox' onFocus={() => { this.ElementBlur('PINbox') }} type={this.Numero}   onClick={() => { this.ClickTxtMonto() }} name='PINbox' />
                                    </div>
                                </div>
                                
                             
                                <table id="tablaBotones">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                            <td> <input onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>
                                            <td><button className="btn" style={this.Estilos.ButtonsEnt} onClick={() => { this.DeleteChar() }} type="button" name="Borrar" id="Borrar"><i className="fa fa-long-arrow-left " aria-hidden="true"></i></button></td>
                                        </tr>
                                        <tr>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input onClick={() => { this.ClickCE() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                            <td> <input onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                            <td><input onClick={() => { this.ClickSelectCantidad() }} type='button' className='PINbutton enter' name='+' value='enter' id='+' /></td>
                                            
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        ): (
                                <div>
                                    <input id='PINbox' onFocus={() => { this.ElementBlur('PINbox') }}  type={this.TipoTexto} defaultValue='' name='PINbox' disabled />
                                <table id="tablaBotones">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                                <td> <input onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>
                                            </tr>
                                            <tr>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>
                                            </tr>
                                            <tr>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>
                                            </tr>
                                            <tr>
                                                <td><input onClick={() => { this.ClickCE() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                                <td> <input onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                                <td><input onClick={() => { this.ClickSelectCantidad() }} type='button' className='PINbutton enter' name='+' value='enter' id='+' /></td>
                                            </tr>
                                        </tbody>
                                </table>
                            </div>
                        )
                    }
                </div>
            </Popup>
        );
    }

    //<center> <input onClick={() => { this.PagarTotal() }} type='button' style={this.Estilos.TercioAncho} className='PINbutton enter' name='+' value={GetPrecioFormateado(this.TotalPagarMaster)} id='+' /> </center>
}
