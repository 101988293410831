import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { TecladoNumerico } from './TecladoNumerico';
import swal from 'sweetalert';
import { GetUrlApi } from './Globales/VariableGlobales';

export class Descuentos extends Component {
    static displayName = Descuentos.name;
    Orden = {};
    IndiceProdSelect = 0;

    constructor(props) {
        super(props);
        this.state = {
            dctoOrden: true,
            dctoArt: false,
            dctoEfec: false,
            TiposDescuento: [],
            mostrarDescProdPorc: false,
            descYaIngresado:false
        }
        this.CerrarModal = this.props.CerrarModal;
        if (this.props.orden)
            this.Orden = this.props.orden;
        if (this.props.ActualizarEstado)
            this.ActualizarEstado = this.props.ActualizarEstado;
        this.CerrarModalMaster = this.CerrarModalMaster.bind(this);

        this.TraerTiposDescuentos().then(() => {
            if (document.getElementById('descOrden' + this.Orden.dctoOrden)) {
                document.getElementById('descOrden' + this.Orden.dctoOrden).className = 'btn btn-light';
            }

        });
    }

    async TraerTiposDescuentos() {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/DetalleDescuento/GetDescuentos');
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            const datos = await categorias.json();
            console.log(datos)
            this.setState({ TiposDescuento: datos });
        } catch (err) {
            swal('No se pudo traer las categorias verifique su conexion a internet');
        }

    }

    componentDidMount() {
        if (this.props.CerrarModal)
            this.CerrarModal = this.props.CerrarModal;
        document.getElementById('btnDescOrden').className = 'btn btn-light';

        //if (this.Orden.dctoOrden === 50) {
        //    document.getElementById('descOrden50').className = 'btn btn-light';
        //}
        //else if (this.Orden.dctoOrden === 20) {
        //    document.getElementById('descOrden20').className = 'btn btn-light';
        //}
        //else if (this.Orden.dctoOrden === 10) {
        //    document.getElementById('descOrden10').className = 'btn btn-light';
        //}
    }

     DesactivarOpciones() {
        this.setState({
            dctoOrden: false,
            dctoArt: false,
            dctoEfec: false,
            mostrarDescProdPorc: false
        });
    }

    ReseatearBotones() {
        var botones = document.getElementsByName('OpcPrincipales');
        botones.forEach((value) => {
            value.className = 'btn btn-dark';
        });
    }

    ReseatearBotonesPorcentaje() {
        var botones = document.getElementsByName('descPorc');
        botones.forEach((value) => {
            value.className = 'btn btn-light';
        });
    }

    async ClickDescuento(descuento) {
        if (this.props.ActualizarEstado) {

            if (this.props.orden.dctoOrden) {
                var neworden = this.props.orden;
                delete neworden.dctoOrden;

                await this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento });//actualiza paraLlevar
               // this.ActualizarTexto();
                await this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar

                this.ClickVolver();
            }
            else {
                var neworden = this.props.orden;
                neworden.dctoOrden = descuento;
                neworden.dctoArt = 0;
                neworden.dctoEfec = 0;

                await this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento });//actualiza paraLlevar
                //this.ActualizarTexto();
                await this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar

                this.ClickVolver();
            }
        }

        //if (this.props.ActualizarEstadoPagar && this.props.ActualizarEstado) {
        //    var neworden = this.props.orden;
        //    neworden.dctoOrden = descuento;
        //    neworden.dctoArt = 0;
        //    neworden.dctoEfec = 0;

        //    await this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento });//actualiza paraLlevar
        //    await this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar
            
        //    this.ClickVolver();
        //}
    }

    ClickSetDescuentoProd(descuento, yaClickeado) {
        if (yaClickeado) {
            var neworden = this.props.orden;
            delete neworden.carroProductos[this.IndiceProdSelect].descuentoPorc;

            this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento });//actualiza paraLlevar
            //this.ActualizarTexto();
            this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar

            this.props.VolverToPagar();
        }
        else {
            var neworden = this.props.orden;
            neworden.carroProductos[this.IndiceProdSelect].descuentoPorc = descuento;

            this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento });//actualiza paraLlevar
            //this.ActualizarTexto();
            this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar

            this.props.VolverToPagar();
        }


        
    }

    CerrarModal() {
        //sobreescrito por prop
    }

    ClickDescuentoProd(indice) {
        this.IndiceProdSelect = indice;
        this.setState({ mostrarDescProdPorc: true, dctoArt: false });

    }

    ClickVolver() {
        this.props.ActualizarEstadoPagar({ activarDescuento: false })
    }

    CerrarModalMaster() {
        this.setState({ dctoEfec: false });
    }
    
    ClickDescontarOrden() {
        this.setState({
            dctoOrden: true,
            dctoArt: false,
            dctoEfec: false,
            mostrarDescProdPorc: false
        });
    }

    ClickDescOrden() {
        this.ReseatearBotones();
        document.getElementById('btnDescOrden').className = 'btn btn-light';
        this.DesactivarOpciones();
        this.setState({ dctoOrden: true });
    }

    ClickDescArt() {
        this.ReseatearBotones();
        document.getElementById('btnDescArt').className = 'btn btn-light';
        this.DesactivarOpciones();
        this.setState({ dctoArt: true });
    }

    ClickDescEfec() {
        this.ReseatearBotones();
        document.getElementById('btnDescEfect').className = 'btn btn-light';
        this.DesactivarOpciones();
        if (this.Orden.dctoEfec) {
            swal({
                title: "Descuento ya aplicado",
                text: "¿Desea eliminar el descuento ya ingresado?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    var newOrden = this.Orden;
                    delete newOrden.dctoEfec;
                    delete newOrden.descEfectivo;//quitar uno
                    //newOrden.dctoEfec = null;
                    this.props.ActualizarEstado({ orden: newOrden });
                    this.props.VolverToPagar();
                    this.DesactivarOpciones();
                    //this.CerrarModal();
                } else {

                }
            });
        }
        this.setState({ dctoEfec: true, });
    }

    Estilos = {
        btnDescuento: {
            'width': '33%',
            'font-size': '80%'
        },
        TercioAncho: {
            'width': '33%'
        },
        FullAncho: {
            'width': '50%',
            'margin-top': '-3%',
            'marginLeft': '-6%'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '100%'
        },
        AlturaListaProductos: {
            'height': '200px'
        },
        EstiloPopup: {
            'width': '47%',
            'height': '70%',
            'border-radius': '2%'
        },
        MarginTr:
        {
            'margin-bottom': '10%'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        BtnStyles:
        {
            'width': '120px',
            'height': '50px',
            'background-color': '#769e8e'
        },
        BtnArt:
        {
            'width': '40%',
            'height': '50px',
            'marginRight': '2%',
            'margin-bottom': '2%',
            'background-color': 'rgb(118, 158, 142)',
            'borderColor': '#17a2b8',
            'font-size': '80%',
            'marginLeft': '8%'
        },
        BtnStylesM:
        {
            'width': '120px',
            'height': '50px',
            'background-color': 'rgb(207, 240, 227)'
        },
        BtnDanger:
        {
            'width': '50%',
            'margin-top': '-3%',
            'background-color': '#f80303ad'
        }

    }

    
    GetHtmlDescArticulos() {
        var hayDescuento = false;
        var porcBoton = 0;
        if (this.Orden) {
            this.Orden.carroProductos.forEach((item, i) => {
                if (item.descuentoPorc > 0 && i === this.IndiceProdSelect) {
                    hayDescuento = true;
                    porcBoton = item.descuentoPorc;
                }
            });
            return (
                <div>
                    <center>
                        <table className="row" style={this.Estilos.FullAncho}>


                            {
                                this.state.TiposDescuento.map((item) => {
                                    var isSelected = item.porcDescuento === porcBoton;
                                    var textclass = '';
                                    if (isSelected) {
                                        textclass = 'btn btn-light';
                                    }
                                    else {
                                        textclass = 'btn btn-info';
                                    }
                                    return (
                                        <React.Fragment>
                                            <tr style={this.Estilos.MarginTr}>
                                                <td style={this.Estilos.SpanStyle} rowspan="3">
                                                    <button className={textclass} id={'descUnitario' + item.porcDescuento} onClick={() => { this.ClickSetDescuentoProd(item.porcDescuento) }} style={this.Estilos.BtnStyles}>{item.porcDescuento}%</button>

                                                    <span style={this.Estilos.SpanStyle}>{item.descripcion}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })

                            }




                        </table><br />
                        {
                            (porcBoton > 0) ? (
                                <button id={'descUnitario' + porcBoton} className="btn btn-danger" onClick={() => { this.ClickSetDescuentoProd(porcBoton, true) }} style={this.Estilos.BtnDanger}>Remover descuento</button>
                            ) : (null)
                        }

                    </center>
                </div>
            );
        }
    }

    GetHtmlDescEfectivo() {
        if (this.Orden ) {
            if (this.Orden.dctoEfec > 0) {
                //swal({
                //    title: "Descuento ya aplicado",
                //    text: "¿Desea eliminar el descuento ya ingresado?",
                //    icon: "warning",
                //    buttons: true,
                //    dangerMode: true,
                //}).then((willDelete) => {
                //    if (willDelete) {
                //        var newOrden = this.Orden;
                //        delete newOrden.dctoEfec;
                //        //newOrden.dctoEfec = null;
                //        this.props.ActualizarEstado({ orden: newOrden });
                //        this.props.VolverToPagar();
                //        this.DesactivarOpciones();
                //        this.CerrarModal();
                //    } else {

                //    }
                //});
            }
            else {
                return (<TecladoNumerico  ActualizarEstado={this.props.ActualizarEstado} orden={this.Orden} CerrarModal={this.CerrarModal} CerrarModalMaster={this.CerrarModal} descEfectivo={true} />);
            }
        }
    }

    GetHtmlBorrarDescOrden() {
        if (this.props.orden.dctoOrden > 0) {
            return (
                <button className="btn btn-danger" onClick={() => { this.ClickDescuento(this.props.orden.dctoOrden) }} style={this.Estilos.FullAncho}>Remover descuento</button>
                );
        }
        return null;
    }

    render() {
        return (
            <Popup
                onClose={() => { this.props.ActualizarEstadoPagar({ activarDescuento: false }) }}
                contentStyle={this.Estilos.EstiloPopup}
                defaultOpen
                modal
                closeOnDocumentClick>
                <div>
                    <button name="OpcPrincipales" id="btnDescOrden" className="btn btn-dark" style={this.Estilos.btnDescuento} onClick={() => { this.ClickDescOrden() }}>Descontar la orden</button>
                    <button name="OpcPrincipales" id="btnDescArt" className="btn btn-dark" style={this.Estilos.btnDescuento} onClick={() => { this.ClickDescArt() }} >Descontar artículo</button>
                    <button name="OpcPrincipales" id="btnDescEfect" className="btn btn-dark" style={this.Estilos.btnDescuento} onClick={() => { this.ClickDescEfec() }} >Descuento en efectivo</button>
                     <hr/>
                    
                    {
                        (this.state.dctoOrden) ? (
                            <div>

                                <center>
                                    <table className="row" style={this.Estilos.FullAncho}>
                                        {
                                            this.state.TiposDescuento.map((item) => {
                                                return (
                                                    <tr style={this.Estilos.MarginTr}>
                                                        <td style={this.Estilos.SpanStyle} rowspan="3">
                                                            <button className="btn btn-info" name="descPorc" id={'descOrden' + item.porcDescuento} onClick={() => { this.ClickDescuento(item.porcDescuento) }} style={this.Estilos.BtnStyles}>{item.porcDescuento}%</button>
                                                            <span style={this.Estilos.SpanStyle}>{item.descripcion}</span>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                );
                                            })
                                        }
                                    </table>
                                    <br />
                                    {this.GetHtmlBorrarDescOrden()}
                                </center>
                            </div>
                        )
                            : (null)
                    }

                    {
                        (this.state.dctoEfec) ? (
                            this.GetHtmlDescEfectivo()
                        ) : (null)
                    }


                    {
                        (this.state.mostrarDescProdPorc) ? (
                            this.GetHtmlDescArticulos()) : (null)
                    }

                    {
                        (this.state.dctoArt) ? (
                            <div style={this.Estilos.AlturaListaProductos}>
                                <h3>Seleccione el producto a descontar</h3>
                                <div style={this.Estilos.Escroleable}>
                                    {
                                        this.props.orden.carroProductos.map((item, i) => {
                                            const prefijo = 'optDescuento';
                                            const id = i + 'optDescuento';
                                            return <button name="optDescuento" id={prefijo + i} style={this.Estilos.BtnArt} className="btn btn-info" onClick={() => { this.ClickDescuentoProd(i, id) }} name="btnDescArt" key={prefijo + i}> {item.desprod} {(item.descuentoPorc > 0) ? (<div>({item.descuentoPorc}% descuento)</div>) : (null)} </button>
                                        })
                                    }
                                </div>
                            </div>) : (null)
                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <hr />

                    <center>
                        <button onClick={() => { this.CerrarModal() }} style={this.Estilos.BtnDanger} className="btn btn-primary"> <i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                    </center>
                </div>
            </Popup>


        );
    }
}
