import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

import Popup from "reactjs-popup";

export class MantenedorDescuentos extends Component {
    static displayName = MantenedorDescuentos.name;
    StateInicial = {
        descuentos: [],
        activarAgregarDesc: false,
        activarEditarDesc: false,
        DescSeleccionado: {}
    };
    constructor() {
        super();
        this.state = this.StateInicial;
        this.TraerDesc();
    }
    //-------->INICIO FUNCIONES CRUD
    async TraerDesc() {
        var respuesta = await fetch(GetUrlApi() + '/api/DetalleDescuentos/?sucursalID=' + GetUserCookie().SucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ descuentos: datos });
        }
    }

    async CrearDescuento() {
        var detalleDescuento = {};
        detalleDescuento.descripcion = document.getElementById('txtDesc').value;
        detalleDescuento.porcDescuento = Number( document.getElementById('txtPorc').value);
        detalleDescuento.IsHabilitado = true;
        detalleDescuento.UsuModifica = GetUserCookie().username;
        if (detalleDescuento.descripcion === '' || detalleDescuento.porcDescuento === 0) {
            swal('No puede ingresar campos en blanco');
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/DetalleDescuentos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(detalleDescuento),
        });
        if (respuesta.ok) {
            swal('Descuento creado con exito').then(() => {
                this.Recargar();
            });
        }
    }


    //-------->FIN FUNCIONES CRUD

    //-------->INICIO FUNCIONES ONCHANGE
    CambioSucursal() {
        const sucursalSeleccionada = document.getElementById('cboSucursal').value;
        var nuevasCajas = this.state.cajas.filter(p => p.sucursalID === Number(sucursalSeleccionada));
        console.log(nuevasCajas);
        this.setState({ cajasFiltradasSucursal: nuevasCajas });
    }
    //-------->FIN FUNCIONES ONCHANGE
    Estilos = {
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
            'margin-left': '3%'
        },
        Space:
        {
            'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px'
        }
    }

    GetHtmlPopupCrearDesc() {

        return (
            <Popup
                defaultOpen
                modal
                contentStyle={
                   this.Estilos.StylePopUp
                }
                onClose={() => { this.setState({ activarAgregarDesc: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Ingrese los siguientes datos</h3>
                    <br />
                    <table>
                        <tbody>

                            <tr>
                                <td>Descripción</td>
                                <td>
                                    <input defaultValue="0" className="form-control" id="txtDesc" type="text" autocomplete="off" />
                                </td>
                            </tr>
                            <tr>
                                <td>% de descuento</td>
                                <td>
                                    <input className="form-control" id="txtPorc" type="number" autocomplete="off"/>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <br />
                    <button onClick={() => { this.CrearDescuento() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupEditarDesc() {

        return (
            <Popup
                defaultOpen
                modal
                //contentStyle={}
                contentStyle={
                    this.Estilos.StylePopUp
                }
                onClose={() => { this.setState({ activarEditarDesc: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Editar Descuento</h3>
                    <table>
                        <tbody>

                            <tr>
                                <td>Descripción</td>
                                <td>
                                    <input defaultValue={this.state.DescSeleccionado.descripcion} className="form-control" id="txtDesc" type="text" /> 
                                </td>
                            </tr>
                            <tr>
                                <td>% de descuento</td>
                                <td>
                                    <input defaultValue={this.state.DescSeleccionado.porcDescuento} className="form-control" id="txtPorc" type="text" />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    //------->inicio onclick
    ClickAgregarCC() {
        this.setState({ activarAgregarDesc: true });
    }

    ClickEditarDesc(desc) {
        this.setState({ DescSeleccionado: desc, activarEditarDesc: true });
    }

    async ClickListoEditar() {
        var detalleDescuento = this.state.DescSeleccionado;
        detalleDescuento.descripcion = document.getElementById('txtDesc').value; 
        detalleDescuento.porcDescuento = Number(document.getElementById('txtPorc').value);
        detalleDescuento.IsHabilitado = true;
        detalleDescuento.UsuModifica = GetUserCookie().username;
        if (detalleDescuento.descripcion === '' || detalleDescuento.porcDescuento === 0) {
            swal('No puede ingresar campos en blanco');
            return;
        }
        if (detalleDescuento.Nombre === '' || detalleDescuento.Apellido === '') {
            swal('No puede ingresar campos en blanco');
            return;
        } 
        var respuesta = await fetch(GetUrlApi() + '/api/DetalleDescuentos/' + detalleDescuento.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(detalleDescuento),
        });
        if (respuesta.ok) {
            swal('Cuenta de la casa editada con exito').then(() => {
                this.Recargar();
            });
        }
    }

    async ClickInhabilitarDesc(id) {
        swal({
            title: "¿Está seguro que desea dehabilitar este descuento?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Descuento deshabilitado", {
                    icon: "success",
                }).then(async () => {
                    var respuesta = await fetch(GetUrlApi() + '/api/DetalleDescuentos/' + id, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        swal('Descuento deshabilitado con exito');
                        this.Recargar();
                    }
                    else {

                    }

                });
            } else {

            }
        });
    }
    //------->fin onclick


    Recargar() {
        this.setState(this.StateInicial);
        this.TraerDesc();
    }


    render() {
        return (
            <div>
                {
                    (this.state.activarAgregarDesc) ? (this.GetHtmlPopupCrearDesc()) : (null)
                }
                {
                    (this.state.activarEditarDesc) ? (this.GetHtmlPopupEditarDesc()) : (null)
                }

                <div className="alert alert-info" role="alert">
                    <strong> Descuentos</strong>

                </div>
                <button onClick={() => {
                    this.ClickAgregarCC()
                }} type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                    <i style={this.Estilos.Space} class="fa fa-plus-circle" aria-hidden="true"></i>
                    Agregar descuento</button>
                <table className="table" style={this.Estilos.TableStyle}>
                    <thead>

                        <tr>
                            <th>Descripcion</th>
                            <th>% de descuento</th>
                            <th>Fecha de ingreso</th>
                            <th>Acciones</th>
                        </tr>

                    </thead>
                    <tbody>
                        {
                            this.state.descuentos.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item.descripcion}</td>
                                        <td> {item.porcDescuento}</td>
                                        <td>{new Date(item.fechaCreacion).toLocaleDateString()}</td>
                                        <td>
                                            <button style={this.Estilos.BtnOpt} onClick={() => { this.ClickEditarDesc(item) }} data-toggle="tooltip" data-placement="right" title="Editar" >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                            <button style={this.Estilos.BtnOpt1} onClick={() => { this.ClickInhabilitarDesc(item.id) }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                <i class="fa fa-trash " aria-hidden="true"></i></button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

            </div>
        );
    }
}
