import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import swal from 'sweetalert';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ResumenInformeVentas } from './ResumenInformeVentas';
import { AnalisisVentas } from './AnalisisVentas';
import { RSalidaDeCajero } from './RSalidaDeCajero';
import { Indicadores } from './Indicadores';
import { Replicacion } from './Replicacion';
import { CorteDeCaja } from './CorteDeCaja';

export class Reportes extends Component {
    static displayName = Reportes.name;


    constructor() {
        super();
        this.state = {
            GananciasTotales: 0,
            fechaSeleccionada: false,
            cantTransacciones: 0,
            promedioPorVenta: 0,
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false,
            cargandoGraficos: false,
            volverAlIncio: false,
            dropdownOpen: false,
            dropdownOpenDash: false,
            activarResVentas: false,
            activarAnalisisVentas: false,
            activarSalidaCajero: false,
            activarCorteCaja: false,
            activarIndicadores: true,
            activarReplicacion: false
        }
        this.toggle = this.toggle.bind(this);
        this.toggles = this.toggles.bind(this);
    }
    toggles() {
        this.setState({
            dropdownOpenDash: !this.state.dropdownOpenDash
        });
    }
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    Estilos = {
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '80%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '40%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '0',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }

    }

    componentDidMount() {

        const width = window.screen.availWidth;

        const height = window.screen.availHeight;

        if (width < 1000) {

            this.HacerResponsive();

        }

    }



    HacerResponsive() {

        var elements = Array.from(document.getElementsByTagName("*"));
        elements.forEach((item) => {
            item.style.width = '100%';
        });

    }

    ClickVolver() {
        this.setState({ volverAlIncio: true });

    }
    DesactivarOpciones() {
        this.setState({
            activarResVentas: false,
            activarAnalisisVentas: false,
            activarSalidaCajero: false,
            activarIndicadores: false,
            activarReplicacion: false,
            activarCorteCaja: false
        });
    }

    render() {

        return (

            <div style={this.Estilos.EstiloPaginaCompleta} className="row">

                {

                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)

                }









                <div style={this.Estilos.Columna1} className="column">


                    <button onClick={() => { this.DesactivarOpciones(); this.setState({ activarIndicadores: true }) }} style={this.Estilos.FullAncho} className="btn btn-dark">Dashboard</button>

                    <ButtonDropdown direction="right" style={this.Estilos.FullAlto} className="btn btn-dark" isOpen={this.state.dropdownOpen} toggle={this.toggle}>

                        <DropdownToggle >

                            Reportes

                         </DropdownToggle>

                        <DropdownMenu>



                            <DropdownItem onClick={() => { this.DesactivarOpciones(); this.setState({ activarResVentas: true }) }}>Resumen de Ventas</DropdownItem>

                            <DropdownItem onClick={() => { this.DesactivarOpciones(); this.setState({ activarCorteCaja: true }) }}>Corte de Caja</DropdownItem>



                        </DropdownMenu>

                    </ButtonDropdown>

                    <button onClick={() => { this.ClickVolver() }} style={this.Estilos.FullAncho} className="btn btn-dark">Volver</button>

                </div>



                <div style={this.Estilos.Columna2} className="column">

                    {
                        (this.state.activarIndicadores) ? (<Indicadores />) : (null)
                    }
                    {
                        (this.state.activarReplicacion) ? (<Replicacion />) : (null)
                    }
                    {
                        (this.state.activarResVentas) ? (<ResumenInformeVentas />) : (null)
                    }

                    {
                        (this.state.activarAnalisisVentas) ? (<AnalisisVentas />) : (null)
                    }
                    {
                        (this.state.activarSalidaCajero) ? (<RSalidaDeCajero />) : (null)
                    }
                    {
                        (this.state.activarCorteCaja) ? (<CorteDeCaja />) : (null)
                    }
                </div>

            </div>

        );

    }

}