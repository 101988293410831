import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
//import './Estilos.css';
import Popup from "reactjs-popup";
import { TecladoNumerico } from './TecladoNumerico';
import swal from 'sweetalert';
import CurrencyFormat from "react-currency-format";


export class DescuentosParaLlevar extends Component {
    static displayName = DescuentosParaLlevar.name;
    Orden = {};
    IndiceProdSelect = 0;
    TotalOrden = 0;
    CodAut = '';
    DescuentoAplicable = 0;
    PorcDescuentoAplicable = 0;
    descuentoNum = '';
    autUp = false
    constructor(props) {
        super(props);
        this.state = {
            dctoOrden: true,
            dctoArt: false,
            dctoEfec: false,
            TiposDescuento: [],
            mostrarDescProdPorc: false,
            mostrarDescEfectivoPorc: false,
            IsDescuentoRealizado: false,
            IsAutorizado: false,
            IsDescuentoProducto:false,
            IsDescuentoVenta:false,
            Descuentoefectivo:false,
            DescEnPorcentaje:0,
            maxDescGlobal:localStorage.getItem('maxDescGlobal')
        }
        if (this.props.CerrarModal)
            this.CerrarModalMaster = this.props.CerrarModalMaster;
        if (this.props.orden)
            this.Orden = this.props.orden;
        if (this.props.ActualizarEstado)
            this.ActualizarEstado = this.props.ActualizarEstado;

        this.TotalOrden = this.props.TotalOrden;
        console.log('TotalDesdeOrden');
        console.log(this.TotalOrden);
        this.TraerTiposDescuentos().then(() => {
            if (document.getElementById('descOrden' + this.Orden.dctoOrden)) {
                document.getElementById('descOrden' + this.Orden.dctoOrden).className = 'btn btn-light';
            }
            
        });

        document.onkeypress = (e) => {
            if(!this.state.IsAutorizado){
                const element = document.getElementById('ValorDescuento');
                if (element == null) {
                    console.log("Element no encontrado");
                    return;
                }
                const val = document.getElementById('ValorDescuento').value;

                if (val.length >= 6 && val.length <= 9) {
                    document.getElementById('ValorDescuento').style.fontSize = "3em";
                } else if (val.length > 10 && val.length <= 20) {
                    document.getElementById('ValorDescuento').style.fontSize = "1.5em";

                }
                else if (val.length > 20 && val.length) {
                    document.getElementById('ValorDescuento').style.fontSize = "0.5em";

                } else if (val.length < 6) {
                    document.getElementById('ValorDescuento').style.fontSize = "3em";
                }
            }
            if(!isNaN(e.key)){
                if(!this.state.IsAutorizado){
                    this.descuentoNum += e.key;
                    document.getElementById('ValorDescuento').value += e.key;
                    document.getElementById('ValorDescuento').value = Number(this.descuentoNum).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
                }
                else{
                    this.ClickTecladoCantidadAut(e.key)
                }
            }
            else if (e.key === 'Enter') {
                
                if(!this.state.IsAutorizado)
                    setTimeout(() => { this.ClickEnterDescuento() }, 1000);
                else
                    this.ValidarUsuario();   
                //this.ClickSelectCantidad();

                //this.props.CerrarModalMaster();
                //var modales = document.getElementById("TecladoNumericoD");
                //modales.style.visibility = "hidden";
            }
            else if (e.keyCode === 'Delete' || e.keyCode === 'Backspace' || e.key === 'c' || e.key === 'C') {
                if(!this.state.IsAutorizado)
                    this.ClearInputDescuento();
                else
                    this.ClickCED()
            } else if (e.keyCode === 'Escape') {
                this.CerrarModal2();
            }
            
        };

    }

   async TraerTiposDescuentos()
    {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/DetalleDescuento/GetDescuentos');
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            const datos = await categorias.json();
            console.log(datos)
            this.setState({ TiposDescuento: datos });
        } catch (err) {
            swal('No se pudo traer las categorias verifique su conexion a internet');
        }

    }

    componentDidMount() {
        this.autUp = true
        if (this.props.CerrarModal)
            this.CerrarModal = this.props.CerrarModal;
        document.getElementById('btnDescOrden').className = 'btn btn-light';

        //if (this.Orden.dctoOrden > 0 && document.getElementById('descOrden' + this.Orden.dctoOrden)) {
        //    document.getElementById('descOrden' + this.Orden.dctoOrden).className = 'btn btn-light';
        //}
        //else if (this.Orden.dctoOrden === 20) {
        //    document.getElementById('descOrden20').className = 'btn btn-light';
        //}
        //else if (this.Orden.dctoOrden === 10) {
        //    document.getElementById('descOrden10').className = 'btn btn-light';
        //}
    }

    DesactivarOpciones() {
        console.log(this.state);
        this.setState({
            dctoOrden: false,
            dctoArt: false,
            dctoEfec: false,
            Descuentoefectivo: false,
            mostrarDescEfectivoPorc:false,
            mostrarDescProdPorc: false
        });
    }

    ReseatearBotones() {
        var botones = document.getElementsByName('OpcPrincipales');
        botones.forEach((value) => {
            value.className = 'btn btn-dark';
        });
    }

    ReseatearBotonesPorcentaje() {
        var botones = document.getElementsByName('descPorc');
        botones.forEach((value) => {
            value.className = 'btn btn-light';
        });
    }
    CerrarModal() {
        //sobreescrito por prop
    }

    async ClickDescuento(descuento) {
        this.setState({DescEnPorcentaje:descuento});
        
        if (this.props.ActualizarEstado) {
            
            if (this.props.orden.dctoOrden) {
                var neworden = this.props.orden;
                delete neworden.dctoOrden;
                this.setState({ IsDescuentoRealizado:true});
                await this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento, IsDescuentoRealizado: false } );//actualiza paraLlevar
                this.ActualizarTexto();
                this.CerrarModal();
            }
            else {
                var neworden = this.props.orden;
                neworden.dctoOrden = descuento;
                neworden.dctoArt = 0;
                neworden.dctoEfec = 0;
                var porcentajeMinimo = localStorage.getItem("porcentMinimoSolicitudAutorizDesc");

                if (descuento < parseInt(porcentajeMinimo, 10))
                {
                    //this.setState({ IsAutorizado: true, Descuentoefectivo: true, IsDescuentoVenta: true, mostrarDescEfectivoPorc: true });
                    await this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento, IsDescuentoRealizado: true });//actualiza paraLlevar
                    this.ActualizarTexto();
                    this.CerrarModal();
                }
                else
                {
                    this.setState({ IsAutorizado: true, Descuentoefectivo: true, IsDescuentoVenta: true, mostrarDescEfectivoPorc: false });
                    //document.getElementById('PINboxDosAut').focus();
                  // await this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento, IsDescuentoRealizado: true });//actualiza paraLlevar
                   // this.ActualizarTexto();
                }

                //this.setState({ IsAutorizado: true, Descuentoefectivo: true, IsDescuentoVenta: true, mostrarDescEfectivoPorc: true });
                //await this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento, IsDescuentoRealizado: true } );//actualiza paraLlevar
                //this.ActualizarTexto();
                //this.CerrarModal();
            }
        }
    }

    ClickSetDescuentoProd(descuento, yaClickeado) {
        if (yaClickeado) {
            var neworden = this.props.orden;
            delete neworden.carroProductos[this.IndiceProdSelect].descuentoPorc;

            this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento });//actualiza paraLlevar
            //this.ActualizarTexto();
            this.props.CerrarModal();
        }
        else {
            var neworden = this.props.orden;
            neworden.carroProductos[this.IndiceProdSelect].descuentoPorc = descuento;
            console.log(neworden);
            this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento });//actualiza paraLlevar
            //this.ActualizarTexto();
            this.props.CerrarModal();
        }
        
    }

    ClickDescuentoProd(indice) {
        this.IndiceProdSelect = indice;
        this.setState({ mostrarDescProdPorc: true, dctoArt:false });

    }


    ClickDescOrden() {
        this.ReseatearBotones();
        document.getElementById('btnDescOrden').className ='btn btn-light';
        this.DesactivarOpciones();
        this.setState({ dctoOrden: true });
    }

    ClickDescArt() {
        this.ReseatearBotones();
        document.getElementById('btnDescArt').className = 'btn btn-light';
        this.DesactivarOpciones();
        this.setState({ dctoArt: true, IsDescuentoProducto:true });
    }

    ClickDescEfec() {
        this.ReseatearBotones();
        document.getElementById('btnDescEfect').className = 'btn btn-light';
        this.DesactivarOpciones();
        if (this.Orden.dctoEfec>0) {
            swal({
                title: "Descuento ya aplicado",
                text: "¿Desea eliminar el descuento ya ingresado?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    var newOrden = this.Orden;
                    delete newOrden.dctoEfec;
                    //newOrden.dctoEfec = null;
                    this.props.ActualizarEstado({ orden: newOrden });
                    this.CerrarModal();
                }
            });
        }
        this.setState({ dctoEfec: true });
    }


    ClickDescEfecValid() {
        this.ReseatearBotones();
        document.getElementById('btnDescEfect').className = 'btn btn-light';
        this.DesactivarOpciones();

        this.setState({ Descuentoefectivo: true, IsDescuentoVenta: true, mostrarDescEfectivoPorc:true });
    }

    CerrarModal() {
        this.setState({ dctoEfec: false });
    }

    CerrarModal2() {
        this.setState({ dctoEfec: false });
        this.ClickDescuento(this.props.orden.dctoOrden);
    }

    Estilos = {
        InputSize:
        {
            'width': '80%',
            'height': '60px',
            'font-size': '4em',
            'text-align': 'center'
        },
        EstiloPopupAut: {
            'width': '30%',
            'height': '65%',
            'border-radius': '8px',
            'marginLeft': '25%'
            //'margin': 'initial'
        },
        FuenteAdvertencia: {
            'color': 'red'
        },
        //btnDescuento: {
        //    'width': '15%',
        //    'fontSize': '12px'
        //},
        btnDescuentoOld: {
            'width': '33%',
            'fontSize': '19px'
        },

        TercioAncho: {
            'width': '33%'
        },
        FullAncho: {
            'width': '50%',
            'margin-top': '-3%',
            'marginLeft': '-6%'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '100%'
        },
        AlturaListaProductos: {
            'height': '42%'
        },
        EstiloPopup: {
            'width': '47%',
            'height': '70%',
            'border-radius': '2%'
        },
        MarginTr:
        {
            'margin-bottom': '5%'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '101px',
            'text-decoration': 'solid',
            'fontSize': '12px'
        },
        SpanStyle_old:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '101px',
            'text-decoration': 'solid',
            'fontSize': '12px'
        },
        BtnStyles:
        {
            'width': '100px',
            'height': '30px',
            'background-color': '#769e8e',
            'fontSize': '16px',
        },
        BtnStyles_old:
        {
            'width': '143px',
            'height': '60px',
            'background-color': '#769e8e',
            'fontSize': '28px',
        },

        BtnStylesM:
        {
            'width': '120px',
            'height': '50px',
            'background-color': 'rgb(207, 240, 227)'
        },
        BtnDanger:
        {
            'width': '50%',
            'margin-top': '-3%',
            'background-color': '#f80303ad'
        },
        BtnArt:
        {
            'width': '40%',
            'height': '60px',
            'marginRight': '2%',
            'margin-bottom': '2%',
            'background-color': 'rgb(118, 158, 142)',
            'borderColor': '#17a2b8',
            'font-size': '80%',
            'marginLeft': '8%'
        },
        BtnTecladoDescuento:
        {
            'width': '4rem',
            'height': '4rem',
           // 'background': 'rgb(158 103 83)',
            'fontSize': '2rem',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'fontWeight': 'bold'
        },
        BtnTecladoDescuentoSinBack:
        {
               'width': '70px',
               'height': '65px',
               //'background': '#c6d6cf',
               'font-size': '20px',
               'border-radius': '25%'
        },
        InputDescuento:
        {
                'width': '50%',
                'height': '60px',
                'borderRadius': '10px',
                'fontSize': '35px',
                'background': '#dcf3f3',
                'textAlign': 'center'
        }
    }

    //--------SOBRESCRIBIR
    CerrarModalMaster() { }

    ActualizarEstado() {
        console.log('ActualizarEstado no actualizo');
    }
    ActualizarTexto() {
        console.log('ActualizarTexto no actualizo');
    }
    
    //--------FIN SOBRESCRIBIR
    ClickTecladoDescuento(num)
    {
        var val = document.getElementById('ValorDescuento').value;
        console.log(num);
        val += num;
        var num = this.formatNumber(val);
        document.getElementById('ValorDescuento').value = num;
    }

    formatNumber(n) {
        //n = String(n).replace(/\D/g, "");
        //n = String(n).replace(/\D/g, "");
        n = String(n).replaceAll('.','').replaceAll(',','')
        return n === '' ? n : Number(n).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
    }


    async ClickEnterDescuento() {
        var val = document.getElementById('ValorDescuento').value;
        var valReplace = val.replaceAll('.','').replaceAll(',','');
        var porcDescuento = 0;
        var valint = Number(valReplace);
        this.DescuentoAplicable = valint;

        var porcentajeMinimo = localStorage.getItem("porcentMinimoSolicitudAutorizDesc");

        if (this.TotalOrden != 0) {
            //porcDescuento = Math.Round(Number((cantidad * 100) / this.TotalOrdenDesc));
            var totalT = Number(this.TotalOrden);
            var cantidadT = Number(valint);

            porcDescuento = (cantidadT * 100) / totalT;
            //porcDescuento = Math.round(porcDescuento);
        }

        
         let errMsg='';
         let errNumber=0;
            let amountMaxGl = (Number(this.props.TotalOrden) * Number(this.state.maxDescGlobal)) / 100

            if(Number(valReplace) > amountMaxGl){
                errMsg = 'El monto no debe superar el maximo de descuento global configurado (' + this.state.maxDescGlobal + '%)' 
                errNumber = 1
            }

            if (errMsg!=''){
                swal({
                    title: errMsg,
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
        
         

        this.PorcDescuentoAplicable = porcDescuento;
        if (this.state.IsDescuentoProducto) {
/*            if (porcDescuento <= 5) {*/
            if (porcDescuento < parseInt(porcentajeMinimo, 10))
            {
                    //var neworden = this.props.orden;
                    //neworden.descEfectivo = valint;
                    //neworden.dctoEfec = valint;

                    //await this.props.ActualizarEstado({ orden: neworden, IsDescuentoRealizado: true });//actualiza paraLlevar
                    //this.ActualizarTexto();
                    //this.CerrarModal();
                    var neworden = this.props.orden;
                    neworden.carroProductos[this.IndiceProdSelect].descuentoPorc = porcDescuento;
                    console.log(neworden);
                    this.props.ActualizarEstado({ orden: neworden, dctoPorc: porcDescuento, IsDescuentoRealizado: true });//actualiza paraLlevar
                    this.props.CerrarModal();
                
            } else {
                //var neworden = this.props.orden;
                //neworden.carroProductos[this.IndiceProdSelect].descuentoPorc = porcDescuento;
                //console.log(neworden);
                //this.props.ActualizarEstado({ orden: neworden, dctoPorc: descuento, IsDescuentoRealizado: true});//actualiza paraLlevar
                //this.props.CerrarModal();
                this.setState({ IsAutorizado: true });
                //document.getElementById('PINboxDosAut').focus();
            }
        } else if (this.state.IsDescuentoVenta) {
            //if (porcDescuento <= 5) {
            if (porcDescuento < parseInt(porcentajeMinimo, 10)) {
                if (this.props.ActualizarEstado) {
                    var neworden = this.props.orden;
                    neworden.descEfectivo = valint;
                    neworden.dctoEfec = valint;
                    await this.props.ActualizarEstado({ orden: neworden, IsDescuentoRealizado: true });//actualiza paraLlevar
                    this.ActualizarTexto();
                    this.CerrarModal();
                }
            } else {
                this.setState({ IsAutorizado: true });
            }
        }
      
    }

    ClearInputDescuento()
    {
        this.descuentoNum = '';
        document.getElementById('ValorDescuento').value = '';
    }

    GetHtmlDescArticulos() {
        var hayDescuento = false;
        var porcBoton = 0;
        if (this.Orden) {
            this.Orden.carroProductos.forEach((item, i) => {
                if (item.descuentoPorc > 0 && i === this.IndiceProdSelect) {
                    hayDescuento = true;
                    porcBoton = item.descuentoPorc;
                }
            });
        }
        return (
            <div>
                <center>
                    <table className="row" style={this.Estilos.FullAncho}>
                       
                        
                            {
                            this.state.TiposDescuento.map((item) => {
                                var isSelected = item.porcDescuento === porcBoton;
                                var textclass = '';
                                if (isSelected) {
                                    textclass = 'btn btn-light';
                                }
                                else {
                                    textclass = 'btn btn-info';
                                }
                                    return (
                                        <React.Fragment>
                                            <tr style={this.Estilos.MarginTr}>
                                                <td style={this.Estilos.SpanStyle} rowspan="1">
                                                    <button className={textclass} id={'descUnitario' + item.porcDescuento} onClick={() => { this.ClickSetDescuentoProd(item.porcDescuento) }} style={this.Estilos.BtnStyles}>{item.porcDescuento}%</button>

                                                <span style={this.Estilos.SpanStyle}>{item.descripcion}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            <td></td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })

                            }
                            
                            
                        
                        
                    </table><br />
                    {
                        (porcBoton > 0) ? (
                            <button id={'descUnitario' + porcBoton} className="btn btn-danger" onClick={() => { this.ClickSetDescuentoProd(porcBoton, true) }} style={this.Estilos.BtnDanger}>Remover descuento</button>
                        ): (null)
                    }
                    
                </center>
            </div>
            );
    }


    GetHtmlDescArticulos() {
        var hayDescuento = false;
        var porcBoton = 0;
        if (this.Orden) {
            this.Orden.carroProductos.forEach((item, i) => {
                if (item.descuentoPorc > 0 && i === this.IndiceProdSelect) {
                    hayDescuento = true;
                    porcBoton = item.descuentoPorc;
                }
            });
        }
        return (
            <div>
                <center>
                    <input style={this.Estilos.InputDescuento} id='ValorDescuento' name='ValorDescuento' disabled />
                    <table id="tablaBotonesD">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(1) }} type='button' className='btn' name='1' value='1' id='1' /></td>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(2) }} type='button' className='btn' name='2' value='2' id='2' /></td>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(3) }} type='button' className='btn' name='3' value='3' id='3' /></td>

                               
                            </tr>
                            <tr>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(4) }} type='button' className='btn' name='4' value='4' id='4' /></td>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(5) }} type='button' className='btn' name='5' value='5' id='5' /></td>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(6) }} type='button' className='btn' name='6' value='6' id='6' /></td>
                            </tr>
                            <tr>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(7) }} type='button' className='btn' name='7' value='7' id='7' /></td>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(8) }} type='button' className='btn' name='8' value='8' id='8' /></td>
                                <td style={this.Estilos.BtnTecladoDescuento}><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(9) }} type='button' className='btn' name='9' value='9' id='9' /></td>
                            </tr>
                            <tr>
                                <td style={this.Estilos.BtnTecladoDescuentoSinBack}><input style={this.Estilos.BtnTecladoDescuentoSinBack} onClick={() => { this.ClearInputDescuento() }} type='button' className='btn btn-success' name='-' value='clear' id='-' /></td>
                                <td style={this.Estilos.BtnTecladoDescuento}> <input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(0) }} type='button' className='btn' name='0' value='0' id='0' /></td>
                                <td style={this.Estilos.BtnTecladoDescuentoSinBack}><input style={this.Estilos.BtnTecladoDescuentoSinBack} onClick={() => { this.ClickEnterDescuento() }} type='button' className='btn btn-primary' value='enter' id='+' /></td>
                            </tr>
                        </tbody>
                    </table>
                    {
                        (porcBoton > 0) ? (
                            <button id={'descUnitario' + porcBoton} className="btn btn-danger" onClick={() => { this.ClickSetDescuentoProd(porcBoton, true) }} style={this.Estilos.BtnDanger}>Remover descuento</button>
                        ) : (null)
                    }

                </center>
            </div>
        );
    }

    GetHtmlDescEfectivoArticulos() {
        return (
            <div>
                <center>
                    <input style={this.Estilos.InputDescuento} id='ValorDescuento' name='ValorDescuento' disabled />

                    <table id="tablaBotonesD">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input style={this.Estilos.BtnTecladoDescuento}   onClick={() => { this.ClickTecladoDescuento(1) }} type='button' className='btn' name='1' value='1' id='1' /></td>
                                <td><input style={this.Estilos.BtnTecladoDescuento}  onClick={() => { this.ClickTecladoDescuento(2) }} type='button' className='btn' name='2' value='2' id='2' /></td>
                                <td><input style={this.Estilos.BtnTecladoDescuento}   onClick={() => { this.ClickTecladoDescuento(3) }} type='button' className='btn' name='3' value='3' id='3' /></td>


                            </tr>
                            <tr>
                                <td><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(4) }} type='button' className='btn' name='4' value='4' id='4' /></td>
                                <td><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(5) }} type='button' className='btn' name='5' value='5' id='5' /></td>
                                <td><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(6) }} type='button' className='btn' name='6' value='6' id='6' /></td>
                            </tr>
                            <tr>
                                <td><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(7) }} type='button' className='btn' name='7' value='7' id='7' /></td>
                                <td><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(8) }} type='button' className='btn' name='8' value='8' id='8' /></td>
                                <td><input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(9) }} type='button' className='btn' name='9' value='9' id='9' /></td>
                            </tr>
                            <tr>
                                <td><input style={this.Estilos.BtnTecladoDescuentoSinBack} onClick={() => { this.ClearInputDescuento() }} type='button' className='btn btn-danger' name='-' value='clear' id='-' /></td>
                                <td> <input style={this.Estilos.BtnTecladoDescuento} onClick={() => { this.ClickTecladoDescuento(0) }} type='button' className='btn' name='0' value='0' id='0' /></td>
                                <td><input style={this.Estilos.BtnTecladoDescuentoSinBack} onClick={() => { this.ClickEnterDescuento() }} type='button' className='btn btn-success' value='enter' id='+' /></td>
                            </tr>
                        </tbody>
                    </table>
                    

                </center>
            </div>
        );
    }

    GetHtmlDescEfectivo() {
        if (this.Orden) {
            if (this.Orden.dctoEfec > 0) {
                //swal({
                //    title: "Descuento ya aplicado",
                //    text: "¿Desea eliminar el descuento ya ingresado?",
                //    icon: "warning",
                //    buttons: true,
                //    dangerMode: true,
                //}).then((willDelete) => {
                //    if (willDelete) {
                //        var newOrden = this.Orden;
                //        delete newOrden.dctoEfec;
                //        //newOrden.dctoEfec = null;
                //        this.props.ActualizarEstado({ orden: newOrden });
                //        this.CerrarModalMaster();
                //    } 
                //});
            }
            else {
                return (<TecladoNumerico
                    ActualizarTexto={this.ActualizarTexto}
                    ActualizarEstado={this.props.ActualizarEstado}
                    orden={this.Orden}
                    CerrarModal={this.CerrarModal}
                    CerrarModalMaster={this.CerrarModalMaster}
                    descEfectivo={true}
                    TotalOrdenDesc={this.TotalOrden}
                />);
            }
        }

    }
    ClickTecladoCantidadAut(num) {
        if(document.getElementById('PINboxDosAut')){
            var val = document.getElementById('PINboxDosAut').value;
            this.CodAut += num;
                //document.getElementById('PINbox').type = 'text';
            document.getElementById('PINboxDosAut').value =this.CodAut;
            // document.getElementById('PINboxDosAut').focus();
        }
    }
    ClickCED() {
        this.CodAut = "";
        document.getElementById('PINboxDosAut').value = '';
    }

    async ValidarUsuario()
    {
        if(this.autUp) {
            if(this.CodAut != ''){
            
                var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + this.CodAut + '?pin=true', {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (respuesta.ok) {
                    var user = await respuesta.json();
                    if (user.tipoUsuarioID === 1 || user.tipoUsuarioID === 2) {
                        if (this.state.IsDescuentoProducto) {
                            var neworden = this.props.orden;
                            neworden.carroProductos[this.IndiceProdSelect].descuentoPorc = this.PorcDescuentoAplicable;
                            console.log(neworden);
                            this.props.ActualizarEstado({ orden: neworden, dctoPorc: this.PorcDescuentoAplicable, IsDescuentoRealizado: true});//actualiza paraLlevar
                            this.props.CerrarModal();

                            //var neworden = this.props.orden;
                            //neworden.descEfectivo = this.DescuentoAplicable;
                            //neworden.dctoEfec = this.DescuentoAplicable;

                            //await this.props.ActualizarEstado({ orden: neworden, IsDescuentoRealizado: true });//actualiza paraLlevar
                            //this.ActualizarTexto();
                            //this.CerrarModal();
                            //this.setState({ IsAutorizado: false});

                        } else if(this.state.IsDescuentoVenta) {
                            var neworden = this.props.orden;
                            if (this.state.DescEnPorcentaje>0){
                                neworden.dctoOrden=this.state.DescEnPorcentaje;
                            }else{
                                neworden.descEfectivo = this.DescuentoAplicable;
                                neworden.dctoEfec = this.DescuentoAplicable;
                            }
                            await this.props.ActualizarEstado({ orden: neworden, IsDescuentoRealizado: true });//actualiza paraLlevar
                            this.ActualizarTexto();
                            this.props.CerrarModal();
                            this.setState({ IsAutorizado: false });

                        }
                    }
                    else {
                        swal('Perfil de usuario no valido');
                    }
                    this.CodAut = ''

                }
                else {
                    swal({
                        title: "Notificación",
                        text: "Usuario invalido",
                        icon: "danger",
                        dangerMode: true,
                    })
                }
            } else {
                swal('Perfil de usuario no valido');
            }
            this.autUp = false
        }
    }
    GetHtmlAutorizado() {

        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupAut}
                modal
                closeOnDocumentClick={false}
                //onClose={() => { this.setState({ activarPopNomCliente: false }) }}
                >
                <center>
                    <div>
                        <p><strong>Ingrese Código de Administrador</strong></p>
                        <p style={this.Estilos.FuenteAdvertencia}><strong>Si autoriza el descuento esté será notificado al supervisor Correspondiente</strong></p>
                    <input id='PINboxDosAut' style={this.Estilos.InputSize} type="password" name='PINboxDosAut' disabled />


                    <table id="tablaBotones">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>

                            </tr>
                            <tr>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>

                            </tr>
                            <tr>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                <td> <input onClick={() => { this.ClickTecladoCantidadAut(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>

                            </tr>
                            <tr>
                                <td><input onClick={() => { this.ClickCED() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                <td> <input onClick={() => { this.ClickTecladoCantidadAut(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                <td><input onClick={() => { this.ValidarUsuario() }} type='button' className='PINbutton enter' name='+' value='enter' id='+' /></td>

                            </tr>
                            <tr>
                                <td colspan='3' align='center'>
                                    <button onClick={() => { this.CerrarModal2() }} style={this.Estilos.BtnDanger} className="btn btn-secondary"> <small> <i className="fa fa-times-circle fa-2x" aria-hidden="true">&nbsp; Cancelar </i></small></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    </center>
            </Popup>
        );

    }

    GetHtmlBorrarDescOrden() {
        if (this.props.orden.dctoOrden > 0) {
            return (
                <button className="btn btn-danger" onClick={() => { this.ClickDescuento(this.props.orden.dctoOrden) }} style={this.Estilos.FullAncho}>Remover descuento</button>
            );
        }
        return null;
    }

    render() {
        return (
            <Popup
                onClose={() => { this.CerrarModal() }}
                contentStyle={this.Estilos.EstiloPopup}
                defaultOpen
                modal
                closeOnDocumentClick>
                <button name="OpcPrincipales" id="btnDescOrden" className="btn btn-dark" style={this.Estilos.btnDescuento} onClick={() => { this.ClickDescOrden() }}>Descontar la orden</button>
                <button name="OpcPrincipales" id="btnDescArt" className="btn btn-dark" style={this.Estilos.btnDescuento} onClick={() => { this.ClickDescArt() }} hidden="hidden" >Descontar artículo</button>
                <button name="OpcPrincipales" id="btnDescEfect" className="btn btn-dark" style={this.Estilos.btnDescuento} onClick={() => { this.ClickDescEfecValid() }}>Descuento en efectivo</button>
                <hr />
                {
                    (this.state.dctoOrden) ? (
                        <div >
                            <center>
                                <table className="row" style={this.Estilos.FullAncho}>
                                    {
                                        this.state.TiposDescuento.map((item) => {
                                            return (
                                                <tr style={this.Estilos.MarginTr}>
                                                    <td style={this.Estilos.SpanStyle} rowspan="3">
                                                        <button className="btn btn-info" name="descPorc" id={'descOrden' + item.porcDescuento} onClick={() => { this.ClickDescuento(item.porcDescuento) }} style={this.Estilos.BtnStyles}>{item.porcDescuento}%</button>
                                                        <span style={this.Estilos.SpanStyle}>{item.descripcion}</span>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                   
                                                </tr>
                                                );
                                        })
                                    }
                                    
                                </table>
                                {this.GetHtmlBorrarDescOrden()}
                            </center>
                            



                          

                        </div>
                    )
                        : (null)
                }

                {
                    (this.state.dctoEfec) ? (
                        this.GetHtmlDescEfectivo()
                    ): (null)
                }

                {
                    (this.state.IsAutorizado) ? (
                        this.GetHtmlAutorizado()
                    ): (null)
                }
                

                {
                    (this.state.mostrarDescProdPorc) ? (
                        this.GetHtmlDescArticulos()) : (null)
                }

                {
                    (this.state.mostrarDescEfectivoPorc) ? (
                        this.GetHtmlDescEfectivoArticulos()) : (null)
                }


                {
                    (this.state.dctoArt) ? (
                        <div style={this.Estilos.AlturaListaProductos}>
                            <h3>Seleccione el producto a descontar</h3>
                           
                            <div style={this.Estilos.Escroleable}>
                                {
                                    this.props.orden.carroProductos.map((item, i) => {
                                        const prefijo = 'optDescuento';
                                        const id = i + 'optDescuento';
                                        return <button name="optDescuento" id={prefijo + i} style={this.Estilos.BtnArt} className="btn btn-info" onClick={() => { this.ClickDescuentoProd(i, id) }} key={prefijo + i}> {item.desprod}{(item.descuentoPorc > 0) ? (<div>({item.descuentoPorc}% descuento)</div>) : (null)} </button>
                                    })
                                }
                            </div>
                        </div>) : (null)
                }
                <br/>
                <br/>
                <hr />

                <center>
                    <button onClick={() => { this.CerrarModal() }} style={this.Estilos.BtnDanger} className="btn btn-primary">  <i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                </center>
            </Popup>
        );
    }
}
