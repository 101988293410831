import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';


export class EditarMedioPago extends Component {
    static displayName = EditarMedioPago.name;

    constructor(props) {
        super(props);
        this.state = {
            medioSeleccionado: {},
            volverAlIncio: false,

        }
    }
    componentDidMount() {
        if (this.props.medioSeleccionado) {
            document.getElementById('txtDesc').value = this.props.medioSeleccionado.desmed;
            this.setState({ medioSeleccionado: this.props.medioSeleccionado });
        }
        else
            swal("no cargo el medio de pago seleccionado");


    }





    HacerResponsive() {
        var elements = Array.from(document.getElementsByTagName("*"));

        elements.forEach((item) => {
            item.style.width = '100%';
        });
    }

    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        btnOpciones: {
            'width': '100%',
            'height': '80px'
        },
        MitadAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: {
                'width': '50%',
                'backgroundColor': 'white',
                'borderRadius': '25px',
                'border': '2px solid #73AD21',
                'padding': '25px'
            }
        },
        Contenedor: {
            'width': '60%',
            'backgroundColor': 'white',
            'borderRadius': '25px',
            'border': '2px solid #73AD21',
            'padding': '25px'
        },
        BarraLateral: {
            'backgroundColor': 'white',
            'height ': '100%',
            'width': '100%'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
    }

    //FUNCIONES CLICK
    async ClickGuardar() {
        var txtDesc = document.getElementById('txtDesc').value;
        var categoria = document.getElementById('cboCategoria').value;
        var medio = this.state.medioSeleccionado;
        medio.desmed = txtDesc;

        medio.equivBsale = Number(categoria);
        medio.tieneNumOperacion = medio.equivBsale === 2 || medio.equivBsale === 6;
        var respuesta = await fetch(GetUrlApi() + '/api/tbmedio/' + medio.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(medio)
        });
        if (respuesta.ok) {
            swal("Guardado exitoso", {
                icon: "success",
            })
            this.props.recargar();
            this.props.actualizarEstado({ popupAgregar: false, popupEditar: false });
        }
        else {
            swal('No se pudo guardar');
        }
    }
    //FIN FUNCIONES CLICK

    //FUNCIONES HTML

    //FIN FUNCIONES HTML

    render() {
        return (
            <Popup
                modal
                onClose={() => { this.props.actualizarEstado({ popupEditar: false }) }}
                defaultOpen
                closeOnDocumentClick>
                <div>
                    <h3>Editar</h3>
                    <form class="needs-validation" novalidate>
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom01">Descripción</label>
                                <input id="txtDesc" type="text" className="form-control" />
                                <div class="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom02">Seleccione el medio de facturación</label>
                                <select id="cboCategoria" className="form-control">
                                    <option selected={this.props.medioSeleccionado.equivBsale === 1} value="1">EFECTIVO</option>
                                    <option selected={this.props.medioSeleccionado.equivBsale === 2} value="2">TARJETA DE CREDITO</option>
                                    <option selected={this.props.medioSeleccionado.equivBsale === 5} value="5">CHEQUE</option>
                                    <option selected={this.props.medioSeleccionado.equivBsale === 6} value="6">TARJETA DEBITO</option>
                                    <option value="9">GIFTCARD</option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="validationCustomUsername">Seleccione las reglas de vuelto y exceso</label>
                                <div class="input-group">
                                    <select id="cboReglas" className="form-control">
                                        <option value="1">EFECTIVO</option>
                                        <option value="2">TARJETA CREDITO/DEBITO</option>
                                        <option value="3">CUPÓN</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom03">¿Es cuenta de la casa?</label>
                                <div class="input-group">
                                    Si &nbsp;<input type="radio" id="isCC" placeholder="City" name="cboIsCC" defaultChecked={this.props.medioSeleccionado.isCuentaCasa} />&nbsp;&nbsp;
                                    No &nbsp;<input type="radio" id="validationCustom03" placeholder="City" name="cboIsCC" defaultChecked={!this.props.medioSeleccionado.isCuentaCasa} />&nbsp;&nbsp;
                                </div>

                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="validationCustom04">¿Es garantía por evento?</label>
                                <div class="input-group">
                                    Si &nbsp;<input type="radio" id="isGarantiaEvento" placeholder="City" name="cboIsGaran" defaultChecked={this.props.medioSeleccionado.isGarantiaEvento} />&nbsp;&nbsp;
                                    No &nbsp;<input type="radio" id="validationCustom03" placeholder="City" name="cboIsGaran" defaultChecked={!this.props.medioSeleccionado.isGarantiaEvento}/>&nbsp;&nbsp;
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">

                            </div>
                        </div>
                    </form>

                    <center>
                        <br/>
                        <br/>
                        <button onClick={() => { this.ClickGuardar() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                            <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                    </center>

                </div>
            </Popup>
        );
    }
}
