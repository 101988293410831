import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';
import UserProfile from './Globales/PerfilUsuario';



export class IngresosDinero extends Component {
    static displayName = IngresosDinero.name;

    constructor(props) {
        super(props);
        this.state = {
            volverAlIncio: false,
            mostrarIngresoDinero: true,
            mostrarReembolso: false
        }
        
    }

    componentDidMount() {
        //alert(UserProfile.getUser().tipoUsuario);
        var user = GetUserCookie();
        if (user.tipoUsuario.id !== 1 && user.tipoUsuario.id !== 6 && user.tipoUsuario.id !== 2 && user.tipoUsuario.id !== 3 && user.tipoUsuario.id !== 4 && user.tipoUsuario.id !== 5) {
            this.setState({ volverAlIncio: true });
        }
        else {
            document.getElementById('btnTerminar').disabled=true;
        }
        document.getElementById('btnTerminar').disabled = true;
        const width = window.screen.availWidth;
        const height = window.screen.availHeight;

        if (width < 1000) {
            this.HacerResponsive();
        }

    }

    HacerResponsive() {
        var elements = Array.from(document.getElementsByTagName("*"));
        
        elements.forEach((item) => {
            item.style.width = '100%';
        });
    }

    
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: {
                'width': '50%',
                'background-color': 'white',
                'border-radius': '25px',
                'border': '2px solid #73AD21',
                'padding': '25px'
            }
        },
        Contenedor: {
            'width': '60%',
            'background-color': 'white',
            'border-radius': '25px',
            'border': '2px solid #73AD21',
            'padding': '25px'
        },
        BarraLateral: {
            'background-color': 'white',
            'height ': '100%',
            'width': '100%'
        }
    }
    //Funciones click

    ChangeComentario() {
        if (document.getElementById('txtComentario').value.length > 0) {
            document.getElementById('btnTerminar').disabled = false;
        }
        else {
            document.getElementById('btnTerminar').disabled = true;
        }
    }


    async ClickTerminar(idTipoSalida) {
        document.getElementById('btnTerminar').disabled=true;
        var data = {};
        data.Monto = Number(document.getElementById('txtMonto').value);
        data.UsuarioID = UserProfile.getUser().id;
        data.TipoMovimiento = idTipoSalida;
        data.GavetaID = UserProfile.getUser().idGaveta;

        data.OrdenID = (document.getElementById('txtNumOrden') ? (Number(document.getElementById('txtNumOrden').value)):(null));
        data.Comentario = document.getElementById('txtComentario').value;
        data.NumeroComprobante = document.getElementById('txtNumOperacion').value;
        data.ReceptorDelDinero = document.getElementById('txtRecepcionDinero').value;
        data.IdMedioPago=8; //ingreso de dinero solo en efectivo
        data.CajaID = GetUserCookie().CajaID;
        data.IsEnabled = 1;
        var respuesta = await fetch(GetUrlApi() + '/api/MovimientoDineros?cajaId=' + data.CajaID + '&sucursalID=' + GetUserCookie().SucursalID, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
       
        if (respuesta.status) {
            var res = await respuesta.json();
            if (res.mensaje==""){
                swal('Operación realizada con exito');
                
    
    
                //se agrega aquí modulo de mensaje para poder avisar cuando se realiza un retiro de dinero.
    
                    var CajaID = data.CajaID//cajaid
                    var GavetaID = UserProfile.getUser().idGaveta;//gavetaid
                    var SucursalID = GetUserCookie().SucursalID;//sucursalid
                    var UsuariosID = UserProfile.getUser().id; // usuarioid
    
                var respuesta = await fetch(GetUrlApi() + '/api/Mensajeria/GetEnviarMensaje?ModuloPosID=4&SucursalPosID=' + SucursalID + '&UsuarioPosID=' + UsuariosID + '&GavetaID=' + GavetaID + '&CajaID=' + CajaID + '&ArqueoCaja=0&OrdenId=0', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (!respuesta.ok) {
                          console.log(respuesta);
                    }
    
                document.getElementById('btnTerminar').disabled = false;
                this.setState({ cargandoEnvio: false, volverAlIncio: true });
            }
            else {
                try {
                    
                    document.getElementById('btnTerminar').disabled = false;
                    swal(res.mensaje);
                }
                catch (err) {
                    swal('error en la solicitud');
                }
            }
            
        }
        else {
            try {
                var res = await respuesta.json();
                document.getElementById('btnTerminar').disabled = false;
                swal(res.mensaje);
            }
            catch (err) {
                swal('error en la solicitud');
            }
        }
    }

    //fin Funciones click

    GetHtmlIngresoDinero() {
        return (<div >
            <h3>Ingreso de dinero</h3>
            <p>Ingrese el monto en efectivo a ingresar <input id="txtMonto" style={this.Estilos.MitadAncho} className="form-control" type="number" /> </p>
            <p>Nombre de quien Ingresa</p>
            <input id="txtRecepcionDinero" type="text" className="form-control" />
            <br />
            <p>Número de Operación</p>
            <input id="txtNumOperacion" type="text" className="form-control" />
            <br />
            <p>Comentario</p>
            <textarea id="txtComentario" onChange={() => { this.ChangeComentario() }} className="form-control" />
            <br />
            <button  id="btnTerminar" onClick={() => { this.ClickTerminar(3) }} style={this.Estilos.FullAncho} className="btn btn-success"> Terminar </button>
        </div>);
    }

    GetHtmlReembolso() {

        return (<div >
            <h3>Reembolso</h3>
            <p># de orden <input id="txtNumOrden" style={this.Estilos.MitadAncho} className="form-control" type="number" /></p>
            <p>Ingrese el monto en efectivo a reembolsar <input id="txtMonto" style={this.Estilos.MitadAncho} className="form-control" type="number" /> </p>
            <p>Comentario</p>
            <textarea id="txtComentario" onChange={() => { this.ChangeComentario() }} className="form-control" />
            <br />
            <button  onClick={() => { this.ClickTerminar(2) }} onChange={() => { this.ChangeComentario() }} id="btnTerminar" style={this.Estilos.FullAncho} className="btn btn-success"> Terminar </button>
        </div>);
    }

    render() {
        return (
            <div>

                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }

                <center> <h1 className="LetraBlanca">Entradas de Dinero (Ingresos)</h1> </center>
                <hr />

                <div className="row">
                    <div style={this.Estilos.Columnas.col1} className="column">
                       {/* <div style={this.Estilos.BarraLateral}>
                            <div>
                                <center><h3><strong> Opciones</strong></h3></center>
                            </div>
                            <div><button onClick={() => { this.setState({ mostrarSalidaDinero: true, mostrarReembolso: false }) }} className="btn btn-light" style={this.Estilos.FullAncho}>Salida de dinero</button> </div>
                            <div hidden="hidden"><button onClick={() => { this.setState({ mostrarReembolso: true, mostrarSalidaDinero: false }) }} className="btn btn-light" style={this.Estilos.FullAncho}>Reembolsos</button></div>
                            <div><button onClick={() => {  this.setState({ volverAlIncio: true }) }} className="btn btn-light" style={this.Estilos.FullAncho}>Volver</button></div>
                        </div>*/}
                    </div>

                    <div style={this.Estilos.Columnas.col2}>
                        {
                            (this.state.mostrarIngresoDinero) ? (this.GetHtmlIngresoDinero()) : (null)

                        }
                        {
                            (this.state.mostrarReembolso) ? (this.GetHtmlReembolso()) : (null)
                        }
                    </div>
                    <div style={this.Estilos.Columnas.col1}>
                        
                    </div>
                </div>


            </div>
        );
    }
}
