import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { Logout } from './Globales/Utilidades';
import swal from 'sweetalert';
import { GetPrecioFormateado } from './Globales/Utilidades';

export class OrdenMesa extends Component {
    static displayName = OrdenMesa.name;
    urlParams;
    MesaID = 0;
    TipoVentaID = 0;
    ProductosSeleccionados = [];
    IsTraspasoActivado = false;
    IsOrdenIndividual = false;
    OrdenID = null;
    OrdenSeleccionada = {};

    constructor(props) {
        super(props);
        
        this.state = {
            ordenes: [],
            isDividir: false,
            vistaMesa:false,
            vistaDividir:false,
        };
        this.urlParams = new URLSearchParams(this.props.location.search);
        
    }

    componentDidMount() {
        this.MesaID = Number(this.urlParams.get('id'));
        this.TipoVentaID = Number(this.urlParams.get('TipoVentaID'));
        if (!this.MesaID) {
            this.IsOrdenIndividual = true;
            this.OrdenID = Number(this.urlParams.get('ordenID'));
            this.TraerOrdenesIndividual(this.OrdenID);
            

        } else {
            this.TraerOrdenes();
        }
        if (this.OrdenID)
            this.setState({ vistaDividir: true, vistaMesa: false });
        else {
            this.setState({ vistaDividir: false, vistaMesa: true });
        }
    }

    async TraerOrdenes() {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdenByMesa?idMesa=' + this.MesaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            if (datos.length > 1) {
                console.log(datos);
                this.setState({ ordenes: datos });

            } else if (datos.length === 1) {
                if (this.TipoVentaID===0) {
                    this.TipoVentaID = 1;
                }
                this.Redirigir('/paraLlevarDos?tipoV=' + this.TipoVentaID +'&editar=true&id=' + datos[0].id);
            }
        }
        else {
            alert('error al traer las mesas');
        }
    }

    async TraerOrdenesIndividual(idOrden) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + this.OrdenID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            var ordenes = this.state.ordenes;
            ordenes.push(datos);
            this.setState({ ordenes: ordenes });
        }
        else {
            alert('error al traer las mesas');
        }
    }

    Estilos = {
        Orden: {
            'width': '250px',
            'backgroundColor': 'rgb(236, 240, 245)',
            'height': '300px',
            'cursor': 'pointer',
            'margin-left': '1%',
            'overflowY': 'scroll',
        },
        ButtonStyle:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        FondoBlanco: {
            'backgroundColor':'white',
            'height':'100%',
        },parrafoModif: {
            'margin': '0'
        },
        Row2: {
            'overflowY': 'scroll',
            'overflowX': 'scroll',
            'height': '500px',
            'marginRight': '1px'
        },
        Inline: {
            'display': 'inline'
        },
        TirarToDerecha: {
            'float':'rigth'
        },
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
    }
    Redirigir(url) {
        this.props.history.push(url);
    }

    ClickOrden(orden,indice,TipoVentaID) {
        if (!this.state.isDividir) {
            this.OrdenSeleccionada = orden;
            this.Redirigir('/paraLlevarDos?tipoV=' + TipoVentaID+'&editar=true&id=' + orden.id + '&nmesa=' + this.MesaID)
        }
        
    }

    

    async ClickConfirmarDivision() {
        var ordenesCrear = this.state.ordenes.filter(p => p.noExiste && p.productosOrdens.length > 0);
        var ordenesBorrar = this.state.ordenes.filter(p => p.productosOrdens.keys() === 1  ) ;

        var data = {};
        data.ordenesCrear = ordenesCrear;
        data.ordenesBorrar = ordenesBorrar;
        data.ordenesEditar = [this.state.ordenes[0]];
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/ActualizarDivision', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            this.Redirigir('/');
            Logout();
        }
        else {
            swal(respuesta.status);
        }
    }

    AgregarOrden() {
        var newDiv = JSON.parse(JSON.stringify(this.state.ordenes[0]));
        newDiv.productosOrdens = [];
        newDiv.noExiste = true;
        newDiv.total = 0;
        newDiv.subtotal = 0;
        newDiv.id = 0;
        var newOrdenes = this.state.ordenes;
        newOrdenes.push(newDiv);
        this.setState({ ordenes: newOrdenes, isDividir: true });
    }

    

    ClickProducto(ProdOrden, idTd) {
        if (document.getElementById(idTd).style.background === 'rgb(110, 152, 135)') {
            this.ProductosSeleccionados.forEach((item,index) => {
                if (item.id === ProdOrden.id) {
                    this.ProductosSeleccionados.splice(index, 1);
                }
            });
            document.getElementById(idTd).style.background = 'white';
        }
        else {
           // ProdOrden.cantidad = 1;
            this.ProductosSeleccionados.push(ProdOrden);
            this.IsTraspasoActivado = true;
            document.getElementById(idTd).style.background = 'rgb(110, 152, 135)';
        }
    }

    ClickTraspaso(indiceOrden) {
        const ordenFinal = this.state.ordenes;

        ordenFinal.forEach((item, i) => {
            item.productosOrdens.forEach((value, index) => {

                this.ProductosSeleccionados.forEach((valor) => {
                    if (valor.id === value.id) {
                        if (value.cantidad===1) {
                            delete ordenFinal[i].productosOrdens[index];
                        } else {
                            var newOrden = ordenFinal[i].productosOrdens[index];
                            newOrden.cantidad = newOrden.cantidad-1;
                           // valor.cantidad--;
                            //this.setState({ ordenes: ordenFinal});
                        }
                    }
                });
            });
        });
        //ordenFinal.forEach((item, i) => {
        //    item.productosOrdens.forEach((producto, indice) => {
        //        this.ProductosSeleccionados.forEach((prodSelec, ind) => {
        //            if (producto.id === prodSelec.id) {
        //                producto.cantidad--;
        //            }
        //        });
        //    });
        //});

        this.ProductosSeleccionados.forEach((item, index) => {
            item = JSON.parse( JSON.stringify(item));
            item.cantidad = 1;
            ordenFinal[indiceOrden].productosOrdens.push(item);
        });

        
        this.ProductosSeleccionados = [];
        var elementos = document.getElementsByName('prodOrden');
        elementos.forEach((td) => {
            td.style.borderColor = '';
        });
        this.IsTraspasoActivado = false;
        this.setState({ ordenes: ordenFinal });
    }

    ClickMenos() {
        const ultOrden = this.state.ordenes[this.state.ordenes.length - 1];
        var largoArreglo = 0;
        ultOrden.productosOrdens.forEach((item) => {
            largoArreglo++;
        });
        if (largoArreglo > 0) {
            swal('Debe remover los productos antes de borrar la orden');
        }
        else {
            var ordenEstado = this.state.ordenes;
            ordenEstado.pop();
            this.setState({ orden: ordenEstado });
        }
    }

    //Imprimir(idOrden) {
    //    var stringOrdenes = '';
    //    this.state.ordenes.forEach((item,i) => {
    //        stringOrdenes += item.id;
    //        if (i < this.state.ordenes.length-1)
    //        stringOrdenes += ',' ;
    //    });
    //    const url = "print://multiOrden=" + stringOrdenes;
    //    window.location.href = url;
    //}
    async ClickImprimir2() {

        var stringOrdenes = '';
        this.state.ordenes.forEach((item, i) => {
            stringOrdenes += item.id;
            //if (i < this.state.ordenes.length-1)
            stringOrdenes += ',';
        });
        var myBody = {};

        //myBody.command = "C:\\fbhposprint\\Printer.exe";
        //myBody.parameter = stringOrdenes;
        //myBody.path = "C:\\fbhposprint\\";
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = "print://multiOrden=" + stringOrdenes;
        myBody.path = "C:\\fbhposprint\\";
        console.log(myBody);
        //http://192.168.100.193:4741/fbhpos
        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        });


        console.log(respuesta.json());
    }

    ClickImprimir() {
        var stringOrdenes = '';
        this.state.ordenes.forEach((item,i) => {
            stringOrdenes += item.id;
            //if (i < this.state.ordenes.length-1)
            stringOrdenes += ',' ;
        });
        const url = "print://multiOrden=" + stringOrdenes;
        window.location.href = url;
        
    }


    render() {
        return (
            <div>
                <div className="row" style={this.Estilos.Row2}>
                {
                        this.state.ordenes.map((item, i) => {
                            var totalOrden = 0;
                            var totalDescuento = item.subtotal - item.total;
                            if (totalDescuento < 0) {
                                totalDescuento = 0;
                            }
                            return (
                                <table onClick={() => { this.ClickOrden(item, i, item.tiposVentaID) }} style={this.Estilos.Orden}  className="table">
                                        <thead></thead>
                                        <tbody>
                                            <tr onClick={() => { this.ClickTraspaso(i) }}>
                                                <td>Orden:{(item.id) ? (item.numOrden):('Nueva')}</td>
                                                <td>Mesero:{item.usuarios.nombre}</td>
                                                
                                                <td>{i + 1}/{this.state.ordenes.length}</td>
                                            </tr>
                                            {
                                                item.productosOrdens.map((value, index) => {
                                                    totalOrden += value.total;
                                                    var stringModificadores = [];
                                                    value.modificadoresProductos.forEach((modif) => {

                                                        stringModificadores.push(modif.descModificador);
                                                    });
                                                    try {
                                                        const id = 'prodOrden' + index + i;
                                                        return (
                                                            <tr name="prodOrden" id={id} onClick={() => { this.ClickProducto(value, id) }}>
                                                                <td>{value.cantidad}</td>
                                                                <td><strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                    return (<p style={this.Estilos.parrafoModif}>{mod}</p>);
                                                                })}
                                                                </td>
                                                                <td>{value.total}</td>
                                                            </tr>
                                                        );
                                                    } catch (err) {
                                                        return (
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td> </td>
                                                            </tr>
                                                            );
                                                    }
                                                })
                                                
                                            }
                                        <tr>
                                            <td colSpan="2"><p style={this.Estilos.parrafoModif}>Total:{GetPrecioFormateado(totalOrden )}</p>
                                                <p style={this.Estilos.parrafoModif}>Estacion:{item.cajaID}</p>
                                            </td>
                                            <td> </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                         );
                    })
                }
                
                
                </div>
                <button onClick={() => { Logout(); this.Redirigir('/') }} data-toggle="tooltip" data-placement="right" title="Volver" style={this.Estilos.ButtonStyle}>
                    <i class="fa fa-reply fa-2x" aria-hidden="true"></i></button>
                
                

                {
                    (!this.state.vistaMesa) ? (
                        <button onClick={() => { this.AgregarOrden(); this.setState({ isDividir: true }) }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyle}>
                            <i class="fa fa-plus fa-2x" aria-hidden="true"></i></button>
                    ) : (null)
                }
                {
                    (!this.state.vistaMesa) ? (
                        <button onClick={() => { this.ClickMenos() }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyle}>
                            <i class="fa fa-minus  fa-2x" aria-hidden="true"></i></button>
                    ) : (null)
                }
                {
                    (this.state.isDividir) ? (
                        <button onClick={() => { this.ClickConfirmarDivision() }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyleS}>
                            <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                    ): (null)
                }
                
                {
                    (this.state.vistaMesa) ? (
                        <button onClick={() => { this.ClickImprimir2() }} data-toggle="tooltip" data-placement="right" title="Imprimir" style={this.Estilos.ButtonStyleDerecha}>
                            <i class="fa fa-print  fa-2x" aria-hidden="true"></i></button>
                    ): (null)
                }
                {
                    (this.state.vistaMesa) ? (
                        <button onClick={() => { this.Redirigir('/paraLlevarDos?tipoV=1&nmesa=' + this.MesaID) }} data-toggle="tooltip" data-placement="right" title="Imprimir" style={this.Estilos.ButtonStyleDerecha}>
                            <i class="fa fa-plus-circle  fa-2x" aria-hidden="true"></i>
                        </button>
                    ): (null)
                }
            </div>
        );
    }
}
