import React, { Component, useDebugValue } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { TecladoNumerico } from './TecladoNumerico';
import { Vuelto } from './Vuelto';
import { SetPropina } from './SetPropina';
import { Descuentos } from './Descuentos';
import { Redirect } from 'react-router';
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { GetUserCookie } from './Globales/Utilidades';
import { PopupCuentaCasa } from './PopupCuentaCasa';
import { PopupGarantiaEvento } from './PopupGarantiaEvento';
import { GetUrlBlinamicApi } from './Globales/VariableGlobales';
import { TecladoCanjePuntos } from './TecladoCanjePuntos';
import { Card, Button, CardBody, CardHeader, Table, Form } from 'reactstrap';
import { TecladoGiftcard } from './TecladoGiftcard';
import { IngresoCheque } from './IngresoCheque';
import imgcancel from './Imagenes/cancel.png';
import { ProcesarPagos } from './ProcesarPagos';
export class Pagar extends Component {
    static displayName = Pagar.name;
    MedioSeleccionado = {};
    IdOrden = 0;
    isNumOperacion = false;
    UltimoMedioAgregado = 0;
    IsEfectivoSeleccionado = false;
    Vuelto = 0;
    Propina = 0;
    HayVuelto = false;
    VueltoBalance = 0;
    HayPropina = false;
    TipoVenta = 0;//pasado por prop desde parallevar.js
    TipoVentaDesc = "";//pasado por prop desde parallevar.js
    MesaID = 0;
    NumeroOrdenNew = 0;
    TicketID = 0;
    NotaVentaID = 0;
    ReservaID = 0;
    IsBoletaSelected = false;
    IsFacturaSelected = false;
    IsEcommerce = false;
    TieneCliente = false;
    ClientData = [];
    NotasDeCredito = [];
    CantidadNC = 0;
    DetalleMediosReserva = [];
    DetalleMediosEcommerce = [];
    indicecolorCL = 0;
    NotasDeCreditoUsadas = "";
    ItemDesMed = {};
    dataClientePuntos = [];
    puntosAcumulados =0;
    montoProductosSinPuntos = 0;
    datosGiftcard = [];
	isAutorizeCode = false;
	isCuotas = false;
    UsedNotaCreditoID = 0;
    MontoNC = 0;
    FolioNC = 0;
    DataClienteConvenio= [];
    IDserieGiftcard = 0;
    IsSelectedGiftcard = false;
    VueltoExacto = 0;
    EfectivoExacto = 0;
    OrdenActBD = {};
    VentaID=0;
/*    NCVieneDesdePantallaVenta = false;*/
    constructor(props) {
        super(props);
        this.state = {
            MediosPago: [],
            MediosPagoVisibles: [],
            carroProductos: [],
            total: 0,
            orden: {},
            pagos: [],
            pagoRecibido: 0,
            activarTeclado: false,
            activarCanje :false,
            actTecladoGiftcard: false,
            actConsultaGiftcard:false,
            activarVuelto: false,
            activarSetPropina: false,
            activarDescuento: false,
            mostrarGarantiaEvento: false,
            redirigir: false,
            cargandoMedios: true,
            cargando: false,
            numDividir: 1,
            mostrarCuentaCasa: false,
            listoDeshabilitado: false,
            cerrarModalDeshabilitado: false,
            DesplegarMediosDePago: false,
            ActivarPopUpNotasCredito: false,
            activarPopUpConsultaCliente: false,
            ConsultaClientes: [],
            IsClienteObligatorio: false,
            MediosPago: this.props.MediosPago,
            MediosPagoVisibles: this.props.MediosPagoVisibles,
            htenResponse: null,
            isMedioDePagoDisabled: false,
            offerProducts: [],
            ProgramasPuntosDocumentos : [],
            paymentMethodSelectedId: 0,
            offerPaymentMethodId: 0,
            offerTotalDiscount: 0,
            montoAbonado:0,
            ConsultarAbonos:false,
            activarIngresoCheques:false,
            actDatosOrdenCompra:false,
            montoCheques:0,
            chequesIngresados:[],
            datosOC : [],
			mpDisabled: '',
            totalConOferta: 0,
            Ambiente: localStorage.getItem('ambiente'),
            visibleProcesarPagos: false,
            vueltoReopen: false
        }

 
        this.TraerMediosDePago();
        this.DataClienteConvenio = this.props.DataClienteConvenio;

        //dato de si existe o no nota de crédito desde la pantalla de venta (para hacer cambio de productos similares)
        //this.NCVieneDesdePantallaVenta = this.props.NCVieneDesdePantallaVenta;
 
        this.VentaID= this.props.orden.ventaID;
        this.NumeroOrdenNew = this.props.NumOrden;
        this.ClientData = this.props.ClientData;
        this.CerrarTecladoPago = this.CerrarTecladoPago.bind(this);
        this.MostrarVuelto = this.MostrarVuelto.bind(this);
        this.AgregarPago = this.AgregarPago.bind(this);
        this.TicketID = this.props.TicketID;
        this.DetalleMediosReserva = this.props.DetalleMediosReserva;
        this.DetalleMediosEcommerce = this.props.DetalleMediosEcommerce;
        this.NotaVentaID = this.props.NotaVentaID;
        this.ReservaID = this.props.ReservaID;
        this.CerrarModalMaster = this.CerrarModalMaster.bind(this);
        this.setState = this.setState.bind(this);
        this.DesactivarModalesPagar = this.DesactivarModalesPagar.bind(this);
        this.setState2 = this.setState2.bind(this);
        this.Redirigir = this.Redirigir.bind(this);
        this.ReabrirVuelto = this.ReabrirVuelto.bind(this);
        this.VolverToPagar = this.VolverToPagar.bind(this);
        if (props.ActualizarTexto) {
            this.ActualizarTexto = props.ActualizarTexto;
        }
        this.TipoVenta = props.TipoVenta;
        this.MesaID = props.orden.mesaID;
        this.IsBoletaSelected = this.props.IsBoletaSelected;
        this.IsFacturaSelected = this.props.IsFacturaSelected;
        this.IsEcommerce = this.props.IsEcommerce;
        this.NumDocVenta = this.props.NumDocVenta;

        //programa puntos 
        
        this.dataClientePuntos = this.props.dataClientePuntos;
        this.puntosAcumulados = this.props.puntosPorProductos;
        this.montoProductosSinPuntos = this.props.montoProductosSinPuntos;
        this.UsedNotaCreditoID = this.props.UsedNotaCreditoID;
        this.FolioNC = this.props.FolioNC;
        this.MontoNC = this.props.MontoNC;
        this.IDserieGiftcard  = this.props.IDserieGiftcard;
        this.IsSelectedGiftcard = this.props.isSelectedGiftcard;
        if (this.montoProductosSinPuntos > 0){
            //busco equivalencia de puntos del monto por productos que no estan configurados para acumulacion de puntos
            
            if (this.dataClientePuntos.rut.length > 0){
                this.BuscaPuntosSinConfigProd(this.dataClientePuntos.rut,this.montoProductosSinPuntos);
            }
        }
        try {
            if (this.dataClientePuntos.rut != 0){
                this.BuscaTipoDocumentosProgramasPuntos(this.dataClientePuntos.idProgramaPuntos);
            }
           
        } catch (error) {
            
        }
        this.setState({chequesIngresados:[]});


    }
  
    async ConsultaGiftcard(){
        let serie = document.getElementById('SerieGiftcard').value;
        if (serie =='' || serie == undefined){
            swal('Debe ingresar un numero de serie valido de Giftcard.');
            return;
        }
        try {
            let token =sessionStorage.getItem('jwtToken');
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/GiftCard/GetGiftCardInfoBySerie?GiftCardSerie=' + serie, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.data.length > 0 ){
                        let nowDate = new Date();
                        let activa = result.data[0].activated;
                        let monto = result.data[0].remainingAmount;
                        const str = result.data[0].expirationDate;
                        const [day, month, year] = str.split('-');
                        const expired = new Date(+year, month - 1, +day);
                       
                        
                        if (nowDate > expired){
                            swal('La Giftcard se encuentra expirada.');
                            return;
                        }
                        if (activa == 0){
                            swal('La Giftcard se encuentra inactiva.');
                            return;
                        }
                        if (monto < 1){
                            swal('Giftcard sin saldo disponible.');
                            return;
                        }

                        this.datosGiftcard = result.data;
                        this.setState ({actTecladoGiftcard:true,actConsultaGiftcard:false});
                        return;
                    }
                    else{
                        swal('No se encontro información de Giftcard.');
                        return;
                    }
                });
        }
        catch (e){
            console.log (e);
        }
    }

    async SetDatosOC()
    {
        let num  = document.getElementById('numOC').value;
        let hes = document.getElementById('hesOC').value;
        let monto = document.getElementById('montoOC').value;
        let fecha =document.getElementById('fecOC').value
        let cantidad =  Number(monto);
        try{
            if (num == ''){
                swal({
                    title: "ERROR !",
                    text: "Debe ingresar el número de Orden de Compra.",
                    icon: "error",
                    button: "Entendido !",
                });
                return;
            }
            if (monto == '' || monto < 1){
                swal({
                    title: "ERROR !",
                    text: "Debe ingresar un monto válido.",
                    icon: "error",
                    button: "Entendido !",
                });
                return;
            }
            if (hes == '' || hes < 1){
                hes=0;
            }

            var d1 = new Date();
            d1.setHours(0,0,0,0);
            
            const [year, month, day] = fecha.split('-');
            var d2 = new Date(+year, month - 1, +day);

            if (d2 < d1){
                swal({
                    title: "ERROR !",
                    text: "La fecha de vencimiento no puede ser inferior al día actual",
                    icon: "error",
                    button: "Entendido !",
                });
                return;
            }
            if (cantidad>this.GetBalance()){
                swal({
                    title: "ERROR !",
                    text: "El monto no puede ser superior al monto total de la venta",
                    icon: "error",
                    button: "Entendido !",
                });
                return;
            }
            var numOp;
            var numCheque = null;
            var idBanco = null;
            var numTarjeta = null;
            var puntos = 0; 
            var cuentaCasaID = this.cuentaCasaID;
            if (!this.cuentaCasaID) {
                cuentaCasaID = null;
            }
            
            var OCdatos = {}
            OCdatos.NumeroOC = num;
            OCdatos.FechaVencimiento = fecha;
            OCdatos.Monto = monto;
            OCdatos.NumeroHes = hes;
            var arrOC = [];
            arrOC.push (OCdatos);

            this.setState({datosOC:arrOC});
            this.AgregarPago(cantidad, numOp, cuentaCasaID, null, null, numCheque, numTarjeta, idBanco,puntos,null, null, null);
            this.setState({actDatosOrdenCompra:false});


           
            this.ActualizarTexto();
        }
        catch (e){

        }
    }

    async BuscaPuntosSinConfigProd(rutcliente, monto){
        let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
        if (SwProgramaOfertas === 'N'){
            return;
        }
        try {
            var rutsindv = rutcliente.split('-')[0];
            let token =sessionStorage.getItem('jwtToken');
            let idprograma = this.dataClientePuntos.idProgramaPuntos;
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/getConsultaCalculoPuntos?IdPrograma=' + idprograma +'&RutCliente=' + rutsindv + '&MontoVenta=' + monto + '&CodProducto=0', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                     
                    if (result.montoEquivalentePuntos > 0){
                        //sumo la diferencia de la venta que no se encuentra configurada
                        //a los puntos acumulados x productos
                        this.puntosAcumulados += result.montoEquivalentePuntos; 
                }
                    return;
                });
        }
        catch (e){
            console.log (e);
        }
    }
    async setMontOfertaFidelizacion(){
        this.resetOffer()
        try {
            //if (this.state.pagos.length <= 1 )
            //{
                //const paymentMethodIdOrder = this.state.pagos[0]? this.state.pagos[0].idMedio:this.state.paymentMethodSelectedId

                //setting de datos básicos para definir los convenios
                let productsOrderCarroProductos = [];
                let productsOrderCarroProductosEncabezado = {};
                let productosConvenioAux = {};

                let sucursalID = GetUserCookie().Sucursal.sucursalIDBlinamic;
                var usaConvenioAux = this.DataClienteConvenio.usaConvenio;
                var idConvenioAux = this.DataClienteConvenio.idConvenio;

                this.state.orden.carroProductos.forEach(function (item) {
                    productsOrderCarroProductos.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred, SucursalID: sucursalID, ConvenioID: idConvenioAux })
                })

                productsOrderCarroProductosEncabezado.unCarroProductos = productsOrderCarroProductos;


                //fin setting de datos básicos para definir los convenios

                //Obtener las ofertas respectivas de los productos a vender

                let productsOrder = []
                this.state.orden.carroProductos.forEach(function (item) {
                    if (!item.isDescItem && !item.isProductNC)
                    {
                        productsOrder.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred })
                    }
                })
                const venta = {
                    total: this.GetTotalPagar(),
                    clientId: this.ClientData.id,
                    sucursalId: GetUserCookie().Sucursal.sucursalIDBlinamic,
                    //paymentMethodId: paymentMethodIdOrder,
                    products: productsOrder
                }
                let token =sessionStorage.getItem('jwtToken')
                const offerProducts = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOferta?venta=' + encodeURIComponent(JSON.stringify(venta)), {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization" : "Bearer " + token + ""
                    }
                }).then(res => res.json()).then(
                    (result) => { return result.result.products }
                )
                     
                //FIN Obtener las ofertas respectivas de los productos a vender

            /*logica que trae los productos con sus descuentos por convenio para poder realizar comparación de cual es mejor*/
            /*en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
            /*y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/


            let salidadatosjson = JSON.stringify(productsOrderCarroProductosEncabezado);
            const ConvenioProducts2 = await fetch(GetUrlBlinamicApi() + '/api/Convenio/GetBeneficioProductoConvenioMasivo',
                {
                    method: 'POST',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + token + ""
                    }
                    , body: salidadatosjson
                }).then(res => res.json()).then(
                    (result) => {
                        if (result.data.length > 0) {
                            productosConvenioAux = result.data;
                            return result.data;
                        }
                    }
                )
            //}

            //arreglo para ser llenado al final del codigo y así tener todos los decuentos y se transmitan a blinamic.
            let ArregloAuxOfertasConvenio = [];






            /*FIN   en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
            /*FIN  y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/


                // this.setState({ offerProducts: result.result.products })
            this.state.orden.carroProductos.forEach(function (prod) {

                if (!prod.isDescItem && !prod.isProductNC) {
                    ///*logica que trae los productos con sus descuentos por convenio para poder realizar comparación de cual es mejor*/
                    ///*en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
                    ///*y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/

                    var esOfertaTradicionalExistente = false;

                    var productoID = 0;
                    var esBeneficiado = false;
                    var porcDescuento = 0;
                    var calculoPorcentajeDescuentoConvenio = 0;

                    if (usaConvenioAux) {
                        productosConvenioAux.forEach(function (productoConvenio) {

                            if (prod.id == productoConvenio.productoID) {
                                productoID = productoConvenio.productoID;
                                esBeneficiado = productoConvenio.esBeneficiado;
                                porcDescuento = productoConvenio.porcDescuento;
                            }
                        })

                    }
                    ///*FIN   en el caso de existir un porcentaje de descuento superior al descuento por oferta reemplazar el valor de offerProd.valueOffer por el nuevo valor del descuento*/
                    ///*FIN  y luego de eso marcar el producto como si hubiese utilizado el descuento por convenio*/






                    const carroInOffer = prod.inOffer ? prod.inOffer : false
                    offerProducts.forEach(function (offerProd) {
                        const isValid = offerProd.isValid ? offerProd.isValid : true
                        if (prod.desprod.split(';')[0] === offerProd.productCode && isValid && !carroInOffer) {

                            esOfertaTradicionalExistente = true;
                            /* codigo que es capaz de cruzar si hay o no convenio y/o oferta y ver cual es mejor */

                            if (usaConvenioAux) {
                                if (esBeneficiado) {
                                    calculoPorcentajeDescuentoConvenio = Math.round(((prod.precpred * porcDescuento) / 100) * prod.cantidad);
                                    /*if (offerProducts.length > 0) {*/
                                    
                                    if (offerProd.offerDisc < (calculoPorcentajeDescuentoConvenio)) {
                                        offerProd.offerDisc = calculoPorcentajeDescuentoConvenio;

                                        //especulacion en base a análisis de que este cálculo está incorrecto

                                        offerProd.valueOffer = (prod.precpred * prod.cantidad) - offerProd.offerDisc;
                                        prod.valueOffer = offerProd.offerDisc;

                                        offerProd.isValid = true;
                                        prod.isConvenioUsado = true;
                                    }
                                    else {
                                        prod.isConvenioUsado = false;
                                    }
                                    //}
                                    //else {
                                    //    offerProd = [{ offerDisc: calculoPorcentajeDescuentoConvenio, productID: prod.id, valueOffer: (prod.precpred - calculoPorcentajeDescuentoConvenio), valueOfferUnitario: (prod.precpred - calculoPorcentajeDescuentoConvenio), isValid: true }];
                                    //}

                                }
                                else {
                                    prod.isConvenioUsado = false;
                                }
                            }

                            /*FIN codigo que es capaz de cruzar si hay o no convenio y/o oferta y ver cual es mejor */





                            prod.valueOffer = offerProd.offerDisc
                            offerProd.isValid = false
                            prod.inOffer = true
                        }

                    })

                    if (!esOfertaTradicionalExistente && usaConvenioAux) {

                        calculoPorcentajeDescuentoConvenio = Math.round(((prod.precpred * porcDescuento) / 100) * prod.cantidad);
                        prod.valueOffer = calculoPorcentajeDescuentoConvenio;
                        //prod.valueOfferUnitario = (prod.valueOffer) / prod.cantidad
                        prod.inOffer = true;
                        //offerProd.isValid = false


                        let arregloAux = {};
                        arregloAux.isValid = true;
                        arregloAux.ofertaId = 0;
                        arregloAux.offerDisc = calculoPorcentajeDescuentoConvenio;
                        arregloAux.productCode = prod.desprod.split(';')[0];
                        arregloAux.umount = 1;
                        arregloAux.valueOffer = (prod.precpred * prod.cantidad) - calculoPorcentajeDescuentoConvenio;

                        ArregloAuxOfertasConvenio.push(arregloAux);

                    }
                }
            })

            ArregloAuxOfertasConvenio.forEach(function (Aux) {
                offerProducts.push(Aux);
            })

            this.setState({ offerProducts: offerProducts })
            this.setOfferTotalDiscount()





            ////codigo antiguo.
            ////let productsOrder = []
            ////this.state.orden.carroProductos.forEach(function (item) {
            ////    productsOrder.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred })
            ////})
            ////const venta = {
            ////    total: this.GetTotalPagar(),
            ////    clientId: this.ClientData.id,
            ////    sucursalId: GetUserCookie().Sucursal.sucursalIDBlinamic,
            ////    //paymentMethodId: paymentMethodIdOrder,
            ////    products: productsOrder
            ////}
            ////let token = sessionStorage.getItem('jwtToken')
            ////const offerProducts = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOferta?venta=' + encodeURIComponent(JSON.stringify(venta)), {
            ////    method: 'get',
            ////    headers: {
            ////        'Content-Type': 'application/json',
            ////        "Authorization": "Bearer " + token + ""
            ////    }
            ////}).then(res => res.json()).then(
            ////    (result) => { return result.result.products }
            ////)
            ////// this.setState({ offerProducts: result.result.products })
            ////this.state.orden.carroProductos.forEach(function (prod) {
            ////    const carroInOffer = prod.inOffer ? prod.inOffer : false
            ////    offerProducts.forEach(function (offerProd) {
            ////        const isValid = offerProd.isValid ? offerProd.isValid : true
            ////        if (prod.desprod.split(';')[0] === offerProd.productCode && isValid && !carroInOffer) {
            ////            prod.valueOffer = offerProd.offerDisc
            ////            offerProd.isValid = false
            ////            prod.inOffer = true
            ////        }

            ////    })
            ////})
            ////this.setState({ offerProducts: offerProducts })
            ////this.setOfferTotalDiscount()


        } catch (error) {
            this.resetOffer()    
        }
    }
    resetOffer() {
        this.state.orden.carroProductos.forEach(function (item) {
            item.valueOffer = 0
            item.inOffer = false
        })
        this.setState({offerProducts: []})
        this.setOfferTotalDiscount()
    }
    setOfferTotalDiscount() {
        try {
            let total =0
            const carroProds = this.state.orden.carroProductos
            carroProds.forEach(function (carroProd) {
                total += (carroProd.valueOfferUnitario ? carroProd.valueOfferUnitario : 0) * carroProd.cantidad       
            })
            
            this.setState({ offerTotalDiscount: Math.round(total) })
        }catch (e){
           console.log(e);
        }        
    }
    
    async orderWithOffer() {
        //offer, set default payment method if payment method is selected from Venta
        if (this.props.orden.enabledOffer?this.props.orden.enabledOffer:false) {
            const mp = this.state.MediosPagoVisibles.filter(t => t.id === this.props.orden.offerPaymentMethodId)
            this.UltimoMedioAgregado = this.props.orden.offerPaymentMethodId
            this.isNumOperacion = mp[0].tieneNumOperacion
            this.MedioSeleccionado = mp[0].desmed
            if (mp[0].isCuentaCasa) {
                this.setState({ mostrarCuentaCasa: true });
            }
            //else if (tbmedio.isGarantiaEvento) {
            //    this.setState({ mostrarGarantiaEvento: true });
            //} 
            else {
                this.setState({ paymentMethodSelectedId: this.props.orden.offerPaymentMethodId })
                await this.setMontOfertaFidelizacion()
                if (mp[0].daVuelto){
                    this.IsEfectivoSeleccionado = true
                    this.setState({ activarTeclado: true });
                }
                else {
                    this.IsEfectivoSeleccionado = false;
                    this.AgregarPago(this.GetTotal(), null, null, null, false, null, null, null, null, null, null, null);
                    
                }
            }
        }
    }
    
    async BuscaTipoDocumentosProgramasPuntos(idPrograma){
        try{ 
            let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
            if (SwProgramaOfertas === 'N'){
                return;
            }

            let token =sessionStorage.getItem('jwtToken');
 
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/getTipoDocumentos?IdPrograma=' + idPrograma, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.length > 0){
                        this.setState({ProgramasPuntosDocumentos : result});
                }
                    return;
                });
        }
        catch (e){
           console.log(e);
        }
    }
    
    DesactivarModalesPagar() {
        this.setState({
            mostrarCuentaCasa: false,
            activarTeclado: false,
            activarCanje:false,
            activarVuelto: false,
            activarSetPropina: false,
            activarDescuento: false,
            mostrarGarantiaEvento: false,
            actTecladoGiftcard:false,
            actConsultaGiftcard:false,
            ConsultarAbonos:false,
            actDatosOrdenCompra:false,
            activarIngresoCheques:false
        });
    }

    async componentDidMount() {
        //var tipoventaID = this.props.orden.TiposVentaID;
        this.IdOrden = this.props.idOrden;
        this.NumeroOrdenNew = this.props.NumOrden;
        
        this.VentaID =this.props.orden.ventaID;

        this.CantidadNC = parseInt(this.ClientData.cantidadNotasCredito, 10);
        if (this.ClientData.nombre !== "" && this.CantidadNC > 0) {
            this.TieneCliente = true;
            this.TraerNotasCredito();
        } else {
            this.TieneCliente = false;
        }
        if (this.ClientData.nombre !== "") {
            this.setState({
                IsClienteObligatorio: false
            });
        } else {
            this.setState({
                IsClienteObligatorio: true
            });
        }

        this.TipoVentaDesc = "";
       
        if (this.props.orden.pagadoDelivery === true) {
            document.getElementById('btnListo').disabled = true;
            this.setState({ listoDeshabilitado: true });
            this.ActualizarOrdenBD();
            this.setState({ activarVuelto: true });
        }
        else if (this.props.orden) {
            var total = 0;
            var totalConOferta = 0;
            const carroProductos = this.props.orden.carroProductos;
            
            const carroProductosCopy = carroProductos.map(a => ({ ...a }));
            

            const ordenCopy = Object.assign({}, this.props.orden);

            var carritoCopy = [];

            carroProductosCopy.forEach((producto) => {
                

                const productoResultado = carritoCopy.find(productoFind => productoFind.id === producto.id);

                

                if (productoResultado !== undefined && this.UsedNotaCreditoID ===0) {
                    productoResultado.Correlativos.push(producto.Correlativos[0]);
                    productoResultado.cantidad = productoResultado.cantidad + 1;
                } else {
                    carritoCopy.push(producto);
                }
            });

            delete ordenCopy.carroProductos;

            ordenCopy.carroProductos = carritoCopy;

            if (this.state.carroProductos) {
                this.setState({ carroProductos: carritoCopy });
            }
            carritoCopy.forEach(function (producto) {
                var totalThisProd = producto.precpred * producto.cantidad;
                total += totalThisProd;
                
                totalConOferta += (producto.precpred * producto.cantidad) - (producto.valueOffer ? producto.valueOffer : 0)
            });
            this.setState({ total: total, carroProductos: carritoCopy, orden: ordenCopy, montoAbonado: this.props.montoAbonado, totalConOferta: totalConOferta });
       
        }
       
        if (this.DetalleMediosReserva.length > 0) {
            this.DesplegarMetodosReserva();
        }
        
        if (this.DetalleMediosEcommerce.length > 0) {
            this.DesplegarMetodosEcommerce();
        }

        this.setOfferTotalDiscount();


        if (this.props.orden.puntosXCanje > 0 && this.props.SetEfectivo ){
           await this.SetEfectivoOne(1)
        }
        
        if (this.props.UsedNotaCreditoID != 0){
            await this.AgregarMedioNC(this.props.UsedNotaCreditoID,this.MontoNC, this.FolioNC); 
        }
    }
    async AgregarMedioNC(id, montoNota, folioBsale) {
        
        if (!this.NotasDeCreditoUsadas.includes(folioBsale)) {
            this.NotasDeCreditoUsadas += folioBsale + ",";
            var tipoPagoNC = 23;//this.state.MediosPago.find(t => t.medioEquivalente == "NotaCredito");
            this.TraerMediosDePagoDos(tipoPagoNC, montoNota, folioBsale);
            this.DesactivarModales();

        } 
    }

    async SetEfectivoOne(monto) {
 
        try {
            var categorias = await fetch(GetUrlApi() + '/api/TbMedio/MedioH?CodigoMedio=8');
            if (!categorias.ok) {
                throw new Error('problema al traer los medios de pago');
            }
            
            var datos = await categorias.json();
            this.UltimoMedioAgregado = datos.id;
            this.MedioSeleccionado = datos.desmed;
            await this.setMontOfertaFidelizacion();
            this.AgregarEfectivoOne(monto, null, null, null, false, null, null, null, null);
            //this.setState({ MediosPago: datos, cargandoMedios: false });
        } catch (err) {
            console.log(err);
        }
        return datos;
 
    

}
    async TraerNotasCredito() {
        var Rut = this.ClientData.rut;
        var datos = [];
        let EmpresaID = GetUserCookie().EmpresaID;
        try {
            await fetch(GetUrlApi() + '/api/NotasDeCredito/NotasDeCreditoCliente?Rut=' + Rut + '&EmpresaID=' + EmpresaID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    datos = result;
                }
            );
            this.NotasDeCredito = datos;
        } catch (err) {
            swal('No se pudo traer las Notas de Crédito verifique su conexion a internet');
        }

    }

    DesplegarMetodosReserva() {
        var sumAmounts = 0;
        this.DetalleMediosReserva.map((item, i) => {
            sumAmounts +=  Number(item.montoCuota);
            //se agrupan las formas de pago de la reserva 13-05-2022
            //var medioData = this.TraerMediosDePagoDos(item.tipoPagoID, item.montoCuota, null);
            //return medioData;
        });
        this.UltimoMedioAgregado = 31; // id medio pago abono
        this.MedioSeleccionado = 'Abono Cliente';
        this.AgregarPago(sumAmounts, null, null, null, false, null, null, null, null, null, null, null);
    }

    DesplegarMetodosEcommerce() {
        this.DetalleMediosEcommerce.map((item, i) => {
            var medioData = this.TraerMediosDePagoDos(item.tipoPagoID, item.montoCuota, null);
            return medioData;
        });
    }

    async TraerMediosDePagoDos(codigoMedio, montoCuota, folioNotaCredito) {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/TbMedio/MedioH?CodigoMedio=' + codigoMedio);
            if (!categorias.ok) {
                throw new Error('problema al traer los medios de pago');
            }
            var datos = await categorias.json();
            console.log("Medios YaCargados");
            console.log("medios de pago ", datos);
            this.UltimoMedioAgregado = datos.id;
            this.MedioSeleccionado = datos.desmed;
            
            this.AgregarPagoDos(montoCuota, null, null, null, false, null, null, null, folioNotaCredito);
            //this.setState({ MediosPago: datos, cargandoMedios: false });
        } catch (err) {
            console.log(err);
        }
        return datos;
    }

    setState2(newstate) {
        this.setState(newstate);
        this.ActualizarEstado(newstate);
    }
    async AgregarEfectivoOne(total, codOperacion, cuentaCasaID, isFacturado, codInfo, NumCheque, NumTarjeta, BancosID, folioNotaCredito) {
        var pagos = this.state.pagos;
        total = parseInt(total,10);

        var newPago = {};
        if (!isFacturado) {
            isFacturado = false;
        }
        if (codOperacion) {
            newPago.NumeroDeOperacion = codOperacion;
        }
        newPago.idMedio = this.UltimoMedioAgregado;
        newPago.medio = this.MedioSeleccionado;
      
        newPago.total = total;
        
        newPago.cuentaCasaID = cuentaCasaID;
        newPago.isFacturado = isFacturado;
        newPago.numInformativo = codInfo;

        newPago.NumCheque = NumCheque;
        newPago.BancosID = BancosID;
        newPago.NumTarjeta = NumTarjeta;
        newPago.FolioNotaCredito = folioNotaCredito;
        pagos.push(newPago);

        var pagoRecibido = 0;
        pagos.forEach(function (valor) {
            pagoRecibido += Number(valor.total);
        });

        this.setState({ pagos: pagos, pagoRecibido: pagoRecibido });

    }

    async AgregarPagoDos(total, codOperacion, cuentaCasaID, isFacturado, codInfo, NumCheque, NumTarjeta, BancosID, folioNotaCredito) {
        var pagos = this.state.pagos;
        total = parseInt(total,10);

        var newPago = {};
        if (!isFacturado) {
            isFacturado = false;
        }
        if (codOperacion) {
            newPago.NumeroDeOperacion = codOperacion;
        }
        newPago.idMedio = this.UltimoMedioAgregado;
        newPago.medio = this.MedioSeleccionado;
        //if (newPago.idMedio !== 8) {
            newPago.total = total;
        //}
        //else {
        //    newPago.total = this.RedondearValor(total);
       // }
        newPago.cuentaCasaID = cuentaCasaID;
        newPago.isFacturado = isFacturado;
        newPago.numInformativo = codInfo;

        newPago.NumCheque = NumCheque;
        newPago.BancosID = BancosID;
        newPago.NumTarjeta = NumTarjeta;
        newPago.FolioNotaCredito = folioNotaCredito;
        pagos.push(newPago);

        var pagoRecibido = 0;
        pagos.forEach(function (valor) {
            pagoRecibido += Number(valor.total);
        });

        this.setState({ pagos: pagos, pagoRecibido: pagoRecibido });

    }

    CerrarModalMaster() {
        this.setState({ activarSetPropina: false, activarDescuento: false, activarIngresoCheques:false });
    }

    VolverToPagar() {
         
        this.setState({
            activarTeclado: false,
            activarCanje:false,
            activarVuelto: false,
            activarSetPropina: false,
            activarDescuento: false,
            actTecladoGiftcard:false,
            actConsultaGiftcard:false,
            activarIngresoCheques:false,
            actDatosOrdenCompra:false
        });
    }

    RedondearValor(monto) {
        if (monto > 0) {
            const resto = monto % 10;
            if (resto > 0 && resto <= 5) {
                return monto - resto;
            }
            else if (resto > 5 && resto < 10) {
                return monto + (10 - resto);
            }
        }
        else {
            return monto;
        }

        return monto
    }

    
        
    async AgregarPago(total, codOperacion, cuentaCasaID, isFacturado, codInfo, NumCheque, NumTarjeta, BancosID,puntoscanje,cheques, autorizeCode, cuotasNumber) {
        
        let pagos = this.state.pagos;
        let canjes = 0;
        const medio = this.state.MediosPagoVisibles.find((m) => m.id === this.UltimoMedioAgregado)
        if (medio.isTarjeta && this.isHten()) {
            // Llamar a HTEN PAGOS
            console.log("MEDIO ", medio);
            let endpoint = "compraDebito";
            let tipoTarjetaJson = "DB";
            let callHten = false;
            switch (medio.medioEquivalente) {
                case "TARJETACREDITO":
                    /* endpoint = "compraCredito";
                    await Swal.fire({
                        title: 'Es tarjeta NO BANCARIA?',
                        text: "Si es una tarjeta comercial presione en SI.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, es NB!',
                        cancelButtonText: 'No, es CR!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            tipoTarjetaJson = "NB";
                        } else {
                            tipoTarjetaJson = "CR";
                        }
                    });
                    callHten = true; */
                    break;
                case "BINES":
                    endpoint = "consultaCompra";
                    break;
                case "QR_ESTATICO":
                    endpoint = "pagoFpay_static";
                    callHten = true;
                    break;  
                case "QR_DINAMICO":
                    endpoint = "pagoFpay_dynamic";
                    callHten = true;
                    break;
            }
            
            console.log("endpoint ", endpoint);
            if (callHten) {
                await this.callToHten(endpoint, tipoTarjetaJson, null, total);
                NumTarjeta = JSON.stringify(this.state.htenJsonResponse);
            }
            if ((this.state.htenApproved === false)) {

                this.setState({ htenApproved: null })
                return;
            }
        }

        if (puntoscanje > 0){
            canjes=puntoscanje;
        }
 
        let newPago = {};
        newPago.isTarjeta = medio.isTarjeta
        newPago.medioEquivalente = medio.medioEquivalente
        if (!isFacturado) {
            isFacturado = false;
        }
        if (codOperacion) {
            newPago.NumeroDeOperacion = codOperacion;
        }
		if(autorizeCode)
			newPago.autorizeCode = autorizeCode
		if(cuotasNumber)
			newPago.cuotasNumber = cuotasNumber
        newPago.idMedio = this.UltimoMedioAgregado;
        newPago.medio = this.MedioSeleccionado;
        //if (newPago.idMedio !== 8) {
            newPago.total = total;
       // }
        //else {
        //    newPago.total = this.RedondearValor(total);
       // }
        //total sin redondeo 
        newPago.AmountPay = total;

        newPago.cuentaCasaID = cuentaCasaID;
        newPago.isFacturado = isFacturado;
        newPago.numInformativo = codInfo;

        newPago.NumCheque = NumCheque;
        newPago.BancosID = BancosID;
        newPago.NumTarjeta = NumTarjeta;
        newPago.PuntosCanje = canjes;

        
		
        if (cheques != null){
            newPago.cheques = cheques;
        }
       
        if (this.MedioSeleccionado == 'Convenio'){
            if (pagos.length > 0){
                await Swal.fire({
                    title: "Error al validar pago",
                    icon: "error",
                    text: "No se puede utilizar medio pago Convenio de forma parcial, si ya se encuentran registrados medios de pago.",
                    dangerMode: true,
                 })
                return
            }
            let totalVenta=this.GetTotal();
            if ( totalVenta > total){
                Swal.fire({
                    title: "Error al validar pago",
                    icon: "error",
                    text: "Para pagar con Convenio debe ser por el total de la Venta.",
                    dangerMode: true,
                 })
                 return
            }

        }
        
        let updCheques=false;
        if (this.MedioSeleccionado=='Cheques'){
        
            //actualizar monto de arreglo pagos --> position cheque
            for (var i=0; i < pagos.length;i++){
                if (pagos[i].medio=='Cheques'){
                    pagos[i].total = total;
                    pagos[i].AmountPay=total;
                    pagos[i].cheques = cheques;
                    updCheques=true;
                    break;
                }
            }
            
        }
        //si presiona nuevamente efectivo
        var existsEfectivo = false;
        if (this.MedioSeleccionado == 'Efectivo'){
            for (var i=0; i < pagos.length;i++){
                if (pagos[i].medio=='Efectivo'){
                    pagos[i].total = total;
                    pagos[i].AmountPay=total;
                    existsEfectivo=true;
                    break;
                }
            }
        }
         
     
        await this.setState({chequesIngresados:cheques });
        if (!updCheques && !existsEfectivo){ //si agrega y/o modifica otro medio de pago != a cheque o efectivo se agrega al arreglo
            pagos.push(newPago);
        }
     
        let pagoRecibido = 0;
        pagos.forEach(function (valor) {
            pagoRecibido += Number(valor.total);
        });
        // this.setMontOfertaFidelizacion()
        this.setState({ pagos: pagos, pagoRecibido: pagoRecibido });
		
		if(this.GetBalance() <= 0){
			this.setState({ mpDisabled: 'disabled', isMedioDePagoDisabled: "disabled" })
		} else {
			this.setState({ mpDisabled: '' }) 
		}

    }

    async ClickPagosListos() {
        this.setState({ visibleProcesarPagos: true })
		/* let isAutorizeCode = false
		let htmlAutorizeCode = ''
		// document.getElementById('btnListo').disabled = true;

		await this.setState({ listoDeshabilitado: true, activarTeclado: false,activarCanje:false,activarIngresoCheques:false });
		var montosNC =0
		this.state.pagos.forEach((item) => {
				if (item.idMedio == 23){ //monto por NC
						montosNC +=item.total;
				}
		})

		if (this.GetTotal() < montosNC){
				await Swal.fire({
						title: "Error al validar pago",
						icon: "error",
						text: "El monto total de la venta debe ser superior o igual a los montos pagados con Nota de Crédito.",
						dangerMode: true,
				})
				document.getElementById('btnListo').disabled = false
				await this.setState({ listoDeshabilitado: false, activarTeclado:false,activarCanje:false,activarIngresoCheques:false })
				await this.setState({ cargando: false })
				return
        }


        console.log("entró a proceso de actualizar orden:" + Date(Date.now()));
        await this.ActualizarOrdenBD()

        console.log("terminó el proceso de actualizar orden:" + Date(Date.now()));
		this.setState({ activarVuelto: true })

				
		*/		
				
        
    }
    async finalizarVenta(pagosProcesados) {
        if(pagosProcesados) {
            let isAutorizeCode = false
            let htmlAutorizeCode = ''
            // document.getElementById('btnListo').disabled = true;

            await this.setState({ listoDeshabilitado: true, activarTeclado: false,activarCanje:false,activarIngresoCheques:false });
            var montosNC =0
            this.state.pagos.forEach((item) => {
                if (item.idMedio == 23){ //monto por NC
                        montosNC +=item.total;
                }
                if(item.isTarjeta && this.isHten()) {
                    //implementar numero de tarjeta del arreglo pagosProcesados
                }
            })

            if (this.GetTotal() < montosNC){
                    await Swal.fire({
                        title: "Error al validar pago",
                        icon: "error",
                        text: "El monto total de la venta debe ser superior o igual a los montos pagados con Nota de Crédito.",
                        dangerMode: true,
                    })
                    document.getElementById('btnListo').disabled = false
                    await this.setState({ listoDeshabilitado: false, activarTeclado:false,activarCanje:false,activarIngresoCheques:false })
                    await this.setState({ cargando: false })
                    return
            }
            this.setState({ visibleProcesarPagos: false })
            await this.ActualizarOrdenBD()
            this.setState({ activarVuelto: true })
            
        }
    }

    async ClickImprimirDirecto() {

        this.setState({ cargando: true });
        const idOrden = this.IdOrden;
        var myBody = {};
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = idOrden;
        myBody.path = "C:\\fbhposprint\\";
        //http://192.168.100.193:4741/fbhpos
        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).catch(() => {
            this.setState({ cargando: false });
        });
        this.setState({ cargando: false });
    }

    async ClickImprimir2(id) {

        const idOrden = this.IdOrden;
        // Enviar a imprimir voucher
        this.ClickImprimirHten(idOrden);

        const usaCustomCloudService = localStorage.getItem('usaCustomCloudService')
        if (usaCustomCloudService === "true") {
            this.setState({ cargando: true });
            const userData = sessionStorage.getItem('userProfile');
            const empresa = userData.Sucursal.direccionDeli.split(".")[0];
            const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
            const printer = "printer_" + userData.caja.descripcion;
            var myBody = {
                "company_id": empresa,
                "store_id": sucursal,
                "order_id": id,
                "printer_id": printer,
                "additional_data": "",
                "exec_path": "C:\\fbhposprint\\"
            };

            await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(data => data.json()).then(data => {
                swal({
                    title: "Enviado a imprimir con código " + data.hten_unique_id,
                    icon: "success",
                    text: "Guarde ese código para consultar estado.",
                }).then(() => {
                    this.setState({ cargando: false });
                });
            }).catch(error => {
                swal({
                    title: "Error al querer imprimir",
                    icon: "error",
                    text: "Por favor contacte al administrador del sistema " + error,
                    dangerMode: true,
                }).then(() => {
                    this.setState({ cargando: false });
                });
            });
        } else {
            this.ClickImprimirDirecto();
        }
    }

    async ActualizarOrdenBD() {
        var excesoToPropina = false;
        var IsVueltoBruto = false;
        await this.setState({ cargando: true });
        var totalPagado = 0;
        this.state.pagos.forEach((item) => {
            totalPagado += item.total
            var medioAsociado = this.state.MediosPago.find(p => p.id === item.idMedio);
            if (medioAsociado.aplicaExcedente) {
                IsVueltoBruto = true;
            }
            if (!medioAsociado.daVuelto) {
                excesoToPropina = true;
                this.HayVuelto = false;
            }
            else {
                this.HayVuelto = true;
            }
        });


        var balancePagosTotal = this.GetTotal() - totalPagado;

        var orden = this.state.orden;

        var listaMediosPago = [];
        //orden.PropinaCant = this.GetValorPropina();
        orden.PropinaCant = 0;

        if ((balancePagosTotal) < 0 && excesoToPropina && !IsVueltoBruto) {
            const toAdd = balancePagosTotal * -1;
            orden.PropinaCant += toAdd;
        }
        else if (excesoToPropina) {//si algo falla para el 2
            const toAdd = balancePagosTotal * -1;
            orden.PropinaCant += toAdd;
        }
        orden.Pagado = false;
        orden.id = this.IdOrden;
        orden.Total = this.GetTotal();
        if (IsVueltoBruto) {

            orden.Total = totalPagado;
        }
        //if (  excesoToPropina) {

        //    orden.Total = totalPagado;
        //}
        orden.Subtotal = this.state.total;
        orden.DescEfectivo = this.GetDescuento() + this.state.offerTotalDiscount;

        orden.SubTotalOffers = this.GetTotalWithOffers();
        orden.GavetaID = this.props.idGaveta;
        orden.TiposVentaID = this.props.orden.tiposVentaID;
        //orden.NumOrden = this.NumeroOrdenNew;
        orden.NumOrden = this.props.orden.numOrden;
        orden.NotasDeCreditoUsadas = this.NotasDeCreditoUsadas;
        //if (!orden.MesaID || orden.MesaID === 0) {
        //    orden.TiposVentaID = this.TipoVenta;
        //}

        orden.MesaID = this.MesaID;
        if (!orden.MesaID || orden.MesaID === 0) {
            orden.MesaID = null;
        }
        //orden.CajaID = Number( document.cookie);
        orden.CajaID = GetUserCookie().CajaID;
        orden.SucursalID = GetUserCookie().SucursalID;
        this.state.pagos.forEach((item) => {
            listaMediosPago.push(item.idMedio);
        });
        //orden.MediosPorOrdens = listaMediosPago;
        if (orden.propinaCant > 0) {
            this.HayPropina = true;
        }
        /* if (GetUserCookie().Sucursal.configPOS.isStockHabilitado) {
            this.ReducirStock();
        } */
        if (this.ClientData.rut !== '') {
            orden.NombreCliente = this.ClientData.rut;
            orden.ClienteID= this.ClientData.id;
        }
        //Medios de Pago
        var MediosList = [];
        var propinaAgegada = false;
        var aplicaExcedente = false;
        var montoExcedente = this.GetBalance() * -1;
        var idMedioExcedente = 0;
        var totalPropinaGlobal = 0;

        let vuelto = this.VueltoBalance * -1
        this.state.pagos.forEach(async (value, i) => {
            if (value.total > 0) {
                var medioAsociado = this.state.MediosPago.find(p => p.id === value.idMedio);


                if (medioAsociado.aplicaExcedente) {
                    idMedioExcedente = medioAsociado.id;
                    aplicaExcedente = true;
                    this.isFinalEfectivoOrCupon = true;
                }
                else {
                    aplicaExcedente = false;
                }

                var medio = {};
                medio.TbMedioID = value.idMedio;
                medio.OrdenID = this.IdOrden;
                medio.GavetaID = this.props.idGaveta;
                medio.Total = value.total;
                medio.cuentaCasaID = value.cuentaCasaID;
                medio.isFacturado = value.isFacturado;
                medio.numInformativo = value.numInformativo;
                medio.NumCheque = value.NumCheque;
                medio.BancosID = value.BancosID;
                medio.NumTarjeta = value.NumTarjeta;
                medio.TotalReal = medio.Total;
                medio.totalPagado = medio.TotalReal;
                medio.FolioNotaCredito = value.FolioNotaCredito;
                medio.Cheques = value.cheques;
				medio.autorizeCode = value.autorizeCode
				medio.Cuotas = value.cuotasNumber

                if (value.idMedio === 8){
                    medio.ExactChange = this.VueltoExacto;
                }
                else{
                    medio.ExactChange = 0;
                }
                medio.AmountPay  = value.AmountPay;

                if (value.medio =='Orden de Compra'){
                    medio.DatosOrdenCompra = this.state.datosOC;
                }
                if (value.RutMedioPago !== '') {
                    medio.RutMedioPago = value.RutMedioPago;

                } else {
                    medio.RutMedioPago = null;

                }

                if (medioAsociado.aplicaExcedente && i === (this.state.pagos.length - 1)) {//si es cupon
                    if (montoExcedente < 0)
                        montoExcedente = montoExcedente * -1;
                    //medio.Total = montoExcedente;
                    medio.Total = value.total;
                    montoExcedente = value.total - montoExcedente;
                    //medio.Total= medio.Total - montoPropina;
                    this.isFinalEfectivoOrCupon = true;
                }
                else if (!medioAsociado.daVuelto && !i === (this.state.pagos.length - 1)) {
                    medio.Total -= montoExcedente;
                }
                medio.NumeroDeOperacion = value.NumeroDeOperacion;
                if (!medioAsociado.daVuelto && !propinaAgegada && !medioAsociado.aplicaExcedente && i === (this.state.pagos.length - 1)) {//si es tarjeta
                    propinaAgegada = true;
                    //medio.MontoPropina = montoPropina + (totalOrden - montoExcedente);
                    var excedente = 0;
                    this.state.pagos.forEach((item) => {
                        excedente += item.total;
                    });
                    medio.MontoPropina = orden.montoPropina;
                    if (medio.MontoPropina < 0) {
                        //medio.MontoPropina = medio.MontoPropina * -1;
                        medio.MontoPropina = orden.montoPropina;
                    }
                    totalPropinaGlobal += medio.MontoPropina;
                }
                else {
                    medio.MontoPropina = 0;
                    if (medioAsociado.daVuelto && i === (this.state.pagos.length - 1)) {//si es efectivo
                        medio.Vuelto = vuelto
                     
                        vuelto -= vuelto
                        if (montoExcedente < 0) {
                            montoExcedente = montoExcedente * -1;
                        }
                        if (montoExcedente < 0) {
                            medio.TotalReal = montoExcedente * -1;
                        }
                        else {
                            medio.TotalReal = parseInt(medio.TotalReal) + parseInt(montoExcedente);
                            if (this.state.Ambiente != 'MAISA') {
                                medio.Diferencia = montoExcedente;
                            }                            
                        }
                        this.isFinalEfectivoOrCupon = true;
                    }
                }

                MediosList.push(medio);

                //if (medio.Total > 0) {
                //    var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
                //        method: 'post',
                //        headers: {
                //            'Content-Type': 'application/json',
                //        },
                //        body: JSON.stringify(medio),
                //    });
                //}
            }
        });
        orden.MediosPorOrdens = MediosList;
        if (montoExcedente < 0) {
            montoExcedente = montoExcedente * -1;
        }
        if (aplicaExcedente && montoExcedente > 0) {
            var medio = {};
            medio.tbmedioID = idMedioExcedente;
            medio.OrdenID = this.IdOrden;
            medio.GavetaID = this.props.idGaveta;
            medio.Total = montoExcedente;
            medio.TotalReal = montoExcedente;
            medio.MontoPropina = 0;


            var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(medio),
            });
        }

        if ((totalPropinaGlobal > 0 && totalPropinaGlobal !== orden.propinaCant && !aplicaExcedente) || this.isFinalEfectivoOrCupon) {
            this.Propina = totalPropinaGlobal;
            orden.propinaCant = orden.propinaCant;
            //orden.Total = orden.Subtotal + (montoPropina - totalPropinaGlobal);
            this.HayPropina = true;
            if (this.isFinalEfectivoOrCupon) {
                this.Propina = 0;
                orden.propinaCant = 0;
                this.HayPropina = false;
                orden.Total = orden.Subtotal - (this.GetDescuento() + this.state.offerTotalDiscount);
            }

        }

        // Fin Medios De Pago

       // console.log(JSON.stringify(orden));
       /* var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + this.IdOrden, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orden),
        }); */

        this.OrdenActBD = orden;
        await this.setState({ cargando: false });
       
       /* no call apipos - facturacion service refresh ordenbd 17-05-23
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/ActualizaOrden?id=' + this.IdOrden, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orden),
        }); 
     
        if (respuesta.ok) {
            //await this.GuardarMediosPago(orden.PropinaCant, this.GetTotal(), orden);
            await this.setState({ cargando: false });
        }

        */
    }

    async ReducirStock() {
        var respuesta = await fetch(GetUrlApi() + '/api/Tbproductoes/ReducirStockDeOrden?ordenID=' + this.IdOrden, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            //body: JSON.stringify(orden),
        });
        if (respuesta.ok) {

        }
    }

    async GuardarMediosPago(montoPropina, totalOrden, orden) {
        var propinaAgegada = false;
        var aplicaExcedente = false;
        var montoExcedente = this.GetBalance() * -1;
        var idMedioExcedente = 0;
        var totalPropinaGlobal = 0;
        var isFinalEfectivoOrCupon = false;
        this.state.pagos.forEach(async (value, i) => {
            var medioAsociado = this.state.MediosPago.find(p => p.id === value.idMedio);


            if (medioAsociado.aplicaExcedente) {
                idMedioExcedente = medioAsociado.id;
                aplicaExcedente = true;
                this.isFinalEfectivoOrCupon = true;
            }
            else {
                aplicaExcedente = false;
            }

            var medio = {};
            medio.TbMedioID = value.idMedio;
            medio.OrdenID = this.IdOrden;
            medio.GavetaID = this.props.idGaveta;
            medio.Total = value.total;
            medio.cuentaCasaID = value.cuentaCasaID;
            medio.isFacturado = value.isFacturado;
            medio.numInformativo = value.numInformativo;
            medio.NumCheque = value.NumCheque;
            medio.BancosID = value.BancosID;
            medio.NumTarjeta = value.NumTarjeta;
            medio.TotalReal = medio.Total;
            medio.totalPagado = medio.TotalReal;

            if (medioAsociado.aplicaExcedente && i === (this.state.pagos.length - 1)) {//si es cupon
                if (montoExcedente < 0)
                    montoExcedente = montoExcedente * -1;
                //medio.Total = montoExcedente;
                medio.Total = value.total;
                montoExcedente = value.total - montoExcedente;
                //medio.Total= medio.Total - montoPropina;
                this.isFinalEfectivoOrCupon = true;
            }
            else if (!medioAsociado.daVuelto && !i === (this.state.pagos.length - 1)) {
                medio.Total -= montoExcedente;
            }
            medio.NumeroDeOperacion = value.NumeroDeOperacion;
            if (!medioAsociado.daVuelto && !propinaAgegada && !medioAsociado.aplicaExcedente && i === (this.state.pagos.length - 1)) {//si es tarjeta
                propinaAgegada = true;
                //medio.MontoPropina = montoPropina + (totalOrden - montoExcedente);
                var excedente = 0;
                this.state.pagos.forEach((item) => {
                    excedente += item.total;
                });
                medio.MontoPropina = montoPropina;
                if (medio.MontoPropina < 0) {
                    //medio.MontoPropina = medio.MontoPropina * -1;
                    medio.MontoPropina = montoPropina;
                }
                totalPropinaGlobal += medio.MontoPropina;
            }
            else {
                medio.MontoPropina = 0;
                if (medioAsociado.daVuelto && i === (this.state.pagos.length - 1)) {//si es efectivo
                    if (montoExcedente < 0) {
                        montoExcedente = montoExcedente * -1;
                    }
                    if (montoExcedente < 0) {
                        medio.TotalReal = montoExcedente * -1;
                    }
                    else {
                        medio.TotalReal = montoExcedente;
                    }
                    this.isFinalEfectivoOrCupon = true;
                }
            }



            if (medio.Total > 0) {
                var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(medio),
                });
            }
        });
        if (montoExcedente < 0)
            montoExcedente = montoExcedente * -1;
        if (aplicaExcedente && montoExcedente > 0) {
            var medio = {};
            medio.tbmedioID = idMedioExcedente;
            medio.OrdenID = this.IdOrden;
            medio.GavetaID = this.props.idGaveta;
            medio.Total = montoExcedente;
            medio.TotalReal = montoExcedente;
            medio.MontoPropina = 0;


            var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(medio),
            });
        }

        if ((totalPropinaGlobal > 0 && totalPropinaGlobal !== montoPropina && !aplicaExcedente) || this.isFinalEfectivoOrCupon) {
            this.Propina = totalPropinaGlobal;
            orden.propinaCant = montoPropina;
            //orden.Total = orden.Subtotal + (montoPropina - totalPropinaGlobal);
            this.HayPropina = true;
            if (this.isFinalEfectivoOrCupon) {
                this.Propina = 0;
                orden.propinaCant = 0;
                this.HayPropina = false;
                orden.Total = orden.Subtotal - this.GetDescuento();
            }


            var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + orden.id, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orden),
            });
        }



    }


    async MostrarVuelto() {
        await this.ActualizarOrdenBD();
        this.setState({ activarTeclado: false, activarVuelto: true, activarCanje:false,  actTecladoGiftcard:false,actConsultaGiftcard:false,activarIngresoCheques:false,actDatosOrdenCompra:false});
    }

    CerrarTecladoPago(pagado) {
        if (pagado)
            this.MostrarVuelto();
        else
            this.setState({ activarTeclado: false,activarCanje:false, actTecladoGiftcard:false,actConsultaGiftcard:false,activarIngresoCheques:false,actDatosOrdenCompra:false });
    }

    GetBalance() {
        var balance = (this.GetTotal() - (this.state.pagoRecibido));
        
        if (this.IsEfectivoSeleccionado) {
            
            if (balance < 0) {
                this.VueltoBalance = (this.RedondearValor(balance * -1) * -1);
            }else{
                this.VueltoBalance = this.RedondearValor(balance);
            }   
            this.VueltoExacto = balance;         
        }else{
            this.VueltoBalance = balance;
            this.VueltoExacto = balance
        }
 
        if (balance < 0) {
            return 0;
        } else {
            return balance;
        }

    }

    GetDescuento() {
        var cont = 0;

        if (this.state.orden.dctoOrden > 0) {
            cont += (Math.round(this.state.totalConOferta) - this.getDescItem()) * (this.state.orden.dctoOrden / 100);
        }
        if (this.state.orden.descEfectivo > 0) {
            cont += Math.round(this.state.orden.descEfectivo);
        }
        if (this.state.orden.carroProductos) {
            this.state.orden.carroProductos.forEach(function (producto) {
                if(producto.isDescItem)
                    cont += producto.descItem
                if (producto.descuentoPorc > 0) {
                    const descuento = (producto.precpred - (producto.valueOffer ? producto.valueOffer : 0)) * (producto.descuentoPorc / 100);
                    cont += descuento;
                }
            });
        }


        return Math.round(cont);
    }

    getDescItem() {
        let cont = 0
        this.state.orden.carroProductos.forEach(function (producto) {
            cont += producto.isDescItem? producto.descItem:0
        })
        return Math.round(cont)

    }

    GetPrecioFormateado(precio) {
        try {
            return '$' + precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } catch (err) {
            return '$ 0';
        }
    }
    GetSubtotal() {
        return this.state.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    GetTotal() {
        let ValorDescuento = this.GetDescuento()
        const offerDiscount = this.state.offerTotalDiscount
        const total = Number(this.state.total);
        if (!ValorDescuento) {
            ValorDescuento = 0;
        }
        return Math.round((total - ValorDescuento - offerDiscount));
    }

    GetTotalWithOffers() {
     
        const offerDiscount = this.state.offerTotalDiscount
        const total = Number(this.state.total);
        return Math.round((total  - offerDiscount));
    }
    GetTotalSinCheques(){
        const total = this.GetTotal();
        var pagos = this.state.pagos;
        var montoNoCheque = 0
        pagos.forEach(function (valor) {
            if (valor.medio!='Cheques'){
                montoNoCheque += Number(valor.total);
            }
        });
        
        return Math.round((total - montoNoCheque));      
    }

    GetValorPropina() {
        const propinaPorc = this.state.orden.propinaPorc;
        const propinaPorc2 = this.state.propinaPorc;
        const propinaCant = this.state.orden.propinaCant;
        const propinaDiff = this.state.orden.propinaDiff;
        if (propinaPorc > 0 && propinaCant > 0) {
            console.log('conflicto entre propinas');
        }
        else if (propinaPorc > 0) {
            var res = this.state.total * (this.state.orden.propinaPorc / 100);
            return Math.round(res);
        }
        else if (propinaCant > 0) {
            var res = this.state.orden.propinaCant;
            return Math.round(res);
        }
        else if (propinaDiff > 0) {
            const total = Number(this.state.total);
            var res = total - this.state.orden.propinaDiff;
            res = res * -1;
            return Math.round(res);
        }
        else if (propinaPorc2 > 0) {
            var res = this.state.total * (propinaPorc2 / 100);
            return Math.round(res);
        }
        return 0;
    }

    Redirigir() {
        this.setState({ redirigir: true });
    }



    async TraerMediosDePago() {
        
        try {
            const categorias = await fetch(GetUrlApi() + '/api/TbMedio');
            if (!categorias.ok) {
                throw 'problema al traer los medios de pago';
            }
            const datos = await categorias.json();
            const MediosPagoVisibles = datos.filter(t => t.isHabilitado);
            this.setState({ MediosPago: datos, MediosPagoVisibles: MediosPagoVisibles, cargandoMedios: false });
            await this.TraerMedioPagoOffer()
            // await this.orderWithOffer()
        } catch (err) {
            console.log(err);
        }
    }
    async TraerMedioPagoOffer() {
        try {
            this.resetOffer()
            let productsOrder = []
            let token =sessionStorage.getItem('jwtToken')
            this.props.orden.carroProductos.forEach(function(item) {
                productsOrder.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred })
            })

            const venta = {
                total: this.GetTotalPagar(),
                clientId: this.props.ClientData.id,
                sucursalId: GetUserCookie().Sucursal.sucursalIDBlinamic,
                paymentMethodId: this.state.paymentMethodId,
                products: productsOrder
            }
            
            const result = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOfertaMedios?venta=' + encodeURIComponent(JSON.stringify(venta)), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => { return result.result }
            )
            let mediosPagoVisibles = this.state.MediosPagoVisibles
            await mediosPagoVisibles.map((med, index2) => {
                med.offerDisc = 0
                result.methods.map((item, index) => {
                    med.offerDisc = med.id === item.paymentMethodId? item.offerDisc:med.offerDisc
                })
            })
            this.setState({ MediosPagoVisibles: mediosPagoVisibles })
        } catch (err) {
            console.log(err);
        }
        
    }
    //-------CLICKS
    async ClickMedioPago(idMedio, medio, isNumOperacion, tbmedio) {
        this.alertShowed = false;
        this.UltimoMedioAgregado = idMedio;
        this.isNumOperacion = isNumOperacion;
        this.MedioSeleccionado = medio;
		this.isAutorizeCode = tbmedio.isAutorizeCode
		this.isCuotas = tbmedio.isCuotas
        await this.setState({ paymentMethodSelectedId: idMedio })
        await this.setState({ offerPaymentMethodId: idMedio })
        
        // await this.getTotalMontoDescOfertaOnline()
        //if (this.UsedNotaCreditoID === 0){
            await this.setMontOfertaFidelizacion()
        //}
       
        if (medio == 'Canje Puntos'){ //Canje
            let existsCanje = this.state.pagos.filter((item) => item.medio == 'Canje Puntos');
            
            if (existsCanje.length > 0){
                swal("Ya existe un item de forma de pago por puntos, debe eliminarlo si desea modificar la cantidad de puntos.");
                return;
            }else{
                this.setState({ activarCanje: true });
            }
            
        }
        else if(medio == 'Giftcard'){
            this.setState({ actConsultaGiftcard: true });
        }
        else if(medio =='Orden de Compra'){
            if (this.state.datosOC.length == 0){
                this.setState({actDatosOrdenCompra : true});
            }
            
        }
        else if (tbmedio.isCheque){
            this.setState({activarIngresoCheques:true,montoCheques:this.GetBalance() });
        }
        else{
            if (tbmedio.isCuentaCasa) {
                this.setState({ mostrarCuentaCasa: true });
            }
            else {
                if (tbmedio.daVuelto)
                    this.IsEfectivoSeleccionado = true;
                else
                    this.IsEfectivoSeleccionado = false;
                    
                    medio = this.state.MediosPagoVisibles.find((m) => m.id === idMedio)
                    if (medio.isTarjeta && this.isHten()) {
                        // Llamar a HTEN PAGOS
                        console.log("MEDIO ", medio);
                        let endpoint = "compraDebito";
                        let tipoTarjetaJson = "DB";
                        if (medio.medioEquivalente === "TARJETACREDITO") {
                            // endpoint = "compraCredito";
                            // tipoTarjetaJson = "CR";
                        }
                        if (medio.medioEquivalente === "BINES") {
                            endpoint = "consultaCompra";
                        }
                        if (medio.medioEquivalente === "QR_ESTATICO") {
                            endpoint = "pagoFpay_static";
                        }
                        if (medio.medioEquivalente === "QR_DINAMICO") {
                            endpoint = "pagoFpay_dynamic";
                        }
                        console.log("endpoint ", endpoint);
                        //gasr
                        if (endpoint === "compraDebito") {
                            /* await Swal.fire({
                                title: '<strong>ATENCIÓN !!!</strong>',
                                text: 'El pago con tarjeta es para el saldo restante de la venta. Si desea pagar con otro medio de pago una parte del total, primero pague y por ultimo regrese a pagar con tarjeta.',
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonText: 'CONTINUAR',
                                denyButtonText: 'Pagar con otro medio de pago',
                                animation: false,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                closeOnClickOutside: false,
                                icon: 'warning',
                            }).then((result) => {
                                console.log("REsult ", result);
                                if (!result.isConfirmed) {
                                    return;
                                }
                                this.callToHten(endpoint, tipoTarjetaJson, null);
                            }); */
                            this.setState({ activarTeclado: true });
                        } else {
                            if (endpoint === "consultaCompra") {
                                // this.callToHten(endpoint, tipoTarjetaJson, null);
                            } else {
                                this.setState({ activarTeclado: true });
                            }
                        }
                    } else {
                        this.setState({ activarTeclado: true });
                    }

        //this.setState({ cargando: false });
                // console.log(this.GetBalance())
                // console.log(await this.getTotalMontoDescOfertaOnline())
                // console.log(this.GetBalance() - await this.getTotalMontoDescOfertaOnline())
                
                // this.setState({ activarTeclado: true });
            }

        } 

    }

    async callToHten(endpoint, tipoTarjetaJson, htenResponse, total) {
        const ipHten = localStorage.getItem('ipHten');
        const userData = JSON.parse(sessionStorage.getItem('userProfile'));
        const idOrden = this.IdOrden;
        var myBody = {};
        myBody.numeroEmpleado = userData.id + "";
        myBody.montoOperacion = (total?total:this.GetBalance()) + ""; ;// this.GetBalance() + "";
        myBody.montoPropina = "-1";
        myBody.montoVuelto = "-1";
        myBody.codigoMoneda = "CL";
        if (this.state.Ambiente=="MAISA"){
            myBody.numeroBoleta = this.VentaID + ""; 
        }else{
            myBody.numeroBoleta = this.NumeroOrdenNew + "";
        }
       
        myBody.tipoTarjeta = tipoTarjetaJson;

        const controller = new AbortController();
        const signal = controller.signal;
        let isDynamic = false;
        switch (endpoint) {
            case "pagoFpay_static":
                myBody.montoPropina = "0";
                myBody.qrType = "1";
                endpoint = "pagoFpay";
                Swal.fire({
                    title: 'Leer el QR estático',
                    text: 'Avise al cliente que lea el QR y haga el pago con la APP mobile. Este mensaje se cerrará cuando se reciba respuesta del pago o en 3 minutos por timeout.',
                    icon: 'warning',
                    showCloseButton: false,
                    showCancelButton: true,
                    showConfirmButton: false,
                    focusConfirm: false,
                    cancelButtonText: 'Cancelar...'
                }).then((result) => {
                    controller.abort();
                    this.setState({ isMedioDePagoDisabled: false });
                    this.quitarPago(this.state.pagos.length - 1);
                });

                break;
            case "pagoFpay_dynamic":
                myBody.montoPropina = "0";
                myBody.qrType = "0";
                endpoint = "pagoFpay";
                isDynamic = true;
                break;
        }

        if (htenResponse != null) {
            myBody.mensaje0110 = htenResponse.mensaje0110;
        }
        
        if (localStorage.getItem('htenDemo') != null && localStorage.getItem('htenDemo') === "SI") {
            myBody.ultimos4 = "demo";
            if (endpoint === "pagoFpay") {
                myBody.numeroEmpleado = "DEMO";
            }
        }
        console.log("Body ", myBody);
        await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'post',
            dataType: 'jsonp',
            signal: signal,
            async: false,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then(respuesta => respuesta.json()).then((data) => {
            console.log(data);
            //this.processResponse(data, endpoint);

            // Si es QR Dinamico debo mostrar el QR y luego ejecutar la consulta. El resultado da pagado o rechazado.
            
            if (endpoint === "pagoFpay") {
               
                if (isDynamic) {
                    console.log(this.state.htenJsonResponse);

                    Swal.fire({
                        title: 'Por favor realice el pago con su APP mobile.',
                        html: data.glosaRespuesta,
                        showCloseButton: false,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText:
                            '<i class="fa fa-thumbs-up"></i> Listo!',
                        confirmButtonAriaLabel: 'Vayamos a leer el resultado!',
                        cancelButtonText:
                            '<i class="fa fa-thumbs-down"> Cancelar</i>',
                        cancelButtonAriaLabel: 'Thumbs down'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // llamar a pago con propina en -1 para consultar estado
                            const controller2 = new AbortController();
                            const signal2 = controller2.signal;
                            Swal.fire({
                                title: 'Esperando respuesta de pago QR',
                                text: 'Este mensaje se cerrará cuando se reciba respuesta del pago o en 3 minutos por timeout.',
                                icon: 'warning',
                                showCloseButton: false,
                                showCancelButton: true,
                                showConfirmButton: false,
                                focusConfirm: false,
                                cancelButtonText: 'Cancelar...'
                            }).then((result) => {
                                controller2.abort();
                                this.setState({ isMedioDePagoDisabled: false });
                                this.quitarPago(this.state.pagos.length - 1);
                            });
                            myBody.montoPropina="-1"
                            fetch('http://' + ipHten + '/api/' + endpoint, {
                                method: 'post',
                                dataType: 'jsonp',
                                signal: signal2,
                                async: false,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myBody),
                            }).then(respuesta => respuesta.json()).then((data) => {
                                if (this.processResponse(data, endpoint)) {

                                    this.setState({ cerrarModalDeshabilitado: true });
                                    Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        animation: false,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'success',
                                    }).then(() => {
                                        localStorage.setItem("ultimaOrden", this.IdOrden)
                                        localStorage.setItem("tipoUltimaOrden", "pago")
                                        if (this.GetBalance() === 0) {
                                            this.setState({ isMedioDePagoDisabled: "disabled" });
                                        }
                                        return true;
                                    });
                                } else {
                                    Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago NO ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        animation: false,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'warning',
                                    });
                                    this.setState({ isMedioDePagoDisabled: false });
                                    this.quitarPago(this.state.pagos.length - 1);

                                    return false;
                                }
                            });

                        } else {
                            this.setState({ isMedioDePagoDisabled: false });
                            this.quitarPago(this.state.pagos.length - 1);
                            return false;
                        }
                    });
                    return false;
                }
            }

            if (endpoint !== "consultaCompra") {
                if (this.processResponse(data, endpoint)) {
                    this.setState({ cerrarModalDeshabilitado: true });
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta+'</strong>',
                        text: 'El pago ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'success',
                    }).then(() => {
                        localStorage.setItem("ultimaOrden", this.IdOrden)
                        localStorage.setItem("tipoUltimaOrden", "pago")
                        if (this.GetBalance() == 0) {
                            this.setState({ isMedioDePagoDisabled: "disabled" });
                        }

                        if (endpoint === "compraDebito") {
                            this.AgregarPago(this.GetBalance(), null, null, null, null, null, this.state.htenResponse, null).then(() => { });
                            this.ClickPagosListos();
                        }
                        return true;
                    });
                } else {
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta+'</strong>',
                        text: 'El pago NO ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    });
                    return false;
                }
            } else {
                console.log("consulta ", data);
                console.log("consulta json ", this.state.htenJsonResponse);
                if (this.processResponse(data, endpoint)) {
                    console.log("consulta 1 ", data);
                    console.log("consulta 1 json ", this.state.htenJsonResponse);
                    if (!this.alertShowed) {
                        this.alertShowed = true;
                        console.log(this.state.htenJsonResponse);
                        Swal.fire({
                            title: '<strong>TARJETA LEÍDA</strong>',
                            text: 'Tarjeta:' + this.state.htenJsonResponse.marcaTarjeta + ' bin:' + this.state.htenJsonResponse.seisPrimerosDigitosTarjeta,
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: 'CONTINUAR PARA PAGAR',
                            animation: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            closeOnClickOutside: false,
                            icon: 'success',
                        }).then(() => {
                            let newEndpoint = 'compraCredito';
                            if (this.state.htenJsonResponse.tipoTarjeta == "DB") {
                                newEndpoint = 'compraDebito';
                            }

                            myBody.tipoTarjeta = this.state.htenJsonResponse.tipoTarjeta;
                            myBody.mensaje0110 = this.state.htenJsonResponse.mensaje0110;

                            fetch('http://' + ipHten + '/api/' + newEndpoint, {
                                method: 'post',
                                dataType: 'jsonp',
                                async: false,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myBody),
                            }).then(respuesta => respuesta.json()).then(async(data) => {
                                if (this.processResponse(data, newEndpoint)) {

                                    this.setState({ cerrarModalDeshabilitado: true });
                                    await Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'success',
                                    }).then(() => {
                                        localStorage.setItem("ultimaOrden", this.IdOrden)
                                        localStorage.setItem("tipoUltimaOrden", "pago")
                                        if (this.GetBalance() == 0) {
                                            this.setState({ isMedioDePagoDisabled: "disabled" });
                                        }

                                        if (newEndpoint === "compraDebito") {
                                            this.AgregarPago(this.GetBalance(), null, null, null, null, null, this.state.htenResponse, null).then(() => { });
                                            this.ClickPagosListos();
                                        }
                                        return true;
                                    });
                                } else {
                                    Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago NO ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',                                        
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'warning',
                                    });
                                    return false;
                                }
                            });

                        });
                    }
                } else {
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                        text: 'El pago NO ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    });
                    return false;
                }
            }
        }).catch(async (reason) => {
            console.log(reason);
            this.setState({ htenApproved: false })
            if (reason.code !== 20) {
                await Swal.fire({
                    title: '<strong>ERROR</strong>',
                    text: 'No logramos conectar con HTEN ' + reason,
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'error',
                })
            }
            return false;
        });

    }


    async ClickImprimirHten(idOrden) {
        const usaCustomCloudService = localStorage.getItem('usaCustomCloudService')
        if (usaCustomCloudService === "true") {
            this.setState({ cargando: true });
            const userData = sessionStorage.getItem('userProfile');
            const empresa = userData.Sucursal.direccionDeli.split(".")[0];
            const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
            const printer = "printer_" + userData.caja.descripcion;
            var myBody = {
                "company_id": empresa,
                "store_id": sucursal,
                "order_id": "",
                "printer_id": printer,
                "additional_data": "print://voucherHten?" + idOrden,
                "exec_path": "C:\\fbhposprint\\"
            };

            await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(data => data.json()).then(data => {
                console.log(data);
                swal({
                    title: "Enviado a imprimir con código " + data.hten_unique_id,
                    icon: "success",
                    text: "Guarde ese código para consultar estado.",
                }).then(() => {
                    this.setState({ cargando: false });
                });
            }).catch(error => {
                swal({
                    title: "Error al querer imprimir",
                    icon: "error",
                    text: "Por favor contacte al administrador del sistema " + error,
                    dangerMode: true,
                }).then(() => {
                    this.setState({ cargando: false });
                });
            });
        } else {
            this.ClickImprimirDirectoHten(idOrden);
        }
    }

    processResponse(data, endpoint) {
        console.log("llega rta de HTEN ", data)
        if (data.constructor === ({}).constructor) {
            console.log("is_object")
            if (endpoint === "pagoFpay") {
                let fpayObj = {};
                try {
                    fpayObj = JSON.parse(data.glosaRespuesta);
                } catch (e) {
                    const codRta = parseInt(data.codigoRespuesta);
                    if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                        this.setState({ htenApproved: true })
                        return true;
                    } else {
                        return false;
                        this.setState({ htenApproved: false })
                    }
                }
                data.voucher = fpayObj.gateway.voucher.join("\n");
                data.payload = data.glosaRespuesta;
                data.glosaRespuesta = fpayObj.state;
            }
            this.setState({ htenJsonResponse: data });
            this.setState({ htenResponse: JSON.stringify(data) });

            const codRta = parseInt(data.codigoRespuesta);
            if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                this.setState({ htenApproved: true })
                return true;
            } else {
                this.setState({ htenApproved: false })
                return false;
            }
        }
        const jsonObj = JSON.parse(data);
        this.setState({ htenResponse: JSON.stringify(jsonObj) });
        this.setState({ htenJsonResponse: jsonObj });
        const codRta = parseInt(jsonObj.codigoRespuesta);
        if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
            this.setState({ htenApproved: true })
            return true;
        } else {
            this.setState({ htenApproved: false })
            return false;
        }
    }

    async ClickImprimirDirectoHten(idOrden) {
        this.setState({ cargando: true });
        var myBody = {};
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = "print://voucherHten?" + idOrden;
        myBody.path = "C:\\fbhposprint\\";
        console.log("VOUCHER HTEN", myBody);

        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).catch(() => {
            this.setState({ cargando: false });
        });
        this.setState({ cargando: false });

    }

    ClickListo() {
        if (this.props.ActualizarEstado) {
            this.props.ActualizarEstado({ activarPagar: false });
        }
    }

    ClickBorrarPago(indicePago, idMedio) {//elimina uno de los pagos realizados listados en la tabla
        swal({
            title: "¿Esta seguro que desea eliminar este pago?",
            //text: "Se cambiara el medio de pago actual",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                // No se puede quitar pagos con HTEN

                let pagos = this.state.pagos
                const medio = this.state.MediosPagoVisibles.find((m) => m.id === pagos[indicePago].idMedio)
                const processed = pagos[indicePago].processed
                if (medio.isTarjeta && this.isHten() && processed) {
                    
                    Swal.fire({
                        title: '<strong>ATENCIÓN</strong>',
                        text: 'NO SE PUEDEN QUITAR PAGOS HECHOS CON HTEN. Debe cerrar el ticket y luego hacer nota de crédito.',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    });
                } else {
                    //this.quitarPago(indicePago);
                    
                    const valorPagoRemover = pagos[indicePago].total//  - this.getTotalMontoOferta(this.state.offerProducts)
                    //pagos = pagos.filter((item) => item.idMedio != idMedio);
                    //var pagoDelete = this.state.pagos.filter((item) => item.idMedio == idMedio);
                    
                    var pagoDelete = pagos[indicePago];
                    pagos.splice(indicePago,1);
                    if (pagoDelete.medio=='Cheques'){
                        this.setState({chequesIngresados:[] });
                    }
                    else if(pagoDelete.medio=='Orden de Compra'){
                        this.setState({datosOC:[]});
                    }
                    // this.getTotalMontoDescOferta()
                    this.setState({ pagos: pagos, pagoRecibido: (this.state.pagoRecibido - valorPagoRemover), enabledOffer: false })
                    // this.setMontOfertaFidelizacion()
                    this.resetOffer()
                    
                    if(this.GetBalance() <= 0){
                        this.setState({ mpDisabled: 'disabled' }) 
                    } else {
                        this.setState({ mpDisabled: '' }) 
                    }
                }
            }
        });
    }

    quitarPago(indicePago) {
        var pagos = this.state.pagos;
        let valorPagoRemover = 0;
        if (typeof pagos[indicePago] !== 'undefined') {
            valorPagoRemover = pagos[indicePago].total;
            delete pagos[indicePago];
        }
        this.setState({ pagos: pagos, pagoRecibido: (this.state.pagoRecibido - valorPagoRemover) });
    }
    //-------FIN CLICKS

   async DesplegarMediosDePagoNC(montoNota, folioBsale) {
        var notaCreditoID = this.state.MediosPago.find(t => t.medioEquivalente == "NotaCredito");
        if (!this.NotasDeCreditoUsadas.includes(folioBsale)) {
            this.NotasDeCreditoUsadas += folioBsale + ",";
            await this.setMontOfertaFidelizacion();
            this.TraerMediosDePagoDos(notaCreditoID.id, montoNota, folioBsale);
            this.DesactivarModales();

        } else {
            swal("Esta Nota de Credito de folio " + folioBsale + ". Ya fue utilizada en esta venta");
            this.DesactivarModales();
        }

    }
    closeModal() {
        this.props.closeModal()
    }


    //--------ESTILOS
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '44%' },
            col2: { 'width': '25%' }
        },


                                    //color original
                    //BtnMedio: {'background': 'rgb(90, 88, 88)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //azul (debito)
                    //BtnMedio_2: {'background': 'rgb(0, 128, 255)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //gris suave (credito)
                    //BtnMedio_3: {'background': 'rgb(233, 233, 233)','background': 'linear-gradient(180deg, #e9e9e9 0%, #585858 100%)'}

                    //amarillo (efectivo)
                    //BtnMedio_4: {'background': 'rgb(255, 255, 153)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //lila (MACH)
                    //BtnMedio_5: {'background': 'rgb(173, 92, 255)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //calipso (GiftCard)
                    //BtnMedio_6: {'background': 'rgb(0, 220, 255)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //Naranjo (falabella pay)
                    //BtnMedio_7: {'background': 'rgb(255, 136, 0)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //Morado (orden de compra)
                    //BtnMedio_8: {'background': 'rgb(116, 92, 255)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //verde claro (convenio)
                    //BtnMedio_9: {'background': 'rgb(14, 209, 69)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

                    //verde oscuro (cheque)
                    //BtnMedio_10: {'background': 'rgb(14, 140, 48)','background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'}

        BtnMedio: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'
        },

        BtnMedio_2: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(0, 128, 255)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #0080ff 20%, #585858 80%)'
        },

        BtnMedio_3: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(233, 233, 233)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #e9e9e9 20%, #585858 80%)'
        },

        BtnMedio_4: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(255, 255, 153)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #ffff99 20%, #585858 80%)'
        },

        BtnMedio_5: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(173, 92, 255)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #ad5cff 20%, #585858 80%)'
        },

        BtnMedio_6: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(0, 220, 255)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #00dcff 20%, #585858 80%)'
        },

        BtnMedio_7: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(255, 136, 0)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #ff8800 20%, #585858 80%)'
        },

        BtnMedio_8: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(116, 92, 255)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #745cff 20%, #585858 80%)'
        },

        BtnMedio_9: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(142, 255, 174)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #8effae 20%, #585858 80%)'
        },

        BtnMedio_10: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(14, 140, 48)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #0e8c30 20%, #585858 80%)'
        },

        BtnMedio2: {
            'width': '45%',
            'fontSize': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(128,85,85)',
            'color': 'white',
            'background': 'linear-gradient(180deg, #A3A4A3 0%, #585858 100%)'
        },
        EstiloPopup: {
            'width': '70%',
            'height': '40%'
        },
        BtnOpciones: {
            'width': '49%',
            'margin': '0px',
            'height': '50px',
            'borderColor': '#d8e1de',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'fontSize': '80%',
        },
        BtnOpcionesL: {
            'width': '49%',
            'margin': '1px',
            'height': '50px',
            'borderColor': '#d8e1de',
            'color': 'white',
            'fontSize': '80%',
        },
        BtnOpcionesL2: {
            'width': '49%',
            'margin': '1px',
            'height': '50px',
            'borderColor': '#d8e1de',
            'color': 'white',
            'fontSize': '80%',
        },
        PopUpStyle:
        {
            'width': '73%',
            'height': '56%',
            'marginLeft': '1%',
            'borderRadius': '3px',
            'marginTop': '13%'
        },
        DivFlow:
        {
            'overflow': 'scroll',
            'width': '100%',
            'height': '145px',
            'marginTop': '0%'
        },
        Porden:
        {
            'marginBottom': '-6%'
        },
        PDetalle:
        {
            'marginTop': '-6%',
            'marginBottom': '3%'
        },
        PTotalesOrden:
        {
            'fontSize': '20.5px',
            'marginBottom': '0%',
            'color': '#14a00a'
        },
        PTotalesOrdenNumDoc:
        {
            'fontSize': '18px',
            'marginBottom': '0%',
            'color': '#f46a0b'
        },
        PTotalesOrdenD:
        {
            'fontSize': '18px',
            'marginBottom': '0%'
        },
        PTotalesOrdenDP:
        {
            'fontSize': '20.5px',
            'marginBottom': '0%'
        },
        MedioPago:
        {
            'width': '35%'
        },
        Pagos:
        {
            'width': '34%',
            'marginLeft': '-4%',

        },
        PagosTable:
        {
            'width': '90%',
            'marginLeft': '6%',
            'height': '280px',
            'marginBottom': '6%',
            'fontSize': '18px'
        },
        DatosOrden:
        {
            'width': '35%'
        },
        ParrafoHeader:
        {
            'marginBottom': '0'
        },
        ParrafoHeaderBottom:
        {
            'marginBottom': '0',
            'borderBottom': '1px solid #212529'
        },
        ParrafoTotalesBottom:
        {
            'marginBottom': '0',
            'borderBottom': '1px solid #212529',
            'height': '190px'

        },
        PopUpStyleNotasCredito:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '97%',
            //'height': '85%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'height': '70%',
            'borderRadius': '8px',
            'overflow': 'auto'
        },
        ColorTrOSPrimary: {
            background: '#e8eef3'
        },
        ColorTrOSSecond: {
            background: '#e8f3f2'

        }, PopUpStyleOrdenService:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '97%',
            'height': '85%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        }, StyleDivOrdenService: {
            'overflow': 'auto',
            'width': '100%',
            'height': '100%'
        }, MarginDivCliente: {
            'marginTop': '6%'
        }, MarginCCliente: {
            'marginLeft': '4%',
            'margin-right': '-5%'
        }, MarginDivClienteD: {
            'marginTop': '2%'
        },
        BtnConsultaCliente: {
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'background-color': '#8abbde',
            'height': '55px',
            'color': 'white'
        },
        ThStyle0:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)',
            'width': '10%'
        },
        ThStyle1:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)',
            'width': '15%'
        },
        ThStyle2:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)',
            'width': '20%'
        },
        ThStyle3:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)',
            'width': '30%'
        }, ColorTrOSPrimary: {
            background: '#e8eef3'
        },
        ColorTrOSSecond: {
            background: '#e8f3f2'

        },PopUpStyleAbonosReserva:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'height': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        SeparacionAddCliente: {
            'margin':'2%'
        }
        //EstiloTablaPagos:
        //{

        //}
    }
    //--------FIN ESTILOS
    Cargando() {
        if (this.state.cargandoMedios) {
            return (<p>Cargando medios de pago...</p>);
        }
    }


    Imprimir() {
        const idOrden = this.IdOrden;
        const url = "print://" + idOrden;
        window.location.href = url;
    }

    ActualizarTexto() {

    }
    ActualizarEstado(param) {
        param.orden = this.state.orden;
        this.props.ActualizarEstado(param);
        this.VolverToPagar();
    }

    ClickMas() {
        var newNumDiv = this.state.numDividir + 1;
        this.setState({ numDividir: newNumDiv });
    }

    ClickMenos() {
        var newNumDiv = this.state.numDividir - 1;
        this.setState({ numDividir: newNumDiv });
    }

    GetTotalPagar() {
        return Math.round(this.GetTotal() / this.state.numDividir);
    }
    DesactivarModales() {
        this.setState({ ActivarPopUpNotasCredito: false });
    }
    async ReabrirVuelto() {
        this.setState({ vueltoReopen: true })
        await this.setState({ activarVuelto: false });
        await this.setState({ activarVuelto: true });
    }

    GetHtmlPopupGiftcard() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info">Consultar Giftcard</CardHeader>
                <Form>
                    <CardBody id="modCliente" className="form form-group">
                    
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>     
                        <div className="form-group">     
                            <label>Serie N° Giftcard: </label>
                            <input type="text" id="SerieGiftcard" placeholder="Serie Giftcard" />
                            </div>       
                        </div>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModalesPagar() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="ConsultaGiftcard" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.ConsultaGiftcard() }} ><strong>Consultar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }

    GetHtmlPopupOrdenCompraFP() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info text-white"><strong>Ingrese datos de Orden de Compra : Saldo Venta {GetPrecioFormateado(this.GetBalance())} </strong></CardHeader>
                <Form>
                    <CardBody id="modOC" className="form form-group">
                    
                        <div className="row" style={this.Estilos.SeparacionAddCliente}>     
                            <div className="form-group col">     
                                <label>N° Orden: </label>
                                <input type="number" id="numOC" placeholder="Número OC"  className="form-control"/>
                            </div>       
                            <div className="form-group col">     
                                <label>Fecha Vencimiento: </label>
                                <input type="date" id="fecOC" className="form-control" />
                            </div> 
                         

                        </div>
                        <div className="row" style={this.Estilos.SeparacionAddCliente}>  
                               <div className="form-group col">     
                                    <label>Hes: </label>
                                    <input type="number" id="hesOC" className="form-control"  />
                                </div>    
                                <div className="form-group col">     
                                    <label>Monto: </label>
                                    <input type="number" id="montoOC" className="form-control" />
                                </div> 
                        </div>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModalesPagar() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="btnDatosOC" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.SetDatosOC() }} ><strong>Aceptar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }
    ActivarPopUpNotasDeCredito() {
        this.setState({ ActivarPopUpNotasCredito: true });
    }
    GetHtmlPopUpNotasDeCredito() {
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleNotasCredito}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.StyleDivOrdenService}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={this.Estilos.ThStyle1}>Rut</th>
                                <th style={this.Estilos.ThStyle2}>Nombre</th>
                                <th style={this.Estilos.ThStyle1}>OrdenID</th>
                                <th style={this.Estilos.ThStyle3}>Numero Comprobante</th>
                                <th style={this.Estilos.ThStyle3}>Folio </th>
                                <th style={this.Estilos.ThStyle3}>Id </th>
                                <th style={this.Estilos.ThStyle3}>Total</th>
                                <th style={this.Estilos.ThStyle3}>SubTotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.NotasDeCredito.map((item, index) => {
                                    if (this.indicecolorCL === index) {
                                        this.indicecolorCL = index;
                                        return (<tr style={this.Estilos.ColorTrOSPrimary} onClick={() => { this.DesplegarMediosDePagoNC(item.totalNC, item.folioBsale) }}>
                                            <td>{item.rut}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.ordenID}</td>
                                            <td>{item.numeroComprobante}</td>
                                            <td>{item.folioBsale}</td>
                                            <td>{item.idBsale}</td>
                                            <td>{item.totalNC}</td>
                                            <td>{item.subTotalNC}</td>
                                        </tr>);
                                    } else if (this.indicecolorCL !== index) {
                                        this.indicecolorCL = index + 1;
                                        return (<tr style={this.Estilos.ColorTrOSSecond} onClick={() => { this.DesplegarMediosDePagoNC(item.totalNC, item.folioBsale) }}>
                                            <td>{item.rut}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.ordenID}</td>
                                            <td>{item.numeroComprobante}</td>
                                            <td>{item.folioBsale}</td>
                                            <td>{item.idBsale}</td>
                                            <td>{item.totalNC}</td>
                                            <td>{item.subTotalNC}</td>
                                        </tr>);
                                    }

                                })
                            }
                        </tbody>
                    </table>

                </div>

            </Popup>
        );
    }
    ClickActivarCliente(item) {
        this.ItemDesMed = item;
        this.setState({ activarPopUpConsultaCliente: true });
    }
    async DesplegarDatosClienteDD(idCliente) {
        //let RutCliente = document.getElementById('RutCliente').value;
        //let NombreCliente = document.getElementById('NombreCliente').value;
        let empresa = GetUserCookie().EmpresaID;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetDataClientes?IdCliente=' + idCliente + '&EmpresaID=' + empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.ClientData = result;
            });
        this.setState({ activarPopUpConsultaCliente: false });
        this.ClickMedioPago(this.ItemDesMed.id, this.ItemDesMed.desmed, this.ItemDesMed.tieneNumOperacion, this.ItemDesMed)

        //let datos = respuesta.json();
    }
    GetHtmlPopupConsultaCliente() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.setState({ activarPopUpConsultaCliente: false }) }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.StyleDivOrdenService}>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="row" style={this.Estilos.MarginDivCliente}>
                                <div className="col-sm-3" style={this.Estilos.MarginCCliente}><strong>Rut:</strong> </div>
                                <div className="col-sm-6"><input type="text" onChange={() => { this.formateaRut() }} id="RutCliente" placeholder="RUT" /></div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="row" style={this.Estilos.MarginDivCliente}>
                                <div className="col-sm-3" style={this.Estilos.MarginCCliente}><strong>Nombre: </strong> </div>
                                <div className="col-sm-6"><input type="text" id="NombreCliente" placeholder="Nombre" /></div>
                            </div>
                        </div>
                        <div className="col-sm-4" >
                            <div className="row" style={this.Estilos.MarginDivClienteD}>

                                <div className="col-sm-8"> <button style={this.Estilos.BtnConsultaCliente} onClick={() => { this.BuscarCliente() }}> Consultar Cliente</button></div>

                            </div>

                        </div>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={this.Estilos.ThStyle0}>Id cliente</th>
                                <th style={this.Estilos.ThStyle1}>Rut</th>
                                <th style={this.Estilos.ThStyle2}>Nombre</th>
                                <th style={this.Estilos.ThStyle1}>Email</th>
                                <th style={this.Estilos.ThStyle3}>Telefono</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.ConsultaClientes.map((item, index) => {
                                    if (this.indicecolorCL === index) {
                                        this.indicecolorCL = index;
                                        return (<tr style={this.Estilos.ColorTrOSPrimary} onClick={() => { this.DesplegarDatosClienteDD(item.id) }}>
                                            <td>{item.id}</td>
                                            <td>{item.rut}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.email}</td>
                                            <td>{item.telefono}</td>
                                        </tr>);
                                    } else if (this.indicecolorCL !== index) {
                                        this.indicecolorCL = index + 1;
                                        return (<tr style={this.Estilos.ColorTrOSSecond} onClick={() => { this.DesplegarDatosClienteDD(item.id) }}>
                                            <td>{item.id}</td>
                                            <td>{item.rut}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.email}</td>
                                            <td>{item.telefono}</td>
                                        </tr>);
                                    }

                                })
                            }
                        </tbody>
                    </table>

                </div>

            </Popup>
        );
    }

    GetHtmlPopupMediosDePagoReserva() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAbonosReserva}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModalesPagar() }}
                closeOnDocumentClick>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <h5>Detalle de Abonos de la Reserva</h5>
                    <table className="table table-sm table-striped table-hover table-dark table-responsive" style={this.Estilos.DivProd} >
                        <thead>
                            <tr>
                                <th >Nro. Transacción</th>
                                <th >Monto</th>
                                <th >Forma de Pago</th>
                                <th> Fecha Abono</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.DetalleMediosReserva.map((item, index) => {
                                    return (<tr key={index}>
                                        <td >{item.noTransaccion}</td>
                                        <td >{GetPrecioFormateado(item.montoCuota)}</td>
                                        <td >{item.descripcionMedioPago}</td>     
                                        <td >{item.fechaAbono}</td>                                   
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );
    }

    formateaRut() {
        var rut = document.getElementById('RutCliente').value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            document.getElementById('RutCliente').value = rutSinPuntosD;
            return rutPuntos;
        }
    }


    async BuscarCliente() {
        let RutCliente = document.getElementById('RutCliente').value;
        let NombreCliente = document.getElementById('NombreCliente').value;

        var myBody = {};
        myBody.rut = RutCliente;
        myBody.nombre = NombreCliente;
        let empresa = GetUserCookie().EmpresaID;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + RutCliente + '&NombreCliente=' + NombreCliente + '&EmpresaID=' + empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                this.setState({ ConsultaClientes: result });
            }
        );
    }

    async MovimientosAbonos(){
      
        if (this.DetalleMediosReserva.length > 0 ){
                       
            this.setState ({ConsultarAbonos:true});
            return;
        }       
        else{
            swal('No se encontro información de Abonos.');
        }        
        return;
    }

    CerrarModalPagar() {
        if (this.state.listoDeshabilitado) {
            return;
        }
        if (this.state.cerrarModalDeshabilitado) {
            return;
        }
        this.props.CerrarModalMaster()
    }
    CerrarModalProcesarPago(newPagos) {
        console.log('CerrarModalProcesarPago')
        this.setState({ visibleProcesarPagos: false })
        this.setState({ pagos: newPagos })
    }

    isHten() {
        return JSON.parse(sessionStorage.getItem('userProfile')).Sucursal.hten;
    }
    getHtmlProcesarPago() {
        return (
            <ProcesarPagos
                pagos={ this.state.pagos }
                finalizarVenta= { this.finalizarVenta.bind(this) } 
                cerrarModal = {this.CerrarModalProcesarPago.bind(this) }
                orderId = { this.IdOrden }
            ></ProcesarPagos>
        )
    }

    render() {
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyle}
                modal
                defaultOpen
                onClose={() => { this.props.CerrarModalMaster() }}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                
            >

                <div className="row">

                    { (this.state.visibleProcesarPagos) ? (this.getHtmlProcesarPago()) : (null) }
                    {(this.state.activarPopUpConsultaCliente) ? (
                        this.GetHtmlPopupConsultaCliente()

                    ) : (null)}

                    {(this.state.ConsultarAbonos) ? (
                        this.GetHtmlPopupMediosDePagoReserva()

                    ) : (null)}

                    {(this.state.ActivarPopUpNotasCredito) ? (this.GetHtmlPopUpNotasDeCredito()) : (null)}

                    {
                        (this.state.mostrarGarantiaEvento) ? (
                            <PopupGarantiaEvento
                                total={this.GetBalance()}
                                AgregarPago={this.AgregarPago}
                                DesactivarModalesPagar={this.DesactivarModalesPagar}
                            />) : (null)
                    }{
                        (this.state.mostrarCuentaCasa) ? (
                            <PopupCuentaCasa
                                DesactivarModalesPagar={this.DesactivarModalesPagar}
                                AgregarPago={this.AgregarPago}
                                total={this.GetBalance()}
                                mostrarTotal={true}
                                CerrarModalMaster={this.CerrarTecladoPago}
                                ParentComp="Pagar"
                                isCuentaCasa={true}
                            />) : (null)
                    }
                   

                    {
                        (this.state.redirigir) ? (<Redirect to='/' />) : (null)
                    }
                    {
                        (this.state.activarIngresoCheques) ?
                        
                        (<IngresoCheque IsEfectivo={this.IsEfectivoSeleccionado}
                            // isNumOperacion={this.isNumOperacion}
														isNumOperacion={true}
                            AgregarPago={this.AgregarPago}  
                            mostrarTotal={true}
                            UltimoMedioAgregado= {this.UltimoMedioAgregado}
                            CerrarModalMaster={this.CerrarTecladoPago}
                            totalCheques = {Number(this.GetTotalSinCheques())}
                            Cheques = {this.state.chequesIngresados}
                            ClientData={this.ClientData}
                            ParentComp="Pagar" />)
                        : (null)
                    }
                    {
                    (this.state.activarTeclado) ?
                    
                        (<TecladoNumerico IsEfectivo={this.IsEfectivoSeleccionado}
                            isNumOperacion={ this.isNumOperacion}
							tieneNumOperacion={ this.isNumOperacion}
							isAutorizeCode = { this.isAutorizeCode}
                            AgregarPago={this.AgregarPago}
                            total={(this.GetBalance() )} 
                            mostrarTotal={true}
                            UltimoMedioAgregado={this.state.MediosPago.find(p => p.id === this.UltimoMedioAgregado)}
                            CerrarModalMaster={this.CerrarTecladoPago}
                            ParentComp="Pagar" />)
                        : (null)
                    }
                    {
                    (this.state.activarCanje) ? 
                    (<TecladoCanjePuntos IsEfectivo={this.IsEfectivoSeleccionado}
                        isNumOperacion={this.isNumOperacion}
                        AgregarPago={this.AgregarPago}
                        total={this.GetBalance()}
                        mostrarTotal={true}
                        UltimoMedioAgregado={this.state.MediosPago.find(p => p.id === this.UltimoMedioAgregado)}
                        CerrarModalMaster={this.CerrarTecladoPago}
                        ParentComp="Pagar"
                        SaldoPuntos = {this.dataClientePuntos.saldoPuntos}
                        FactorPuntos = {this.dataClientePuntos.factorCanje} />)
                    : (null)
                     }
                      {
                    (this.state.actTecladoGiftcard) ? 
                    (<TecladoGiftcard IsEfectivo={this.IsEfectivoSeleccionado}
                        isNumOperacion={this.isNumOperacion}
                        AgregarPago={this.AgregarPago}
                        total={this.GetBalance()}
                        mostrarTotal={true}
                        UltimoMedioAgregado={this.state.MediosPago.find(p => p.id === this.UltimoMedioAgregado)}
                        CerrarModalMaster={this.CerrarTecladoPago}
                        ParentComp="Pagar"
                        DatosGiftcard = {this.datosGiftcard} />)
                    : (null)
                     }
                    {(this.state.activarSetPropina) ?
                        (<SetPropina orden={this.state.orden} ActualizarEstadoPagar={this.setState2} ActualizarEstado={this.ActualizarEstado} CerrarModalMaster={this.CerrarModalMaster} />)
                        : (null)
                    }
                    {(this.state.actConsultaGiftcard) ? (
                             this.GetHtmlPopupGiftcard()
                    ) : (null)}
                     {(this.state.actDatosOrdenCompra) ? (
                             this.GetHtmlPopupOrdenCompraFP()
                    ) : (null)}
                    {(this.state.activarVuelto) ?
                        (<Vuelto
                            IdOrden={this.IdOrden}
                            orden={this.state.orden}
                            RedirigirPagar={this.Redirigir}
                            vuelto={this.VueltoBalance * -1}
                            total={this.GetTotal()}//{this.state.total}
                            mostrarTotal={true}
                            CerrarModalMaster={this.CerrarTecladoPago}
                            ParentComp="Pagar"
                            totalPropina={this.Propina}
                            HayVuelto={this.HayVuelto}
                            HayPropina={this.HayPropina}
                            TipoVenta={this.TipoVenta}
                            ReabrirVuelto={this.ReabrirVuelto}
                            TicketID={this.TicketID}
                            NotaVentaID={this.NotaVentaID}
                            IsEcommerce={this.IsEcommerce}
                            ReservaID={this.ReservaID}
                            IsBoletaSelected={this.IsBoletaSelected}
                            IsFacturaSelected={this.IsFacturaSelected}
                            ClientData={this.ClientData}
                            AddCliente={this.props.AddCliente}
                            PopUpStyleAddCliente={this.props.PopUpStyleAddCliente}
                            SeparacionAddCliente={this.props.SeparacionAddCliente}
                            BotonCerrarOSListDs={this.props.BotonCerrarOSListDs}
                            PuntosAcumulados={this.puntosAcumulados}
                            DataClientePuntos={this.dataClientePuntos}
                            offerProducts={this.state.offerProducts}
                            MediosdePago={this.state.pagos}
                            ProgramasPuntosDocumentos={this.state.ProgramasPuntosDocumentos}
                            DatosGiftcard={this.datosGiftcard}
                            UsedNotaCreditoID={this.UsedNotaCreditoID}
                            carroProducto={this.state.orden.carroProductos}
                            DataClienteConvenio={this.DataClienteConvenio}
                            IDserieGiftcard  = {this.IDserieGiftcard}
                            IsSelectedGiftcard = {this.IsSelectedGiftcard}
                            OrdenActBD = {this.OrdenActBD}
                            reopen = { this.state.vueltoReopen }
                        />)
                        : (null)
                    }
                    {(this.state.activarDescuento) ?
                        (<Descuentos
                            ActualizarTexto={this.props.ActualizarTexto}
                            VolverToPagar={this.VolverToPagar}
                            orden={this.state.orden}
                            ActualizarEstadoPagar={this.setState}
                            ActualizarEstado={this.props.ActualizarEstado}
                            CerrarModalMaster={this.CerrarModalMaster}
                            CerrarModal={this.CerrarModalMaster} />)
                        : (null)
                    }

                    <div className="column" style={this.Estilos.MedioPago}>
                    {





                        (this.IsEcommerce == true) ?
                            (
                                this.state.MediosPagoVisibles.map((item, i) => {
                                    const id = item.desmed + i;
                                    return <button style={this.Estilos.BtnMedio} disabled="disabled" className="btn btn-dark" onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                                })
                            ) :
                            (this.state.MediosPagoVisibles.map((item, i) => {
                                var configBoton = this.Estilos.BtnMedio;
                                if (item.medioEquivalente === "ORDENCOMPRA") {
                                    configBoton = this.Estilos.BtnMedio_8;
                                }
                                else if (item.medioEquivalente === "CONVENIO") {
                                    configBoton = this.Estilos.BtnMedio_9;
                                }
                                else if (item.medioEquivalente === "GIFTCARD") {
                                    configBoton = this.Estilos.BtnMedio_6;
                                }
                                else if (item.medioEquivalente === "TARJETADEBITO")
                                {
                                    configBoton = this.Estilos.BtnMedio_2;
                                }
                                else if (item.medioEquivalente === "TARJETACREDITO")
                                {
                                    configBoton = this.Estilos.BtnMedio_3;
                                }
                                else if (item.medioEquivalente === "EFECTIVO")
                                {
                                    configBoton = this.Estilos.BtnMedio_4;
                                }
                                else if (item.medioEquivalente === "CHEQUE")
                                {
                                    configBoton = this.Estilos.BtnMedio_10;
                                }
                                else
                                {
                                    configBoton = this.Estilos.BtnMedio;
                                }



                                const id = item.desmed + i;
                                var isVisibleCanje = false;

                                if (this.IsSelectedGiftcard){
                                    if( item.medioEquivalente == "EFECTIVO" || item.medioEquivalente=="TARJETADEBITO"){
                                            return <button style={configBoton} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                                    }
                                }
                                else{

                                        if (item.medioEquivalente=="CANJEPUNTOS"){
                                            try{ 
                                                this.state.ProgramasPuntosDocumentos.map((currentValue,x)=> {
                                                    if (currentValue.codigo == 1 && this.IsBoletaSelected) //boleta 
                                                    {
                                                        isVisibleCanje=true; 
                                                    }
                                                    if(currentValue.codigo == 2 && this.IsFacturaSelected){
                                                        isVisibleCanje=true; 
                                                    }
                                                });
                                                if (isVisibleCanje){
                                                    return <button style={this.Estilos.BtnMedio} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                                                }
                                            }catch(e){
                                                console.log(e);
                                            }
                                        }
                                        else if( item.medioEquivalente == "ORDENCOMPRA"){
                                            if (this.IsFacturaSelected){
                                                return <button style={configBoton} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                                            }
                                        }
                                        else if (item.medioEquivalente === "CONVENIO") {
                                            if (this.DataClienteConvenio.idConvenio != 0 && this.DataClienteConvenio.EsBeneficioInterno) {
                                                return <button style={configBoton} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                                            }
                                        }  
                                        else if(item.medioEquivalente==="GIFTCARD"){
                                            return <button style={configBoton} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                                        }  
                                        else{
                                            if (item.isClienteObligatorio) {
                                                    
                                                if (this.state.IsClienteObligatorio) {
                                                    return <button style={configBoton} disabled={this.state.mpDisabled} className="btn btn-dark" onClick={() => { this.ClickActivarCliente(item) }} name="btnMedio" key={id}> {item.desmed}</button>

                                                } else {
                                                    return item.offerDisc > 0 ? <button style={configBoton} disabled={this.state.mpDisabled} className="btn btn-dark" onClick={() => { this.ClickMedioPago(item.id, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} <small><i class="fa fa-bookmark"></i></small></button> : <button style={configBoton} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                                                    // return <button style={this.Estilos.BtnMedio} className="btn btn-dark" onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed}({item.offerDisc}) </button>

                                                }

                                            }
                                            else if (item.medioEquivalente == "NotaCredito") {
                                                if (this.state.Ambiente != 'MAISA') {
                                                    if (this.TieneCliente && this.CantidadNC == 1) {
                                                        return <button style={this.Estilos.BtnMedio2} disabled={this.state.mpDisabled} className="btn btn-dark" onClick={() => { this.ActivarPopUpNotasDeCredito() }} name="btnMedio" key={id}> {item.desmed} ({this.CantidadNC})</button>
                                                    } else if (this.TieneCliente && this.CantidadNC > 1) {
                                                        return <button style={this.Estilos.BtnMedio2} disabled={this.state.mpDisabled} className="btn btn-dark"
                                                            onClick={() => { this.ActivarPopUpNotasDeCredito() }} name="btnMedio" key={id}> {item.desmed} ({this.CantidadNC})</button>

                                                    }
                                                }
                                                ////else {
                                                ////    if (!this.NCVieneDesdePantallaVenta) {
                                                ////        if (this.TieneCliente && this.CantidadNC == 1) {
                                                ////            return <button style={this.Estilos.BtnMedio2} disabled={this.state.mpDisabled} className="btn btn-dark" onClick={() => { this.ActivarPopUpNotasDeCredito() }} name="btnMedio" key={id}> {item.desmed} ({this.CantidadNC})</button>
                                                ////        } else if (this.TieneCliente && this.CantidadNC > 1) {
                                                ////            return <button style={this.Estilos.BtnMedio2} disabled={this.state.mpDisabled} className="btn btn-dark"
                                                ////                onClick={() => { this.ActivarPopUpNotasDeCredito() }} name="btnMedio" key={id}> {item.desmed} ({this.CantidadNC})</button>

                                                ////        }
                                                ////    }
                                                ////}
                                            }
                                            else {
                                                    
                                                return item.offerDisc > 0 ? <button style={configBoton} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button> : <button style={configBoton} className="btn btn-dark" disabled={this.state.mpDisabled} onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} {item.tieneNumOperacion} </button>

                                            }
                                        }

                                   }    
                                 })
                            )
                    }


                    </div>

                    <div className="column" style={this.Estilos.Pagos} >
                        <div style={this.Estilos.PagosTable}> {
                            (this.state.cargando) ? (
                                <div style={this.Estilos.FondoDefault}>
                                    <center>
                                        <div className="spinner-border text-dark" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <p> Cargando...</p>
                                    </center>
                                </div>
                            ) : (null)
                        }
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Forma Pago</th>
                                        <th>Monto</th>
                                        {/* { this.state.offerTotalDiscount>0? <th>Descuento</th>:null} */}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.pagos.map((item, i) => {
                                            //const isGarantiaEvento = this.state.MediosPago.find(p => p.id === item.idMedio).isGarantiaEvento; <td> {item.medio} {(isGarantiaEvento) ? (mensaje) : (null)}</td>
                                            var mensaje = (item.isFacturado) ? ('(Facturado)') : ('(Por facturar)');
                                            return (
                                                <tr key={item.medio + i}>
                                                    <td>{item.medio}</td>
                                                    <td>{this.GetPrecioFormateado(item.total)}  </td>
                                                    {/* {this.state.offerTotalDiscount>0? <td> { this.state.offerTotalDiscount * -1 } </td>:null} */}
                                                    {
                                                        (this.IsEcommerce === true || item.medio  === 'Abono Cliente'  ||(item.medio === 'Nota de Crédito' && this.state.Ambiente==='MAISA')) ? (<td> </td>) :
                                                            (<td> <a onClick={() => { this.ClickBorrarPago(i, item.idMedio) }} href="javascript:void(0)" ><img height="30" width="30" src={imgcancel} /></a></td>)
                                                    }

                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                        <button className="btn btn-danger" onClick={() => { this.CerrarModalPagar() }} style={this.Estilos.BtnOpcionesL2}><i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>

                        {(this.state.pagoRecibido >= (this.GetTotal() - 9)) ? (
                            (this.state.pagos.length > 0) ? (
                                (this.state.listoDeshabilitado) ? (
                                    <button id="btnListo" disabled='true' className="btn btn-success" style={this.Estilos.BtnOpcionesL}
                                        data-toggle="tooltip" data-placement="right" title="Listo"><i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
                                ) : (
                                        <button id="btnListo" onClick={() => { this.ClickPagosListos() }} className="btn btn-success" style={this.Estilos.BtnOpcionesL}
                                            data-toggle="tooltip" data-placement="right" title="Listo"><i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
                                    )

                            ) : (null)
                        ) : (null)

                        }
                    </div>


                    <div className="column" style={this.Estilos.DatosOrden}>
                        <div style={this.Estilos.ParrafoHeaderBottom}>
                            
                            <p style={this.Estilos.PTotalesOrdenNumDoc}># NumDoc: {this.NumDocVenta}</p>
                            <p style={this.Estilos.ParrafoHeader}># Orden: {this.NumeroOrdenNew}</p>
                            <p style={this.Estilos.ParrafoHeader}>Usuario: {GetUserCookie().username}</p>
                            <p style={this.Estilos.ParrafoHeader}>
                                {this.TipoVentaDesc}
                                {
                                    (this.state.orden.mesaID) ? (<span> {this.state.orden.mesa.descipcion}</span>) : (null)
                                }
                            </p>
                        </div>
                        <div style={this.Estilos.ParrafoTotalesBottom}>
                            <p style={this.Estilos.ParrafoHeader}>Subtotal:  ${this.GetSubtotal()} </p>
                            <p style={this.Estilos.ParrafoHeader}>Descuento Caja {this.GetPrecioFormateado(this.GetDescuento())}</p>
                            <p style={this.Estilos.ParrafoHeader}>Descuento Oferta { this.GetPrecioFormateado(this.state.offerTotalDiscount) }</p>
                            <p style={this.Estilos.ParrafoHeader}>Monto Abonado { this.GetPrecioFormateado(this.state.montoAbonado) + '  ' }
                                <button className="btn btn-info btn-sm" id='btnabonos' title='Ver Detalle Abonos' onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.MovimientosAbonos() }} >
                                    <i className="fa fa-info-circle small fa-1x"></i>
                                </button>
                            </p>                            
                            <p style={this.Estilos.PTotalesOrden}><strong>Total {this.GetPrecioFormateado(this.GetTotal())} </strong></p>
                            <p style={this.Estilos.PTotalesOrdenD}><strong>Total recibido {GetPrecioFormateado(this.state.pagoRecibido)}</strong></p>
                            <p style={this.Estilos.PTotalesOrdenD}><strong>Diferencia {this.GetPrecioFormateado(this.GetBalance())}</strong></p>
                            <p style={this.Estilos.PTotalesOrdenD}><strong>Vuelto {((this.VueltoBalance * -1) > 0) ? (this.GetPrecioFormateado(this.VueltoBalance * -1)) : (this.GetPrecioFormateado(0))}</strong></p>
                        </div>
                        <div>
                            <hr />
                            <button onClick={() => { this.setState({ activarDescuento: true }) }} className="btn btn-dark" style={this.Estilos.BtnOpciones}
                                data-toggle="tooltip" data-placement="right" title="Descuento" hidden="hidden">Descuento</button>
                            <button onClick={() => { this.ClickImprimir2() }} className="btn btn-dark" hidden="hidden" style={this.Estilos.BtnOpciones}
                                data-toggle="tooltip" data-placement="right" title="Imprimir cuenta"><i className="fa fa-print fa-2x" aria-hidden="true"></i></button>

                        </div>

                    </div>



                </div>
            </Popup>
        );
    }
}
