import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';


export class EspecificarPedido extends Component {
    static displayName = EspecificarPedido.name;
    idGrupo;
    Producto = {};
    MontoAumentoMod = 0;
    constructor(props) {
        super(props);
        this.state = {
            modificadores: [{isObligatorio:false}],
            listasModificadores: [],
            modificadorSeleccionado: {},
            indiceModif: 0,
            listaModfSeleccionados: [],
            cargandoModificadores: true
        }
        this.CerrarModalMaster = props.CerrarModalMaster;
    }

    componentDidMount() {
      
        this.Producto = this.props.prod;//contene el producto que fue clickeado
        this.TraerModificadores();
        this.AgregarProductoBarcode = this.props.AgregarProductoBarcode;
    }
    AgregarProductoBarcode() {

    }


    async TraerModificadores() {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GettbproductoConModif?id=' + this.Producto.id);
            if (!categorias.ok) {
                let errorMessage = { message: 'problema al traer los modificadores' };
                throw errorMessage;
            }
            var datos = await categorias.json();
            
            var sp = datos.relProdMods;
            this.setState({ modificadores: sp, listasModificadores: sp, modificadorSeleccionado: sp[this.state.indiceModif].tbModificadores, cargandoModificadores: false });
            
        } catch (err) {
            console.log(err.message);
        }
    }

    AgregarProductoSeleccionado(id) {
        var modifConcat = '';
        this.state.listaModfSeleccionados.forEach((currentValue) => {
            modifConcat += currentValue;
            modifConcat += '\n';
        });
        this.props.handler(id, this.state.listaModfSeleccionados,false, this.MontoAumentoMod);
        
        this.CerrarModal();
    }

    CerrarModal() {
        try {
            var modales = document.getElementsByClassName("popup-overlay");
            if (modales.length > 0)
                modales[modales.length - 1].style.visibility = "hidden";
        } catch (err) {
            console.log('ex en cierre de modal '+err);
        }
    }


    //-----INICIO Estilos
    FullAncho = {
        'width': '100%'
    }
    BtnOptionStyle=
        {
            'width': '40%',
            'margin-right': '5%',
            'margin-bottom': '1%',
            'background-color': 'rgb(118, 158, 142)',
            'height': '48px'
        }
    BtnOptionStyle2 =
        {
            'width': '80%',
            'margin-right': '3%',
            'margin-bottom': '1%',
            'height': '48px',
            'margin-left': '6%'
        }
    TercioAncho= {
        'width': '33%'
    }
    
    buttonProductosSize = {
        'width': '23%',
        'height': '55px',
        'color': 'black',
        'fontweight': 'bold',
        'margin': '1px'
    }

    //-----FIN ESTILOS

    GetHtmlOptModif() {
        if (!this.state.modificadorSeleccionado) {
            return <p>No hay modificadores asociados a este producto</p>
        }
        else {
           
                try {
                    return this.state.modificadorSeleccionado.map((item, index) => {
                        console.log(item);
                        const modif = item.desmod;
                        if (item.habilita === 1) {
                            return <button onClick={() => { this.AgregarModificador(item) }} style={this.BtnOptionStyle} className="btn btn-secondary btn-lg" key={index}>{item.desmod}</button>
                        }
                    })
                } catch (err) {
                    console.log(err);
                    return <div></div>
                }
            
           
        }
    }

    async AgregarModificador(modif) {
        var arreglo = this.state.listaModfSeleccionados;
        const indiceActual = this.state.indiceModif;
        const ultimoIndice = this.state.listasModificadores.length - 1;
        const idProd = this.props.prod.id;//cambiado codprod por ID
        
        //this.MontoAumentoMod += modif.montoAumentoPrec;
        this.setState({ listaModfSeleccionados: arreglo });
        if (modif.tbProductoID !== null && modif.isProducto) {
           
            var productoAgregar = await this.TraerProducto(modif.tbProductoID);
            if (modif.isCombo) {
                productoAgregar.precpred = 0;
                arreglo.push(modif.desmod);
            }
            this.props.AgregarProductoModif(productoAgregar, null, false, indiceActual === ultimoIndice);
            
        }
        else {
            arreglo.push(modif.desmod);
        }
        if (indiceActual === ultimoIndice) {
            this.AgregarProductoSeleccionado(idProd);
            this.Resetear();
            this.props.CerrarModalMaster();
        }
        else {
            this.SgteModificador();
        }
    }

    ClickProducto() {
        this.TraerModificadores();
    }

    SgteModificador() {
        var indiceSgte = this.state.indiceModif;
        indiceSgte++;
        const sgteModif = this.state.modificadores[indiceSgte];
        
        this.setState({ indiceModif: indiceSgte, modificadorSeleccionado: sgteModif.tbModificadores });
    }

    SgteModificador2() {
        const indiceActual = this.state.indiceModif;
        const ultimoIndice = this.state.listasModificadores.length - 1;
        const idProd = this.props.prod.id;
        var indiceSgte = this.state.indiceModif;
        indiceSgte++;
        const sgteModif = this.state.modificadores[indiceSgte];
        if (indiceActual === ultimoIndice) {
            this.AgregarProductoSeleccionado(idProd);
            this.Resetear();
            this.props.CerrarModalMaster();
        }
        else {
            this.setState({ indiceModif: indiceSgte, modificadorSeleccionado: sgteModif.tbModificadores });
        }
        
    }

    async Resetear() {
        await this.setState({
            indiceModif: 0,
            listaModfSeleccionados: [],

        });
        this.TraerModificadores();
    }

    CerrarModalMaster() {
        //sera reescrita por una prop
    }


    async TraerProducto(id) {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorId?id=' + id);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            var datos = await categorias.json();

            return datos;
        } catch (err) {
            return -1;
        }
        return 0;
    }

    render() {
       
        return (
            <Popup
                defaultOpen
                modal
                onClose={() => { this.props.DesactivarModales() }}
                closeOnDocumentClick>
                {
                    (this.state.cargandoModificadores) ? (
                        <center>
                            <div className="spinner-border text-dark" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <p> Cargando...</p>
                        </center>)
                        : (null)
                }
                <div>
                    <h1> {(this.state.modificadores[this.state.indiceModif]) ? (this.state.modificadores[this.state.indiceModif].mensaje) : (<div></div>)} </h1>
                    <center>{this.GetHtmlOptModif()}</center>

                </div>
                <br />
                <hr />
               
                {
                    (!this.state.modificadores[this.state.indiceModif].isObligatorio) ? (
                        <button style={this.BtnOptionStyle2} className="btn btn-danger" onClick={() => { this.SgteModificador2() }}> <i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                    ): (null)
                }
            </Popup>
        );
    }
}
