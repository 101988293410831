import React, { Component } from 'react';
//import { GetUrlApi } from './Globales/VariableGlobales';
//import './Estilos.css';
import Popup from "reactjs-popup";

//import swal from 'sweetalert';
import { TecladoNumerico } from './TecladoNumerico';




export class CambioPrecioPopUp extends Component {
    static displayName = CambioPrecioPopUp.name;
    Orden = {};
    IndiceProdSelect = 0;

    constructor(props) {
        super(props);
        this.state = {
            dctoOrden: false,
            dctoArt: true,
            dctoEfec: false,
            TiposDescuento: {},
            mostrarDescProdPorc: false
        }
        console.log(this.props.productos);
        console.log(this.props.orden);
        if (this.props.CerrarModal)
            this.CerrarModalMaster = this.props.CerrarModalMaster;
        if (this.props.orden)
            this.Orden = this.props.orden;
        if (this.props.ActualizarEstado)
            this.ActualizarEstado = this.props.ActualizarEstado;

       // this.TraerTiposDescuentos();
    }

    componentDidMount() {

    }

    DesactivarOpciones() {
        console.log(this.state);
        this.setState({
            dctoOrden: false,
            dctoArt: false,
            dctoEfec: false,
            mostrarDescProd: false
        });
    }

    ReseatearBotones() {
        var botones = document.getElementsByName('OpcPrincipales');
        botones.forEach((value) => {
            value.className = 'btn btn-dark';
        });
    }

    ReseatearBotonesPorcentaje() {
        var botones = document.getElementsByName('descPorc');
        botones.forEach((value) => {
            value.className = 'btn btn-light';
        });
    }
    CerrarModal() {
        //sobreescrito por prop
    }
    //CerrarModal() {
    //    this.setState({ dctoEfec: false });
    //}


    Estilos = {
        btnDescuento: {
            'width': '33%',
            'font-size': '80%'
        },
        TercioAncho: {
            'width': '33%'
        },
        FullAncho: {
            'width': '50%',
            'margin-top': '-3%',
            'margin-left': '-6%'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '100%'
        },
        AlturaListaProductos: {
            'height': '42%'
        },
        EstiloPopup: {
            'width': '47%',
            'height': '70%',
            'border-radius': '2%'
        },
        MarginTr:
        {
            'margin-bottom': '10%'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        BtnStyles:
        {
            'width': '120px',
            'height': '50px',
            'background-color': '#769e8e'
        },
        BtnStylesM:
        {
            'width': '120px',
            'height': '50px',
            'background-color': 'rgb(207, 240, 227)'
        },
        BtnDanger:
        {
            'width': '50%',
            'margin-top': '-3%',
            'background-color': '#f80303ad'
        },
        BtnArt:
        {
            'width': '40%',
            'height': '60px',
            'margin-right': '2%',
            'margin-bottom': '2%',
            'background-color': 'rgb(118, 158, 142)',
            'border-color': '#17a2b8',
            'font-size': '80%',
            'margin-left': '8%'
        }
    }

    //--------SOBRESCRIBIR
    CerrarModalMaster() { }

    ActualizarEstado() {
        console.log('ActualizarEstado no actualizo');
    }
    ActualizarTexto() {
        console.log('ActualizarTexto no actualizo');
    }
    
    //--------FIN SOBRESCRIBIR

    ClickDescuentoProd(indice) {
        this.IndiceProdSelect = indice;
        console.log(indice);
        this.setState({ mostrarDescProd: true});

    }

 

   

    GetHtmlBorrarDescOrden() {
        if (this.props.orden.dctoOrden > 0) {
            return (
                <button className="btn btn-danger" onClick={() => { this.ClickDescuento(this.props.orden.dctoOrden) }} style={this.Estilos.FullAncho}>Remover descuento</button>
            );
        }
        return null;
    }

    render() {
        return (
            <Popup
                onClose={() => { this.CerrarModal() }}
                contentStyle={this.Estilos.EstiloPopup}
                defaultOpen
                modal
                closeOnDocumentClick>
                {
                    (this.state.mostrarDescProd) ? (
                        <TecladoNumerico
                            ActualizarEstado={this.props.ActualizarEstadoMaster}
                            orden={this.Orden}
                            CerrarModal={this.props.CerrarModal}
                            CerrarModalMaster={this.props.CerrarModalMaster} cambioPrecio={true} IndiceProducto={this.IndiceProdSelect} />
                    ) : (null)
                }
                {
                    (this.state.dctoArt) ? (
                        <div style={this.Estilos.AlturaListaProductos}>
                            <h3>Seleccione el producto a cambiar precio</h3>
                           
                            <div style={this.Estilos.Escroleable}>
                                {
                                    this.props.productos.map((item, i) => {
                                        const prefijo = 'optDescuento';
                                        const id = i + 'optDescuento';
                                        return <button name="optDescuento" id={prefijo + i} style={this.Estilos.BtnArt} className="btn btn-info" onClick={() => { this.ClickDescuentoProd(i, id) }} key={prefijo + i}> {item.desprod}{(item.descuentoPorc > 0) ? (<div>({item.descuentoPorc}% descuento)</div>) : (null)} </button>
                                    })
                                }
                            </div>
                        </div>) : (null)
                }
                <br/>
                <br/>
                <br/>
                <br/>
                <hr />

                <center>
                    <button onClick={() => { this.props.DesactivarModales() }} style={this.Estilos.BtnDanger} className="btn btn-primary">  <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                </center>
            </Popup>
        );
    }
}
