import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';
import Popup from "reactjs-popup";
import { PopupModificadoresProducto } from "./PopupModificadoresProducto";


export class MantenedorModificadores extends Component {
    static displayName = MantenedorModificadores.name;

    StateInicial = {
        activarAgregarMod: false,
        activarEditarMod: false,
        activarEditarModPop: false,
        activarAgregarCategoria: false,
        activarPopupModifs: false,
        categoriasBotones: [],
        cargandoCategorias: true,
        productosBotones: [],
        productosSeleccionados: [],
        //---->inicio estados crud categ 
        categoriaSeleccionada: {},
        sucursales: [],
        modificadorSeleccionado: {},
        nivelSeleccionado:0,
        isAddCombo: false,
        IsSending: false,
        //----> fin estados crud categ 

        //---->inicio estados crud producto 
        productoClickeado: {
            relProdMods:[
                { tbModificadores: {} }
            ]
        },
        modificadoresProducto:[],
        zonasImpresion: [],
        defaultIsPesableAgregar: true,
        prodsCombo:[],
        //----> fin estados crud producto
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerCategorias();
        this.TraerTodosProductos(GetUserCookie().SucursalID);
        this.CerrarPopupModif = this.CerrarPopupModif.bind(this);
        this.CrearModificador2 = this.CrearModificador2.bind(this);
        this.CrearModificadorMulti = this.CrearModificadorMulti.bind(this);
    }

    CerrarPopups() {
        this.setState({
            activarAgregarMod: false,
            activarEditarModPop: false,
            isAddCombo: false,
        });
    }

    //-------->INICIO FUNCIONES CRUD
    async TraerProductos() {
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ productos: datos });
        }
    }


    async TraerCategorias() {
        try {
            var SucursalID = GetUserCookie().SucursalID;
            const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);

            const datos = await categorias.json();

            this.setState({ categoriasBotones: datos, cargandoCategorias: false });

        } catch (err) {

        }
    }
    
    async CrearModificador() {//borrar
        var producto = {};
        producto.Desmod = document.getElementById('txtDescripcionMod').value;
        producto.Habilita = 1;
        producto.IsProducto = document.getElementById('chkIsProd').checked;
        producto.isCombo = !document.getElementById('chkIsCombo').checked;
        if (this.state.productoClickeado.relProdMods.length>0) {
            producto.RelProdModID = this.state.nivelSeleccionado;
        } else {
            producto.RelProdModID = 0;
            producto.TbProductoID = this.state.productoClickeado.id;
        }
        producto.TbProductoID = this.state.productoClickeado.id;
        producto.SucursalID = GetUserCookie().SucursalID;
        producto.UsuIngreso = GetUserCookie().username;
        
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            this.RecargarProducto();
        }
    }

    async CrearModificador2(desmod) {
        if (this.state.IsSending) {
            return;
        }
        await this.setState({ IsSending:true });
        var producto = {};
        producto.Desmod = desmod;
        producto.Habilita = 1;
        producto.IsProducto =false;
        if (this.state.productoClickeado.relProdMods.length > 0) { 
            producto.RelProdModID = this.state.nivelSeleccionado;
        } else {
            producto.RelProdModID = 0;
            producto.TbProductoID = this.state.productoClickeado.id;
        }
        producto.TbProductoID = this.state.productoClickeado.id;
        producto.SucursalID = GetUserCookie().SucursalID;
        producto.UsuIngreso = GetUserCookie().username;
        producto.productoAsociadoID = this.state.productoClickeado.id;
        const isObligatorio = !document.getElementById('chkObligatorio').checked;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/?isObligatorio=' + !isObligatorio, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            this.RecargarProducto();
            if (producto.RelProdModID === 0) {
                var resp = await respuesta.json();
                this.setState({ nivelSeleccionado: resp.relProdModID });
            }
        }
        this.setState({ IsSending:false });
    }

    async CrearModificadorMulti(listaModifs) {
        var res = []; 
        listaModifs.forEach((item, i) => {
            var producto = {};
            producto.Desmod = item.label;
            producto.Habilita = 1;
            producto.IsProducto = false;
            producto.RelProdModID = this.state.nivelSeleccionado;
            producto.TbProductoID = this.state.productoClickeado.id;
            producto.SucursalID = GetUserCookie().SucursalID;
            producto.UsuIngreso = GetUserCookie().username;
            producto.MontoAumentoPrec = item.attributes[0].value;
            producto.isCombo = !document.getElementById('chkIsCombo').checked;
            res.push(producto);
        });

        const isObligatorio = document.getElementById('chkObligatorio').checked;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/CrearMultiplesModifs?isObligatorio=' + isObligatorio, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(res),
        });
        if (respuesta.ok) {
            this.RecargarProducto();
        }
    }

    //-------->FIN FUNCIONES CRUD

    //-------->INICIO FUNCIONES ONCHANGE

    //-------->FIN FUNCIONES ONCHANGE
    Estilos = {
        Columna1: {
            'height': '600px',
            'overflowY': 'scroll',
            'resize': 'both',
            'overflow': 'auto'
        },
        DivCentralTest: {
            'width': '50%',
            'float': 'left',
            'padding': '10px',
            //'height': '100%'
        },
        MarginLeftCR:
        {
            'marginLeft': '-2.5%'
        },
        BotoneraCategoria:
        {
            'width': '50%',
            'height': '60px',
            'borderRadius': '15px',
            'paddingTop': '10px',
            'paddingRight': '20px',
            'paddingBottom': '10px',
            'overflow': 'hidden',
            'outline': 'none',
            'borderColor': 'grey',
            'fontSize': '80%',
            'text-align': 'center'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'marginTop': '0.5%',
            'marginRight': '1%',
            'marginLeft': '2%'
        },
        BtnSalir: {
            'height': '44px',
            'width': '50px',
            'border-color': 'rgb(110, 152, 135)',
            'border-radius': '10%'
        },
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        BotoneraProductos: {
            'width': '32%',
            'height': '70px',
            'color': 'black',
            'border-color': '#bfbfc0',
            'margin-bottom': '1%',
            'font-size': '85%',
            'margin-right': '0.25%'
        },
    }


    //------->inicio onclick
    async ClickCategoria(idCategoria, idBtn, categoria) {
        var botones = document.getElementsByName('botonesCategorias');
        botones.forEach((value) => {
            value.style.backgroundColor = '';
            value.style.color = '';
        });
        document.getElementById(idBtn).style.backgroundColor = '#6e9887';
        document.getElementById(idBtn).style.color = '#fff';
        await this.setState({ cargandoProductos: true });
        try {
            var SucursalID = GetUserCookie().SucursalID;
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
            if (!categorias.ok) {
                throw 'problema al traer las categorias';
            }
            var datos = await categorias.json();

            //#RFD.26112019 Optimiza filtro en API  
            //datos = datos.filter(datos => datos.codgrup === idCategoria);

            this.setState({ productosSeleccionados: datos, cargandoProductos: false, activarEditarMod: false, categoriaSeleccionada: categoria });
        } catch (err) {

        }
    }

    async ClickProducto(producto) {
        var modProd = [];
        if (producto.relProdMods.length>0) {
            modProd = producto.relProdMods;
           
        } 
        await this.setState({ activarEditarMod: true, productoClickeado: producto, modificadoresProducto: modProd });
        document.getElementById('chkObligatorio').checked=true;
    }
    


    async ClickEditarMod() {
        var modif = this.state.modificadorSeleccionado;
       modif.Desmod = document.getElementById('txtDescripcionMod').value;
       modif.Habilita = 1;
       modif.IsProducto = document.getElementById('chkIsProd').checked;
       modif.RelProdModID = this.state.productoClickeado.relProdMods[0].id;
       modif.SucursalID = GetUserCookie().SucursalID;
        modif.UsuIngreso = GetUserCookie().username;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/' + modif.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(modif),
        });
        if (respuesta.ok) {
            swal('Modificador actualizado con exito');
            this.RecargarProducto();
        }
    }

    async ClickEliminarMod(idMod) {
        var modifsToDelete = document.getElementsByName('chkBorrarModif');
        modifsToDelete = Array.from(modifsToDelete);
        modifsToDelete=modifsToDelete.filter(p => p.checked);
        if (modifsToDelete.length > 0) {
            swal({
                title: "¿Está seguro que desea dehabilitar los modificadores seleccionados?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    this.ClickBorradoMultiple().then(() => {
                        this.RecargarProducto();
                    });
                }
                
            });
        }
        else {
            swal({
                title: "¿Está seguro que desea dehabilitar este modificador?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/' + idMod, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {

                        this.RecargarProducto();
                    }
                }
            });
        }
        
    }
    ClickIsProducto() {
        this.setState({ isAddCombo: true });
    }

    async ClickNivelObligatorio(id) {
       
        var respuesta = await fetch(GetUrlApi() + '/api/RelProdMods/EditObligatorio?id=' + id , {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (respuesta.ok) {
            this.RecargarProducto();
            
        }
    }

    async ClickBorradoMultiple() {
        var modifsToDelete = document.getElementsByName('chkBorrarModif');
        modifsToDelete = Array.from(modifsToDelete);
        modifsToDelete=modifsToDelete.filter(p => p.checked);
        
        for (var i = 0; i < modifsToDelete.length;i++) {
            var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/' + modifsToDelete[i].value, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        }
        
    }

    //------->fin onclick

   async  TraerTodosProductos(id) {
        var res = [];
       var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/GettbproductoPorSucursal?sucursalID='+id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            if (datos.length > 0) {
                datos.forEach((item) => {
                    res.push(item.desprod);
                });
            }
       }
       this.setState({ prodsCombo:res });
    }

    Recargar() {
        this.setState(this.StateInicial);
        this.TraerCategorias();
    }

    async RecargarProducto() {
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + this.state.productoClickeado.tbgrupoID + "&SucursalID=" + SucursalID);
        if (!categorias.ok) {
            throw 'problema al traer las categorias';
        }
        var datos = await categorias.json();
        const newProd = datos.find(p => p.id === this.state.productoClickeado.id);
        await this.setState({ productoClickeado: {}, activarEditarMod: false });
        await this.setState({ productoClickeado: newProd, activarEditarMod: true, productosSeleccionados: datos });
        await this.ClickProducto(newProd);
        document.getElementById('chkObligatorio').checked = true;
    }

    GetHtmlPopupAgregarMod() {
        return (
            <Popup
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.CerrarPopups() }}
                closeOnDocumentClick>
                    <center>
                    <h3>Ingrese la infomacion requerida</h3>
                    <table>
                        <tbody>
                            {
                                (!this.state.isAddCombo) ? (
                                    <tr>
                                        <td>Descripcion</td>
                                        <td>
                                            <input className="form-control" id="txtDescripcionMod" type="text" />
                                        </td>
                                    </tr>
                                ) : (null)
                            }
                           
                            {
                                (this.state.isAddCombo) ? (
                                    <tr>
                                        <td>Nombre del producto</td>
                                        <td>
                                            <input id="txtDescripcionMod" id="txtDescripcionMod" list="browsers" name="browser" className="form-control"/>
                                            <datalist id="browsers">
                                                {
                                                    this.state.prodsCombo.map((item) => {
                                                        return (<option value={item} />);
                                                    })
                                                }
                                            </datalist>
                                        </td>
                                    </tr>
                                ): (null)
                            }
                            
                            <tr>
                                <td>¿Es un producto?</td>
                                <td>
                                    <input onClick={() => { this.setState({ isAddCombo:true }) }} id="chkIsProd" type="checkbox" />
                                </td>
                            </tr>
                            

                        </tbody>
                    </table>
                    <button onClick={() => { this.CrearModificador() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>

                </center>

            </Popup>
        );
    }

    GetHtmlPopupEditarMod() {

        return (
            <Popup
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.CerrarPopups() }}
                closeOnDocumentClick>
                <center>

                    <h3>Ingrese la infomacion requerida</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Descripcion</td>
                                <td>
                                    <input defaultValue={this.state.modificadorSeleccionado.desmod} className="form-control" id="txtDescripcionMod" type="text" />
                                </td>
                            </tr>
                            <tr>
                                <td>¿Es un producto?</td>
                                <td>
                                    <input defaultChecked={this.state.modificadorSeleccionado.isProducto} id="chkIsProd" type="checkbox" />
                                </td>
                            </tr>


                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickEditarMod() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>

                </center>

            </Popup>
        );
    }

    CerrarPopupModif() {
        this.setState({ activarAgregarMod: false });
    }

    GetArregloParam() {
        var res = [];
        res.push(this.state.productoClickeado);
        return res;
    }
    ClickSeleccionarTodos() {
        var chkPrincipal = document.getElementById('chkSeleccionarTodos');
        var prodsToDelete = document.getElementsByName('chkBorrarModif');
        if (chkPrincipal.checked) {
            prodsToDelete.forEach((item) => {
                item.checked = true;
            });
        } else {
            prodsToDelete.forEach((item) => {
                item.checked = false;
            });
        }

    }

    render() {
        return (
            <div className="row">
                <div style={this.Estilos.Columna1} className="column left">
                    {
                        (this.state.activarAgregarMod) ? (
                            <PopupModificadoresProducto
                                ActualizarCarroMaster={this.ActualizarCarroMaster}
                                CerrarModalMaster={this.CerrarPopupModif}
                                DesactivarModales={this.CerrarPopupModif}
                                productos={this.GetArregloParam()}
                                parametros={{}}
                                AgregarModificador={this.CrearModificador2}
                                AgregarModificadores={this.CrearModificadorMulti}
                            />
                        ): (null)
                    }
                    {
                        (this.state.activarAgregarCategoria) ? (this.GetHtmlPopupAgregarMod()) : (null)
                    }
                    
                    {
                        (this.state.activarEditarModPop) ? (this.GetHtmlPopupEditarMod()) : (null)
                    }
                    {
                        (this.state.cargandoCategorias) ? (
                            <div className="spinner-border text-dark" role="status">
                                <span className="sr-only"></span>
                            </div>
                        ) : (null)
                    }
                    {
                        this.state.categoriasBotones.map((item, i) => {
                            const prefijo = 'categorias';
                            return (
                                <button name="botonesCategorias"
                                    className="btn"
                                    id={prefijo + i}
                                    style={this.Estilos.BotoneraCategoria} onClick={() => { this.ClickCategoria(item.id, prefijo + i, item) }} key={prefijo + i}><strong> {item.desgrup}</strong> </button>
                            );
                        })
                    }

                </div>
                <div id="divCentral" style={this.Estilos.DivCentralTest}>
                    {
                        this.state.productosSeleccionados.map((item, i) => {
                            return <button className="btn " style={this.Estilos.BotoneraProductos}
                                onClick={() => { this.ClickProducto(item) }}
                                key={i}> {item.desprod} </button>
                        })

                    }


                </div>
                <div className="column right" style={this.Estilos.MarginLeftCR}>
                    {
                        (this.state.activarEditarMod) ? (
                            <center>
                                
                                <h3>{this.state.productoClickeado.desprod}</h3>
                                <table>
                                    {
                                        (this.state.modificadoresProducto.length > 0) ? (
                                            <thead>
                                                <tr>
                                                    <th>Desc</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                        ) : (null)
                                    }
                                    
                                    <tbody>
                                        {
                                            (this.state.modificadoresProducto.length===0) ? (
                                                <p>Este producto no tiene modificadores</p>
                                            ): (null)
                                        }
                                        <tr>
                                            <td></td>
                                            <td>
                                                {
                                                    (this.state.modificadoresProducto.length > 0) ? ( 
                                                        <React.Fragment>
                                                            <label style={{ color: 'black', 'fontSize': '15px' }}>Todos </label>
                                                            &nbsp;&nbsp;<input type="checkbox" id="chkSeleccionarTodos" onClick={() => { this.ClickSeleccionarTodos() }} />
                                                        </React.Fragment>
                                                    ) : (null)
                                                }
                                                
                                            </td>
                                        </tr>
                                        {
                                            this.state.modificadoresProducto.map((item, ind) => {//item=relprodMod
                                                var modificadoresHabilitados = item.tbModificadores.filter(p => p.habilita === 1);
                                                return modificadoresHabilitados.map((item2, i) => {//item2=tbmodificas
                                                    if (item2.habilita === 1)
                                                   return (
                                                       <React.Fragment>
                                                           {(item.isObligatorio && i === 0) ? (<tr style={{ color: 'red' }}>
                                                               <td>Obligatorio</td>
                                                               <td>
                                                                   
                                                                  
                                                               </td>
                                                           </tr>) : (null)}
                                                           {(!item.isObligatorio && i === 0) ? (<tr style={{ color: 'green' }}>
                                                               <td> Opcional</td>
                                                               <td></td>
                                                           </tr>) : (null)}
                                                           <tr>
                                                               <td>{item2.desmod}</td>
                                                                 <td>

                                                                   <button onClick={() => { this.ClickEliminarMod(item2.id) }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                                       <i class="fa fa-trash " aria-hidden="true"></i></button>&nbsp;&nbsp;
                                                                   {
                                                                       (item2.tbProductoID > 0 && !item2.isCombo) ? (//si es producto de venta
                                                                           <i class="fa fa-money" aria-hidden="true"></i>
                                                                       ): (null)
                                                                   }
                                                                   <input defaultValue={item2.id} type="checkbox" name="chkBorrarModif" />
                                                                 </td>
                                                           </tr>
                                                           {
                                                               (i === modificadoresHabilitados.length - 1) ? (//si es el elemento del nivel de modificadores
                                                                   <React.Fragment>
                                                                        <tr>
                                                                           <td colSpan="3">
                                                                               <button onClick={() => { this.setState({ activarAgregarMod: true, nivelSeleccionado: item.id, activarAgregarMod:true }) }}
                                                                                   type="button" className="btn btn-info">
                                                                                   <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                                                                   Agregar modificador</button>
                                                                           </td>
                                                                       </tr>
                                                                       <tr>
                                                                            <td colSpan="3"> <hr /></td>
                                                                       </tr>
                                                                   </React.Fragment>
                                                               ): (null)
                                                           }
                                                       </React.Fragment>
                                                    );
                                                })
                                            })
                                        }

                                    </tbody>
                                </table>
                                <button onClick={() => { this.setState({ activarAgregarMod: true, nivelSeleccionado:0 }) }}
                                    type="button" className="btn btn-info">
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                    Agregar nivel</button>
                                <div>¿Obligatorio?&nbsp;&nbsp;
                                    <p style={{ margin: 5 }}>Si &nbsp;<input type="radio" name="obligatorio" id="chkObligatorio"  /></p>
                                    <p style={{ margin: 5 }}>No &nbsp;<input type="radio" name="obligatorio" id="dummy" /></p>
                                    
                                    
                                </div>
                            </center>
                        ) : (null)
                    }




                </div>
            </div>
        );
    }


}
