import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import ReactToExcel from 'react-html-table-to-excel';

import swal from 'sweetalert';

export class Replicacion extends Component {
    static displayName = Replicacion.name;

    constructor() {
        super();
        this.state = {
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false,
            cargadatos:false,
            resultado: {},
            VerTabla:false
        }

    }
    Estilos = {
        FondoDefault: {
            'backgroundColor': '#ecf0f5',
            'heigth': '100%',
        },
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '80%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '40%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '0',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            'textAlign': 'left'
        },
        TdStyleValues: {
            'textAlign': 'center'
        }

    }
    async TraerTotalVentasPorFecha() {
        try {
            this.setState({cargadatos:true});
            var data = {};
            document.getElementById('fechaini').value=document.getElementById('fechaFiltro').value;
            document.getElementById('fechafin').value=document.getElementById('fechaFiltroHasta').value;
            data.fechaIni = document.getElementById('fechaFiltro').value;
            data.fechaFin = document.getElementById('fechaFiltroHasta').value;

            var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/ObtenerReporteVentas', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                throw { mensaje: 'problema al traer las ventas' };

            } else { 
            var datos = await respuesta.json();
            this.setState({resultado:datos,VerTabla:true,cargadatos:false})
            console.log(datos);
            }
        } catch (err) {
            swal('error en TraerTotalVentasPorFecha ' + err);
        }
    }
    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;
       
    }

    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }
    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <div>
                    <table style={this.Estilos.FullAncho} >
                        <thead>
                            <th colSpan="3">Replicación</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                            </tr>
                            <tr>
                                <td> <input className="form-control" type="text" /> </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde()}} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta()}} className="form-control" type="date" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



            <hr />
            <div className="row">
                <div className="col-lg-5">
                    <input type="text" id="fechaini"  hidden ></input>
                        <input type="text" id="fechafin" hidden></input>
                </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.TraerTotalVentasPorFecha() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br/>
                <div>
                    <center>
                    {
                        (this.state.cargadatos) ? (
                            <div style={this.Estilos.FondoDefault}>
                                <center>
                                    <div className="spinner-border text-dark" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                    <p> Cargando...</p>
                                </center>
                            </div>
                        ) : (null)
                    }
                </center></div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card" style={this.Estilos.AnchoBoxGraf}>
                            <div className="card-body">
                                <h5 className="card-title">Replicación.</h5>
                                <p className="card-text">Some quick example text to build on the panel title and make up the bulk of the panel's content.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card" style={this.Estilos.AnchoBoxGraf}>
                            <div className="card-body">
                                <h5 className="card-title">Ultima Actualización.</h5>
                                <p className="card-text">Some quick example text to build on the panel title and make up the bulk of the panel's content.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }

}