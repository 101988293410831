import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import { TecladoNumerico } from './TecladoNumerico';

export class PopupMotivoAnulacion extends Component {
    static displayName = PopupMotivoAnulacion.name;
    Orden = {};
    MotivoAnulacion = '';
    constructor(props) {
        super(props);
        this.state = {
            Motivos: [],
            mostrarTeclado: false
        }
        this.Orden = props.Orden;
        this.AnularOrden = this.AnularOrden.bind(this);
        this.TraerMotivos();
    }

    DesactivarModales() {
        this.setState({ mostrarTeclado: false });
    }

    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivoAnulacions', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ Motivos: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }
    

    Estilos = {
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        Popup: {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px'
        },
        btnDescuento: {
            'width': '33%'
        },
        TercioAncho: {
            'width': '33%'
        },
        FullAncho: {
            'width': '100%'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '100%'
        },
        AlturaListaProductos: {
            'height': '50%'
        },
        BtnGroup:
        {
            'border-color': '#d8e1de',
            'margin-left': '3%',
            'margin-bottom': '1%',
            'width': '45%',
            'font-size': '80%',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'height': '50px'
        },
        InputSize:
        {
            'width': '80%',

        },
        ButonGroupAproved:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'background': '#dc3545',
            'border-color': '#6e9887',
            'margin-left': '16%'
        },
        ButonGroupAproved2:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'border-color': '#6e9887',
            'margin-left': '16%'
        }

    }

    DesactivarSelecciones() {
        this.MotivoAnulacion = '';
        document.getElementsByName('btnMotivo').forEach((item) => {
            item.className = 'btn btn-dark';
            document.getElementById(item.id).style.background = 'black';
            document.getElementById(item.id).style.color = 'white';
        });
    }

    ClickSeleccionarMotivoBtn(texto, idElem) {
        this.DesactivarSelecciones();
        this.MotivoAnulacion = texto;
        document.getElementById(idElem).className ='btn btn-light';
        document.getElementById(idElem).style.background = 'white';
        document.getElementById(idElem).style.color = 'black';
    }

    ClickSeleccionarMotivo(texto, idElem) {

        if (this.MotivoAnulacion === '' || !this.MotivoAnulacion) {
            this.MotivoAnulacion = document.getElementById('motivoPesonalizado').value;

        }
        if (this.MotivoAnulacion === '' || !this.MotivoAnulacion) {
            swal('No a seleccionado un motivo de anulación');
        }
        else {
            swal({
                title: "Confirmar anulación",
                text: "¿Está seguro que desea anular la orden?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.setState({ mostrarTeclado: true });
                }
            });
        }
        
    }

    async AnularOrden() {
        var data = {};
        data.idOrdenAnular = this.Orden.id;
        data.descripcion = this.MotivoAnulacion;

        var respuesta = await fetch(GetUrlApi() + '/api/Orden/AnularOrden', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {

            this.props.ClickBorrarOrden();
            swal({
                title: "Orden Anulada",
                text: "Orden Anulada con exito",
                icon: "success",
                buttons: true,
                dangerMode: true,
            })
        }
        else {
            alert('error');
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

    ClicSeleccionar() {
        
    }

    render() {
        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.Popup}
                onClose={() => { this.props.DesactivarModales() }}
                closeOnDocumentClick>
                <center>
                    <h3>Motivo de Anulación de la Orden</h3>
                    <div className="row">
                    {
                        this.state.Motivos.map((item, index) => {
                            const id = 'btnMotivo' + index;
                            return (<button id={id} name="btnMotivo" className="btn " style={this.Estilos.BtnGroup} onClick={() => { this.ClickSeleccionarMotivoBtn(item.descripcion, id) }}>{item.descripcion}</button>);
                        })
                    }
                        {
                            (this.state.mostrarTeclado) ? (<TecladoNumerico IsAutorizacion={true} CrearCierre={this.AnularOrden} CerrarModalMaster={this.DesactivarModales.bind(this)} />) : (null)
                        }
                    </div>
                    <hr/>
                    <h3> Otra razón </h3>
                    <input id="motivoPesonalizado" style={this.Estilos.InputSize} onClick={() => { this.DesactivarSelecciones() }} className="form-control" type="text" />
                    <hr />
                    <button className="btn btn-danger" style={this.Estilos.ButonGroupAproved} onClick={() => { this.props.DesactivarModales() }}>
                        <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>

                        </button>
                    <button className="btn btn-success" style={this.Estilos.ButonGroupAproved2} onClick={() => { this.ClickSeleccionarMotivo() }} data-toggle="tooltip" data-placement="right" title="Listo">
                        <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i>
                    </button>
                </center>
            </Popup>
        );
    }
}
