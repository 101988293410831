import React, { Component } from 'react';
import './Estilos.css';
import Popup from "reactjs-popup";
import { TecladoNumerico } from './TecladoNumerico';

export class Misc extends Component {
    static displayName = Misc.name;

    constructor() {
        super();
        this.state = {
            propinaClicked: false,
            propinaPorc: false
        }
    }

    OpcionSeleccionada() {
        if (this.state.propinaClicked || this.state.propinaPorc) {
            return true;
        }
        return false;
    }

    ClickPropina() {
        this.setState({ propinaClicked: true });
    }
    
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        FullScreen : {
            'height ': '100%',
            'width': '100%'
        },
        CuartoDeAncho: {
            'width': '24%',
            'margin': '1px',
            'height ': '200px'
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

    GetHtmlBotones() {
        if (!this.OpcionSeleccionada()) {
            return (
                <div>
                    <button style={this.Estilos.CuartoDeAncho}>Cambiar el precio</button>
                    <button style={this.Estilos.CuartoDeAncho}>Asignar el asiento</button>
                    <button style={this.Estilos.CuartoDeAncho}>Anular orden</button>
                    <button style={this.Estilos.CuartoDeAncho}>No venta</button>

                    <button style={this.Estilos.CuartoDeAncho}>Cambio de mesero</button>
                    <button onClick={() => { this.ClickPropina() }} style={this.Estilos.CuartoDeAncho}>Propina</button>
                    <button style={this.Estilos.CuartoDeAncho}>Buscar el cliente</button>
                    <button style={this.Estilos.CuartoDeAncho}>Revisar la orden</button>
                   
                </div>
            );
        } else {
            (<div></div>)
        }
    }

    GetHtmlPropina() {
        return (
            <div>
                <button onClick={() => { this.setState({ propinaPorc: true, propinaClicked: false }) }}>Porcentaje</button>
                <button>Cantidad</button>
                <button>Diferencia</button>
                {
                    (this.state.propinaPorc) ? (<div>
                        <TecladoNumerico  ActualizarEstado={this.props.ActualizarEstado} CerrarModalMaster={this.props.CerrarModalMaster} />
                    </div>)
                        : (<div></div>)
                }
            </div>
            );
    }

    //--------ESTILOS

    render() {
        return (
            <Popup
                modal
                defaultOpen
                closeOnDocumentClick>
                <div>
                    {
                        (this.OpcionSeleccionada()) ? (<div>{this.GetHtmlPropina()}</div>) : (<div> {this.GetHtmlBotones()}</div>)
                    }
                   
                </div>
                <button style={this.Estilos.FullAncho} className="btn btn-primary" onClick={() => { this.props.CerrarModalMaster() }}>
                    Volver
                </button>
            </Popup>

        );
    }
}
