import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado , GetUserCookie} from './Globales/Utilidades';
//import { Pinpad } from './Pinpad';
import swal from 'sweetalert';
import ReactToExcel from 'react-html-table-to-excel';
import Popup from "reactjs-popup";
import { validateRUT } from 'validar-rut';
import $ from 'jquery';

export class NotaCredito extends Component {
    static displayName = NotaCredito.name;

    TextoIngresado = '';
    Devoluciones = [];
    ResultadoBusqueda = [];
    indicecolorCL = 0;
    constructor() {
        super();
        this.state = {
            boleta: {
                boletaB64: "",
                carroProductos:[],
                clientesModel: [],
                codigoCliente: "",
                codigoVendedor: "0",
                detalleBoleta:[],
                fecha: "",
                notaVenta: "",
                nroCpte: "",
                numeroOrden: "",
                numeroOrdenServicio: "",
                numeroReserva: "",
                activarPopUpConsultaDocumento: false,
                activarPopUpConsultaCliente: false,
                ConsultaClientes: [],
                TieneDatosCliente:false,
                CrearCliente:false,
                DataCliente: {
                    id: '', nombre: '', rut: '', telefono: '', email: '', fechaUltServicio: '', montoUltServicio: '0', fechaUltCompra: '', montoUltCompra: '0', estrellas: '',
                    ciudad: '', comuna: '', direccion: '', girocomercial: '',
                },
                TotalPrecioUnitario: 0,
                TotalSubTotal: 0
            },
            CheckListo: false,
            cargandoIsSelected: false,
            notaCredito: {
            NumeroComprobante :0,
            MotivosDevolucionID :0,
            MotivoLibre:'',
            ProductosDevolucion: [],
            ClienteNotasDeCredito:[]
            },
            tieneDatosBoleta: false,
            tieneDatosCliente: false,
            MotivosDevolucion: [],
            ConfirmoSeleccion: false
        }
        document.onkeypress = (e) => {
            if (e.key === 'Enter') {
               
            }
            else if (e) {
                //this.TextoIngresado += e.key;
                //document.getElementById('NumBoleta').value += e.key;
                
            }
        };
        this.TraerMotivos();
    }


    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('FechaDevolucion').value = today;
    }


    componentDidMount() {
            this.cargarFechas();
    }

    //ObtenerMotivos

    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivosDevolucions/ObtenerMotivos', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ MotivosDevolucion: datos });
        }
    }


    Estilos = {
        Columna1: {
            'width':'55%'
        },
        Columna2: {
            'width': '45%'
        },
        Contenedor: {
            'width': '100%',
            'background-color': 'white',
            //'padding': '1.9%',
            'marginLeft': '0.5%'
        },
        ThStyleSM: {
            'width': '2%',
            'paddingRight': '3%'
        },
        TdStyleSM: {
            'width': '2%',
            'textAlign': 'right',
            'paddingRight': '3%'
        },
        ThStyleM: {
            'width': '16%',
            'paddingRight': '0%'
        },
        TdStyleM1: {
            'width': '16%',
            'paddingLeft': '3%'
        },
        TdStyleM2: {
            'width': '16%',
            'paddingLeft': '5%'
        },
        ThStyleM1: {
            'width': '16%',
            'paddingLeft': '3%'
        },
        ThStyleM2: {
            'width': '16%',
            'paddingLeft': '5%'
        },
        ThStyleLG: {
            'width': '35%',
            'paddingRight': '0%'
        },
        ThStyleS: {
            'width': '2%',
            'paddingRight': '3%'
        },
        InputDcto: {
            'width': '60%'
        },LabelMargin: {
            'marginLeft': '5%'
        },
        SeparacionRow: {
            'paddingTop': '4%'
        },
        ButtonStyle1: {
            'height': '100%',
            'width': '38%',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '15%',
            'background': '#b4d1ea',
            'height': '43px'
        },
        ButtonStyle: {
            'height': '100%',
            'width': '45%',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '15%',
            'background': '#b4d1ea',
            'height': '43px'
        },
        ButtonStyleLimpiar: {
            'height': '100%',
            'width': '45%',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '15%',
            //'background': '#b4d1ea',
            'height': '43px',
            'background': 'rgb(253, 255, 252)'
        },
        ButtonStylePeq: {
            'height': '100%',
            'width': '45%',
            'borderRadius': '15%',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'background': 'rgb(222, 235, 249)',
            'fontSize': '18px'
        },
        ButtonStyleConfirmar: {
            'height': '100%',
            'width': '25%',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '15%',
            'background': 'rgb(253,255,252)',
            'height': '43px'
        },
        StyleTableData: {
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            //'margin-bottom': '10.5%',
            'border': '1px solid #a9a9a9',
            'borderRadius': '8px',
            //'font-size': '20px',
            //'margin-left': '-4.5%',
            'background': 'rgb(253 243 243)'
        },
        PopUpStyleConsultaStock:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '98%',
            'height': '80%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'border-radius': '8px'
        },
        DivIframe: {
            'overflow': 'auto',
            'width': '100%',
            'height': '100%'
        },
        IFrame: {
            'overflow': 'auto!important'
        },
        PopUpStyleOrdenService:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '97%',
            'height': '85%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        StyleDivOrdenService: {
            'overflow': 'auto',
            'width': '100%',
            'height': '100%'
        },
        MarginDivCliente: {
            'marginTop': '6%'
        },
        MarginCCliente: {
            'marginLeft': '4%',
            'marginRight': '-5%'
        },
        MarginDivClienteD: {
            'marginTop': '2%'
        },
        BtnConsultaCliente: {
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'border-radius': '5px',
            'margin-left': '5px',
            'background-color': '#8abbde',
            'height': '55px',
            'color': 'white'
        },
        BtnConsultaClienteD: {
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'border-radius': '5px',
            'margin-left': '-100px',
            'background-color': 'rgb(162 183 159)',
            'height': '55px',
            'color': 'white',
            //'margin-left': '-128%'
        },
        ColorTrOSPrimary: {
            background: '#e8eef3'
        },
        ColorTrOSSecond: {
            background: '#e8f3f2'

        },
        ThStyle0:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)',
            'width': '10%'
        },
        ThStyle1:
        {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': 'rgb(222, 222, 222)',
            'width': '15%'
        },
        ThStyle2:
        {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': 'rgb(222, 222, 222)',
            'width': '20%'
        },
        ThStyle3:
        {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': 'rgb(222, 222, 222)',
            'width': '30%'
        },
        PopUpStyleAddCliente: {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'height': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        SeparacionAddCliente: {
            'margin-top': '5%',
        },
        BotonCerrarOSListDs: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            //'background-color': '#8abbde',
            'width': '40%',
            'height': '60px'
        },
        DivContentDataCliente: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'width': '74%',
            'height': '300px',
            //'marginTop': '5.5%',
            'marginbottom': '1.5%',
            'border': '1px solid rgb(169, 169, 169)',
            'borderRadius': '8px',
            'fontSize': '20px',
            'margin-left': '4.5%',
            'background': 'rgb(253, 243, 243)'
        }
    }


    LimpiarVentana()
    {
        //document.getElementById('BotonEnviarNota').disabled = false;

        this.setState(
            {
                boleta: {
                    boletaB64: "",
                    carroProductos: [],
                    clientesModel: [],
                    codigoCliente: "",
                    codigoVendedor: "0",
                    detalleBoleta: [],
                    fecha: "",
                    notaVenta: "",
                    nroCpte: "",
                    numeroOrden: "",
                    numeroOrdenServicio: "",
                    numeroReserva: "",
                    activarPopUpConsultaDocumento: false,
                    activarPopUpConsultaCliente: false,
                    ConsultaClientes: [],
                    TieneDatosCliente: false,
                    CrearCliente: false,
                    DataCliente: {
                        id: '', nombre: '', rut: '', telefono: '', email: '', fechaUltServicio: '', montoUltServicio: '0', fechaUltCompra: '', montoUltCompra: '0', estrellas: '',
                        ciudad: '', comuna: '', direccion: '', girocomercial: '',
                    },
                },
                CheckListo:false,
                cargandoIsSelected:false,
                notaCredito: {
                    NumeroComprobante: 0,
                    MotivosDevolucionID: 0,
                    MotivoLibre: '',
                    ProductosDevolucion: [],
                    ClienteNotasDeCredito: []
                },
                tieneDatosBoleta: false,
                tieneDatosCliente: false,
                ConfirmoSeleccion: false
            }
        )
    }

    async BuscarBoleta() {
        var val = document.getElementById('NumBoleta').value;
        var check = document.getElementById('EsFactura');
        var tipoDoc = 0;
        var resRadio=document.querySelector('input[name="rate"]:checked').value;
        if (resRadio=='Factura') {
            tipoDoc = 33;
        } else if(resRadio=='Boleta') {
            tipoDoc = 39;
        } else {
            tipoDoc = 39;

        }
        console.log(check);
        console.log(val);
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/ObtenerBoleta?NumDoc=' + val+'&TipoDoc='+tipoDoc, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        //    .then(res => res.json()).then(
        //    (result) => {
              
        //        console.log(result);
        //    }
        //);
        console.log(respuesta);
        if (respuesta.ok) {
            var data = await respuesta.json();
            if (data.nroCpte!=null) {
                this.setState({ boleta: data, tieneDatosBoleta: true });
                console.log(data);
            } else {
                swal("Documento no encontrado");
                console.log(data);

            }
           
        } else {

            console.log(respuesta);
        }
    }
    async BuscarCliente() {

        let RutCliente = document.getElementById('RutCliente').value;
        let validaRut = RutCliente != '' ? validateRUT(RutCliente) : true;

        if (validaRut) {


            let NombreCliente = document.getElementById('NombreCliente').value;

            var rutAux = RutCliente;
            // Despejar Puntos
            var valor = rutAux.replaceAll('.', '');
            // Despejar Guión
            valor = valor.replaceAll('-', '');
            // Aislar Cuerpo y Dígito Verificador
            const cuerpo = valor.slice(0, -1);
            let dv = valor.slice(-1).toUpperCase();
            // Formatear RUN
            rutAux = cuerpo + '-' + dv

            var myBody = {};
            myBody.rut = rutAux;
            myBody.nombre = NombreCliente;
            console.log(myBody);
            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + rutAux + '&NombreCliente=' + NombreCliente, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    this.setState({ ConsultaClientes: result, TieneDatosCliente:true });
                }
            );
            //let datos = respuesta.json();
            console.log(this.state.ConsultaClientes);

        } else {
            $("#msgerror").html("El Rut no es válido.");
        }


    }
    QuitarLineas(idTr) {
        var checkboxes = document.getElementById("TablaDeDatos");

        var cont = 0;


        alert(cont);

    }
    AgregarADevolucion(IdTr) {


        var indiceTr =this.Devoluciones.indexOf(IdTr); 
        console.log(indiceTr);
        if (indiceTr !== -1) {
            if (indiceTr !== -1) {
                this.Devoluciones.splice(indiceTr, 1);
            }
        } else {
            this.Devoluciones.push(IdTr);
        }

        console.log(this.Devoluciones);
    }

    ConfirmarSeleccion() {
        console.log(this.Devoluciones);
        console.log(this.ResultadoBusqueda);
        var array = this.Devoluciones;
        var arrCarro = this.state.boleta.carroProductos;
        this.ResultadoBusqueda.map(function (item) {

            console.log(item);
            var indiceTr = array.indexOf(item); 
            console.log(indiceTr);
            if (indiceTr == -1) {
                var trID = item.replace('Tr', '');
                console.log("Quitar " + trID);
                var found = arrCarro.find(element => element.plu == trID);
                console.log(found);



                var indiceTr = arrCarro.indexOf(found);
                console.log(indiceTr);
                if (indiceTr !== -1) {
                    if (indiceTr !== -1) {
                        arrCarro.splice(indiceTr, 1);
                    }
                } 
                console.log(arrCarro);
            }

        });
        this.state.boleta.carroProductos = arrCarro;
        var totalPrecioUnitario = 0;
        var totalSubtotal = 0;
        arrCarro.forEach(function (element) {
            totalPrecioUnitario += element.precpred;
            totalSubtotal += element.cantidad * element.precpred;
        });
        this.state.boleta.totalPrecioUnitario = totalPrecioUnitario;
        this.state.boleta.totalSubTotal = totalSubtotal;

        this.setState({ boleta: this.state.boleta, ConfirmoSeleccion: true, CheckListo: true})
    }

    async EnviarSeleccion()
    {
        this.setState({ cargandoIsSelected: true });
        var tipoDoc = 39;
        var resRadio = document.querySelector('input[name="rate"]:checked').value;
        if (resRadio == 'Factura') {
            tipoDoc = 33;
        } else if (resRadio == 'Boleta') {
            tipoDoc = 39;
        } else {
            tipoDoc = 39;
        }
        var DataObj= this.state.notaCredito;

        if (!this.state.tieneDatosBoleta) {
            swal('No puedes ingresar Nota de Crédito sin Boleta');
            this.setState({ cargandoIsSelected: false });
            return;
        }
        if (!this.state.tieneDatosCliente) {
            swal('No puedes ingresar Nota de Crédito sin Cliente');
            this.setState({ cargandoIsSelected: false });

            return;
        }
        var fechaDevolucion = document.getElementById("FechaDevolucion").value;

        if (fechaDevolucion==="") {
            swal('No puedes ingresar Nota de Crédito sin Fecha');
            this.setState({ cargandoIsSelected: false });

            return;
        }
        var idMotivo = document.getElementById("MotivoDevolucion").value;
        if (idMotivo === "0") {
            swal('No puedes ingresar Nota de Crédito sin Motivo de Devolución');
            this.setState({ cargandoIsSelected: false });

            return;
        }
        var MotivoLibre = document.getElementById("MotivoLibre").value;
       
        console.log(this.state.boleta);
        console.log(this.state.tieneDatosCliente);
        console.log(fechaDevolucion);
        console.log(idMotivo);

        var cliente = {};


        cliente.Rut = this.state.DataCliente.rut;
        cliente.Nombre = this.state.DataCliente.nombre;
        cliente.Telefono = this.state.DataCliente.telefono;
        cliente.Email = this.state.DataCliente.email;
        cliente.NotasDeCreditoID =0;

        DataObj.NumeroComprobante = this.state.boleta.nroCpte;
        DataObj.MotivosDevolucionID = idMotivo;
        DataObj.FechaDevolucion = fechaDevolucion;
        DataObj.ProductosDevolucion = this.state.boleta.carroProductos;
        DataObj.ClienteNotasDeCredito = cliente;
        DataObj.MotivoLibre = MotivoLibre;
        DataObj.tipoDoc = tipoDoc;
        let jsons = JSON.stringify(DataObj);
        let mensajes = "";
        let ResultadoMsje =false;
        //data.numCaja = UserProfile.getUser().;
        var respuesta = await fetch(GetUrlApi() + '/api/NotasDeCredito/ProcesarNotaDeCredito', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(DataObj),
        }).then(res => res.json()).then(
            (result) => {
                console.log("Resultado Nota Crédito");
                console.log(result);
                ResultadoMsje = result;
            }
        );
        //console.log(respuesta.json());
        if (ResultadoMsje.resultado) {

            this.LimpiarVentana();
            window.open(ResultadoMsje.urlRespuesta, '_blank');
            //alert("Retorno True");
        } else {
            //alert("Retorno False");
            swal("Hubo problemas al generar la Nota de Crédito");
        }

        //console.log(DataObj);

    }

    ClickVerBoleta() {
        this.setState({ activarPopUpConsultaDocumento: true });
    }
    ClickConsultaCliente() {
        this.setState({ activarPopUpConsultaCliente: true });
    }
    ClickAddCliente() {
        this.setState({ CrearCliente: true });
    }
    async AgregarCliente() {

        let nombreCl = document.getElementById('nombreAddCliente').value;
        let apellidoCl = document.getElementById('apellidoAddCliente').value;
        let rutCl = document.getElementById('rutAddCliente').value;
        let telefonoCl = document.getElementById('telefonoAddCliente').value;
        let emailCl = document.getElementById('emailAddCliente').value;

        if (rutCl != '' && nombreCl != '') {

            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/AgregarCliente?Nombre=' + nombreCl + '&Apellido=' + apellidoCl + '&Rut=' + rutCl + '&Telefono=' + telefonoCl + '&Email=' + emailCl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // .then(res => res.json()).then(
            //    (result) => {
            //        if (result) {
            //            this.BuscarClienteRut(rutCl);
            //        }
            //    }
            //);
            if (respuesta.ok) {
                this.BuscarClienteRut(rutCl);
                this.DesactivarModales();
            }
            console.log(respuesta);
        }
    }

    async BuscarClienteRut(rut) {
        //let RutCliente = document.getElementById('RutCliente').value;
        //let NombreCliente = document.getElementById('NombreCliente').value;

        var myBody = {};
        //myBody.rut = RutCliente;
        //myBody.nombre = NombreCliente;
        console.log(myBody);
        let empresa = GetUserCookie().EmpresaID;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientesRut?RutCliente=' + rut + '&EmpresaID=' + empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                console.log(result);
                if (result.length > 0) {
                    this.DesplegarDatosCliente(result[0].id);
                    return;
                }
            }
        );
        //let datos = respuesta.json();
    }

    async DesplegarDatosCliente(idCliente) {
        //let RutCliente = document.getElementById('RutCliente').value;
        //let NombreCliente = document.getElementById('NombreCliente').value;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetDataClientes?IdCliente=' + idCliente, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                console.log(result);
                this.setState({
                    DataCliente: {
                        id: result.id,
                        nombre: result.nombre,
                        rut: result.rut,
                        telefono: result.telefono,
                        email: result.email,
                        fechaUltServicio: result.fechaUltServicio,
                        montoUltServicio: result.montoUltServicio,
                        fechaUltCompra: result.fechaUltCompra,
                        montoUltCompra: result.montoUltCompra,
                        estrellas: result.estrellas,
                        ciudad: result.ciudad,
                        comuna: result.comuna,
                        direccion: result.direccion,
                        girocomercial: result.giroComercial,
                    },
                    tieneDatosCliente: true
                });
            });
        //let datos = respuesta.json();
        console.log(this.state.DataCliente);
        //this.setState({ IsClienteObligatorio: false });
        this.DesactivarModales();
    }

    DesactivarModales() {
        this.setState({
            activarPopUpConsultaDocumento: false,
            activarPopUpConsultaCliente: false,
            CrearCliente: false
        });
    }
    DesactivarAddCliente() {
        this.setState({
            CrearCliente: false
        });
    }
    formateaRutDos() {
        var rut = document.getElementById('rutAddCliente').value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            document.getElementById('rutAddCliente').value = rutSinPuntosD;
            return rutPuntos;
        }
    }
    GetHtmlPopupVerBoletas() {

        //let url = "https://todobikes.blinamic.cl/ReportData/ReporteDocumentosElectronicos";
        //let url = "https://todobikes.blinamic.cl/ReportData/ReporteDocumentosElectronicos";
        let url = "https://todobikes.blinamic.cl/ReportData/ReporteDocumentosElectronicos";

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleConsultaStock}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.DivIframe}>
                    <iframe id="Example2"
                        style={this.Estilos.IFrame}
                        name="Example2"
                        title="Example2"
                        width="100%"
                        height="800px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src={url}>
                    </iframe>
                </div>

            </Popup>
        );
    }

    GetHtmlPopupConsultaCliente() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.StyleDivOrdenService}>
                    <div className="row">
                        <p class="text-danger" id="msgerror"></p>
                        <div className="col-sm-4">
                            <div className="row" style={this.Estilos.MarginDivCliente}>
                                <div className="col-sm-3" style={this.Estilos.MarginCCliente}><strong>Rut:</strong> </div>
                                <div className="col-sm-6">
                                    <input type="text"

                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                this.BuscarCliente();
                                            } else {
                                                this.KeyUpRutValidator();
                                            }
                                        }
                                        }
                                        onKeyPress={(e) => {
                                            this.onlyNumbersWithK(e);
                                        }
                                        }
                                        onChange={() => { this.LimpiarMensajeValidarRut() }}

                                        id="RutCliente" placeholder="RUT ej: 11111111-1" maxLength="13" minLength="9" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="row" style={this.Estilos.MarginDivCliente}>
                                <div className="col-sm-3" style={this.Estilos.MarginCCliente}><strong>Nombre: </strong> </div>
                                <div className="col-sm-6"><input type="text" id="NombreCliente" placeholder="Nombre" /></div>
                            </div>
                        </div>
                        <div className="col-sm-4" >
                            <div className="row" style={this.Estilos.MarginDivClienteD}>

                                <div className="col-sm-8"> <button style={this.Estilos.BtnConsultaCliente} onClick={() => { this.BuscarCliente() }}> Consultar Cliente</button></div>
                                <div className="col-sm-4"> <button style={this.Estilos.BtnConsultaClienteD} onClick={() => { this.ClickAddCliente() }}> <i className="fa fa-plus fa-2x"></i></button></div>
                            </div>

                        </div>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={this.Estilos.ThStyle0}>Id cliente</th>
                                <th style={this.Estilos.ThStyle1}>Rut</th>
                                <th style={this.Estilos.ThStyle2}>Nombre</th>
                                <th style={this.Estilos.ThStyle1}>Email</th>
                                <th style={this.Estilos.ThStyle3}>Telefono</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (this.state.TieneDatosCliente) ?
                                    (
                                        this.state.ConsultaClientes.map((item, index) => {
                                            if (this.indicecolorCL === index) {
                                                this.indicecolorCL = index;
                                                return (<tr style={this.Estilos.ColorTrOSPrimary} onClick={() => { this.DesplegarDatosCliente(item.id) }}>
                                                    <td>{item.id}</td>
                                                    <td>{item.rut}</td>
                                                    <td>{item.nombre}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.telefono}</td>
                                                </tr>);
                                            } else if (this.indicecolorCL !== index) {
                                                this.indicecolorCL = index + 1;
                                                return (<tr style={this.Estilos.ColorTrOSSecond} onClick={() => { this.DesplegarDatosCliente(item.id) }}>
                                                    <td>{item.id}</td>
                                                    <td>{item.rut}</td>
                                                    <td>{item.nombre}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.telefono}</td>
                                                </tr>);
                                            }

                                        })

                                    ): (null)

                            }
                        </tbody>
                    </table>

                </div>

            </Popup>
        );
    }

    KeyUpRutValidator() {
        let cadena = $("#RutCliente").val();
        cadena = cadena.replace(/[.]/gi, "").replace("-", "");
        if (cadena.length > 9) {
            cadena = cadena.substr(0, 9);
        }
        let concatenar = "";
        let i = cadena.length - 1;
        for (; i >= 0;) {
            concatenar = cadena[i] + concatenar;
            if (i + 1 == (cadena.length) && i > 0) {
                concatenar = "-" + concatenar;
            }
            if (concatenar.length == 9 && cadena.length > 7) {
                concatenar = "." + concatenar;
            }
            if (concatenar.length == 5 && cadena.length > 4) {
                concatenar = "." + concatenar;
            }
            i--;
        }
        $("#RutCliente").val(concatenar);
    }

    onlyNumbersWithK(e) {
        if (e.which == 75 || e.which == 107 || e.which == 48 || e.which == 49 || e.which == 50 || e.which == 51 || e.which == 52 || e.which == 53
            || e.which == 54 || e.which == 55 || e.which == 56 || e.which == 57) {
            return;
        } else {
            e.preventDefault();
        }
    }

    LimpiarMensajeValidarRut() {
        $("#msgerror").html("");
    }

    GetHtmlPopupAddCliente() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                defaultOpen
                modal
                onClose={() => { this.DesactivarAddCliente() }}
                closeOnDocumentClick>
                <div className="container">
                    <div className="row" style={this.Estilos.SeparacionAddCliente}>
                        <div className="col-md-6">
                            <span>RUT (12345678-9):  </span>
                            <input type="text" placeholder="Rut" onChange={() => { this.formateaRutDos() }} id="rutAddCliente" />
                        </div>
                        <div className="col-md-6">
                            <span>Telefono:  </span>
                            <input type="text" placeholder="Telefono" id="telefonoAddCliente" />
                        </div>
                    </div>
                    <div className="row" style={this.Estilos.SeparacionAddCliente}>
                        <div className="col-md-6">
                            <span>Nombre:  </span>
                            <input type="text" placeholder="Nombre" id="nombreAddCliente" />
                        </div>
                        <div className="col-md-6">
                            <span>Apellidos:  </span>
                            <input type="text" placeholder="Apellidos" id="apellidoAddCliente" />
                        </div>
                    </div>

                    <div className="row" style={this.Estilos.SeparacionAddCliente}>
                        <div className="col-md-6">
                            <span>Email:  </span>
                            <input type="text" placeholder="Email" id="emailAddCliente" />
                        </div>
                    </div>
                    <div className="row" style={this.Estilos.SeparacionAddCliente}>
                        <div className="col-7">
                            <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarAddCliente() }}><strong>Cerrar</strong></button>
                        </div>
                        <div className="col-5">
                            <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success" onClick={() => { this.AgregarCliente() }}><strong>Agregar</strong></button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
    render() {
        return (
            <div style={this.Estilos.Contenedor} >
                <center>
                    <p> <h2><b>Nota Crédito</b></h2></p>
                </center>
                {(this.state.activarPopUpConsultaDocumento) ? (
                    this.GetHtmlPopupVerBoletas()

                ) : (null)}

                {(this.state.activarPopUpConsultaCliente) ? (
                    this.GetHtmlPopupConsultaCliente()

                ) : (null)}
                {(this.state.CrearCliente) ? (
                    this.GetHtmlPopupAddCliente()
                ) : (null)}


                
                <div className="row">


                    <div className="col-sm-7" style={this.Estilos.StyleTableData}>
                        <table border="1" id="TablaDeDatos">
                            <thead>
                                <th style={this.Estilos.ThStyleS}></th>
                                <th style={this.Estilos.ThStyleSM}>Cantidad</th>
                                <th style={this.Estilos.ThStyleM} >Codigo</th>
                                <th style={this.Estilos.ThStyleLG} >Detalle</th>
                                <th style={this.Estilos.ThStyleM} >Precio Unitario</th>
                                <th style={this.Estilos.ThStyleM1}>Descuento</th>
                                <th style={this.Estilos.ThStyleM2}>Subtotal</th>
                            </thead>
                            <tbody>
                                {
                                    (this.state.tieneDatosBoleta) ? (
                                        this.state.boleta.carroProductos.map((item, index) => {
                                            var subTotal = item.cantidad * item.precpred;
                                            var ProdDescripcion = item.desprod.split(';');
                                            var IdTr = "Tr" + ProdDescripcion[0];
                                            this.ResultadoBusqueda.push(IdTr);
                                            return (
                                                <tr id={IdTr}>
                                                    {
                                                        (this.state.CheckListo) ?
                                                            (<td></td>)
                                                             :
                                                     (<td><input type = "checkbox" onClick = { ()=> this.AgregarADevolucion(IdTr)}></input> </td>)
                                                    }
                                                    <td style={this.Estilos.TdStyleSM} className="">{item.cantidad}</td>
                                                    <td>{ProdDescripcion[0]}</td>
                                                    <td>{ProdDescripcion[2]}</td>
                                                    <td className="aling-right text-right">{GetPrecioFormateado(item.precpred)}</td>
                                                    <td style={this.Estilos.TdStyleM1} className="aling-right text-right">0</td>
                                                    <td style={this.Estilos.TdStyleM2} className="aling-right text-right">{GetPrecioFormateado(subTotal)}</td>
                                                    <td hidden="hidden">
                                                        <center>
                                                            <a onClick={() => { this.QuitarLineas(IdTr) }} href="javascript:void(0)">
                                                                <i className="fa fa-minus-circle fa-2x text-danger" aria-hidden="true"></i>
                                                            </a>
                                                        </center>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    ): (null)
                                    
                                }
                                {
                                    (this.state.tieneDatosBoleta) ?
                                        (
                                            <tr>

                                                <td className="aling-right"><b>Totales</b></td>
                                                <td className="aling-right"></td>
                                                <td className="aling-right "></td>
                                                <td className="aling-right"></td>
                                                <td className="aling-right text-right">{GetPrecioFormateado(this.state.boleta.totalPrecioUnitario)}</td>
                                                <td className="aling-right"></td>
                                                <td className="aling-right text-right">{GetPrecioFormateado(this.state.boleta.totalSubTotal)}</td>
                                            </tr>

                                        )
                                            :
                                        (null)

                                }


                                <tr>

                                    <td colSpan="7" className="aling-right">
                                        <center>
                                            {
                                                (this.state.cargandoIsSelected) ? (
                                                    <center>
                                                                <div >
                                                                    <center>
                                                                        <div className="spinner-border text-dark" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div>
                                                                        <p> Cargando...</p>
                                                                    </center>
                                                                </div>
                                                    </center>
                                                ):

                                                (this.state.ConfirmoSeleccion) ?
                                                (
                                                    <button id="BotonEnviarNota" style={this.Estilos.ButtonStyleConfirmar} onClick={() => { this.EnviarSeleccion() }} >Enviar Nota de Crédito</button>

                                                ) : (
                                        <button id="BotonConfirmarSeleccion" style={this.Estilos.ButtonStyleConfirmar} onClick={() => { this.ConfirmarSeleccion() }} >Confirmar</button>
                                                    )}
                                        </center>                                    
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>

                       
                    </div>

                    <div className="col-sm-5">
                        <div className="row">
                            {(this.state.tieneDatosCliente) ?
                                (
                                    <div style={this.Estilos.DivContentDataCliente}>

                                        <div className="row">
                                            <div className="col-6"><strong>Nombre:</strong> {this.state.DataCliente.nombre}</div>
                                            <div className="col-6"><strong>Rut:</strong> {this.state.DataCliente.rut}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6"><strong>Telefono:</strong> {this.state.DataCliente.telefono}</div>
                                            <div className="col-6"><strong>Email:</strong> {this.state.DataCliente.email}</div>

                                        </div>
                                        <div className="row">
                                            <div className="col-6"><strong>Fecha Ultimo Servicio:</strong> {this.state.DataCliente.fechaUltServicio}</div>
                                            <div className="col-6"><strong>Monto Ultimo Servicio:</strong> {GetPrecioFormateado(this.state.DataCliente.montoUltServicio)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6"><strong>Fecha Ultima Compra:</strong> {this.state.DataCliente.fechaUltCompra}</div>
                                            <div className="col-6"><strong>Monto Ultima Compra: </strong>{GetPrecioFormateado(this.state.DataCliente.montoUltCompra)}</div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6"><strong>Estrellas:</strong> {this.state.DataCliente.estrellas}</div>

                                        </div>


                                    </div>
                                ) :
                                (
                                    <div style={this.Estilos.DivContentDataCliente}>

                                        <div className="row">
                                            <div className="col-6"><strong>Nombre:</strong> </div>
                                            <div className="col-6"><strong>Rut:</strong></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6"><strong>Telefono:</strong></div>
                                            <div className="col-6"><strong>Email:</strong> </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-6"><strong>Fecha Ultimo Servicio:</strong> </div>
                                            <div className="col-6"><strong>Monto Ultimo Servicio:</strong> </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6"><strong>Fecha Ultima Compra:</strong> </div>
                                            <div className="col-6"><strong>Monto Ultima Compra: </strong></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6"><strong>Estrellas:</strong> </div>

                                        </div>


                                    </div>

                                )}



                        </div>

                        <div className="row">
                            <div className="col-sm-10">
                                <input id="NumBoleta" type="text" placeholder="N° de Documento" style={this.Estilos.InputDcto}></input>
                                <button id="BotonBuscarBoleta" style={this.Estilos.ButtonStyle1} onClick={() => { this.BuscarBoleta() }} >Buscar Documento</button>
                            </div>
                            
                        </div>
                       
                        <div className="row">
                            <div className="col-sm-4" id="radiosOpt">
                                <label style={this.Estilos.LabelMargin}>¿Seleccione Tipo Documento?</label>
                                <br/>
                                <input style={this.Estilos.LabelMargin} type="radio" id="r1" name="rate" value="Boleta"/><label style={this.Estilos.LabelMargin}>Boleta </label>
                                <br/>
                                <input style={this.Estilos.LabelMargin} type="radio" id="r2" name="rate" value="Factura" /><label style={this.Estilos.LabelMargin}>Factura</label>
                            </div>
                        </div>

                      
                        <div className="row" style={this.Estilos.SeparacionRow} >
                            <div className="col-sm-6">
                                <label>Fecha Devolución</label>

                                <input type="date" id="FechaDevolucion" placeholder="Fecha Devolución" style={this.Estilos.InputDcto}></input>
                               
                            </div>
                            <div className="col-sm-6">
                                <button style={this.Estilos.ButtonStyle} onClick={() => { this.ClickConsultaCliente() }} >
                                    Buscar Cliente</button>
                            </div>

                        </div>
                        <div className="row" style={this.Estilos.SeparacionRow} >
                            <div className="col-sm-6">
                                <label>Motivo Devolución</label>
                                <select id="MotivoDevolucion" className="form-control">
                                    <option value="0">--Seleccionar--</option>
                                    {
                                        this.state.MotivosDevolucion.map((item, index) => {
                                            return (<option value={item.id}>{item.motivo}</option>);
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-6">
                                <p><label>Observación</label><br/>
                                    <textarea placeholder="Motivo Libre" id="MotivoLibre" /></p>
                            </div>
                        </div>
                        <div className="row" style={this.Estilos.SeparacionRow} >
                            <div className="col-sm-6">
                                <button id="BotonVerBoleta" style={this.Estilos.ButtonStylePeq} onClick={() => { this.ClickVerBoleta() }} >
                                    Ver Boletas</button>
                            </div>
                            <div className="col-sm-6">
                                <button style={this.Estilos.ButtonStyleLimpiar} onClick={() => { this.LimpiarVentana() }} >
                                    Limpiar</button>
                            </div>
                        </div>

                    </div>


                </div>



            </div>



        );
    }



}