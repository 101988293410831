import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';
import Popup from "reactjs-popup";

export class MesasAmbientes extends Component {
    static displayName = MesasAmbientes.name;
    urlParams;
    IsSelectMesa = false;
    OrdenMoverID = 0;
    MesaMoverID = 0;
    FiltroAmbiente = 0;
    FiltroAmbienteString = "";
    stateInicial = {
        Mesas: [],
        Ambientes: [],
        IsSeleccionar: false,
        sucursales: [],
        //inicio estadosCrud
        activarPopupCrearAmbiente: false,
        activarPopupEditarMesa: false,
        activarPopupEditarAmbiente: false,
        ambienteSeleccionado: {
            id:0
        },
        activarPopupCrearMesa: false,
        mesaSeleccionada: {},
        //fin estadosCrud
    }

    MesasIniciales = [];
    constructor(props) {
        super(props);
        this.state = this.stateInicial;
        this.TraerAmbientes();
        this.urlParams = new URLSearchParams(this.props.location.search);
        this.TraerSucursales();
        //this.TraerAmbientes();
    }

    componentDidMount() {
        
        this.TraerMesas();

    }

    //------>INICIO FUNCIONES CLICK
    ClickNewAmbiente() {
        this.setState({ activarPopupCrearAmbiente: true });
    }
    //------>FIN FUNCIONES CLICK


    //------>INICIO FUNCIONES CRUD
    async CrearAmbiente() {
        var ambiente = {};
        ambiente.Descipcion = document.getElementById('txtDesc').value;
        ambiente.SucursalID = document.getElementById('cboSucursal').value;
        ambiente.IsHabilitado = true;
        ambiente.UsuModifica = GetUserCookie().username;
        if (ambiente.descripcion === '' || ambiente.porcDescuento === 0) {
            swal('No puede ingresar campos en blanco');
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/Ambientes', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ambiente),
        });
        if (respuesta.ok) {
            swal('Ambiente creado con exito').then(() => {
                this.Recargar();
            });
        }
    }

    async CrearMesa() {
        var ambiente = {};
        ambiente.Descipcion = document.getElementById('txtDesc').value;
        ambiente.SucursalID = GetUserCookie().SucursalID;
        ambiente.Capacidad = document.getElementById('txtCapacidad').value;
        ambiente.IsHabilitado = true;
        ambiente.AmbienteID = this.state.ambienteSeleccionado.id;
        ambiente.UsuModifica = GetUserCookie().username;
        if (ambiente.Descipcion === '' || ambiente.Capacidad === 0) {
            swal('No puede ingresar campos en blanco');
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ambiente),
        });
        if (respuesta.ok) {
            swal('Ambiente creado con exito').then(() => {
                this.Recargar();
            });
        }
    }

    async ClickListoEditar() {
        var ambiente = this.state.ambienteSeleccionado;
        ambiente.Descipcion = document.getElementById('txtDesc').value;
        ambiente.SucursalID = document.getElementById('cboSucursal').value;
        ambiente.IsHabilitado = true;
        ambiente.UsuModifica = GetUserCookie().username;
        if (ambiente.descripcion === '' || ambiente.porcDescuento === 0) {
            swal('No puede ingresar campos en blanco');
            return;
        }
        if (ambiente.Nombre === '' || ambiente.Apellido === '') {
            swal('No puede ingresar campos en blanco');
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/Ambientes/' + ambiente.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ambiente),
        });
        if (respuesta.ok) {
            swal('Ambiente editado con exito').then(() => {
                this.Recargar();
            });
        }
    }


    async ClickListoEditarMesa() {
        var mesa = this.state.mesaSeleccionada;
        mesa.Descipcion = document.getElementById('txtDescMesa').value;
        mesa.SucursalID = GetUserCookie().SucursalID;
        mesa.Capacidad = document.getElementById('txtCapacidad').value;
        mesa.IsHabilitado = true;
        mesa.AmbienteID = this.state.ambienteSeleccionado.id;
        mesa.UsuModifica = GetUserCookie().username;
        if (mesa.Nombre === '' || mesa.Apellido === '') {
            swal('No puede ingresar campos en blanco');
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas/' + mesa.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(mesa),
        });
        if (respuesta.ok) {
            swal('Ambiente editado con exito').then(() => {
                this.Recargar();
            });
        }
    }

    Recargar() {
        this.setState(this.stateInicial);
        this.TraerAmbientes().then(() => {
            this.TraerMesas();
        });
        
    }


    async ClickInhabilitarAmbiente() {
        const id = this.state.ambienteSeleccionado.id;
        swal({
            title: "¿Está seguro que desea dehabilitar este ambiente?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Ambiente deshabilitado", {
                    icon: "success",
                }).then(async () => {
                    var respuesta = await fetch(GetUrlApi() + '/api/Ambientes/' + id, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        swal('Ambiente deshabilitado con exito');
                        this.Recargar();
                    }
                    else {

                    }

                });
            } else {

            }
        });
    }

    async ClickInhabilitarMesas() {
        const id = this.state.mesaSeleccionada.id;
        swal({
            title: "¿Está seguro que desea dehabilitar esta mesa?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Mesa deshabilitada", {
                    icon: "success",
                }).then(async () => {
                    var respuesta = await fetch(GetUrlApi() + '/api/Mesas/' + id, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        swal('Mesa deshabilitada con exito');
                        this.Recargar();
                    }
                    else {

                    }

                });
            } else {

            }
        });
    }

    //------>FIN FUNCIONES CRUD
    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Sucursals', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ sucursales: datos });
        }
    }

    //async TraerAmbientes() {
    //    var respuesta = await fetch(GetUrlApi() + '/api/Ambientes', {
    //        method: 'get',
    //        headers: {
    //            'Content-Type': 'application/json',
    //        }
    //    });
    //    if (respuesta.ok) {
    //        var datos = await respuesta.json();
    //        const primerAmbiente = datos[0];
    //        this.setState({ ambientes: datos });
    //    }
    //}


    async TraerAmbientes() {
        var SucursalID = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas/GetAmbientes?SucursalID=' + SucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            var primerAmbiente = datos[0];
            if (!primerAmbiente) {
                primerAmbiente = {
                    id:0
                }
            }
            this.setState({ Ambientes: datos, ambienteSeleccionado: primerAmbiente});
        }
        else {
            alert('error al traer las mesas');
        }
    }


    async TraerMesas() {
        var respuesta = await fetch(GetUrlApi() + '/api/Mesas/GetMesasDos', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            var usuario = GetUserCookie();

            datos = datos.filter(p => p.sucursalID === usuario.SucursalID);
            this.MesasIniciales = datos;
            this.setState({ Mesas: datos });
            this.FiltrarDatos();

        }
        else {
            alert('error al traer las mesas');
        }
    }
    
    FiltrarAmbiente(idAmbiente, ambiente) {
        this.setState({ ambienteSeleccionado: ambiente });
        this.FiltroAmbiente = idAmbiente;
        this.FiltrarDatos();
    }
    async FiltrarDatos() {
        var mesas = this.MesasIniciales

        var ambientes = this.state.Ambientes;
        var FiltroAmbientes = this.FiltroAmbiente
        if (FiltroAmbientes == 0) {
            if (ambientes.length > 0) {
                FiltroAmbientes = ambientes[0].id;
            }
        }
        console.log(FiltroAmbientes);
        if (FiltroAmbientes != 0) {
            if (FiltroAmbientes == 5001) {
                mesas = mesas;
            } else {
                mesas = mesas.filter(p => p.ambienteID == FiltroAmbientes);
            }

        }
        console.log("Filtro");
        console.log(mesas);
        this.setState({ Mesas: mesas });
    }

    async ClickTrasladarMesa(idMesaClic) {
        var data = {};
        data.idOrden = this.OrdenMoverID;
        data.MesaID = this.MesaMoverID;
        data.NewMesa = idMesaClic;

        var respuesta = await fetch(GetUrlApi() + '/api/Orden/Trasladar', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            this.Redirigir('/');
        }
        else {
            swal('error al trasladar mesa');
        }

    }

    Estilos = {
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        ContentPrincipal:
        {
            'margin-top': '5%'
        },
        columnaCentral: {
            'width': '100%',
            'background-color': '#fff',
            'overflow-x': 'hidden',
            'padding-top': '20px',
            'height': '100%',
        },
        Content:
        {
            'margin-left': '0%',
            'top': '0',
            'background-color': '#fff',
            'overflow-x': 'hidden',
            'padding-top': '20px'
        },
        DivCenter:
        {
            'margin-right': '0%',

        },
        Laterales:
        {
            'height': 'auto',
            'width': '10%',
            'position': 'fixed',
            'top': '92px',

            'background-color': '#525050',
            'overflow-x': 'hidden',
            'padding-top': '20px'
        },
        BodyCardStyle:
        {
            'margin-left': '-15px'
        },
        MesaOcupada:
        {
            'background': '#BFC205',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display': 'block'
        },
        MesaLibre:
        {
            'background': '#529CE6',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display': 'block'
        },
        MesaPagada:
        {
            'background': 'rgb(200, 3, 3)',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display': 'block'
        },
        MesaCtaImpresa:
        {
            'background': 'rgb(199, 64, 220)',
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px',
            'display': 'block'
        },
        NroMesa:
        {
            'font-size': '23px',
            'text-decoration': 'solid',
            'padding-bottom': '0px',
            'margin-left': '45%',
            'border-radius': '4px',
            'border-color': '#fff !important',
            'border': '2px solid',
            'width': '35px',
            'height': '35px',
            'text-align': 'center'
        },
        NroMesaO:
        {
            'font-size': '27px',
            'text-decoration': 'solid',
            'padding-bottom': '4px',
            'margin-left': '-20%',
            'border-radius': '4px',
            'border-color': '#fff !important',
            'border': '2px solid',
            'width': '41px',
            'height': '41px',
            'text-align': 'center',
            'margin-top': '-10%'
        },
        IconSize:
        {
            'font-size': '28px',
            'padding-top': '13%'
        },
        CapacidadSize:
        {
            'font-size': '17px',
            'margin-left': '-5%',
            'margin-top': '-6%'
        },
        CapacidadSizeO:
        {
            'font-size': '20px',
            'margin-left': '-5%',
            'margin-top': '-31%'
        },
        BtnStyle:
        {
            'color': 'white',
            'width': '100px',
            'height': '100px',
            'border-radius': '4px'
        },
        DivIcon:
        {
            'font-size': '10px',
            'margin-left': '-35%',
            'margin-bottom': '4%'
        },
        DivIcon2:
        {
            'font-size': '10px',
            'margin-left': '-35%'
        },
        DivIcon3:
        {
            'font-size': '10px',
            'margin-left': '-35%',
            'margin-top': '-9%'
        },
        DivIcon4:
        {
            'font-size': '16px',
            'margin-left': '-35%',
            'margin-top': '11%',
            'margin-bottom': '-12%'
        },
        UserSize:
        {
            'font-size': '12px',
            //'margin-right': '-6%',
            'margin-left': '-74%',
            'margin-top': '-11%'
        },
        HoraSize:
        {
            'font-size': '12px',
            'margin-right': '10%',
            'padding-top': '6%',
            'margin-left': '-55%'
        },
        MarginTopC:
        {
            'margin-top': '-14%',
            'margin-left': '-11%'
        },
        BtnStyleDemoA:
        {
            'background': '#529CE6',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius': ' 5px'
        },
        BtnStyleDemoC:
        {
            'background': '#BFC205',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius': ' 5px'
        },
        BtnStyleDemoI:
        {
            'background': '#C740DC',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius': ' 5px'
        },
        BtnStyleDemoS:
        {
            'background': '#20B119',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius': ' 5px'
        },
        BtnStyleDemoP:
        {
            'background': '#C80303',
            'color': 'white',
            'width': '25px',
            'height': '25px',
            'border-radius': ' 5px'
        },
        NumMesaMarco:
        {
            'border-radius': '4px',
            'border-color': '#fff !important',
            'border': '2px solid',
            'margin-left': '2%'
        },
        SideNav:
        {
            'height': '100%',
            'width': '118%',
            'top': '92px',

            'background-color': '#fff',
            'overflow-x': 'hidden',
            'padding-top': '20px',
            'z-index': '1',
            'position': 'inherit'
        },
        DivS:
        {
            'width': '10%',
            'margin-left': '-1%'
        },
        ButtonStyle:
        {
            'borderRadius': '10px',
            'height': '50px',
            'width': '85px',
            'border-color': 'cadetblue',
            'background-color': '#f2f2f2',
            'margin-bottom': '3%'
        },
        DivContent:
        {
            'background': 'transparent',
            'width': '100%',
            'height': '600px'
        },
        Footer:
        {
            'background-color': 'white',
            'position': 'absolute',
            'bottom': '0',
            'width': '100%',
            'height': '40px',
            'color': 'black',
            'margin-left': '2%'
        },
        Separacion:
        {
            //'margin-top': '-26.5%',
            'margin-top': '-10.5%',
            'margin-left': '11%',
            'width': '90%'

        },
        UlStyle:
        {
            'list-style-type': 'none',
            'margin': '0',
            'padding': '0',
            'width': '25%',
            'background-color': '#f1f1f1',
            'position': 'fixed',
            'height': '100%',
            'overflow': 'auto'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'marginTop': '0.5%',
            'marginRight': '1%',
            'marginLeft': '2%'
        },
    }

    Redirigir(url) {
        this.props.history.push(url);
    }


    ClickVerMesaDesocupada(id) {
        if (!this.state.IsSeleccionar)
            this.Redirigir('/paraLlevarDos?tipoV=1&nmesa=' + id);
        else {
            this.ClickTrasladarMesa(id);
        }
    }

    ClickVerMesaOcupada(id, TipoVentaID) {
        if (!this.state.IsSeleccionar)
            this.Redirigir('/OrdenesMesa?id=' + id + '&TipoVentaID=' + TipoVentaID);
        else {
            this.ClickTrasladarMesa(id);
        }
    }

    async ClickMesa(id) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdenByMesa?idMesa=' + id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            if (datos.length > 0) {
                this.ClickVerMesaOcupada(id);
            } else {
                this.ClickVerMesaDesocupada(id);
            }

        }
        else {
            alert('error al traer las mesas');
        }
    }


    GetHtmlPopupEditarAmbiente() {
        this.TraerSucursales();
        return (
            <Popup
                defaultOpen
                modal
                contentStyle={
                    { 'height': '40%' }
                }
                onClose={() => { this.setState({ activarPopupEditarAmbiente: false }) }}
                closeOnDocumentClick>
                <center>
                    <br />
                    <br />
                    <h3>Ingrese los siguientes datos</h3>
                    <br />

                    <table>
                        <tbody>

                            <tr>
                                <td>Descripción</td>
                                <td>
                                    <input defaultValue={this.state.ambienteSeleccionado.descipcion} className="form-control" id="txtDesc" type="text" autoComplete="off" />
                                </td>
                            </tr>
                            <tr>
                                <td>Sucursal</td>
                                <td>
                                    <select id="cboSucursal" className="form-control">
                                        {
                                            this.state.sucursales.map((item) => {
                                                var isSelected = item.id === this.state.ambienteSeleccionado.sucursalID;
                                                return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>
                </center>

            </Popup>
        );
    }


    GetHtmlPopupCrearAmbiente() {
        this.TraerSucursales();
        return (
            <Popup
                defaultOpen
                modal
                contentStyle={
                    { 'height': '40%' }
                }
                onClose={() => { this.setState({ activarPopupCrearMesa: false }) }}
                closeOnDocumentClick>
                <center>
                    <br />
                    <br />
                    <h3>Ingrese los siguientes datos</h3>
                    <br />
                    
                    <table>
                        <tbody>

                            <tr>
                                <td>Descripción</td>
                                <td>
                                    <input className="form-control" id="txtDesc" type="text" autoComplete="off" />
                                </td>
                            </tr>
                            <tr>
                                <td>Sucursal</td>
                                <td>
                                    <select id="cboSucursal" className="form-control">
                                        {
                                            this.state.sucursales.map((item) => {
                                                return (<option value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <button onClick={() => { this.CrearAmbiente() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupCrearMesa() {
        this.TraerSucursales();
        return (
            <Popup
                defaultOpen
                modal
                contentStyle={
                    { 'height': '60%' }
                }
                onClose={() => { this.setState({ activarPopupCrearMesa: false }) }}
                closeOnDocumentClick>
                <center>
                    <br />
                    <br />
                    <h3>Ingrese los siguientes datos</h3>
                    <br />

                    <table>
                        <tbody>

                            <tr>
                                <td>Descripción (N° de mesa)</td>
                                <td>
                                    <input className="form-control" id="txtDesc" type="text" autoComplete="off" />
                                </td>
                            </tr>
                           
                            <tr>
                                <td>Capacidad de la mesa</td>
                                <td>
                                    <input className="form-control" id="txtCapacidad" type="number" autoComplete="off" />
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <button onClick={() => { this.CrearMesa() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupEditarMesa() {
        this.TraerSucursales();
        return (
            <Popup
                defaultOpen
                modal
                contentStyle={
                    { 'height': '60%' }
                }
                onClose={() => { this.setState({ activarPopupEditarMesa: false }) }}
                closeOnDocumentClick>
                <center>
                    <br />
                    <br />
                    <br />

                    <table>
                        <tbody>

                            <tr>
                                <td>Descripción (N° de mesa)</td>
                                <td>
                                    <input defaultValue={this.state.mesaSeleccionada.descipcion} className="form-control" id="txtDescMesa" type="text" autoComplete="off" />
                                </td>
                            </tr>
                            <tr>
                                <td>Ambiente</td>
                                <td>
                                    <select id="cboAmbiente" className="form-control">
                                        {
                                            this.state.Ambientes.map((item) => {
                                                const isSelected = item.id === this.state.mesaSeleccionada.ambienteID;
                                                return (<option selected={isSelected} value={item.id}>{item.descipcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Capacidad de la mesa</td>
                                <td>
                                    <input defaultValue={this.state.mesaSeleccionada.capacidad} className="form-control" id="txtCapacidad" type="number" autoComplete="off" />
                                </td>
                            </tr>
                            <tr>
                                <td>Sucursal</td>
                                <td>
                                    <select id="cboSucursal" className="form-control">
                                        {
                                            this.state.sucursales.map((item) => {
                                                const isSelected = item.id === this.state.mesaSeleccionada.sucursalID;
                                                return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditarMesa() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>
                    <button onClick={() => { this.ClickInhabilitarMesas()}} className="btn btn-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={this.Estilos.BtnSalir}>
                        <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    render() {
        return (
            <div style={this.Estilos.DivContent}>
                {
                    (this.state.activarPopupCrearAmbiente) ? (this.GetHtmlPopupCrearAmbiente()) : (null)
                }
                {
                    (this.state.activarPopupEditarMesa) ? (this.GetHtmlPopupEditarAmbiente()) : (null)
                }
                {
                    (this.state.activarPopupCrearMesa) ? (this.GetHtmlPopupCrearMesa()) : (null)
                }
                {
                    (this.state.activarPopupEditarMesa) ? (this.GetHtmlPopupEditarMesa()) : (null)
                }
                {
                    (this.state.activarPopupEditarAmbiente) ? (this.GetHtmlPopupEditarAmbiente()) : (null)
                }
                <div style={this.Estilos.DivS}>
                    <div style={this.Estilos.SideNav}>
                        <center>
                            {
                                this.state.Ambientes.map((item) => {
                                    return (
                                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title={item.descipcion}
                                            onClick={() => { this.FiltrarAmbiente(item.id,item) }}
                                        >

                                            <b> {item.descipcion}</b></button>
                                    );
                                })
                            }
                            <button onClick={() => { this.ClickNewAmbiente() }} className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title="Todos" >

                                <b>+</b></button>
                        </center>
                    </div>
                </div>
                <div style={this.Estilos.Separacion}>
                    {
                        (this.state.ambienteSeleccionado.id > 0) ? (
                            <React.Fragment>
                                <h4>{this.state.ambienteSeleccionado.descipcion}</h4>
                                <button className="btn btn-info" onClick={() => { this.setState({ activarPopupEditarAmbiente: true }) }} style={{ 'marginRight': '10px' }}  >Editar ambiente</button>
                                <button className="btn btn-danger" onClick={() => { this.ClickInhabilitarAmbiente() }}  >Deshabilitar ambiente</button>
                                <hr/>
                            </React.Fragment>
                        ): (null)
                    }
                    <div className="row" style={this.Estilos.DivCenter}>
                        {
                            this.state.Mesas.map((item) => {
                                    return (
                                        <div className="mb-3 ml-2 mr-1" style={this.Estilos.MesaLibre} >
                                            <button className="btn" style={this.Estilos.BtnStyle} onClick={() => { this.setState({ activarPopupEditarMesa: true, mesaSeleccionada:item }) }}
                                                 >
                                                <div className="card-text text-left" style={this.Estilos.CapacidadSizeO} ><b>{item.capacidad}</b> </div>

                                                <div className="row" style={this.Estilos.DivIcon4} >
                                                    <div className="col-sm-6">
                                                        <i className="fa fa-cutlery fa-2x"></i>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div style={this.Estilos.NroMesaO}>
                                                            <b>{item.descipcion}</b>
                                                        </div>

                                                    </div>
                                                </div>



                                            </button>

                                        </div>);
                                

                            })
                        }
                        <div className="mb-3 ml-2 mr-1" style={this.Estilos.MesaLibre} >
                            <button className="btn" style={this.Estilos.BtnStyle} onClick={() => { this.setState({ activarPopupCrearMesa:true }) }}
                            >
                                <div className="card-text text-left" style={this.Estilos.CapacidadSizeO} ><b></b> </div>

                                <div className="row" style={this.Estilos.DivIcon4} >
                                    <div className="col-sm-6">
                                        <i className="fa fa-cutlery fa-2x"></i>
                                    </div>
                                    <div className="col-sm-6">
                                        <div style={this.Estilos.NroMesaO}>
                                            <b>+</b>
                                        </div>

                                    </div>
                                </div>



                            </button>

                        </div>
                    </div>
                </div>

               
            </div>

        );
    }
}
