import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { EspecificarPedido } from './EspecificarPedido';
import { DetallesProducto } from './DetallesProducto';
import { Modificadores } from './Modificadores';
import { CancelarProd } from './CancelarProd';
import { DescuentosParaLlevar } from './DescuentosParaLlevar';
import { Pagar } from './Pagar';
import { Misc } from './Misc';
import { GetPrecioFormateado } from './Globales/Utilidades';
import UserProfile from './Globales/PerfilUsuario';
import { TecladoNumerico } from './TecladoNumerico';
import swal from 'sweetalert';
import { TrasladarOrden } from './TrasladarOrden';
import { TransferirOrdenes } from './TransferirOrdenes';
import { PopupMotivoAnulacion } from './PopupMotivoAnulacion';
import { GetUserCookie } from './Globales/Utilidades';
import { Imprimir } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';
import Popup from "reactjs-popup";

export class ParaLlevar extends Component {
    static displayName = ParaLlevar.name;
    IdVenta = 0;
    indice = 0;
    IdGavetaActual = 0;
    UserLogeado = {};
    Caja = GetUserCookie().CajaID;
    urlParams;
    IsReadOnly = false;
    UltimoItemClickeado = null;
    IsParaLlevar = false;
    PayForm = "Gaveta No Habilitada"
    InidiceRemover = 0;//usado para conectar el teclado de autorizacion a cancela producto
    constructor() {
        super();
        
        this.state = {
            orden: {
                carroProductos: [], descEfectivo: 0
                , numeroDeClientes: 1
            },
            cargandoCategorias: true,
            cargandoProductos: false,
            categoriasBotones: [],
            productosSeleccionados: [],
            carroProductos: [],
            precioTotal: 0,
            totalDescuento: 0,
            cantidad: 1,
            textoBoleta: '',
            dctoOrden: false,
            dctoArt: false,
            dctoEfec: false,
            dctoPorc: 0,
            verDetalle: false,
            activarVerDetalle: false,
            productoClickeado: null,
            activarMisc: false,
            activarModif: false,
            activarCancelar: false,
            activarPagar: false,
            activarDescuentos: false,
            activarCantidad: false,
            activarModForz: false,
            activarPopTransferir: false,
            activarPopAnular: false,
            activarTecladoNumCliente: false,
            activarPopNomCliente: false,
            activarTransferir: false,
            propinaPorc: 0,
            descEfectivo: 0,
            parametros: { indiceInicio: null },//hasta ahora solo llamado desde cancelarProd.js
            totalPropina: 0,
            isPagado: false,
            mostrarTecladoAut: false,
            isAnulada: false
        }
        this.handler = this.handler.bind(this)
        this.CerrarModalMaster = this.CerrarModalMaster.bind(this)
        this.ClickEliminar = this.ClickEliminar.bind(this);
        this.ActualizarCarroMaster = this.ActualizarCarroMaster.bind(this);
        this.ActualizarTexto = this.ActualizarTexto.bind(this);
        this.setState2 = this.setState2.bind(this);
        this.ClickBorrarOrden = this.ClickBorrarOrden.bind(this);
        
        this.setState = this.setState.bind(this);
        this.DesactivarModales = this.DesactivarModales.bind(this);
        this.RemoverItemOrden = this.RemoverItemOrden.bind(this);
        
        
        this.TraerCategorias();
        this.TraerCaja();
    }

    async TraerCaja() {
        var respuesta = await fetch(GetUrlApi() + '/api/Cajas/' + UserProfile.getUser().CajaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.Caja = datos;
            this.IdGavetaActual = datos.idUltimaGavetaFk;
            if (datos.isHabilitada) {
                try {
                    document.getElementById('btnPagar').disabled = false;
                    this.PayForm = "Pagar";
                    document.getElementById('btnPagar').innerHTML = '<i className="fa fa-usd fa-2x" aria-hidden="true"></i>';
                } catch (err) {

                }
            } else {
                swal({
                    title: "Notificacíon",
                    text: "No hay gaveta",
                    icon: "warning",
                    dangerMode: true,
                })
                document.getElementById('btnPagar').disabled = true;
                document.getElementById('btnListo').disabled = true;
                this.PayForm = "Gaveta No Habilitada"
                document.getElementById('btnPagar').innerHTML = '<i className="fa fa-ban fa-2x" aria-hidden="true"></i>';
            }
            //this.UserLogeado = await this.TraerUsuario(datos.idUsuarioFk);
            //#26112019.RFD no va a la api a buscar usuario
            var orden = this.state.orden;
            orden.usuariosID = UserProfile.getUser().id; //this.UserLogeado.id;
            orden.numCaja = this.Caja.id;
            this.setState({ orden: orden });
            this.ActualizarTexto();
        }
        else {
            alert('error');
        }
    }

    async TraerUsuario(idUser) {

        const url = GetUrlApi() + '/api/Usuarios/' + idUser;
        const usuario = await fetch(url);


        if (usuario.ok) {
            var datos = await usuario.json();
            return datos;
        }
        return null;
    }

    async setState2(obj) {
        await this.setState(obj);
        this.ActualizarTexto();
    }
    
    componentDidMount() {
        //document.getElementById('btnPagar').disabled = true;
        document.getElementById('btnDividir').disabled = true;
        this.urlParams = new URLSearchParams(this.props.location.search);
        var tipoVenta = Number( this.urlParams.get('tipoV'));
        if ( tipoVenta === 4) {
            this.setState({ activarPopNomCliente: true });
        }
        if (!Number(this.urlParams.get('nmesa'))) {
            this.IsParaLlevar = true;
        }
        this.CargarParametros().then(() => {
            var numMesa = Number(this.urlParams.get('nmesa'));
            if (numMesa > 0 && this.IdVenta === 0) {
                this.setState({ activarTecladoNumCliente: true });
            }
        });
        
    }

    async CargarParametros() {

        const idOrden =this.urlParams.get('id');
        const isPagar = this.urlParams.get('pagar') === 'true';
        const isEditar = this.urlParams.get('editar') === 'true';
        if (isEditar) {
            await this.CargarOrden(idOrden);
        }
        else {
           
        }
        this.ActualizarTexto();
        if (isPagar) {
            await this.setState({ activarPagar: true });
        }
        document.getElementById('btnDividir').disabled=false;
    }

    ActualizarCarroMaster(nuevoCarro) {
        this.setState({ carroProductos: nuevoCarro });
    }

    handler(someValue, mod) {
        this.AgregarProducto(someValue, mod);
    }

    CerrarModalMaster() {
        this.CerrarModal();
        this.DesactivarModales();
    }

    

    

    async TraerCategorias() {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes');
            if (!categorias.ok) {
                throw new Error('problema al traer las categorias');
            }
            const datos = await categorias.json();
            await this.setState({ categoriasBotones: datos, cargandoCategorias: false });
            this.TraerProductos(this.state.categoriasBotones[0].id, 'categorias0')
        } catch (err) {
            swal('No se pudo traer las categorias verifique su conexion a internet');
        }
    }

    async TraerProductos(idCategoria, idBtn) {
        var botones = document.getElementsByName('botonesCategorias');
        botones.forEach((value) => {
            value.style.backgroundColor = '';
            value.style.color = '';
        });
        document.getElementById(idBtn).style.backgroundColor = '#6e9887';
        document.getElementById(idBtn).style.color = '#fff';
        await this.setState({ cargandoProductos: true });
        try {
            var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria);
            if (!categorias.ok) {
                throw new Error('problema al traer las categorias');
            }
            var datos = await categorias.json();
           
            //#RFD.26112019 Optimiza filtro en API  
            //datos = datos.filter(datos => datos.codgrup === idCategoria);
            
            this.setState({ productosSeleccionados: datos, cargandoProductos: false });
        } catch (err) {

        }
    }

    async GenerarOrden() {
        var data = this.state.orden;


        data.Pagado = false;
        data.TiposVentaID = Number(this.urlParams.get('tipoV'));
        if (data.TiposVentaID===0) {
            data.TiposVentaID = 2;
        }
        data.MesaID = Number(this.urlParams.get('nmesa'));
        if (!data.MesaID || data.MesaID === 0) {
            data.MesaID = null;
        }
        data.descuentoPorc = this.state.orden.descuentoPorc;
        data.DescEfectivo = this.state.orden.dctoEfec;
        //data.Productos = orden.carroProductos;
        data.carroProductos = this.state.orden.carroProductos;
        data.propinaPorc = this.state.orden.propinaPorc;
        data.DctoOrden = this.state.orden.dctoOrden;
        data.DctoArt = this.state.orden.dctoArt;
        data.Total = this.state.precioTotal;
        data.Subtotal = this.state.precioTotal;
        data.GavetaID = this.IdGavetaActual;
        data.usuariosID = GetUserCookie().id;
        data.CajaID = this.Caja.id;
        data.SucursalID = GetUserCookie().SucursalID;
        if (data.usuariosID === 0) {
            data.usuariosID = 1;
        }
        //data.numCaja = UserProfile.getUser().;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            const res = await respuesta.json();
            this.IdVenta = res.id;
            this.ActualizarTexto();
        }
        else {
            alert('error');
        }
    }

    async ActualizarOrden() {
        var data = this.state.orden;


        data.ID = this.IdVenta;
        data.Pagado = false;
        try {
            data.TiposVentaID = this.state.orden.tiposVenta.id;
        } catch (err) {
            data.TiposVentaID = Number(this.urlParams.get('tipoV'));
        }
        if (this.state.orden.mesaID) {
            data.MesaID = this.state.orden.mesaID;
        } else if (Number(this.urlParams.get('nmesa'))){
            data.MesaID = Number(this.urlParams.get('nmesa'));
        }
        else if (!data.MesaID || data.MesaID === 0) {
            data.MesaID = null;
        }

        data.descuentoPorc = this.state.orden.descuentoPorc;
        data.DescEfectivo = this.state.orden.dctoEfec;
        //data.Productos = orden.carroProductos;
        data.carroProductos = this.state.orden.carroProductos;
        data.propinaPorc = this.state.orden.propinaPorc;
        data.DctoOrden = this.state.orden.dctoOrden;
        data.DctoArt = this.state.orden.dctoArt;
        data.Total = this.GetTotal();
        data.Subtotal = this.state.precioTotal;
        data.GavetaID = this.IdGavetaActual;
        data.usuariosID = GetUserCookie().id;
        data.CajaID = this.Caja.id;
        data.SucursalID = GetUserCookie().SucursalID;
        await fetch(GetUrlApi() + '/api/Orden/' + this.IdVenta, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
       
    }

    async CargarOrden(ordenId) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + ordenId, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            const resOrden = await respuesta.json();
            if (!resOrden.nombreCliente && resOrden.tiposVentaID===4) {
                this.setState({ activarPopNomCliente: true });
            }
            var isPagado2 = false;
            this.IdVenta = resOrden.id;
            if (resOrden.pagado) {
                this.BloquearCambios();
                isPagado2 = true;
                this.IsReadOnly = true;
            }
            else if (resOrden.isAnulada) {
                document.getElementsByName('blokCambios').forEach((item) => {
                    item.disabled = true;
                });
                document.getElementsByName('botonesCategorias').forEach((item) => {
                    item.disabled = true;
                });
                this.setState({ isAnulada:true });
            }
            this.setState({ orden: resOrden, isPagado: isPagado2 });
            this.ActualizarTexto();
        }
    }

    BloquearCambios() {//llamado cuando la orden abierta ya esta pagada
        document.getElementsByName('blokCambios').forEach((item) => {
            item.disabled = true;
        });
        document.getElementsByName('botonesCategorias').forEach((item) => {
            item.disabled = true;
        });
    }

    ActualizarTexto() {
        const mesero = GetUserCookie().username;
        var texto = 'Mesero: ' + mesero + ' estación ' + this.Caja.id + '\n';
        var precio = 0;
        var descuento = 0;
        var totalPropina = 0;
        const numOrden = (this.IdVenta > 0) ? (this.IdVenta.toString()) : ('Nueva Orden');
        var numMesa = Number(this.urlParams.get('nmesa'));
        if (!numMesa || numMesa===0) {
            numMesa = this.state.orden.mesaID;
        }
        texto += '-------------------------------\n';
        texto += '# de Orden: ' + numOrden + '\n';
        texto += '-------------------------------\n';
        if (numMesa > 0) {
            texto += '# de Mesa: ' + numMesa + '- Clientes: ' + this.state.orden.numeroDeClientes + '\n';
            
        }
        else {
            const nomCliente = (this.state.orden.nombreCliente) ? (this.state.orden.nombreCliente) : ('-');
            const numcliente = (this.state.orden.numeroDeClientes > 0) ? (this.state.orden.numeroDeClientes) : (1);
            texto += 'Clientes: ' + numcliente + '\n';
            texto += 'nombre: ' + nomCliente + '\n';
            texto += '-------------------------------\n';
        }

        this.state.orden.carroProductos.forEach(function (currentValue) {
            precio += currentValue.precpred * currentValue.cantidad;
            texto += currentValue.cantidad + '  ';
            texto += currentValue.desprod + '  ';
            texto += GetPrecioFormateado(currentValue.precpred * currentValue.cantidad);
            texto += '\n';
            if (currentValue.descuentoPorc > 0) {
                texto +='   '+ currentValue.descuentoPorc + '%  (';
                texto += GetPrecioFormateado(Math.round(currentValue.precpred * (currentValue.descuentoPorc / 100))) + ')';
                texto += '\n';
            }

            if (currentValue.modificadores) {
                currentValue.modificadores.forEach(function (valor) {
                    texto += '   ' + valor;
                    texto += '\n';
                });
            }
        });
        //----INICIO PROPINA
        if (this.state.orden.propinaPorc) {
            texto += '\n';
            texto += this.state.orden.propinaPorc + '% propina ' + GetPrecioFormateado(Math.round((this.state.precioTotal * (this.state.orden.propinaPorc / 100))));
            totalPropina += GetPrecioFormateado( Math.round((this.state.precioTotal * (this.state.orden.propinaPorc / 100))));
        } else if (this.state.propinaPorc) {//arreglar los bloques try catch de teclado numerico
            texto += '\n';
            texto += this.state.propinaPorc + '% propina ' + GetPrecioFormateado(Math.round((this.state.precioTotal * (this.state.propinaPorc / 100))));
            totalPropina += GetPrecioFormateado( Math.round((this.state.precioTotal * (this.state.propinaPorc / 100))));
        }

        if (this.state.orden.propinaCant) {
            const lastTwo = texto.slice(-2);
            if (lastTwo !== '\n') {
                texto += '\n';
            }
            texto += 'propina ' + GetPrecioFormateado(this.state.orden.propinaCant);
            totalPropina += GetPrecioFormateado(this.state.orden.propinaCant);
        }
        if (this.state.orden.propinaDiff) {
            const lastTwo = texto.slice(-2);
            if (lastTwo !== '\n') {
                texto += '\n';
            }
            texto += 'propina $' + this.state.orden.propinaDiff - this.GetTotal();
            totalPropina += GetPrecioFormateado(this.state.orden.propinaDiff - this.GetTotal());
        }
        //--------FIN PROPINA

        //-------- DESCUENTOS

        if (this.state.orden.dctoEfec) {
            descuento += Number(this.state.orden.dctoEfec);
            const lastTwo = texto.slice(-2);
            if (lastTwo !== '\n') {
                texto += '\n';
            }
            texto += 'Descuento (' + this.state.orden.dctoEfec + ')';
            
        }
        const orden = this.state.orden;
        if (orden.dctoOrden > 0) {
            const lastTwo = texto.slice(-2);
            if (lastTwo!=='\n') {
                texto += '\n';
            }
            
            texto += orden.dctoOrden + '%  descuento  ($' + Math.round(this.state.precioTotal * (orden.dctoOrden / 100)) + ')';
            descuento += Number(Math.round(this.state.precioTotal * (orden.dctoOrden / 100)));
            
        }
        if (texto.substr(texto.length - 2) === '\n') 
            texto += '\n';
        
        var fecha = new Date();
        texto += '\n';
        texto += '-------------------------------';
        texto += '\n';
        texto += '               ' + fecha.getHours() + ':' + fecha.getMinutes()+' ' + fecha.getDate() + '/' + (fecha.getMonth()+1) + '/' + fecha.getFullYear();
        //--------FIN DESCUENTOS
        this.setState({ textoBoleta: texto, precioTotal: precio, totalDescuento: descuento, totalPropina: totalPropina });
    }

    GetValorPropina() {
        const propinaPorc = this.state.orden.propinaPorc;
        const propinaCant = this.state.orden.propinaCant;
        const propinaDiff = this.state.orden.propinaDiff;
        const propinaPorc2 = this.state.propinaPorc;
        var res;
        if (propinaPorc > 0 && propinaCant > 0) {
            console.log('conflicto entre propinas');
        }
        else if (propinaPorc > 0) {
            res = this.state.precioTotal * (this.state.orden.propinaPorc / 100);
            return Math.round(res);
        }
        else if (propinaCant > 0) {
            res = this.state.orden.propinaCant;
            return Math.round(res);
        }
        else if (propinaDiff > 0) {
            const total = Number(this.state.precioTotal);
            res = total - this.state.orden.propinaDiff;
            res = res * -1;
            return Math.round(res);
        }
        else if (propinaPorc2>0) {
            res = this.state.precioTotal * (this.state.propinaPorc / 100);
            return Math.round(res);
        }
        return 0;
    }

    async AgregarProducto(idProducto, mod) {
        var seleccionados = Object.create(this.state.productosSeleccionados);
        var carrito = Object.create(this.state.orden.carroProductos).__proto__;
        
        var nuevoTotal = this.state.precioTotal;
        const cant = Number( this.state.cantidad);
       
        var productoSeleccionado = Object.create(seleccionados.filter(seleccionados => seleccionados.id === idProducto));
        productoSeleccionado = productoSeleccionado[productoSeleccionado.length - 1];
        if (mod) {
            productoSeleccionado.modificadores = mod;
        }
        //#RFD.26112019 Modifica por cambio modelo
        var productoAgregar = {};
        productoAgregar.cantidad = cant;
        productoAgregar.isCombo = productoSeleccionado.isCombo;
        productoAgregar.codgrup = productoSeleccionado.tbgrupoid;
        productoAgregar.id = productoSeleccionado.id;//jg codprod cambiado por id
        productoAgregar.desprod = productoSeleccionado.desprod;
        productoAgregar.precioFinal = productoSeleccionado.precioFinal;
        productoAgregar.precpred = productoSeleccionado.precpred;
        productoAgregar.tipomodif = productoSeleccionado.tipomodif;
        productoAgregar.modificadores = productoSeleccionado.modificadores;
        productoAgregar.isGuardado = true;


        carrito.push(productoAgregar);
        nuevoTotal += productoSeleccionado.precpred * this.state.cantidad;
        productoSeleccionado.precioFinal = productoSeleccionado.precpred * this.state.cantidad;
        var newOrden = this.state.orden;
        newOrden.carroProductos = carrito;
            
        await this.setState({ carroProductos: carrito, precioTotal: nuevoTotal, cantidad: 1, orden: newOrden });
        this.ActualizarTexto();
        
    }

   

    GetOrden() {
        var res = this.state.orden;
        return res;
    }

    CerrarModal() {
        try {
            var modales = document.getElementsByClassName("popup-overlay");
            if (modales.length > 0)
                modales[modales.length - 1].style.visibility = "hidden";
            this.setState({ verDetalle: false, activarVerDetalle: false });
        } catch (err) {
            console.log('ex en cierre de modal');
        }
    }


    ClickDescuentos() {

        this.setState({ activarDescuentos: true });
    }

    async ClickPagar() {
        
        document.getElementById('btnPagar').disabled = true;
        document.getElementById('btnPagar').innerText = 'Procesando';
        if (!this.IdVenta > 0)
            await this.GenerarOrden();
        else {
            this.ActualizarOrden();
        }
        this.setState({ activarPagar: true });
        if (document.getElementById('btnPagar')) {
            document.getElementById('btnPagar').disabled = false;
            document.getElementById('btnPagar').innerHTML = '<i className="fa fa-usd fa-2x" aria-hidden="true"></i>';
        }
    }
    

    async DejarPendiente() {
        document.getElementById('btnListo').disabled=true;
        if (!this.IdVenta > 0)
            this.GenerarOrden();
        else {
            this.ActualizarOrden();
        }
        this.Redirigir('/');
    }

    async ClickEliminar(index) {
        try {
            const prodSeleccionado = this.state.orden.carroProductos[index];
            if (!prodSeleccionado.isGuardado) {
                this.setState({ mostrarTecladoAut: true });
                this.InidiceRemover = index;
            }
            else {
                this.RemoverItemOrden(index,null);
            }
            
        }
        catch (err) {
            console.log(err);
        }
    }

    async RemoverItemOrden(index, motivo) {

        var contenidoCarro = this.state.orden.carroProductos;
        if (!index) {
            index = 0;
        }
        var nuevoTotal = this.state.precioTotal - this.state.orden.carroProductos[index].precioFinal;
        if (index > -1) {
            if (!contenidoCarro[index].isGuardado ) {
                var data = {};
                data.OrdenID = this.IdVenta;
                data.Motivo = motivo;
                data.TbProductoID = contenidoCarro[index].id;
                var respuesta = await fetch(GetUrlApi() + '/api/ProductosOrden/AnularProducto', {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

            }
            contenidoCarro.splice(index, 1);
            var newOrdenes = this.state.orden;
            newOrdenes.carroProductos = contenidoCarro;
            await this.setState({ carroProductos: contenidoCarro, precioTotal: nuevoTotal });
            this.DesactivarModalesAut();
            this.ActualizarTexto();
        }
        else {
            console.log('no entro');
        }
    }

    async ClickBorrarOrden() {
        this.Redirigir('/');
        Logout();
        
       
    }

    //BorrarEstaOrden() {
    //    const res = fetch(GetUrlApi() + '/api/Orden/' + this.IdVenta, {
    //        method: 'DELETE',
    //        headers: {
    //            'Content-Type': 'application/json',
    //        }
    //    });
    //    this.Redirigir('/revisar');
    //}
    

    Redirigir(url) {
        this.props.history.push(url);
    }
    ClickAddNombre()
    {
        this.setState({activarPopNomCliente: true });
        
    }
    ClickTecladoCantidad(num) {
        document.getElementById('txtCantidad').value = document.getElementById('txtCantidad').value + num;

    }
    ClickSelectCantidad() {
        const cantidad = document.getElementById('txtCantidad').value;
        this.setState({ cantidad: cantidad });
    }
    ClickVerDetalles(codProd) {
        if (this.state.verDetalle) {
            //#RFD.26112019 cambio codprod por id
            var prodClicked = this.state.productosSeleccionados.filter(productosSeleccionados => productosSeleccionados.id === codProd)[0];
            this.setState({ activarVerDetalle: true, productoClickeado: prodClicked });
        }
        else {
            this.AgregarProducto(codProd);
        }
    }
    ClickDctoPorc(porc) {
        this.setState({ dctoPorc: porc });

        this.CerrarModal();
    }
    ClickTablaPedido(indiceProdSelec) {
        this.setState({ activarModif: true, parametros: { indiceInicio: indiceProdSelec } });
    }
    //Inicio Clicks mesas
    ClickTransferir() {
        this.setState({ activarPopTransferir:true});
    }
    ClickAnular() {
        if (this.IdVenta > 0)
            this.setState({activarPopAnular:true});

        else
            swal('La orden no esta creada');
        
    }
    //Fin Clicks mesas
    DesactivarModales() {
        this.setState({
            activarMisc: false,
            activarVerDetalle: false,
            activarModif: false,
            activarCancelar: false,
            activarPagar: false,
            activarDescuentos: false,
            activarCantidad: false,
            activarModForz: false,
            activarPopTransferir: false,
            activarPopAnular: false,
            activarTransferir:false,
            mostrarTecladoAut:false,

        });
    }

    DesactivarModalesAut() {
        this.setState({
            activarMisc: false,
            activarVerDetalle: false,
            activarModif: false,
            activarPagar: false,
            activarDescuentos: false,
            activarCantidad: false,
            activarModForz: false,
            activarPopTransferir: false,
            activarPopAnular: false,
            activarTransferir: false,
            mostrarTecladoAut: false
        });
    }
   
    //--------ESTILOS
    row = {
        'display': 'flex'
    }

    column1 = {
        'float': 'left',
        'width': '33.33 %',
        'padding': '10px',
        'height': '300px',

    }
    column2 = {
        'width': '33.33 %',
        'padding': '10px',
        'height': '300px'
    }
    column3 = {
        'float': 'rigth',
        'width': '33.33 %',
        'padding': '10px',
        'height': '300px'
    }

    FullScreen = {
        'height': '100%',
        'width': '100%'
    }

    buttonCategoriaSize = {
        'width': '50%',
        'height': '70px',
        'borderRadius': '15px',
        'paddingTop': '10px',
        'paddingRight': '20px',
        'paddingBottom': '10px',
        'overflow': 'hidden',
        'outline': 'none',
        'borderColor': 'grey',
        'fontSize':'12px'
    }

    buttonProductosSize = {
        'width': '19%',
        'height': '55px',
        'color': 'black',
        'fontweight': 'bold',
        'margin': '1px',
       // 'backgroundColor': '#03FAD1'
    }

    FullAncho = {
        'width': '100%'
    }
    TextareaBoleta = {
        'width': '100%',
        'height': '460px',
        'border-radius': '8px',
        'box-shadow': '2px 2px 10px #666'
    }

    BotonesTeclado = {
        'width': '33%'
    }
    AlturaTotales = {
        'height': '15%'
    }
    HightAuto = {
        'height': 'auto'
    }

    BtnSalir = {
        'height': '60px',
        'width': '9%',
        'opacity': '90%',
        'border-color': 'rgb(110, 152, 135)',
        'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px'
    }

    Estilos = {
        FooterStyles:
        {
                'margin-left': '0%'
        },
        btnOptInferior: {
            'height': '100%',
            'width': '11%',
            'background': 'rgb(233, 234, 234)',
            'color': 'black',
            'border-color': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px'
        },
        FondoBlanco: {
            'backgroundColor': 'white',
            'margin-top': '-5%'
        },
        LetraBlanca: {
            'color':'white'
        },
        TablaPedido: {
            'backgroundColor': 'white',
            'width':'100%',
            'height':'100%'
        },
        FuenteRoja: {
            'color':'red',
            'fontWeight':'bold'
        },
        FuenteTotal: {
            'fontweight': 'bold',
            'fontSize':'150%'
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden',
            'overflowY': 'hidden',
        },
        Columna1: {
            'height': '600px',
            'overflowY': 'scroll',
            'resize': 'both',
            'overflow': 'auto'
        },
        ResizeDiv: {
            'resize': 'both',
            'overflow': 'auto'
        },
        SelloPagado: {
            'width':'50%',
            'height':'50%'
        },
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        ContentPrincipal:
        {
            'margin-top': '5%'
        },
        columnaCentral: {
            'width': '100%',
            'background-color': '#525050',
            'overflow-x': 'hidden',
            'padding-top': '20px',
            'height': '100%',
        },
        Content:
        {
            'margin-left': '0%',
            'top': '0',
            'background-color': '#525050',
            'overflow-x': 'hidden',
            'padding-top': '20px'
        },
        DivCenter:
        {
            'margin-right': '-17%',
            'margin-left': '5%'
        },
        Laterales:
        {
            'height': 'auto',
            'width': '10%',
            'position': 'fixed',
            'top': '92px',

            'background-color': '#525050',
            'overflow-x': 'hidden',
            'padding-top': '20px'

        },
        ColIzq: {
            'width': '13%',
            'paddingTop': '50px'
        },
        ColCenter: {
            'width': '13%',
            'paddingTop': '12px',
            'margin-left':'7%'
        },
        ColDer: {
            'width': '30%',
            'paddingTop': '50px'
        },
        btnOk: {
            'Background': '#47cf73'
        },
        estiloSpan: {
            'pointerEvents': 'none'
        },
        AlertaPinInvalido: {
            'display': 'none'
        },
        AnchoMitad: {
            'width': '50%'
        },
        BotonesDerecha: {
            'float': 'right'
        },
        DivBotonera: {
            'width':'100%'
        },
        BotoneraCategoria:
        {
            'width': '50%',
            'height': '60px',
            'borderRadius': '15px',
            'paddingTop': '10px',
            'paddingRight': '20px',
            'paddingBottom': '10px',
            'overflow': 'hidden',
            'outline': 'none',
            'borderColor': 'grey',
            'fontSize': '80%',
            'text-align': 'center'
        },
        BotoneraSuperior:
        {
            'border-radius': '5px',
            'height': '50px',
            'width': '90px',
            'border-color': '#6e9887',
            'box-shadow': '2px 2px 10px #666',
            'margin-left': '0%',
            'font-size': '90%',
            'background': 'rgba(199, 202, 202, 0.19)',
            'margin-right': '0.7%'
        },
        BotonesCliente:
        {
            'border': 'none',
            'border-radius': '6px',
            'background': 'transparent',
            'font-size': '10px'
        },
        SpanFontSizeClientes:
        {
            'font-size': '20px'
        },
        BotoneraProductos:
        {
            'width': '24%',
            'height': '70px',
            'color': 'black',
            'border-color': '#bfbfc0',
            'margin-bottom': '1%',
            'font-size': '85%',
            'margin-right': '0.25%'
        },
        ButtonOptions:
        {
            'float': 'right',
            'border-radius': '10%',
            'border-color': 'gray'
        },
        BtnGroup:
        {
            'height': '100%',
            'width': '6.1%',
            'border-color': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '34%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'border-radius': '8px'
        },
        ContentSubtotal:
        {
            'border': '1px solid #a9a9a9',
            'border-radius': '8px',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'height': '50px'
        },
        EstiloPopupName:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '4px'
        },
        NombreBox:
        {
            'width': '60%',
            'margin-bottom': '1%',
        },
        NombreBoxSave:
        {
            'width': '25%',
            'height': '45px',
            'border-radius': '4px'
        },
        BtnSuc:
        {
            'width': '50%',
        }
    }

    //--------ESTILOS
    ClickMasCliente() {
        var orden = this.state.orden;
        orden.numeroDeClientes++;
        this.setState({ orden: orden });
        this.ActualizarTexto();
    }
    ClickMenosCliente() {
        var orden = this.state.orden;
        orden.numeroDeClientes--;
        this.setState({ orden: orden });
        this.ActualizarTexto();
    }
    GetHtmlTablaPedido() {
        var parrafoPropina = null;
        var orden = this.state.orden;
        var parrafoDescuento = null;
        //propina
        if (orden.propinaPorc > 0) {
            const total = GetPrecioFormateado( Math.round(this.state.precioTotal * (orden.propinaPorc / 100)));
            parrafoPropina =
                <tr>
                    <td>
                        <p> Propina</p>
                </td>
                <td>{orden.propinaPorc}%</td>
                <td>${total}</td>
                </tr >
        }
        else if (orden.propinaCant > 0) {
            parrafoPropina = <tr>
                <td>
                    Propina
                </td>
                <td></td>
                <td>${orden.propinaCant}</td>
            </tr >
        }
        else if (orden.propinaDiff>0) {
            parrafoPropina = <tr>
                <td>
                    Propina
                </td>
                <td></td>
                <td>${(this.state.precioTotal-orden.propinaDiff)*-1 }</td>
            </tr >
        }
        //fin propina


        //Descuentos GLOBALES
        if (orden.dctoOrden > 0) {
            const valorDesc = Math.round(this.state.precioTotal * (orden.dctoOrden / 100));
            parrafoDescuento =
                <tr id="seccionDescuentosGlobales">
                    <td>
                        <p style={this.Estilos.FuenteRoja}>Descuento </p>
                </td>
                <td>
                    <p style={this.Estilos.FuenteRoja}>  {orden.dctoOrden}% </p>
                </td>
                <td>
                    <p style={this.Estilos.FuenteRoja}>  {valorDesc} </p>
                </td>
                </tr>
        }
        else if (orden.dctoEfec > 0) {
            parrafoDescuento =
                <tr>
                    <td id="seccionDescuentosGlobales">
                        <p style={this.Estilos.FuenteRoja}>Descuento</p>
                    </td>
                <td>
                     <p> ${orden.descEfectivo} </p>
                </td >
                <td>
                </td>
            </tr >
        }
        //fin descuentos GLOBALES
        return (
            <table  style={this.Estilos.TablaPedido}>
                <thead>
                   
                </thead>
                <tbody>
                    {

                        this.state.carroProductos.map((item, i) => {
                            var descPorc = '';
                            var modificadores = '';
                            var parrafoDescuento=null;
                            if (item.modificadores) {
                                item.modificadores.forEach((item) => {
                                    modificadores += '\n';
                                    modificadores += item;
                                    
                                });
                            }
                            //descuentos
                            if (item.descuentoPorc > 0) {
                                descPorc = item.descuentoPorc;
                                const valorDesc = Math.round(item.precpred * (descPorc / 100));

                                parrafoDescuento = <p style={this.Estilos.FuenteRoja}>{descPorc}% {valorDesc}</p>
                            }
                            
                            //fin descuentos
                            var prefix = 'tablaProds';
                            return (
                                <tr onClick={() => { this.ClickTablaPedido(i) }} key={prefix + i}>
                                    <td>{item.cantidad} </td>
                                    <td>{item.desprod}<br />{modificadores}
                                        
                                    </td>
                                    {parrafoDescuento}
                                    <td>{GetPrecioFormateado( item.precpred)}</td>
                                </tr>
                            )
                        })

                    }
                    {parrafoPropina}
                    {parrafoDescuento}
                </tbody>
            </table>
        );
    }
    GetTotal() {
        var ValorDescuento = this.GetDescuento();
        const valorProp = Number(this.GetValorPropina());
        const total = Number(this.state.precioTotal);

        if (!ValorDescuento) {
            ValorDescuento = 0;
        }

        return Math.round((total - ValorDescuento) /*+ valorProp*/);
    }
    GetDescuento() {
        var cont = 0;
        if (this.state.orden.dctoOrden > 0) {
            cont += Number(this.state.precioTotal) * (this.state.orden.dctoOrden / 100);
        }
        if (this.state.orden.descEfectivo > 0) {
            cont += this.state.orden.descEfectivo;
        }
        if (this.state.orden.carroProductos.length>0) {
            this.state.orden.carroProductos.forEach(function (producto) {
                if (producto.descuentoPorc > 0) {
                    const descuento = producto.precpred * (producto.descuentoPorc / 100);
                    cont += descuento;
                }
            });
        }
        return Math.round(cont);
    }
    ClickModificador(item) {
        this.UltimoItemClickeado = item;
        this.setState({ activarModForz: true });
    }
    GetEspecificarPedido() {
        if (!this.UltimoItemClickeado) {
            return null;
        }
        else {
            return (
                <EspecificarPedido
                    CerrarModalMaster={this.CerrarModalMaster}
                    DesactivarModales={this.DesactivarModales}
                    carrito={this.state.carroProductos}
                    handler={this.handler}
                    prod={this.UltimoItemClickeado}
                />
            );
        }
    }
    ClickNuevaOrden() {

        document.getElementById('btnListo').disabled = true;
        if (!this.IdVenta > 0)
            this.GenerarOrden();
        else {
            this.ActualizarOrden();
        }
        const nMesa = this.state.orden.mesaID;
        if (nMesa) {
            window.location.href = '/paraLlevar?tipoV=1&nmesa=' + nMesa;
            //this.Redirigir('/paraLlevar?tipoV=1&nmesa=' + nMesa);
        }
        else {
            window.location.href = '/paraLlevar';
            //this.Redirigir('/ParaLlevar');
        }

        
    }
    async ClickDividir() {
        document.getElementById('btnListo').disabled = true;
        if (!this.IdVenta > 0)
            await this.GenerarOrden();
        else {
            await this.ActualizarOrden();
        }
        var data = {};
        data.OrdenID = this.IdVenta;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/DesmenuzarOrden', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        //if (respuesta.ok) {
        //    const res = await respuesta.json();
            
        //}
        this.Redirigir('/OrdenesMesa?ordenID=' + this.IdVenta);
    }
    ClickClear() {
        document.getElementById('1').style.backgroundColor = '#bbb';
        document.getElementById('2').style.backgroundColor = '#bbb';
        document.getElementById('3').style.backgroundColor = '#bbb';
        document.getElementById('4').style.backgroundColor = '#bbb';
        this.Numero = '';
        document.getElementById('PINbox').value = '';
    }
    GetHtmlPopupComensales() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                closeOnDocumentClick>
                <center>
                    <div className="row" >
                        <div style={this.Estilos.ColCenter}>
                                <table id="tablaBotones">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="3">
                                                <h5>Seleccione el numero de comensales</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <input id="PINbox" type="number" />
                                            </td>
                                            <td></td>
                                    </tr>
                                    <tr>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>
                                    </tr>
                                      
                                        <tr>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>
                                        </tr>
                                    <tr>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                        <td> <input onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                        <td><input onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>
                                    </tr>
                                        <tr>
                                            <td><input onClick={() => { this.ClickClear() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                        <td> <input onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                        <td><button onClick={() => { this.ClickEnterNumClientes() }} className='PINbutton enter' name='+' id='+'>
                                                <span style={this.Estilos.estiloSpan} className="glyphicon glyphicon-ok"></span>Enter
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>

                    </div>


                </center>
            </Popup>
        );
    }
    ClickEnterNumClientes() {
        const numClientes = Number( document.getElementById('PINbox').value);
        var newOrden = this.state.orden;
        newOrden.numeroDeClientes = numClientes;
        this.setState({ orden: newOrden, activarTecladoNumCliente: false });
        this.ActualizarTexto();
    }
    ClickTecladoCantidad2(num) {
        const numComoTexto = Number(num).toString();
        document.getElementById('PINbox').value = document.getElementById('PINbox').value + numComoTexto;
        const valorPinBox = document.getElementById('PINbox').value;
        //if (valorPinBox.length <= 4)
        //    document.getElementById(valorPinBox.length).style.backgroundColor = 'white';
    }

    ClickGuardarNombre() {
        var nomCli = document.getElementById('txtNomCliente').value;
        var newStateOrden = this.state.orden;
        newStateOrden.nombreCliente = nomCli;
        this.setState({ orden: newStateOrden, activarPopNomCliente: false });
        this.ActualizarTexto();
    }

    GetHtmlPopupNomCliente() {

        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupName}
                modal
                onClose={() => { this.setState({ activarPopNomCliente: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Ingrese el nombre del cliente</h3>
                    <input type="text" id="txtNomCliente" className="form-control" style={this.Estilos.NombreBox}/>
                    <button className="btn btn-success" style={this.Estilos.BtnSuc} onClick={() => {
                        this.ClickGuardarNombre()
                    }}> <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i> </button>
                </center>

            </Popup>
        );
    }

    ClickEnterNumClientes() {
        const numClientes = Number(document.getElementById('PINbox').value);
        var newOrden = this.state.orden;
        newOrden.numeroDeClientes = numClientes;
        this.setState({ orden: newOrden, activarTecladoNumCliente: false });
        this.ActualizarTexto();
    }

    render() {
        return (
            <div style={this.Estilos.EstiloPaginaCompleta}>
                {
                    (this.state.mostrarTecladoAut) ? (<TecladoNumerico IsAutorizacion={true} CrearCierre={this.RemoverItemOrden} param={(this.InidiceRemover+1)} param2={this.state.orden.carroProductos[this.InidiceRemover]} />) : (null)
                }
                    {(this.state.activarTecladoNumCliente) ? (
                        this.GetHtmlPopupComensales()

                    ) : (null)}
                {
                    (this.state.activarModForz) ? (
                        <EspecificarPedido
                            CerrarModalMaster={this.CerrarModalMaster}
                            DesactivarModales={this.DesactivarModales}
                            carrito={this.state.carroProductos}
                            handler={this.handler}
                            prod={this.UltimoItemClickeado}
                        />
                    ): (null)
                }
                {
                    (this.state.activarVerDetalle) ? (<div> <DetallesProducto CerrarModalMaster={this.CerrarModalMaster} prod={this.state.productoClickeado} /> </div>) : (null)
                }

                {
                    (this.state.activarMisc) ? (<div> <Misc ActualizarEstado={this.setState2}
                        CerrarModalMaster={this.CerrarModalMaster}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        prod={this.state.productoClickeado} /> </div>) : (null)
                }
                {
                    (this.state.activarModif) ? (<div> <Modificadores
                        ActualizarCarroMaster={this.ActualizarCarroMaster}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        productos={this.state.orden.carroProductos}
                        parametros={this.state.parametros}
                        ActualizarEstadoMaster={this.setState2} /> </div>) : (null)
                }
                {
                    (this.state.activarCancelar) ? (<div> <CancelarProd
                        ActualizarEstado={this.setState2}
                        ClickEliminar={this.ClickEliminar}
                        CerrarModalMaster={this.CerrarModalMaster}
                        DesactivarModales={this.DesactivarModales}
                        orden={this.state.orden}
                        productos={this.state.orden.carroProductos} /> </div>) : (null)
                }
                {
                    (this.state.activarPagar) ? (<div> <Pagar
                        idOrden={this.IdVenta}
                        ActualizarEstado={this.setState2}
                        orden={this.GetOrden()}
                        productos={this.state.carroProductos}
                        CerrarModalMaster={this.CerrarModalMaster}
                        idGaveta={this.IdGavetaActual}
                        TipoVenta={Number(this.urlParams.get('tipoV'))}
                        MesaID={Number(this.urlParams.get('nmesa'))} /> </div>) : (null)
                }
                {
                    (this.state.activarDescuentos) ? (<DescuentosParaLlevar ActualizarEstado={this.setState2} orden={this.state.orden} CerrarModal={this.DesactivarModales} />) : (null)
                }
                {
                    (this.state.activarCantidad) ? (<TecladoNumerico ActualizarEstado={this.setState2} CerrarModalMaster={this.DesactivarModales} cantidad={true} CerrarModal={this.DesactivarModales} />) : (null)
                }
                {
                    (this.state.activarPopTransferir) ? (

                        <TrasladarOrden DesactivarModales={this.DesactivarModales}
                            Orden={this.state.orden}
                            ClickBorrarOrden={this.ClickBorrarOrden}
                            ActualizarMaster={this.setState}
                        />
                    ) : (null)
                }

                {
                    (this.state.activarTransferir) ? (
                        <TransferirOrdenes DesactivarModales={this.DesactivarModales}
                            Orden={this.state.orden}
                            ClickBorrarOrden={this.ClickBorrarOrden}
                            CerrarModal={this.DesactivarModales}
                        />
                    ) : (null)
                }


                {
                    (this.state.activarPopAnular) ? (
                        <PopupMotivoAnulacion DesactivarModales={this.DesactivarModales}
                            Orden={this.state.orden}
                            ClickBorrarOrden={this.ClickBorrarOrden}
                        />
                    ) : (null)
                }
                {(this.state.activarPopNomCliente) ? (this.GetHtmlPopupNomCliente()):(null)}
              
                <div className="row">
                    <div style={this.Estilos.Columna1} className="column left">
                        {
                            (this.state.cargandoCategorias) ? (
                                <center>
                                    <div className="spinner-border text-black" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    <p> Cargando...</p>
                                </center>
                            ) : (null)
                        }
                        {
                            this.state.categoriasBotones.map((item, i) => {
                                const prefijo = 'categorias';
                                return (
                                    <button name="botonesCategorias" className="btn" id={prefijo + i} style={this.Estilos.BotoneraCategoria} onClick={() => { this.TraerProductos(item.id, prefijo + i) }} key={prefijo + i}><strong> {item.desgrup}</strong> </button>
                                );})
                }
                    </div>
                    <div id="divCentral" className="column middle">
                        <div style={this.Estilos.DivBotonera}>
                            
                            <button className="btn" style={this.Estilos.BotoneraSuperior} id="btnDividir" onClick={() => { this.ClickDividir() }} >Dividir</button>
                            <button className="btn" style={this.Estilos.BotoneraSuperior} onClick={() => { this.Redirigir('/revisar') }} >Combinar</button>
                            <button className="btn" style={this.Estilos.BotoneraSuperior} onClick={() => { this.ClickTransferir() }}>Trasladar</button>
                            <button className="btn" style={this.Estilos.BotoneraSuperior} onClick={() => { this.ClickAddNombre() }} >Nombre Cliente</button>
                            <button className="btn" style={this.Estilos.BotonesCliente} onClick={() => { this.ClickMenosCliente() }}>
                                <i className="fa fa-minus  fa-2x" aria-hidden="true"></i>&nbsp;
                                        </button>
                            <span style={this.Estilos.SpanFontSizeClientes}><i className="fa fa-user text-success" aria-hidden="true"></i><strong> {this.state.orden.numeroDeClientes}</strong></span>
                            <button className="btn" style={this.Estilos.BotonesCliente} onClick={() => { this.ClickMasCliente() }} ><i className="fa fa-plus-circle  fa-2x" aria-hidden="true"></i></button>
                        </div>
                        <hr/>
                        <div id="productos">
                            {
                                (this.state.isAnulada) ? (<center> <h1 style={this.Estilos.FuenteRoja}> Orden Anulada</h1></center>) : (null)
                        }
                            {
                                (this.state.cargandoProductos) ? (<div >
                                    <div className="spinner-border text-black" role="status"> </div>
                                    Cargando productos...
    
                                </div>) : (<div></div>)
                            }
                            {
                                (this.state.isPagado) ? (<center><img style={this.Estilos.SelloPagado} src={require('./Imagenes/selloPagado.png')} /> </center>) : (null)
                            } 
                            {
                                this.state.productosSeleccionados.map((item, i) => {
                                    if (item.tipomodif > 0) {
                                        return <button onClick={() => { this.ClickModificador(item) }} className="btn" style={this.Estilos.BotoneraProductos} >{item.desprod}</button>
                                    }
                                    else {
                                        //#RFD.26112019 cambio codprod por id
                                        return <button className="btn " style={this.Estilos.BotoneraProductos}
                                            onClick={() => { this.ClickVerDetalles(item.id) }}
                                            key={i}> {item.desprod} </button>

                                    }
                                })
                            }
                        </div>
                      
                    </div>
                    
                    <div className="column right" >

                        <div style={this.FullScreen}>

                            <textarea className="form-group" readOnly value={this.state.textoBoleta} style={this.TextareaBoleta}></textarea>
                            <div style={this.Estilos.FondoBlanco} >
                                <div style={this.Estilos.ContentSubtotal}>
                                    <center>  <p style={this.Estilos.FuenteTotal}><strong> Total: {(this.GetTotal()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</strong></p></center> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="footer" style={this.Estilos.FooterStyles}>
                    <button className="btn btn-danger" onClick={() => { this.ClickBorrarOrden() }} style={this.BtnSalir}><i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                    <button name="blokCambios" className="btn btn-dark" onClick={() => { this.setState({ activarModif: true }) }} style={this.Estilos.btnOptInferior}>Mensajes</button>
                    <button name="blokCambios" className="btn btn-dark" onClick={() => { this.setState({ activarCancelar: true })}} style={this.Estilos.btnOptInferior} >Modificar</button>
                    <button name="blokCambios" className="btn btn-dark" onClick={() => { this.setState({ activarTransferir: true }) }} style={this.Estilos.btnOptInferior}>Transferir</button>
                    <button name="blokCambios" className="btn btn-dark" onClick={() => { this.setState({ activarCantidad: true }) }}  style={this.Estilos.btnOptInferior} >Cantidad</button>
                    <button name="blokCambios" className="btn" style={this.Estilos.btnOptInferior} onClick={() => { this.ClickAnular() }} >Anular</button>
                    <button name="blokCambios" className="btn btn-dark" onClick={() => { this.ClickDescuentos() }} style={this.Estilos.btnOptInferior}  >Descuentos</button>
                    <button name="blokCambios" id="btnListo" className="btn btn-success" onClick={() => { this.DejarPendiente() }} style={this.Estilos.BtnGroup}
                        data-toggle="tooltip" data-placement="right" title="Listo">
                        <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
                    <button name="blokCambios" id="btnPagar" onClick={() => { this.ClickPagar() }} className="btn btn-success" style={this.Estilos.BtnGroup}
                        data-toggle="tooltip" data-placement="right" title={this.PayForm}></button>
                    <button name="blokCambios" id="btnNuevaOrden" onClick={() => { this.ClickNuevaOrden() }} className="btn btn-success" style={this.Estilos.BtnGroup}
                        data-toggle="tooltip" data-placement="right" title="Nueva Orden"><i className="fa fa-plus-square-o fa-2x" aria-hidden="true"></i></button>
                    <button name="blokCambios" id="btnImprimir" onClick={() => { Imprimir(this.state.orden.id) }} className="btn btn-success" style={this.Estilos.BtnGroup}
                        data-toggle="tooltip" data-placement="right" title="Imprimir"><i className="fa fa-print fa-2x" aria-hidden="true"></i></button>
                    
                </div>
            </div>
        );
    }
}
