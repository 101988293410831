import React from 'react';
import KeyboardedInput from 'react-touch-screen-keyboard';
import 'react-touch-screen-keyboard/lib/Keyboard.css'; // if you just want css
//import 'react-touch-screen-keyboard/lib/Keyboard.scss'; // if you've got sass-loader

class Keyboard extends React.Component {
    render() {
        return (
            <KeyboardedInput
                enabled
                type={this.props.type}
                onChange={this.props.onChange}
                value={this.props.value}
                min={this.props.min}
                max={this.props.max}
                step={this.props.step}
                name={this.props.name}
                inputClassName={this.props.inputClassName}
                keyboardClassName={this.props.keyboardClassName}
                placeholder={this.props.placeholder}
                defaultKeyboard="us"
                opacity={0.9} // optional 
                readOnly={this.props.readOnly}
            />
        );
    }
}
export default Keyboard;