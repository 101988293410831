import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from '../Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from '../Globales/VariableGlobales';
import { GetUserCookie } from '../Globales/Utilidades';
import swal from 'sweetalert';

import Popup from "reactjs-popup";
import { PopupAgregarFamilia } from '../MantenedorFamilias/PopupAgregarFamilia';
import { PopupEditarFamilia } from '../MantenedorFamilias/PopupEditarFamilia';

export class MantenedorFamilias extends Component {
    static displayName = MantenedorFamilias.name;

    StateInicial = {
        familias:[],
        //-----estados popup
        activarAgregarFamilia:false,
        activarAgregarUsuario: false,
        activarEditarModif: false,
        activarEditarFamilia: false,
        //------estados popup

        //------estados crear usuario
        tiposUsuarios: [],
        sucursales: [],
        cajas: [],
        cajasFiltradasSucursal: [],

        //------fin estados crear usuario

        //------estados editar usuario
        usuarioSeleccionado: {},
        familiaSeleccionada: {},
        //------fin estados editar usuario
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerFamilias();
    }

    //-------->INICIO FUNCIONES CRUD
    async TraerFamilias() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Familias?sucursalID=' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ familias: datos });
        }
    }

    
    //-------->FIN FUNCIONES CRUD

    Estilos = {
       
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
            'margin-left': '3%'
        },
        Space:
        {
            'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px'
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
    }



    //------->inicio onclick
    ClickAgregarUsuario() {
        this.setState({ activarAgregarUsuario: true });
    }

    ClickEditarUsuario(usuario) {
        this.setState({ usuarioSeleccionado: usuario, activarEditarFamilia: true });
    }

    async ClickListoEditar() {
        var modif = this.state.usuarioSeleccionado;
        modif.Desmod = document.getElementById('txtDescMod').value;
        modif.Habilita = 1;
        modif.IsProducto = false;
        modif.RelProdModID = null;
        modif.SucursalID = GetUserCookie().SucursalID;
        modif.UsuIngreso = GetUserCookie().username;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/' + modif.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(modif),
        });
        if (respuesta.ok) {
            swal('Modificador editado con exito', {
                icon: "success",
            }).then(() => {
                this.Recargar();
            });
        }
    }

    async ClickInhabilitarFamilia(familiaID) {
        console.log('el id es ' + familiaID);
        swal({
            title: "¿Está seguro que desea dehabilitar esta familia?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async(willDelete) => {
            if (willDelete) {
                var respuesta = await fetch(GetUrlApi() + '/api/Familias/' + familiaID, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (respuesta.ok) {
                    swal("Familia deshabilitada", {
                        icon: "success",
                    });
                    this.Recargar();
                }
            }
        });
    }

    ClickCrearFamilia() {
        this.setState({ activarAgregarFamilia:true });
    }

    ClickEditarFamilia(item) {
        this.setState({ activarEditarFamilia: true, familiaSeleccionada:item });
    }
    //------->fin onclick


    Recargar() {
        this.setState(this.StateInicial);
        this.TraerFamilias();
    }

    CerrarModalMaster() {
        this.setState({ activarAgregarFamilia:false });
    }

    render() {
        return (
            <div  >
                {
                    (this.state.activarAgregarFamilia) ? (
                        <PopupAgregarFamilia
                            Recargar={this.Recargar.bind(this)}
                            CerrarModalMaster={this.CerrarModalMaster.bind(this)}
                        />
                    ): (null)
                }
                {
                    (this.state.activarEditarFamilia) ? (
                        <PopupEditarFamilia
                            Recargar={this.Recargar.bind(this)}
                            CerrarModalMaster={this.CerrarModalMaster.bind(this)}
                            familiaSeleccionada={this.state.familiaSeleccionada}
                        />
                    ): (null)
                }
                <div className="alert alert-info" role="alert">
                    <strong> Familias</strong>

                </div>

                <button onClick={() => { this.ClickCrearFamilia() }} type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                    <i style={this.Estilos.Space} class="fa fa-plus-circle" aria-hidden="true"></i>
                    Agregar Familia
                        </button>


                <table className="table" style={this.Estilos.TableStyle}>
                    <thead>
                        <tr>
                            <td><b>Descripcion</b></td>
                            <td><b>Sucursal</b></td>
                            <td><b></b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.familias.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item.descripcion}</td>
                                        <td>{item.sucursal.descripcion}</td>
                                        <td>
                                            <button style={this.Estilos.BtnOpt} onClick={() => { this.ClickEditarFamilia(item) }} data-toggle="tooltip" data-placement="right" title="Editar" >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                            <button style={this.Estilos.BtnOpt1} onClick={() => { this.ClickInhabilitarFamilia(item.id) }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                <i class="fa fa-trash " aria-hidden="true"></i></button>

                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

            </div>
        );
    }


}
