import React, { Component } from 'react';

export default class LogoLeanPos extends Component {
    constructor(props) {
        super(props);
    }

    Estilos = {
        mySpinnerBg: {
            'position': 'absolute',
            'backgroundColor': '#000000a1',
            'zIndex': '1',
            'right': '0',
        },
        mySpinner: {
            'top': '45%',
            'position': 'absolute',
        }
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-middle w-100 h-100" style={this.Estilos.mySpinnerBg}>
                <div style={this.Estilos.mySpinner}>
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }
}


