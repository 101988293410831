import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import { GetUrlApi } from './Globales/VariableGlobales';
import UserProfile from './Globales/PerfilUsuario';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';
import { Card, Button, CardBody, CardHeader, Table, Form, CardFooter } from 'reactstrap';

export class IngresoCheque extends Component {
    static displayName = IngresoCheque.name;
    ParentComp = '';
    UltimoMedioAgregado = {};
    totalCheques=0;
    saldoPago = 0;
    sumCheques=0;
    datoscliente=[];
    cheques=[];
    constructor(props) {
        super(props);
        this.state = {
            mostrarTotal: false, 
            mensaje: '',
            medioIngresado: {},
            bancos:[],
            chequesAdd:[],
            plazas:[]
           
           
        }
     
        this.UltimoMedioAgregado = props.UltimoMedioAgregado;
        this.totalCheques=props.totalCheques;
        this.saldoPago = props.totalCheques;
        this.datoscliente =props.ClientData;
 
        if (this.props.CerrarModalMaster)
            this.CerrarModalMaster = this.props.CerrarModalMaster;
 
        if (this.props.ActualizarEstadoPagar) 
            this.ActualizarEstadoPagar = this.props.ActualizarEstadoPagar;
        if (this.props.VolverToPagar)
            this.VolverToPagar = this.props.VolverToPagar;
        if (this.props.ActualizarTexto)
            this.ActualizarTexto = this.props.ActualizarTexto;
        this.cheques=props.Cheques;
    }


    componentDidMount() {
        console.log(GetUserCookie().Sucursal.configPOS);
        this.setState({chequesAdd:this.cheques});
        if (this.UltimoMedioAgregado) {
            this.setState({ medioIngresado: this.UltimoMedioAgregado });
        }
 
        document.getElementById('nomTitular').value=this.datoscliente.nombre;
        document.getElementById('rutCheque').value=this.datoscliente.rut;
        document.getElementById('fecCheque').valueAsDate = new Date();
        this.TraerBancos();
        this.TraerPlazasSucursales();


        var montoIngresados=0;
  
        for (var i=0; i < this.cheques.length;i++){
                var item = this.cheques[i];
                montoIngresados += Number(item.Monto);
        }
        this.sumCheques=montoIngresados;
        this.saldoPago = this.saldoPago - montoIngresados;
    }
    VolverToPagar() { }

Estilos = {
    PopUpStyleAddCheque: {
        'background': 'rgb(255, 255, 255)',
        'margin': 'auto',
        'border': '1px solid rgb(187, 187, 187)',
        'borderRadius': '8px',
        'width': '50%',
        'height': '85%',
        'overflow':'auto'
    } ,
    PTotalesOrdenD:
        {
            'fontSize': '18px',
            'marginBottom': '0%'
        }
}

ActualizarTexto() { 
}
CerrarModalMaster() {
}
    async  TraerBancos() {
        var respuesta = await fetch(GetUrlApi() + '/api/Bancos/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var bancos = await respuesta.json();
            this.setState({ bancos: bancos });

        }
    }


    async  TraerPlazasSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Plazas/GetPlazas?id=0&codplaza=0', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var sucs = await respuesta.json();
            this.setState({ plazas: sucs });

        }
    }

    DesactivarModales() {
        //this.setState({  cheques:[] });
    }


  GuardaCheques(){
        if (this.cheques.length == 0){
            swal({
                title: "ERROR !",
                text: "Debe ingresar al menos un cheque para guardar la forma de pago",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }

        try {//si se agrega un pago en Pagar.js entra aqui
            var numOp;
            var numCheque = null;
            var idBanco = null;
            var numTarjeta = null;
            var puntos = 0;
            var cantidad = 0;

            if (!numOp) {
                numOp = null;
            }
            
            var cuentaCasaID = this.props.cuentaCasaID;
            if (!this.props.cuentaCasaID) {
                cuentaCasaID = null;
            }
            for (var i=0; i < this.cheques.length;i++){
                var item = this.cheques[i];
                cantidad += Number(item.Monto);
               
            }
            console.log(' '+cantidad+', '+ numOp+', '+ cuentaCasaID+', null, null, '+numCheque+', '+ numTarjeta+', '+idBanco);

            this.props.AgregarPago(cantidad, numOp, cuentaCasaID, null, null, numCheque, numTarjeta, idBanco,puntos,this.cheques);
        } catch (err) {

            console.log(err);
        }
       /* try {
            this.props.ActualizarEstado({ propinaPorc: cantidad });
            this.props.ActualizarEstadoPagar({ propinaPorc: cantidad });
        } catch (err) {

        }
        try {
            var neworden = this.props.orden;
            neworden.propinaPorc = cantidad;
            this.props.ActualizarEstado({ orden: neworden });
        } catch (err) {

        }*/
        this.ActualizarTexto();
        this.CerrarModalMaster();


  }

    formateaRut(id) {
        var rut = document.getElementById(id).value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            document.getElementById(id).value = rutSinPuntosD;
            return rutPuntos;
        }
    }

    checkRut(rut) {
        // Despejar Puntos
        var valor = rut.replaceAll('.', '');
        // Despejar Guión
        valor = valor.replace('-', '');

        // Aislar Cuerpo y Dígito Verificador

        const cuerpo = valor.slice(0, -1);
        let dv = valor.slice(-1).toUpperCase();

        // Formatear RUN
        rut = cuerpo + '-' + dv

        // Si no cumple con el mínimo ej. (n.nnn.nnn)
        if (cuerpo.length < 7) { return false; }

        // Calcular Dígito Verificador
        let suma = 0;
        let multiplo = 2;

        // Para cada dígito del Cuerpo
        let index = 0;
        for (let i = 1; i <= cuerpo.length; i++) {

            // Obtener su Producto con el Múltiplo Correspondiente
            index = multiplo * valor.charAt(cuerpo.length - i);

            // Sumar al Contador General
            suma = suma + index;

            // Consolidar Múltiplo dentro del rango [2,7]

            if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

        }

        // Calcular Dígito Verificador en base al Módulo 11
        const dvEsperado = 11 - (suma % 11);

        // Casos Especiales (0 y K)
        dv = (dv == 'K') ? 10 : dv;
        dv = (dv == 0) ? 11 : dv;

        // Validar que el Cuerpo coincide con su Dígito Verificador
        if (dvEsperado != dv) { return false; }

        // Si todo sale bien, eliminar errores (decretar que es válido)

        return true;
    }

     
    checkRutAddClient(rut){
        let valueRut = document.getElementById(rut).value;
        if(!this.checkRut(valueRut)){
            swal({
                title: "ERROR !",
                text: "RUT Inválido!",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }
        if(valueRut.indexOf("-") == -1){
            valueRut = valueRut.substring(0,valueRut.length-1) + "-" + valueRut.substring(valueRut.length,valueRut.length-1);
            document.getElementById(rut).value=valueRut;
        }
    }
    
    uppercaseName(inputInfo){
        let valueRut = document.getElementById(inputInfo).value;
        document.getElementById(inputInfo).value=valueRut.toUpperCase();

    }
    limpiarTexto (){
        document.getElementById('codBanco').selectedIndex=0;
         document.getElementById("tipoCheque").selectedIndex=0;
         document.getElementById('amount').value=0;
         document.getElementById('numCheque').value=0;
         document.getElementById('serie').value=0;
         document.getElementById('numCuenta').value=0;
    }
    async AgregarCheque (){
        let tipoCheque = document.getElementById('tipoCheque').value;
        let rut = document.getElementById('rutCheque').value;
        var arrcheques = Object.create(this.cheques).__proto__;
        if (!this.checkRut(rut)) {
            swal({
                title: "ERROR !",
                text: "RUT Inválido!",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }
        
        let banco = document.getElementById('codBanco').value;
        let t = document.getElementById("codBanco");
        let descBanco = t.options[t.selectedIndex].text; 
        let d = document.getElementById("tipoCheque");
        let descCheque = d.options[d.selectedIndex].text; 
        let codplaza = document.getElementById("codPlaza").value;
        let nombreTitular = document.getElementById('nomTitular').value;
        let monto = document.getElementById('amount').value;
        let numCheque = document.getElementById('numCheque').value;
        let fecCheque = document.getElementById('fecCheque').value;
        let serie = document.getElementById('serie').value;
        let numCta = document.getElementById('numCuenta').value;
        if (tipoCheque !== '' &&
                rut !== ''
                && banco !== '' &&
                monto !== '' &&
                numCheque !== ''  &&
                nombreTitular !== ''   &&
                serie !== ''  && 
                codplaza !=='' && 
                numCta !==''  ) {
            
            if (tipoCheque==1){
                if (fecCheque==''){
                    swal({
                        title: "ERROR !",
                        text: "Debe ingresar la fecha del Cheque",
                        icon: "error",
                        button: "Entendido !",
                    });
                    return;
                }
                 
                var d1 = new Date();
                d1.setHours(0,0,0,0);
                
                const [year, month, day] = fecCheque.split('-');
                var d2 = new Date(+year, month - 1, +day);

                if (d2 < d1){
                    swal({
                        title: "ERROR !",
                        text: "La fecha del cheque no puede ser inferior al día actual",
                        icon: "error",
                        button: "Entendido !",
                    });
                    return;
                }
            }       
            if (monto <= 0 ){
                swal({
                    title: "ERROR !",
                    text: "El monto del cheque no puede ser inferior o igual a 0.",
                    icon: "error",
                    button: "Entendido !",
                });
                return; 
            }

            if (monto > this.totalCheques){
                swal({
                    title: "ERROR !",
                    text: "El monto del cheque no puede ser superior al monto asignado para Cheques",
                    icon: "error",
                    button: "Entendido !",
                });
                return;
            }    
            
            var montoIngresados=0;
            
            for (var i=0; i < this.cheques.length;i++){
                var item = this.cheques[i];
                montoIngresados += Number(item.Monto);
                if(item.RutTitular == rut.split('-')[0] && item.CodigoBanco == banco && item.IDTipoCheque == tipoCheque && item.NumCheque==numCheque){
                    swal({
                        title: "ERROR !",
                        text: "No puede agregar el mismo cheque.",
                        icon: "error",
                        button: "Entendido !",
                    });
                    return;
                    
                }
            }
             
            if (Number(montoIngresados) + Number(monto) > this.totalCheques){
            
                swal({
                    title: "ERROR !",
                    text: "El monto del cheque no puede ser superior al monto total de la venta",
                    icon: "error",
                    button: "Entendido !",
                });
                return;
            } 

            var chequeAdd = {};          
            chequeAdd.CodigoBanco = banco;
            chequeAdd.DescBanco =descBanco;
            chequeAdd.IDTipoCheque = tipoCheque;
            chequeAdd.DescTipoCheque= descCheque;
            chequeAdd.RutTitular = rut.split('-')[0];
            chequeAdd.DvTitular = rut.split('-')[1];
            chequeAdd.Monto = monto;
            chequeAdd.NumCheque = numCheque;
            chequeAdd.FechaCheque = fecCheque;
            chequeAdd.Serie = serie;
            chequeAdd.NombreTitular = nombreTitular;
            chequeAdd.Sucursal = codplaza;
            chequeAdd.NroCta = numCta;
            this.saldoPago = Number(this.saldoPago) - Number(monto);
            this.sumCheques += Number(monto);
            arrcheques.push(chequeAdd);     
            this.cheques= arrcheques  ;
            this.setState ({chequesAdd:arrcheques});
            this.limpiarTexto();
        }
        else {
            swal({
                title: "ERROR !",
                text: "Falta información por ingresar!",
                icon: "error",
                button: "Entendido !",
            });
    }
}

maxLengthCheck(id) {
    var object =  document.getElementById(id);
    if (object.value.length > object.maxLength)
      object.value = object.value.slice(0, object.maxLength)
  }


    render() {
        return (
            <Popup
            modal
            id='IngresoChequesPop'
            onClose={() => { this.CerrarModalMaster() }}
            defaultOpen
            contentStyle={this.Estilos.PopUpStyleAddCheque}
            closeOnDocumentClick={false}
          >
              <button className="d-inline-flex close justify-content-end" onClick={() => { this.CerrarModalMaster() }}>
                    &times;
                </button>
            <CardHeader className="bg-info text-white"><strong>Ingresar Datos Cheque - Saldo Venta : {GetPrecioFormateado(this.saldoPago)}</strong></CardHeader>
            <Form>
                <CardBody id="modCliente" className="form form-group">
                
                <div className="row"  >     
                            <div className="form-group">     
                                <label>Tipo Cheque: </label>
                                <select className="form-control" id="tipoCheque" type="text" >
                                    <option value='2'>Cheque al día</option>
                                    <option value='1'>Cheque a Fecha</option>
                                </select>
                            </div>       
                        </div>
                    <Card className="form form-group">
                        <div className="row">
                            <div className="col">
                                <label>Rut Titular: </label>
                                <input type="text" id="rutCheque"  placeholder="RUT (12345678-9)"
                                    className="form-control" 
                                    onChange={(val) => {
                                        this.formateaRut('rutCheque');
                                    }}  
                                    onBlur = {(val)=>{
                                        this.checkRutAddClient('rutCheque');
                                    }}
                                    maxLength="10" minLength="9"  />
                            </div>
                            <div className="col">
                                <label>Nombre Titular: </label>
                                <input type="text" id="nomTitular" name="nomTitular" className="form-control"  onBlur = {(val)=>
                                    {this.uppercaseName('nomTitular');}} onChange={(val) => {
                                            this.maxLengthCheck('nomTitular');
                                        }} maxlength = "40"/>
                            </div> 
                            <div className="col">
                                <label>Banco: </label>
                                <select className="form-control" id="codBanco" type="text" >
                                                {
                                                    
                                                    this.state.bancos.map((item) => {
                                                        return (<option value={item.codigo}>{item.descripcion}</option>);
                                                    })
                                                }
                                </select>
                            </div>

                        
                            
                        </div>
                        <div className="row"  >
                            <div className="col">
                                <label>Monto: </label>
                                <input type="number" id="amount"   className="form-control"  />
                            </div>
                            <div className="col">
                                <label>N° Cheque: </label> 
                                <input type="number" id="numCheque"  className="form-control"  onChange={(val) => {
                                            this.maxLengthCheck('numCheque');
                                        }} maxlength = "10"/>
                            </div>
                             <div className="col">
                                <label>Fecha Cheque: </label>
                                <input type="date" id="fecCheque"  className="form-control"  />
                            </div>
                           
                         </div>
                         <div className="row"  >
                              <div className="col">
                                <label>Serie: </label>
                                <input type="text" id="serie" onChange={(val) => {
                                            this.maxLengthCheck('serie');
                                        }} maxlength = "9"  className="form-control" />
                              </div>
                              <div className="col">
                                <label>N° Cuenta: </label> 
                                <input type="number" id="numCuenta" onChange={(val) => {
                                            this.maxLengthCheck('numCuenta');
                                        }} maxlength = "15"  className="form-control" />
                            </div> 
                              <div className="col">
                                    <label>Plaza/Sucursal: </label>
                                    <select className="form-control" id="codPlaza" type="text" >
                                                {
                                                    
                                                    this.state.plazas.map((item) => {
                                                        return (<option value={item.codigoPlaza}>{item.nombrePlaza}</option>);
                                                    })
                                                }
                                 </select>             
                              </div>     
                             
                              <div className="col">
                                <br></br>
                                <p style={this.Estilos.PTotalesOrdenD}> <strong>Saldo Venta :  {GetPrecioFormateado(this.saldoPago)}</strong></p>
                                </div>             
                         </div>
                         <br></br>
                         <div className="form-group">   
                            <div className="row  pt-2 justify-content-center">
                                <div className="d-inline-flex ml-2 mr-2 mb-2">
                                    <button id="AgregarCheque"    className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarCheque() }} ><strong>Agregar Cheque <i class="fa fa-plus-circle" aria-hidden="true"></i></strong></button>
                                </div>
                            </div>
                        </div>
                       
                </Card>

                <Card>
                    <div className="ml-2" style={this.Estilos.DivProd}>
                                <div className="row bg-info text-white">
                                                        <div className="col" ><strong>Tipo Cheque</strong></div>
                                                        <div className="col"><strong>N° Cheque</strong></div> 
                                                        <div className="col" ><strong>Monto</strong></div>
                                                        <div className="col"><strong>Banco</strong></div>
                                </div>


                                {
                                        this.state.chequesAdd.map((item, index) => {
                                            let id= item.NumCheque+'' + index;
                                   
                                                return (
                                                    <div className="row"  id={id}>
                                                        <div className="col">{item.DescTipoCheque}</div>
                                                        <div className="col">{item.NumCheque}</div>
                                                        <div className="col" >{GetPrecioFormateado(item.Monto)}</div>
                                                        <div className="col">{item.DescBanco} </div>
                                                    </div>

                                                );
                                            }
                                        )
                                }
                    </div>   
                    <CardFooter>
                    <div className="row  pt-2 justify-content-center">
                                <div className="d-inline-flex ml-2 mr-2 mb-2">
                                    <label ><p style={this.Estilos.PTotalesOrdenD}> <strong>Total Cheques : {GetPrecioFormateado(this.sumCheques)}</strong></p></label>
                                </div>
                            </div>
                </CardFooter>       
                </Card>
                    <div className="row pt-2 justify-content-center">
                        <div className="d-inline-flex ml-2 mr-2 mb-2">
                            <button id="GrabaCheques"   className="btn btn-info form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.GuardaCheques() }} ><strong>Guardar Cheque(s)</strong></button>
                        </div>
                        <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.CerrarModalMaster() }}><strong>Cancelar</strong></button>
                            </div>
                    </div>
                </CardBody>
            </Form>
        </Popup>
        )

    }


}