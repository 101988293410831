import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import { GetUrlApi } from './Globales/VariableGlobales';
import UserProfile from './Globales/PerfilUsuario';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';
import Swal from "sweetalert2";
export class TecladoNumerico extends Component {
    
    static displayName = TecladoNumerico.name
    ParentComp = ''
    TotalPagarMaster
    Numero
    IngresoUsuario = false
    Url = ''
    TipoTexto = "number"
    isAutorizeCodeSelected = false
    isCuotasSelected = false
    IsCodOpSelected = false
    IsMontoSelected = true
    IsEfectivo = false
    NumCaja = 1
    CodigoOperacion = ''
    autorizeCode = ''
    cuotasNumber = 0
    IsContPersonas = false
    IsAutorizacion = false
    MotivoAnulacion = ''
    isGarantiaEvento = false
    UltimoMedioAgregado = {}
    TotalOrdenDesc = 0
    TextoIngresadoNum = ''
    DescItem = {}
    CodAut = ''
    limitDescItem = 0
    descItemEfec = false
    constructor(props) {
        super(props);
        this.state = {
            mostrarTotal: false,
            total: 0,
            mensaje: '',
            activarRedireccion: false,
            redirigirToRevisar: false,
            redirigirToMesas: false,
            activosMotivosAnulacion: false,
            Motivos: [],
            medioIngresado: {},
            bancos:[],
            ErrorExists:false,
            reqAuth: false,
            reqAuthVal: false,
            maxDescItem: localStorage.getItem('maxDescItem')
        }
        this.Numero = '';
        this.DescItem = props.DescItem;
        this.IsEfectivo = props.IsEfectivo;
        this.TotalOrdenDesc = props.TotalOrdenDesc;
        this.limitDescItem = props.limitDescItem;
        this.descItemEfec  = props.descItemEfec;
        
        //console.log('Total Orden de descuento');
        //console.log(this.TotalOrdenDesc);
        this.IngresoUsuario = props.ingresoUsuario;
        if (this.IngresoUsuario) {
            this.Estilos.EstiloPopup.width = '45%';
            this.TipoTexto = 'password';
        }
        URL = props.url;
        if (this.props.CerrarModalMaster)
            this.CerrarModalMaster = this.props.CerrarModalMaster;
        if (this.props.total) {
            this.TotalPagarMaster = this.props.total;
         //   document.getElementById('PINbox').value = this.TotalPagarMaster;
        }
        if (this.props.ActualizarEstadoPagar) 
            this.ActualizarEstadoPagar = this.props.ActualizarEstadoPagar;
        if (this.props.VolverToPagar)
            this.VolverToPagar = this.props.VolverToPagar;
        if (this.props.ActualizarTexto)
            this.ActualizarTexto = this.props.ActualizarTexto;
        if (this.props.isGarantiaEvento)
            this.isGarantiaEvento = this.props.isGarantiaEvento;
        this.IsCodOpSelected = false;
        this.IsCodOpSelected = props.IsContPersonas;
        this.UltimoMedioAgregado = props.UltimoMedioAgregado;
        if (this.UltimoMedioAgregado) {
            if (this.UltimoMedioAgregado.isCheque)
             this.TraerBancos();
        }
        this.IsAutorizacion = props.IsAutorizacion;
        
        document.onkeypress = (e) => {
            
            var element = document.getElementById('PINbox');
            
           if (e.key === 'd' || e.key==='D'){
            this.DeleteChar();
            document.getElementById('PINbox').focus();
            return;
           }
            if (element!=null) {
                var val = document.getElementById('PINbox').value;
                if (val.length >= 6 && val.length <= 9) {
                    document.getElementById('PINbox').style.fontSize = "3em";
                } else if (val.length > 10 && val.length <= 20) {
                    document.getElementById('PINbox').style.fontSize = "1.5em";

                }
                else if (val.length > 20 && val.length) {
                    document.getElementById('PINbox').style.fontSize = "0.5em";

                } else if (val.length < 6) {
                    document.getElementById('PINbox').style.fontSize = "4em";
                }
                 if (!this.IsCodOpSelected && !this.isAutorizeCodeSelected && !this.isCuotasSelected && this.IsMontoSelected && !this.state.reqAuth) {
                
                    if (e.key === 'Enter') {
                       
                        //if (val!="") {
                        //    var dreplace = val.replaceAll('.', '');
                        //    this.Numero = dreplace;
                        //}
                        //setTimeout(() => { this.ClickSelectCantidad() }, 1000);
                        this.ClickSelectCantidad();

                        //this.props.CerrarModalMaster();
                        //var modales = document.getElementById("TecladoNumericoD");
                        //modales.style.visibility = "hidden";
                    }
                    else if (e.key === '1') {

                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '2') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '3') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '4') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '5') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '6') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '7') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '8') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '9') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;
                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '0') {
                        this.Numero += e.key;
                        document.getElementById('PINbox').value += e.key;

                        document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === 'c' || e.key === 'C') {
                        this.ClickCE();
                    }
                }else if(this.isCuotasSelected) {
                    if (e.key === '1') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '2') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '3') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '4') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '5') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '6') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '7') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '8') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '9') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '0') {
                        this.cuotasNumber = this.cuotasNumber === 0? '':this.cuotasNumber;
                        this.cuotasNumber += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;

                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    }
                    else if(e.key==='Enter'){
                        this.ClickSelectCantidad();
                    }
                }
                else if(this.isAutorizeCodeSelected) {
                    if (e.key === '1') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '2') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '3') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '4') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '5') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '6') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '7') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '8') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '9') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;
                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    } else if (e.key === '0') {
                        this.autorizeCode += e.key;
                        // document.getElementById('txtCuotas').value = this.cuotasNumber;

                        // document.getElementById('txtCuotas').value =/*'$'+*/ Number(this.cuotasNumber).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                    }
                    else if(e.key==='Enter'){
                        
                        this.ClickSelectCantidad();
                    }
                } else if(this.state.reqAuth && !this.state.reqAuthVal) {
                    if (e.key === 'Enter') {
                        this.ValidarUsuario()
                    }
                    else if (e.key === '1') {
                        this.ClickTecladoCantidadAut(1)

                    } else if (e.key === '2') {
                        this.ClickTecladoCantidadAut(2)
                    } else if (e.key === '3') {
                        this.ClickTecladoCantidadAut(3)

                    } else if (e.key === '4') {
                        this.ClickTecladoCantidadAut(4)

                    } else if (e.key === '5') {
                        this.ClickTecladoCantidadAut(5)

                    } else if (e.key === '6') {
                        this.ClickTecladoCantidadAut(6)

                    } else if (e.key === '7') {
                        this.ClickTecladoCantidadAut(7)

                    } else if (e.key === '8') {
                        this.ClickTecladoCantidadAut(8)

                    } else if (e.key === '9') {
                        this.ClickTecladoCantidadAut(9)

                    } else if (e.key === '0') {
                        this.ClickTecladoCantidadAut(0)

                    } else if (e.key === 'c' || e.key === 'C') {
                        this.ClickCED();
                    }
                }
            }
            
            
        };
        

    }
    ElementBlur(e) {
        document.getElementById(e).blur();
    }
    
    componentDidMount() {  
            // cuotasElement.val('3');
        if (this.UltimoMedioAgregado) {
            this.setState({ medioIngresado: this.UltimoMedioAgregado });
        }
        if (this.IsEfectivo) {
            this.Estilos.EstiloPopup.width = '30%';
        }
        if (this.IsAutorizacion) {
            document.getElementById('PINbox').type = 'password';
            this.setState({ mensaje:'El gerente debe autorizar esta operación' });
        }
        if (this.props.descEfectivo) {
            this.setState({ mensaje:'Ingrese Monto en Efectivo a Descontar' });
        }
        if (this.TotalPagarMaster > 0 && !this.IsEfectivo) {
            document.getElementById('PINbox').value = this.RedondearValor(this.TotalPagarMaster).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            this.Estilos.EstiloPopup.height = '70%';
        }
        if (this.props.mostrarTotal && !this.IsEfectivo) {
            this.Numero = this.RedondearValor( this.props.total);
            const montoInicio = this.props.total.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            this.setState({ mostrarTotal: true, total: montoInicio });
        }
        if (this.props.propinaDif) {
            this.setState({ mensaje: 'Total recibido' });
        }
        if (this.props.saldoPuntos){
            this.setState({ mensaje: 'Ingrese Puntos a utilizar' });
        }
        if(this.props.title){
            this.setState({ mensaje: this.props.title })
            document.getElementById('PINbox').value = 0
        }
        try{document.getElementById('PINbox').focus()}catch(e){}     
    }
    
   async  TraerBancos() {
        var respuesta = await fetch(GetUrlApi() + '/api/Bancos/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var bancos = await respuesta.json();
            this.setState({ bancos: bancos });

        }
    }

    RedondearValor(monto) {
        if (this.IsEfectivo) {
            if (monto > 0) {
                const resto = monto % 10;
                if (resto > 0 && resto <= 5) {
                    return monto - resto;
                }
                else if (resto > 5 && resto < 10) {
                    return monto + (10 - resto);
                }
            }
            else {
                return monto;
            }
        }
        return monto
    }

    ClickTecladoCantidad2(num) {
        var val =document.getElementById('PINbox').value;
        if (val.length>=6 && val.length<=9) {
            document.getElementById('PINbox').style.fontSize = "3em";
        } else if (val.length > 10 && val.length <= 20) {
            document.getElementById('PINbox').style.fontSize = "1.5em";

        }
        else if (val.length > 20 && val.length) {
            document.getElementById('PINbox').style.fontSize = "0.5em";

        } else if (val.length < 6) {
            document.getElementById('PINbox').style.fontSize = "4em";
        }

        if (this.IsCodOpSelected) {
            this.CodigoOperacion += num;
            document.getElementById('numOperacion').value = Number(this.CodigoOperacion).toString();
        }
        else if(this.isAutorizeCodeSelected) {
           // this.autorizeCode = document.getElementById('txtAutorizeCode').value.toString() ;
            this.autorizeCode += num.toString()
            document.getElementById('txtAutorizeCode').value = Number(this.autorizeCode).toString()
        }
        else if(this.isCuotasSelected) {
            //this.cuotasNumber = Number(this.cuotasNumber).toString() + num
            //document.getElementById('txtCuotas').value = Number(this.cuotasNumber).toString()

           // this.cuotasNumber = document.getElementById('txtCuotas').value.toString() ;
            this.cuotasNumber += num
            document.getElementById('txtCuotas').value = Number(this.cuotasNumber).toString()
        }
        else {
            
            this.Numero = this.Numero.toString().replaceAll('.','') +  num;
            //document.getElementById('PINbox').type = 'text';
            document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        }
        document.getElementById('PINbox').focus();
    }

    ClickTxtMonto() {
        this.resetSelectedText()
        this.IsMontoSelected = true
        document.getElementById('PINbox').focus()
    }

 

    async ClickSelectCantidad() {
        //const cantidad = document.getElementById('PINbox').value;
        if(this.state.ErrorExists){
            return;
        }
        var codOperacion;
        
        if (document.getElementById('numOperacion'))
         codOperacion = document.getElementById('numOperacion').value;
        let errMsg = ''
        let errNumber = 0;
        const codeAutorizeElement = document.getElementById('txtAutorizeCode')
        if (codeAutorizeElement){
            if(codeAutorizeElement.value === ''){
                errMsg = 'Debe ingresar el código autorización'
                errNumber=1;
            }
          

        }
        const cuotasElement = document.getElementById('txtCuotas')
        if(cuotasElement && errMsg === '') {
            if(cuotasElement.value === '' || cuotasElement.value === '0'){
                errMsg = 'Debe ingresar Nro de Cuotas';
                errNumber=2;
            }
            
        }
        if (this.props.isNumOperacion && codOperacion === '') {
            errMsg = 'Debe ingresar el código de operación'
        }
        if (Number(this.Numero) <= 0 && !this.DescItem) { //para eliminar descuentos de convenio/oferta
            errMsg = 'El monto debe ser mayor a $0';
            errNumber=3;
        }

        if (!this.IsEfectivo){
            if (Number(this.Numero) > this.TotalPagarMaster){
                errMsg='El monto a pagar  no puede ser superior al Saldo a pagar de la Venta.';
                errNumber=4;
            }
        }
        if(Number(this.Numero) > this.limitDescItem){
            errMsg = 'El monto no debe superar el valor a pagar'
            errNumber = 5
        }
        
        if(this.DescItem){
            if (this.descItemEfec){
                let amountMax = (Number(this.limitDescItem) * Number(this.state.maxDescItem)) / 100

                if(Number(this.Numero) > amountMax){
                    errMsg = 'El monto no debe superar el maximo de descuento configurado (' + this.state.maxDescItem + '%)' 
                    errNumber = 6
                } 
            }
            else{
                if(Number(this.Numero) > Number(this.state.maxDescItem)){
                    errMsg = 'El monto no debe superar el maximo de descuento configurado (' + this.state.maxDescItem + '%)' 
                    errNumber = 6
                } 
            }
            
        }
       
       
        if(errMsg !='') {
            this.setState({ErrorExists:true});
            swal({
                title: errMsg,
                icon: "warning",
                dangerMode: true,
            }).then(() => {
                
                switch (errNumber){
                    case 1: 
                        document.getElementById('txtAutorizeCode').focus();
                        break;
                    case 2:
                        document.getElementById('txtCuotas').focus();
                        break;  
                    case 5:
                        break;
                    default:
                        document.getElementById('PINbox').focus();
                        break;  
                }
                this.setState({ErrorExists:false});
            });
          /*swal( 
                errMsg  
              );*/
              return;
        }
        else {
            

            //if (this.TextoIngresadoNum != '') {
            //    this.Numero = this.TextoIngresadoNum;
            //    this.CerrarModalMaster();
            //}
            
            const cantidad = Number(this.Numero.toString().replaceAll('.',''));

            //if (this.TextoIngresadoNum!='') {
            // const   cantidad = Number(this.TextoIngresadoNum);
            //}

            console.log('la cantidad ' + cantidad);

           if (this.props.cantidad) {
                this.props.ActualizarEstado({ cantidad: cantidad });
                this.CerrarModalMaster();
            }
            else if (this.props.propinaCant) {
                var neworden = this.props.orden;
                neworden.propinaCant = cantidad;
                neworden.propinaPorc = 0;
                neworden.propinaDiff = 0;

                //     if (this.props.ActualizarEstado)
                //       this.props.ActualizarEstado({ orden: neworden });//actualiza paraLlevar
                if (this.props.ActualizarEstadoPagar)
                    this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar

            }
            else if (this.props.propinaDif) {
                var neworden = this.props.orden;
                neworden.propinaCant = 0;
                neworden.propinaPorc = 0;
                neworden.propinaDiff = cantidad;

                // this.props.ActualizarEstado({ orden: neworden });//actualiza paraLlevar
                this.props.ActualizarEstadoPagar({ orden: neworden });//actualiza pagar
            }
            else if (this.props.descEfectivo) {
                var porcDescuento = 0;
                if (this.TotalOrdenDesc!=0) {
                    //porcDescuento = Math.Round(Number((cantidad * 100) / this.TotalOrdenDesc));
                    var totalT = Number(this.TotalOrdenDesc);
                    var cantidadT = Number(cantidad);

                    porcDescuento = (cantidadT * 100) / totalT;
                    porcDescuento = Math.round(porcDescuento);
                }


              
                var neworden = this.props.orden;
                neworden.descEfectivo = cantidad;
                neworden.dctoEfec = cantidad;
                this.props.ActualizarEstado({ orden: neworden, IsDescuentoRealizado: true });//actualiza paraLlevar
                this.ActualizarEstadoPagar({ orden: neworden, IsDescuentoRealizado: true  });//actualiza pagar
                if (this.VolverToPagar)
                    this.VolverToPagar();
                if (this.props.CerrarModal) {
                    this.props.CerrarModal();
                }
            }
            else if (this.IngresoUsuario) {
                var ruta = this.props.url;
                //this.Url = ruta;
                this.Url = "/revisar";
                const urlApi = GetUrlApi() + '/api/Usuarios/' + cantidad + '?pin=true';
                const usuario = await fetch(urlApi);
                if (usuario.ok) {
                    var datos = await usuario.json();
                    const nombre = datos.nombre + ' ' + datos.apellido;
                    ruta += '?user=' + datos.id;
                    URL += '?user=' + datos.id;

                    var usuarioSesion = UserProfile.getUser;
                    usuarioSesion.id = datos.id;
                    usuarioSesion.username = datos.nombre + " " + datos.apellido;
                    usuarioSesion.tipoUsuario = datos.tipoUsuarioID;
                    usuarioSesion.CajaID = 1;
                    
                    

                    var data = await fetch(GetUrlApi() + '/api/Cajas/' + this.NumCaja);
                    data =await data.json();
                    data.idUsuarioFk = datos.id;
                    var respuesta = await fetch(GetUrlApi() + '/api/Cajas/' + this.NumCaja, {
                        method: 'put',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });
                    if (respuesta.ok) {
                        usuarioSesion.idGaveta = data.idUltimaGavetaFk;
                    }

                    UserProfile.setUser(usuarioSesion);
                    
                    this.setState({ redirigirToRevisar: true });
                    this.props.CerrarModalMaster();
                    //this.props.actualizarEstado({ urlRedirijir: URL, activarRedireccion: true, usuarioIngresado: datos.id });
                }
                else {
                    swal('Usuario no valido');
                }
            }
            else if (this.IsAutorizacion) {
                
                var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + cantidad + '?pin=true', {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (respuesta.ok) {
                    var user = await respuesta.json();
                    if (user.tipoUsuarioID === 1 || user.tipoUsuarioID === 2) {
                        if (this.props.param) {
                            var productoEnviado = this.props.param2;//contiene el item a borrar
                            if (!productoEnviado.isGuardado) {
                                this.setState({ activosMotivosAnulacion: true });
                            }
                            else {
                                const parametroRecibido = (this.props.param - 1);
                                this.props.ValidarCierre(parametroRecibido, this.MotivoAnulacion);
                            }
                        }
                        else {
                            this.props.ValidarCierre();
                        }

                    }
                    else {
                        swal('Perfil de usuario no valido');
                    }

                }
                else {
                    swal({
                        title: "Notificación",
                        text: "Usuario invalido",
                        icon: "danger",
                        dangerMode: true,
                    })
                }
               
            }
            else if (this.isGarantiaEvento) {
                const tieneFactura = this.props.tieneFactura;
                const numInfo = this.props.numInfo;
                this.props.AgregarPago(cantidad, null, null, tieneFactura, numInfo);
                this.props.CerrarModalMaster();
            }
            else if (this.props.cambioPrecio) {
                var neworden = this.props.orden;
                console.log(neworden);

                neworden.carroProductos[this.props.IndiceProducto].precpred = cantidad;
                this.props.ActualizarEstado({ orden: neworden });//actualiza pagar
                this.props.CerrarModalMaster();

            }
            else if(this.DescItem){
                console.log('**********************************************')
                console.log('**********Descuento por item******************')
                console.log('**********************************************')
                this.setState({reqAuth:true})
                return
            }
            else {
               try {//si se agrega un pago en Pagar.js entra aqui
                 
                   if (cantidad === 0) {
                       return;
                   } 
                    var numOp;
                    var numCheque = null;
                    var idBanco = null;
                    var numTarjeta = null;
                    if(document.getElementById('txtAutorizeCode'))
                        this.autorizeCode = document.getElementById('txtAutorizeCode').value;
                    if(document.getElementById('txtCuotas'))
                        this.cuotasNumber = document.getElementById('txtCuotas').value;
                    

                    /*
                    if (this.state.medioIngresado.isCheque) {
                        
                        numCheque = document.getElementById('TxtNumCheque').value;
                        idBanco = Number( document.getElementById('txtBanco').value);
                    }
                    console.log("Medio Ingresado isTarjeta ", this.state.medioIngresado.isTarjeta);
                    //if (this.state.medioIngresado.isTarjeta) {
                    //    //numTarjeta = document.getElementById('txtNumTarjeta').value;
                    //    // Llamar a HTEN aqui si quieren el popup
                    //}
                    }*/

                    if (this.state.medioIngresado.isTarjeta) {
                        // numTarjeta = document.getElementById('txtNumTarjeta').value;
                    }
                  
                    if (document.getElementById('PINbox')) {
                        const totalDeLaVenta = this.props.total;
                        document.getElementById('PINbox').value = totalDeLaVenta;
                    }
                    if (document.getElementById('numOperacion')) {
                        numOp = document.getElementById('numOperacion').value;
                    }
                    

                    if (!numOp) {
                        numOp = null;
                    }
                    
                    var cuentaCasaID = this.props.cuentaCasaID;
                    if (!this.props.cuentaCasaID) {
                        cuentaCasaID = null;
                    }
                    this.props.AgregarPago(cantidad, numOp, cuentaCasaID, null, null, numCheque, numTarjeta, idBanco,0,[], this.autorizeCode, this.cuotasNumber);
                } catch (err) {

                    console.log(err);
                }
                try {
                    this.props.ActualizarEstado({ propinaPorc: cantidad });
                    this.props.ActualizarEstadoPagar({ propinaPorc: cantidad });
                } catch (err) {

                }
                try {
                    var neworden = this.props.orden;
                    neworden.propinaPorc = cantidad;
                    this.props.ActualizarEstado({ orden: neworden });
                } catch (err) {

                }
            }

            this.ActualizarTexto();
            this.CerrarModalMaster();
        }
    }
    CerrarModal2() {
        this.setState({ reqAuth: false });
    }
    autorizeDescItem() {
        var cantidad = Number(this.Numero.toString().replaceAll('.',''))
        
        if (!this.descItemEfec){
            cantidad =  Math.round((Number(this.limitDescItem) * cantidad) / 100)
        }

        let valueOfferTmp = this.DescItem.valueOffer
        this.DescItem.descItem = cantidad
        this.DescItem.isDescItem = true
        this.DescItem.valueOffer = 0
        this.DescItem.valueOfferUnitario = 0
        this.DescItem.inOffer = false
        this.DescItem.isConvenioUsado = false
        this.DescItem.isProductNC=false
        this.props.addDescItem(valueOfferTmp)
        this.ActualizarTexto()
        this.CerrarModalMaster()
    }
    //--------ESTILOS
 

    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '100%'
        },TercioAncho: {
            'width': '33%'
        },
        EstiloPopup: {
            'width': '31%',
            'height': '70%',
            'borderRadius': '8px',
            'marginLeft':'25%'
           //'margin': 'initial'
        },
        EstiloPopupAut: {
            'width': '30%',
            'height': '65%',
            'border-radius': '8px',
            'marginLeft': '25%'
            //'margin': 'initial'
        },
        ColorFondoTeclado: {
            'background': '#3498db'
        },
        
        BtnGroup:
        {
            'borderColor': '#d8e1de',
            'margin-left': '3%',
            'margin-bottom': '1%',
            'width': '45%',
            'font-size': '80%',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'height': '50px'
        }, PopUpStyle: {
             'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px'
        }, InputSize:
        {
            'width': '80%',

        },
        InputSizeDosAut:
        {
            'width': '80%',
            'height': '60px',
            'font-size': '4em',
            'text-align': 'center'
        },
        ButonGroupAproved:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'background': '#dc3545',
            'borderColor': '#6e9887',
            'margin-left': '16%'
        },
        ButonGroupAproved2:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'borderColor': '#6e9887',
            'margin-left': '16%'
        },
        ButtonsEnt: {
            'width': '4rem',
            'height': '4rem',
            'background': 'rgb(224 74 88)',
            'color': 'white',
            'fontSize': '19px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'border': '0',
        }
     

    }

    Numero = {
        numm: {
            'size': '15px'
        }
    }
    //--------ESTILOS

    //---------FUNCIONES SOBRESCRIBIR
    CerrarModalMaster() {
   
    }
    addDescItem() {

    }

    ActualizarEstadoPagar() {

    }
    ActualizarTexto() {
        //console.log('ActualizarTexto no sobreescribio');
    }

    PagarMontoRapido(monto) {
        this.Numero = monto;
        this.ClickSelectCantidad();
    }

    PagarTotal() {
        this.Numero = this.TotalPagarMaster;
        this.ClickSelectCantidad();
    }

    ClickCE() {
    
        this.TextoIngresadoNum = ''
        const elementSelected = this.IsMontoSelected? document.getElementById('PINbox'):this.isAutorizeCodeSelected?document.getElementById('txtAutorizeCode'):this.isCuotasSelected?document.getElementById('txtCuotas'):null

        if(elementSelected) {
            elementSelected.value = ''
            if(this.IsMontoSelected){
                document.getElementById('-').blur()
            }else if(this.isAutorizeCodeSelected)
                this.autorizeCode = ''
            else if(this.isCuotasSelected)
                this.cuotasNumber = ''
        
            
        }
        let valueAmount = document.getElementById('PINbox').value;
        try{
            if (valueAmount =='' || Number(valueAmount)==0){
                this.Numero = "0";
            }
            else{
               
                this.Numero= Number(valueAmount.toString().replaceAll('.',''));
            }
        }
        catch(e){
            this.Numero = "0";
            document.getElementById('PINbox').value='';
        }
      

    }
    DeleteChar() {
        const elementSelected = this.IsMontoSelected? document.getElementById('PINbox'):this.isAutorizeCodeSelected?document.getElementById('txtAutorizeCode'):this.isCuotasSelected?document.getElementById('txtCuotas'):null
        var val = elementSelected.value.slice(0, -1);
       
     try{
     if(elementSelected) {
        elementSelected.value = val;
        if(this.IsMontoSelected){ 
            this.Numero = document.getElementById('PINbox').value.toString().replaceAll('.','');
            document.getElementById('PINbox').value= Number(this.Numero).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
        }else if(this.isAutorizeCodeSelected)
            this.autorizeCode = val
        else if(this.isCuotasSelected)
            this.cuotasNumber = val
            }

        
        }      
        catch (e){
            console.log(e);
        }
        elementSelected.focus(); 
    }
    ClickCodigoOperacion() {
        this.resetSelectedText()
        this.IsCodOpSelected = true
    }
    ClickAutorizeCode() {
        this.resetSelectedText()
        this.isAutorizeCodeSelected = true
    }
    clickTxtCuotas() {
        console.log('clickTxtCuotas')
        this.resetSelectedText()
        this.isCuotasSelected = true

    }
    handlePressTxtCuotas = e => {
        if(e.key === 'Backspace') { 
            this.cuotasNumber = e.target.value
        }
    }
    handlePressTxtAutorizeCode = e => {
        if(e.key === 'Backspace') { 
            this.autorizeCode = e.target.value
        }
    }
    resetSelectedText() {
        this.IsCodOpSelected = false
        this.IsMontoSelected = false
        this.isAutorizeCodeSelected = false
        this.isCuotasSelected = false
    }
    VolverToPagar() { }
    //---------FIN FUNCIONES SOBRESCRIBIR

    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivoAnulacions', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ Motivos: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }

    CerrarModal() {
        this.setState({ activosMotivosAnulacion: false  });
    }

    GetHtmlPopupAnulacion() {
        this.TraerMotivos();
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                onClose={() => { this.CerrarModal() }}
                closeOnDocumentClick>
                <center>
                    <h3>Motivo de Anulación de la Orden</h3>
                    <div className="row">
                        {
                            this.state.Motivos.map((item, index) => {
                                const id = 'btnMotivo' + index;
                                return (<button id={id} name="btnMotivo" className="btn " style={this.Estilos.BtnGroup} onClick={() => { this.ClickSeleccionarMotivoBtn(item.descripcion, id) }}>{item.descripcion}</button>);
                            })
                        }
                        
                    </div>
                    <hr />
                    <h3> Otra razón </h3>
                    <input id="motivoPesonalizado" style={this.Estilos.InputSize} onClick={() => { this.DesactivarSelecciones() }} className="form-control" type="text" />
                    <hr />
                    <button className="btn btn-danger" style={this.Estilos.ButonGroupAproved} onClick={() => { this.CerrarModal() }}>
                        <i className="fa fa-times-circle fa-2x" aria-hidden="true"></i>
                    </button>

                    <button className="btn btn-success" style={this.Estilos.ButonGroupAproved2} onClick={() => { const parametroRecibido = (this.props.param-1); this.props.ValidarCierre(parametroRecibido, this.MotivoAnulacion); }} data-toggle="tooltip" data-placement="right" title="Listo">
                        <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i>
                    </button>
                </center>
            </Popup>
        );
    }
    GetHtmlAutorizado() {

        return (
            <Popup
                defaultOpen
                contentStyle={this.Estilos.EstiloPopupAut}
                modal
                closeOnDocumentClick={false}
                //onClose={() => { this.setState({ activarPopNomCliente: false }) }}
                >
                <center>
                    <div>
                        <p><strong>Ingrese Código de Administrador</strong></p>
                        <p style={this.Estilos.FuenteAdvertencia}><strong>El descuento será notificado al Supervisor correspondiente</strong></p>
                    <input id='PINboxDosAut' style={this.Estilos.InputSizeDosAut} type="password" name='PINboxDosAut' disabled />


                    <table id="tablaBotones">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>

                            </tr>
                            <tr>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>

                            </tr>
                            <tr>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                <td> <input onClick={() => { this.ClickTecladoCantidadAut(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                <td><input onClick={() => { this.ClickTecladoCantidadAut(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>

                            </tr>
                            <tr>
                                <td><input onClick={() => { this.ClickCED() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                <td> <input onClick={() => { this.ClickTecladoCantidadAut(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                <td><input onClick={() => { this.ValidarUsuario() }} type='button' className='PINbutton enter' name='+' value='enter' id='+' /></td>

                            </tr>
                            <tr>
                                <td colspan='3' align='center'>
                                    <button onClick={() => { this.CerrarModal2() }} style={this.Estilos.BtnDanger} className="btn btn-secondary"> <small> <i className="fa fa-times-circle fa-2x" aria-hidden="true">&nbsp; Cancelar </i></small></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    </center>
            </Popup>
        );

    }
    ClickTecladoCantidadAut(num) {
        if(document.getElementById('PINboxDosAut')){
            var val = document.getElementById('PINboxDosAut').value;
            this.CodAut += num;
                //document.getElementById('PINbox').type = 'text';
            document.getElementById('PINboxDosAut').value =this.CodAut;
            // document.getElementById('PINboxDosAut').focus();
        }
    }
    ClickCED() {
        this.CodAut = "";
        document.getElementById('PINboxDosAut').value = '';
    }

    async ValidarUsuario()
    {
        this.setState( {reqAuthVal: true})
        if(this.CodAut === '') {
            await swal({
                title: 'Perfil de usuario no valido',
                text: 'Usuario no válido',
                icon: 'error',
                dangerMode: true
            }).then((result) => {
                this.setState( {reqAuthVal: false})
                this.ClickCED()
            })
        } else {
            var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + this.CodAut + '?pin=true', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {
                var user = await respuesta.json();
                if (user.tipoUsuarioID === 1 || user.tipoUsuarioID === 2) {
                    this.setState( {reqAuthVal: false})
                    if(this.DescItem) {
                        this.autorizeDescItem()
                    }
                }
                else {
                    swal({
                            title: 'Perfil de usuario no valido',
                            text: 'Usuario no válido',
                            icon: 'error',
                            dangerMode: true
                    }).then((result) => {
                        this.setState( {reqAuthVal: false})
                        this.ClickCED()
                    })
                }

            }
            else {
                swal({
                    title: 'Perfil de usuario no valido',
                    text: 'Usuario no válido',
                    icon: 'error',
                    dangerMode: true
                }).then((result) => {
                    this.setState({ reqAuthVal: false })
                    this.ClickCED()
                })
            }
        }
    }

    ClickSeleccionarMotivoBtn(texto, idElem) {
        this.DesactivarSelecciones();
        this.MotivoAnulacion = texto;
        document.getElementById(idElem).className = 'btn btn-light';
        document.getElementById(idElem).style.background = 'white';
        document.getElementById(idElem).style.color = 'black';
    }
    DesactivarSelecciones() {
        this.MotivoAnulacion = '';
        document.getElementsByName('btnMotivo').forEach((item) => {
            item.className = 'btn btn-dark';
            document.getElementById(item.id).style.background = 'black';
            document.getElementById(item.id).style.color = 'white';
        });
    }

    maxLengthCheck(id) {
        var object =  document.getElementById(id);
        if (object.value.length > object.maxLength)
          object.value = object.value.slice(0, object.maxLength)
      }
    render() {
        return (
            <Popup
                modal
                id='TecladoNumericoD'
                onClose={() => { this.CerrarModalMaster() }}
                defaultOpen
                contentStyle={this.Estilos.EstiloPopup}
                closeOnDocumentClick>
                <div>
               
                    {
                        (this.state.activosMotivosAnulacion) ? (this.GetHtmlPopupAnulacion()) : (null)
                    }
                    {
                        (this.state.redirigirToRevisar) ? (<Redirect to='/revisar' />): (null)
                    }
                    {
                        (this.state.redirigirToMesas) ? (<Redirect to='/Mesas' />) : (null)
                    }
                    {
                        (this.state.mensaje.length > 1) ? (<strong> {this.state.mensaje}</strong>) : (null)
                    }
                    {
                        (this.TotalPagarMaster) ? (//si debe mostrar pago
                            <div>
                                <input id='PINbox' onFocus={() => { this.ElementBlur('PINbox') }} type={this.Numero} defaultValue={this.state.total} onClick={(e) => { this.ClickTxtMonto(e) }} name='PINbox' />
                                {(this.props.isCuentaCasa) ?
                                    (<center> <h4>Monto a Abonar</h4></center>) :
                                    (null)}

                                {
                                    (this.state.medioIngresado.tieneNumOperacion && GetUserCookie().Sucursal.configPOS.isCodigoOperacion) ? (<div>
                                        Ingrese codigo de operacion <input onClick={() => { this.ClickCodigoOperacion() }}  className="form-control" id="numOperacion" type="text" />
                                    </div>) : (null)
                                    
                                }
                                {
                                    (this.state.medioIngresado.isAutorizeCode && GetUserCookie().Sucursal.configPOS.isCodigoOperacion) ? 
                                        (<div> Ingreso codigo de autorizacion <input onClick={() => { this.ClickAutorizeCode() }} onFocus={() => { this.ClickAutorizeCode() }}  onKeyUp={this.handlePressTxtAutorizeCode} 
                                        onChange={(val) => {
                                            this.maxLengthCheck('txtAutorizeCode');
                                        }}
                                        className="form-control" id="txtAutorizeCode" type="number"  maxlength = "10" min="0" max="9999999999"/>
                                        </div>):(null)
                                }
                                {
                                    (this.state.medioIngresado.isCuotas && GetUserCookie().Sucursal.configPOS.isCodigoOperacion) ? 
                                        (<div> Nro Cuotas 
                                            <input onClick={() => { this.clickTxtCuotas() }}  onFocus={() => { this.clickTxtCuotas() }} onKeyUp={this.handlePressTxtCuotas} 
                                            onChange={(val) => {
                                                this.maxLengthCheck('txtCuotas');
                                            }}
                                            maxlength = "2"  className="form-control" id="txtCuotas" type="number" min="1" max="99"/>
                                        </div>):(null)
                                }
                                {
                                    (this.state.medioIngresado.isCheque && GetUserCookie().Sucursal.configPOS.isCodigoOperacion) ? (
                                        <div>
                                            Ingrese el numero de cheque <input className="form-control" id="TxtNumCheque" type="text" />
                                            Ingrese el banco <select className="form-control" id="txtBanco" type="text" >
                                                {
                                                    this.state.bancos.map((item) => {
                                                        return (<option value={item.id}>{item.descripcion}</option>);
                                                    })
                                                }
                                            </select>
                                        </div>
                                    ): (null)
                                }
                                <table id="tablaBotones">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                            <td> <input onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>
                                            <td><button title='Borrar' className="btn" style={this.Estilos.ButtonsEnt} onClick={() => { this.DeleteChar() }} type="button" name="Borrar" id="Borrar"><i className="fa fa-long-arrow-left " aria-hidden="true"></i></button></td>
                                        </tr>
                                        <tr>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                            <td><input onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input onClick={() => { this.ClickCE() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                            <td> <input onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                            <td><input onClick={() => { this.ClickSelectCantidad() }} type='button' className='PINbutton enter' name='+' value='enter' id='+' /></td>
                                            
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        ): (
                                <div>
                                    <input id='PINbox' onFocus={() => { this.ElementBlur('PINbox') }}  type={this.TipoTexto} defaultValue='' name='PINbox' disabled />
                                <table id="tablaBotones">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className='PINbutton' name='7' value='7' id='7' /></td>
                                                <td> <input onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className='PINbutton' name='8' value='8' id='8' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className='PINbutton' name='9' value='9' id='9' /></td>
                                            </tr>
                                            <tr>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className='PINbutton' name='4' value='4' id='4' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className='PINbutton' name='5' value='5' id='5' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className='PINbutton' name='6' value='6' id='6' /></td>
                                                <td><button className="btn" style={this.Estilos.ButtonsEnt} onClick={() => { this.DeleteChar() }} type="button" name="Borrar" id="Borrar"><i className="fa fa-long-arrow-left " aria-hidden="true"></i></button></td>
                                            </tr>
                                            <tr>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className='PINbutton' name='1' value='1' id='1' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className='PINbutton' name='2' value='2' id='2' /></td>
                                                <td><input onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className='PINbutton' name='3' value='3' id='3' /></td>
                                            </tr>
                                            <tr>
                                                <td><input onClick={() => { this.ClickCE() }} type='button' className='PINbutton clear' name='-' value='clear' id='-' /></td>
                                                <td> <input onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className='PINbutton' name='0' value='0' id='0' /></td>
                                                <td><input onClick={() => { this.ClickSelectCantidad() }} type='button' className='PINbutton enter' name='+' value='enter' id='+' /></td>
                                            </tr>
                                        </tbody>
                                </table>
                            </div>
                        )
                    }
                    {
                        (this.state.reqAuth? this.GetHtmlAutorizado():null)
                    }
                </div>
                
            </Popup>
            );
            
        
    }

    //<center> <input onClick={() => { this.PagarTotal() }} type='button' style={this.Estilos.TercioAncho} className='PINbutton enter' name='+' value={GetPrecioFormateado(this.TotalPagarMaster)} id='+' /> </center>
}
