import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import swal from 'sweetalert';
import { GetPrecioFormateado } from './Globales/Utilidades';


export class ArqueoDetalleIngreso extends Component {
    static displayName = ArqueoDetalleIngreso.name;
    IdMedioSeleccionado = 0;
    DescMedioSeleccionado = '';
    

    IdGavetaSeleccionada = 0;
    IngresosDetalle= [];
    constructor(props) {
        super(props);
        this.state = {
            MediosVentas: [],
            MediosDePago: [],
            hayCambios: false,
            cargando: false,
            mostrarDetallesTodosMedios: false,
            medioSeleccionado: {
                isCheque: false,
                isTarjeta: false,

            }
        }
        this.DescMedioSeleccionado = props.descMedio;
        this.IdMedioSeleccionado = props.idMedio;
        this.IdGavetaSeleccionada = props.IdGaveta;
        this.IngresosDetalle=props.IngresosDetalle;
        if (props.recargarPagina) {
            this.Recargar = props.recargarPagina;
        }
        //this.TraerMediosOrden();

    }
    componentDidMount() {
        var datos = this.props.MediosPorOrden;
        var ordenes = this.props.ordenes;
        console.log(ordenes)

        datos = datos.filter(p => p.tbMedioID === this.IdMedioSeleccionado);
        datos.forEach((item) => {
            item.orden = ordenes.find(p=>p.id===item.ordenID);
        });
        var mediosDePago = this.props.mediosDePago;
        console.log(datos)
        var medioSeleccionado = {};
        if (datos.length > 0) {
            medioSeleccionado = datos[0].tbMedio;
        } else {
            medioSeleccionado = {
                isCheque: false,
                isTarjeta: false,
            }
        }
        this.setState({ MediosVentas: datos, mediosDePago: mediosDePago, medioSeleccionado: medioSeleccionado });
    }

    Recargar() {

    }
    

    async EditarMedioDePago(idOrdenMedio, idcbo) {

        try {
           
            const nuevoId = idcbo;
            var data = {};
            data.tbMedioID = nuevoId;
            data.OrdenID = 0;
            data.Total = 0;
            var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden/' + idOrdenMedio, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                alert('error');
                
                //this.props.setStateArqueo();
                //await this.TraerMediosOrden();
            }
        } catch (err) {
            swal('Opción no válida');
        }
    }

    //------Clicks
    ClickCambiarCboMedioPago() {
       
        swal({
            title: "¿Esta seguro que desea realizar los cambios?",
            text: "Se cambiaran los medios de pago seleccionados",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            await this.setState({ cargando: true, hayCambios: false });
            
            if (willDelete) {
                var elemsToEdit = document.getElementsByName('selectDeMedios');
               
                elemsToEdit = Array.from(elemsToEdit).filter(p => p.value !== '--');
                elemsToEdit.forEach(async (item) => {
                    
                    await this.EditarMedioDePago(item.attributes.idmediopago.value, item.value);
                });
                //await this.EditarMedioDePago(idOrdenMedio,);
                const isNumber = !isNaN(Number(this.props.ValorGavetaPendiente));
                if (!isNumber) {
                    this.Recargar();
                }
                else {
                    this.props.CargarPendientes(this.IdGavetaSeleccionada);
                }
            }
        });
        
    }
    //------Clicks
    
    ChangeMedioPago() {
        this.setState({hayCambios:true});
    }
    //--------ESTILOS
    Estilos= {
        Contenedor: {
            'border-radius': '25px',
            'border': '2px solid #e6ede7',
            'overflow': 'scroll',
            'height': '1000px',
            'marginLeft': '16%'
        },
        InputNum:
        {
            'width': '130px'
        }
    }
    //--------FIN ESTILOS

    GetTotal() {
        var res = 0;
        this.state.MediosVentas.forEach((item) => {
            res += item.totalReal;
        });
        return res;
    }

    GetTotalPropinas() {
        var res = 0;
        this.state.MediosVentas.forEach((item) => {

            if (item.orden) {
                res += item.montoPropina;
            }
        });
        return res;
    }

    

    render() {
        return (
            <div style={this.Estilos.Contenedor}>
                <center><h4>Ingresos de Dinero</h4></center>
                <table className="table">
                    <thead>
                        <tr>
                            <td>N° Comprobante</td>
                            <td>Fecha</td>
                            {/*<td>Hora</td>*/}
                            <td>Comentario</td>
                            <td>Receptor</td>
                            <td>Monto</td>
                            <td>Medio Pago</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.IngresosDetalle.map((item, index) => {
                                return (
                                    <tr key={"trIngreso" + index} >
                                        <td>{item.numeroComprobante}</td>
                                        <td>{item.fecha}</td>
                                        {/*<td>{item.hora}</td>*/}
                                        <td>{item.comentario}</td>
                                        <td>{item.receptorDelDinero}</td>
                                        <td>{GetPrecioFormateado(item.monto)}</td>
                                        <td>{item.desMedioPago}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

                {
                    (this.state.MediosVentas.length === 0) ? (<p></p>) :
                        (
                            <div hidden='hidden'>
                                <p><strong> Totales</strong></p>
                                <p>Monto Total: {GetPrecioFormateado(this.GetTotal())}</p>
                                <p> Total Propina {GetPrecioFormateado(this.GetTotalPropinas())} </p>
                            </div>
                        )
                }
                {
                    (this.state.hayCambios) ? (
                        <center>
                            <button className="btn btn-success" onClick={() => { this.ClickCambiarCboMedioPago() }}>Guardar cambios</button>
                            
                        </center>
                            ): (null)
                }
                {
                    (this.state.cargando) ? (
                        <div style={this.Estilos.FondoDefault}>
                            <center>
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                <p> Actualizando...</p>
                            </center>
                        </div>
                    ) : (null)
                }
            </div>
        );
    }
}
