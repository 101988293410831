import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import { GetUrlApi } from './Globales/VariableGlobales';
import UserProfile from './Globales/PerfilUsuario';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';
import { GetNumeroMiles } from './Globales/Utilidades';

export class DescuentoPuntos extends Component {
    static displayName = DescuentoPuntos.name;
    ParentComp = '';
    TotalPagarMaster;
    Numero;
    Url = '';
    TipoTexto = "number";
    IsCodOpSelected = false;
    IsMontoSelected = true;
    IsEfectivo = false;
    NumCaja = 1;
    CodigoOperacion = ''; 
    IsAutorizacion = false;
    dataClientePuntos = [];
    TotalOrdenDesc = 0;
    TextoIngresadoNum = '';
    Puntos  = 0;
    SaldoPuntosUsable = 0;
    FactorPuntos = 0;
    PuntosUtilizados = 0
    constructor(props) {
        super(props);
        this.state = {
            mostrarTotal: false,
            total: 0,
            mensaje: '',    
            medioIngresado: {},
            bancos:[],
            ErrorExists:false,
            Ambiente: localStorage.getItem('ambiente')
        }
        this.Numero = '';
 
        this.TotalOrdenDesc = props.TotalOrdenDesc;
        this.Puntos = props.SaldoPuntos;
        this.FactorPuntos = props.FactorPuntos;
        this.dataClientePuntos = this.props.dataClientePuntos;
        URL = props.url;
        if (this.props.CerrarModal)
            this.CerrarModalMaster = this.props.CerrarModalMaster;
        if (this.props.total) {
            this.TotalPagarMaster = this.props.total;
         //   document.getElementById('PINbox').value = this.TotalPagarMaster;
          
        }
        if (this.props.ActualizarEstadoPagar) 
            this.ActualizarEstadoPagar = this.props.ActualizarEstadoPagar;
        if (this.props.VolverToPagar)
            this.VolverToPagar = this.props.VolverToPagar;
        if (this.props.ActualizarTexto)
            this.ActualizarTexto = this.props.ActualizarTexto;
 
         
        
        this.IsAutorizacion = props.IsAutorizacion;


        document.onkeypress = (e) => {
            var element = document.getElementById('PINbox');
           
            if (element!=null) {
                if (e.key === 'd' || e.key==='D'){
                    this.DeleteChar();
                    document.getElementById('PINbox').focus();
                    return;
                   }
                var val = document.getElementById('PINbox').value;
                if (val.length >= 6 && val.length <= 9) {
                    document.getElementById('PINbox').style.fontSize = "3em";
                } else if (val.length > 10 && val.length <= 20) {
                    document.getElementById('PINbox').style.fontSize = "1.5em";

                }
                else if (val.length > 20 && val.length) {
                    document.getElementById('PINbox').style.fontSize = "0.5em";

                } else if (val.length < 6) {
                    document.getElementById('PINbox').style.fontSize = "4em";
                }

                if (e.key === 'Enter') {
                    console.log(this.Numero);
                    //if (val!="") {
                    //    var dreplace = val.replaceAll('.', '');
                    //    this.Numero = dreplace;
                    //}
                    //setTimeout(() => { this.ClickSelectCantidad() }, 1000);
                    this.ClickSelectCantidad();

                    
                }
                else if (e.key === '1') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '2') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '3') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '4') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '5') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '6') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '7') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '8') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '9') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;
                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === '0') {
                    this.Numero += e.key;
                    document.getElementById('PINbox').value += e.key;

                    document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

                } else if (e.key === 'c' || e.key === 'C') {
                    this.ClickCE();
                }
               /* try {
                    document.getElementById('lbPuntosEnPesos').innerHTML =  GetNumeroMiles(this.RedondearValor(this.Numero * this.FactorPuntos));
                   } catch (error) {    }
                  */
            }
            
        };



    }
    ElementBlur(e) {
        console.log(e);
        document.getElementById(e).blur();
    }
    componentDidMount() {
        console.log(GetUserCookie().Sucursal.configPOS);

        if (this.props.CerrarModal)
        this.CerrarModal = this.props.CerrarModal;

        
        if (this.IsEfectivo) {
            this.Estilos.EstiloPopup.width = '30%';
        }
        if (this.IsAutorizacion) {
            document.getElementById('PINbox').type = 'password';
            this.setState({ mensaje:'El gerente debe autorizar esta operación' });
        }
        if (this.props.descEfectivo) {
            this.setState({ mensaje:'Ingrese Monto en Efectivo a Descontar' });
        }
        if (this.TotalPagarMaster > 0) {
            //document.getElementById('PINbox').value = this.RedondearValor(this.TotalPagarMaster).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            this.Estilos.EstiloPopup.height = '70%';
            //document.getElementById('inSaldoPago').value = this.RedondearValor(this.TotalPagarMaster).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            
        }
        if (this.props.mostrarTotal) {
            //this.Numero = this.RedondearValor( this.props.total);
            const montoInicio = this.props.total.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
            this.setState({ mostrarTotal: true, total: montoInicio });
        }
        if (this.props.propinaDif) {
            this.setState({ mensaje: 'Total recibido' });
        }
        if (this.props.saldoPuntos){
            this.setState({ mensaje: 'Ingrese Puntos a utilizar' });
        }

        //Redondeo los puntos siempre hacia abajo
        const restoPuntos = this.props.SaldoPuntos % 10;
        this.SaldoPuntosUsable = this.props.SaldoPuntos - restoPuntos;


        let saldoInPoints = this.TotalPagarMaster / this.FactorPuntos ;
        const restoPuntosT = saldoInPoints % 10;
        saldoInPoints = saldoInPoints - restoPuntosT;
        let montoEnPesos = 0 
        

       
            if (saldoInPoints <= this.props.SaldoPuntos ){
                //document.getElementById('inpPuntos').text= saldoInPoints;
                this.PuntosUtilizados=saldoInPoints ;
                montoEnPesos  = saldoInPoints * this.FactorPuntos;
            }else{
               // document.getElementById('inpPuntos').text= this.SaldoPuntosUsable;
                this.PuntosUtilizados=this.SaldoPuntosUsable;
                montoEnPesos  = this.SaldoPuntosUsable * this.FactorPuntos;
            }
        
     
            
            document.getElementById('PINbox').value = montoEnPesos;
            
            try {
                var val =document.getElementById('PINbox').value;
          
            if (val.length>=6 && val.length<=9) {
                document.getElementById('PINbox').style.fontSize = "3em";
               // document.getElementById('lbPuntosEnPesos').style.fontSize = "3em";
            } else if (val.length > 10 && val.length <= 20) {
                document.getElementById('PINbox').style.fontSize = "1.5em";
                //document.getElementById('lbPuntosEnPesos').style.fontSize = "1.5em";
            }
            else if (val.length > 20 && val.length) {
                document.getElementById('PINbox').style.fontSize = "0.5em";
                //document.getElementById('lbPuntosEnPesos').style.fontSize = "0.5em";
    
            } else if (val.length < 6) {
                document.getElementById('PINbox').style.fontSize = "4em";
                //document.getElementById('lbPuntosEnPesos').style.fontSize = "4em";
            }
                this.Numero=document.getElementById('PINbox').value ;
                this.PuntosUtilizados=  GetNumeroMiles(this.RedondearValor(this.Numero / this.FactorPuntos));
               } catch (error) {    }
    
          try{document.getElementById('PINbox').focus()}catch(e){}     
        


        
    }
 

    RedondearValor(monto) {
        if (this.IsEfectivo) {
            if (monto > 0) {
                const resto = monto % 10;
                if (resto > 0 && resto <= 5) {
                    return monto - resto;
                }
                else if (resto > 5 && resto < 10) {
                    return monto + (10 - resto);
                }
            }
            else {
                return monto;
            }
        }
        if (monto < 1){
            return monto;
        }
        else {
            monto = Math.round(monto * 10)/10;
        }
        return monto
    }

    ClickTecladoCantidad2(num) {
        var val =document.getElementById('PINbox').value;
      
        if (val.length>=6 && val.length<=9) {
            document.getElementById('PINbox').style.fontSize = "3em";
            //document.getElementById('lbPuntosEnPesos').style.fontSize = "3em";
        } else if (val.length > 10 && val.length <= 20) {
            document.getElementById('PINbox').style.fontSize = "1.5em";
            //document.getElementById('lbPuntosEnPesos').style.fontSize = "1.5em";
        }
        else if (val.length > 20 && val.length) {
            document.getElementById('PINbox').style.fontSize = "0.5em";
            //document.getElementById('lbPuntosEnPesos').style.fontSize = "0.5em";

        } else if (val.length < 6) {
            document.getElementById('PINbox').style.fontSize = "4em";
            //document.getElementById('lbPuntosEnPesos').style.fontSize = "4em";
        }


    
            
            this.Numero = this.Numero.toString().replaceAll('.','') +  num;
            //document.getElementById('PINbox').type = 'text';
           document.getElementById('PINbox').value =/*'$'+*/ Number(this.Numero).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
           try {
            this.PuntosUtilizados =  GetNumeroMiles(this.RedondearValor(this.Numero / this.FactorPuntos));
           } catch (error) {    }
          
     

    }
    
    ClickTxtMonto() {
        this.IsCodOpSelected = false;
        this.IsMontoSelected = true;
    }

    DeleteChar() {
        
        var dato = document.getElementById('PINbox').value;
        var result =  dato.slice(0, -1);
        document.getElementById('PINbox').value = Number(result.toString().replaceAll('.',''));
        this.TextoIngresadoNum = Number(result.toString().replaceAll('.',''));
        this.Numero =Number(result.toString().replaceAll('.',''));

        try {
    //        document.getElementById('lbPuntosEnPesos').innerHTML =  GetNumeroMiles(this.RedondearValor(Number(this.Numero.toString().replaceAll('.','')) * this.FactorPuntos)); 
            document.getElementById('PINbox').value= Number(this.Numero).toString().replaceAll('.','').split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

          
           } catch (error) {    }
          
    }

    async ClickSelectCantidad() {
        //const cantidad = document.getElementById('PINbox').value;
        var codOperacion;
        if(this.state.ErrorExists){
            return;
        }
        if (document.getElementById('numOperacion'))
         codOperacion = document.getElementById('numOperacion').value;
        if (this.props.isNumOperacion && codOperacion === '') {
            swal('Debe ingresar el código de operación');
        }
        else {
           
            let montoEnPesos = 0;

         
            montoEnPesos=  Number(this.Numero.toString().replaceAll('.',''));
           
            const cantidad =this.RedondearValor(montoEnPesos / this.FactorPuntos);
      
 
            console.log('la cantidad de puntos' + cantidad);
            if (cantidad > this.SaldoPuntosUsable /*this.Puntos*/){
                this.setState({ErrorExists:true});
                swal({
                    title: 'La cantidad de puntos no puede superar el Saldo de Puntos Usables del Cliente (Sin unidades. Ej.: 501 => 500).',
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.setState({ErrorExists:false});
                    document.getElementById('PINbox').focus();
                })
                return;
            }

            if (cantidad < 1){
                this.setState({ErrorExists:true});
                swal({
                    title: 'Debe ingresar un monto válido para puntos.',
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.setState({ErrorExists:false});
                    document.getElementById('PINbox').focus();
                })
                return;
            }
            if (montoEnPesos > this.TotalPagarMaster){
                this.setState({ErrorExists:true});
                swal({
                    title: 'El monto a pagar con puntos no puede ser superior al Saldo a pagar de la Venta.',
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.setState({ErrorExists:false});
                    document.getElementById('PINbox').focus();
                })
                return;
            }
         /*   const restoPuntosDigitados = montoEnPesos % 10;

            if (restoPuntosDigitados > 0){
                this.setState({ErrorExists:true});
                swal({
                    title: 'La cantidad de puntos a canjear no debe tener unidades (Ej.: 501 => 500).',
                    icon: "warning",
                    dangerMode: true,
                }).then(() => {
                    this.setState({ErrorExists:false});
                    document.getElementById('PINbox').focus();
                })
                
                return;
            }*/
            var neworden = this.props.orden;
             

            if (neworden.puntosXCanje === undefined || neworden.puntosXCanje === 0){
                var descEfec  = neworden.descEfectivo === undefined ? 0 : neworden.descEfectivo;
                descEfec += montoEnPesos;
                neworden.descEfectivo=descEfec;

                neworden.dctoEfec = descEfec;
                neworden.puntosXCanje = cantidad;
                this.props.ActualizarEstado({ orden: neworden, IsDescuentoRealizado: true });//actualiza paraLlevar
                this.ActualizarEstadoPagar({ orden: neworden, IsDescuentoRealizado: true  });//actualiza pagar
                if (this.VolverToPagar)
                    this.VolverToPagar();
                if (this.props.CerrarModal) {
                    this.props.CerrarModal();
                }
            }
          

            
            this.ActualizarTexto();
            this.CerrarModal();
        }
    }

    

    //--------ESTILOS
 

    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '100%'
        },TercioAncho: {
            'width': '33%'
        },
        EstiloPopup: {
            'width': '38%',
            'height': '70%',
            'border-radius': '8px',
            'margin-left':'30%'
           //'margin': 'initial'
        },
        
        ColorFondoTeclado: {
            'background': '#3498db'
        },
        
        BtnGroup:
        {
            'borderColor': '#d8e1de',
            'margin-left': '3%',
            'margin-bottom': '1%',
            'width': '45%',
            'font-size': '80%',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
            'height': '50px'
        }, PopUpStyle: {
             'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px'
        }, InputSize:
        {
            'width': '80%',

        },
        ButonGroupAproved:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'background': '#dc3545',
            'borderColor': '#6e9887',
            'margin-left': '16%'
        },
        ButonGroupAproved2:
        {
            'width': '17%',
            'height': '50px',
            'margin-right': '10%',
            'color': 'white',
            'borderColor': '#6e9887',
            'margin-left': '16%'
        },
        ButtonsEnt: {
            'width': '4rem',
            'height': '4rem',
            'background': 'rgb(224 74 88)',
            'color': 'white',
            'fontSize': '19px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'border': '0',
        },
        Buttons: {
            'width': '4rem',
            'height': '4rem',
           // 'background': 'rgb(158 103 83)',
            'fontSize': '2rem',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'fontWeight': 'bold'
        },
        ButtonsDes: {
            'width': '4rem',
            'height': '4rem',
            'background': 'rgb(144 181 220)',
            'color': 'white',
            'fontSize': '19px',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '32%',
            'padding': '0',
            'margin': '0.10rem 0.35rem 0.10rem 0.35rem',
            'border': '0',
        }
     

    }

    Numero = {
        numm: {
            'size': '15px'
        }
    }
    //--------ESTILOS

    //---------FUNCIONES SOBRESCRIBIR
    CerrarModalMaster() {

    }

    ActualizarEstadoPagar() {

    }
    ActualizarTexto() {
        //console.log('ActualizarTexto no sobreescribio');
    }

    PagarMontoRapido(monto) {
        this.Numero = monto;
        this.ClickSelectCantidad();
    }

    PagarTotal() {
        this.Numero = this.TotalPagarMaster;
        this.ClickSelectCantidad();
    }

    ClickCE() {
        this.Numero = "0";
        this.TextoIngresadoNum = '';
 //       document.getElementById('lbPuntosEnPesos').innerHTML = '';
        document.getElementById('PINbox').value = '';
        document.getElementById('-').blur();
    }

    ClickCodigoOperacion() {
        this.IsCodOpSelected = true;
        this.IsMontoSelected = false;
    }
    VolverToPagar() { }
    //---------FIN FUNCIONES SOBRESCRIBIR

  

    CerrarModal() {
         
    }

    
 
    render() {
        return (
            <Popup
                id='DescuentoPuntos'
                onClose={() => { this.CerrarModal() }}
                contentStyle={this.Estilos.EstiloPopup}
                defaultOpen
                modal
                closeOnDocumentClick>
                <div>
              
                    
                            <div>

                              

                                    <div className="row">   
                                          <div className="col"><strong>Tienes disponible:  {GetNumeroMiles(this.Puntos)}  Puntos, equivalentes a $:{GetNumeroMiles(this.RedondearValor(this.Puntos * this.FactorPuntos))}</strong> </div>
                                     </div>
                                    <div>
                                         <div className="row">
                                         <div className="col"><strong>Saldo a Pagar: $ {GetNumeroMiles(this.TotalPagarMaster)}</strong>
                                         </div>
                                     </div>
                                  
     
                                  
                                     <div className="row">
                                           <div className="col" ><strong>¿Que monto deseas ocupar?</strong></div>
                                     </div>
                                     <div className="row">
                                        
                                             <input id='PINbox' onFocus={() => { this.ElementBlur('PINbox') }} type={this.Numero}   onClick={() => { this.ClickTxtMonto() }} name='PINbox' />
                                          
                                     </div>
                                   </div>  
                              
                               
                                
                             
                                <table id="tablaBotones">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(7) }} type='button' className="btn"  name='7' value='7' id='7' /></td>
                                            <td> <input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(8) }} type='button' className="btn"  name='8' value='8' id='8' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(9) }} type='button' className="btn"  name='9' value='9' id='9' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(4) }} type='button' className="btn"  name='4' value='4' id='4' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(5) }} type='button' className="btn"  name='5' value='5' id='5' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(6) }} type='button' className="btn"  name='6' value='6' id='6' /></td>
                                            <td><button className="btn" style={this.Estilos.ButtonsEnt} onClick={() => { this.DeleteChar() }} type="button" name="Borrar" id="Borrar"><i className="fa fa-long-arrow-left " aria-hidden="true"></i></button></td>
                                        </tr>
                                        <tr>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(1) }} type='button' className="btn"  name='1' value='1' id='1' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(2) }} type='button' className="btn"  name='2' value='2' id='2' /></td>
                                            <td><input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(3) }} type='button' className="btn"  name='3' value='3' id='3' /></td>
                                            
                                        </tr>
                                        <tr>
                                            <td><input style={this.Estilos.ButtonsDes} onClick={() => { this.ClickCE() }} type='button' className='btn' name='-' value='clear' id='-' ></input></td>
                                            <td> <input style={this.Estilos.Buttons}  onClick={() => { this.ClickTecladoCantidad2(0) }} type='button' className="btn"  name='0' value='0' id='0' /></td>
                                            <td></td>
                                            <td>¿Deseas ocupar tus puntos?</td>
                                            <td><input onClick={() => { this.VolverToPagar() }} type='button' className='PINbutton clear' name='-' value='No' id='-' ></input></td>
                                        <td><input onClick={() => { this.ClickSelectCantidad() }} type='button' className='PINbutton enter' name='+' value='Si' id='+'></input></td>

                                        </tr>
                                        <tr>
                                       
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                       
                </div>
            </Popup>
        );
    }

    //<center> <input onClick={() => { this.PagarTotal() }} type='button' style={this.Estilos.TercioAncho} className='PINbutton enter' name='+' value={GetPrecioFormateado(this.TotalPagarMaster)} id='+' /> </center>
}
