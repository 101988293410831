import React, { Component } from 'react';
//import './Estilos.css';
import Popup from "reactjs-popup";

export class DetallesProducto extends Component {
    static displayName = DetallesProducto.name;

    constructor() {
        super();
        this.state = {
            producto: {}
        }
    }
    componentDidMount() {
        this.setState({ producto: this.props.prod });
        console.log(this.props.prod);
        //console.log(this.state.precpred);
    }

    CerrarModal() {
        try {
            var modales = document.getElementsByClassName("popup-overlay");
            if (modales.length > 0)
                modales[modales.length - 1].style.visibility = "hidden";
        } catch (err) {
            console.log('ex en cierre de modal ' + err);
        }
    }

   

    Estilos = {
        nombreDesc:{
            'backgroundColor': 'black',
            'color': 'white',
            'width': '40%',
            'height':'20%'
        },
        FullAncho : {
            'width': '100%'
        },
        rowDet1: {
            'backgroundColor': 'black',
            'color': 'white',
            'width': '70%',
            'textAlign': 'center',
            'fontweight': 'bold'
        },
        rowDet2: {
            'backgroundColor': 'black',
            'color': 'white',
            'width': '30%',
            'textaAlign': 'center',
            'fontweight': 'bold'
        },
        estiloTA: {
            'height': '200px',
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        TercioAncho: {
            'width': '33%'
        }

    }

    render() {
        return (
            <Popup
                modal
                defaultOpen
                closeOnDocumentClick>

                <table style={this.Estilos.FullAncho}>
                    <tr>
                        <td style={this.Estilos.rowDet1}>
                             {this.state.producto.desprod} 
                        </td>
                        <td style={this.Estilos.rowDet2}>
                            <p>$ {this.state.producto.precpred} </p>
                        </td>
                    </tr>
                </table>
                <textarea className="form-control" readOnly style={this.Estilos.estiloTA} />
                

                <div style={this.Estilos.FullAncho} className="row">
                    <div style={this.Estilos.MitadAncho} className="column"></div>
                    <div style={this.Estilos.MitadAncho} className="column">
                        <p>impuesto 1</p>
                        <p>impuesto 2</p>
                        <p>descontable</p>
                    </div>
                </div>
                <table style={this.Estilos.FullAncho}>
                    <tr>
                        <td style={this.Estilos.TercioAncho}> </td>
                        <td style={this.Estilos.TercioAncho}>
                            <button style={this.FullAncho} className="btn btn-primary" onClick={() => { this.props.CerrarModalMaster() }}>
                                Volver
                            </button>
                        </td>
                        <td>
                            <button style={this.FullAncho} className="btn btn-success" onClick={() => { }}>
                                
                                    Agregar
                            </button>
                        </td>
                    </tr>
                </table>
                
                

            </Popup>
        );
    }
}
