export function GetUrlApi() {
    // URL desarrollo
    // return '192.168.100.60:9095';
    //return "http://localhost:5001";
    // return 'http://localhost:5000';
    //return 'http://localhost:62339';
 
    //return 'https://todobikesapitest.blinamic.cl';

    // return 'http://localhost:59960';
    // URLS Pruebas
    // return 'https://apipostest.blinamic.cl';

    //url maisa test
    // return 'http://10.10.10.248:8083';

    //url maisa produccion
    // return 'http://10.10.10.32:83';

    // URL Produccion
    return 'https://todobikesapi.blinamic.cl';
}

export function GetUrlBlinamicApi(){
    //local
    // return 'https://localhost:44326'
    // return 'https://localhost:7079';

    //test
    // return 'https://prueba18api.blinamic.cl'

    //url maisa test
    // return 'http://10.10.10.248:8082';

    //url maisa produccion
    //return 'http://10.10.10.32:82';

    // Production
    return 'https://api.blinamic.cl'
}

export function GetUrlApiWhatsapp() {
    // URLS Pruebas
    //return "http://localhost:5001/api/";
    // return "http://localhost:59960/api/";
    // return 'https://apipostest.blinamic.cl/api/';

    //maisa test
    // return 'http://10.10.10.248:8083/api/'

    //maisa produccion
    //return 'http://10.10.10.32:83/api/'

    // URL Produccion
    return 'https://todobikesapi.blinamic.cl/api/';
}

export function GetUrlApiWhatsappBlinamic() {

    // URLS Pruebas
    // return "https://demo.blinamic.cl/MotorNotificaciones/EnvioWhatsAppDocumentoElectronico";

    //maisa test
    // return "http://10.10.10.248:8080/MotorNotificaciones/EnvioWhatsAppDocumentoElectronico"

    //maisa produccion
    //return "http://10.10.10.32:80/MotorNotificaciones/EnvioWhatsAppDocumentoElectronico"


  // URL Produccion
   return 'https://todobikes.blinamic.cl/MotorNotificaciones/EnvioWhatsAppDocumentoElectronico';

  // URL QA
  // return 'http://todobikeshtenapi.blinamic.cl/MotorNotificaciones/EnvioWhatsAppDocumentoElectronico';
}

export function GetUrlApiWhatsappEcommerceBlinamic() {
    // URLS Pruebas Todobikes
    // return "http://demo.blinamic.cl/MotorNotificaciones/conexionMotorNotificacionPOS";

    //maisa Test
    // return "http://10.10.10.248:8080/MotorNotificaciones/conexionMotorNotificacionPOS"

    //maisa produccion
    //return "http://10.10.10.32:80/MotorNotificaciones/conexionMotorNotificacionPOS"


    // URL QA
    // return 'http://todobikeshtenapi.blinamic.cl/MotorNotificaciones/conexionMotorNotificacionPOS';
    // URL Produccion
    return 'https://todobikes.blinamic.cl/MotorNotificaciones/conexionMotorNotificacionPOS';
}
