import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';
import { PopupEditarPromocion } from './PopupEditarPromocion';
import Popup from "reactjs-popup";


export class MantenedorPromociones extends Component {
    static displayName = MantenedorPromociones.name;
    TipoPromoSeleccionado = {};
    CargaIncialLista = false;
    IntervalHora = null;
    StateInicial = {
        promociones: [],
        diasSemana:[],
        activarAgregarPromo: false,
        activarEditarPromo: false,
        Categorias: [],
        ProductosSeleccionados:[],
        tiposUsuarios: [],
        sucursales: [],
        todosSelected: false,
        tiposPromocion:[],
        usuarioSeleccionado: {},
        promoSeleccionada: {},
        sucursalTodosSelected:false,
        diasTodosSelected: false,
        tipoPromocionSelected: {},
        volverAlIncio: false,
        HoraServidor:''
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerPromociones();
        this.TraerDias();
        this.TraerSucursales();
        this.TraerTiposPromocion();

        this.ClickEditarPromo = this.ClickEditarPromo.bind(this);
        this.Recargar = this.Recargar.bind(this);
        this.CerrarPopups = this.CerrarPopups.bind(this);
    }


    componentDidMount() {
        var user = GetUserCookie();
        if (user.id === 0 || !user.Sucursal.configPOS.isPromociones) {
            Logout();
            this.setState({ volverAlIncio: true });
        } else {
            //this.IntervalActualizaOrdenes = setInterval(() => {
            //    this.TraerHora()

            //}, 1000);
        }
    }

    async TraerHora() {
        var respuesta = await fetch(GetUrlApi() + '/api/Hora/getDateTime', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ HoraServidor: datos.fechaHora });
        }
    }



    //-------->INICIO FUNCIONES CRUD
    async TraerPromociones() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Promociones?sucursalID' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ promociones: datos });
        }
    }

    async TraerDias() {
        var respuesta = await fetch(GetUrlApi() + '/api/DiasSemanas', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ diasSemana: datos });
        }
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Sucursals', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ sucursales: datos });
        }
    }

    async TraerTiposPromocion() {
        var respuesta = await fetch(GetUrlApi() + '/api/TipoPromocion', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ tiposPromocion: datos });
            if (datos.length > 0) {
                this.TipoPromoSeleccionado = datos[0];
            }
        }
    }
    ToNumberArray(array1) {
        var res = [];
        array1.forEach((item) => {
            res.push(item.value);
        });
        return res;
    }

    async TraerProductos() {
        var idCategoria = document.getElementById('cboCateg').value;
        var selectedIndex = document.getElementById('cboCateg').selectedIndex;
        var cbo = document.getElementById("cboCateg").options[selectedIndex];
        var sucuID = cbo.getAttribute("sucursalID");
        if (!sucuID) {
            sucuID = GetUserCookie().SucursalID;
        }
        var sucursalesSeleccionadas = this.ToNumberArray(Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos'));
        var data = {};
        data.sucursales = sucursalesSeleccionadas;
        data.id = idCategoria;
        data.SucursalID = sucuID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductosPromocion?id=' + idCategoria + "&SucursalID=" + sucuID, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (categorias.ok) {
            var datos = await categorias.json();
            
            this.setState({ ProductosSeleccionados: datos, todosSelected: false });
        }
    }

    async TraerProductosGrupo() {
        var idCategoria = document.getElementById('cboCateg').value;
        var selectedIndex = document.getElementById('cboCateg').selectedIndex;
        var cbo = document.getElementById("cboCateg").options[selectedIndex];
        var sucuID = cbo.getAttribute("sucursalID");
        if (!sucuID) {
            sucuID = GetUserCookie().SucursalID;
        }
        var sucursalesSeleccionadas = this.ToNumberArray(Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos'));
        var data = {};
        data.sucursales = sucursalesSeleccionadas;
        data.id = idCategoria;
        data.SucursalID = sucuID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductosPromocion2?id=' + idCategoria + "&SucursalID=" + sucuID, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (categorias.ok) {
            var datos = await categorias.json();

            this.setState({ ProductosSeleccionados: datos, todosSelected: false });
        }
    }

    async TraerProductos2(categoriaID) {//borrar
        if (!document.getElementById('cboProd')) {
            return;
        }
        if (document.getElementById('cboProd').options===null) {
            return;
        }
        if (this.CargaIncialLista) {
            return;
        }
        var SucursalID = GetUserCookie().SucursalID;
        var sucursalesSeleccionadas = this.ToNumberArray(Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos'));
        var data = {};
        data.sucursales = sucursalesSeleccionadas;
        data.id = categoriaID;
        data.SucursalID = SucursalID;
        
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductosPromocion?id=' + categoriaID + "&SucursalID=" + SucursalID,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (categorias.ok) {
            var datos = await categorias.json();
            this.setState({ ProductosSeleccionados: datos, todosSelected: false });
            try {
                var option_str = document.getElementById('cboProd');
                if (option_str === null) {
                    return;
                }

                for (var i = 0; i < option_str.options.length; i++) {
                    if (this.state.promoSeleccionada.productos.filter(p => p.tbProducto.id === Number(option_str.options[i].value)).length > 0) {
                        option_str.options[i].selected = true;
                    }
                }
            } catch (err) {

            }

        }
        this.CargaIncialLista = true;
    }

    async TraerProductosGrupo2(categoriaID) {
        if (!document.getElementById('cboProd')) {
            return;
        }
        if (document.getElementById('cboProd').options === null) {
            return;
        }
        if (this.CargaIncialLista) {
            return;
        }
        var SucursalID = GetUserCookie().SucursalID;
        var sucursalesSeleccionadas = this.ToNumberArray(Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos'));
        var data = {};
        data.sucursales = sucursalesSeleccionadas;
        data.id = categoriaID;
        data.SucursalID = SucursalID;

        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductosPromocion?id=' + categoriaID + "&SucursalID=" + SucursalID, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (categorias.ok) {
            var datos = await categorias.json();
            this.setState({ ProductosSeleccionados: datos, todosSelected: false });
            try {
                var option_str = document.getElementById('cboProd');
                if (option_str === null) {
                    return;
                }

                for (var i = 0; i < option_str.options.length; i++) {
                    if (this.state.promoSeleccionada.productos.filter(p => p.tbProducto.id === Number(option_str.options[i].value)).length > 0) {
                        option_str.options[i].selected = true;
                    }
                }
            } catch (err) {

            }

        }
        this.CargaIncialLista = true;
    }

    async TraerGrupos() {//llamada por configuracion
        var SucursalID = GetUserCookie().SucursalID;
        const grupos = await fetch(GetUrlApi() + '/api/Grupos?sucursalID=' + SucursalID);
        const datos = await grupos.json();
        this.setState({ Categorias: datos });
    }

    async TraerProductosPorGrupo() {//llamada por configuracion
        var idCategoria = document.getElementById('cboCateg').value;
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoGrupo?id=' + idCategoria + "&SucursalID=" + SucursalID);
        if (categorias.ok) {
            var datos = await categorias.json();
            console.log(datos);
            this.setState({ Categorias: datos, todosSelected: false });
        }
    }

    async TraerCategorias() {
        var SucursalID = GetUserCookie().SucursalID;
        const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);
        const datos = await categorias.json();
        this.setState({ Categorias: datos, cargandoCategorias: false, prodSeleccionado: false });
    }

    async TraerCategoriasSucursal(sucursalID) {
        const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + sucursalID);
        const datos = await categorias.json();
        this.setState({ Categorias: datos, cargandoCategorias: false, prodSeleccionado: false });
    }

    async TraerCategoriasSucursales() { 
        var sucursales = Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos');
        var dataSucursales = [];
        sucursales.forEach((item) => {
            dataSucursales.push(item.value);
        });
        
        var respuesta = await fetch(GetUrlApi() + '/api/tbgrupoes/GetProductoPorGrupoIdMulti', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataSucursales),
        });
        if (respuesta.ok) {
            var data =await respuesta.json();
            this.setState({ Categorias: data });
        }
    }

    async TraerCategoriasSucursales2() {
        var sucursales = Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos');
        var dataSucursales = [];
        sucursales.forEach((item) => {
            dataSucursales.push(item.value);
        });

        var respuesta = await fetch(GetUrlApi() + '/api/tbgrupoes/GetProductoPorGrupoIdMulti2', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataSucursales),
        });
        if (respuesta.ok) {
            var data = await respuesta.json();
            this.setState({ Categorias: data });
        }
    }

    async CrearPromo() {
        var promocion = {};
        promocion.Descripcion = document.getElementById('txtDesc').value;
        if (promocion.Descripcion==='') {
            swal('Tiene que ingresar una descripción');
            return;
        }
        var dias = Array.apply(null, document.getElementById('cboDias').options).filter(p => p.selected && p.label !== 'Todos');
        var dataDias = [];
        dias.forEach((item) => {
            dataDias.push({
                DiasSemanaID: item.value,
                PromocionID: 0
            });
        });
        promocion.DiasPromocion = dataDias;
        if (promocion.DiasPromocion.length===0) {
            swal('Tiene que seleccionar almenos un día');
            return;
        } 
        promocion.HoraDesde = document.getElementById('txtHoraDesde').value;
        promocion.HoraHasta = document.getElementById('txtHoraHasta').value;
        if (promocion.HoraDesde === '' || promocion.HoraHasta === '') {
            swal('La hora seleccionada no es válida');
            return;
        }
        promocion.DetallePrecioPromocion = {
            Descripcion: '',
            DescPorc: this.TipoPromoSeleccionado.isPorc,
            PrecioFijo: this.TipoPromoSeleccionado.isPrecioFijo,
            MontoPorc: null,
            MontoPrecioFijo:null
        };
        if (promocion.DetallePrecioPromocion.DescPorc) {//SI ES POR PORCENTAJE
            promocion.DetallePrecioPromocion.MontoPorc = Number( document.getElementById('txtMonto').value);
            if (this.TipoPromoSeleccionado.reducePrecio) {
                promocion.DetallePrecioPromocion.MontoPorc = promocion.DetallePrecioPromocion.MontoPorc * -1;
            }
        }
        else if (promocion.DetallePrecioPromocion.PrecioFijo && this.TipoPromoSeleccionado.aumentaPrecio && this.TipoPromoSeleccionado.reducePrecio) {//SI ES CAMBIO DE PRECIO POR MONTO FIJO
            promocion.DetallePrecioPromocion.MontoPrecioFijo = Number(document.getElementById('txtMonto').value);
            if (this.TipoPromoSeleccionado.reducePrecio) {
                promocion.DetallePrecioPromocion.MontoPrecioFijo = promocion.DetallePrecioPromocion.MontoPrecioFijo * -1;
            }
        }
        else if (this.TipoPromoSeleccionado.isPrecioFijo && !this.TipoPromoSeleccionado.aumentaPrecio && !this.TipoPromoSeleccionado.reducePrecio) {//SI ES FIJA POR MONTO EXACTO
            promocion.DetallePrecioPromocion.montoPrecioFijo = Number(document.getElementById('txtMonto').value);
            promocion.DetallePrecioPromocion.DescPorc = false;
            promocion.DetallePrecioPromocion.PrecioFijo = false;
            promocion.DetallePrecioPromocion.PrecioFijoExacto = true;
        }
        if (!document.getElementById('txtMonto').value) {
            swal('Monto no válido');
            return;
        }
        promocion.IsHabilitado = true;
        var sucursales = Array.apply(null, document.getElementById('cboSucursales').options).filter(p => p.selected && p.label !== 'Todos');
        var dataSucursales = [];
        sucursales.forEach((item) => {
            dataSucursales.push({
                SucursalID: item.value,
                PromocionID:0
            });
        });
        promocion.sucursales = dataSucursales; 

        var productos = Array.apply(null, document.getElementById('cboProd').options).filter(p => p.selected && p.label !== 'Todos');
        var dataProds = [];
        productos.forEach((item) => {
            dataProds.push({
                TbProductoID: item.value,
                PromocionID:0
            });
        });
        promocion.Productos = dataProds;
        if (promocion.Productos.length === 0) {
            swal('Tiene que seleccionar almenos un producto');
            return;
        }
        promocion.TipoPromocionID = document.getElementById('cboTipoPromo').value; 

        var respuesta = await fetch(GetUrlApi() + '/api/Promociones', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(promocion),
        });
        if (respuesta.ok) {
            swal('Promocion creada con exito').then(() => {
                this.Recargar();
            });
        }
    }

    

    //-------->FIN FUNCIONES CRUD

    //-------->INICIO FUNCIONES ONCHANGE
    async CambioSucursal() {
        
        var elem = document.getElementById('cboSucursales').value; 
        if (elem === '0') {
            await this.setState({ sucursalTodosSelected:true});
        }
        else {
            
            await this.setState({ sucursalTodosSelected: false });
        }
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerCategoriasSucursales2(elem);
        } else {
            this.TraerCategoriasSucursales(elem);
        }
        
    }

    CambioDias() {
        var elem = document.getElementById('cboDias').value;
        if (elem === '0') {
            this.setState({ diasTodosSelected: true });
        }
        else {
            this.setState({ diasTodosSelected: false });
        }
    }

    CambioTipoPromo() {
        var tipoPromoID = document.getElementById('cboTipoPromo').value;
        this.TipoPromoSeleccionado = this.state.tiposPromocion.find(p => p.id === Number( tipoPromoID));
    }
    //-------->FIN FUNCIONES ONCHANGE

        //-------->INICIO FUNCIONES ONCLICK

    
        //-------->FIN FUNCIONES ONCLICK
    Estilos = {
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'bottom',
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
            'margin-left': '3%'
        },
        Space:
        {
            'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '65%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px'
        }
    }

    ChangeCategoria() {
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerProductosGrupo();
        } else {
            this.TraerProductos();
        }
    }

    GetHtmlPopupCrearPromocion() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                onClose={() => { this.setState({ activarAgregarPromo: false }) }}
                closeOnDocumentClick>
                <div style={{ marginLeft: "15px" }} className="row">
                    <div style={{ width: "60%" }}>
                        <form>
                            <div class="form-group">
                                <label for="txtDesc">Descripcion</label> 
                                <input className="form-control" id="txtDesc" type="text" />
                            </div>
                            
                            <div class="form-group">
                                <label for="cboDias">Día o dias</label>
                                <select className="form-control" id="cboDias" multiple onChange={() => { this.CambioDias() }}>
                                    {
                                        this.state.diasSemana.map((dia) => {
                                            return (<option selected={this.state.diasTodosSelected} value={dia.id}>{dia.descripcion}</option>);
                                        })
                                    }
                                    <option value="0">Todos</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="inputAddress">Hora de inicio</label>
                                <input className="form-control" id="txtHoraDesde" type="time" />
                            </div>
                            <div class="form-group">
                                <label for="inputAddress2">Hora de termino</label>
                                <input className="form-control" id="txtHoraHasta" type="time" />
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputCity">Locales</label>
                                    <select id="cboSucursales" className="form-control" onChange={() => { this.CambioSucursal() }} multiple>
                                        {this.state.sucursales.map((item, i) => {
                                            return (<option selected={this.state.sucursalTodosSelected} value={item.id}>{item.descripcion}</option>);
                                        })}
                                        <option value="0">Todos</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    
                                </div>

                            </div>
                            <div class="form-group">
                                
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputCity">Precio</label>
                                        <select id="cboTipoPromo" className="form-control" onChange={() => { this.CambioTipoPromo() }}>
                                            {
                                                this.state.tiposPromocion.map((item) => {
                                                    return (<option  value={item.id}>{item.descripcion}</option>);
                                                })
                                            }
                                        </select>
                                    </div>
                                        <div class="form-group col-md-4">
                                            <label for="inputState">Monto</label>
                                        <input className="form-control" id="txtMonto" type="number" min="0" />
                                        
                                        </div>
                                        
                                 </div>
                                        
                                        
                                
                            </div>
                        </form>
                        <button onClick={() => { this.CrearPromo() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                            <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                    </div>

                    <div style={{ width: "40%" }}>
                        <label>Seleccione los productos que desea agregar</label>
                        <table>
                            <tr>
                                <td rowSpan="5">

                                    <select className="form-control" id="cboCateg" style={{ 'overflow': 'auto', width: '100%', cursor: 'pointer' }} size="15" onChange={() => { this.ChangeCategoria() }}>
                                        <option hidden disabled selected value> -- select an option -- </option>
                                        {
                                            this.state.Categorias.map((item, i) => {
                                                const id = 'optCatef' + i;
                                                return (<option sucursalID={item.sucursalID} key={id} value={item.id}>{(GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (item.descripcion) : (item.desgrup)}</option>)
                                            })
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" id="cboProd" style={{ 'overflow': 'auto', width: '100%', float: 'right', cursor: 'pointer' }} size="15" multiple onChange={() => { this.ClickTodos() }}>

                                        {

                                            this.state.ProductosSeleccionados.map((item, i) => {
                                                const id = 'optCatef' + i;
                                                if (i === this.state.ProductosSeleccionados.length - 1) {
                                                    console.log(item);
                                                    return (
                                                        <React.Fragment>
                                                            <option montoAumento={item.precpred} selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>
                                                            <option value="0">Todos</option>
                                                        </React.Fragment>)
                                                }
                                                else {
                                                    return (<option montoAumento={item.precpred} selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>)
                                                }
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                        </table>
                        {this.state.HoraServidor}
                        
                    </div>
                </div>
                    
                
                
               

            </Popup>
        );
    }

    
    //------->inicio onclick
    ClickAgregarPromo() {
        this.setState({ activarAgregarPromo: true });
        this.TraerSucursales();
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerGrupos();
        } else {
            this.TraerCategorias();
        }
        
    }

    async ClickEditarPromo(promo) {
        await this.setState({ promoSeleccionada: promo, activarEditarPromo: true });
        this.TraerSucursales();
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerGrupos();
        } else {
            this.TraerCategorias();
        }
        
        

    }



   
    async ClickInhabilitarPromocion(promoID) {
        swal({
            title: "¿Está seguro que desea dehabilitar esta promoción?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async(willDelete) => {
            if (willDelete) {
                var respuesta = await fetch(GetUrlApi() + '/api/Promociones/' + promoID, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (respuesta.ok) {
                    swal("Promocion deshabilitada", {
                        icon: "success",
                    });
                    this.Recargar();
                }
                

            }
        });
    }
    ClickTodos() { 
        var idCategoria = document.getElementById('cboProd').value;
        if (idCategoria === '0') {//si no es un elemento traido de bd
            this.setState({ todosSelected: true });
        }
        else {
            this.setState({ todosSelected: false });
        }

    }

    ClickTodos2() {
        this.CargaIncialLista = true;
        var idCategoria = document.getElementById('cboProd').value;
        if (idCategoria === '0') {//si no es un elemento traido de bd
            this.setState({ todosSelected: true });
        }
        else {
            this.setState({ todosSelected: false });
        }

    }
    //------->fin onclick
    GetTextoPrecio(detallePromo) {
        if (detallePromo.descPorc) {
            return detallePromo.montoPorc + '%';
        }
        else if (detallePromo.precioFijo) {
            return '$' + detallePromo.montoPrecioFijo;
        }
        else/* if (detallePromo.precioFijoExacto)*/ {
            return '=$' + detallePromo.montoPrecioFijo;
        }
        return '';
    }

    Recargar() {
        this.setState(this.StateInicial);
        this.TraerPromociones();
        this.TraerDias();
        this.TraerSucursales();
        this.TraerTiposPromocion();
    }

    CerrarPopups() {
        this.setState({ activarEditarPromo:false });
    }

    render() {
        return (
            <div  >
                {
                    (this.state.activarAgregarPromo) ? (this.GetHtmlPopupCrearPromocion()) : (null)
                   
                }
                {
                    (this.state.activarEditarPromo) ? (<PopupEditarPromocion
                        state={this.state}
                        ClickEditarPromo={this.ClickEditarPromo}
                        Recargar={this.Recargar}
                        CerrarPopups={this.CerrarPopups}
                    />) : (null)
                }
                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                <div className="alert alert-info" role="alert">
                    <strong> Promociones</strong>

                </div>

                <button onClick={() => {
                    this.ClickAgregarPromo()
                }}
                    type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                    <i style={this.Estilos.Space} class="fa fa-plus-circle" aria-hidden="true"></i>
                    Agregar Promoción
                        </button>
                {this.state.HoraServidor}

                <table className="table" style={this.Estilos.TableStyle}>
                    <thead>
                        <tr>
                            <td><b>Promoción</b></td>
                            <td><b>Día</b></td>
                            <td><b>Horario</b></td>
                            <td><b>Local</b></td>
                            <td><b>Productos</b></td>
                            <td><b>Precio</b></td>
                            <td><b>Acciones</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.promociones.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item.descripcion}</td>
                                        <td>
                                            <select className="form-control">
                                                {item.diasPromocion.map((item) => {
                                                    if (!item.diasSemana) {
                                                        return;
                                                    }
                                                    return (<option>{item.diasSemana.descripcion}</option>);
                                                })

                                                }
                                            </select>
                                        </td>
                                        <td>{item.horaDesde}-{item.horaHasta}</td>
                                        <td>
                                            <select className="form-control">
                                                {item.sucursales.map((item) => {
                                                    try {
                                                        return (<option>{item.sucursal.descripcion}</option>);
                                                    } catch (err) {
                                                        
                                                    }
                                                    
                                                })

                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control">
                                                {item.productos.map((item) => {
                                                    try {
                                                        return (<option>{item.tbProducto.desprod}</option>);
                                                    }
                                                    catch (err) {

                                                    }
                                                })

                                                }
                                            </select>
                                        </td>
                                        <td>{this.GetTextoPrecio(item.detallePrecioPromocion)}</td>
                                        <td>
                                            <button style={this.Estilos.BtnOpt} onClick={() => { this.setState({ promoSeleccionada: item, activarEditarPromo: true }) }} data-toggle="tooltip" data-placement="right" title="Editar" >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                            <button style={this.Estilos.BtnOpt1} onClick={() => { this.ClickInhabilitarPromocion(item.id) }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                <i class="fa fa-trash " aria-hidden="true"></i></button>

                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

            </div>
        );
    }


}
