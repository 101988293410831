import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import swal from 'sweetalert';
import ReactToExcel from 'react-html-table-to-excel';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';


export class ReporteListadoDeOrdenes extends Component {
    static displayName = ReporteListadoDeOrdenes.name;
    StateInicial = {
        fechaDesdeIngresada: false,
        fechaHastaIngresada: false,
        cargadatos: false,
        resultado: [],
        VerTabla: false,
        SucursalesOpt: [],
        SucSelected: 0,
        ventasPorPlatillo: [],
        ventasNetas: 0,
        descuentos: 0,
        sumPorcentajes: 0,
        sumCantidad: 0,
        tiposVenta: [],
        medios: [],
        ordenes: [],
        ordenesDos: {}

    };
    constructor() {
        super();
        this.state = this.StateInicial;
      
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/GetSucursales', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ SucursalesOpt: datos });
        }
    }

    async TraerTiposVentas() {
        var respuesta = await fetch(GetUrlApi() + '/api/TiposVentas/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ tiposVenta: datos });
        }
    }

    async TraerMediosDePago() {
        var respuesta = await fetch(GetUrlApi() + '/api/tbmedio/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ medios: datos });
        }
    }

    Estilos = {
        FondoDefault: {
            'backgroundColor': '#ecf0f5',
            'heigth': '100%',
        }, ThStyle:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)',
            'font-size': '11px'
        }, 
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '100%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '250px',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '100%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        BordesCard:
        {
            'borderRadius': '25px'
        },
        TarjetaEstadisticas2: {
            'width': '250px',
            'backgroundColor': 'white',
            'height': '350px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '50px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '40px',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '100%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            'textAlign': 'left'
        },
        TdStyleValues: {
            'textAlign': 'center'
        },
        AnchoBox: {
            'width': '350px'
        }, AvgStyle:
        {
            'color': 'red'
        },
        AvgStylePlus:
        {
            'color': 'Green'
        },
        AvgStyleEquals:
        {
            'color': '#F9810A'
        },
        AnchoBoxGraf: {
            'width': '1200px',
            'borderRadius': '25px',
            'margin-left': '-3%'

        },
        BtnExcel:
        {
            'width': '50px',
            'height': '50px',
            'border-radius': '8px',
            'background': '#6e9887',
            'color': '#fff'
        },
        TdStyle:
        {
            'font-size': '12px'
        }
    }
    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('fechaFiltro').value = today;
        document.getElementById('fechaFiltroHasta').value = today;
        document.getElementById('fechaini').value = today;
        document.getElementById('fechafin').value = today;
    }
    Redirigir(url) {
        this.props.history.push(url);
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuario.id != 1) {
            Logout();
            this.Redirigir('/');
        } else {
            this.cargarFechas();
            this.TraerSucursales();
            this.TraerTiposVentas();
            this.TraerMediosDePago();
            this.GetVentasPorPlatillo()
        }


    }



    HacerResponsive() {

        var elements = Array.from(document.getElementsByTagName("*"));
        elements.forEach((item) => {
            item.style.width = '100%';
        });

    }
    async LimpiarState() {
        this.setState({ resultado: [], VerTabla: false, cargadatos: true })

    }
    async GetVentasPorPlatillo() {
        try {
            await this.setState({ cargadatos: true });
            var sucursales = document.getElementById('SucId').value;//--->formatea las sucursales como un array de entreros
            //if (sucursales === "0") {
            //    sucursales = [];
            //    this.state.SucursalesOpt.forEach((sucursal) => {
            //        sucursales.push(sucursal.id);
            //    });
            //} else {
            //    sucursales = [];
            //    const sucursalID = document.getElementById('SucId').value;
            //    sucursales.push(sucursalID);
            //}

            var tipos = document.getElementById('filtroTipo').value;//--->formatea las tipos como un array de entreros 
            //if (tipos === "0") {
            //    tipos = [];
            //    this.state.SucursalesOpt.forEach((sucursal) => {
            //        tipos.push(sucursal.id);
            //    });
            //} else {
            //    tipos = [];
            //    const sucursalID = document.getElementById('filtroTipo').value;
            //    tipos.push(sucursalID);
            //}
            var valorControl = document.getElementById('filtroEstado').value
            var estados = valorControl === '1';//--->formatea los estados como un array de entreros
            //if (estados === "0") {
            //    estados = [];
            //    this.state.SucursalesOpt.forEach((sucursal) => {
            //        estados.push(sucursal.id);
            //    });
            //} else {
            //    estados = [];
            //    const sucursalID = document.getElementById('filtroEstado').value;
            //    estados.push(sucursalID);
            //}

            var MediosDePago = document.getElementById('filtroMedios').value;//--->formatea los medios de pago como un array de entreros (ID) 
            //if (MediosDePago === "0") {
            //    MediosDePago = [];
            //    this.state.SucursalesOpt.forEach((sucursal) => {
            //        MediosDePago.push(sucursal.id);
            //    });
            //} else {
            //    MediosDePago = [];
            //    const sucursalID = document.getElementById('filtroMedios').value;
            //    MediosDePago.push(sucursalID);
            //}

            var data = {};
            data.sucursales = sucursales;
            data.tipos = tipos;
            data.estado = estados;
            data.MediosDePago = MediosDePago;
            data.desde = document.getElementById('fechaFiltro').value;
            data.hasta = document.getElementById('fechaFiltroHasta').value;

            console.log(JSON.stringify(data));
            var respuesta = await fetch(GetUrlApi() + '/api/reporteria/GetListadoOrdenes', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (respuesta.ok) {
                var data = await respuesta.json();
                console.log(data);
                this.setState({
                    ordenes: data,
                    ordenesDos: data,
                    cargadatos: false,
                    VerTabla:true
                });
            }
            else {
                console.log('fallo la llamada ajax de ordenes');
            }
        } catch (err) {

        }
    }

    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;

    }
    convertToCsv(fName, rows) {
        var csv = '';
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            for (var j = 0; j < row.length; j++) {
                var val = row[j] === null ? '' : row[j].toString();
                val = val.replace(/\t/gi, " ");
                if (j > 0)
                    csv += '\t';
                csv += val;
            }
            csv += '\n';
        }
        var cCode, bArr = [];
        bArr.push(255, 254);
        for (var i = 0; i < csv.length; ++i) {
            cCode = csv.charCodeAt(i);
            bArr.push(cCode & 0xff);
            bArr.push(cCode / 256 >>> 0);
        }

        // for UTF-16


        var blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fName);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                var url = window.URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        }
    }

    async ExportarExcel(e) {
      
        var tmpElemento = document.createElement('a');
        // obtenemos la información desde el div que lo contiene en el html
        // Obtenemos la información de la tabla
        var data_type = 'data:application/vnd.ms-excel';
        var tabla_div = document.getElementsByName('OrderTable');
        var tabla_html = "\n";
        tabla_div.forEach(function (n) {
            tabla_html = tabla_html + n.outerHTML.replace(/ /g, '%20') + "\n";
        })
        //var tabla_html = tabla_div.outerHTML.replace(/ /g, '%20');
        tmpElemento.href = data_type + ', ' + tabla_html;
        //Asignamos el nombre a nuestro EXCEL
        tmpElemento.download = 'ReporteListadoOrdenes.xls';
        // Simulamos el click al elemento creado para descargarlo
        tmpElemento.click();

    }
   


    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }


    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <div>
                    <table className="table" >
                        <thead>
                            <th colSpan="3">Reporte Ordenes</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                                <td>Tipo</td>
                                <td hidden>Estado</td>
                                <td>Medio de pago</td>
                            </tr>
                            <tr>
                                <td>
                                    <select id="SucId" className="form-control">
                                        <option value="-1">Todos</option>
                                        {
                                            this.state.SucursalesOpt.map((item, index) => {
                                                return (<option value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde() }} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta() }} className="form-control" type="date" />
                                </td>
                                <td>
                                    <select id="filtroTipo" className="form-control">
                                        <option value="-1">Todos</option>
                                        {
                                            this.state.tiposVenta.map((tipoVenta) => {
                                                return (<option value={tipoVenta.id}>{tipoVenta.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                                <td hidden>
                                    <select id="filtroEstado" className="form-control">
                                        <option value="1">Pagado</option>
                                        <option value="0">No Pagado</option>
                                    </select>
                                </td>
                                <td>
                                    <select id="filtroMedios" className="form-control">
                                        <option value="-1">Todos</option>
                                        {
                                            this.state.medios.map((medio) => {
                                                return (<option value={medio.id}>{medio.desmed}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



                <hr />
                <div className="row">
                    <div className="col-lg-5">
                        <input type="text" id="fechaini" hidden ></input>
                        <input type="text" id="fechafin" hidden></input>
                    </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.GetVentasPorPlatillo() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br />
                <div>
                    <center>
                        {
                            (this.state.cargadatos) ? (
                                <div style={this.Estilos.FondoDefault}>
                                    <center>
                                        <div className="spinner-border text-dark" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                        <p> Cargando...</p>
                                    </center>
                                </div>
                            ) : (null)
                        }
                    </center>
                </div>

                <br />

                <div style={this.Estilos.Bordes}>
                    {(this.state.VerTabla) ? (
                        <div className="container" style={this.Estilos.FullAncho} >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                        <div className="card-body">
                                            <div className="card-title text-left">
                                                <ReactToExcel
                                                    className="btn btn-info"
                                                    table="OrderTable"
                                                    filename="ListadoOrdenes"
                                                    sheet="ListadoOrdenes"
                                                    buttonText="Exportar" />
                                            </div>

                                            <table border="0" className="table table-responsive" id="OrderTable" name="OrderTable">
                                                <thead>
                                                    <tr><th></th></tr>
                                                    <tr>
                                                        <th style={this.Estilos.ThStyle}>Fecha</th>
                                                        <th style={this.Estilos.ThStyle}>Hora</th>
                                                        <th style={this.Estilos.ThStyle}>Tipo Venta</th>
                                                        <th style={this.Estilos.ThStyle}>Tipo Delivery</th>
                                                        <th style={this.Estilos.ThStyle}>N° Orden</th>
                                                        <th style={this.Estilos.ThStyle}>N° Boleta</th>
                                                        <th style={this.Estilos.ThStyle}>Medio de pago</th>
                                                        <th style={this.Estilos.ThStyle}>Total Medio de Pago</th>
                                                        <th style={this.Estilos.ThStyle}>Total Orden</th>
                                                        <th style={this.Estilos.ThStyle}>Descuento</th>
                                                        <th style={this.Estilos.ThStyle}>Propina</th>
                                                        <th style={this.Estilos.ThStyle}>Nombre del cliente</th>
                                                        <th style={this.Estilos.ThStyle}>N°Clientes</th>

                                                        <th style={this.Estilos.ThStyle}>N°Operación</th>
                                                        <th style={this.Estilos.ThStyle}>N°Informativo</th>
                                                        <th style={this.Estilos.ThStyle}>N°Cheque</th>
                                                        <th style={this.Estilos.ThStyle}>N°Tarjeta</th>
                                                        <th style={this.Estilos.ThStyle}>Banco</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.ordenesDos.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{new Date(item.fecha).toLocaleDateString()}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.hora}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.tipoVenta}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.estacion}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.numeroOrden}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.numeroBoleta}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.medioDePago}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.totalRealMedioPago}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.totalOrden}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.descuento}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.propina}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.nombreCliente}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.numeroClientes}</td>

                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.numeroOperacion}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.numeroInformativo}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.numeroCheque}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.numeroTarjeta}</td>
                                                                    <td style={this.Estilos.TdStyle} className="text-rigth">{item.banco}</td>
                                                                    
                                                                </tr>
                                                            );

                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    ) : (null)}</div>
            </div>



        );
    }

}