import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';


export class ArqueoModificarMediosDos extends Component {
    static displayName = ArqueoModificarMediosDos.name;
    IdParametroUrl = 0;
    GavetaName = "";
    TotalMedios = 0;

    constructor(props) {
        super(props);
        this.state = {
            MediosVentas: [],
            cargandoTransacciones: true,
            cargandoResumen: true,
            mediosDePago: [],
            redirijirArqueo: false,
            ordenes: [],
            NumPagina: 1,
            RegPorPagina: 5,
            resumenMedios: [],
            gaveta: {},
            total: 0,
            totalPropinas: 0
        }
        this.TraerMediosDePago();
    }

    //async CargarDatos() {
    //    await this.TraerMediosDePago();

    //}

    componentDidMount() {

        //const urlParams = new URLSearchParams(this.props.location.search)
        console.log(this.props);
        this.IdParametroUrl = this.props.gavetaId;
        this.GavetaName = this.props.gavetaName;
        this.TraerGaveta();
        //this.TraerResumen();
        this.TraerMediosOrden();
        //    .then(() => {
        //    this.CargarDatos();
        //});
    }

    //async  TraerOrdenes() {
    //    var respuesta = await fetch(GetUrlApi() + '/api/Orden', {
    //        method: 'get',
    //        headers: {
    //            'Content-Type': 'application/json',
    //        }
    //    });
    //    if (respuesta.ok) {
    //        var datos = await respuesta.json();
    //        var MediosVentas2 = this.state.MediosVentas;
    //        console.log("Medios--->");
    //        console.log(MediosVentas2);

    //        MediosVentas2.forEach((item) => {
    //            item.orden = datos.find((element) => {
    //                return element.id === item.OrdenID;
    //            });
    //        });
    //        this.setState({ MediosVentas: MediosVentas2 });
    //    }
    //    else {
    //        alert('error');
    //    }
    //}

    async TraerMediosOrden(url) {

        var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden/GetMediosPorOrdenGaveta?gavetaID=' + this.IdParametroUrl + '&sucursalID=' + GetUserCookie().SucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();

            //datos = datos.filter(p => p.orden.cierreCajaID === null && p.isArqueoPendiente === true && p.orden.gavetaID == this.IdParametroUrl && p.orden.isAnulada && !p.orden.pagado && !p.orden.isPendiente);
            console.log(datos);
            var cont = 0;
            var propinas = 0;
            datos.forEach((item) => {
                cont += item.totalReal;
                propinas += item.montoPropina;
            });
            
            this.setState({ MediosVentas: datos, cargandoTransacciones: false, total: cont, totalPropinas: propinas });
        }
        else {
            this.setState({ cargandoTransacciones: false });
            alert('error');
        }
    }

    async TraerMediosDePago() {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/tbmedio');
            if (!categorias.ok) {
                throw new Error('problema al traer los medios de pago');
            }
            var datos = await categorias.json();
            this.setState({ mediosDePago: datos, cargandoMedios: false });
        } catch (err) {
            console.log(err);
        }
    }

    

    async TraerGaveta() {
        var gaveta = await fetch(GetUrlApi() + '/api/gavetas/' + this.IdParametroUrl);

        if (gaveta.ok) {
            var datos = await gaveta.json();
            this.setState({ gaveta: datos });
        }
        else {
            console.log('traer gaveta se cayó');
        }
    }

    ClickCambiarCboMedioPago(idOrdenMedio, idCbo) {
        if (document.getElementById(idCbo).value === '--') {
            swal('Opción no válida');
        } else {

            swal({
                title: "¿Esta seguro que desea modificar el medio de pago?",
                text: "Se cambiara el medio de pago actual",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {

                    await this.EditarMedioDePago(idOrdenMedio, idCbo);
                    swal("El medio de pago se a cambiado con exito", {
                        icon: "success",
                    });
                }
            });
        }
    }

    ClickSiguiente() {//se usaba para la paginacion
        const sgtPagina = this.state.NumPagina + 1;
        // if (sgtPagina < this.state.MediosVentas.length / this.state.RegPorPagina)
        this.setState({ NumPagina: sgtPagina });
    }

    ClickAnterior() {//se usaba para la paginacion
        const paginaAnterior = this.state.NumPagina - 1;
        if (paginaAnterior > 0)
            this.setState({ NumPagina: paginaAnterior });
    }

    async EditarMedioDePago(idOrdenMedio, idcbo) {

        try {
            const nuevoId = document.getElementById(idcbo).value;
            var data = {};
            data.tbMedioID = Number(nuevoId);
            data.OrdenID = 0;
            data.Total = 0;
            var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden/' + idOrdenMedio, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (respuesta.ok) {
                this.props.CargarPendientes();
                await this.TraerMediosOrden();
            }
            else {
                alert('error');
            }
        } catch (err) {
            swal('Opción no válida');
        }
    }


    //-----INICIO Estilos

    Estilos = {
        FondoDefault: {
            'background-color': '#ecf0f5',
            'heigth': '100%',
        },
        FullAncho: {
            'width': '100%'
        },
        TercioAncho: {
            'width': '33%'
        },
        CuartoAncho: {
            'width': '25%'
        },
        DosTercioAncho: {
            'width': '66%'
        },
        CabeceraTabla: {
            'fontSize:': '5px'
        },
        Filtros: {
            'border-radius': '25px',
            'background-color': 'white'
        },
        TablaMedios: {
            'overflowY': 'scroll',
            'height': '100px',
            'background-color': '#ecf0f5',
            'position': 'sticky',
            'top': '0'
        },
        //ColTabla1: {
        //    'width': '20%',
        //    'MarginLeft': '10px',
        //    'MarginRigth': '10px',
        //},
        ColTabla2: {
            'width': '80%'
        },
        Encabezado: {
            'background-color': 'white',
            'height': '50%',
            'width': '80%',
            'border-radius': '25px',
            'marginLeft': '100px'
        },
        MarginDiv:
        {
        'marginRight': '1%'
        }

    }
    //-----fin Estilos

    GetHtmlCrudMedioPago() {
        if (this.state.MediosVentas.length > 0) {
            const id = 'medio';
            return (
                this.state.MediosVentas.map((item, index) => {
                    //var nombreMedioPago = '';
                    //try {
                    //    const mediosPago = this.state.mediosDePago;
                    //    nombreMedioPago = mediosPago.filter(mediosPago => mediosPago.codmed === item.tbMediosID);
                    //    nombreMedioPago = nombreMedioPago[0].desmed;
                    //}
                    //catch (err) {
                    //    nombreMedioPago = 'no especificado';
                    //}
                    var idCombobox = 'cbo' + index;
                    const fecha = new Date(item.orden.fecha);
                    return (
                        <tr key={id + 'tr' + index}>
                            <td key={id + 'td1'} style={this.Estilos.CabeceraTabla}>{item.orden.numOrden}</td>
                            <td key={id + 'td2'} style={this.Estilos.CabeceraTabla}>{item.numeroDeOperacion}</td>
                            <td key={id + 'td7'} style={this.Estilos.CabeceraTabla}>{item.numCheque}</td>
                            <td key={id + 'td8'} style={this.Estilos.CabeceraTabla}>{item.numInformativo}</td>
                            <td key={id + 'td9'} style={this.Estilos.CabeceraTabla}>{(item.bancos != null) ? (item.bancos.descripcion):(' ')}</td>
                            <td key={id + 'td3'} style={this.Estilos.CabeceraTabla}>{item.tbMedio.desmed}</td>
                            <td key={id + 'td4'} style={this.Estilos.CabeceraTabla}>{GetPrecioFormateado(item.totalReal)}</td>
                            <td key={id + 'td5'} style={this.Estilos.CabeceraTabla}>{GetPrecioFormateado(item.orden.total)}</td>
                            <td key={id + 'td6'} style={this.Estilos.CabeceraTabla}>{GetPrecioFormateado(item.montoPropina)}</td>

                            <td key={id + 'td8'} style={this.Estilos.CabeceraTabla}>{fecha.toLocaleDateString()}  {item.orden.hora}</td>
                            <td key={id + 'td9' + index} style={this.Estilos.CabeceraTabla}>
                                <form key={id + 'form' + index} className="form-inline">
                                    <div className="form-group mb-2">
                                        <select key={id + 'select' + index} style={this.Estilos.DosTercioAncho} className="form-control" id={idCombobox} >
                                            <option>--</option>
                                            {
                                                this.state.mediosDePago.map((item, index) => {
                                                    return (<option key={id + 'opt' + index} value={item.id}>{item.desmed}</option>);
                                                })
                                            }
                                        </select>
                                        <a href="" onClick={(e) => { e.preventDefault(); this.ClickCambiarCboMedioPago(item.id, idCombobox) }}  ><img alt="" height="20" width="20" src={require('./Imagenes/edit.png')} /></a>

                                    </div>

                                </form>

                            </td>
                        </tr>
                    );
                })
            )
        }
    }

    ClickRedirigirArqueo() {
        this.setState({ redirijirArqueo: true });
    }

    ClickCambiarOrden() {
        const param = document.getElementById('cboOrden').value;
        this.TraerMediosOrden(param);
    }

    GetHoraApertura() {
        try {
            return this.state.gaveta.horaUltApertura.substring(0, 8);
        } catch (err) {
            return '';
        }
    }

    GetHoraCierre() {
        try {
            return this.state.gaveta.horaUltCierre.substring(0, 8)
        } catch (err) {
            return '';
        }
    }

    render() {
        return (
            <div style={this.Estilos.FondoDefault}>
                {
                    (this.state.redirijirArqueo) ? (<Redirect to='/SalidaCajero' />) : (null)
                }
                
                <div>
                    <div className="row">
                        <div className="column" style={this.Estilos.Encabezado}>
                            <div style={this.Estilos.CuartoAncho} className="column">Usuario: {GetUserCookie().username} </div>
                            <div style={this.Estilos.CuartoAncho} className="column">Gaveta: {this.GavetaName}</div>
                            <div style={this.Estilos.CuartoAncho} className="column">Entrada cajero {new Date(this.state.gaveta.fechaUltApertura).toLocaleDateString()}  {this.GetHoraApertura()}</div>
                            <div style={this.Estilos.CuartoAncho} className="column">Salida Cajero {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}</div>
                        </div>

                    </div>
                </div>

                <div style={this.Estilos.Filtros}>
                </div>
                {
                    (this.state.cargandoTransacciones) ? (
                        <div style={this.Estilos.FondoDefault}>
                            <center>
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                <p> Cargando...</p>
                            </center>
                        </div>
                    ) : (null)
                }
                <br />
                {
                    (this.state.cargandoTransacciones) ? (null) : (<div>
                        <div className="form-inline">
                            
                            <div style={this.Estilos.MarginDiv}>
                                Total ventas: {GetPrecioFormateado(this.state.total - (this.state.totalPropinas))} 
                            </div>
                            <div style={this.Estilos.MarginDiv}>
                             Total Propinas: {GetPrecioFormateado(this.state.totalPropinas)}
                            </div>
                        </div>
                        <br />
                    </div>)
                }


                {
                    (this.state.cargandoTransacciones) ? (null) :
                        (
                            <div style={{ 'marginLeft': '0px', 'marginRight': '0px' }} className="row">
                                
                                <div style={this.Estilos.ColTabla2}>
                                    <table style={this.Estilos.TablaMedios} className="table">
                                        <thead>
                                            <tr>
                                                <th>Orden</th>
                                                <th>Operación</th>
                                                <th>Cheque</th>
                                                <th>Inf</th>
                                                <th>Banco</th>
                                                <th>Medio de pago</th>
                                                <th>Monto pagado</th>
                                                <th>Total de la cuenta</th>
                                                <th>Propina</th>
                                                <th>Fecha/Hora Transacción</th>
                                                <th>Editar medio de pago</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.GetHtmlCrudMedioPago()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )

                }

            </div>


        );
    }
}
