import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import swal from 'sweetalert';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';
import Popup from "reactjs-popup";
import { GetUserCookie } from './Globales/Utilidades';
import { Redirect } from 'react-router';
import { setTimeout } from 'timers';

export class KitchenDisplay extends Component {
    AnchoMinimoOrden = '250px';
    AnchoMaximoOrden = '500px';
    FontSizeMax = '';
    FontSizeMin = '';
    FontSizeStrong = 10;
    FontSizeExpandir = 15;
    static displayName = KitchenDisplay.name;
    
    OrdenID = null;
    SucursalID = 0;
    IntervalActualizaOrdenes = null;

    StateInicial = {
        activarNotifOrden: false,
        activarExpandirOrden: false,
        ordenes: [],
        isDividir: false,
        vistaMesa: false,
        vistaDividir: false,
        filtroEstado: false,
        filtroSucursal: false,
        sucursales: [],
        volverAlIncio: false,
        contOrdenesPrevio: 0,
        isCargaInicial: true,//es solo un flag para determinar si es la primera vez que se llama a la funcion traerOrdenes
        traerListas: false,
        ordenSelec: {},
        //--->estados filtros
        filtroZonaImpresionSeleccionado: 0,
        zonasImpresion:[]
        //--->fin estados filtros
    };
    constructor(props) {
        super(props);
        
        this.state = this.StateInicial;
        
    }

    componentDidMount() {
        document.getElementById('navBar').style.display='none';
        var user = GetUserCookie();
        if (user.id === 0 || !user.Sucursal.configPOS.isKitchenDisplay) {
            Logout();
            this.setState({ volverAlIncio: true });
        }
        else {
            this.TraerZonasImpresion();

            this.IntervalActualizaOrdenes = setInterval(() => {
                if (this.state.traerListas) {
                    console.log('traer historico');
                    this.TraerOrdenesHistorico();
                }
                else {
                    console.log('traer normal');
                    this.TraerOrdenes()
                }
                
            }, 4000);
        }
       
    }



    async TraerOrdenes() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdenTiempoDesde?sucursalID=' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok && !this.state.traerListas) {
            var isCargarInicial = this.state.isCargaInicial;
            if (isCargarInicial) {
                isCargarInicial = false;
            }
            var datos = await respuesta.json();
            //console.log(datos);
            if (datos.length > this.state.contOrdenesPrevio && !this.state.isCargaInicial ) {
                //var audio = new Audio(require('./sounds/fart-03.mp3'));
                var audio = new Audio(require('./sounds/bell.mp3'));
                audio.play();
                await this.setState({ activarNotifOrden: true, ordenes: datos });
                setTimeout(() => {
                    this.setState({ activarNotifOrden: false });
                }, 5000);
            }
            this.setState({ ordenes: datos, contOrdenesPrevio: datos.length, isCargaInicial: isCargarInicial });
          
        }
       
    }

    async TraerOrdenesHistorico() {
      
        const sucursalUsuario = GetUserCookie().SucursalID;
        clearInterval(this.IntervalActualizaOrdenes);
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdeneseListas?sucursalID=' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {

            var datos = await respuesta.json();
            this.setState({ ordenes: datos });

        }

    }
    
    async TraerZonasImpresion() {
        var respuesta = await fetch(GetUrlApi() + '/api/ZonaImpresions/' , {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var zonasImpreson = await respuesta.json();
            this.setState({ zonasImpresion: zonasImpreson });

        }

    }


    Estilos = {
        Orden: {
            'width': '18%',
            'backgroundColor': 'rgb(236, 240, 245)',
            //'height': '300px',
            'cursor': 'pointer',
            'margin-left': '1%',
            'overflowY': 'scroll',
            'margin-bottom': '0.5%'
        },
        ButtonStyle:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        FondoBlanco: {
            'backgroundColor': 'white',
            'height': '100%',
        }, parrafoModif: {
            'margin': '0'
        },
        Row2: {
            'overflowY': 'scroll',
          //  'overflowX': 'scroll',
            'height': '600px',
            'marginRight': '1px'
        },
        Inline: {
            'display': 'inline'
        },
        TirarToDerecha: {
            'float': 'rigth'
        },
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '45%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%'
        },
        botonVerTodos:
        {
            'margin-left': '-8%',
            'width': '60%',
            'background-color': '#769e8e',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'color': 'white',
            'margin-top': '5%'
        },
        FullAncho: {
            'width': '50%',
            'margin-left': '-25%'
        },
        MarginTr:
        {
            'margin-bottom': '10%'
        },
        BtnStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        BtnExpandir: {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float':'rigth'
        },
        PopUpStyleExpandir: {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'margin-top': 'auto',
            'margin-right': 'auto',
            'margin-bottom': 'auto',
            'margin-left': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '8px',
            'heigth':'395px'
        },
        DivExpandeOrden:
        {
            'overflow': 'auto',
            'height': '370px'
        },
        DivNotiOrden:
        {
            'overflow': 'auto',
            'height': '370px'
        },
        PopUpStyleNoti: {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '40%',
            'margin': 'auto',
            'margin-top': 'auto',
            'margin-right': 'auto',
            'margin-bottom': 'auto',
            'margin-left': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '8px',
            'heigth': '395px'
        }
    }
    Redirigir(url) {
        this.props.history.push(url);
    }
    //----INICIO ONCLICK
    async ClickOrdenListo(idOrden) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/OrdenLista?idOrdenLista=' + idOrden, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            
            
        }
        else {
            console.log('error al traer las mesas');
        }
        this.TraerOrdenes();
    }

    ClickProductoListo(productoOrdenID) {
        var isFiltro = (this.state.filtroZonaImpresionSeleccionado > 0) ? ('true') : ('false');
        var respuesta = fetch(GetUrlApi() + '/api/ProductosOrden/ProductoListo?id=' + productoOrdenID + '&isFiltroAplicado=' + isFiltro, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {


        }
        else {

        }
    }

    async ClickImprimir2() {
        const idOrden = this.props.IdOrden;
        var stringOrdenes = '';
        this.state.ordenes.forEach((item, i) => {
            stringOrdenes += item.id;
            //if (i < this.state.ordenes.length-1)
            stringOrdenes += ',';
        });
        var myBody = {};

        //myBody.command = "C:\\fbhposprint\\Printer.exe";
        //myBody.parameter = stringOrdenes;
        //myBody.path = "C:\\fbhposprint\\";
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = "print://multiOrden=" + stringOrdenes;
        myBody.path = "C:\\fbhposprint\\";
        console.log(myBody);
        //http://192.168.100.193:4741/fbhpos
        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        });


        console.log(respuesta.json());
    }
        //----FIN ONCLICK

    GetTiempoOrden(tiempo) {
        
        var colorTiempo = 'green';
        const tiempoMax = GetUserCookie().Sucursal.configPOS.tiempoEspMax;
        if (tiempo > tiempoMax) {
            tiempo = '>' + tiempoMax;
            colorTiempo = 'red';
        }
        return (
            <td style={{ 'color': colorTiempo }}>{tiempo}:00 Min </td>
            );
    }

    ExpandirOrden(TablaOrdenID,orden) {
        this.setState({ ordenSelec: orden, activarExpandirOrden:true });
        //var elemento = document.getElementById(TablaOrdenID);
        //var nuevoAncho = '';
        //if (elemento.style.width === this.AnchoMinimoOrden) {
        //    nuevoAncho = this.AnchoMaximoOrden;
        //}
        //else {
        //    nuevoAncho = this.AnchoMinimoOrden;
        //}
        //document.getElementById(TablaOrdenID).style.width = nuevoAncho;
    }

    GetHtmlNotifOrden() {
        var ultOrden = this.state.ordenes[this.state.ordenes.length - 1];
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleNoti}
                position="top center"
                modal
                onClose={() => { this.setState({ activarNotifOrden: false }) }}
                defaultOpen
                closeOnDocumentClick>
                <center>
                    <h4>Se ha ingresado una nueva orden</h4>
                    <div style={this.Estilos.DivNotiOrden}>
                        <table style={this.Estilos.Orden} >
                            <thead>
                                <tr>
                                    <td>#{ultOrden.orden.numOrden}</td>
                                    <td>{ultOrden.orden.usuarios.nombre}</td>
                                    <td>{ultOrden.orden.tiposVenta.descripcion} {(ultOrden.orden.tiposVentaID === 1 && ultOrden.orden.mesaID > 0) ? (ultOrden.orden.mesaID) : (null)}</td>
                                </tr>
                                <tr>
                                    <td>{ultOrden.orden.hora}</td>
                                    <td colSpan="2"><center>  {this.GetTiempoOrden(ultOrden.orden.minutosDesdeApertura)} </center> </td>

                                </tr>
                            </thead>
                            <tbody>
                                {

                                    ultOrden.productosOrdens.map((value, index) => {
                                        var stringModificadores = value.modificadoresProductoComanda;
                                        try {
                                            const zonaFiltro = this.state.filtroZonaImpresionSeleccionado;
                                            if (zonaFiltro > 0) {
                                                if (value.tbProducto.zonaImpresionID === zonaFiltro) {
                                                    if (value.mostrarNomZona) {
                                                        return (
                                                            <React.Fragment>
                                                                <tr name="prodOrden" >
                                                                    <td colSpan="3">{value.tbProducto.zonaImpresion.descripcion}</td>
                                                                </tr>
                                                                <tr name="prodOrden"  >
                                                                    <td>{value.cantidad}</td>
                                                                    <td>  <strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                        return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                                    })}
                                                                    </td>
                                                                    <td> </td>
                                                                </tr>
                                                            </React.Fragment>

                                                        );
                                                    }

                                                    return (
                                                        <tr name="prodOrden"  >
                                                            <td>{value.cantidad}</td>
                                                            <td> <strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                            })}

                                                            </td>
                                                            <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                        </tr>
                                                    );
                                                }
                                            }
                                            else {
                                                if (value.mostrarNomZona) {
                                                    return (
                                                        <React.Fragment>
                                                            <tr name="prodOrden" >
                                                                <td colSpan="3">{value.tbProducto.zonaImpresion.descripcion}</td>
                                                            </tr>
                                                            <tr name="prodOrden">
                                                                <td>{value.cantidad}</td>
                                                                <td>  <strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                    return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                                })}
                                                                </td>
                                                                <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                            </tr>
                                                        </React.Fragment>

                                                    );
                                                }
                                                return (
                                                    <tr name="prodOrden" >
                                                        <td>{value.cantidad}</td>
                                                        <td><strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                            return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                        })}

                                                        </td>
                                                        <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                    </tr>
                                                );
                                            }


                                        } catch (err) {
                                            return (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td> </td>
                                                </tr>
                                            );
                                        }
                                    })
                                }


                            </tbody>
                        </table>
                    </div>
                 

                </center>
            </Popup>
            )
    }


    GetHtmlExpandirOrden() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleExpandir}
                position="top center"
                modal
                onClose={() => { this.setState({ activarExpandirOrden: false }) }}
                defaultOpen
                closeOnDocumentClick>
                <center>
                    <div style={this.Estilos.DivExpandeOrden}>
                        <table style={this.Estilos.Orden} >
                            <thead>
                                <tr>
                                    <td>#{this.state.ordenSelec.numOrden}</td>
                                    <td>{this.state.ordenSelec.orden.usuarios.nombre}</td>
                                    <td>{this.state.ordenSelec.orden.tiposVenta.descripcion} {(this.state.ordenSelec.orden.tiposVentaID === 1 && this.state.ordenSelec.orden.mesaID > 0) ? (this.state.ordenSelec.orden.mesaID) : (null)}</td>
                                </tr>
                                <tr>
                                    <td>{this.state.ordenSelec.hora}</td>
                                    <td colSpan="2"><center>  {this.GetTiempoOrden(this.state.ordenSelec.minutosDesdeApertura)} </center> </td>

                                </tr>
                            </thead>
                            <tbody>
                                {

                                    this.state.ordenSelec.productosOrdens.map((value, index) => {
                                        var stringModificadores = value.modificadoresProductoComanda; 
                                        if (!stringModificadores) {
                                            stringModificadores = [];
                                        }
                                        try {
                                            const zonaFiltro = this.state.filtroZonaImpresionSeleccionado;
                                            if (zonaFiltro > 0) {
                                                if (value.tbProducto.zonaImpresionID === zonaFiltro) {
                                                    if (value.mostrarNomZona) {
                                                        return (
                                                            <React.Fragment>
                                                                <tr name="prodOrden">
                                                                    <td colSpan="3">{value.tbProducto.zonaImpresion.descripcion}</td>
                                                                </tr>
                                                                <tr name="prodOrden"  >
                                                                    <td>{value.cantidad}</td>
                                                                    <td>  <strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                        return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                                    })}
                                                                    </td>
                                                                    <td> </td>
                                                                </tr>
                                                            </React.Fragment>

                                                        );
                                                    }

                                                    return (
                                                        <tr name="prodOrden"  >
                                                            <td>{value.cantidad}</td>
                                                            <td> <strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                            })}

                                                            </td>
                                                            <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                        </tr>
                                                    );
                                                }
                                            }
                                            else {
                                                if (value.mostrarNomZona) {
                                                    return (
                                                        <React.Fragment>
                                                            <tr name="prodOrden" >
                                                                <td colSpan="3">{value.tbProducto.zonaImpresion.descripcion}</td>
                                                            </tr>
                                                            <tr name="prodOrden">
                                                                <td>{value.cantidad}</td>
                                                                <td>  <strong>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                    return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                                })}
                                                                </td>
                                                                <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                            </tr>
                                                        </React.Fragment>

                                                    );
                                                }
                                                return (
                                                    <tr name="prodOrden" >
                                                        <td>{value.cantidad}</td>
                                                        <td><strong>{value.tbProducto.desprod}</strong>{
                                                            stringModificadores.map((mod) => {
                                                                return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                            })}

                                                        </td>
                                                        <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                    </tr>
                                                );
                                            }


                                        } catch (err) {
                                            console.log(err);
                                            return (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td> </td>
                                                </tr>
                                            );
                                        }
                                    })
                                }


                            </tbody>
                        </table>
                    </div>
                  

                </center>
            </Popup>
        )
    }

    render() {
        return (
            <div style={{ 'marginLeft': '10px' }}>
                <button onClick={() => { Logout(); clearInterval(this.IntervalActualizaOrdenes); this.Redirigir('/') }}
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Volver"
                    style={this.Estilos.ButtonStyle}>
                    <i class="fa fa-reply fa-2x" aria-hidden="true"></i></button>
                <button onClick={() => { this.setState({ filtroEstado: true })}}
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Filtros"
                    style={this.Estilos.ButtonStyle}>
                    <i class="fa fa-filter  fa-2x" aria-hidden="true"></i></button>
                
               

                <h1 style={{ 'display': 'inline', float: "right" }}><span className="badge badge-secondary">{this.state.ordenes.length}</span></h1>
                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                {
                    (this.state.activarNotifOrden) ? (this.GetHtmlNotifOrden()) : (null)
                }

                {
                    (this.state.activarExpandirOrden) ? (this.GetHtmlExpandirOrden()) : (null)
                }
                {(this.state.filtroEstado) ? (<div className="col-sm-1">
                    <Popup
                        contentStyle={this.Estilos.PopUpStyle}
                        position="top center"
                        modal
                        onClose={() => { this.setState({ filtroEstado: false }) }}
                        defaultOpen
                        closeOnDocumentClick>
                        <center>

                            <table className="row" style={this.Estilos.FullAncho}>
                                {
                                    this.state.zonasImpresion.map((item, i) => {
                                        return (
                                            <tr style={this.Estilos.MarginTr}>
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => {

                                                    this.setState({ filtroZonaImpresionSeleccionado: item.id, filtroEstado: false });
                                                        
                                                        this.IntervalActualizaOrdenes = setInterval(() => { this.TraerOrdenes() }, 4000)

                                                    }}>
                                                    <button className="btn btn-info"  style={this.Estilos.BtnStyles}>
                                                        <span>
                                                            <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i>
                                                        </span>

                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>{item.descripcion}</span>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </td>
                                            </tr>
                                            );
                                    })
                                }
                                
                                <tr >
                                    <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => {
                                        this.setState({ filtroZonaImpresionSeleccionado: 0, filtroEstado: false, traerListas:false });
                                            this.IntervalActualizaOrdenes = setInterval(() => { this.TraerOrdenes() }, 4000)
                                          }}>
                                        <button className="btn btn-info"  style={this.Estilos.BtnStyles}>
                                            <i class="fa fa-window-close-o fa-2x" aria-hidden="true" ></i>
                                        </button>

                                        <span  style={this.Estilos.SpanStyle}>Sin Filtro</span>

                                    </td>
                                    <td rowspan="3" colSpan="2">
                                        <div></div>
                                    </td>
                                </tr>

                                <tr >
                                    <td onClick={() => { this.TraerOrdenesHistorico(); this.setState({ traerListas: true, filtroEstado:false }) }} style={this.Estilos.SpanStyle} rowspan="3" >
                                        <button className="btn btn-info"  style={this.Estilos.BtnStyles}>
                                            <i class="fa fa-window-close-o fa-2x" aria-hidden="true" ></i>
                                        </button>

                                        <span  style={this.Estilos.SpanStyle}>Listas</span>

                                    </td>
                                    <td rowspan="3" colSpan="2">
                                        <div></div>
                                    </td>
                                </tr>

                            </table>
                            
                        </center>
                    </Popup>
                </div>) : (null)}

              

                <div className="row" style={this.Estilos.Row2}>
                    {
                        this.state.ordenes.map((item, i) => {
                            var OrdenID ='orden'+i;
                            var totalDescuento = item.subtotal - item.total;
                            if (totalDescuento < 0) {
                                totalDescuento = 0;
                            }
                            var totalProductosListos = item.productosOrdens.filter(p => !p.isListo).length;
                            var totalProductosFiltrados = item.productosOrdens.filter(p => p.tbProducto.zonaImpresionID === this.state.filtroZonaImpresionSeleccionado).length;
                            if (this.state.filtroZonaImpresionSeleccionado === 0) {
                                totalProductosFiltrados = 1;
                            }
                            if (/*totalProductosListos === 0 || */totalProductosFiltrados===0) {
                                return;
                            }
                            return (
                                <table id={OrdenID} style={this.Estilos.Orden} >
                                    <thead>
                                        <tr>
                                            <td>#{item.orden.numOrden}</td>
                                            <td>{item.orden.usuarios.nombre}</td>
                                            <td>{item.orden.tiposVenta.descripcion} {(item.tiposVentaID === 1 && item.mesaID > 0) ? (item.mesaID): (null)}</td>
                                        </tr>
                                        <tr>
                                            <td>{item.hora}</td>
                                            {
                                                (!this.state.traerListas) ? (
                                                    <td colSpan="2"><center>  {this.GetTiempoOrden(item.minutosDesdeApertura)} </center> </td>
                                                ): (null)
                                            }
                                           
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {
                                            item.productosOrdens.map((value, index) => {
                                                var stringModificadores = value.modificadoresProductoComanda;
                                                if (!stringModificadores) {
                                                    stringModificadores = [];
                                                }
                                                try {
                                                    const id = 'prodOrden' + index + i;
                                                    const zonaFiltro = this.state.filtroZonaImpresionSeleccionado;
                                                    if (zonaFiltro > 0) {
                                                        console.log('entro en zona filtro >0');
                                                        if (value.tbProducto.zonaImpresionID === zonaFiltro) {
                                                            if (value.mostrarNomZona) {
                                                                return (
                                                                    <React.Fragment>
                                                                        <tr name="prodOrden" id={id} >
                                                                            <td colSpan="3">{value.tbProducto.zonaImpresion.descripcion}</td>
                                                                        </tr>
                                                                        <tr name="prodOrden" id={id} >
                                                                            <td>{value.cantidad}</td>
                                                                            <td>  <strong style={{ fontSize: this.FontSizeStrong + 'px' }}>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                                return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>); 
                                                                            })}
                                                                            </td>
                                                                            <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                   
                                                                );
                                                            }
                                                           
                                                            return (
                                                                <tr name="prodOrden" id={id} >
                                                                    <td>{value.cantidad}</td>
                                                                    <td> <strong style={{ fontSize: this.FontSizeStrong+'px' }}>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                        return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                                    })}

                                                                    </td>
                                                                    <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                                </tr>
                                                            );
                                                        }
                                                    }
                                                    else {
                                                        if (value.mostrarNomZona) {
                                                            return (
                                                                <React.Fragment>
                                                                    <tr name="prodOrden" id={id}>
                                                                        <td colSpan="3">{value.tbProducto.zonaImpresion.descripcion}</td>
                                                                    </tr>
                                                                    <tr name="prodOrden" id={id}>
                                                                        <td>{value.cantidad}</td>
                                                                        <td>  <strong style={{ fontSize: this.FontSizeStrong + 'px' }}>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                            return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                                        })}
                                                                        </td>
                                                                        <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                                    </tr>
                                                                </React.Fragment>

                                                            );
                                                        }
                                                        return (
                                                            <tr name="prodOrden" id={id} >
                                                                <td>{value.cantidad}</td>
                                                                <td><strong style={{ fontSize: this.FontSizeStrong + 'px'}}>{value.tbProducto.desprod}</strong>{stringModificadores.map((mod) => {
                                                                    return (<p style={this.Estilos.parrafoModif}>{mod.descModificador}</p>);
                                                                })}

                                                                </td>
                                                                <td> <input checked={value.isListo} onClick={() => { this.ClickProductoListo(value.id) }} type="checkbox" /></td>
                                                            </tr>
                                                        );
                                                    }
                                                    
                                                    
                                                } catch (err) {
                                                    console.log(err);
                                                    return (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td> </td>
                                                        </tr>
                                                    );
                                                }
                                            })
                                        }
                                        {
                                            (!item.isListo ) ? (
                                                <tr>
                                                    <td>
                                                        {
                                                            (this.state.filtroZonaImpresionSeleccionado === 0) ? (
                                                                <button name="blokCambios" id="btnListo" className="btn btn-success" onClick={() => { this.ClickOrdenListo(item.id) }} style={this.Estilos.BtnGroup}
                                                                    data-toggle="tooltip" data-placement="right" title="Listo">
                                                                    <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
                                                            ): (null)
                                                        }

                                                        
                                                        

                                                    </td>
                                                    <td>
                                                        <button onClick={() => { this.ClickImprimir2() }} data-toggle="tooltip" data-placement="right" title="Imprimir" style={this.Estilos.ButtonStyleDerecha}>
                                                            <i class="fa fa-print  fa-2x" aria-hidden="true"></i></button>
                                                        
                                                    </td>
                                                    <td>
                                                        <button style={this.Estilos.BtnExpandir} onClick={() => { this.ExpandirOrden(OrdenID, item) }}
                                                            data-toggle="tooltip" data-placement="right" title="Expandir">
                                                            <i class="fa fa-expand fa-2x" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ): (null)
                                        }
                                      

                                    </tbody>
                                </table>
                            );
                        })
                    }


                </div>
                
            </div>
        );
    }
}
