import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from '../Globales/VariableGlobales';
import { GetUserCookie } from '../Globales/Utilidades';
import swal from 'sweetalert';
import Popup from "reactjs-popup";

export class PopupEditarFamilia extends Component {
    static displayName = PopupEditarFamilia.name;


    stateInicial = {
        familiaSeleccionada: {}
    }
    constructor(props) {
        super(props);
        this.state = this.stateInicial;
        this.CerrarModalMaster = props.CerrarModalMaster;
    }

    componentDidMount() {
        // this.setState(this.props.state);
    }

    Estilos = {
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
    }

    async CrearFamilia() {
        var producto = {};
        producto.descripcion = document.getElementById('txtDescFamilia').value;
        if (String(producto.Desmod).trim() === '') {
            swal('La descripción no puede esta en blanco', {
                icon: "warning",
            });
            return;
        }
        producto.isHabilitado = true;
        producto.SucursalID = GetUserCookie().SucursalID;
        //producto.UsuIngreso = GetUserCookie().username;
        var respuesta = await fetch(GetUrlApi() + '/api/Familias/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            swal({
                title: "Familia creada con exito",
                icon: "success",
            }).then(() => {
                this.props.Recargar();
            })
        }
    }

    async ClickListoEditar() {

        var familia = this.props.familiaSeleccionada;
        familia.descripcion = document.getElementById('txtDescFamilia').value;
        if (String(familia.descripcion).trim() === '') {
            swal('La descripción no puede esta en blanco', {
                icon: "warning",
            });
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/Familias/' + familia.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(familia),
        });
        if (respuesta.ok) {
            swal(
                {
                    title: "Familia editada con exito",
                    icon: "success",
                }
            ).then(() => {
                this.props.Recargar();
            });
        }

    }



    render() {

        return (
            <Popup
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.props.CerrarModalMaster() }}
                closeOnDocumentClick>

                <center>
                    <table>
                        <tbody>
                            <tr>
                                <td>Descripción</td>
                                <td>
                                    <input defaultValue={this.props.familiaSeleccionada.descripcion} className="form-control" id="txtDescFamilia" type="text" />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }
}
