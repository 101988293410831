import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

import Popup from "reactjs-popup";


export class MantenedorModificadoresMaestro extends Component {
    static displayName = MantenedorModificadoresMaestro.name;

    StateInicial = {
        usuarios: [],

        //-----estados popup
        activarAgregarUsuario: false,
        activarEditarModif: false,
        //------estados popup

        //------estados crear usuario
        tiposUsuarios: [],
        sucursales: [],
        cajas: [],
        cajasFiltradasSucursal: [],

        //------fin estados crear usuario

        //------estados editar usuario
        usuarioSeleccionado: {}
        //------fin estados editar usuario
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerModif();
    }

    //-------->INICIO FUNCIONES CRUD
    async TraerModif() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/TraerModificadoresMaestro?sucursalID=' + sucursalUsuario, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ usuarios: datos });
        }
    }

    async CrearModif() {
        var producto = {};
        producto.Desmod = document.getElementById('txtDescMod').value;
        producto.Habilita = 1;
        producto.IsProducto = false;
        producto.RelProdModID = null;
        producto.SucursalID = GetUserCookie().SucursalID;
        producto.UsuIngreso = GetUserCookie().username;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            this.TraerModif();
            swal({
                title: "Modificador creado con exito",
                icon: "success",
            }).then(() => {
                this.setState({ activarAgregarUsuario: false });
            })
        }
    }

    async TraerOpcionesCrear() {
        const sucursalUsuario = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/usuarios/TraerOpcionesCrearUsuario', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({
                tiposUsuarios: datos.tiposUsuario,
                sucursales: datos.sucursales,
                cajas: datos.cajas,
                cajasFiltradasSucursal: datos.cajas
            });
        }
    }
    //-------->FIN FUNCIONES CRUD

    Estilos = {
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
            'margin-left': '3%'
        },
        Space:
        {
            'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px'
        }
    }

    GetHtmlPopupCrearUser() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.setState({ activarAgregarUsuario: false }) }}
                closeOnDocumentClick>
                <center>
                    <table>
                        <tbody>
                            <tr>
                                <td>Descripción</td>
                                <td>
                                    <input className="form-control" id="txtDescMod" type="text" />
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <button onClick={() => { this.CrearModif() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupEditarModif() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.setState({ activarEditarModif: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Editar Usuario</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Descripción</td>
                                <td>
                                    <input className="form-control" id="txtDescMod" type="text" defaultValue={this.state.usuarioSeleccionado.desmod} />
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    //------->inicio onclick
    ClickAgregarUsuario() {
        this.setState({ activarAgregarUsuario: true });
    }

    ClickEditarUsuario(usuario) {
        this.setState({ usuarioSeleccionado: usuario, activarEditarModif: true });
    }

    async ClickListoEditar() {
        var modif = this.state.usuarioSeleccionado;
        modif.Desmod = document.getElementById('txtDescMod').value; 
        modif.Habilita = 1;
        modif.IsProducto = false;
        modif.RelProdModID = null;
        modif.SucursalID = GetUserCookie().SucursalID;
        modif.UsuIngreso = GetUserCookie().username;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/' + modif.id, {
            method: 'put', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(modif),
        });
        if (respuesta.ok) {
            swal('Modificador editado con exito').then(() => {
                this.Recargar();
            });
        }

    }

    async ClickInhabilitarModif(modifID) {
        swal({
            title: "¿Está seguro que desea dehabilitar este usuario?",
            text: "Si confirma el usuario no podra volver a iniciar sesion",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Modificador deshabilitado", {
                    icon: "success",
                }).then(async () => {
                    var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/InhablitarModif?id=' + modifID, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        this.Recargar();
                    }
                });
            } 
        });
    }
    //------->fin onclick


    Recargar() {
        this.setState(this.StateInicial);
        this.TraerModif();
    }

    render() {
        return (
            <div  >
                {
                    (this.state.activarAgregarUsuario) ? (this.GetHtmlPopupCrearUser()) : (null)
                }
                {
                    (this.state.activarEditarModif) ? (this.GetHtmlPopupEditarModif()) : (null)
                }
                <div className="alert alert-info" role="alert">
                    <strong> Modificadores</strong>

                </div>

                <button onClick={() => {
                    this.ClickAgregarUsuario();
                        this.TraerOpcionesCrear()
                }} type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                    <i style={this.Estilos.Space} class="fa fa-plus-circle" aria-hidden="true"></i>
                    Agregar Modificador
                        </button>


                <table className="table" style={this.Estilos.TableStyle}>
                    <thead>
                        <tr>
                            <td><b>Descripcion</b></td>
                            <td><b>Fecha de creación</b></td>
                            <td><b>Sucursal</b></td>
                            <td><b></b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.usuarios.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item.desmod}</td>
                                        <td>{new Date(item.fecIngreso).toLocaleDateString()} </td>
                                        <td>{item.sucursal.descripcion}</td>
                                        <td>
                                            <button style={this.Estilos.BtnOpt} onClick={() => { this.ClickEditarUsuario(item); this.TraerOpcionesCrear() }} data-toggle="tooltip" data-placement="right" title="Editar" >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                            <button style={this.Estilos.BtnOpt1} onClick={() => { this.ClickInhabilitarModif(item.id) }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                <i class="fa fa-trash " aria-hidden="true"></i></button>

                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

            </div>
        );
    }


}
