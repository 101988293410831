import React, { Component } from 'react';
import { Route } from 'react-router';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { TecladoNumerico } from './TecladoNumerico';

export class PopupCuentaCasa extends Component {
    static displayName = PopupCuentaCasa.name;
    CuentaCasaSelected = {};
    constructor() {
        super();
        this.state = {
            CuentasCasa: [],
            mostrarInfo: false,
            activarTeclado: false
        }
        this.TraerCuentasCasa();
    }

    async TraerCuentasCasa() {
        const urlApi = GetUrlApi() + '/api/CuentaCasas/';
        const usuario = await fetch(urlApi);
        if (usuario.ok) {
            var datos = await usuario.json();
            this.setState({ CuentasCasa: datos });
        }
    }

    Estilos= {
        MarginDv:
        {
            'margin-bottom': '2%',
            'width': '23%',
            'align-content': 'center',
            'margin-left': '-10%'
        },
        STextStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'font-size': '11px',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        BtnDStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        BtnGroup:
        {
            'height': '100%',
            'width': '30%',
            'border-color': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'margin-top': '2%'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '35%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '8px',
            'margin-left':'20%'
        },       
        PopUpStyleInfo:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '8px',
            'margin-left': '6%'

        }
    }

    GetHtmlInfoCliente() {

        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.PopUpStyleInfo}
                onClose={() => { this.props.DesactivarModalesPagar() }}
                closeOnDocumentClick>
                <center>
                    <h3>Revisar info cliente</h3>
                    <table>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td>Nombre del cliente</td>
                                <td> <input type="text" value={this.CuentaCasaSelected.nombre} /> </td>


                            </tr>
                            <tr>
                                <td>Cliente desde</td>
                                <td> <input type="text" value={this.CuentaCasaSelected.fechaCreacion}/></td>
                            </tr>
                            <tr>
                                <td>Total n° de ordenes</td>
                                <td> <input type="text" value={this.CuentaCasaSelected.totalNordenes}/></td>
                            </tr>
                            <tr>
                                <td>Total gastado</td>
                                <td> <input type="text" value={this.CuentaCasaSelected.totalGastado}/></td>
                            </tr>
                            <tr>
                                <td>Promedio de la orden</td>
                                <td> <input type="text" value={this.CuentaCasaSelected.promedioOrden}/></td>
                            </tr>
                            <tr>
                                <td>Fecha de la ultima orden</td>
                                <td> <input type="text" value={this.CuentaCasaSelected.fechaUltOrden}/></td>
                            </tr>
                            <tr>
                                <td>Total de la ultima orden</td>
                                <td> <input type="text" value={this.CuentaCasaSelected.totalUltOrden}/></td>
                            </tr>
                            <tr>
                                <td>Notas del cliente</td>
                                <td> <input type="text" /></td>
                            </tr>
                        </tbody>
                    </table>

                    <button name="blokCambios" id="btnListo" className="btn btn-success" onClick={() => { this.setState({ activarTeclado: true, mostrarInfo: false }) }} style={this.Estilos.BtnGroup}
                        data-toggle="tooltip" data-placement="right" title="Listo">
                        <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>


                </center>
            </Popup>
            );
    }

    render() {
        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.PopUpStyle}
                onClose={() => { this.props.DesactivarModalesPagar() }}
                closeOnDocumentClick>
                <center>
                    {(this.state.activarTeclado) ?
                        (<TecladoNumerico IsEfectivo={false}
                            isNumOperacion={false}
                            AgregarPago={this.props.AgregarPago}
                            total={this.props.total}
                            mostrarTotal={true}
                            CerrarModalMaster={this.props.DesactivarModalesPagar}
                            cuentaCasaID={this.CuentaCasaSelected.id}
                            ParentComp="Pagar"
                            isCuentaCasa={this.props.isCuentaCasa} />)
                        : (null)
                    }
                    <h3></h3>
                    {
                        (this.state.mostrarInfo) ? (this.GetHtmlInfoCliente()) : (null)
                    }
                    {this.state.CuentasCasa.map((item, index) => {
                        return (
                            <div style={this.Estilos.MarginDv}>
                                <div style={this.Estilos.STextStyle} >
                                    <button className="btn btn-info" onClick={() => { this.CuentaCasaSelected=item; this.setState({ mostrarInfo: true }) }} style={this.Estilos.BtnDStyles}>
                                        <i class="fa fa-user-circle-o fa-2x" aria-hidden="true"></i>
                                    </button>

                                    <span style={this.Estilos.STextStyle}>{item.nombre} {item.apellido}</span>

                                </div>
                            </div>
                        );
                    })

                    }


                    
                </center>
            </Popup>
        );
    }
}
