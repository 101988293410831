import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';

export class Modificadores extends Component {
    static displayName = Modificadores.name;
    ColorBtnClicked = 'rgb(110, 152, 135)';
    ColorBtnClickedRgb = 'rgb(52, 235, 183)';
    Parametros;

    constructor(props) {
        super(props);
        var countCarro = this.props.productos.length;
        if (this.props.parametros)
            this.Parametros = this.props.parametros;
        if (this.props.ActualizarEstadoMaster) {
            this.ActualizarEstadoMaster = this.props.ActualizarEstadoMaster;
        }
        this.state = {
            ActivarTodos: false,
            ActivarIngresoManual: false,
            modificadores: [],
            TodosLosModificadores: [],
            productos: [],
            productoSelect: {},
            indiceProdSelect: countCarro - 1,
            displayModif: ''//contenido del textarea
        }
        this.TraerModificadores();
        
        
    }


    componentDidMount() {
        if (this.Parametros.indiceInicio!==null ) {
            
            const numProductos = this.Parametros.indiceInicio;
            try {
                document.getElementById('todos').style.backgroundColor = '#ccc';
                document.getElementsByName('columna')[numProductos].style.backgroundColor = this.ColorBtnClicked;
            } catch (err) {

            }
            this.setState({ productos: this.props.productos, displayModif: this.GetTextoModif(), indiceProdSelect: numProductos });
            this.ActualizarBotones();
        }
        else {
            const numProductos = document.getElementsByName('columna').length;
            try {
                document.getElementById('todos').style.backgroundColor = '#ccc';
                document.getElementsByName('columna')[numProductos - 1].style.backgroundColor = this.ColorBtnClicked;
            } catch (err) {

            }
            this.setState({ productos: this.props.productos, displayModif: this.GetTextoModif(), indiceProdSelect: numProductos - 1 });
            this.ActualizarBotones();
        }
    }

    ActualizarBotones(indice) {
        try {
            const prod = this.props.productos[indice];
            const modifsSelec = prod.modificadores;
            var botones = document.getElementsByName('modificador');
            if (!modifsSelec) {
                this.ResetColorButtons();
            }
            else {
                botones.forEach(function (boton) {
                    if (modifsSelec.includes(boton.innerText.trim())) {
                        boton.style.backgroundColor = 'rgb(52, 235, 183)';
                    }
                    else {
                        boton.style.backgroundColor = '';
                    }
                });
            }
        } catch (err) {
            console.log('error en actualizar botones'+err);
        }
    }

    GetTextoModif() {
        try {
            const indiceProd = this.state.indiceProdSelect;
            const lista = this.props.productos[indiceProd].modificadores;
            if (lista) {

                var res = '';
                lista.forEach(function (modif) {
                    res += modif;
                    res += '\n';
                });
                return res;
            }
        } catch (err) {
            return ''
        };
    }

    ResetColorButtons() {
        var botones = document.getElementsByName('modificador');
        botones.forEach(function (boton) {
            boton.style.backgroundColor = '';
        });
    }

    

    async TraerModificadores() {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/Tbmodifs');
            if (!categorias.ok) {
                throw new Error('problema al traer las categorias');
            }
            var datos = await categorias.json();
            datos = datos.filter(datos => !datos.isProducto && datos.relProdModID===null);
            await this.setState({ modificadores: datos, TodosLosModificadores: datos });
            this.ActualizarBotones(this.state.indiceProdSelect);
        } catch (err) {
            console.log(err);
        }
        return 0;
    }


    YaFueClickeado(idBtnClc) {
        const btn = document.getElementById(idBtnClc);
        if (btn.style.backgroundColor === 'rgb(52, 235, 183)') {
            btn.style.backgroundColor = '';
            return true;
        }
        return false;
    }

    RemoverModif(indiceProd, nomModif) {
        var productos = this.state.productos;
        const modificadores = productos[indiceProd].modificadores;
        const newModifs = modificadores.filter(modificadores => modificadores !== nomModif);
        productos[indiceProd].modificadores = newModifs;
        var textoTA = '';
        newModifs.forEach(function (valor) {
            textoTA += '\n';
            textoTA += valor;
           
        });
        this.setState({ productos: productos, displayModif: textoTA });
        this.ActualizarEstadoMaster(this.state.productos);
    }

    async FiltrarModificadoresPorIni(inicial) {//filtra por inicial
        await this.setState({ modificadores: this.state.TodosLosModificadores });
        if (inicial !== '*') {
            var modificadores = this.state.TodosLosModificadores;
            console.log(modificadores[0].desmod.charAt(0));
            modificadores = modificadores.filter(modificadores => modificadores.desmod.charAt(0).toLowerCase() === inicial);
            await this.setState({ modificadores: modificadores });
        }
    }

    ClickAddModif(modif, idBtnClc) {
        try {
            if (!this.YaFueClickeado(idBtnClc)) {
                const indice = this.state.indiceProdSelect;
                var prodSelect = this.state.productos;
                if (!prodSelect[indice].modificadores)
                    prodSelect[indice].modificadores = [];
                prodSelect[indice].modificadores.push(modif);
                var modifConcat = '';
                prodSelect[indice].modificadores.forEach(function (valor) {
                    modifConcat += '\n';
                    modifConcat += valor;

                });
                this.setState({ productos: prodSelect, displayModif: modifConcat });
                this.ActualizarEstadoMaster(this.state.productos);
                document.getElementById(idBtnClc).style.backgroundColor = this.ColorBtnClicked;
            }
            else {
                const indiceProductoSeleccionado = this.state.indiceProdSelect;
                this.RemoverModif(indiceProductoSeleccionado, modif);
                this.ActualizarBotones();
            }
        } catch (err) {

        }
    }



    ClickFila(indice) {
        try {
            const columnas = document.getElementsByName('columna');
            columnas.forEach(function (currentValue) {
                currentValue.style.backgroundColor = '';
            });
            const colselect = columnas[indice];
            const prodSelect = this.state.productos[indice];
            var contenidoTA = '';
            if (this.state.productos[indice].modificadores) {
                this.state.productos[indice].modificadores.forEach(function (currentValue) {
                    contenidoTA += currentValue;
                    contenidoTA += '\n';
                });
            }
            if (!contenidoTA) {
                contenidoTA = '';
            }
            colselect.style.backgroundColor = this.ColorBtnClicked;
            
            this.ActualizarBotones(indice);
            this.setState({ prodSelect: prodSelect, indiceProdSelect: indice, displayModif: contenidoTA });
            
            
        } catch (err) {

        }
    }

    async ClickGuardar() {
        document.getElementById('btnGuardar').innerHTML='Procesando';
        document.getElementById('btnGuardar').disabled = true;
        var newModif = document.getElementById('modifManual').value;
        const indice = this.state.indiceProdSelect;
        var prodSelect = this.state.productos;
        if (!prodSelect[indice].modificadores)
            prodSelect[indice].modificadores = [];
        prodSelect[indice].modificadores.push(newModif);
        var modifConcat = '';
        prodSelect[indice].modificadores.forEach(function (valor) {
            modifConcat += '\n';
            modifConcat += valor;
        });
        this.setState({ productos: prodSelect, displayModif: modifConcat, ActivarIngresoManual: false });
        this.ActualizarEstadoMaster(this.state.productos);
        document.getElementById('modifManual').value='';
        document.getElementById('btnGuardar').innerHTML = 'Guardar';
        document.getElementById('btnGuardar').disabled = false;
        //document.getElementById('btnGuardar').innerHTML='Procesando';
        //document.getElementById('btnGuardar').disabled=true;
        //var newModif = document.getElementById('modifManual').value;
        //var data = {};
        //data.codmod = 0;
        //data.desmod = newModif;
        //data.habilita= 0;
        //data.codgrup = 0;
        //data.isProducto = false;
        //var respuesta = await fetch(GetUrlApi() + '/api/Tbmodifs', {
        //    method: 'post',
        //    headers: {
        //        'Content-Type': 'application/json',
        //    },
        //    body: JSON.stringify(data),
        //});
        //if (respuesta.ok) {
        //    await this.setState({ ActivarIngresoManual: false });
        //    this.TraerModificadores().then(() => {
        //        var indice = 0;
                
        //        this.state.TodosLosModificadores.forEach((valor, index) => {
        //            if (valor.desmod === newModif) {
        //                indice = index
        //            }
        //        });
                
        //        const idBtn = data.desmod.substring(1, 4) + indice;
        //        console.log('el id del boton es ' + idBtn);
        //        this.ClickAddModif(newModif, idBtn);
        //    });
            
        //}
        //else {
        //    document.getElementById('btnGuardar').innerHTML = 'Guardar';
        //    document.getElementById('btnGuardar').disabled = false;
        //}
    }
    

    ClickTerminar() {
       // this.props.ActualizarCarroMaster(this.state.productos);
        this.props.CerrarModalMaster();
    }

    //--------ESTILOS
    
    Estilos = {
        
        FullAncho: {
            'width': '100%'
        },
       
        TercioAncho: {
            'width': '33%'
        },
        BotonModif: {
            'width': '33%',
            'height ': '100px',
        },
        ColumnaLeft: {
            'width': '75%',
        },ColumnaRight: {
            'width': '25%',
        },
        EstiloPopup: {
            'width': '70%',
            'height': '93%',
            'margin': 'initial',
            'backgroundColor': '#fff',
            'border-radius': '2%',
            'margin-left':'3%',
            'margin-top': '2%'

        },
        BtnModificadorClicked: {
            'width': '33%',
            'BackgroundColor': this.ColorBtnClicked
        },
        BtnModificador: {
            'width': '25%',
            'height': '22%',
            'border-radius': '5%',
            'margin-right': '1%',
            'margin-bottom': '1%',
            'border-color': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'background': 'rgba(199, 202, 202, 0.19)',
            'margin-left': '2%'
        },
        BtnFiltro: {
            'width': '10%',
            'height': '45px',
            'margin-left': '1%',
            'margin-right': '1%',
            'margin-bottom': '1%',
            'border-color': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'border-radius': '5px',
            'background': 'rgba(199, 202, 202, 0.19)'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '350px',
            'width': '100%'
        },
        BtnTodos:
        {
            'width': '86%',
            'background-color': 'rgb(132, 132, 132)',
            'height': '39px',
            'border-radius': '5px',
            'margin-bottom': '1%',
            'color':'white'
        
        },
       BtnMsje:
        {
            'width': '86%',
           'background-color': 'rgb(111, 109, 109)',
            'height': '39px',
            'border-radius': '5px',
            'margin-bottom': '1%'

        },
        BtnListo:
        {
            'width': '86%',
            'background-color': '#28a745',
            'height': '39px',
            'border-radius': '5px',
            'margin-bottom': '1%',
            'color':'#fff'

        }
    }


    //--------ESTILOS

    //--------Funciones props
    ActualizarEstadoMaster() {
       // alert('no se sobreescribio ActualizarEstadoMaster');
    }
    //--------fin Funciones props
    
    GetHtmlProductos() {
        
    }

    CerrarPopup() {
        this.props.CerrarModalMaster() 
        this.props.ActualizarEstadoMaster({ parametros: { indiceInicio: null } });
    }

    render() {
        return (
            <Popup
                contentStyle={this.Estilos.EstiloPopup}
                modal
                defaultOpen
                onClose={() => { this.CerrarPopup() }}
                closeOnDocumentClick>
                <div className="row">
                    <div style={this.Estilos.ColumnaLeft} className="column">
                        <div style={this.Estilos.Escroleable}>
                            {
                                this.state.modificadores.map((item, i) => {
                                    const idBtn = item.desmod.substring(1, 4) + i;

                                    return <button name="modificador" id={idBtn} onClick={() => { this.ClickAddModif(item.desmod, idBtn) }} style={this.Estilos.BtnModificador}>{item.desmod}</button>
                                })
                            }
                        </div>
                        
                        <hr />
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('a') }}>a</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('b') }}>b</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('c') }}>c</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('d') }}>d</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('e') }}>e</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('f') }}>f</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('g') }}>g</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('h') }}>h</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('i') }}>i</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('j') }}>j</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('k') }}>k</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('l') }}>l</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('m') }}>m</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('n') }}>n</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('ñ') }}>ñ</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('o') }}>o</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('p') }}>p</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('q') }}>q</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('r') }}>r</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('s') }}>s</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('t') }}>t</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('u') }}>u</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('v') }}>v</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('x') }}>x</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('y') }}>y</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('z') }}>z</button>
                        <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('*') }}>*</button>
                        
                    </div>
                    <div style={this.Estilos.ColumnaRight} className="column">
                        <table style={this.Estilos.FullAncho}>

                            <tbody>
                                {
                                    this.props.productos.map((item, i) => {
                                        return <tr onClick={() => { this.ClickFila(i) }} name="columna" key={i}>
                                            <td>{item.cantidad}</td>
                                            <td>{item.desprod} </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        
                        <div id="tabs">
                            {
                                (this.state.ActivarIngresoManual) ? (
                                    <div>
                                        Ingrese el modificador <input className="form-control" style={this.Estilos.FullAncho} type="text" id="modifManual" />
                                        <button id="btnGuardar" onClick={() => { this.ClickGuardar() }} className="btn btn-success" style={this.Estilos.FullAncho}>Guardar</button>
                                        <button id="btnCancelar" onClick={() => { this.setState({ ActivarIngresoManual: false }) }} className="btn btn-danger" style={this.Estilos.FullAncho}>Cancelar</button>
                                    </div>
                                )
                                    : (<div>
                                        <button onClick={() => { this.setState({ ActivarTodos: true }) }} id="todos" style={this.Estilos.BtnTodos}>Todos</button>
                                        <button className="btn btn-secondary" style={this.Estilos.BtnMsje} onClick={() => { this.setState({ ActivarIngresoManual: true }) }}  >Escribir Mensaje</button>
                                        <button style={this.Estilos.BtnListo} className="btn" onClick={() => { this.ClickTerminar() }}>   <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> </button>
                                    </div>)
                            }
                            </div>
                        
                        
                    </div>
                    
                </div>
            </Popup>
        );
    }
}
