import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';
//import ReactDataGrid from 'react-data-grid';

//const columns = [
//    { key: 'id', name: 'ID', sortable : true},
//    { key: 'title', name: 'Title' },
//    { key: 'count', name: 'Count' }];

//const rows = [{ id: 0, title: 'row1', count: 20 }, { id: 1, title: 'row1', count: 40 }, { id: 2, title: 'row1', count: 60 }];



export class ArqueoModificarMedios extends Component {
    static displayName = ArqueoModificarMedios.name;
    IdParametroUrl = 0;
    TotalMedios = 0;
    
    constructor(props) {
        super(props);
        this.state = {
            MediosVentas: [],
            cargandoTransacciones: true,
            cargandoResumen: true,
            mediosDePago: [],
            redirijirArqueo: false,
            ordenes: [],
            NumPagina : 1,
            RegPorPagina: 5,
            resumenMedios: [],
            gaveta: {},
            total:0,
            totalPropinas:0
        }
         this.TraerMediosDePago();
    }

    //async CargarDatos() {
    //    await this.TraerMediosDePago();

    //}

    componentDidMount() {

        const urlParams = new URLSearchParams(this.props.location.search)
        this.IdParametroUrl = urlParams.get('id');
        this.TraerGaveta();
        //this.TraerResumen();
        this.TraerMediosOrden();
        //    .then(() => {
        //    this.CargarDatos();
        //});
    }
    

    async TraerMediosOrden(url) {

        var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden/GetMediosPorOrdenGaveta?gavetaID='+this.IdParametroUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            
            //datos = datos.filter(p => p.orden.cierreCajaID === null && p.isArqueoPendiente === true && p.orden.gavetaID == this.IdParametroUrl && p.orden.isAnulada && !p.orden.pagado && !p.orden.isPendiente);
            //console.log(datos);
            var cont = 0;
            var propinas = 0;
            datos.forEach((item) => {
                cont += item.totalReal;
                propinas += item.montoPropina;
            });

            this.setState({ MediosVentas: datos, cargandoTransacciones: false, total: cont, totalPropinas: propinas});
        }
        else {
            this.setState({  cargandoTransacciones: false });
            alert('error');
        }
    }

    async TraerMediosDePago() {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/tbmedio');
            if (!categorias.ok) {
                throw 'problema al traer los medios de pago';
            }
            var datos = await categorias.json();
            this.setState({ mediosDePago: datos, cargandoMedios: false });
        } catch (err) {
            console.log(err);
        }
    }

    //async TraerResumen() {
    //    var categorias = await fetch(GetUrlApi() + '/api/MediosPorOrden?group=true&idGaveta=' + this.IdParametroUrl);
    //    if (!categorias.ok) {
    //        throw 'problema al traer los medios de pago';
    //    }
    //    if (categorias.ok) {
    //        var datos = await categorias.json();
    //        datos = datos.filter(p => p.cierreCajaID === null || p.isArqueoPendiente === true && p.gavetaID == this.IdParametroUrl);
    //        var total = 0;
    //        var totalPropina = 0;
    //        datos.forEach((item) => {
    //            total += item.totalAgrupado;
    //            totalPropina += item.montoPropina;

    //        });
    //        this.setState({ resumenMedios: datos, cargandoResumen: false, total: total, totalPropinas: totalPropina });
    //    }
        
        
    //}

    async TraerGaveta() {
        var gaveta = await fetch(GetUrlApi() + '/api/gavetas/' + this.IdParametroUrl);
        
        if (gaveta.ok) {
            var datos = await gaveta.json();
            this.setState({ gaveta: datos });
        }
        else {
            console.log('traer gaveta se cayó');
        }
    }

    ClickCambiarCboMedioPago(idOrdenMedio, idCbo) {
        if (document.getElementById(idCbo).value === '--') {
            swal('Opción no válida');
        } else {
            
            swal({
                title: "¿Esta seguro que desea modificar el medio de pago?",
                text: "Se cambiara el medio de pago actual",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {

                    await this.EditarMedioDePago(idOrdenMedio, idCbo);
                    swal("El medio de pago se a cambiado con exito", {
                        icon: "success",
                    });
                }
            });
        }
    }

    ClickSiguiente() {//se usaba para la paginacion
        const sgtPagina = this.state.NumPagina + 1;
       // if (sgtPagina < this.state.MediosVentas.length / this.state.RegPorPagina)
            this.setState({ NumPagina: sgtPagina });
    }

    ClickAnterior() {//se usaba para la paginacion
        const paginaAnterior = this.state.NumPagina - 1;
        if (paginaAnterior>0)
            this.setState({ NumPagina: paginaAnterior });
    }

    async EditarMedioDePago(idOrdenMedio, idcbo) {

        try {
            const nuevoId = document.getElementById(idcbo).value;
            var data = {};
            data.tbMedioID = Number(nuevoId);
            data.OrdenID = 0;
            data.Total = 0;
            var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden/' + idOrdenMedio, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (respuesta.ok) {
                await this.TraerMediosOrden();
            }
            else {
                alert('error');
            }
        } catch (err) {
            swal('Opción no válida');
        }
    }


    //-----INICIO Estilos
   
    Estilos= {
        FondoDefault: {
            'background-color': '#ecf0f5',
            'heigth': '100%',
        },
        FullAncho : {
        'width': '100%'
        },
        TercioAncho : {
            'width': '33%'
        },
        CuartoAncho: {
            'width': '25%'
        },
        DosTercioAncho: {
            'width': '66%'
        },
        CabeceraTabla: {
            'fontSize:': '5px'
        },
        Filtros: {
            'border-radius': '25px',
            'background-color': 'white'
        },
        TablaMedios: {
            'overflowY': 'scroll',
            'height': '100px',
            'background-color': '#ecf0f5',
            'position': 'sticky',
            'top': '0'
        },
        ColTabla1: {
            'width': '20%',
            'MarginLeft': '10px',
            'MarginRigth': '10px',
        },
        ColTabla2: {
            'width': '80%'
        },
        Encabezado: {
            'background-color': 'white',
            'height': '50%',
            'width': '80%',
            'border-radius': '25px',
            'marginLeft': '100px'
        }
        
    }
      //-----fin Estilos

    GetHtmlCrudMedioPago() {
        if (this.state.MediosVentas.length > 0) {
            const id = 'medio';
            return (
                this.state.MediosVentas.map((item, index) => {
                    var nombreMedioPago = '';
                    try {
                        const mediosPago = this.state.mediosDePago;
                        nombreMedioPago = mediosPago.filter(mediosPago => mediosPago.codmed === item.tbMediosID);
                        nombreMedioPago = nombreMedioPago[0].desmed;
                    }
                    catch (err) {
                        nombreMedioPago = 'no especificado';
                    }
                    var idCombobox = 'cbo' + index;
                    const fecha = new Date(item.orden.fecha);
                    return (
                        <tr key={id + 'tr' + index}>
                            <td key={id + 'td1'} style={this.Estilos.CabeceraTabla}>{item.ordenID}</td>
                            <td key={id + 'td2'} style={this.Estilos.CabeceraTabla}>{item.numeroDeOperacion}</td>
                            <td key={id + 'td3'} style={this.Estilos.CabeceraTabla}>{item.tbMedio.desmed}</td>
                            <td  key={id + 'td4'}style={this.Estilos.CabeceraTabla}>{GetPrecioFormateado(item.total)}</td>
                            <td  key={id + 'td5'}style={this.Estilos.CabeceraTabla}>{GetPrecioFormateado(item.orden.total)}</td>
                            <td  key={id + 'td6'}style={this.Estilos.CabeceraTabla}>{GetPrecioFormateado(item.montoPropina)}</td>
                              
                            <td  key={id + 'td8'}style={this.Estilos.CabeceraTabla}>{fecha.toLocaleDateString()}  {item.orden.hora}</td>
                            <td key={id + 'td9' + index} style={this.Estilos.CabeceraTabla}>
                                <form key={id + 'form' + index} className="form-inline">
                                    <div className="form-group mb-2">
                                        <select key={id + 'select' + index} style={this.Estilos.DosTercioAncho} className="form-control" id={idCombobox} >
                                             <option>--</option>
                                             {
                                                 this.state.mediosDePago.map((item,index) => {
                                                     return (<option key={id + 'opt' + index} value={item.id}>{item.desmed}</option>);
                                                 })
                                        }
                                        </select>
                                        <a href="javascript:void(0)" onClick={() => { this.ClickCambiarCboMedioPago(item.id, idCombobox) }}  ><img height="20" width="20" src={require('./Imagenes/edit.png')} /></a>

                                    </div>
                                    
                                </form>

                            </td>
                        </tr>
                    );
                })
            )
        }
    }

    ClickRedirigirArqueo() {
        this.setState({ redirijirArqueo:true });
    }

    ClickCambiarOrden() {
        const param = document.getElementById('cboOrden').value;
        this.TraerMediosOrden(param);
    }

    GetHoraApertura() {
        try {
            return this.state.gaveta.horaUltApertura.substring(0, 8);
        } catch (err) {
            return '';
        }
    }

    GetHoraCierre() {
        try {
            return this.state.gaveta.horaUltCierre.substring(0, 8)
        } catch (err) {
            return '';
        }
    }

    render() {
        return (
            <div style={this.Estilos.FondoDefault}>
                {
                    (this.state.redirijirArqueo) ? (<Redirect to='/SalidaCajero' />) : (null)
                }
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a onClick={() => { this.ClickRedirigirArqueo() }} href="javascript:void(0)">Arqueo</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Editar medios de pagos</li>
                    </ol>
                </nav>

                <div>
                    <div className="row">
                        <div className="column" style={this.Estilos.Encabezado}>
                            <div style={this.Estilos.CuartoAncho} className="column">Usuario: {GetUserCookie().username}</div>
                            <div style={this.Estilos.CuartoAncho} className="column">Gaveta: {this.IdParametroUrl}</div>
                            <div style={this.Estilos.CuartoAncho} className="column">Entrada cajero {new Date(this.state.gaveta.fechaUltApertura).toLocaleDateString()}  {this.GetHoraApertura()}</div>
                            <div style={this.Estilos.CuartoAncho} className="column">Salida Cajero {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}</div>
                        </div>
                        
                    </div>
                </div>
                
                <div style={this.Estilos.Filtros}>
                </div>
                {
                    (this.state.cargandoTransacciones) ? (
                        <div style={this.Estilos.FondoDefault}>
                            <center>
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                <p> Cargando...</p>
                            </center>
                        </div>
                    ): (null)
                }
                <br />
                {
                    (this.state.cargandoTransacciones) ? (null) : (<div>
                        <form className="form-inline">
                            <div className="form-group mb-2">
                                Ordenar por
                            </div>
                            <div className="form-group mx-sm-3 mb-2">
                                <select className="form-control" onChange={() => { this.ClickCambiarOrden() }} id="cboOrden" >
                                    <option value="">Número de orden</option>
                                    <option value="?orderFecha=true">Fecha y hora</option>
                                    <option value="?orderMedio=true">Medio de pago</option>
                                </select>
                            </div>
                            
                        </form>
                        <br/>
                    </div>)
                }


                {
                    (this.state.cargandoTransacciones) ? (null) :
                        (
                            <div className="row">
                                <div style={this.Estilos.ColTabla1}>
                                    <center>
                                       
                                        <p>Total ventas: {GetPrecioFormateado(this.state.total)}</p>
                                        <p>Total Propinas: {GetPrecioFormateado(this.state.totalPropinas)}</p>
                                    </center>
                                </div>
                                <div style={this.Estilos.ColTabla2}>
                                    <table style={this.Estilos.TablaMedios} className="table">
                                        <thead>
                                            <tr>
                                                <th>Numero de orden</th>
                                                <th>Numero de operación</th>
                                                <th>Medio de pago</th>
                                                <th>Monto pagado</th>
                                                <th>Total de la cuenta</th>
                                                <th>Propina</th>
                                                <th>Fecha/Hora Transacción</th>
                                                <th>Editar medio de pago</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.GetHtmlCrudMedioPago()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
               )

                }
                
            </div>


        );
    }
}
