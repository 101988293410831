import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

export class PopupModificadoresProducto extends Component {
    static displayName = PopupModificadoresProducto.name;
    ColorBtnClicked = 'rgb(110, 152, 135)';
    ColorBtnClickedRgb = 'rgb(52, 235, 183)';
    Parametros;
    StateInicial = {
        ActivarIngresoManual: false,
        modificadores: [],
        TodosLosModificadores: [],
        productos: [],
        productoSelect: {},
        indiceProdSelect: 0,
        displayModif: '',//contenido del textarea
        Categorias: [],
        ProductosSeleccionados: [],
        todosSelected: false,
        prodSeleccionado: true,
    };

    constructor(props) {
        super(props);
        if (this.props.parametros)
            this.Parametros = this.props.parametros;
        if (this.props.ActualizarEstadoMaster) {
            this.ActualizarEstadoMaster = this.props.ActualizarEstadoMaster;
        }

        this.state = this.StateInicial;
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {

        }
        this.TraerModificadores();
    }


    async TraerProductos() {
        var idCategoria = document.getElementById('cboCateg').value;
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoPorGrupoId?id=' + idCategoria + "&SucursalID=" + SucursalID);
        if (categorias.ok) {
            var datos = await categorias.json();
            this.setState({ ProductosSeleccionados: datos, todosSelected:false });
        }
    }


    async TraerCategorias() {
         var SucursalID = GetUserCookie().SucursalID;
         const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);
        const datos = await categorias.json();
        this.setState({ Categorias: datos, cargandoCategorias: false, prodSeleccionado:false });
    }


    async TraerGrupos() {//llamada por configuracion
        var SucursalID = GetUserCookie().SucursalID;
        const grupos = await fetch(GetUrlApi() + '/api/Grupos?sucursalID=' + SucursalID);
        const datos = await grupos.json();
        this.setState({ Categorias: datos, cargandoCategorias: false, prodSeleccionado: false });
    }

    async TraerProductosPorGrupo() {//llamada por configuracion
        var idCategoria = document.getElementById('cboCateg').value;
        var SucursalID = GetUserCookie().SucursalID;
        var categorias = await fetch(GetUrlApi() + '/api/tbproductoes/GetProductoGrupo?id=' + idCategoria + "&SucursalID=" + SucursalID);
        if (categorias.ok) {
            var datos = await categorias.json();
            this.setState({ ProductosSeleccionados: datos, todosSelected: false });
        }
    }

    componentDidMount() {
        if (this.Parametros.indiceInicio !== null) {

            const numProductos = this.Parametros.indiceInicio;
            try {
                document.getElementById('todos').style.backgroundColor = '#ccc';
                document.getElementsByName('columna')[numProductos].style.backgroundColor = this.ColorBtnClicked;
            } catch (err) {

            }
            this.setState({ productos: this.props.productos, displayModif: this.GetTextoModif(), indiceProdSelect: numProductos });
            this.ActualizarBotones();
        }
        else {
            const numProductos = document.getElementsByName('columna').length;
            try {
                document.getElementById('todos').style.backgroundColor = '#ccc';
                document.getElementsByName('columna')[numProductos - 1].style.backgroundColor = this.ColorBtnClicked;
            } catch (err) {

            }
            this.setState({ productos: this.props.productos, displayModif: this.GetTextoModif(), indiceProdSelect: numProductos - 1 });
            this.ActualizarBotones();
        }
    }

    ActualizarBotones(indice) {
        try {
            const prod = this.props.productos[indice];
            const modifsSelec = prod.modificadores;
            var botones = document.getElementsByName('modificador');
            if (!modifsSelec) {
                this.ResetColorButtons();
            }
            else {
                botones.forEach(function (boton) {
                    if (modifsSelec.includes(boton.innerText.trim())) {
                        boton.style.backgroundColor = 'rgb(52, 235, 183)';
                    }
                    else {
                        boton.style.backgroundColor = '';
                    }
                });
            }
        } catch (err) {
            console.log('error en actualizar botones' + err);
        }
    }

    GetTextoModif() {
        try {
            const indiceProd = this.state.indiceProdSelect;
            const lista = this.props.productos[indiceProd].modificadores;
            if (lista) {

                var res = '';
                lista.forEach(function (modif) {
                    res += modif;
                    res += '\n';
                });
                return res;
            }
        } catch (err) {
            return ''
        };
    }

    ResetColorButtons() {
        var botones = document.getElementsByName('modificador');
        botones.forEach(function (boton) {
            boton.style.backgroundColor = '';
        });
    }



    async TraerModificadores() {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/Tbmodifs');
            if (!categorias.ok) {
                throw new Error('problema al traer las categorias');
            }
            var datos = await categorias.json();
            datos = datos.filter(datos => !datos.isProducto && datos.relProdModID===null);
            await this.setState({ modificadores: datos, TodosLosModificadores: datos });
            this.ActualizarBotones(this.state.indiceProdSelect);
        } catch (err) {
            console.log(err);
        }
        return 0;
    }


    YaFueClickeado(idBtnClc) {
        const btn = document.getElementById(idBtnClc);
        if (btn.style.backgroundColor === 'rgb(52, 235, 183)') {
            btn.style.backgroundColor = '';
            return true;
        }
        return false;
    }

    RemoverModif(indiceProd, nomModif) {
        var productos = this.state.productos;
        const modificadores = productos[indiceProd].modificadores;
        const newModifs = modificadores.filter(modificadores => modificadores !== nomModif);
        productos[indiceProd].modificadores = newModifs;
        var textoTA = '';
        newModifs.forEach(function (valor) {
            textoTA += '\n';
            textoTA += valor;

        });
        this.setState({ productos: productos, displayModif: textoTA });
        this.ActualizarEstadoMaster(this.state.productos);
    }

    async FiltrarModificadoresPorIni(inicial) {//filtra por inicial
        await this.setState({ modificadores: this.state.TodosLosModificadores });
        if (inicial !== '*') {
            var modificadores = this.state.TodosLosModificadores;
            console.log(modificadores[0].desmod.charAt(0));
            modificadores = modificadores.filter(modificadores => modificadores.desmod.charAt(0).toLowerCase() === inicial);
            await this.setState({ modificadores: modificadores });
        }
    }

    ClickAddModif(modif, idBtnClc) {
        try {
            if (!this.YaFueClickeado(idBtnClc)) {
                this.props.AgregarModificador(modif);
                const indice = this.state.indiceProdSelect;
                var prodSelect = this.state.productos;
                if (!prodSelect[indice].modificadores)
                    prodSelect[indice].modificadores = [];
                prodSelect[indice].modificadores.push(modif);
                var modifConcat = '';
                prodSelect[indice].modificadores.forEach(function (valor) {
                    modifConcat += '\n';
                    modifConcat += valor;

                });
                this.setState({ productos: prodSelect, displayModif: modifConcat });
                this.ActualizarEstadoMaster(this.state.productos);
                document.getElementById(idBtnClc).style.backgroundColor = this.ColorBtnClicked;
            }
            else {
                const indiceProductoSeleccionado = this.state.indiceProdSelect;
                this.RemoverModif(indiceProductoSeleccionado, modif);
                this.ActualizarBotones();
            }
        } catch (err) {

        }
    }



    ClickFila(indice) {
        try {
            const columnas = document.getElementsByName('columna');
            columnas.forEach(function (currentValue) {
                currentValue.style.backgroundColor = '';
            });
            const colselect = columnas[indice];
            const prodSelect = this.state.productos[indice];
            var contenidoTA = '';
            if (this.state.productos[indice].modificadores) {
                this.state.productos[indice].modificadores.forEach(function (currentValue) {
                    contenidoTA += currentValue;
                    contenidoTA += '\n';
                });
            }
            if (!contenidoTA) {
                contenidoTA = '';
            }
            colselect.style.backgroundColor = this.ColorBtnClicked;

            this.ActualizarBotones(indice);
            this.setState({ prodSelect: prodSelect, indiceProdSelect: indice, displayModif: contenidoTA });


        } catch (err) {

        }
    }

    async ClickGuardar() {
        document.getElementById('btnGuardar').innerHTML = 'Procesando';
        document.getElementById('btnGuardar').disabled = true;
        var producto = {};
        producto.Desmod = document.getElementById('modifManual').value;
        if (String(producto.Desmod).trim() === '') {
            swal('No puede crear un mensaje en blanco');
            document.getElementById('modifManual').value = '';
            document.getElementById('btnGuardar').innerHTML = 'Guardar';
            document.getElementById('btnGuardar').disabled = false;
            return;
        }
        producto.Habilita = 1;
        producto.IsProducto = false;
        producto.RelProdModID = null;
        producto.SucursalID = GetUserCookie().SucursalID;
        producto.UsuIngreso = GetUserCookie().username;
        var respuesta = await fetch(GetUrlApi() + '/api/TbModificas/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            this.TraerModificadores();
        }
        document.getElementById('modifManual').value = '';
        document.getElementById('btnGuardar').innerHTML = 'Guardar';
        document.getElementById('btnGuardar').disabled = false;
    }



    async ClickTerminar() {
       
        if (this.state.Categorias.length > 0) {
            var res = Array.apply(null, document.getElementById('cboProd').options).filter(p=>p.selected && p.label!=='Todos');
            //for (var i = 0; i < res.length; i++) {
            await this.props.AgregarModificadores(res);
           
        } else {

        }


        this.props.CerrarModalMaster();
    }

    //--------ESTILOS

    Estilos = {

        FullAncho: {
            'width': '100%'
        },

        TercioAncho: {
            'width': '33%'
        },
        BotonModif: {
            'width': '33%',
            'height ': '100px',
        },
        ColumnaLeft: {
            'width': '75%',
        }, ColumnaRight: {
            'width': '25%',
        },
        EstiloPopup: {
            'width': '70%',
            'height': '93%',
            'margin': 'initial',
            'backgroundColor': '#fff',
            'border-radius': '2%',
            'margin-left': '3%',
            'margin-top': '2%'

        },
        BtnModificadorClicked: {
            'width': '33%',
            'BackgroundColor': this.ColorBtnClicked
        },
        BtnModificador: {
            'width': '25%',
            'height': '22%',
            'border-radius': '5%',
            'margin-right': '1%',
            'margin-bottom': '1%',
            'border-color': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'background': 'rgba(199, 202, 202, 0.19)',
            'margin-left': '2%'
        },
        BtnFiltro: {
            'width': '10%',
            'height': '45px',
            'margin-left': '1%',
            'margin-right': '1%',
            'margin-bottom': '1%',
            'border-color': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'border-radius': '5px',
            'background': 'rgba(199, 202, 202, 0.19)'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '350px',
            'width': '100%'
        },
        BtnTodos:
        {
            'width': '86%',
            'background-color': 'rgb(132, 132, 132)',
            'height': '39px',
            'border-radius': '5px',
            'margin-bottom': '1%',
            'color': 'white'

        },
        BtnMsje:
        {
            'width': '86%',
            'background-color': 'rgb(111, 109, 109)',
            'height': '39px',
            'border-radius': '5px',
            'margin-bottom': '1%'

        },
        BtnListo:
        {
            'width': '86%',
            'background-color': '#28a745',
            'height': '39px',
            'border-radius': '5px',
            'margin-bottom': '1%',
            'color': '#fff'

        }
    }


    //--------ESTILOS

    //--------Funciones props
    ActualizarEstadoMaster() {
        // alert('no se sobreescribio ActualizarEstadoMaster');
    }
    //--------fin Funciones props

    GetHtmlProductos() {

    }

    CerrarPopup() {
        this.props.CerrarModalMaster();
    }

    ClickTodos() {
        var idCategoria = document.getElementById('cboProd').value;
        if (idCategoria === '0') {//si no es un elemento traido de bd
            this.setState({ todosSelected: true });
        }
        else {
            this.setState({ todosSelected: false });
        }
        
    }

    async ClickSelectTipoModif() {
        
        if (this.state.prodSeleccionado) {
            if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
                this.TraerGrupos().then(() => {
                    if (document.getElementById('chkIsCombo')) {
                        document.getElementById('chkIsCombo').checked = true;
                    }
                });
            }
            else {
                this.TraerCategorias().then(() => {
                    if (document.getElementById('chkIsCombo')) {
                        document.getElementById('chkIsCombo').checked = true;
                    }
                });
            }
            
        }
        else {
            this.TraerModificadores();
        }
        await this.setState({ prodSeleccionado: !this.state.prodSeleccionado });
        
    }

    ClickSeleccionarCombo() {
        
        
    }

    ChangeCategoria() {
        if (GetUserCookie().Sucursal.configPOS.isGruposFamilias) {
            this.TraerProductosPorGrupo();
        }
        else {
            this.TraerProductos();
        }
        
    }

    render() {
        return (
            <Popup
                contentStyle={this.Estilos.EstiloPopup}
                modal
                defaultOpen
                onClose={() => { this.CerrarPopup() }}
                closeOnDocumentClick>
                <div className="row">
                    <div style={this.Estilos.ColumnaLeft} className="column">
                        
                           
                            
                        {
                            (this.state.prodSeleccionado) ? (<React.Fragment>
                                <div style={this.Estilos.Escroleable}>
                                {
                                    this.state.modificadores.map((item, i) => {
                                        const idBtn = item.desmod.substring(1, 4) + i;

                                        return <button name="modificador" id={idBtn} onClick={() => { this.ClickAddModif(item.desmod, idBtn) }} style={this.Estilos.BtnModificador}>{item.desmod}</button>
                                    })
                                    }
                                </div>
                            </React.Fragment>) : (

                                    <table style={{ width: '100%', height: '100%' }}>
                                        <tr>
                                            <td>
                                                <h3>{(GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? ('Grupos') : ('Categorías')}</h3>
                                            </td>
                                            <td>
                                                <h3>Productos</h3>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <select id="cboCateg" style={{ 'overflow': 'auto', width: '100%', cursor: 'pointer' }} size="15" onChange={() => { this.ChangeCategoria() }}>
                                                    <option hidden disabled selected value> -- select an option -- </option>
                                                    {
                                                        this.state.Categorias.map((item, i) => {
                                                            const id = 'optCatef' + i;
                                                            return (<option key={id} value={item.id}>{(GetUserCookie().Sucursal.configPOS.isGruposFamilias) ? (item.descripcion) : (item.desgrup)}</option>)
                                                        })
                                                    }
                                                </select>
                                            </td>
                                            <td>
                                                <select id="cboProd" style={{ 'overflow': 'auto', width: '100%', float: 'right', cursor: 'pointer' }} size="15" multiple onChange={() => { this.ClickTodos() }}>

                                                    {
                                                        this.state.ProductosSeleccionados.map((item, i) => {
                                                            const id = 'optCatef' + i;
                                                            if (i === this.state.ProductosSeleccionados.length - 1) {
                                                                return (
                                                                    <React.Fragment>
                                                                        <option montoAumento={item.precpred} selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>
                                                                        <option value="0">Todos</option>
                                                                    </React.Fragment>
                                                                    )
                                                            }
                                                            else {
                                                                return (<option montoAumento={item.precpred} selected={this.state.todosSelected} key={id} value={item.id}>{item.desprod} </option>)
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                )
                        }
                        <hr />
                        {
                            (this.state.prodSeleccionado) ? (
                                <React.Fragment>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('a') }}>a</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('b') }}>b</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('c') }}>c</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('d') }}>d</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('e') }}>e</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('f') }}>f</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('g') }}>g</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('h') }}>h</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('i') }}>i</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('j') }}>j</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('k') }}>k</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('l') }}>l</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('m') }}>m</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('n') }}>n</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('ñ') }}>ñ</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('o') }}>o</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('p') }}>p</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('q') }}>q</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('r') }}>r</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('s') }}>s</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('t') }}>t</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('u') }}>u</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('v') }}>v</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('x') }}>x</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('y') }}>y</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('z') }}>z</button>
                                    <button style={this.Estilos.BtnFiltro} onClick={() => { this.FiltrarModificadoresPorIni('*') }}>*</button>
                                </React.Fragment>
                            ): (null)
                        }
                        

                    </div>
                    <div style={this.Estilos.ColumnaRight} className="column">
                        <table style={this.Estilos.FullAncho}>

                            <tbody>
                                {
                                    this.props.productos.map((item, i) => {
                                        return <tr onClick={() => { this.ClickFila(i) }} name="columna" key={i}>
                                            <td></td>
                                            <td>{item.desprod} </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>

                        <div id="tabs">
                            {
                                (this.state.ActivarIngresoManual) ? (
                                    <div>
                                        Ingrese el modificador <input className="form-control" style={this.Estilos.FullAncho} type="text" id="modifManual" />
                                        <button id="btnGuardar" onClick={() => { this.ClickGuardar() }} className="btn btn-success" style={this.Estilos.FullAncho}>Guardar</button>
                                        <button id="btnCancelar" onClick={() => { this.setState({ ActivarIngresoManual: false }) }} className="btn btn-danger" style={this.Estilos.FullAncho}>Cancelar</button>
                                    </div>
                                )
                                    : (<div>
                                        <button onClick={() => { this.ClickSelectTipoModif() }} id="todos" style={this.Estilos.BtnTodos}>{(this.state.prodSeleccionado) ? ('Productos de venta') : ('Modificadores')}</button>
                                        <button className="btn btn-secondary" style={this.Estilos.BtnMsje} onClick={() => { this.setState({ ActivarIngresoManual: true }) }}  >Crear Mensaje</button>
                                        <button style={this.Estilos.BtnListo} className="btn" onClick={() => { this.ClickTerminar() }}>   <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> </button>
                                        {
                                            (!this.state.prodSeleccionado) ? (
                                                <div  style={{ cursor: 'pointer' }}>
                                                    ¿Tiene precio de venta?
                                                    <center>
                                                        <p style={{ margin: 5 }}>Si &nbsp;<input type="radio" name="rbtPopu" id="chkIsCombo"  /></p>
                                                        <p style={{ margin: 5 }}>No &nbsp;<input type="radio" name="rbtPopu" id="dummy1" /></p>
                                                    </center>
                                                </div>
                                            ): (null)
                                        }
                                        
                                    </div>)
                            }
                        </div>


                    </div>

                </div>
            </Popup>
        );
    }
}
