import React, { Component } from 'react'; 
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

import Popup from "reactjs-popup";

export class MantenedorCuentasCasa extends Component {
    static displayName = MantenedorCuentasCasa.name;
    StateInicial = {
        cuentasCasa: [],
        activarAgregarCC: false,
        activarEditarCC: false,
        CCSeleccionado: {}
    };
    constructor() {
        super();
        this.state = this.StateInicial;
        this.TraerCC();
    }
    //-------->INICIO FUNCIONES CRUD
    async TraerCC() {
        var respuesta = await fetch(GetUrlApi() + '/api/CuentaCasas/' , {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ cuentasCasa: datos });
        }
    }

    async CrearCC() {
        var usuario = {};
        usuario.Nombre = document.getElementById('txtnombre').value;
        usuario.Apellido = document.getElementById('txtApellido').value;
        usuario.IsHabilitado = true;
        usuario.UsuModifica = GetUserCookie().username;
        if (String(usuario.Nombre).trim() === '' || String(usuario.Apellido).trim() === '') {
            swal('No puede ingresar campos en blanco');
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/CuentaCasas', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(usuario),
        });
        if (respuesta.ok) {
            swal('Cuenta de la casa creada con exito').then(() => {
                this.Recargar();
            });
        }
    }

   
    //-------->FIN FUNCIONES CRUD

    //-------->INICIO FUNCIONES ONCHANGE
    CambioSucursal() {
        const sucursalSeleccionada = document.getElementById('cboSucursal').value;
        var nuevasCajas = this.state.cajas.filter(p => p.sucursalID === Number(sucursalSeleccionada));
        console.log(nuevasCajas);
        this.setState({ cajasFiltradasSucursal: nuevasCajas });
    }
    //-------->FIN FUNCIONES ONCHANGE
    Estilos = {
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
            'margin-left': '3%'
        },
        Space:
        {
            'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px',
        },
        StylePopUp1:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px',
        }
    }

    GetHtmlPopupCrearCC() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                
                onClose={() => { this.setState({ activarAgregarCC: false }) }}
                closeOnDocumentClick>
                <center>

                    <h3>Ingrese los siguientes datos</h3>
                    <table>
                        <tbody>
                           
                            <tr>
                                <td>Nombre</td>
                                <td>
                                    <input className="form-control" id="txtnombre" type="text" />
                                </td>
                            </tr>
                            <tr>
                                <td>Apellido</td>
                                <td>
                                    <input className="form-control" id="txtApellido" type="text" />
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <button onClick={() => { this.CrearCC() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i>
                    </button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupEditarCC() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp1}
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.setState({ activarEditarCC: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Editar Usuario</h3>
                    <table>
                        <tbody>
                            
                            <tr>
                                <td>Nombre</td>
                                <td>
                                    <input defaultValue={this.state.CCSeleccionado.nombre} className="form-control" id="txtnombre" type="text" />
                                </td>
                            </tr>
                            <tr>
                                <td>Apellido</td>
                                <td>
                                    <input defaultValue={this.state.CCSeleccionado.apellido} className="form-control" id="txtApellido" type="text" />
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    //------->inicio onclick
    ClickAgregarCC() {
        this.setState({ activarAgregarCC: true });
    }

    ClickEditarCC(CC) {
        this.setState({ CCSeleccionado: CC, activarEditarCC: true });
    }

    async ClickListoEditar() {
        var cc = this.state.CCSeleccionado;
        cc.Nombre = document.getElementById('txtnombre').value;
        cc.Apellido = document.getElementById('txtApellido').value;
        cc.UsuModifica = GetUserCookie().username;
        if (String(cc.Nombre).trim() === '' || String(cc.Apellido).trim() === '') {
            swal('No puede ingresar campos en blanco');
            return;
        }
        var respuesta = await fetch(GetUrlApi() + '/api/CuentaCasas/' + cc.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cc),
        });
        if (respuesta.ok) {
            swal('Cuenta de la casa editada con exito').then(() => {
                this.Recargar();
            });
        }
    }

    async ClickInhabilitarCC(id) {
        swal({
            title: "¿Está seguro que desea dehabilitar esta cuenta de la casa?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Cuenta de la casa deshabilitado", {
                    icon: "success",
                }).then(async () => {
                    var respuesta = await fetch(GetUrlApi() + '/api/CuentaCasas/' + id, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        swal('Cuenta de la casa deshabilitado con exito');
                        this.Recargar();
                    }
                    else {

                    }

                });
            } else {

            }
        });
    }
    //------->fin onclick


    Recargar() {
        this.setState(this.StateInicial);
        this.TraerCC();
    }


    render() {
        return (
            <div>
                {
                    (this.state.activarAgregarCC) ? (this.GetHtmlPopupCrearCC()) : (null)
                }
                {
                    (this.state.activarEditarCC) ? (this.GetHtmlPopupEditarCC()) : (null)
                }
                
                    <div className="alert alert-info" role="alert">
                    <strong> Cuentas de la casa</strong>
                    
                     </div>
                <button onClick={() => {
                    this.ClickAgregarCC()
                }} type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                    <i style={this.Estilos.Space} class="fa fa-plus-circle" aria-hidden="true"></i>
                    Agregar cuenta de la casa</button>
                <table className="table" style={this.Estilos.TableStyle}>
                    <thead>
                        
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Fecha de creacion</th>
                            <th>Acciones</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        {
                            this.state.cuentasCasa.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item.nombre}</td>
                                        <td> {item.apellido}</td>
                                        <td>{new Date(item.fechaCreacion).toLocaleDateString()}</td>
                                        <td>
                                            <button style={this.Estilos.BtnOpt} onClick={() => { this.ClickEditarCC(item) }} data-toggle="tooltip" data-placement="right" title="Editar" >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                            <button style={this.Estilos.BtnOpt1} onClick={() => { this.ClickInhabilitarCC(item.id) }} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                <i class="fa fa-trash " aria-hidden="true"></i></button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                
            </div>
        );
    }
}
