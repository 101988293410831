import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from '../Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from '../Globales/VariableGlobales';
import { GetUserCookie } from '../Globales/Utilidades';
import swal from 'sweetalert';


export class AdministracionModulosSistema extends Component {
    static displayName = AdministracionModulosSistema.name;

    StateInicial = {
        cambiosGuardados:false,
        configuracion: {},
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
    }

    componentDidMount() {
        this.setState({ configuracion: this.props.configuracion });
    }


    Estilos = {
        ButtonStyle:
        {
            'zIndex': '1',
            'border-radius': '10px',
            'height': '55px',
            'width': '55px',
            'borderColor': 'cadetblue',
            'background-color': '#f2f2f2',
            'margin-bottom': '3%'
        },
        icon:
        {
            'marginLeft': '-8%'
        },
    }


    async ClickActualizarConfiguracion() {
        var configuracion = this.props.configuracion;
        configuracion.isParaLlevarHabilitado = document.getElementById('chkParaLlevar').checked;
        configuracion.isMesasHabilitado = document.getElementById('chkMesas').checked;
        configuracion.isBarHabilitado = document.getElementById('chkBar').checked;
        configuracion.isDeliveryHabilitado = document.getElementById('chkDelivery').checked;
        const idConfig = GetUserCookie().Sucursal.configPOS.id;
        var respuesta = await fetch(GetUrlApi() + '/api/ConfigPOS/' + idConfig, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(configuracion),
        });
        if (respuesta.ok) {
            this.setState({ cambiosGuardados: true });
            setTimeout(() => {
                this.setState({ cambiosGuardados: false });
            }, 3000);
        }
    }



    Recargar() {
        this.setState(this.StateInicial);
    }

    render() {
        return (
            <div  >
                <h3><strong> Módulos del sistema</strong></h3>
                {
                    (this.state.cambiosGuardados) ? (
                        <div className="alert alert-success" role="alert">
                            Cambios guardados
                        </div>
                    ): (null)
                }
                
                <br/>
                <div className="row">
                    <div style={{ 'width': '25%' }}>
                        <center>
                            <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" >
                                <i className="fa fa-shopping-basket fa-2x" style={this.Estilos.icon}></i>
                            </button>
                            <br />
                            <label>Para Llevar</label>
                            <br />
                            <label className="switch">
                                <input id="chkParaLlevar" type="checkbox" defaultChecked={this.props.configuracion.isParaLlevarHabilitado} onClick={() => { this.ClickActualizarConfiguracion() }} />
                                <span className="slider round"></span>
                            </label>
                        </center>
                    </div>
                    <div style={{ 'width': '25%' }}>
                        <center>
                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right">
                            <i className="fa fa-cutlery fa-2x"></i>
                            </button>
                            <br />
                            <label>Mesas</label>
                            <br />
                            <label className="switch">
                                <input id="chkMesas" type="checkbox" defaultChecked={this.props.configuracion.isMesasHabilitado} onClick={() => { this.ClickActualizarConfiguracion() }} />
                                <span className="slider round"></span>
                            </label>
                        </center>
                    </div>
                    <div style={{ 'width': '25%' }}>
                        <center>
                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right"  >
                            <i className="fa fa-glass fa-2x"></i>
                            </button>
                            <br />
                            <label>Bar</label>
                            <br />
                            <label className="switch">
                                <input id="chkBar" type="checkbox" defaultChecked={this.props.configuracion.isBarHabilitado} onClick={() => { this.ClickActualizarConfiguracion() }} />
                                <span className="slider round"></span>
                            </label>
                        </center>
                    </div>
                    <div style={{ 'width': '25%' }}>
                        <center>
                            <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right">
                                <i className="fa fa-paper-plane fa-2x" aria-hidden="true"></i>
                            </button>
                            <br />
                            <label>Delivery</label>
                            <br />
                            <label className="switch">
                                <input id="chkDelivery" type="checkbox" defaultChecked={this.props.configuracion.isDeliveryHabilitado} onClick={() => { this.ClickActualizarConfiguracion() }} />
                                <span className="slider round"></span>
                            </label>
                        </center>
                    </div>
                </div> 
               

            </div>
        );
    }


}
