import React, { Component } from 'react'
import { GetUrlApi } from './Globales/VariableGlobales'
import Popup from "reactjs-popup"
import Swal from 'sweetalert2'
import { Row } from 'reactstrap'
import { QRHten } from './QRHten'


export class ProcesarPagos extends Component {
    static displayName = ProcesarPagos.name
    constructor(props) {
        super(props)
        this.state = {
            pagos: [],
            isQR: false,
            medioSelected: {},
            indexSelected: -1,
            htenResult: {},
            qrSignal: {},
            qrController: {},
            visibleFinishBtn: false,
            isProcessing: false,
            isFinishing: false
        }
        if (this.props.finalizarVenta)
            this.finalizarVenta = this.props.finalizarVenta
        
        // this.finalizar = this.finalizarVenta.bind(this);
        // this.setState({ pagos: this.props.pagos })
        //if (this.props.CerrarModal)
         //   this.CerrarModalMaster = this.props.CerrarModalMaster
         

    }

    componentDidMount() { 
        console.log('Mounted')
        this.setState({ pagos: this.props.pagos })
        if(this.props.pagos.length === 1) {
            document.getElementById("btnCancel").disabled = true
            this.ClickPagar(this.props.pagos[0], 0)
        }
    }

    render() {
        return (
            
            <Popup
                onClose={() => { this.clickBtnBack() }}
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                closeOnDocumentClick>
                    <p class='text-center h3'><strong>Procesando Pagos</strong></p>
                    <center>
                        
                    </center>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g==" crossOrigin="anonymous" referrerpolicy="no-referrer" />
                    <div class='container'>
                        <table className='table table-sm'>
                            <thead>
                                <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>Medio de Pago</th>
                                    <th scope='col'>Monto</th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody>
                            {( 
                            
                                this.state.pagos.map((pago, i) => {
                                    return (
                                        <tr>
                                            <th scope='row'>{ i + 1 }</th>
                                            <td>{ pago.medio }</td>
                                            <td>{ this.GetPrecioFormateado(pago.total) }</td>
                                            <td>{ pago.processed? 
                                            (<span class='btn btn-success'><i class="fa-solid fa-check fa-lg"></i></span>)
                                            :
                                            (this.isProcessing(pago, i))?<div className="spinner-border text-dark" role="status"><span className="sr-only"></span></div>:<button type='button' class='btn btn-info' onClick={() => {this.ClickPagar(pago, i)}}> <i class="fa-solid fa-hand-holding-dollar fa-lg"></i></button> } </td>
                                        </tr>
                                    )
                                })
                            )}
                            </tbody>
                        </table>
                        <div class='row justify-content-md-center'>
                            { this.state.visibleFinishBtn? 
                                (!this.state.isFinishing)?
                                    (<button type='button' class='btn btn-primary' onClick={ () => { this.clickBtnFinish() } }><i class="fa fa-check"></i> Finalizar</button>): 
                                    (<div class='container'><div class='row justify-content-md-center'><div className="spinner-border text-dark" role="status"><span className="sr-only"></span></div> Finalizando...</div></div>)
                                :(null) }
                        </div>
                        <div class='row justify-content-md-center'>
                            { this.state.visibleFinishBtn?(null):<button id='btnCancel' type='button' class='btn btn-secondary' onClick={ () => { this.clickBtnBack() } }><i class="fa-solid fa-arrow-left"></i> Volver </button> }
                        </div>
                    </div>
                    { (this.state.isQR)? 
                        (<QRHten
                            medio={ this.state.medioSelected }
                            htenResponse= { this.state.htenResponse }
                            qrCancel= { this.qrCancel.bind(this) } 
                        >

                        </QRHten>):(null)
                        }
            </Popup>
        );
    }

    async ClickPagar(MedioPago, index) {
        // swal('Procesando Pago');
        // const pago = this.state.pagos.filter(pago => pago.id === MedioPago.id)
        // pago.map((localpago) => {
        //     localpago.processed = true
        // })
        document.getElementById("btnCancel").disabled = true
        this.setState({ medioSelected: MedioPago, indexSelected: index, isProcessing: true })
        const procesado = await this.fncProcesarPago(MedioPago)
        if(procesado) {
            await this.state.pagos.map((pago, i) => {
                if(pago.idMedio === MedioPago.idMedio && i === index)
                    pago.processed = true
                    
            })
            this.setState({ pagos: this.state.pagos })   
            this.checkFinishButton() 
        }
        document.getElementById("btnCancel").disabled = false
        this.setState({ isProcessing: false })
    }
    isProcessing(medio, index){
        const result = (medio.idMedio === this.state.medioSelected.idMedio && this.state.isProcessing && this.state.indexSelected === index)?true:false
        return result
    }

    cerrarModal() { }

    CerrarModal2() { }

    CerrarModalMaster() { }

    Estilos = {
        PopUpStyle:
        {
            'width': '65%',
            'height': '65%',
            'background-color': '#ecf0f5',
            'border-radius': '25px',
        },
    }

    async fncProcesarPago(medio) {
        let retorno = false
        const total = medio.total
        if (medio.isTarjeta && this.isHten()) {
            // Llamar a HTEN PAGOS
            let endpoint = "compraDebito"
            let tipoTarjetaJson = "DB"
            let callHten = false;
            switch (medio.medioEquivalente) {
                case "TARJETADEBITO":
                    retorno = false
                    tipoTarjetaJson = 'DB'
                    callHten = true
                    break
                case "TARJETACREDITO":
                    retorno = false
                    endpoint = "compraCredito"
                    await Swal.fire({
                        title: 'Es tarjeta NO BANCARIA?',
                        text: "Si es una tarjeta comercial presione en SI.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, es NB!',
                        cancelButtonText: 'No, es CR!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            tipoTarjetaJson = "NB"
                        } else {
                            tipoTarjetaJson = "CR"
                        }
                    });
                    callHten = true
                    break
                case "BINES":
                    endpoint = "consultaCompra"
                    break
                case "QR_ESTATICO":
                    endpoint = "pagoFpay_static"
                    callHten = true
                    break
                case "QR_DINAMICO":
                    endpoint = "pagoFpay_dynamic"
                    callHten = true
                    break
                case "MACH-BCI":
                    endpoint = "wallet/payment"
                    callHten = true
                    break
                default:
                    retorno = true
                    break
            }
            if (callHten) {
                const processHten = await this.callToHten(endpoint, tipoTarjetaJson, null, total)
                if(processHten) {
                    medio.NumTarjeta = JSON.stringify(this.state.htenJsonResponse)
                    retorno = true
                } else {
                    retorno = false
                }
                
            }
            if ((this.state.htenApproved === false)) {

                this.setState({ htenApproved: null })
                retorno = false
            }
        }
        else {
            retorno = true
        }
        return retorno
    }
    isHten() {
        return JSON.parse(sessionStorage.getItem('userProfile')).Sucursal.hten;
    }
    async callToHten(endpoint, tipoTarjetaJson, htenResponse, total) {
        const ipHten = localStorage.getItem('ipHten')
        const userData = JSON.parse(sessionStorage.getItem('userProfile'))
        let myBody = {}
        myBody.numeroEmpleado = userData.id + ""
        myBody.montoOperacion = total + ""
        myBody.montoPropina = "-1"
        myBody.montoVuelto = "-1"
        myBody.codigoMoneda = "CL"
        myBody.numeroBoleta = this.props.orderId + ''
        myBody.tipoTarjeta = tipoTarjetaJson;

        const controller = new AbortController();
        const signal = controller.signal;
        let isDynamic = false;
        switch (endpoint) {
            case "pagoFpay_static":
                myBody.montoPropina = "0";
                myBody.qrType = "1";
                endpoint = "pagoFpay";
                Swal.fire({
                    title: 'Leer el QR estático',
                    text: 'Avise al cliente que lea el QR y haga el pago con la APP mobile. Este mensaje se cerrará cuando se reciba respuesta del pago o en 3 minutos por timeout.',
                    icon: 'warning',
                    showCloseButton: false,
                    showCancelButton: true,
                    showConfirmButton: false,
                    focusConfirm: false,
                    cancelButtonText: 'Cancelar...'
                }).then((result) => {
                    controller.abort();
                    this.setState({ isMedioDePagoDisabled: false });
                    this.quitarPago(this.state.pagos.length - 1);
                });

                break;
            case "pagoFpay_dynamic":
                myBody.montoPropina = "0";
                myBody.qrType = "0";
                endpoint = "pagoFpay";
                isDynamic = true;
                break;
            case "wallet/payment":
                const userData = JSON.parse(sessionStorage.getItem('userProfile'))
                const machQrType = localStorage.getItem('machQrType')
                myBody = { 
                    "amount": total,
                    "tipAmount": 0.00,
                    "currencyCode": "CLP",
                    "employeeNumber": userData.id,
                    "posTransactionId": this.props.orderId,
                    // "qrType": "DYNAMIC",
                    "qrType": machQrType,
                    "entity": "BCI" }
                
                break
        }
        if (htenResponse != null) {
            myBody.mensaje0110 = htenResponse.mensaje0110;
        }
        
        if (localStorage.getItem('htenDemo') != null && localStorage.getItem('htenDemo') === "SI") {
            myBody.ultimos4 = "demo";
            if (endpoint === "pagoFpay") {
                myBody.numeroEmpleado = "DEMO";
            }
        }
        console.log("Body ", JSON.stringify(myBody));
        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'post',
            dataType: 'jsonp',
            // mode: 'no-cors',
            signal: signal,
            async: false,
            headers: {
                'Content-Type': 'application/json',
                // 'Connection': 'keep-alive',
                // 'Accept': '*/*',
                // 'Accept-Encoding': 'gzip, deflate, br'
            },
            body: JSON.stringify(myBody),
        }).then(respuesta => respuesta.json()).then(async (data) => {
            console.log('result', data);
            //this.processResponse(data, endpoint);

            // Si es QR Dinamico debo mostrar el QR y luego ejecutar la consulta. El resultado da pagado o rechazado.
            
            if (endpoint === "pagoFpay") {
            
                if (isDynamic) {
                    console.log(this.state.htenJsonResponse);

                    Swal.fire({
                        title: 'Por favor realice el pago con su APP mobile.',
                        html: data.glosaRespuesta,
                        showCloseButton: false,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText:
                            '<i class="fa fa-thumbs-up"></i> Listo!',
                        confirmButtonAriaLabel: 'Vayamos a leer el resultado!',
                        cancelButtonText:
                            '<i class="fa fa-thumbs-down"> Cancelar</i>',
                        cancelButtonAriaLabel: 'Thumbs down'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // llamar a pago con propina en -1 para consultar estado
                            const controller2 = new AbortController();
                            const signal2 = controller2.signal;
                            Swal.fire({
                                title: 'Esperando respuesta de pago QR',
                                text: 'Este mensaje se cerrará cuando se reciba respuesta del pago o en 3 minutos por timeout.',
                                icon: 'warning',
                                showCloseButton: false,
                                showCancelButton: true,
                                showConfirmButton: false,
                                focusConfirm: false,
                                cancelButtonText: 'Cancelar...'
                            }).then((result) => {
                                controller2.abort();
                                this.setState({ isMedioDePagoDisabled: false });
                                this.quitarPago(this.state.pagos.length - 1);
                            });
                            myBody.montoPropina="-1"
                            fetch('http://' + ipHten + '/api/' + endpoint, {
                                method: 'post',
                                dataType: 'jsonp',
                                signal: signal2,
                                async: false,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myBody),
                            }).then(respuesta => respuesta.json()).then(async (data) => {
                                if (this.processResponse(data, endpoint)) {

                                    this.setState({ cerrarModalDeshabilitado: true })
                                    await Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        animation: false,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'success',
                                    }).then((result) => {
                                        localStorage.setItem("ultimaOrden", this.props.orderId)
                                        localStorage.setItem("tipoUltimaOrden", "pago")
                                        
                                        //if (this.GetBalance() === 0) {
                                        //    this.setState({ isMedioDePagoDisabled: "disabled" });
                                        // S}
                                        return true;
                                    })
                                    return true;
                                } else {
                                    await Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago NO ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        animation: false,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'warning',
                                    });
                                    this.setState({ isMedioDePagoDisabled: false });
                                    this.quitarPago(this.state.pagos.length - 1);

                                    return false;
                                }
                            });

                        } else {
                            this.setState({ isMedioDePagoDisabled: false });
                            this.quitarPago(this.state.pagos.length - 1);
                            return false;
                        }
                    });
                    return false;
                }
            }else if(endpoint == 'wallet/payment') {
                if(data.statusCode === -1){
                    await Swal.fire({
                        title: '<strong>Error</strong>',
                        text: 'Ocurrió un error al momento de obtener el código QR',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    })
                    console.log('Error: ', data)
                    return false;
                } 
                else if(data.qrCode) {
                    const printRegister = await this.registerQr(data.payment.id, data.qrCode.image)
                    console.log('printRegister', printRegister)
                    this.setState({ htenResponse: data })
                    this.setState({ isQR: true })
                    
                    return await this.qrStatus(data.payment.id, data.entity)
                }
                else {
                    await Swal.fire({
                        title: '<strong>Error</strong>',
                        text: 'Ocurrió un error al momento de obtener el código QR',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    })
                    console.log('Error: ', data)
                    return false;
                }
                
            }

            if (endpoint !== "consultaCompra") {
                if (this.processResponse(data, endpoint)) {
                    this.setState({ cerrarModalDeshabilitado: true });
                    
                    await Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta+'</strong>',
                        text: 'El pago ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'success',
                    }).then(() => {
                        localStorage.setItem("ultimaOrden", this.props.orderId)
                        localStorage.setItem("tipoUltimaOrden", "pago")
                        return true;
                    });
                    return true
                } else {
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta+'</strong>',
                        text: 'El pago NO ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    });
                    return false;
                }
            } else {
                if (this.processResponse(data, endpoint)) {
                    console.log("consulta 1 ", data);
                    console.log("consulta 1 json ", this.state.htenJsonResponse);
                    if (!this.alertShowed) {
                        this.alertShowed = true;
                        console.log(this.state.htenJsonResponse);
                        Swal.fire({
                            title: '<strong>TARJETA LEÍDA</strong>',
                            text: 'Tarjeta:' + this.state.htenJsonResponse.marcaTarjeta + ' bin:' + this.state.htenJsonResponse.seisPrimerosDigitosTarjeta,
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: 'CONTINUAR PARA PAGAR',
                            animation: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            closeOnClickOutside: false,
                            icon: 'success',
                        }).then(() => {
                            let newEndpoint = 'compraCredito';
                            if (this.state.htenJsonResponse.tipoTarjeta == "DB") {
                                newEndpoint = 'compraDebito';
                            }

                            myBody.tipoTarjeta = this.state.htenJsonResponse.tipoTarjeta;
                            myBody.mensaje0110 = this.state.htenJsonResponse.mensaje0110;

                            fetch('http://' + ipHten + '/api/' + newEndpoint, {
                                method: 'post',
                                dataType: 'jsonp',
                                async: false,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myBody),
                            }).then(respuesta => respuesta.json()).then(async(data) => {
                                if (this.processResponse(data, newEndpoint)) {

                                    this.setState({ cerrarModalDeshabilitado: true });
                                    await Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'success',
                                    }).then(() => {
                                        localStorage.setItem("ultimaOrden", this.props.orderId)
                                        localStorage.setItem("tipoUltimaOrden", "pago")
                                        if (this.GetBalance() == 0) {
                                            this.setState({ isMedioDePagoDisabled: "disabled" });
                                        }

                                        if (newEndpoint === "compraDebito") {
                                            this.AgregarPago(this.GetBalance(), null, null, null, null, null, this.state.htenResponse, null).then(() => { });
                                            this.ClickPagosListos();
                                        }
                                        return true;
                                    });
                                } else {
                                    Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago NO ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',                                        
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'warning',
                                    });
                                    return false;
                                }
                            });

                        });
                    }
                } else {
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                        text: 'El pago NO ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    });
                    return false;
                }
            }
        }).catch(async (reason) => {
            // this.setState({ htenApproved: false })
            if (reason.code !== 20) {
                await Swal.fire({
                    title: '<strong>ERROR</strong>',
                    text: 'No logramos conectar con HTEN ' + reason,
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'error',
                })
                if(this.state.isQR)
                    this.qrCancel()
                this.setState({ isQR: false })
            } 
            return false
            
        })

        console.log('result', resul)
        return resul

    }
    processResponse(data, endpoint) {
        console.log("llega rta de HTEN ", data)
        if (data.constructor === ({}).constructor) {
            console.log("is_object")
            if (endpoint === "pagoFpay") {
                let fpayObj = {};
                try {
                    fpayObj = JSON.parse(data.glosaRespuesta);
                } catch (e) {
                    const codRta = parseInt(data.codigoRespuesta);
                    if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                        this.setState({ htenApproved: true })
                        return true;
                    } else {
                        this.setState({ htenApproved: false })
                        return false;
                    }
                }
                data.voucher = fpayObj.gateway.voucher.join("\n");
                data.payload = data.glosaRespuesta;
                data.glosaRespuesta = fpayObj.state;
            }
            this.setState({ htenJsonResponse: data });
            this.setState({ htenResponse: JSON.stringify(data) });

            const codRta = parseInt(data.codigoRespuesta);
            if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                this.setState({ htenApproved: true })
                return true;
            } else {
                this.setState({ htenApproved: false })
                return false;
            }
        }
        const jsonObj = JSON.parse(data);
        this.setState({ htenResponse: JSON.stringify(jsonObj) });
        this.setState({ htenJsonResponse: jsonObj });
        const codRta = parseInt(jsonObj.codigoRespuesta);
        if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
            this.setState({ htenApproved: true })
            return true;
        } else {
            this.setState({ htenApproved: false })
            return false;
        }
    }
    clickBtnFinish() {
        this.setState({ isFinishing: true })
        this.props.finalizarVenta(this.state.pagos)
    }
    clickBtnBack() {
        this.props.cerrarModal(this.state.pagos)
    }
    finalizarVenta() {

    }
    async checkFinishButton() {
        let isAllProcessed = true
        await this.state.pagos.map(item => {
            if(!item.processed) {
                isAllProcessed = false
            }
        })
        this.setState({ visibleFinishBtn: isAllProcessed })

        if(isAllProcessed && this.state.pagos.length === 1) {
            this.clickBtnFinish()
        }
    }
    GetPrecioFormateado(precio) {
        try {
            return '$' + precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } catch (err) {
            return '$ 0';
        }
    }

    async qrCancel() {
        const controller = this.state.qrController
        const data = this.state.htenResponse
        controller.abort()
        await this.cancelQrStatus(data.payment.id, data.entity)
        this.setState({ isQR: false })
    }
    async getStatusQr(id, entity){
        const endpoint = 'wallet/payment/' + id + '?entity=' + entity
        let retorno = false
        const ipHten = localStorage.getItem('ipHten')
        // this.setState({ qrController: controller, qrSignal: signal })

        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'get',
            dataType: 'jsonp',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(respuesta => respuesta.json()).then(async (data) => {
            console.log(data, 'retry statusqr')
            const statusResult = data.payment?data.payment.status:'NOT FOUND'
            if(statusResult === 'APPROVED')
                retorno = true
            else
                retorno = false
        })

        

        return retorno

    }
    async qrStatus(id, entity) {
        let ret = false
        const controller = new AbortController()
        const signal = controller.signal
        const ipHten = localStorage.getItem('ipHten')
        const endpoint = 'wallet/payment/' + id + '?entity=' + entity
        this.setState({ qrController: controller, qrSignal: signal })

        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'get',
            dataType: 'jsonp',
            signal: this.state.qrSignal,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(respuesta => respuesta.json()).then(async (data) => {
            console.log('qrStatus data', data)
            return data
        })
        if(resul.statusCode === -1) {
            const retryStatus = await this.getStatusQr(id, entity)
            if(!retryStatus){
                this.cancelQrStatus(id, entity)
                await Swal.fire({
                    title: '<strong>' + resul.statusCode + '-' + resul.statusDescription + '</strong>',
                    text: 'Ocurrió un error, verifique si se hizo cobro en su cuenta',
                    footer: 'ID Transacción:<strong> ' + id + '</strong>',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'warning',
                }).then(async () => { })
            }
            else{
                ret = true
                Swal.fire({
                    title: '<strong> Autorizado </strong>',
                    text: 'El pago ha sido autorizado',
                    footer: 'ID Transacción:<strong> ' + id + '</strong>',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'success',
                }).then(() => { })
            }
            
        }
        else if(resul.payment.status === 'APPROVED'){
            ret = true
            this.setState({ htenJsonResponse: resul });
            Swal.fire({
                title: '<strong> Autorizado </strong>',
                text: 'El pago ha sido autorizado',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'success',
            }).then(() => { })
        } else if(resul.payment.status === 'REJECTED') {
            Swal.fire({
                title: '<strong>No Autorizado</strong>',
                text: 'El pago NO ha sido autorizado',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'warning',
            })
        } else if(resul.payment.status === 'ERROR') {
            Swal.fire({
                title: '<strong>Error</strong>',
                text: 'Ocurrió un error, verifique si se hizo cobro en su cuenta',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'warning',
            });
        } else {
            Swal.fire({
                title: '<strong>Problemas con el pago</strong>',
                text: 'Ocurrió un problema con el pago, verifique si se le hizo cobro',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'warning',
            });
        }
        this.deleteQr(id)
        this.setState({ isQR: false })
        document.getElementById("btnCancel").disabled = false
        return ret
    }

    async cancelQrStatus(id, entity) {
        let ret = false
        const ipHten = localStorage.getItem('ipHten')
        const endpoint = 'wallet/payment/' + id + '/cancel'
        const body = { entity: entity }
        document.getElementById("btnCancel").disabled = false
        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'post',
            dataType: 'jsonp',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then(respuesta => respuesta.json()).then(async (data) => {
            return data
        })
        this.setState({ isProcessing: false })
        this.deleteQr(id)
        return ret
    }
    async registerQr(id, content){
        let ret = false
        const qrContent = { transactionId: id, content: content }
        const respuesta =await fetch(GetUrlApi() + '/api/orden/registerPrintQR', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:  JSON.stringify(qrContent)
        })
        return ret
    }
    async deleteQr(id){
        let ret = false
        const qrContent = { transactionId: id, content: '' }
        const respuesta =await fetch(GetUrlApi() + '/api/orden/registerPrintQRDelete', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:  JSON.stringify(qrContent)
        })
        return ret
    }
    async checkPagos(){
        this.state.pagos.map((pago, i) => {
            switch (pago.medioEquivalente) {
                case "CANJEPUNTOS":
                    this.ClickPagar(pago)
                    break
            }
        })
    }
}
