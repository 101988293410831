import React, { Component } from 'react';
import { Route } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { Redirect } from 'react-router';
import UserProfile from './Globales/PerfilUsuario';
import { Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import Popup from "reactjs-popup";
import { TecladoNumerico } from './TecladoNumerico';

export class PopupGarantiaEvento extends Component {
    static displayName = PopupGarantiaEvento.name;
    StateInicial = {
        total: 0,
        SiClicked: false,
        activarTeclado: false,
        tieneFactura: false,
    }

    constructor() {
        super();
        this.state = this.StateInicial;
        this.CerrarTeclado = this.CerrarTeclado.bind(this);
        this.CerrarTodo = this.CerrarTodo.bind(this);
    }

    componentDidMount() {
        this.setState({ total: this.props.total });
    }

    Estilos = {
        btnDocumento: {
            'marginLeft': '20px',
            'height ': '50%'
        },
        btnDocumento: {
            'marginLeft': '20px',
            'height ': '50%'
        },
    }

    //------->INICIO FUNCIONES ON CLICK
    ClickSi() {
        this.setState({ activarTeclado: true, tieneFactura: true });
    }

    AgregarPagoSi() {
        this.setState({ activarTeclado: true, tieneFactura:true });
        const totalRestante = (this.state.total / 10) * 3;
        //this.props.AgregarPago(totalRestante, null, null);
       // this.props.DesactivarModalesPagar();
    }

    ClickNo() {
        this.setState({ activarTeclado: true, tieneFactura: false });
        //this.props.AgregarPago(this.state.total, null, null);
       // this.props.DesactivarModalesPagar();
    }
    //------->FIN FUNCIONES ON CLICK
    
    CerrarTeclado() {
        this.setState({ activarTeclado: false });
    }

    CerrarTodo() {
        this.setState({ activarTeclado: false });
        this.props.DesactivarModalesPagar();
    }

    render() {
        return (
            <Popup
               // contentStyle={}
                defaultOpen
                modal
              //  onClose={() => { }}
                closeOnDocumentClick>

                <center>

                    {(this.state.activarTeclado) ?
                        (<TecladoNumerico IsEfectivo={this.IsEfectivoSeleccionado}
                            AgregarPago={this.props.AgregarPago}
                            total={this.state.total}
                            isGarantiaEvento={true}
                            tieneFactura={this.state.tieneFactura}
                            numInfo={document.getElementById('txtNumInfo').value}
                            CerrarModalMaster={this.CerrarTodo} />)
                        : (null)
                    }
                
                    
                    <p>Numero informativo <input id="txtNumInfo" className="form-control" type="text" /></p>
                    <p>¿Tiene factura?</p>

                    <div style={this.Estilos.BtnDoc}>
                        <button onClick={() => { this.ClickSi() }} className="btn btn-secondary btn-lg" id="btnBoleta" style={this.Estilos.btnDocumento} > Si</button>
                        <button onClick={() => { this.ClickNo() }} className="btn btn-secondary btn-lg" id="btnFactura" style={this.Estilos.btnDocumento} >No</button>
                        <div style={this.Estilos.BtnDoc}>

                        </div>
                    </div>
                    
                </center>

            </Popup>
        );
    }
    
}
