import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

//if ("serviceWorker" in navigator) {
//    navigator.serviceWorker
//        .register("./firebase-messaging-sw.js")
//        .then(function (registration) {
//            console.log("Registration successful, scope is:", registration.scope);
//        })
//        .catch(function (err) {
//            console.log("Service worker registration failed, error:", err);
//        });
//}


ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

//registerServiceWorker();
