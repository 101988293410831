import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import swal from 'sweetalert';
import ReactToExcel from 'react-html-table-to-excel';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';


export class ReporteVentasPorPlatillo extends Component {
    static displayName = ReporteVentasPorPlatillo.name;
    StateInicial = {
        fechaDesdeIngresada: false,
        fechaHastaIngresada: false,
        cargadatos: false,
        resultado: [],
        VerTabla: false,
        SucursalesOpt: [],
        SucSelected: 0,
        ventasPorPlatillo: [],
        ventasNetas: 0,
        descuentos:0,
        sumPorcentajes:0,
        sumCantidad:0,
    };
    constructor() {
        super();
        this.state = this.StateInicial;
        this.TraerSucursales();
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/GetSucursales', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ SucursalesOpt: datos });
        }
    }

    Estilos = {
        FondoDefault: {
            'backgroundColor': '#ecf0f5',
            'heigth': '100%',
        },
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '100%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '250px',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '100%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        BordesCard:
        {
            'borderRadius': '25px'
        },
        TarjetaEstadisticas2: {
            'width': '250px',
            'backgroundColor': 'white',
            'height': '350px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '50px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '40px',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '100%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            'textAlign': 'left'
        },
        TdStyleValues: {
            'textAlign': 'center'
        },
        AnchoBox: {
            'width': '350px'
        }, AvgStyle:
        {
            'color': 'red'
        },
        AvgStylePlus:
        {
            'color': 'Green'
        },
        AvgStyleEquals:
        {
            'color': '#F9810A'
        },
        AnchoBoxGraf: {
            'width': '1000px',
            'borderRadius': '25px',
            'margin-left': '-3%'

        },
        BtnExcel:
        {
            'width': '50px',
            'height': '50px',
            'border-radius': '8px',
            'background': '#6e9887',
            'color': '#fff'
        }
    }
    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('fechaFiltro').value = today;
        document.getElementById('fechaFiltroHasta').value = today;
        document.getElementById('fechaini').value = today;
        document.getElementById('fechafin').value = today;
    }
    Redirigir(url) {
        this.props.history.push(url);
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuario.id != 1) {
            Logout();
            this.Redirigir('/');
        } else {
            this.cargarFechas();
            //this.GetVentasPorPlatillo()
            //const width = window.screen.availWidth;

            //const height = window.screen.availHeight;

            //if (width < 1000) {

            //    this.HacerResponsive();

            //}
        }


    }



    HacerResponsive() {

        var elements = Array.from(document.getElementsByTagName("*"));
        elements.forEach((item) => {
            item.style.width = '100%';
        });

    }
    async LimpiarState() {
        this.setState({ resultado: [], VerTabla: false, cargadatos: true })

    }
    async GetVentasPorPlatillo() {
        try {
            await this.setState({ cargadatos:true});
            var sucursales = document.getElementById('SucId').value;
            if (sucursales === "0") {
                sucursales = [];
                this.state.SucursalesOpt.forEach((sucursal) => {
                    sucursales.push(sucursal.id);
                });
            } else {
                sucursales = [];
                const sucursalID = document.getElementById('SucId').value;
                sucursales.push(sucursalID);
            }

            var data = {};
            data.sucursales = sucursales;
            data.desde = document.getElementById('fechaFiltro').value;
            data.hasta = document.getElementById('fechaFiltroHasta').value;
            
            var respuesta = await fetch(GetUrlApi() + '/api/reporteria/GetVentasPorPlatillo', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (respuesta.ok) {
                var data = await respuesta.json();
                console.log(data);
                this.setState({
                    ventasPorPlatillo: data.filas, VerTabla: true, ventasNetas: data.ventasNetas, descuentos: data.descuentos,
                    sumPorcentajes: data.sumPorcentajes,
                    sumCantidad: data.sumCantidad,
                    cargadatos: false
                });
            } 
        } catch (err) {

        }
    }

    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;

    }
    convertToCsv(fName, rows) {
        var csv = '';
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            for (var j = 0; j < row.length; j++) {
                var val = row[j] === null ? '' : row[j].toString();
                val = val.replace(/\t/gi, " ");
                if (j > 0)
                    csv += '\t';
                csv += val;
            }
            csv += '\n';
        }
        var cCode, bArr = [];
        bArr.push(255, 254);
        for (var i = 0; i < csv.length; ++i) {
            cCode = csv.charCodeAt(i);
            bArr.push(cCode & 0xff);
            bArr.push(cCode / 256 >>> 0);
        }

        // for UTF-16


        var blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fName);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                var url = window.URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        }
    }
    async ExportarExcel(idApertura, idCierre) { 
        var s = idApertura;
        var a = idCierre; 
        console.log(s);
        console.log(a);
        var data = {};
        var sucursales = document.getElementById('SucId').value;
        if (sucursales === "0") {
            sucursales = [];
            this.state.SucursalesOpt.forEach((sucursal) => {
                sucursales.push(sucursal.id);
            });
        } else {
            sucursales = [];
            const sucursalID = document.getElementById('SucId').value;
            sucursales.push(sucursalID);
        }
        data.desde = document.getElementById('fechaFiltro').value;
        data.hasta = document.getElementById('fechaFiltroHasta').value;
        data.sucursales = sucursales;

        var respuesta = await fetch(GetUrlApi() + '/api/reporteria/GetVentasPorPlatillo', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!respuesta.ok) {
            throw { mensaje: 'problema al traer las ventas' };

        } else {
            var datos = await respuesta.json();
            var arr = [[" Total de ventas reportadas por platillo de " + data.desde + " hasta " + data.hasta, " ", " ", " "]];
            arr.push([" ", "Total ventas", "Porcentaje", "Cantidad"]);
            datos.filas.forEach(function (item) {
                var nuevoArray = [];
                nuevoArray.push(' ');
                nuevoArray.push(item.platillo.desprod);
                nuevoArray.push(String(item.ventas));
               
                nuevoArray.push(String(item.porcentaje));
                nuevoArray.push(String(item.cantidad));
                arr.push(nuevoArray);
            });
            arr.push([' ', ' ', 'ventas netas: ' + GetPrecioFormateado(this.state.ventasNetas), '' + String(this.state.sumPorcentajes) + "%", String(this.state.sumCantidad)]);
            arr.push([' ', ' ', 'Descuentos: ' + GetPrecioFormateado(this.state.descuentos)]);
            arr.push([' ', ' ', 'Ventas: ' + GetPrecioFormateado((this.state.ventasNetas-  this.state.descuentos))]);
            const nombreArchivoExport = "ReporteVentasPorProducto" + "_" + new Date().toLocaleDateString() + ".csv";
            this.convertToCsv(nombreArchivoExport, arr);



        }
    }



    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }


    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <div>
                    <table style={this.Estilos.FullAncho} >
                        <thead>
                            <th colSpan="3">Ventas Diarias</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                            </tr>
                            <tr>
                                <td>
                                    <select id="SucId" className="form-control">
                                        {
                                            this.state.SucursalesOpt.map((item, index) => {
                                                return (<option value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde() }} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta() }} className="form-control" type="date" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



                <hr />
                <div className="row">
                    <div className="col-lg-5">
                        <input type="text" id="fechaini" hidden ></input>
                        <input type="text" id="fechafin" hidden></input>
                    </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.GetVentasPorPlatillo() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br />
                <div>
                    <center>
                        {
                            (this.state.cargadatos) ? (
                                <div style={this.Estilos.FondoDefault}>
                                    <center>
                                        <div className="spinner-border text-dark" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                        <p> Cargando...</p>
                                    </center>
                                </div>
                            ) : (null)
                        }
                    </center></div>

                <br />

                <div style={this.Estilos.Bordes}>
                    {(this.state.VerTabla) ? (
                        <div className="tab-content mt-3">
                            <div className="card-title text-left"> <button className="btn" style={this.Estilos.BtnExcel} onClick={() =>  { this.ExportarExcel() }}><i class="fa fa-file-excel-o fa-2x" aria-hidden="true"></i></button></div>

                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Ventas</th>
                                        <th>%</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.ventasPorPlatillo.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.platillo.desprod}</td>
                                                    <td>{GetPrecioFormateado( item.ventas)}</td>
                                                    <td>{(!isNaN(item.porcentaje) ) ? (item.porcentaje):(0)}%</td>
                                                    <td>{item.cantidad}</td>
                                                </tr>
                                                );
                                            
                                        })
                                    }
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><strong>{this.state.sumPorcentajes}%</strong></td>
                                        <td><strong>{this.state.sumCantidad}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Ventas netas: {GetPrecioFormateado(this.state.ventasNetas)}</td>
                                        <td>Total descuentos {( this.state.descuentos>0) ? (GetPrecioFormateado(this.state.descuentos)):(0)}</td>
                                        <td>Ventas {GetPrecioFormateado(this.state.ventasNetas - this.state.descuentos)}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>

                    ) : (null)}</div>
            </div>



        );
    }

}