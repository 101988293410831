import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { Estilos } from './Globales/Estilos';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import UserProfile from './Globales/PerfilUsuario';

export class Ordenes extends Component {
    static displayName = Ordenes.name;

    MostrarAbiertas = false;
    MostrarPagadas = true;
    OrdenesIniciales = {};


    constructor() {
        super();
        this.state = {
            ordenes: [],
            ordenesFiltradas: [],
            redirigirHome: false,
            cargando: false,
            usuarios: [],
            MostrarAbiertas: false,
            MostrarPagadas: true
        }
        this.TraerUsuarios();
    }

    componentDidMount() {
        this.TraerOrdenes();
        this.FechaDefault();
    }

    async TraerUsuarios() {
        var respuesta = await fetch(GetUrlApi() + '/api/Usuarios', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();

            this.setState({ usuarios: datos });
        }
        else {
            alert('error');
        }
    }
    async TraerOrdenes() {
        await this.setState({ cargando: true });
        var respuesta = await fetch(GetUrlApi() + '/api/Orden?basico=true&HorasDesde=true', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            datos = datos.filter(p => p.total > 0);
            this.OrdenesIniciales = datos;
            await this.FiltrarDatos();
            //this.setState({ ordenes: datos, cargando: false });
        }
        else {
            alert('error');
        }
    }



    async ClickBorrarOrden(idOrden) {
        swal({
            title: "¿Esta seguro que desea borrar la orden número " + idOrden + "?",
            // text: "Se cambiara el medio de pago actual",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const res = await fetch(GetUrlApi() + '/api/Orden/' + idOrden, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(() => {
                    var cbo = document.getElementById('cboEstado');
                    this.TraerOrdenes();
                });
                swal("La orden se a borrado con exito", {
                    icon: "success",
                });
            }
        });

    }

    FechaDefault() {
        var date = new Date();

        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;

       // var today = year + "-" + month + "-" + day + " " + hour + ":" + min;
        var today = year + "-" + month + "-" + day + "T"  + "23:59" ;

        document.getElementById('fechaHasta').value = today;
    }

    async FiltrarDatos() {
        var ordenes = this.OrdenesIniciales;
        var ver;
        if (document.getElementById('cboVer'))
            ver = document.getElementById('cboVer').value;
        if (ver === 'Ver las del usuario') {
            ordenes = ordenes.filter(p => p.idMesero === UserProfile.getUser().id);
        }
        else {
            ordenes = ordenes.filter(p => p.idMesero > 0);
        }
        var cboEstado = document.getElementById('cboEstado');
        if (cboEstado.value === 'Pagada') {
            await this.setState({ MostrarPagadas: true, MostrarAbiertas: false });
            ordenes = ordenes.filter(p => p.pagado);
        }
        else {
            await this.setState({ MostrarAbiertas: true, MostrarPagadas: false });
            ordenes = ordenes.filter(p => p.total > 0 && !p.pagado);
        }

        var cbo = document.getElementById('cboEstado');
        try {
            var fechaDesde = document.getElementById('fechaDesde').value;
            var fechaHasta = document.getElementById('fechaHasta').value;

            //var hasta = new Date(fechaHasta);
            //hasta.setHours(48, 120, 120, 0);
            //console.log(hasta.toDateString());
            if (fechaDesde === '') {
                ordenes = ordenes.filter(p => p.fecha < fechaHasta );
            }
            else {
                ordenes = ordenes.filter(p => p.fecha >= fechaDesde && p.fecha<=fechaHasta);
            }
        } catch (err) {

        }
        this.setState({ ordenes: ordenes, cargando: false });
    }


    GetNameUser(id) {
        var usuario = this.state.usuarios.find((element) => {
            return element.id === id;
        });
        if (usuario) {
            return usuario.nombre + ' ' + usuario.apellido;
        }
        else {
            return '';
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

    Estilos = {
        columnaLateral: {
            'width': '10%'
        },
        columnaCentral: {
            'width': '80%',
            'backgroundColor': 'white'
        },
        tabla: {
            'width': '100%',
            'position': 'relative',
            'height': '500px',
            'overflow': 'auto',
            'display': 'block'
        },
        FullAncho: {
            'width': '100%'
        },
        TableHeaderFijo: {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': '#ecf0f5'
        }

    }

    //INICIO FUNCIONES CLICK
    ClickVolver() {
        this.setState({ redirigirHome: true });
    }

    ClickEditar(ordenId) {
        this.Redirigir('/ParaLlevar?editar=true&id=' + ordenId);
    }

    ClickPagar(ordenId) {
        this.Redirigir('/ParaLlevar?editar=true&pagar=true&id=' + ordenId);
    }


    //FIN FUNCIONES CLICK

    GetTiempo(fechaInicio) {
        var date = new Date();

        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;

        var today = year + "-" + month + "-" + day;
        console.log(today);
        console.log(fechaInicio);
        return today - fechaInicio;
    }
    //INICIO FUNCIONES HTML


    GetHtmlTablaOrdenes() {
        const ordenes = this.state.ordenes;
        return (
            <table style={this.Estilos.tabla} className="table">
                <thead>
                    <tr>
                        <th style={this.Estilos.TableHeaderFijo}>Nro Orden</th>
                        <th style={this.Estilos.TableHeaderFijo}>Módulo</th>
                        <th style={this.Estilos.TableHeaderFijo}>Cuenta</th>
                        <th style={this.Estilos.TableHeaderFijo}>Mesero</th>

                        <th style={this.Estilos.TableHeaderFijo}>Total</th>
                        <th style={this.Estilos.TableHeaderFijo}>Hora</th>
                        <th style={this.Estilos.TableHeaderFijo}>Tiempo</th>
                        <th style={this.Estilos.TableHeaderFijo}>Editar</th>
                        <th style={this.Estilos.TableHeaderFijo}>Imprimir</th>
                        {
                            (UserProfile.getUser().tipoUsuario === 1 && this.state.MostrarAbiertas) ? (<th style={this.Estilos.TableHeaderFijo}>Pagar</th>) : (null)
                        }
                        {
                            (UserProfile.getUser().tipoUsuario === 1 && this.state.MostrarAbiertas) ? (<th style={this.Estilos.TableHeaderFijo}>Cancelar</th>) : (null)
                        }

                    </tr>
                </thead>
                <tbody>
                    {
                        ordenes.map((item, index) => {
                            var ambiente = (index % 2 === 0) ? ('Para llevar') : ('Delivery');
                            return (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{ambiente}</td>
                                    <td>1</td>
                                    <td>{this.GetNameUser(item.idMesero)}</td>

                                    <td>{GetPrecioFormateado(item.total)}</td>
                                    <td>{item.hora}</td>
                                    <td>{item.horasDesdeApertura}</td>
                                    <td><a href="javascript:void(0)" onClick={() => { this.ClickEditar(item.id) }}><img height="30" width="30" src={require('./Imagenes/editanegro.png')} /> </a></td>
                                    <td><img height="30" width="30" src={require('./Imagenes/printer.png')} /></td>
                                    {
                                        (UserProfile.getUser().tipoUsuario === 1 && this.state.MostrarAbiertas) ? (<td>  <a href="javascript:void(0)" onClick={() => { this.ClickPagar(item.id) }}> <img height="30" width="30" src={require('./Imagenes/pagonegro.png')} /> </a> </td>) : (null)
                                    }
                                    {
                                        (UserProfile.getUser().tipoUsuario === 1 && this.state.MostrarAbiertas) ? (<td> <a href="javascript:void(0)" onClick={() => { this.ClickBorrarOrden(item.id) }}> <img height="30" width="30" src={require('./Imagenes/cancel.png')} /> </a> </td>) : (null)
                                    }

                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        );
    }
    //FIN FUNCIONES HTML

    render() {
        return (
            <div className="row">
                {
                    (this.state.redirigirHome) ? (<Redirect to='/' />) : (null)
                }

                <div style={this.Estilos.columnaLateral} className="column"></div>

                <div style={this.Estilos.columnaCentral} className="column">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a onClick={() => { this.ClickVolver() }} href="javascript:void(0)">FBHPOS</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Revisar</li>
                        </ol>
                    </nav>
                    <table>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>  Estado de Mesa
                                        <select onChange={() => { this.FiltrarDatos() }} id="cboEstado">
                                            <option>Abierta</option>
                                            <option>Pagada</option>
                                        </select>
                                    </p>
                                </td>
                                <td>
                                    {
                                        (UserProfile.getUser().tipoUsuario === 1 || UserProfile.getUser().tipoUsuario === 2) ? (
                                            <p>
                                                Ver
                                                    <select onChange={() => { this.FiltrarDatos() }} id="cboVer">
                                                    <option>Ver las del usuario</option>
                                                    <option>Todas la Órdenes</option>
                                                </select>
                                            </p>
                                        ) : (null)
                                    }

                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>


                    <p>  Fecha de Inicio <input onChange={() => { this.FiltrarDatos() }} id="fechaDesde" type="datetime-local" />  Fecha de Corte <input onChange={() => { this.FiltrarDatos() }} id="fechaHasta" type="datetime-local" />
                        {this.state.ordenes.length} Resultados Encontrados
                    </p>
                    {
                        (this.state.cargando) ? (
                            <div style={this.Estilos.FondoDefault}>
                                <center>
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    <p> Cargando ventas...</p>
                                </center>
                            </div>
                        ) : (null)
                    }

                    {
                        (this.state.ordenes) ? (this.GetHtmlTablaOrdenes()) : (null)
                    }
                </div>

                <div style={this.Estilos.columnaLateral} className="column"></div>


            </div>

        );
    }
}
