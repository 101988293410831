import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import swal from 'sweetalert';
import { GetPrecioFormateado } from './Globales/Utilidades';


export class ArqueoDetalleMedio extends Component {
    static displayName = ArqueoDetalleMedio.name;
    IdMedioSeleccionado = 0;
    DescMedioSeleccionado = '';
    

    IdGavetaSeleccionada = 0;
    constructor(props) {
        super(props);
        this.state = {
            MediosVentas: [],
            MediosDePago: [],
            hayCambios: false,
            cargando: false,
            mostrarDetallesTodosMedios: false,
            medioSeleccionado: {
                isCheque: false,
                isTarjeta: false,

            }
        }
        this.DescMedioSeleccionado = props.descMedio;
        this.IdMedioSeleccionado = props.idMedio;
        this.IdGavetaSeleccionada = props.IdGaveta;
        if (props.recargarPagina) {
            this.Recargar = props.recargarPagina;
        }
        //this.TraerMediosOrden();

    }
    componentDidMount() {
        var datos = this.props.MediosPorOrden;
        var ordenes = this.props.ordenes;
        console.log(ordenes)

        datos = datos.filter(p => p.tbMedioID === this.IdMedioSeleccionado);
        datos.forEach((item) => {
            item.orden = ordenes.find(p=>p.id===item.ordenID);
        });
        var mediosDePago = this.props.mediosDePago;
        console.log(datos)
        var medioSeleccionado = {};
        if (datos.length > 0) {
            medioSeleccionado = datos[0].tbMedio;
        } else {
            medioSeleccionado = {
                isCheque: false,
                isTarjeta: false,
            }
        }
        this.setState({ MediosVentas: datos, mediosDePago: mediosDePago, medioSeleccionado: medioSeleccionado });
    }

    Recargar() {

    }
    

    async EditarMedioDePago(idOrdenMedio, idcbo) {

        try {
           
            const nuevoId = idcbo;
            var data = {};
            data.tbMedioID = nuevoId;
            data.OrdenID = 0;
            data.Total = 0;
            var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden/' + idOrdenMedio, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                alert('error');
                
                //this.props.setStateArqueo();
                //await this.TraerMediosOrden();
            }
        } catch (err) {
            swal('Opción no válida');
        }
    }

    //------Clicks
    ClickCambiarCboMedioPago() {
       
        swal({
            title: "¿Esta seguro que desea realizar los cambios?",
            text: "Se cambiaran los medios de pago seleccionados",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            await this.setState({ cargando: true, hayCambios: false });
            
            if (willDelete) {
                var elemsToEdit = document.getElementsByName('selectDeMedios');
               
                elemsToEdit = Array.from(elemsToEdit).filter(p => p.value !== '--');
                elemsToEdit.forEach(async (item) => {
                    
                    await this.EditarMedioDePago(item.attributes.idmediopago.value, item.value);
                });
                //await this.EditarMedioDePago(idOrdenMedio,);
                const isNumber = !isNaN(Number(this.props.ValorGavetaPendiente));
                if (!isNumber) {
                    this.Recargar();
                }
                else {
                    this.props.CargarPendientes(this.IdGavetaSeleccionada);
                }
            }
        });
        
    }
    //------Clicks
    
    ChangeMedioPago() {
        this.setState({hayCambios:true});
    }
    //--------ESTILOS
    Estilos= {
        Contenedor: {
            'border-radius': '25px',
            'border': '2px solid #e6ede7',
            'overflow': 'scroll',
            'height': '1000px',
            'marginLeft': '16%'
        },
        InputNum:
        {
            'width': '130px'
        }
    }
    //--------FIN ESTILOS

    GetTotal() {
        var res = 0;
        this.state.MediosVentas.forEach((item) => {
            res += item.totalReal;
        });
        return res;
    }

    GetTotalPropinas() {
        var res = 0;
        this.state.MediosVentas.forEach((item) => {

            if (item.orden) {
                res += item.montoPropina;
            }
        });
        return res;
    }

    

    render() {
        const isCheque = this.state.medioSeleccionado.isCheque;
        const isTarjeta = this.state.medioSeleccionado.isTarjeta;
        const isGarantiaEvento = this.state.medioSeleccionado.isGarantiaEvento;
        return (
            <div style={this.Estilos.Contenedor}>
                <center><h4>{this.DescMedioSeleccionado}
                    <a style={{ 'fontSize': '11px', 'float': 'right' }} href="" onClick={(e) => { e.preventDefault(); this.props.setStateArqueo({ mostrarDetallesTodosMedios: true }) }}>Todos los pagos</a>  </h4>  </center>
                
                <table className="table">
                    <thead>
                        <tr>
                            <td>N° Orden</td>
                            <td>N° Folio</td>
                            {(isTarjeta) ? (<td>N° Operación</td>) : (null)}
                            {(isCheque) ? (<td>N° cheque</td>) : (null)}
                            {(isGarantiaEvento) ? (<td>N° informativo</td>) : (null)}
                            {(isTarjeta) ? (<td>N° tarjeta</td>) : (null)}
                            {(isCheque) ? (<td>Banco</td>) : (null)}
                            <td>Pagado</td>
                            <td>Total</td>
                            <td>Propina</td>
                            <td>Editar</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.MediosVentas.map((item, index) => {
                                var idCombobox = 'cbo' + index;

                                return (
                                    <tr key={"trMedios" + index} >
                                        <td>{item.orden.numOrden}</td>
                                        <td>{(item.orden) ? (item.orden.numFolio) : (null)}</td>
                                        {(isTarjeta) ? (<td>{item.numeroDeOperacion}</td>) : (null)}
                                        {(isCheque) ? (<td>{item.numCheque}</td>) : (null)}
                                        {(isGarantiaEvento) ? (<td>{item.numInformativo}</td>) : (null)}
                                        {(isTarjeta) ? (<td>{item.numTarjeta}</td>) : (null)}
                                        {(isCheque) ? (<td>{(item.bancos) ? (item.bancos.descripcion) : (null)}</td>) : (null)} 
                                        <td>{GetPrecioFormateado( item.totalReal)}</td>
                                        <td>{(item.orden) ? (GetPrecioFormateado(item.orden.total)) : (null)}</td>
                                        <td>{GetPrecioFormateado(item.montoPropina)}</td>
                                        
                                        <td>

                                            <form key={ 'form1' + index} className="form-inline">
                                                <div className="form-group mb-2">
                                                    <select name="selectDeMedios" IdMedioPago={item.id} onChange={() => { this.ChangeMedioPago() }} key={'select1' + index} style={this.Estilos.DosTercioAncho} className="form-control" id={idCombobox} >
                                                        <option>--</option>
                                                        {
                                                            this.state.mediosDePago.map((item, index) => {
                                                                return (<option key={'opt1' + index} value={item.id}>{item.desmed}</option>);
                                                            })
                                                        }
                                                    </select>
                                                    

                                                </div>
                                            </form>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

                {
                    (this.state.MediosVentas.length === 0) ? (<p>No hay registros</p>) :
                        (
                            <div>
                                <p><strong> Totales</strong></p>
                                <p>Monto Total: {GetPrecioFormateado(this.GetTotal())}</p>
                                <p> Total Propina {GetPrecioFormateado(this.GetTotalPropinas())} </p>
                            </div>
                        )
                }
                {
                    (this.state.hayCambios) ? (
                        <center>
                            <button className="btn btn-success" onClick={() => { this.ClickCambiarCboMedioPago() }}>Guardar cambios</button>
                            
                        </center>
                            ): (null)
                }
                {
                    (this.state.cargando) ? (
                        <div style={this.Estilos.FondoDefault}>
                            <center>
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                <p> Actualizando...</p>
                            </center>
                        </div>
                    ) : (null)
                }
            </div>
        );
    }
}
