import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

import Popup from "reactjs-popup";


export class MantenedorCategorias extends Component {
    static displayName = MantenedorCategorias.name;

    StateInicial = {
        activarAgregarProducto:false,
        activarAgregarCategoria:false,
        categoriasBotones: [],
        cargandoCategorias: true,
        defaultIsPesableAgregar: true,
        //---->inicio estados crud categ 
        categoriaSeleccionada: {},
        sucursales:[],
        //----> fin estados crud categ 

         //---->inicio estados crud producto 
        zonasImpresion:[],
        //----> fin estados crud producto
    }

    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerCategorias();
        this.TraerZonasImpresion();
    }
    
    //-------->INICIO FUNCIONES CRUD
    async TraerZonasImpresion() {
        var respuesta = await fetch(GetUrlApi() + '/api/ZonaImpresions', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ zonasImpresion: datos });
        }
    }

    async TraerCategorias() {
        try {
            var SucursalID = GetUserCookie().SucursalID;
            const categorias = await fetch(GetUrlApi() + '/api/tbgrupoes/TraerGrupos?SucursalID=' + SucursalID);
            
            const datos = await categorias.json();

            this.setState({ categoriasBotones: datos, cargandoCategorias: false });
            
        } catch (err) {

        }
    }
    

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Sucursals', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ sucursales: datos });
        }
    }

    async CrearProducto() {
        var producto = {};
        producto.desprod = document.getElementById('txtDescripcionProd').value;
        producto.precpred = Number(document.getElementById('txtPrecioProd').value);
        producto.tbGrupoID = this.state.categoriaSeleccionada.id;
        producto.sucursalID = Number( document.getElementById('cboSucursalProd').value);
        producto.plu = document.getElementById('txtPlu').value;

        producto.Prodsimil = 0;
        producto.Precmesa = 0;
        producto.Precbar = 0;
        producto.Precllevar = 0;
        producto.Precpedido = 0;
        producto.Precdeliv = 0;
        producto.Permdesct = 0;
        producto.Porcdesct = 0;
        producto.Tipomodif = 0;
        producto.Nivlsuper = 0;
        producto.Nivlsupgr1 = 0;
        producto.Codimpres = 0;
        producto.Estprod = 0;
        producto.Vtacad = 0;
        producto.Codmod = 0;
        producto.Prodrec = 0;
        producto.IsCombo = 0;
        producto.IsPesable = document.getElementById('chkPesable').checked; 
        producto.PrecioKg = Number(document.getElementById('precioKilo').value); 
        producto.Disponible = 0;
        producto.Impuesto = 0;
        producto.ImpuestoCant = 0;
        producto.isHabilitado = true;
        var respuesta = await fetch(GetUrlApi() + '/api/tbproductoes/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(producto),
        });
        if (respuesta.ok) {
            swal('Producto creado con exito').then(() => {
                this.Recargar();
            });
        }
    }


  
    //-------->FIN FUNCIONES CRUD

    //-------->INICIO FUNCIONES ONCHANGE

    //-------->FIN FUNCIONES ONCHANGE
    Estilos = {
        Columna1: {
            'height': '600px',
            'overflowY': 'scroll',
            'resize': 'both',
            'overflow': 'auto'
        },
        DivCentralTest: {
            'width': '50%',
            'float': 'left',
            'padding': '10px',
            //'height': '100%'
        },
        MarginLeftCR:
        {
            'marginLeft': '-2.5%'
        },
        BotoneraCategoria:
        {
            'width': '50%',
            'height': '60px',
            'borderRadius': '15px',
            'paddingTop': '10px',
            'paddingRight': '20px',
            'paddingBottom': '10px',
            'overflow': 'hidden',
            'outline': 'none',
            'borderColor': 'grey',
            'fontSize': '80%',
            'text-align': 'center'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'marginTop': '0.5%',
            'marginRight': '1%',
            'marginLeft': '2%'
        },
        BtnSalir: {
            'height': '44px',
            'width': '50px',
            'border-color': 'rgb(110, 152, 135)',
            'border-radius': '10%'
        },
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
    }
    

    //------->inicio onclick
    async ClickCategoria(categoria, idBtn) {
        this.TraerSucursales();
        var botones = document.getElementsByName('botonesCategorias');
        botones.forEach((value) => {
            value.style.backgroundColor = '';
            value.style.color = '';
        });
        document.getElementById(idBtn).style.backgroundColor = '#6e9887';
        document.getElementById(idBtn).style.color = '#fff';

        await this.setState({ categoriaSeleccionada: categoria, activarAgregarProducto: false, activarAgregarCategoria:false });
        if (document.getElementById('txtDescripcion')) {
            document.getElementById('txtDescripcion').value = categoria.desgrup;
        }
    }

    async ClickEditarCategoria() {
        var usuario = this.state.categoriaSeleccionada;
        usuario.desgrup = document.getElementById('txtDescripcion').value;
        usuario.dispMesas = 0;
        usuario.dispLlevar = 0;
        usuario.ispDelivery = 0;
        usuario.dispPedido = 0;
        usuario.estgrup = 0;
        usuario.orden = 0;
        usuario.modforz = 0;
        usuario.sucursalID = Number(document.getElementById('cboSucursal').value);
        const idSeleccionado = this.state.categoriaSeleccionada.id;
        var respuesta = await fetch(GetUrlApi() + '/api/tbgrupoes/' + idSeleccionado, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(usuario),
        });
        if (respuesta.ok) {
            swal('Categoria actualizada con exito');
            this.Recargar();
        }
    }

    async ClickEliminarCategoria() {
        swal({
            title: "¿Está seguro que desea dehabilitar esta categoría?",
            text: "Si confirma no podra ser seleccionaada en la toma de pedido",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const idSeleccionado = this.state.categoriaSeleccionada.id;
                var respuesta = await fetch(GetUrlApi() + '/api/tbgrupoes/' + idSeleccionado, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (respuesta.ok) {
                    swal('Categoria dehabilitada con exito');
                    this.Recargar();
                }
            }
        });
    }

    async ClickAgregarCategoria() {
        var categoria = {};
        categoria.desgrup = document.getElementById('txtDescripcion').value;
        categoria.sucursalID = document.getElementById('cboSucursal').value;
       categoria.dispMesas = 0;
       categoria.dispLlevar = 0;
       categoria.ispDelivery = 0;
       categoria.dispPedido = 0;
       categoria.estgrup = 0;
       categoria.orden = 0;
        categoria.modforz = 0;
        categoria.isHabilitado = true;

        var respuesta = await fetch(GetUrlApi() + '/api/tbgrupoes/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoria),
        });
        if (respuesta.ok) {
            const resp = await respuesta.json();
            if (resp.mensaje) {
                swal(resp.mensaje, {
                    icon: "error",
                })
                return;
            }

            swal('Categoria creado con exito').then(() => {
                this.Recargar();
            });
        }
    }

    ClickChkAddProd() {
        if (this.state.defaultIsPesableAgregar) {
            this.setState({ defaultIsPesableAgregar: false })
        } else {
            this.setState({ defaultIsPesableAgregar: true })
        }

    }
    //------->fin onclick


    Recargar() {
        this.setState(this.StateInicial);
        this.TraerCategorias();
    }

    GetHtmlAgregarCategoria() {

        return (
            <center>
                <table>
                    <tbody>
                        <tr>
                            <td>Descripcion</td>
                            <td>
                                <input className="form-control" id="txtDescripcion" type="text" /> 
                            </td>
                        </tr>

                        <tr>
                            <td>Sucursal</td>
                            <td>
                                <select id="cboSucursal"  className="form-control">
                                    {this.state.sucursales.map((item, i) => {
                                        const isSelected = item.id === this.state.categoriaSeleccionada.sucursalID;
                                        return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                    })}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button onClick={() => { this.ClickAgregarCategoria() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                    <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>



            </center>
        );
    }

    render() {
        return (
            <div className="row">
                <div style={this.Estilos.Columna1} className="column left">
                    {
                        (this.state.cargandoCategorias) ? (
                            <div className="spinner-border text-dark" role="status">
                                <span className="sr-only"></span>
                            </div>
                        ) : (null)
                    }
                    {
                        this.state.categoriasBotones.map((item, i) => {
                            const prefijo = 'categorias';
                            return (
                                <button name="botonesCategorias" className="btn" id={prefijo + i} style={this.Estilos.BotoneraCategoria} onClick={() => { this.ClickCategoria(item, prefijo + i) }} key={prefijo + i}><strong> {item.desgrup}</strong> </button>
                            );
                        })
                    }
                    <button onClick={() => { this.TraerSucursales(); this.setState({ activarAgregarCategoria: true, categoriaSeleccionada: {} }) }} name="botonesCategorias"
                        className="btn"
                        style={this.Estilos.BotoneraCategoria}>
                        <strong>+</strong> </button>
                </div>
                <div id="divCentral" style={this.Estilos.DivCentralTest}>
                    {
                        (this.state.categoriaSeleccionada.id>0) ? (
                            <center>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Descripcion</td>
                                            <td>
                                                <input className="form-control" id="txtDescripcion" type="text" />
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>Sucursal</td>
                                            <td>
                                                <select id="cboSucursal" className="form-control">
                                                    {this.state.sucursales.map((item, i) => {
                                                        const isSelected = item.id === this.state.categoriaSeleccionada.sucursalID;
                                                        return (<option selected={isSelected} value={item.id}>{item.descripcion}</option>);
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button onClick={() => { this.ClickEditarCategoria() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                                    <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                                <button onClick={() => { this.ClickEliminarCategoria() }} className="btn btn-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={this.Estilos.BtnSalir}>
                                    <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>

                            </center>
                        ): (null)
                        
                    }

                    {
                        (this.state.activarAgregarCategoria) ? (
                            this.GetHtmlAgregarCategoria()
                        ) : (null)
                    }
                </div>
                <div className="column right" style={this.Estilos.MarginLeftCR}>
                    {
                        (this.state.activarAgregarProducto) ? (
                            <center>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Descripcion</td>
                                            <td>
                                                <input className="form-control" id="txtDescripcionProd" type="text" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Precio</td>
                                            <td>
                                                <input className="form-control" id="txtPrecioProd" type="number" />
                                            </td>
                                        </tr>
                                       

                                        <tr>
                                            <td>Sucursal</td>
                                            <td>
                                                <select id="cboSucursalProd" className="form-control">
                                                    {this.state.sucursales.map((item, i) => {
                                                        return (<option value={item.id}>{item.descripcion}</option>);
                                                    })}
                                                </select>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>PLU</td>
                                            <td>
                                                <input className="form-control" id="txtPlu" type="text" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Zona de impresion</td>
                                            <td>
                                                <select id="cboZonaImpresion" className="form-control">
                                                    {this.state.zonasImpresion.map((item, i) => {
                                                        return (<option  value={item.id}>{item.descripcion}</option>);
                                                    })}
                                                </select>
                                            </td>
                                        </tr>

                                       

                                        <tr>
                                            <td>¿Es pesable?</td>
                                            <td>
                                                <input onClick={() => { this.ClickChkAddProd() }} id="chkPesable" type="checkbox" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Precio por kilo</td>
                                            <td>
                                                <input disabled={this.state.defaultIsPesableAgregar} id="precioKilo" type="number" className="form-control" />
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                                <button onClick={() => { this.CrearProducto() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                                    <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                            </center>
                        ): (null)
                    }

                   
                </div>
            </div>
        );
    }


}
