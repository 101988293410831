import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import { GetUrlApi, GetUrlBlinamicApi } from './Globales/VariableGlobales';
import UserProfile from './Globales/PerfilUsuario';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';
import { Card, Button, CardBody, CardHeader, Table, Form, CardFooter } from 'reactstrap';
import { Thumbs } from 'react-responsive-carousel';
import MySpinner from './MySpinner';
export class ActivacionGiftcard extends Component {
    static displayName = ActivacionGiftcard.name;
    ParentComp = '';
    Orden = {};
    SerieGiftcard = 0;

    constructor(props) {
        super(props);
        this.state = {
            mostrarTotal: false, 
            mensaje: '',
            GiftcardSucursal:[],
            showSpinner:false
        }
    
        if (this.props.orden)
            this.Orden = this.props.orden;
        if (this.props.ActualizarEstado)
            this.ActualizarEstado = this.props.ActualizarEstado;

        this.closeModal = this.closeModal.bind(this)
        this.EnviarGiftcard  = this.props.EnviarGiftcard;
    }
    ActualizarEstado(){

    }
    async EnviarGiftcard (barcode, conCodigoProveedor,precioGiftcard, serie){

    }
    componentDidMount() {
        console.log(GetUserCookie().Sucursal.configPOS);
      //  this.GetGiftcards();
    }
    VolverToPagar() { }

Estilos = {
    PopUpStyle: {
        'background': 'rgb(255, 255, 255)',
        'margin': 'auto',
        'border': '1px solid rgb(187, 187, 187)',
        'borderRadius': '8px',
        'width': '50%',
        'height': '85%'
    } ,
    PTotalesOrdenD:
        {
            'fontSize': '18px',
            'marginBottom': '0%'
        },
        ThStyleLG: {
            'width': '30%',
            // 'paddingRight': '0%'
        } ,
        ThStyleSM: {
            'width': '10%',
            // 'paddingRight': '3%'
        },
        ThStyleM: {
            'width': '20%',
            // 'paddingRight': '0%'
        },
        TableData:{
            'height' : '200px' 
        },
        DivProd:{
            'overflow' : 'auto' ,
            'height' : '250px' 
        }  
}

ActualizarTexto() { 
}
CerrarModalMaster() {
}
 
CheckGiftcard(id, serie){
    
    var table = document.getElementById("TablaDeDatos");
    let isSelectCount = 0; 
    for (var i = 0, row; row = table.rows[i]; i++) {
       var chk = document.getElementById("chk"+i).checked;
       if (chk){
            isSelectCount +=1;
       }
    }
    if (isSelectCount > 1){
        document.getElementById(id).checked=false;
        swal({
            title: "ERROR !",
            text: "Debe seleccionar solo una Giftcard.",
            icon: "error",
            button: "Entendido !",
        });

        return;
    }

}

 DesactivarModales() {
        //this.setState({  cheques:[] });
 }

async SearchGiftCard(){

   var serieleg = document.getElementById('serieLegibleSearch').value; 
   var  serie = document.getElementById('serieGiftcardSearch').value;
   var sku = document.getElementById('skuGiftcardSearch').value;

   if ((serie === '' || serie ==="") && (sku ==='' || sku ==="") && (serieleg==='' || serieleg==="")){
        swal({
            title: "ERROR !",
            text: "Debe ingresar al menos un parametro de busqueda.",
            icon: "error",
            button: "Entendido !",
        });
        return;
   }

   if (serie === '' || serie === ""){
    serie=0;
   }
   this.setState({showSpinner:true});
   let sucIDBli= GetUserCookie().Sucursal.sucursalIDBlinamic;
   var respuesta = await fetch(GetUrlBlinamicApi() + '/api/GiftCard/GetGiftcardsSucursal?SerieLegible=' + serieleg + '&Sku=' + sku + '&Serie=' + serie + '&SucursalID=' + sucIDBli , {
       method: 'get',
       headers: {
           'Content-Type': 'application/json',
       }
   });
   
   if (respuesta.ok) {
       var gifts = await respuesta.json();
       if (gifts.length > 0) {
         this.setState({ GiftcardSucursal: gifts});
       }
       else{
            swal({
                title: 'No se encontraron coincidencias.',
                icon: "warning",
                dangerMode: true,
            });
       }
   }
   else{
        swal({
            title: 'No se encontraron coincidencias.',
            icon: "warning",
            dangerMode: true,
        });
   }
   this.setState({showSpinner:false});
}

async SelectGiftcard(){
  

        var amountGiftCard = document.getElementById('amountGiftCard').value;

        if (amountGiftCard === '' || amountGiftCard ==="" || amountGiftCard === undefined){
             swal({
                 title: "ERROR !",
                 text: "Debe ingresar el monto a asignar a la Giftcard.",
                 icon: "error",
                 button: "Entendido !",
             });
             return;
        }

        if (amountGiftCard < 1){
            swal({
                title: "ERROR !",
                text: "El monto debe ser mayor a 0.",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }
        
        var table = document.getElementById("TablaDeDatos");
        let isSelectCount = 0; 
        for (var i = 0, row; row = table.rows[i]; i++) {
           var chk = document.getElementById('chk' + i).checked;
           if (chk){
                isSelectCount +=1;
           }
        }
        if (isSelectCount > 1){
            swal({
                title: "ERROR !",
                text: "Debe seleccionar solo una Giftcard.",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }
        if (isSelectCount < 1){
            swal({
                title: "ERROR !",
                text: "Debe seleccionar al menos una Giftcard.",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }
        var sku= "";
        var serie =""; 
        for (var i = 0, row; row = table.rows[i]; i++) {
            var chk =   document.getElementById('chk' + i).checked;
            if (chk){
                sku = row.cells[3].innerText;
                serie = row.cells[1].innerText;
                break;
            }
         }
         var neworden = this.props.orden;
       // await this.ActualizarEstado({ orden: neworden});
        await this.props.EnviarGiftcard(sku,false, amountGiftCard, serie);
        this.closeModal();


  }
  uppercaseName(inputInfo){
    let valueRut = document.getElementById(inputInfo).value;
    document.getElementById(inputInfo).value=valueRut.toUpperCase();

}
    limpiarTexto (){
  
        document.getElementById('serieGiftcardSearch').value='';
        document.getElementById('skuGiftcardSearch').value='';
        this.setState({GiftcardSucursal:[]});
    }
   
maxLengthCheck(id) {
    var object =  document.getElementById(id);
    if (object.value.length > object.maxLength)
      object.value = object.value.slice(0, object.maxLength)
  }
  closeModal() {
    this.props.closeModal()
    }

    render() {
        return (
           
            <Popup
            modal
            id='SeleccionaGiftcardPop'
            onClose={() => { this.closeModal() }}
            defaultOpen
            contentStyle={this.Estilos.PopUpStyle}
            closeOnDocumentClick>
             {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}
            <CardHeader className="bg-info text-white"><strong>Seleccione Giftcard a Activar</strong></CardHeader>
            <Form>
                <CardBody id="modCliente" className="form form-group">
                
                <div className="row"  >   
                          <div className='col-4'>
                                <label>Código Giftcard: </label>
                                <input type="number" id="serieGiftcardSearch" name="serieGiftcardSearch" className="form-control"  onBlur = {(val)=>
                                        {this.uppercaseName('serieGiftcardSearch');}} onChange={(val) => {
                                                this.maxLengthCheck('serieGiftcardSearch');
                                            }} maxlength = "40"/>
                            </div>  
                           
                            <div className='col-4'>
                                <label>Serie Legible Giftcard: </label>
                                <input type="text" id="serieLegibleSearch" name="serieLegibleSearch" className="form-control"  onBlur = {(val)=>
                                        {this.uppercaseName('serieLegibleSearch');}} onChange={(val) => {
                                                this.maxLengthCheck('serieLegibleSearch');
                                            }} maxlength = "40"/>
                            </div>
                            <div className='col-4'>
                                <label>Sku Giftcard: </label>
                                    <input type="text" id="skuGiftcardSearch" name="skuGiftcardSearch" className="form-control"  onBlur = {(val)=>
                                        {this.uppercaseName('skuGiftcardSearch');}} onChange={(val) => {
                                                this.maxLengthCheck('skuGiftcardSearch');
                                            }} maxlength = "40"/>     
                            </div>  
                    
                </div>
                <div className="row pt-2 justify-content-center">
                         <div >
                                     <button id="SearchGiftcards"   className="btn btn-info form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.SearchGiftCard() }} ><strong>Buscar</strong></button>
                                </div>        
                            </div>                           
               
                                        
                <Card className="row" >
                    <div className="ml-2" style={this.Estilos.DivProd}>

                    <table border="0" id="TablaDeDatos" className='table table-sm table-striped table-hover' style={this.Estilos.TableData}>
                        <thead className="bg-info text-white">
                            <th style={this.Estilos.ThStyleSM}></th>
                            <th style={this.Estilos.ThStyleM}><strong>Codigo</strong></th>
                            <th style={this.Estilos.ThStyleM} ><strong>Serie Legible</strong></th>
                            <th style={this.Estilos.ThStyleM} ><strong>Sku</strong></th>
                            <th style={this.Estilos.ThStyleLG} ><strong>Descripción</strong></th>
                        </thead>
                        <tbody>
                                {
                                        this.state.GiftcardSucursal.map((item, index) => {
                                            let id= item.serieLegible+'' + index;
                                            var IdTr = "Tr" +index;
                                            var chk = "chk" + index;
                                                return (
                                                    <tr id={IdTr}>
                                                        <td><input type="checkbox" onChange={() => this.CheckGiftcard(chk,item.serie)} id={chk}></input> </td>
                                                        <td>{item.serie}</td>
                                                        <td>{item.serieLegible}</td> 
                                                        <td>{item.sku}</td> 
                                                        <td>{item.descripcion}</td> 
                                                    </tr>

                                                );
                                            }
                                        )
                                }
                        </tbody>                                      
                    </table>   
                                

                              
                    </div>   
                    <CardFooter>
                    <div className="row  pt-2 justify-content-center">
                                <div className="d-inline-flex ml-2 mr-2 mb-2">
                                    <label ><p style={this.Estilos.PTotalesOrdenD}> <strong> Monto a Asignar: </strong></p></label>
                                    <input type="number" id="amountGiftCard" onChange={(val) => {
                                            this.maxLengthCheck('amountGiftCard');
                                        }} maxlength = "15"  className="form-control" />
                                </div>
                            </div>
                </CardFooter>       
                </Card>
               
                    <div className="row pt-2 justify-content-center">
                     
                       
                        <div className="d-inline-flex ml-2 mr-2 mb-2">
                            <button id="SelectGiftcard"   className="btn btn-info form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.SelectGiftcard() }} ><strong>Seleccionar Giftcard</strong></button>
                        </div>
                    </div>
                </CardBody>
            </Form>
        </Popup>
        )

    }


}