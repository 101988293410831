import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import Swal from "sweetalert2";

export class RegalarProd extends Component {
    static displayName = RegalarProd.name;


    constructor(props) {
        super(props);
        this.state = {
            activarModif: false,
            activarPopAnular:false,
        }
    }

    componentDidMount() {
        console.log(this.props.productos);
    }



    //handleInputChange(indiceProd) {
    //    const productos = this.props.productos;
    //    const nuevoPrecio = 1;
    //    productos[indiceProd].precpred = nuevoPrecio;
    //    this.props.ActualizarEstado({ carroProductos: productos });
    //}
    

    //--------ESTILOS

    Estilos = {
        FullAncho: {
            'width': '50%'
        },
        MitadAncho: {
            'width': '50%'
        },
        TercioAncho: {
            'width': '33%'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '85%'
        },
        EstiloPopup: {
            'width': '64%',
            'height': '60%',
            'border-radius': '2%',
            'marginLeft': '12%'
        },
        
    }
    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivoAnulacions', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ Motivos: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }

    //--------ESTILOS

    clifRefs(event, indiceSeleccionado) {
        const productos = this.props.productos;
        
        //console.log(productos[0].IsRegalo);
        //console.log(productos[1].regaloPorcentaje);
        let idxCarro=0;
        productos.map((item)=>{
            let codProd = item.desprod.split(';')[0];
            if (codProd==productos[event].desprod.split(';')[0] && idxCarro != event)
            {
                if (item.IsUsoRegalo){
                    Swal.fire({
                        title: "La cantidad del producto regalo no puede ser mayor a 1.",
                        icon: "error"
                    });
                    indiceSeleccionado.target.checked = false;
                    idxCarro+=1;
                    return;
                }
            }
            idxCarro+=1;
        });


        const sumall = productos.map(item => item.precpred * item.cantidad).reduce((prev, curr) => prev + curr, 0);

        if (productos[event].IsRegalo) {

            if (indiceSeleccionado.target.checked) {
                if (productos[event].cantidad > 1){
                    Swal.fire({
                        title: "La cantidad del producto regalo no puede ser mayor a 1.",
                        icon: "error"
                    });
                    indiceSeleccionado.target.checked = false;
                    return;
                }
                if (((sumall - productos[event].precpred) * productos[event].regaloPorcentaje) / 100 > productos[event].ProductoRegaloValor) {
                    productos[event].precpred = parseInt(1);
                    productos[event].IsUsoRegalo = 1;
                    this.props.ActualizarEstado({ carroProductos: productos });
                } else {
                    console.log('El valor supera el porcentaje para regalo')
                    // mensaje de que supera el porcentaje de regalo
                    Swal.fire({
                        title: "El valor supera el porcentaje para regalo.",
                        icon: "error"
                    });
                    indiceSeleccionado.target.checked = false;
                }
            } else {
                productos[event].precpred = parseInt(productos[event].ProductoRegaloValor);
                productos[event].IsUsoRegalo = 0;
                this.props.ActualizarEstado({ carroProductos: productos });
            }
        } else {

            Swal.fire({
                title: "El producto no esta configurado para regalo.",
                icon: "error"
            });
            indiceSeleccionado.target.checked = false;
        }
    }


    render() {
        return (
            <Popup
                contentStyle={this.Estilos.EstiloPopup}
                modal
                defaultOpen
                onClose={() => { this.props.CerrarModalMaster() }}
                closeOnDocumentClick>
                <div style={this.Estilos.Escroleable}>
                        <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Descripcion</th>
                                <th scope="col">Regalo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.props.productos.map((item, i) => {
                                    return <tr key={i}>
                                        <td>{item.cantidad}</td>
                                        <td>{item.desprod} </td>
                                        {/*<td>*/}
                                        {/*    <center> <a onClick={(e) => { e.preventDefault(); this.props.DesactivarModales(); this.props.ClickEliminar(i, this.props.productos, 8000) }} href="">*/}
                                        {/*        <i className="fa fa-ban fa-2x text-danger" aria-hidden="true"></i>*/}
                                        {/*    </a>  </center></td>*/}

                                        <td>
                                            
                                            <center> <input
                                                name="isRegalo"
                                                type="checkbox"
                                                checked={item.IsUsoRegalo}
                                                onClick={this.clifRefs.bind(this,i)}
                                            />  </center>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>

                    </table>

                </div>
                <center>
                    <button style={this.Estilos.FullAncho} className="btn btn-success" onClick={() => { this.props.CerrarModalMaster() }}> <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>    </center>
            </Popup>
        );
    }
}
