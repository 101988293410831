var UserProfile = (function () {

    var usuario = {
        id: 0,
        username: '',
        tipoUsuario: 0,
        idGaveta: 0,
        CajaID : 1,
        caja: {},
        SucursalID: 1,
        Sucursal: {},
        Empresa:'',
        EmpresaID:0,
        CajaExternaID: 0
    };

    var getUser = function () {
        return usuario;    // Or pull this from cookie/localStorage
    };

    var setUser = function (user) {
        usuario = user;
        // Also set this in cookie/localStorage
    };

    var resetUser = function () {
        setUser({
            id: 0,
            username: '',
            tipoUsuario: 0,
            idGaveta: 0,
            CajaID: 1,
            caja: {},
            SucursalID: 1,
            Sucursal: {},
            Empresa:'',
            EmpresaID:0,
            CajaExternaID: 0
        });
    }

    return {
        getUser: getUser,
        setUser: setUser,
        resetUser: resetUser
    }

})();

export default UserProfile;