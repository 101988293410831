import React, { Component } from 'react';
import { GetUrlApi } from './Globales/VariableGlobales';
import { Estilos } from './Globales/Estilos';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Redirect } from 'react-router';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';
import UserProfile from './Globales/PerfilUsuario';
import Popup from "reactjs-popup";
export class OrdenesDos extends Component {
    static displayName = OrdenesDos.name;

    MostrarAbiertas = false;
    MostrarPagadas = true;
    OrdenesIniciales = {};
    FiltroEstado = "";
    FiltroUsuario = -1;
    FiltroUsuarioString = "";
    FiltroTipoOrden = 0;
    FiltroTipoOrdenString ="";
    FiltroFecha = "";
    FiltroFechaString= "";
    IDcheckFirst = "";
    TipoVentaIDOrderPrin = 0;

    constructor() {
        super();
        this.state = {
            ordenes: [],
            ordenesFiltradas: [],
            redirigirHome: false,
            cargando: false,
            usuarios: [],
            MostrarAbiertas: true,
            MostrarPagadas: true,
            CatidadOrdenes: 0,
            filtroEstado: false,
            filtroTipoOrden: false,
            filtroUsuario: false,
            filtroFecha:false,
            Anuladas:false,
            Pagadas:false,
            Todas: false,
            CombinarFunc: false,
            combinarCount: 0,
            ordersCombinar: [],

        }
        this.TraerUsuarios();
    }
 
    componentDidMount() {
        this.TraerOrdenes();
        // this.FechaDefault();
    }

    async TraerUsuarios() {
        var SucursalID = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/TraerUsuarios?sucursalID=' + SucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ usuarios: datos });
        }
        else {
            alert('error');
        }
    }

    async TraerOrdenes() {
        await this.setState({ cargando: true });
        var SucursalID = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden?basico=true&HorasDesde=true&sucursalID=' + SucursalID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos)
            var cantidad = datos.length;
            datos = datos.filter(p => p.total > 0);
            this.OrdenesIniciales = datos;
            this.FiltrarEs(1);
            this.setState({ ordenes: datos, cargando: false });
            this.FiltrarEs(1);
            //await this.FiltrarDatos();
            //this.setState({ ordenes: datos, cargando: false });
        }
        else {
            alert('error');
        }
    }


    async TraerOrdenesFecha(fecha) {
        await this.setState({ cargando: true });
        var SucursalID = GetUserCookie().SucursalID;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden?sucursalID=' + SucursalID + '&fecha=' + fecha, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            var cantidad = datos.length;
            datos = datos.filter(p => p.total > 0);
            this.OrdenesIniciales = datos;
            this.FiltrarEs(1);
            this.setState({ ordenes: datos, cargando: false });
            this.FiltrarEs(1);
            //await this.FiltrarDatos();
            //this.setState({ ordenes: datos, cargando: false });
        }
        else {
            alert('error');
        }
    }

    GetFechaFormateada(fecha) {
        var f = fecha.split('T');
        var fechax = f[0].split('-');
        var ff = fechax[2] + '-' + fechax[1] + '-' + fechax[0];
        return ff;
    }

    GetHoraFormateada(hora) {
        var horas = hora.split(':');
        var horax = horas[0] + ':' + horas[1];
        return horax;
    }

    //async ClickBorrarOrden(idOrden) {
    //    swal({
    //        title: "¿Esta seguro que desea borrar la orden número " + idOrden + "?",
    //        // text: "Se cambiara el medio de pago actual",
    //        icon: "warning",
    //        buttons: true,
    //        dangerMode: true,
    //    }).then(async (willDelete) => {
    //        if (willDelete) {
    //            const res = await fetch(GetUrlApi() + '/api/Orden/' + idOrden, {
    //                method: 'DELETE',
    //                headers: {
    //                    'Content-Type': 'application/json',
    //                }
    //            }).then(() => {
    //                var cbo = document.getElementById('cboEstado');
    //                this.TraerOrdenes();
    //            });
    //            swal("La orden se a borrado con exito", {
    //                icon: "success",
    //            });
    //        }
    //    });

    //}

    FiltrarEs(num) {
        var filtrar = "";
        if (num == 1) {
            this.setState({ Abiertas: true, Anuladas: false, Pagadas: false, Todas: false })
            filtrar = "Abiertas";
        } else if (num == 2)
            {
            this.setState({ Abiertas: false, Anuladas: true, Pagadas: false, Todas: false })
            filtrar = "Anuladas";

        } else if(num==3) {
            this.setState({ Abiertas: false, Anuladas: false, Pagadas: true, Todas: false })
            filtrar = "Pagadas";

        }else if(num==4) {
            this.setState({ Abiertas: false, Anuladas: false, Pagadas: false, Todas: true })
            filtrar = "Todas";
        }else if(num==1001) {
            this.setState({ Abiertas: false, Anuladas: false, Pagadas: false, Todas: true })
            filtrar = "Hoy";
        }
        this.FiltroEstado = filtrar;
        this.FiltrarDatos();

    }
    FiltrarTipoOrden(num) {
       
        this.FiltroTipoOrden = num;
        if (num==1) {
            this.FiltroTipoOrdenString = "Mesa"

        } else if (num==2) {
            this.FiltroTipoOrdenString="Llevar"
        } else if (num==3) {
            this.FiltroTipoOrdenString = "Delivery"

        } else if (num==4) {

            this.FiltroTipoOrdenString="Bar"
        } else {
            this.FiltroTipoOrdenString = ""

        }

        this.FiltrarDatos();

    }
    FiltrarUsuario(num) {

        this.FiltroUsuario = num;
        this.FiltroUsuarioString=this.GetNameUser(num)
        this.FiltrarDatos();

    }
    FiltrarFecha(num) {

        if (num==1) {
            var fechaHasta = document.getElementById('fechaHasta').value;
            this.FiltroFecha = fechaHasta;
            this.TraerOrdenesFecha(fechaHasta).then(() => {
                this.FiltrarDatos(fechaHasta);
            });
           
        } else if (num ==0) {
            var fechaHasta = "";
            this.FiltroFecha = "";
            this.FiltrarDatos();
        }


    }

    async FiltrarDatos() {
        var ordenes = this.OrdenesIniciales;
       //console.log(this.OrdenesIniciales);
        var filtrarEstados = this.FiltroEstado;
        var filtrarUsuarios = this.FiltroUsuario;
        var filtrarTipoOrden = this.FiltroTipoOrden;


        
        var filtrarFechas = this.FiltroFecha;
        var ss = filtrarFechas.split('-')
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;

        if (filtrarFechas=="") {
            filtrarFechas = today;
        }
        console.log(filtrarEstados);
        console.log(filtrarUsuarios);
        console.log(filtrarTipoOrden);
        console.log(filtrarFechas);
        var ver;
       //var cboEstado = document.getElementById('cboEstado');

        if (filtrarEstados != "") {

            if (filtrarEstados === "Pagadas") {
                    ordenes = ordenes.filter(p => p.pagado && !p.isAnulada);
            }
            else if (filtrarEstados === "Abiertas") {
                ordenes = ordenes.filter(p => p.total > 0 && !p.isAnulada && !p.pagado );
            }
            else if (filtrarEstados === "Anuladas") {
                    ordenes = ordenes.filter(p => p.total > 0 && p.isAnulada);
            }
            else if (filtrarEstados === "Todas") {
                    ordenes = ordenes.filter(p => p.total > 0);
            } 
            
        }

        if (filtrarUsuarios != -1) {
            if (filtrarUsuarios == 0) {
                ordenes = ordenes.filter(p => p.total > 0);

            } else {
                ordenes = ordenes.filter(p => p.usuariosID == filtrarUsuarios);
            }
        }

        if (filtrarTipoOrden != 0) {

            if (filtrarTipoOrden==100) {
                ordenes = ordenes.filter(p => p.total > 0 );

            } else {
                ordenes = ordenes.filter(p => p.tiposVentaID == filtrarTipoOrden);
            }
        
        }
        var fecss = filtrarFechas.split('-');
        this.FiltroFechaString = fecss[2] + "-" + fecss[1] + "-" + fecss[0]
        this.FiltroFecha = filtrarFechas;
        if (filtrarFechas != "") {
            var fec = filtrarFechas;
            var filtrarFechasdesde = filtrarFechas + "T" + "00:00"
            filtrarFechas = filtrarFechas + "T" + "23:59"
            if (fec != today && filtrarEstados=== "Abiertas")
            {
                ordenes = ordenes.filter(p => p.total > 0 && !p.isAnulada && !p.pagado && p.fecha >= filtrarFechasdesde && p.fecha <= filtrarFechas);
            }
            else if (filtrarEstados === "Abiertas") {
                ordenes = ordenes.filter(p => p.total > 0 && !p.isAnulada && !p.pagado);
            } else {
                ordenes = ordenes.filter(p => p.fecha >= filtrarFechasdesde && p.fecha <= filtrarFechas);
            }
        }
       

        this.setState({ ordenes: ordenes, cargando: false, filtroEstado: false, filtroTipoOrden:false,filtroUsuario:false,filtroFecha:false });
    }
    ClickCantidadEfectivo() {
        this.setState({ mostrarTeclado: true });
        //console.log('mostrar teclado activado');
    }

    GetNameUser(id) {
        var usuario = this.state.usuarios.find((element) => {
            return element.id === id;
        });
        if (usuario) {
            return usuario.nombre + ' ' + usuario.apellido;
        }
        else {
            return '';
        }
    }
    Combinar()
    {

        var arr = this.state.ordersCombinar;
        if (arr.length == 0) {
            swal({
                title: "Para combinar",
                text: "Debes seleccionar las ordenes",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((value) => {
                    if (value) {
                        this.setState({ CombinarFunc: true, combinarCount: arr.length, ordersCombinar:[] });
                    } else {

                        this.setState({ CombinarFunc: false });
                    }

                });
        } else if (arr.length <= 1) {
            swal({
                title: "Para combinar",
                text: "Debes seleccionar almenos dos ordenes",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((value) => {
                    if (value) {
                        this.setState({ CombinarFunc: true, combinarCount: arr.length, ordersCombinar: arr });
                    } else {

                        this.setState({ CombinarFunc: false, combinarCount: arr.length, ordersCombinar: []});
                    }

                });

        } else {
            swal({
                title: "¿Estas Seguro de Combinar estas Ordenes?",
                text: "",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((value) => {
                    if (value) {
                        this.ClickCombinar(arr);
                    } else {
                        var id = arr[0] + "" + this.state.combinarCount;
                        var cb = document.getElementById(id); 
                        cb.className = "card bg-ligth border-success mb-3 ml-1 mr-1 col-sm-3";
                        this.IDcheckFirst = "";
                        this.setState({ CombinarFunc: false, combinarCount:arr.length, ordersCombinar: []});
                    }

                });
        }
        

    }
    MarcarOrden(id, idCheck,TipoVentaID)
    {
        var comCount = this.state.combinarCount;
        var arr = this.state.ordersCombinar;
    

        var b= document.getElementById(id); 
        var cb = document.getElementById(idCheck); 
        if (b.style.display == "none") {
            b.style.display = "block";
            comCount = comCount + 1;
            if (comCount === 1) {
                cb.className = "card bg-FirstOrder  border-success mb-3 ml-1 mr-1 col-sm-3";
                this.TipoVentaIDOrderPrin = TipoVentaID;
            }
            arr.push(id);
        } else {
            var i = arr.indexOf(id);
            if (i !== -1) {
                arr.splice(i, 1);
            }
            b.style.display = "none";
            comCount = comCount - 1;
        }
        console.log(arr);
        this.setState({ combinarCount: comCount, ordersCombinar:arr});
    }
    async ClickCombinar(arr) {
        console.log(arr);
        var data = {};
        var OrdenIDPrin = arr[0];
        var i = arr.indexOf(OrdenIDPrin);
        if (i !== -1) {
            arr.splice(i, 1);
        }
        console.log(arr);
        data.OrdenIDPrincip = OrdenIDPrin;
        data.Ordenes = arr;
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/CombinarDos', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (respuesta.ok) {
               
                this.setState({CombinarFunc: false, combinarCount: 0, ordersCombinar: []});
                this.ClickEditar(OrdenIDPrin, this.TipoVentaIDOrderPrin);
            }
    }
    Redirigir(url) {
        this.props.history.push(url);
    }

    Estilos = {
        columnaLateral: {
            'width': '10%'
        },
        columnaCentral: {
            'width': '100%',
            'backgroundColor': 'white',
            'overflow-x': 'hidden',
            'padding-top': '20px',
            'height': '100%',
        },
        tabla: {
            'width': '100%',
            'position': 'relative',
            'height': '500px',
            'overflow': 'auto',
            'display': 'block'
        },
        FullAncho: {
            'width': '50%',
            'margin-left': '-25%'
        },
        TableHeaderFijo: {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': '#ecf0f5'
        },
        DivStyle: {
            'width': '100%',
            'position': 'relative',
            'overflow': 'auto',
            'display': 'block',
            'margin-left': '600px'
        },
        CardStyle: {

            'margin-left': '6px',
            'max-width': '16rem'
        },
        CardStyleForPay: {
            'border-color': 'green',
            'margin-left': '6px',
            'max-width': '16rem'
        },
        HeaderCardStyle:
        {
            'padding': '.75rem 1.25rem',
            'margin-bottom': '0',
            'background-color': 'rgba(0,0,0,.03)',
            'border-bottom': '1px solid rgba(0, 0, 0, .125)',
            'width': '19.70rem',
            'margin-left': '-15px'
        },
        BodyCardStyle:
        {
            'margin-left': '-15px'
        },
        LinkStyle:
        {
            'color': 'black'
        },
        ColSideNav:
        {
          'margin-left': '-7%'
        },
        SideNav:
        {
            'height': 'auto',
            'width': '7%',
            'position': 'fixed',
            'top': '92px',

            'background-color': 'white',
            'overflow-x': 'hidden',
            'padding-top': '20px'
        },
        Content:
        {
            'margin-left': '0%',
            'top': '0',

            'background-color': 'white',
            'overflow-x': 'hidden',
            'padding-top': '20px'
        },
        ButtonStyle:
        {
            'z-index':'1',
            'borderRadius': '10px',
            'height': '55px',
            'width': '55px',
            'border-color': 'cadetblue',
            'background-color': '#f2f2f2',
            'margin-bottom': '3%'
        },
        TipoVenta:
        {
            'margin-left': '-10px',
            'margin-top': '-12px',
            'font-size': '13px',
            //'font-family': 'Arial,sans-serif'
        },TipoVenta2:
        {
            'margin-left': '-28px',
            'margin-top': '-12px',
            'font-size': '13px',
            //'font-family': 'Arial,sans-serif'
        },
        ValorVenta:
        {
            'margin-left': '5px',
            'margin-top': '3px',
            'font-size': '15px',
            'font-family': 'Arial,sans-serif',
            'text-align': 'center'
        },
        FechaVenta:
        {
            'margin-left': '5px',
            'margin-bottom': '-16px',
            'margin-top': '14px',
            'font-size': '10px',
            'font-family': 'Arial,sans-serif',
            
            'color': 'gray'
        },
        DivWidth:
        {
            'width': '248px',
            'borderRadius': '2px',
            'margin-left': '-14px'

        },
        BtnStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        MarginTr:
        {
            'margin-bottom': '10%'
        },
        botonVerTodos:
        {
        'margin-left': '-8%',
        'width': '60%',
            'background-color': '#769e8e',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'color': 'white',
            'margin-top': '5%'
        },
        MarginDv:
        {
            'margin-bottom': '2%',
            'width': '23%',
            'align-content': 'center',
            'margin-left': '-20%'
        },
        STextStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'font-size': '11px',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid'
        },
        BtnDStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '37%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%'
        },
        botonVerTodas:
        {
            'margin-left': '39%',
            'width': '100%',
            'background-color': 'rgb(122, 165, 93)',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'color': 'white',
            'margin-top': '10%',
            'font-size': '10px'
        },
        botonVerTodasAzul:
        {
            'margin-left': '39%',
            'width': '100%',
            'background-color': '#769e8e',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'color': 'white',
            'margin-top': '10%',
            'font-size': '10px'
        },
        tdFecha:
            { 'width': '40%' },
        divFecha: {
            'width': '100%',
            'margin-left': '-17%'
        },
        icon:
        {
                'margin-left': '-8%'
        },
        divFilter:
        {
            'border-radius': '10px',
            'height': '66%',
            'background': 'white',
            'margin-bottom': '0%',
        },
        FiltroSpan:
        {
            'font-size': '13px',
            'font-weight': 'bold',
            'border-radius': '5px',
            'background': '#B0BBC4'
        },
        Anuladas:
        {
            'text-decoration': 'line-through'
        },
        AnuladaWord:
        {
            'border-radius': '4px',
            'border-color': '#6c757d !important',
            'border': '2px solid',
            'font-size': '13px',
            'margin-left': '2%'
        },
        PagadaWord:
        {
            'border-radius': '4px',
            'border-color': '#dc3545 !important',
            'border': '2px solid',
            'font-size': '13px',
            'margin-left': '2%'
        },
        BorderSuccess:
        {
            'margin-bottom': '1rem!important',
            'margin-left': '0.25rem!important',
            'border-color': '#28a745!important',
            'margin-right': '0.25rem!important',
            'background-color': '#f8f9fa !important',
            'border': '2px solid'
        },
        DivCenter:
        {
         'margin-right': '-43%',
         'margin-left': '3%'
        },
        SideNavCombinar:
        {
                'height': 'auto',
                'width': '6%',
                'position': 'fixed',
                'top': '92px',
                'background-color': 'white',
                'overflow-x': 'hidden',
                'padding-top': '20px',
        },
        ColSideNavCombinar:
        {
            'margin-left': '7%'

        },
        SelectSpan:
        {
            'display':'none'
        },
        NameStyle:
        {
         'margin-left': '-80%',
         'font-size': '13px'
        },
        DivNameStyle:
        {
            'margin-top': '-6%',
            'margin-bottom': '6%'
        },
        //Al Span de UberEats
        FontDel1:
        {
            'font-size':'20px',
        },
        FontDel2: //Al Span de  Pedidos Ya
        {
            'font-size':'20px',
        },
        //Al Icono Rappi
        FontDel3:
        {
         'font-size': '30px',
            'margin-left': '-245%',
            'color':'#ff7864'
        },
        FechaInput:
        {
            'height': '34px',
            'background': '#eef3f1',
            'border-radius': '8px'
        }

    }

    //INICIO FUNCIONES CLICK
    MEstadoOpen(estado)
    {
            this.setState({ filtroEstado: true });
    }
    MEstadoClose(estado)
    {
            this.setState({ filtroEstado: false });
    }
    MTOrdenOpen(estado) {
        this.setState({ filtroTipoOrden: true });
    }
    MTOrdenClose(estado) {
        this.setState({ filtroTipoOrden: false });
    }
    MUsuarioOpen(estado) {
        this.setState({ filtroUsuario: true });
    }
    MUsuarioClose(estado) {
        this.setState({ filtroUsuario: false });
    }
    MFechaOpen(estado) {

        this.setState({ filtroFecha: true });
    }
    MFechaClose(estado) {
        this.setState({ filtroFecha: false });
    }
    ClickVolver() {
        this.setState({ redirigirHome: true });
    }
    ClickEditar(ordenId,TipoVentaID) {
        this.Redirigir('/Venta?tipoV=' + TipoVentaID+'&editar=true&id=' + ordenId);
    }
    ClickPagar(ordenId) {
        this.Redirigir('/ParaLVentalevarDos?editar=true&pagar=true&id=' + ordenId);
    }
    ParaLlevar(id,TipoVentaID, pagado) {
        //if (!pagado) {
        //    this.ClickEditar(id);

        //}
        this.ClickEditar(id, TipoVentaID);
    }
    IrParaLlevar() {
        this.Redirigir('/Venta');
    }
    IrMesa() {
        this.Redirigir('/Mesas');
    }
    IrBar() {
        this.Redirigir('/Venta?tipoV=4');
    }
    IrDelivery() {
        this.Redirigir('/Venta?tipoV=3');
    }
    //FIN FUNCIONES CLICK

    render() {
        return (
            <div className="row">
                <div className="col-sm-1"></div>
                <div className="container col-sm-10" style={this.Estilos.columnaCentral}>
                    {
                        (this.state.redirigirHome) ? (<Redirect to='/' />) : (null)
                    }
                    <div className="row">


                        <div className="col-sm-12" style={this.Estilos.Content} >
                            <div className="row">
                                <div className="col-sm-1" style={this.Estilos.ColSideNav}>
                                    <div style={this.Estilos.SideNav}>

                                        <center>
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isParaLlevarHabilitado) ? (
                                                    <React.Fragment>
                                                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title="Para Llevar" onClick={() => this.IrParaLlevar()}><i className="fa fa-cart-plus fa-2x" style={this.Estilos.icon}></i></button><br />
                                                    </React.Fragment>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isMesasHabilitado) ? (
                                                    <React.Fragment>
                                                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title="Mesa" onClick={() => this.IrMesa()}><i className="fa fa-cutlery fa-2x"></i></button><br />
                                                    </React.Fragment>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isBarHabilitado) ? (
                                                    <React.Fragment>
                                                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title="Bar" onClick={() => this.IrBar()}><i className="fa fa-glass fa-2x"></i></button><br />
                                                    </React.Fragment>
                                                ) : (null)
                                            }
                                            {
                                                (GetUserCookie().Sucursal.configPOS.isDeliveryHabilitado) ? (
                                                    <React.Fragment>
                                                        <button className="btn" style={this.Estilos.ButtonStyle} data-toggle="tooltip" data-placement="right" title="Delivery" onClick={() => this.IrDelivery()}> <i className="fa fa-paper-plane fa-2x" aria-hidden="true"></i> </button><br /> 
                                                    </React.Fragment>
                                                ): (null)
                                            }
                                            <hr />
                                            <div style={this.Estilos.divFilter}>
                                                <button data-toggle="tooltip" data-placement="right" title="Estado" className="btn" style={this.Estilos.ButtonStyle} onClick={() => this.MEstadoOpen()} ><i className="fa fa-thumb-tack fa-2x"></i></button><br />
                                                <span style={this.Estilos.FiltroSpan}>{(this.FiltroEstado == "Hoy") ? ("Abiertas") : (this.FiltroEstado)}</span>
                                            </div>
                                            <div hidden='hiddden' style={this.Estilos.divFilter}>

                                            <button  data-toggle="tooltip" data-placement="right" title="Usuario" className="btn" style={this.Estilos.ButtonStyle} onClick={() => this.MUsuarioOpen()}><i className="fa fa-users  fa-2x"></i></button><br />
                                                <span style={this.Estilos.FiltroSpan}>{(this.FiltroUsuarioString == "")?("Todos"):(this.FiltroUsuarioString)}</span>
                                            </div>
                                            <div hidden='hiddden' style={this.Estilos.divFilter}>
                                                <button   data-toggle="tooltip" data-placement="right" title="Tipo" className="btn" style={this.Estilos.ButtonStyle} onClick={() => this.MTOrdenOpen()}><i className="fa fa-tags fa-2x"></i></button><br />
                                                <span style={this.Estilos.FiltroSpan}>{(this.FiltroTipoOrdenString == "") ? ("Todos") : (this.FiltroTipoOrdenString)}</span>
                                            </div>
                                            <div hidden='hiddden' style={this.Estilos.divFilter}>
                                                <button   data-toggle="tooltip" data-placement="right" title="Fechas" className="btn" style={this.Estilos.ButtonStyle} onClick={() => this.MFechaOpen()}><i className="fa fa-calendar-o fa-2x"></i></button><br />
                                                <span style={this.Estilos.FiltroSpan}>{(this.FiltroFechaString != "") ? (this.FiltroFechaString) : (this.FiltroFechaString)}</span>
                                            </div>
                                        </center>

                                    </div>
                                </div>
                               

                                <div className="col-sm-10">


                                    {
                                        (this.state.ordenes) ? (


                                            <div className="row" style={this.Estilos.DivCenter}>
                                                {this.state.ordenes.map((item, index) => {
                                                    if (!item.infoDelivery) {
                                                        item.infoDelivery = {};
                                                    }
                                                    var idCheck = item.id +"" +this.state.combinarCount;
                                                    return (
                                                        
                                                        (item.isAnulada === true) ? (
                                                            <div className="card bg-light  border-secondary mb-3 ml-1 mr-1 col-sm-3" style={this.Estilos.BorderSolid}
                                                                onClick={() => this.ParaLlevar(item.id, item.tiposVentaID, item.pagado)}>

                                                                <div className="card-body" style={this.Estilos.BodyCardStyle}>

                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <h6 className="card-title">
                                                                                <div className="row">
                                                                                    <div className="col-sm-8">
                                                                                        <b>{item.tiposVenta.descripcion} #{item.numOrden} </b>
                                                                                    </div>
                                                                                    <div className="col-sm-4">
                                                                                    </div>
                                                                                </div>
                                                                            </h6>
                                                                            <hr />
                                                                            <div className="row">
                                                                                         <div className="col-sm-6">
                                                                                                <div className="card-text" style={this.Estilos.TipoVenta}><b>Rut Cliente</b> </div>
                                                                                            </div>
                                                                                            <div className="col-sm-4" style={this.Estilos.DivNameStyle}>
                                                                                                {
                                                                                                    (item.tiposVenta.descripcion == "Delivery") ?
                                                                                                        (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.infoDelivery.name} </span></b>):
                                                                                                        (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.nombreCliente} </span></b>)
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-sm-2">
                                                                                                <div className="card-text text-right" style={this.Estilos.TipoVenta}>
                                                                                                    {(item.tiposVenta.descripcion == "Delivery") ? (<b>
                                                                                                        {(item.infoDelivery.tipoDelivery == "Rappi") ?
                                                                                                (<span className="text-rigth"><i className="icon-Rappi fa-1x" style={this.Estilos.FontDel3}></i></span>) :
                                                                                                (item.infoDelivery.tipoDelivery == "Uber Eats") ?
                                                                                                    (<span className="text-rigth"><span className="icon-UberEats" style={this.Estilos.FontDel1}>
                                                                                                        <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                                                                                                    </span></span>) :(item.infoDelivery.tipoDelivery == "Pedidos Ya") ?
                                                                                                        (<span className="text-rigth text-danger"><i className="icon-pedidosYa-1 fa-1x" style={this.Estilos.FontDel2}></i></span>) : (null)

                                                                                                    }
     
                                                                                                    </b>) :
                                                                                            (item.mesaID > 0) ? (<b><span className="text-right">{item.mesa.descipcion}</span></b>) : (null)


                                                                                                    }                                                                                                    
                                                                                                    

                                                                                                </div>

                                                                                            </div>
                                                                            </div>
                                                                            <div className="card-text text-secondary" style={this.Estilos.ValorVenta}><b style={this.Estilos.Anuladas}>{GetPrecioFormateado(item.total)}</b><b><span style={this.Estilos.AnuladaWord}> ANULADA</span></b></div>
                                                                            <div className="card-text" style={this.Estilos.FechaVenta}>{this.GetFechaFormateada(item.fecha)} {this.GetHoraFormateada(item.hora)}    {this.GetNameUser(item.usuariosID)}</div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : (item.pagado === true) ? (
                                                                <div className="card bg-light border-danger mb-3 ml-1 mr-1 col-sm-3" style={this.Estilos.BorderSolid}
                                                                    onClick={() => this.ParaLlevar(item.id, item.tiposVentaID, item.pagado)}>

                                                                    <div className="card-body" style={this.Estilos.BodyCardStyle}>

                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <h6 className="card-title">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-8">
                                                                                            <b>{item.tiposVenta.descripcion} #{item.numOrden} </b>
                                                                                        </div>
                                                                                        <div className="col-sm-4">
                                                                                        </div>
                                                                                    </div>
                                                                                </h6>
                                                                                <hr />
                                                                                <div className="row">
                                                                                    <div className="col-sm-6">
                                                                                        <div className="card-text" style={this.Estilos.TipoVenta}><b>Rut Cliente</b> </div>
                                                                                    </div>
                                                                                    <div className="col-sm-4" style={this.Estilos.DivNameStyle}>
                                                                                        {
                                                                                            (item.tiposVenta.descripcion == "Delivery") ?
                                                                                                (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.infoDelivery.name} </span></b>) :
                                                                                                (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.nombreCliente} </span></b>)
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-sm-2">
                                                                                        <div className="card-text text-right" style={this.Estilos.TipoVenta}>
                                                                                            {(item.tiposVenta.descripcion == "Delivery") ? (<b>
                                                                                                {(item.infoDelivery.tipoDelivery == "Rappi") ?
                                                                                                    (<span className="text-rigth"><i className="icon-Rappi fa-1x" style={this.Estilos.FontDel3}></i></span>) :
                                                                                                    (item.infoDelivery.tipoDelivery == "Uber Eats") ?
                                                                                                        (<span className="text-rigth"><span className="icon-UberEats" style={this.Estilos.FontDel1}>
                                                                                                            <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                                                                                                        </span></span>) : (item.infoDelivery.tipoDelivery == "Pedidos Ya") ?
                                                                                                            (<span className="text-rigth text-danger"><i className="icon-pedidosYa-1 fa-1x" style={this.Estilos.FontDel2}></i></span>) : (null)

                                                                                                }

                                                                                            </b>) :
                                                                                                (item.mesaID > 0) ? (<b><span className="text-right">{item.mesa.descipcion}</span></b>) : (null)


                                                                                            }


                                                                                        </div>

                                                                                    </div>
                                                                                </div> <div className="card-text text-danger" style={this.Estilos.ValorVenta}><b>{GetPrecioFormateado(item.total)}</b>  <b><span style={this.Estilos.PagadaWord}> PAGADA</span></b></div>
                                                                                <div className="card-text" style={this.Estilos.FechaVenta}>{this.GetFechaFormateada(item.fecha)} {this.GetHoraFormateada(item.hora)}  {this.GetNameUser(item.usuariosID)}</div>


                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ) : (item.pagado == false && item.pagadoDelivery == true) ? (
                                                                    <div className="card bg-light  border-primary mb-3 ml-1 mr-1 col-sm-3" style={this.Estilos.BorderSuccess} onClick={() => this.ParaLlevar(item.id, item.tiposVentaID, item.pagado)}>
                                                                        <div className="card-body" style={this.Estilos.BodyCardStyle}>

                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <h6 className="card-title">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-8">
                                                                                                <b>{item.tiposVenta.descripcion} #{item.numOrden} </b>
                                                                                            </div>
                                                                                            <div className="col-sm-4">
                                                                                            </div>
                                                                                        </div>
                                                                                    </h6>
                                                                                    <hr />
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="card-text" style={this.Estilos.TipoVenta}><b>Rut Cliente</b> </div>
                                                                                        </div>
                                                                                        <div className="col-sm-4" style={this.Estilos.DivNameStyle}>
                                                                                            {
                                                                                                (item.tiposVenta.descripcion == "Delivery") ?
                                                                                                    (<b><span className="text-left" style={this.Estilos.NameStyle}>{(item.infoDelivery) ? (item.infoDelivery.name) : ('No definido')} </span></b>) :
                                                                                                    (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.nombreCliente} </span></b>)
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-sm-2">
                                                                                            <div className="card-text text-right" style={this.Estilos.TipoVenta}>
                                                                                                {(item.tiposVenta.descripcion == "Delivery") ? (<b>
                                                                                                    {(item.infoDelivery.tipoDelivery == "Rappi") ?
                                                                                                        (<span className="text-rigth"><i className="icon-Rappi fa-1x" style={this.Estilos.FontDel3}></i></span>) :
                                                                                                        (item.infoDelivery.tipoDelivery == "Uber Eats") ?
                                                                                                            (<span className="text-rigth"><span className="icon-UberEats" style={this.Estilos.FontDel2} >
                                                                                                                <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                                                                                                            </span></span>) :
                                                                                                            (item.infoDelivery.tipoDelivery == "Pedidos Ya") ?
                                                                                                                (<span className="text-rigth text-danger"><i className="icon-pedidosYa-1 fa-1x" style={this.Estilos.FontDel2}></i></span>) : (null)

                                                                                                    }

                                                                                                </b>) :
                                                                                                    (item.mesaID > 0) ? (<b><span className="text-right">{item.mesa.descipcion}</span></b>) : (null)


                                                                                                }


                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="card-text text-primary" style={this.Estilos.ValorVenta}><b>{GetPrecioFormateado(item.total)}</b></div>
                                                                                    <div className="card-text" style={this.Estilos.FechaVenta}>{this.GetFechaFormateada(item.fecha)} {this.GetHoraFormateada(item.hora)}    {this.GetNameUser(item.usuariosID)}</div>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                ) : (item.pagado === false) ? (
                                                                    (this.state.CombinarFunc) ?
                                                                        (<div className="card bg-light  border-success mb-3 ml-1 mr-1 col-sm-3" id={idCheck} style={this.Estilos.BorderSuccess}
                                                                            onClick={() => this.MarcarOrden(item.id, idCheck,item.tiposVentaID)}>
                                                                            <div className="card-body" style={this.Estilos.BodyCardStyle}>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <h6 className="card-title">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-8">
                                                                                                        <b>{item.tiposVenta.descripcion} #{item.numOrden} </b>
                                                                                                    </div>
                                                                                                    <div className="col-sm-4">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </h6>
                                                                                            <hr />
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="card-text" style={this.Estilos.TipoVenta}><b>Rut Cliente</b> </div>
                                                                                                </div>
                                                                                            <div className="col-sm-4" style={this.Estilos.DivNameStyle}>
                                                                                                {
                                                                                                    (item.tiposVenta.descripcion == "Delivery") ?
                                                                                                        (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.infoDelivery.name} </span></b>) :
                                                                                                        (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.nombreCliente} </span></b>)
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-sm-2">
                                                                                                <div className="card-text text-right" style={this.Estilos.TipoVenta}>
                                                                                                    {(item.tiposVenta.descripcion == "Delivery") ? (<b>
                                                                                                        {(item.infoDelivery.tipoDelivery == "Rappi") ?
                                                                                                            (<span className="text-rigth"><i className="icon-Rappi fa-1x" style={this.Estilos.FontDel3}></i></span>) :
                                                                                                            (item.infoDelivery.tipoDelivery == "Uber Eats") ?
                                                                                                                (<span className="text-rigth" style={this.Estilos.FontDel1}><span className="icon-UberEats">
                                                                                                                    <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                                                                                                                </span></span>) :(item.infoDelivery.tipoDelivery == "Pedidos Ya") ?
                                                                                                                    (<span className="text-rigth text-danger"><i className="icon-pedidosYa-1 fa-1x" style={this.Estilos.FontDel2}></i></span>) : (null)

                                                                                                        }

                                                                                                    </b>) :
                                                                                                        (item.mesaID > 0) ? (<b><span className="text-right">{item.mesa.descipcion}</span></b>):(null)


                                                                                                    }


                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card-text text-success" style={this.Estilos.ValorVenta}><b>{GetPrecioFormateado(item.total)}</b></div>
                                                                                        <div className="card-text" style={this.Estilos.FechaVenta}>{this.GetFechaFormateada(item.fecha)} {this.GetHoraFormateada(item.hora)}    {this.GetNameUser(item.usuariosID)}</div>

                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                            ):

                                                                        (<div className="card bg-light  border-success mb-3 ml-1 mr-1 col-sm-3" style={this.Estilos.BorderSuccess} onClick={() => this.ParaLlevar(item.id, item.tiposVentaID, item.pagado)}>
                                                                            <div className="card-body" style={this.Estilos.BodyCardStyle}>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <h6 className="card-title">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-8">
                                                                                                        <b>{item.tiposVenta.descripcion} #{item.numOrden} </b>
                                                                                                    </div>
                                                                                                    <div className="col-sm-4">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </h6>
                                                                                            <hr />
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="card-text" style={this.Estilos.TipoVenta}><b>Rut Cliente</b> </div>
                                                                                                </div>
                                                                                            <div className="col-sm-4" style={this.Estilos.DivNameStyle}>
                                                                                                {
                                                                                                    (item.tiposVenta.descripcion == "Delivery") ?
                                                                                                        (<b><span className="text-left" style={this.Estilos.NameStyle}>{(item.infoDelivery) ? (item.infoDelivery.name):('No definido')} </span></b>):
                                                                                                        (<b><span className="text-left" style={this.Estilos.NameStyle}>{item.nombreCliente} </span></b>)
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-sm-2">
                                                                                                <div className="card-text text-right" style={this.Estilos.TipoVenta}>
                                                                                                    {(item.tiposVenta.descripcion == "Delivery") ? (<b>
                                                                                                        {(item.infoDelivery.tipoDelivery == "Rappi") ?
                                                                                                            (<span className="text-rigth"><i className="icon-Rappi fa-1x" style={this.Estilos.FontDel3}></i></span>) :
                                                                                                            (item.infoDelivery.tipoDelivery == "Uber Eats") ?
                                                                                                                (<span className="text-rigth"><span className="icon-UberEats" style={this.Estilos.FontDel2} >
                                                                                                                    <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                                                                                                                </span></span>) :
                                                                                                                (item.infoDelivery.tipoDelivery == "Pedidos Ya") ?
                                                                                                                    (<span className="text-rigth text-danger"><i className="icon-pedidosYa-1 fa-1x" style={this.Estilos.FontDel2}></i></span>) : (null)

                                                                                                    }
     
                                                                                                    </b>) :
                                                                                                        (item.mesaID > 0) ? (<b><span className="text-right">{item.mesa.descipcion}</span></b>) : (null)


                                                                                                    }                                                                                                    
                                                                                                    

                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card-text text-success" style={this.Estilos.ValorVenta}><b>{GetPrecioFormateado(item.total)}</b></div>
                                                                                        <div className="card-text" style={this.Estilos.FechaVenta}>{this.GetFechaFormateada(item.fecha)} {this.GetHoraFormateada(item.hora)}    {this.GetNameUser(item.usuariosID)}</div>

                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                            )
                                           


                                                                ): (null)
                                                        
                                                                
                                                );
                                                })}

                                            </div>





                                        ) : (null)
                                    }

                                </div>
                                <div className="col-sm-1" style={this.Estilos.ColSideNavCombinar}>
                                    <div style={this.Estilos.SideNavCombinar}>

                                        <center>

                                            <div style={this.Estilos.divFilter}>
                                                <button data-toggle="tooltip" data-placement="right" title="Combinar" className="btn" style={this.Estilos.ButtonStyle} onClick={() => this.Combinar()}>
                                                    <i className="fa fa-clone fa-2x" style={this.Estilos.icon}></i></button><br />
                                                <span style={this.Estilos.FiltroSpan}>{(this.state.CombinarFunc) ? (this.state.combinarCount) : (null)}</span>
                                            </div>
                                        </center>

                                    </div>
                                </div>
                            </div>

                            <center>{
                                (this.state.cargando) ? (
                                    <div style={this.Estilos.FondoDefault}>
                                        <center>
                                            <div className="spinner-border text-dark" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <p> Cargando ventas...</p>
                                        </center>
                                    </div>
                                ) : (null)
                            }</center>
                           

                        </div>
                    </div>







                </div>
                <div className="col-sm-1">
                   
                    <div className="col-sm-1">
                        <div className="row">
                            {(this.state.filtroEstado) ? (<div className="col-sm-1">
                                <Popup
                                    contentStyle={this.Estilos.PopUpStyle}
                                    position="top center"
                                    modal
                                    onClose={() => this.MEstadoClose()}
                                    defaultOpen
                                    closeOnDocumentClick>
                                    <center>

                                        <table className="row" style={this.Estilos.FullAncho}>
                                            <tr style={this.Estilos.MarginTr}>
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.FiltrarEs(1)}>
                                                    <button className="btn btn-info" onClick={() => this.FiltrarEs(1)} style={this.Estilos.BtnStyles}>
                                                        <i className="fa fa-list fa-2x" aria-hidden="true"></i>
                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>Abiertas</span>

                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.FiltrarEs(3)}>
                                                    <button className="btn btn-info" onClick={() => this.FiltrarEs(3)} style={this.Estilos.BtnStyles}>
                                                        <span>
                                                            <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i>
                                                        </span>

                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>Pagadas</span>

                                                </td>
                                            </tr>
                                            <tr >
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.FiltrarEs(2)}>
                                                    <button className="btn btn-info" onClick={() => this.FiltrarEs(2)} style={this.Estilos.BtnStyles}>
                                                        <i className="fa fa-window-close-o fa-2x" aria-hidden="true" ></i>
                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>Anuladas</span>

                                                </td>
                                                <td rowspan="3" colSpan="2">
                                                    <div></div>
                                                </td>
                                            </tr>

                                        </table>
                                        <div>
                                            <button className="btn " onClick={() => this.FiltrarEs(4)} style={this.Estilos.botonVerTodos} >Todos Los Estados</button>
                                        </div>
                                    </center>
                                </Popup>
                            </div>) : (null)}
                            {(this.state.filtroTipoOrden) ? (<div className="col-sm-1">
                                <Popup
                                    contentStyle={this.Estilos.PopUpStyle}
                                    position="top center"
                                    modal
                                    onClose={() => this.MTOrdenClose()}
                                    defaultOpen
                                    closeOnDocumentClick>
                                    <center>

                                        <table className="row" style={this.Estilos.FullAncho}>
                                            <tr style={this.Estilos.MarginTr}>
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.FiltrarTipoOrden(1)}>
                                                    <button className="btn btn-info" onClick={() => this.FiltrarTipoOrden(1)} style={this.Estilos.BtnStyles}>
                                                        <i className="fa fa-cutlery fa-2x" aria-hidden="true"></i>
                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>Mesa</span>

                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.FiltrarTipoOrden(4)}>
                                                    <button className="btn btn-info" onClick={() => this.FiltrarTipoOrden(4)} style={this.Estilos.BtnStyles}>
                                                        <span>
                                                            <i className="fa fa-glass fa-2x" aria-hidden="true"></i>
                                                        </span>

                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>Bar</span>

                                                </td>

                                            </tr>
                                            <tr>
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.FiltrarTipoOrden(2)}>
                                                    <button className="btn btn-info" onClick={() => this.FiltrarTipoOrden(2)} style={this.Estilos.BtnStyles}>
                                                        <i className="fa fa-shopping-bag fa-2x" aria-hidden="true"></i>
                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>Para Llevar</span>

                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => this.FiltrarTipoOrden(3)}>
                                                    <button className="btn btn-info" onClick={() => this.FiltrarTipoOrden(3)} style={this.Estilos.BtnStyles}>
                                                        <i className="fa fa-paper-plane fa-2x" aria-hidden="true"></i>
                                                    </button>

                                                    <span style={this.Estilos.SpanStyle}>Delivery</span>

                                                </td>
                                            </tr>

                                        </table>
                                        <div>
                                            <button className="btn " onClick={() => this.FiltrarTipoOrden(100)} style={this.Estilos.botonVerTodos} >Todos Los Tipos de Orden</button>
                                        </div>
                                    </center>
                                </Popup>
                            </div>) : (null)}
                            {(this.state.filtroUsuario) ? (<div className="col-sm-1">
                                <Popup contentStyle={this.Estilos.PopUpStyle}
                                    position="top center"
                                    modal
                                    onClose={() => this.MUsuarioClose()}
                                    defaultOpen
                                    closeOnDocumentClick>

                                    <center>


                                        {this.state.usuarios.map((item, index) => {
                                            return (
                                                <div className="col-sm-4" style={this.Estilos.MarginDv}>
                                                    <div style={this.Estilos.STextStyle} onClick={() => this.FiltrarUsuario(item.id)}>
                                                        <button className="btn btn-info" onClick={() => this.FiltrarUsuario(item.id)} style={this.Estilos.BtnDStyles}>
                                                            <i className="fa fa-user-circle-o fa-2x" aria-hidden="true"></i>
                                                        </button>

                                                        <span style={this.Estilos.STextStyle}>{item.nombre} {item.apellido}</span>

                                                    </div>
                                                </div>
                                            );
                                        })

                                        }
                                        


                                        <div>
                                            <button className="btn " onClick={() => this.FiltrarUsuario(0)} style={this.Estilos.botonVerTodos}>Todos Los Usuarios</button>
                                        </div>
                                    </center>
                                </Popup>
                            </div>) : (null)}
                            {(this.state.filtroFecha) ? (<div className="col-sm-1">
                                <Popup contentStyle={this.Estilos.PopUpStyle}
                                    position="top center"
                                    modal
                                    onClose={() => this.MFechaClose()}
                                    defaultOpen
                                    closeOnDocumentClick>
                                    <center>
                                        <div>
                                            <input id="fechaHasta" type="date" style={this.Estilos.FechaInput}/>
                                        </div>
                                        <div style={this.Estilos.divFecha}>
                                            <table>
                                                <tr>
                                                    <td style={this.Estilos.tdFecha}>
                                                        <button className="btn " onClick={async () => { await this.TraerOrdenes(); this.FiltrarFecha(0) }} style={this.Estilos.botonVerTodas}>

                                                            <i className="fa fa-calendar fa-2x"> Hoy</i>
                                                        </button>
                                                    </td>
                                                    <td style={this.Estilos.tdFecha}>
                                                        <button className="btn " onClick={() => this.FiltrarFecha(1)} style={this.Estilos.botonVerTodasAzul}>

                                                            <i className="fa fa-check-square-o fa-2x"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </center>
                                </Popup>
                            </div>) : (null)}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
