import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { GetUrlApi } from './Globales/VariableGlobales';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import { Logout } from './Globales/Utilidades';

export class PopupPesoProducto extends Component {//CORRESPONDE A LA FUNCION TRASLADAR
    static displayName = PopupPesoProducto.name;
    Orden = {};
    constructor(props) {
        super(props);
        this.state = {
            peso:0
        }
    }

    componentDidMount() {
        this.TraerPeso();
    }

    async TraerPeso() {
        var respuesta = await fetch(GetUrlApi() + '/api/PesajeBalanzas', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            var ultValor = datos[datos.length - 1];
            this.setState({ peso: ultValor.pesoGramos });
        }
        else {
            alert('error al traer las mesas');
        }
        
    }
    
    Estilos = {
        AnchoTabla: {
            'width': '100%',

        },
        AltoCeldas: {
            'height': '30%',
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '60%' }
        },
        Card: {
            'width': '15rem'
        },
        Popup: {
            'width': '50%',
            'height': '37%',
            'border-radius': '8px'
        },
        TercioAncho: {
            'width': '33%',

        },
        BtnStyle:
        {
            'margin-top': '2%',
            'width': '30%',
            'margin-right': '1%'
        },
        ComboStyle:
        {
            'width': '65%',
            'text-overflow': 'ellipsis'
        },
        NombreBox:
        {
            'width': '65%'
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }
    

    render() {
        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.Popup}
                onClose={() => { this.props.DesactivarModales() }}
                closeOnDocumentClick>
                <h1>Peso en balanza {this.state.peso}</h1>

                <button onClick={() => { this.TraerPeso() }}> Actualizar </button>
            </Popup>
        );
    }
}
