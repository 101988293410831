import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Redirect } from 'react-router';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUrlApi, GetUrlApiWhatsapp, GetUrlApiWhatsappBlinamic, GetUrlApiWhatsappEcommerceBlinamic } from './Globales/VariableGlobales';
import { GetUserCookie } from './Globales/Utilidades';
import { Ordenes } from './Ordenes';
import swal from 'sweetalert';
import Swal2 from 'sweetalert2';
import { Card, Button, CardBody, CardHeader, Table, Form } from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import { GetUrlBlinamicApi } from './Globales/VariableGlobales';


export class Vuelto extends Component {
    static displayName = Vuelto.name;
    Propina = 0;
    Total = 0;
    NumeroOrden = 0;
    IsProduccion = false;//indica si debe imprimir y enviar al SII
    PausarTemporizador = false;
    Vuelto = 0;
    MostrarVuelto = true;
    MostrarPropina = false;
    UrlRedirijir = '/revisar';
    FacturaGenerada = false;
    BoletaGenerada = false;
    urlParams = null;
    TicketID = 0;
    NotaVentaID = 0;
    ReservaID = 0;
    IsBoletaSelected = false;
    IsFacturaSelected = false;
    ClientData = [];
    IsEcommerce = false;
    IsReserva = false;

    NroCpte = 0;
    CodigoCpte ='';
    PuntosAcumulados = 0;
    DataClientePuntos=[];
    offerProducts = []; 
    MediosPago = [];
    ProgramasPuntosDocumentos=[];
    DatosGiftcard = [];
    PuntosXCanje = 0;
    UsedNotaCreditoID = 0;
    ResultNC = '';
    carroProducto = [];
    DataClienteConvenio = [];
    IDserieGiftcard = 0 ;
    IsSelectedGiftcard = false;
    OrdenActBD = {};
    constructor(props) {
        super(props);
        this.state = {
            redirigir: false,
            BtnPagarClick: false,
            ingresarCliente: false,
            cuentaRegresiva: 45,
            urlDisponible: false,
            urlDocumentoSii: '',
            urlDocumentoBsale: '',
            cargando: false,
            cliente: {},
            isFacturacionActiva: false,
            orden: {},
            TipoClienteOpt: {},
            isDocsBloqueado: true,
            limpiarCliente: false,
            isEmpresa: false,
            CrearCliente: false,
            cargandoIsSelected: false,
            identificarClienteForzado: localStorage.getItem('identificarClienteForzado'),
            identificarClienteParaOfertas: localStorage.getItem('identificarClienteParaOfertas'),
            enviarComprobranteWhatsapp: localStorage.getItem('enviarComprobranteWhatsapp'),
            altaClienteForzado: localStorage.getItem('altaClienteForzado'),
            pideNroCelular: localStorage.getItem('pideNroCelular'),
            usaCustomCloudService: localStorage.getItem('usaCustomCloudService'),
            activarAltaCliente:false,
            Regiones:[],
            Comunas:[],
            Ambiente: localStorage.getItem('ambiente'),
            isShowErr: localStorage.getItem('showErrOpenGaveta')==='true'?true:false,
            reopen: false
        }
        this.state.reopen = props.reopen
        this.Propina = props.totalPropina;
        this.Vuelto = props.vuelto;
         
        this.ClientData = props.ClientData;
        this.MostrarPropina = props.HayPropina;
        this.TicketID = props.TicketID;
        this.NotaVentaID = props.NotaVentaID;
        this.IsEcommerce = this.props.IsEcommerce;
        this.ReservaID = props.ReservaID;
        if (this.ReservaID != 0)
        { this.IsReserva = true;}

        
        this.MostrarVuelto = props.HayVuelto;
        if (!this.MostrarVuelto) {
            this.Propina = props.vuelto;
            this.Vuelto = 0;
        }
        if (!this.MostrarPropina && !this.MostrarVuelto) {
            this.Propina = 0;
        }
        this.Total = props.total;
        this.NumeroOrden = props.IdOrden;
        this.CargarOrden(this.NumeroOrden);
        this.IsBoletaSelected = props.IsBoletaSelected;
        this.IsFacturaSelected = props.IsFacturaSelected;
        this.PuntosAcumulados = props.PuntosAcumulados;
        this.DataClientePuntos = props.DataClientePuntos;
        this.MediosPago = props.MediosdePago;
        this.ProgramasPuntosDocumentos = props.ProgramasPuntosDocumentos;
        this.DatosGiftcard = props.DatosGiftcard;
        this.PuntosXCanje = props.orden.puntosXCanje;
        this.UsedNotaCreditoID = props.UsedNotaCreditoID;
        this.carroProducto = props.carroProducto;
        this.DataClienteConvenio = props.DataClienteConvenio;
        this.IDserieGiftcard = props.IDserieGiftcard;
        this.IsSelectedGiftcard = props.IsSelectedGiftcard;
        this.OrdenActBD = props.OrdenActBD;
    }


    async   UpdateUsedNC  (notaID){
        try {
            var respuesta = await fetch(GetUrlApi() + '/api/NotasDeCredito/UpdateUsedNC?NotaCreditoID=' + notaID, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(
                (result) => {
                   this.ResultNC = result;
                });

        }
        catch(e){
            return 0;
        }
    }
    componentDidMount() {
        this.setState({ cliente: this.ClientData });
      
        if(!this.state.reopen){
            if (this.IsSelectedGiftcard){
                this.setState({ cargandoIsSelected:true});
                setTimeout(() => { this.ClickGiftcard() }, 2500);
            }
            else if (this.IsBoletaSelected) {

                this.setState({ cargandoIsSelected:true});
                this.setState({ cargando: true });
                
                setTimeout(() => { this.ClickBoleta() }, 2500);
            } else if (this.IsFacturaSelected) {
                this.ClickMostrarFactura();
            } else {
                this.BloquearDocumentos();
                setTimeout(() => { this.DesbloquearDocumentos() }, 3000);
            }
        }
        else{
            if (this.IsSelectedGiftcard){
                this.setState({ cargandoIsSelected:true});
                setTimeout(() => { this.ClickGiftcard() }, 2500);
            }
            else if (this.IsBoletaSelected) {
                this.setState({ cargandoIsSelected:true});
                setTimeout(() => { this.ClickBoleta() }, 2500);
            } else if (this.IsFacturaSelected) {
                this.ClickMostrarFactura();
                //setTimeout(() => { this.ClickMostrarFactura() }, 2500);
    
            } else {
    
                this.BloquearDocumentos();
                setTimeout(() => { this.DesbloquearDocumentos() }, 3000);
            }
    
        }
        


        //if (this.props.orden.mesaID > 0) {
        //    this.RedirigirToMesa(this.props.orden.mesaID);
        //}
        this.TraerTipoCliente();
        this.abrirGaveta();
 
      
        this.TraerRegiones();
        this.TraerComunas();
        //alert(this.TicketID);
        this.setState({ isFacturacionActiva: GetUserCookie().Sucursal.configPOS.isFacturacionActivada });
    }
    async TraerTipoCliente() {
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetTipoClientes', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ TipoClienteOpt: datos });
        }
    }
    async RedirigirToMesa(id) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdenByMesa?idMesa=' + id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            if (datos.length > 0) {
                this.UrlRedirijir = '/OrdenesMesa?id='+id;
            } else {
                this.UrlRedirijir = GetUserCookie().Sucursal.configPOS.urlRedireccionVentaM;  
            }

        }
        else {
           // alert('error al traer las mesas');
        }
    }

    async CargarOrden(ordenId) {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/' + ordenId, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            const resOrden = await respuesta.json();
           
            this.setState({ orden: resOrden });
            
        }
    }
    async ClickImprimir2() {
        const userData = GetUserCookie();
        const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
        // Enviar a imprimir voucher
        const idOrden = this.props.IdOrden;
        //falta implementar validar si hay que imprimir voucher
        await this.ClickImprimirHten(this.NroCpte);
        if(userData.Sucursal.configPOS.openUrlRespuesta && this.state.urlDocumentoBsale!="") {
            if (this.state.enviarComprobranteWhatsapp === 'true') {

                await Swal2.fire({
                    title: '<strong>Enviamos comprobante por Whatsapp ?</strong>',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Si! Whatsapp...',
                    denyButtonText: 'No, mejor impreso...',
                    imageUrl: 'https://i.pinimg.com/originals/34/48/1a/34481aabbfbe67156e366abecaf1fa2c.jpg',
                    imageWidth: 200,
                    imageHeight: 200,
                    imageAlt: 'Enviar a whatsapp !',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                }).then((result) => this.validar(result));
            } else {
                await this.imprimirCloud();
            }
        } else {
            if (this.NroCpte !== "undefined"){
                await Swal2.fire(
                    'Listo!',
                    'La venta se ha registrado. TRX: ' + this.NroCpte ,
                    'success'
                  )
            }
            else{
                await Swal2.fire(
                    'Listo!',
                    'La venta se ha registrado.' ,
                    'success'
                  )
            }
           
        }
        

    }

    async validar(result) {

        if (result.isConfirmed)
        {
            if (this.state.altaClienteForzado === 'true') {
                this.setState({ activarAltaCliente: true });
            }
            else
            {
                if (this.ClientData.id !== '')
                {

                    //var CajaID = data.CajaID//cajaid
                    //var GavetaID = UserProfile.getUser().idGaveta;//gavetaid
                    //var SucursalID = GetUserCookie().SucursalID;//sucursalid
                    //var UsuariosID = UserProfile.getUser().id; // usuarioid

                    //var respuesta = await fetch(GetUrlApi() + '/api/Mensajeria/GetEnviarMensajeCierreBoleta?ModuloPosID=6&SucursalPosID=' + SucursalID + '&UsuarioPosID=' + UsuariosID + '&GavetaID=' + GavetaID + '&CajaID=' + CajaID + '&ArqueoCaja=' + ArqueoCaja+'&OrdenId' + OrdenId, {
                    //    method: 'GET',
                    //    headers: {
                    //        'Content-Type': 'application/json',
                    //    },
                    //});
                    //if (!respuesta.ok) {
                    //    console.log(respuesta);
                    //}



                    //////codigo antoguo para envío de mensajes de boletas 
                    //var myBody = {
                    //    "modulo": 3,
                    //    "tipomensaje": 13,
                    //    "numeroTelefono": this.ClientData.telefono,
                    //    "urldescarga": this.state.urlDocumentoBsale,
                    //    "url": GetUrlApiWhatsappBlinamic(),
                        
                    //};
                    //const response = await fetch(GetUrlApiWhatsapp() +'enviarwhatsapp', {
                    //    method: 'post',
                    //    headers: {
                    //        'Content-Type': 'application/json',
                    //    },
                    //    body: JSON.stringify(myBody),

                    //////codigo Nuevo para envío de mensajes de boletas 

                    

                    var CajaID = this.state.orden.cajaID;//data.CajaID//cajaid
                    var GavetaID = this.state.orden.gavetaID; //UserProfile.getUser().idGaveta;//gavetaid
                    var SucursalID = this.state.orden.sucursalID;// this.props.SucursalID;//sucursalid
                    var UsuariosID = this.state.orden.usuariosID;;//this.props.UsuariosID; //this.UserProfile.getUser().id; // usuarioid
                    
                    const response =  await fetch(GetUrlApi() + '/api/Mensajeria/GetEnviarMensajeCierreBoleta?ModuloPosID=6&SucursalPosID=' + SucursalID + '&UsuarioPosID=' + UsuariosID + '&GavetaID=' + GavetaID + '&CajaID=' + CajaID + '&ArqueoCaja=0&OrdenId=' + this.props.IdOrden, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then((data) => { return data.json() }).catch((error) => {
                        Swal2.fire({
                            title: "Error al enviar whatsapp",
                            icon: "error",
                            text: "Vamos a imprimir en papel",
                            dangerMode: true,
                        });
                        this.imprimirCloud();
                        return;
                    });
                    
                    if (!response) {
                        await Swal2.fire({
                            title: "Error al enviar whatsapp",
                            icon: "error",
                            text: "Vamos a imprimir en papel",
                            dangerMode: true,
                        });
                        this.imprimirCloud();
                        return;
                    }
                }
                else
                {
                    await Swal2.fire({
                        title: "No tenemos un nro al cual enviar un whatsapp",
                        icon: "error",
                        text: "Se envía el comprobante por impresión tradicional. ",
                        dangerMode: true,
                    });
                    this.imprimirCloud();
                }
            }
        }
        else
        {
            await this.imprimirCloud();
        }
    }

    async imprimirCloud() {
        const idOrden = this.props.IdOrden;

        const userData = GetUserCookie();
        const empresa = userData.Sucursal.direccionDeli.split(".")[0];
        const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
        if (this.state.usaCustomCloudService === sucursal) {
            const printer = "printer_" + userData.caja.descripcion;
            const idImprimir = this.props.IdOrden;
            const param = "print://" + idImprimir;
            var myBody = {
                "company_id": empresa,
                "store_id": sucursal,
                "order_id": param,
                "printer_id": printer,
                "additional_data": "",
                "exec_path": "C:\\fbhposprint\\"
            };

            const response = await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then((data) => { return data.json() });
            console.log(response);
            if (!response) {
                await Swal2.fire({
                    title: "Error al querer imprimir",
                    icon: "error",
                    text: "Por favor contacte al administrador del sistema ",
                    dangerMode: true,
                });
                return;
            }
            sessionStorage.setItem(idImprimir, response.hten_unique_id);
            await Swal2.fire({
                title: "Orden " + idImprimir + " enviada a imprimir con código " + response.hten_unique_id,
                icon: "success",
                text: "Si es necesario consulte estado de impresión por nro de Orden.",
            });
        } else {
            this.ClickImprimirDirecto();
        }
    }

    async ClickImprimirHten(idOrden) {
        const usaCustomCloudService = localStorage.getItem('usaCustomCloudService')
        if (usaCustomCloudService === "true") {
            this.setState({ cargando: true });
            const userData = sessionStorage.getItem('userProfile');
            const empresa = userData.Sucursal.direccionDeli.split(".")[0];
            const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
            const printer = "printer_" + userData.caja.descripcion;
            var myBody = {
                "company_id": empresa,
                "store_id": sucursal,
                "order_id": "",
                "printer_id": printer,
                "additional_data": "print://voucherHten?" + idOrden,
                "exec_path": "C:\\fbhposprint\\"
            };

            await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(data => data.json()).then(data => {
                console.log(data);
                swal({
                    title: "Enviado a imprimir con código " + data.hten_unique_id,
                    icon: "success",
                    text: "Guarde ese código para consultar estado.",
                }).then(() => {
                    this.setState({ cargando: false });
                });
            }).catch(error => {
                swal({
                    title: "Error al querer imprimir",
                    icon: "error",
                    text: "Por favor contacte al administrador del sistema " + error,
                    dangerMode: true,
                }).then(() => {
                    this.setState({ cargando: false });
                });
            });
        } else {
            this.ClickImprimirDirectoHten(idOrden);
        }
    }

    async ClickImprimirDirectoHten(idOrden) {
        this.setState({ cargando: true });
        var myBody = {};
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = "print://voucherHten?" + idOrden;
        myBody.path = "C:\\fbhposprint\\";
        console.log("VOUCHER HTEN", myBody);
        

        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).catch(() => {
            this.setState({ cargando: false });
        });
        this.setState({ cargando: false });

    }

    async ClickImprimirDirecto() {
        const idImprimir = this.props.IdOrden;
        const param = "print://" + idImprimir;
        var myBody = {};
        //myBody.command = "C:\\Program Files\\Sublime Text 3\\sublime_text.exe";
        //myBody.parameter = "";
        //myBody.path = "C:\\Program Files\\Sublime Text 3\\";
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = param;
        myBody.path = "C:\\fbhposprint\\";
        console.log(myBody);
        //http://192.168.100.193:4741/fbhpos
        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then((data) => { return data });

        if (respuesta.ok) {
            var data = await respuesta.json();
            console.log(data);
        } else {
            console.error('Error en la solicitud HTTP');
        }
    }


    async abrirGaveta() {
        const usaCustomCloudService = localStorage.getItem('usaCustomCloudService')
        if (usaCustomCloudService === "true") {
            const userData = GetUserCookie();
            const empresa = userData.Sucursal.direccionDeli.split(".")[0];
            const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
            const printer = "printer_" + userData.caja.descripcion;
            const param = "print://abrirGaveta";
            var myBody = {
                "company_id": empresa,
                "store_id": sucursal,
                "order_id": param,
                "printer_id": printer,
                "additional_data": "",
                "exec_path": "C:\\fbhposprint\\"
            };

            try {
                const response = await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(myBody),
                }).then((data) => { return data.json() });
                if (!response) {
                    if (this.state.isShowErr)
                        await Swal2.fire({
                            title: "Error al querer abrir gaveta",
                            icon: "error",
                            text: "Por favor contacte al administrador del sistema ",
                            dangerMode: true,
                        });
                    else
                        console.error('Error al abrir la gaveta')
                    return;
                };
            }
            catch(e){
                console.error('Error al consumir servicio para abrir gaveta :' + e);
                return;
            }
        } else {
            const idImprimir = this.props.IdOrden;
            const param = "print://abrirGaveta";
            var myBody = {};
            myBody.command = "C:\\fbhposprint\\Printer.exe";
            myBody.parameter = param;
            myBody.path = "C:\\fbhposprint\\";
            console.log(myBody);
            await fetch('http://localhost:4741/fbhpos', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).catch(() => {
                if (this.state.isShowErr)
                {
                    Swal2.fire({
                        title: "Error al querer abrir gaveta",
                        icon: "error",
                        text: "Por favor contacte al administrador del sistema ",
                        dangerMode: true,
                    });
                }                    
                else
                    console.error('Error al abrir la gaveta');
            });
        }

    }

    ClickImprimir() {
        const idImprimir = this.props.IdOrden;
        const url = "print://" + idImprimir;

        window.open(url, '_blank');
    }

    async enviarWhatsappEcommerce() {
        if (this.IsEcommerce) {
            var myBody = {
                "NotaVentaId": this.NotaVentaID,
                "url": GetUrlApiWhatsappEcommerceBlinamic(),
                "isEcommerce": this.IsEcommerce,
                "isReserva": this.isReserva
            };
            console.log("Envio mensaje whatsapp ", myBody);

            const response = await fetch(GetUrlApiWhatsapp() + 'EnviarWhatsappEcommerce', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then((data) => { return data.json() });
            console.log(response);
            if (!response) {
                await Swal2.fire({
                    title: "Error al enviar whatsapp",
                    icon: "error",
                    dangerMode: true,
                });
                return;
            }
        }
        else if (this.IsReserva)
        {
            var myBody = {
                "NotaVentaId": this.ReservaID,
                "url": GetUrlApiWhatsappEcommerceBlinamic(),
                "isEcommerce": this.IsEcommerce,
                "isReserva": this.IsReserva
            };
            console.log("Envio mensaje whatsapp ", myBody);

            const response = await fetch(GetUrlApiWhatsapp() + 'EnviarWhatsappEcommerce', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then((data) => { return data.json() });
            console.log(response);
            if (!response) {
                await Swal2.fire({
                    title: "Error al enviar whatsapp",
                    icon: "error",
                    dangerMode: true,
                });
                return;
            }
        }


    }
    RedondearValor(monto) {
        if (this.IsEfectivo) {
            if (monto > 0) {
                const resto = monto % 10;
                if (resto > 0 && resto <= 5) {
                    return monto - resto;
                }
                else if (resto > 5 && resto < 10) {
                    return monto + (10 - resto);
                }
            }
            else {
                return monto;
            }
        }
        return monto
    }

    async ClickBoleta() {
        this.BoletaGenerada = true;
        this.BloquearDocumentos();
        let medio = {};
        medio.IdOrden = this.NumeroOrden;
        if (this.TicketID!=0) {
            medio.TicketID = this.TicketID;
            this.CodigoCpte = 'ORDENSERVICIO';
        } else {
            medio.TicketID = 0;
        }

        if (this.NotaVentaID != 0) {
            medio.NotaVentaID = this.NotaVentaID;
            this.CodigoCpte = 'ECOMMERCE';
        } else {
            medio.NotaVentaID = 0;
        }
        if (this.ReservaID != 0) {
            medio.ReservaID = this.ReservaID;
            this.CodigoCpte='RESERVA';
        } else {
            this.CodigoCpte='VENTANORMAL';
            medio.ReservaID = 0;
        }
        var puntosXCanje = 0;
        var isBoletaPP = false;  
        var existsGiftcard = false;
        var montoGiftcard = 0;
        //Validar que la boleta este habilitada en el programa para canjear o acumular puntos
        try{
            this.ProgramasPuntosDocumentos.forEach(function (currentValue) {
                if (currentValue.codigo == 1) //boleta
                {
                    isBoletaPP = true; 
                } });
        }catch(e){
            console.log(e);
        }
        try{
            this.MediosPago.forEach(function (currentValue) {
              /* se modifica canje puntos por descuentos - ya no es considerado un medio de pago  
                if (currentValue.medio == 'Canje Puntos') //Canje Puntos 
                {
                    puntosXCanje += currentValue.PuntosCanje; 
                }*/
                if (currentValue.medio == 'Giftcard'){
                    montoGiftcard += currentValue.total 
                    existsGiftcard = true;
                }
            
            });
        }catch(e){
            console.log(e);
        }
        // canje+
        
        puntosXCanje = this.PuntosXCanje;
        if (isBoletaPP) {
           
            try{
                if(typeof this.DataClientePuntos !== undefined)
                {
                    medio.RUT = this.DataClientePuntos.rut;
                    medio.PuntosCanje= puntosXCanje;
                    medio.MontoCanje = this.RedondearValor(puntosXCanje * this.DataClientePuntos.factorCanje);
                    medio.PuntosAcumula= this.PuntosAcumulados;
                    medio.SaldoPuntos = this.DataClientePuntos.saldoPuntos - puntosXCanje + this.PuntosAcumulados;
                }
                else{
                    medio.PuntosCanje=0;
                    medio.MontoCanje =0;
                    medio.PuntosAcumula=0;
                    medio.SaldoPuntos=0;
                }
            }
            catch(e) {
                medio.PuntosCanje=0;
                medio.MontoCanje =0;
                medio.PuntosAcumula=0;
                medio.SaldoPuntos=0;
            }
        }
        else{
            medio.PuntosCanje=0;
            medio.MontoCanje =0;
            medio.PuntosAcumula=0;
            medio.SaldoPuntos=0;
        }
        
        medio.Ambiente  =  this.state.Ambiente;
        medio.offerProducts = JSON.stringify(this.props.offerProducts)
        medio.ordenAct = this.OrdenActBD;
        medio.convenioID =  this.DataClienteConvenio.idConvenio;
         
        console.log("entró a proceso de venta:" + Date(Date.now()));

        console.log(JSON.stringify(medio));
        const respuesta =await fetch(GetUrlApi() + '/api/Boleta', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(medio),
        });
        if (respuesta.ok) {

            console.log("salió de la venta:" + Date(Date.now()));

            await this.setState({ cargando: false });
            const jsonRespuesta = await respuesta.json();
            this.setState({ urlDocumentoBsale: jsonRespuesta.urlDocumento });
            this.enviarWhatsappEcommerce();
            if (jsonRespuesta.status) {
                if (!jsonRespuesta.terminoConErrores) {
                    this.NroCpte = jsonRespuesta.nroCpte;
                    /*if (this.state.Ambiente ==='MAISA'){
                        if (this.UsedNotaCreditoID!=0){ //marca nc como utilizada
                            await this.UpdateUsedNC(this.UsedNotaCreditoID);
                        }
                    }*/

                    /*Seccion responsable de cargar la venta realizada con convenio*/
                    if (this.DataClienteConvenio.idConvenio != 0) {

                        var existsConvenioDescuentoPlanilla = false;

                        var valor = this.ClientData.rut.replaceAll('-', '');
                        const cuerpo = valor.slice(0, -1);

                        try {
                            this.MediosPago.forEach(function (currentValue) {
                                if (currentValue.medio == 'Convenio') {
                                    existsConvenioDescuentoPlanilla = true;
                                }

                            });
                        } catch (e) {
                            console.log(e);
                        }
                        let productosConvenio = [];
                        let objetoProductosConvenioAux = {};
                        this.carroProducto.forEach(function (valorActual) {

                            objetoProductosConvenioAux = {};
                            objetoProductosConvenioAux.ProductoID = valorActual.id;
                            objetoProductosConvenioAux.UsaConvenio = valorActual.isConvenioUsado;
                            objetoProductosConvenioAux.valorFinal = valorActual.precpred;
                            productosConvenio.push(objetoProductosConvenioAux);
                        });


                        let medioVentaConvenioAux = {};
                        medioVentaConvenioAux.rut = cuerpo;
                        medioVentaConvenioAux.ConvenioID = this.DataClienteConvenio.idConvenio;
                        medioVentaConvenioAux.OrdenID = this.NumeroOrden;
                        medioVentaConvenioAux.EsMedioPagoConvenio = existsConvenioDescuentoPlanilla;
                        medioVentaConvenioAux.unProductosVentaList = productosConvenio; /*constrir lista con los siguientes datos */

                        let bodyAux = JSON.stringify(medioVentaConvenioAux);

                        let token = sessionStorage.getItem('jwtToken')

                        const respuesta2 = await fetch(GetUrlBlinamicApi() + '/api/Convenio/SetVentaConvenio', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": "Bearer " + token + ""
                            }
                            , body: JSON.stringify(medioVentaConvenioAux)
                        }).then(res => res.json()).then(
                            (result) => {
                                if (result.data.length > 0) {
                                    
                                }
                            });
                    }
                    else {}
                    /*FIN Seccion responsable de cargar la venta realizada con convenio*/

                    let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
                    if (SwProgramaOfertas === 'S'){
                        if (this.NroCpte > 0 ){
                                if (isBoletaPP) {
                                    if ( this.DataClienteConvenio === undefined || this.DataClienteConvenio.idConvenio===0 )
                                    {
                                        if (this.PuntosAcumulados>0){
                                            this.GuardarAcumulacionPuntos();
                                        }
                                    }
                                  
                                    //Verifica Canje
                                    if (puntosXCanje> 0){
                                           await this.GuardarCanjePuntos(puntosXCanje);
                                    }
                            } 
                            this.ConfirmarOfertasAplicadas(); 
                            if (this.DatosGiftcard.length>0 && existsGiftcard){
                                //confirma uso de giftcard
                               await this.NewGiftcardTrx(montoGiftcard,this.NroCpte);      
                            }
                             
                        }
                    }
                     
                    await this.ClickImprimir2();
                    this.BoletaGenerada = true;
                    
                    if (GetUserCookie().Sucursal.configPOS.openUrlRespuesta) {
                        window.open(jsonRespuesta.urlDocumento, '_blank');
                    }
                    this.setState({/* redirigir: true,*/ urlDisponible: true, urlDocumentoSii: jsonRespuesta.url })
                    setTimeout(() => {
                        this.OnClose();
                    }, 2000);
                }
                else {

                    if (respuesta.statusGeneroFactura && !respuesta.statusInsertaBlinamic)
                    {
                        await this.ClickImprimir2();
                        this.BoletaGenerada = true;
                        if (GetUserCookie().Sucursal.configPOS.openUrlRespuesta) {
                            window.open(jsonRespuesta.urlDocumento, '_blank');
                        }
                        swal("Problemas al insertar el movimiento en Blinamic. Consulte con el Soporte");
                        window.location.reload();
                    } else if (!respuesta.statusGeneroFactura && !respuesta.statusInsertaBlinamic) {
                        swal("Problemas al generar el documento. Por favor intente nuevamente.");
                        window.location.reload();

                    }

                }
                
            }
            else {
                await Swal2.fire({
                    title: "Error al generar la venta",
                    icon: "error",
                    html:
                        'Orden: ' + this.state.orden.id +'!<br>' +
                        '<p><div class= "card card-body" >' +
                        jsonRespuesta.message +
                        '</div></p>',
                    footer: 'Contacte con soporte técnico',
                    dangerMode: true,
                }).then((result) => {
                    window.location.reload()
                });
                // swal("Problemas al generar el documento. Intentelo Nuevamente");
                // window.location.reload();
            }
        }
        else {
            console.log('la llamada a la api de facturacion se cayó');
            this.DesbloquearDocumentos();
            this.BoletaGenerada = false;
        }
    }


    async ClickGiftcard() {
        
        this.BoletaGenerada = true;
        this.BloquearDocumentos();
        await this.setState({ cargando: true });
        let medio = {};
        medio.IdOrden = this.NumeroOrden;
        if (this.TicketID!=0) {
            medio.TicketID = this.TicketID;
            this.CodigoCpte = 'ORDENSERVICIO';
        } else {
            medio.TicketID = 0;
        }

        if (this.NotaVentaID != 0) {
            medio.NotaVentaID = this.NotaVentaID;
            this.CodigoCpte = 'ECOMMERCE';
        } else {
            medio.NotaVentaID = 0;
        }
        if (this.ReservaID != 0) {
            medio.ReservaID = this.ReservaID;
            this.CodigoCpte='RESERVA';
        } else {
            this.CodigoCpte='VENTANORMAL';
            medio.ReservaID = 0;
        }
        var puntosXCanje = 0;
        var isBoletaPP = false;  
        var existsGiftcard = false;
        var montoGiftcard = 0;
        //Validar que la boleta este habilitada en el programa para canjear o acumular puntos
        try{
            this.ProgramasPuntosDocumentos.forEach(function (currentValue) {
                if (currentValue.codigo == 1) //boleta
                {
                    isBoletaPP = true; 
                } });
        }catch(e){
            console.log(e);
        }
        try{
            this.MediosPago.forEach(function (currentValue) {
              /* se modifica canje puntos por descuentos - ya no es considerado un medio de pago  
                if (currentValue.medio == 'Canje Puntos') //Canje Puntos 
                {
                    puntosXCanje += currentValue.PuntosCanje; 
                }*/
                if (currentValue.medio == 'Giftcard'){
                    montoGiftcard += currentValue.total 
                    existsGiftcard = true;
                }
            
            });
        }catch(e){
            console.log(e);
        }
        // canje+
        
        puntosXCanje = this.PuntosXCanje;
        if (isBoletaPP) {
           
            try{
                if(typeof this.DataClientePuntos !== undefined)
                {
                    medio.RUT = this.DataClientePuntos.rut;
                    medio.PuntosCanje= puntosXCanje;
                    medio.MontoCanje = this.RedondearValor(puntosXCanje * this.DataClientePuntos.factorCanje);
                    medio.PuntosAcumula= this.PuntosAcumulados;
                    medio.SaldoPuntos = this.DataClientePuntos.saldoPuntos - puntosXCanje + this.PuntosAcumulados;
                }
                else{
                    medio.PuntosCanje=0;
                    medio.MontoCanje =0;
                    medio.PuntosAcumula=0;
                    medio.SaldoPuntos=0;
                }
            }
            catch(e) {
                medio.PuntosCanje=0;
                medio.MontoCanje =0;
                medio.PuntosAcumula=0;
                medio.SaldoPuntos=0;
            }
        }
        else{
            medio.PuntosCanje=0;
            medio.MontoCanje =0;
            medio.PuntosAcumula=0;
            medio.SaldoPuntos=0;
        }
       
        medio.Ambiente  =  this.state.Ambiente;
        medio.offerProducts = JSON.stringify(this.props.offerProducts);
        medio.ordenAct = this.OrdenActBD;
        medio.convenioID =  this.DataClienteConvenio.idConvenio;
        console.log(JSON.stringify(medio));
        const respuesta =await fetch(GetUrlApi() + '/api/Giftcard', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(medio),
        });
        if (respuesta.ok) {
            await this.setState({ cargando: false });
            const jsonRespuesta = await respuesta.json();
            this.setState({ urlDocumentoBsale: jsonRespuesta.urlDocumento });
            this.enviarWhatsappEcommerce();
            if (jsonRespuesta.status) {
                if (!jsonRespuesta.terminoConErrores) {
                    this.NroCpte = jsonRespuesta.nroCpte;
                    /*if (this.state.Ambiente ==='MAISA'){
                        if (this.UsedNotaCreditoID!=0){ //marca nc como utilizada
                            await this.UpdateUsedNC(this.UsedNotaCreditoID);
                        }
                    }*/
                    
                    let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
                    if (SwProgramaOfertas === 'S'){
                        if (this.NroCpte > 0 ){
                                if (isBoletaPP) {
                                    if (this.PuntosAcumulados>0){
                                       await this.GuardarAcumulacionPuntos();
                                    }
                                    //Verifica Canje
                                    if (puntosXCanje> 0){
                                           await this.GuardarCanjePuntos(puntosXCanje);
                                    }
                            } 
                            this.ConfirmarOfertasAplicadas(); 
                            if (this.DatosGiftcard.length>0 && existsGiftcard){
                                //confirma uso de giftcard
                               await this.NewGiftcardTrx(montoGiftcard,this.NroCpte);      
                            }
                            if (this.IsSelectedGiftcard)
                            {
                                //activa Giftcard
                                
                                await this.ActivateGiftcard(this.IDserieGiftcard,this.Total);
                            }
                        }
                    }
                     
                    await this.ClickImprimir2();
                    this.BoletaGenerada = true;
                    
                    if (GetUserCookie().Sucursal.configPOS.openUrlRespuesta) {
                        window.open(jsonRespuesta.urlDocumento, '_blank');
                    }
                    this.setState({/* redirigir: true,*/ urlDisponible: true, urlDocumentoSii: jsonRespuesta.url })
                    setTimeout(() => {
                        this.OnClose();
                    }, 2000);
                }
                else {

                    if (respuesta.statusGeneroFactura && !respuesta.statusInsertaBlinamic)
                    {
                        await this.ClickImprimir2();
                        this.BoletaGenerada = true;
                        if (GetUserCookie().Sucursal.configPOS.openUrlRespuesta) {
                            window.open(jsonRespuesta.urlDocumento, '_blank');
                        }
                        
                        await Swal2.fire({
                            title: "Atencion !!",
                            icon: "error",
                            text: "Problemas al insertar el movimiento en Blinamic. Consulte con el Soporte",
                            dangerMode: true,
                        });
                        window.location.reload();
                    } else if (!respuesta.statusGeneroFactura && !respuesta.statusInsertaBlinamic) {
                        
                        await Swal2.fire({
                            title: "Atencion !!",
                            icon: "error",
                            text: "Problemas al generar el documento. Por favor intente nuevamente.",
                            dangerMode: true,
                        });
                        window.location.reload();

                    }

                }
                
            }
            else {
                await Swal2.fire({
                    title: "Error al generar la venta",
                    icon: "error",
                    html:
                        'Orden: ' + this.state.orden.id +'!<br>' +
                        '<p><div class= "card card-body" >' +
                        jsonRespuesta.message +
                        '</div></p>',
                    footer: 'Contacte con soporte técnico',
                    dangerMode: true,
                }).then((result) => {
                    window.location.reload()
                });
                // swal("Problemas al generar el documento. Intentelo Nuevamente");
                // window.location.reload();
            }
        }
        else {
            console.log('la llamada a la api de facturacion se cayó');
            this.DesbloquearDocumentos();
            this.BoletaGenerada = false;
        }
    }

    async  ClickPagarDos()
    {
        await this.ClickImprimir2();
        this.setState({ BtnPagarClick: true });
    }
    async  ClickListo() {
        this.BloquearDocumentos();
        const isFacturaActivada = GetUserCookie().Sucursal.configPOS.isFacturacionActivada;
        if (!this.FacturaGenerada && !this.BoletaGenerada && isFacturaActivada) {
            if (this.BoletaGenerada || this.FacturaGenerada) {
                this.OnClose();
            }
            else {
                this.ClickBoleta();
            }
            
        }
        this.OnClose();
    }

    async GuardarAcumulacionPuntos (){
        try{
            var arrMedios = [];

            this.MediosPago.forEach(function (currentValue) {
                    arrMedios.push(currentValue.idMedio.toString());
                  });


            var myBody = {
                "rutCliente": this.DataClientePuntos.rut,
                "puntos": this.PuntosAcumulados,
                "tipoMovimiento" : 'A',
                "mediosPago" : arrMedios,// 1, //todos 
                "codOrigen" : 0,
                "nroCpte" : this.NroCpte,
                "tipoTransaccion" : this.CodigoCpte,
                "montoVenta" : this.Total,
                "idPrograma" : this.DataClientePuntos.idProgramaPuntos
    
            };
            
            console.log("Datos acumulacion puntos ", myBody);
            //GeneraMovimientoPuntos2 valida los medios de pago para la acumulacion
            let token =sessionStorage.getItem('jwtToken');
            const response = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/GeneraMovimientoPuntosMP', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                },
                body: JSON.stringify(myBody),
            }).then((data) => { return data.json() });
            console.log(response);
            if (response.mensajeError!='OK') {
                console.log("Error al guardar puntos")
                return;
            }
        }catch(e){
            console.log("Error al guardar puntos: " + e);
        }
        
    }


    async GuardarCanjePuntos (puntos){
        try{
            
            var myBody = {
                "rutCliente": this.DataClientePuntos.rut,
                "puntos": puntos,
                "tipoMovimiento" : 'C',
                "codMedioPago" : 1, //todos 
                "codOrigen" : 0,
                "nroCpte" : this.NroCpte,
                "tipoTransaccion" : this.CodigoCpte,
                "montoVenta" : this.Total,
                "mediosPago" : [],
                "idPrograma" : this.DataClientePuntos.idProgramaPuntos
            };
            let token =sessionStorage.getItem('jwtToken');
            const response = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/GeneraMovimientoPuntos', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                },
                body: JSON.stringify(myBody),
            }).then((data) => { return data.json() });
            console.log(response);
            if (response.mensajeError!='OK') {
                console.log("Error al guardar puntos")
                return;
            }
        }catch(e){
            console.log("Error al guardar puntos: " + e);
        }
        
    }
    async ConfirmarOfertasAplicadas (){
        const venta = {
            nroCpte: this.NroCpte,
            products: await this.getVentas()
        }
        try{
            let token =sessionStorage.getItem('jwtToken')
            const response = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/confirmarOferta?venta=' + encodeURIComponent(JSON.stringify(venta)), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (!result.Status) {
                        console.log('No se ha guardado ningun tipo de oferta: ' + result.Message)
                    }
                    return
                }
            )
        }catch(e){
            console.error("Error al guardar ofertas: " + e)
        }
        
    }
    async  NewGiftcardTrx (monto,nrocpte){
        let id = this.DatosGiftcard[0].id;
        let serie = this.DatosGiftcard[0].serie;
        let referenceNumber =nrocpte;
        let referenceType = 0;
        let userId = GetUserCookie().username;
        try{
            let token =sessionStorage.getItem('jwtToken')
            const response = await fetch(GetUrlBlinamicApi() + '/api/Giftcard/newGiftCardTransaction?GiftCardID='+ id +'&GiftCardSerie='+ 
                        serie +'&TransactionAmount=' + monto + '&TransReferenceNumber=' + referenceNumber +'&TransReferenceType=' + referenceType +
                        '&userId=' + userId, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (!result.status) {
                        console.error('Error al guardar transaccion giftcard: ' + result.mensaje)
                    }
                    return
                }
            )
        }catch(e){
            console.error("Error al guardar giftcard: " + e)
        }
        
    }

    async  ActivateGiftcard (serie, monto){
      
        let userId = GetUserCookie().username;
        let sucursal = GetUserCookie().SucursalID;
        try{
            let token =sessionStorage.getItem('jwtToken')
            const response = await fetch(GetUrlBlinamicApi() + '/api/Giftcard/ActivateGiftcardPOS?&GiftCardSerie='+ 
                        serie +'&Amount=' + monto + '&userId=' + userId + '&SucursalID=' + sucursal, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (!result.status) {
                        console.error('Error al guardar transaccion giftcard: ' + result.mensaje)
                    }
                    return
                }
            )
        }catch(e){
            console.error("Error al guardar giftcard: " + e)
        }
        
    }
    async getVentas (){
        const offerProductList = this.props.offerProducts
        try {
            let token =sessionStorage.getItem('jwtToken')
            const nroCpte = this.NroCpte
            const ventas = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/getVentas?nroCpte=' + nroCpte, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                },
            }).then(res => res.json()).then(
                (result) => {
                    if (!result.status) {
                        console.error('Error al guardar oferta: ' + result.message)
                        return
                    }
                    else 
                        return result.result.products
                }
            )
            
            ventas.forEach(function(product) {
                const carroInOffer = product.inOffer?product.inOffer:false
                offerProductList.forEach(function(offerProduct) {
                    const isValid = offerProduct.isValid?offerProduct.isValid:true
                    if (product.code === offerProduct.productCode && isValid && !carroInOffer) {
                        offerProduct.ventaId = product.ventaId
                        offerProduct.offerDisc = product.valorDescuento // product.valorVenta - offerProduct.valueOffer
                        offerProduct.isValid=false
                        product.inOffer = true
                    }
                })
            })
            return this.props.offerProducts

        }catch(e){
            console.error("Error al guardar ofertas: " + e);
            return { ventas: [] };
        }
        
    }

    async  TraerComunas() {
         
        var region=0;
        try{
            region = document.getElementById('ddlCiudades').value;
        }catch(e){
            region=1;
        }

        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetComunas?RegionID=' + region + '&ProvinciaID=0', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var comuns = await respuesta.json();
            this.setState({ Comunas: comuns });

        }
    }

    async  TraerRegiones() {
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetRegiones', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var regions = await respuesta.json();
            console.log(regions);
            this.setState({ Regiones: regions });

        }
    }
    async AgregarCliente() {

        let nombreCl = document.getElementById('txtEmpresa').value;
        let apellidoCl = "";
        let rutCl = document.getElementById('txtRut').value;
        let telefonoCl = "";
        let emailCl = "";
       // let comunaCl = document.getElementById('txtComuna').value;
        let ciudadCl = document.getElementById('ddlCiudades').value;
        let direccionCl = document.getElementById('txtDireccion').value;

        let comuna = document.getElementById('ddlComuna').value;
        let t = document.getElementById('ddlComuna');
        let descComuna = t.options[t.selectedIndex].text
        let tipocli = document.getElementById('ddlTipoCliente').value;
        let sucID = GetUserCookie().SucursalID;
        let user = GetUserCookie().id;
        if (rutCl != '' && nombreCl != '') {

            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/AgregarClienteF?Nombre=' + nombreCl + '&Direccion=' + direccionCl + '&Rut=' + rutCl + '&Comuna=' + descComuna + '&Ciudad=' + ciudadCl + '&idComuna=' + comuna +'&TipoCliente=' + tipocli +'&SucID=' + sucID +'&Usuario='+ user, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        }
        return true;
    }


    async ClickFactura() {
        this.FacturaGenerada = true;
        this.BloquearDocumentos();
        await this.setState({ cargando: true, cargandoIsSelected:true });
        let resAddCliente = false;
        /*if (!this.state.isEmpresa) {
            if (this.state.CrearCliente) {
                resAddCliente = this.AgregarCliente();
            }
        }*/
        var medio = {};
        medio.IdOrden = this.NumeroOrden;
        medio.Ciudad = document.getElementById('ddlCiudades').value;
        medio.Rut = document.getElementById('txtRut').value;
        medio.Empresa = document.getElementById('txtEmpresa').value;

        let comunaID = document.getElementById("ddlComuna").value;
        if (comunaID != ""){ 
            let t = document.getElementById("ddlComuna");
            let descComuna = t.options[t.selectedIndex].text
    
            medio.Comuna = descComuna;// document.getElementById('txtComuna').value;
        }
        
        medio.Giro = document.getElementById('txtGiro').value;
        medio.Direccion = document.getElementById('txtDireccion').value;
        medio.isEmpresa = this.state.isEmpresa;
        
        if (!this.checkRut(medio.Rut)) {
            swal("El Campo Rut debe ser correcto")
            await this.setState({ cargando: false, cargandoIsSelected:false })
            return;
        }
        if (medio.Rut=="") {
            swal("El Campo Rut debe estar completo");
            await this.setState({ cargando: false, cargandoIsSelected:false })
            return;
        }
        if (medio.Empresa == "") {
            swal("El Campo Razón Social debe estar completo");
            await this.setState({ cargando: false, cargandoIsSelected:false })
            return;

        }
        if (medio.Giro == "") {
            swal("El Campo Giro debe estar completo");
            await this.setState({ cargando: false, cargandoIsSelected:false })
            return;

        }
        if (medio.Ciudad == "") {
            swal("El Campo Región debe estar completo");
            await this.setState({ cargando: false, cargandoIsSelected:false })
            return;

        }
        if (comunaID == "") {
            swal("El Campo Comuna debe estar completo");
            await this.setState({ cargando: false, cargandoIsSelected:false })
            return;

        }        
        if (medio.Direccion == "") {
            swal("El Campo Dirección debe estar completo");
            await this.setState({ cargando: false, cargandoIsSelected:false })
            return;
        }

        //new crear cliente 
        //if (this.state.CrearCliente) {
            //agrega o actualiza datos cliente 
            try{
                resAddCliente = this.AgregarCliente();
            }catch(e){}
     
        //}

        if (this.TicketID!=0) {
            medio.TicketID = this.TicketID;
            this.CodigoCpte = 'ORDENSERVICIO';
        } else {
            medio.TicketID = 0;
        }

        if (this.NotaVentaID != 0) {
            medio.NotaVentaID = this.NotaVentaID;
            this.CodigoCpte = 'ECOMMERCE';
        } else {
            medio.NotaVentaID = 0;
        }
        if (this.ReservaID != 0) {
            medio.ReservaID = this.ReservaID;
            this.CodigoCpte='RESERVA';
        } else {
            this.CodigoCpte='VENTANORMAL';
            medio.ReservaID = 0;
        }
        var puntosXCanje = 0;
        var isFacturaPP = false;  
        var existsGiftcard = false;
        var montoGiftcard = 0;
        //Validar que la factura este habilitada en el programa para canjear o acumular puntos
        try{
            this.ProgramasPuntosDocumentos.forEach(function (currentValue) {
                if (currentValue.codigo == 2) //factura
                {
                    isFacturaPP = true; 
                } });
        }catch(e){
            console.log(e);
        }
        
        try{
            this.MediosPago.forEach(function (currentValue) {
               /* if (currentValue.medio == 'Canje Puntos') //Canje Puntos 
                {
                    puntosXCanje += currentValue.PuntosCanje; 
                }*/ 
                if (currentValue.medio == 'Giftcard'){
                    montoGiftcard += currentValue.total 
                    existsGiftcard = true;
                }
            });
        }catch(e){
            console.log(e);
        }

        puntosXCanje = this.PuntosXCanje;
        if (isFacturaPP) {
           
            try{
                if(typeof this.DataClientePuntos !== undefined)
                {
                    medio.PuntosCanje= puntosXCanje;
                    medio.MontoCanje = this.RedondearValor(puntosXCanje * this.DataClientePuntos.factorCanje);
                    medio.PuntosAcumula= this.PuntosAcumulados;
                    medio.SaldoPuntos = this.DataClientePuntos.saldoPuntos - puntosXCanje + this.PuntosAcumulados;
                }
                else{
                    medio.PuntosCanje=0;
                    medio.MontoCanje =0;
                    medio.PuntosAcumula=0;
                    medio.SaldoPuntos=0;
                }
            }
            catch(e) {
                medio.PuntosCanje=0;
                medio.MontoCanje =0;
                medio.PuntosAcumula=0;
                medio.SaldoPuntos=0;
            }
        }
        else{
            medio.PuntosCanje=0;
            medio.MontoCanje =0;
            medio.PuntosAcumula=0;
            medio.SaldoPuntos=0;
        }
       
        medio.Ambiente  =  this.state.Ambiente;
        medio.offerProducts = JSON.stringify(this.props.offerProducts);
        medio.ordenAct = this.OrdenActBD;
        medio.convenioID =  this.DataClienteConvenio.idConvenio;

        console.log(JSON.stringify(medio));
        var respuesta = await fetch(GetUrlApi() + '/api/Factura', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(medio),
        });
        if (respuesta.ok) {
            await this.setState({ cargando: false });
            const jsonRespuesta = await respuesta.json();
            this.enviarWhatsappEcommerce();

            if (jsonRespuesta.status) {
                if (!jsonRespuesta.terminoConErrores)
                {
                    this.NroCpte = jsonRespuesta.nroCpte;
                    /*if (this.state.Ambiente ==='MAISA'){
                        if (this.UsedNotaCreditoID!=0){ //marca nc como utilizada
                            await this.UpdateUsedNC(this.UsedNotaCreditoID);
                        }
                    }*/
                    
                    let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
                    if (SwProgramaOfertas === 'S'){
                        if (this.NroCpte > 0 ){
                                if (isFacturaPP) {
                                    if (this.DataClienteConvenio === undefined || this.DataClienteConvenio.idConvenio===0 )
                                    {
                                        if (this.PuntosAcumulados>0){
                                            this.GuardarAcumulacionPuntos();
                                        }
                                    }
                                       
                                        //Verifica Canje
                                        if (puntosXCanje> 0){
                                                this.GuardarCanjePuntos(puntosXCanje);
                                        }
                                }  
                                this.ConfirmarOfertasAplicadas(); 
                                if (this.DatosGiftcard.length>0 && existsGiftcard){
                                    //confirma uso de giftcard
                                    this.NewGiftcardTrx(montoGiftcard,this.NroCpte);      
                                }     
                        }
                    }
                    await this.ClickImprimir2();
                    this.FacturaGenerada = true;

                    if (GetUserCookie().Sucursal.configPOS.openUrlRespuesta && jsonRespuesta.urlDocumento!='') {
                        window.open(jsonRespuesta.urlDocumento, '_blank');
                    }
                    this.setState({ urlDisponible: true, urlDocumentoSii: jsonRespuesta.url })
                }
                else {


                    if (respuesta.statusGeneroFactura && !respuesta.statusInsertaBlinamic) {
                        await this.ClickImprimir2();
                        this.FacturaGenerada = true;
                        if (GetUserCookie().Sucursal.configPOS.openUrlRespuesta) {
                            window.open(jsonRespuesta.urlDocumento, '_blank');
                        }
                        
                        await Swal2.fire({
                            title: "Atencion !!",
                            icon: "error",
                            text: "Problemas al insertar el movimiento en Blinamic. Consulte con el Soporte",
                            dangerMode: true,
                        });
                        window.location.reload();
                    } else if (!respuesta.statusGeneroFactura && !respuesta.statusInsertaBlinamic) {
                        
                        await Swal2.fire({
                            title: "Atencion !!",
                            icon: "error",
                            text: "Problemas al generar el documento. Por favor intente nuevamente.",
                            dangerMode: true,
                        });
                        window.location.reload();

                    }

                }
            }
            else {
                await Swal2.fire({
                    title: "Error al generar la venta",
                    icon: "error",
                    html:
                        'Orden: ' + this.state.orden.id +'!<br>' +
                        '<p><div class= "card card-body" >' +
                        jsonRespuesta.message +
                        '</div></p>',
                    footer: 'Contacte con soporte técnico',
                    dangerMode: true,
                }).then((result) => {
                    window.location.reload()
                });
            }
           

        }
        else {
            this.DesbloquearDocumentos();
            this.FacturaGenerada = false;
        }
        this.OnClose();
    }


    async ClickMostrarFactura() {
        //this.Estilos.EstiloPopup.height = '90%';
        console.log(this.ClientData);
        //Se generara factura para: 
        var mensaje = "Se generará Factura a: " + this.ClientData.tipoCliente+" RUT " + this.ClientData.rut;
        swal({
            title: mensaje,
            //text: "Se cambiara el medio de pago actual",
            icon: "warning",
            //buttons: true,
            dangerMode: true,
            buttons: {
               
                cancel: {
                    text: "Cambiar",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "Confirmar",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                },
            }
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({ ingresarCliente: true });
                if (this.ClientData.id != "" && this.state.limpiarCliente == false){
                    this.showCliente();
                }
            } else {
                this.setState({ ingresarCliente: true, isEmpresa:true });
                this.ClearDatosFactura();
            }
        });

    }

    async showCliente(){
        console.log(this.ClientData);
        document.getElementById('txtRut').value=this.ClientData.rut;
        document.getElementById('txtEmpresa').value=this.ClientData.razonSocial;
        document.getElementById('txtDireccion').value=this.ClientData.direccion;
        document.getElementById('txtGiro').value=this.ClientData.girocomercial;
        document.getElementById('ddlCiudades').value=this.ClientData.ciudadID;
        await this.TraerComunas();
        
        document.getElementById('ddlComuna').value = this.ClientData.comunaID;
        document.getElementById('ddlTipoCliente').value=this.ClientData.tipoClienteID;
    }
    
    BloquearDocumentos() {
        if (document.getElementById('btnBoleta'))
            document.getElementById('btnBoleta').disabled = true;
        if (document.getElementById('btnFactura'))
            document.getElementById('btnFactura').disabled = true;
        this.setState({ isDocsBloqueado: true });
    }
    DesbloquearDocumentos() {
        if (document.getElementById('btnBoleta'))
            document.getElementById('btnBoleta').disabled = false;
        if (document.getElementById('btnFactura'))
            document.getElementById('btnFactura').disabled = false;
        this.setState({ isDocsBloqueado: false });
        //this.ClickImprimir2();
    }

    Redirigir(url) {
        this.context.router.push(url);
    }

    RedondearVuelto(montoVuelto) {
        if (montoVuelto < 0) {
            return 0;
        }
        if (montoVuelto > 0) {
            const resto = montoVuelto % 10;
            if (resto > 0 && resto <= 5) {
                return montoVuelto - resto;
            }
            else {
                return montoVuelto;
            }
        }
        else {
            return montoVuelto;
        }
    }

    ClickVolverTipoDoc() {
        this.setState({ ingresarCliente: false });
        this.Estilos.EstiloPopup.height = '50%';
    }

    
    //-------CLICKS

    //-------FIN CLICKS


    //--------ESTILOS
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },MitadAncho: {
            'height ': '50%'
        },
        Columnas: {
            col1: { 'width': '60%' },
            col2: { 'width': '40%' }
        },
        BtnMedio: {
            'width': '49%',
            'margin': '1px',
            'height': '30%'
        },
        DivsContent: {'width':'300px'},
        EstiloPopup: {
            'width': '400px',
            'height': '40%',

            'background-color': '#ecf0f5',
            'border-radius': '25px',
        },
        EstiloPopup2: {
            'width': '65%',
            'height': '65%',

            'background-color': '#ecf0f5',
            'border-radius': '25px',
        },
        BtnOpciones: {
            'height': '65px',
            'border-radius': '8px',
            'background': 'rgb(233, 234, 234)',
            'color': 'black',
            'borderColor': 'rgb(110, 152, 135)',
            'margin-left': '-30%',
            'margin-right': '10%'
        },
        BtnOpciones2: {
            'height': '65px',
            'border-radius': '8px',
            'background': 'rgb(209 230 230)',
            'color': 'black',
            'borderColor': 'rgb(110, 152, 135)',
            'margin-left': '8%',
            'margin-right': '10%'
        },
        BtnOpcionesListo: {
            'height': '60px',
            'width': '39%',
            'margin-left': '7%'
        },
        BtnOpcionesPay: {
            'height': '60px',
            'width': '39%',
            'margin-left': '7%',
            'background':' #6c757d',
            'color': 'white'
        },
        btnDocumento: {
            'marginLeft': '20px',
            'height ': '50%'
        },
        DivContent:
        {
                'width': '100%',
                'height': '100%',
                'margin-top':'-40%'
        },
        CambioContent:
        {
            'width': '100%',
            'margin-top': '26%',
            'font-size': '20px',
            'margin-left': '7%'
        },    
        BtnDoc:
        {
            'width': '100%',
            'padding-top':'10%'
        },    
        BtnClicks:
        {
            'width': '100%',
            'padding-top': '10%'
        },
        BtnClicksFac:
        {
            'width': '100%',
            'padding-top': '10%',
            'margin-left':'13%'
        },
        MarginInfoCliente:
        {
            'margin-left': '5%'
        },
        BtnGroup:
        {
            'height': '100%',
            //'width': '6.1%',
            'borderColor': 'rgb(110, 152, 135)',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'rigthMargin':'50px'
        },
        PopUpStyleAddCliente: {
            'background': 'rgb(255, 255, 255)',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'borderRadius': '8px'
        },
        SeparacionAddCliente: {
        },
        BotonCerrarOSListDs: {
        },
        
    }
    //--------FIN ESTILOS
    ClearDatosFactura()
    {
        document.getElementById('txtRut').value='';
        document.getElementById('ddlCiudades').value='';
        document.getElementById('ddlComuna').value='';
        document.getElementById('txtEmpresa').value='';        
        document.getElementById('txtGiro').value='';
        document.getElementById('txtDireccion').value = '';

        this.setState({limpiarCliente:true});

    }
    async BlurRut() {
        var rutIngresadoTxt = document.getElementById('txtRut').value;
        console.log(rutIngresadoTxt);
        if (rutIngresadoTxt.length>=9) {

            this.formateaRut();
            var rutIngresados = document.getElementById('txtRut').value;
            let empresa = GetUserCookie().EmpresaID;
            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientePorRutD?rut=' + rutIngresados + '&EmpresaID=' + empresa, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {
                var datos = await respuesta.json();
                await this.setState({ ingresarCliente: false });
                this.ClientData = datos;
                if (datos.id==null) {
                    this.setState({ cliente: datos, ingresarCliente: true, limpiarCliente: false, CrearCliente: true });
                } else {
                    this.setState({ cliente: datos, ingresarCliente: true, limpiarCliente: false, CrearCliente: false });
                }
                document.getElementById('txtRut').value = rutIngresados;
            }

        }


    }

  
    formateaRut() {
        var rut = document.getElementById('txtRut').value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            document.getElementById('txtRut').value = rutSinPuntosD;
            return rutPuntos;
        }
    }
    formatRutAppOff()
    {

        var rut = document.getElementById('txtRut').value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            return rutPuntos;
        }
    }
   
    
    GetHtmlInfoCliente() {
        return (
            <div style={this.Estilos.MarginInfoCliente}>
                <CardHeader className="bg-info">Ingrese la informacion del cliente/empresa</CardHeader>
                <center>
                    {
                        (this.state.cargandoIsSelected) ? (
                            <div style={this.Estilos.FondoDefault}>
                                <center>
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    <p class='mt-md-2'> Porfavor espere la generación del Documento</p>
                                </center>
                            </div>
                        ) : (null)
                    }
                </center>
                <div className="form-group col" style={this.Estilos.SeparacionAddCliente}> </div>
                <div className="row mb-3"  >
                    <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                        <label>Razon Social:</label><input className="form-control" type="text"  id="txtEmpresa"/>
                    </div>
                </div>
                <div className="row mb-3"  >
                    <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                        <label>Rut: </label><input className="form-control" type="text"  id="txtRut"  />
                    </div>
                    <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                        <label>Tipo Cliente:</label>                                
                        <select id="ddlTipoCliente" className="form-control" >
                            {
                                this.state.TipoClienteOpt.map((item, index) => {
                                    if (item.id==2 && this.state.isEmpresa  ) {
                                        return (<option value={item.id} selected>{item.nombre}</option>);

                                    } else if (item.id == 1 && !this.state.isEmpresa  )
                                    {
                                        return (<option value={item.id} selected>{item.nombre}</option>);

                                    }
                                    else {
                                        return (<option value={item.id}>{item.nombre}</option>);

                                    }
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                        <label>Giro comercial:</label><input className="form-control"  id="txtGiro" type="text"/>
                    </div>                    
                </div>
                <div className="row mb-3"  >
                    <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                        <label>Region</label>
                        <select name="ddlCiudades" id="ddlCiudades"className="form-control" onChange={() => { this.TraerComunas() }} >
                                  {this.state.Regiones.map((com) => {                   //recorro el state y muestro datos
                                      return (
                                          <option key={com.id} value={com.id}>
                                          {com.nombre}
                                          </option>
                                      );
                                  })}
                        </select>
                    </div>
                    <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                        <label>Comuna: </label>
                        <select name="ddlComuna" id="ddlComuna"className="form-control" >
                                  {this.state.Comunas.map((com) => {                   //recorro el state y muestro datos
                                      return (
                                          <option key={com.id} value={com.id}>
                                          {com.nombre}
                                          </option>
                                      );
                                  })}
                        </select>
                    </div>                    
                    <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                        <label>Dirección:</label><input className="form-control"  id="txtDireccion" type="text"/>
                    </div>
                </div>

                <div style={this.Estilos.BtnClicksFac}>
                    <div className="d-inline-flex ml-2 mr-2 mb-2">
                        <button onClick={() => { this.ClickFactura() }} className="btn btn-success form-control" style={this.Estilos.BotonCerrarOSListDs}> Generar factura</button>
                    </div>
                    <div className="d-inline-flex ml-2 mr-2 mb-2">
                        <button onClick={() => { this.ClearDatosFactura() }} className="btn btn-danger" style={this.Estilos.BotonCerrarOSListDs}>Limpiar datos</button>
                    </div>                                    

                </div>
            </div>



        );
    }

    GetHtmlPopupAddCliente() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info">Alta de cliente</CardHeader>
                <Form>
                    <CardBody id="altaCliente" className="form form-group">
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <input className="form-control" type="text" placeholder="NOMBRE" id="nombreAddCliente" />
                            </div>
                            <div className="d-inline-flex ml-2 mr-2">
                                <input className="form-control" type="text" placeholder="APELLIDOS" id="apellidoAddCliente" />
                            </div>
                        </div>
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <input className="form-control" type="text" placeholder="RUT (12345678-9)"
                                    onChange={(val) => {
                                        this.formateaRut('rutAddCliente');
                                    }}
                                    id="rutAddCliente" maxLength="9" minLength="9" />
                            </div>
                            <div className="d-inline-flex ml-2 mr-2">
                                <PhoneInput
                                    className="form-control"
                                    value={this.state.phoneAdded}
                                    onChange={phoneAdded => this.setState({ phoneAdded })}
                                    //onlyCountries={['cl', 'ar']}
                                    id="telefonoAddCliente"
                                    country={'cl'}
                                />
                            </div>
                        </div>

                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex mr-2 ml-2">
                                <input className="form-control" type="text" placeholder="EMAIL" id="emailAddCliente" />
                            </div>
                        </div>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveCliente" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarCliente() }} ><strong>Agregar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }

    async  OnClose() {
        var tipoV = this.state.tiposVentaID;
        //this.ClickImprimir2();
        if (this.state.orden.tiposVentaID === 1) {
            await this.RedirigirToMesa(this.state.orden.mesaID);
            
            this.setState({ redirigir: true });
        }
        else if (this.state.orden.tiposVentaID === 2) {
            const urlReidirigir = GetUserCookie().Sucursal.configPOS.urlRedireccionParaLlevar;
            window.location.href = urlReidirigir;
        }
        else {
                this.UrlRedirijir = '/';
                this.setState({ redirigir: true });
        }
    }
    checkRut(rut) {
        // Despejar Puntos
        var valor = rut.replaceAll('.', '');
        // Despejar Guión
        valor = valor.replace('-', '');

        // Aislar Cuerpo y Dígito Verificador

        const cuerpo = valor.slice(0, -1);
        let dv = valor.slice(-1).toUpperCase();

        // Formatear RUN
        rut = cuerpo + '-' + dv

        // Si no cumple con el mínimo ej. (n.nnn.nnn)
        if (cuerpo.length < 7) { return false; }

        // Calcular Dígito Verificador
        let suma = 0;
        let multiplo = 2;

        // Para cada dígito del Cuerpo
        let index = 0;
        for (let i = 1; i <= cuerpo.length; i++) {

            // Obtener su Producto con el Múltiplo Correspondiente
            index = multiplo * valor.charAt(cuerpo.length - i);

            // Sumar al Contador General
            suma = suma + index;

            // Consolidar Múltiplo dentro del rango [2,7]

            if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

        }

        // Calcular Dígito Verificador en base al Módulo 11
        const dvEsperado = 11 - (suma % 11);

        // Casos Especiales (0 y K)
        dv = (dv == 'K') ? 10 : dv;
        dv = (dv == 0) ? 11 : dv;

        // Validar que el Cuerpo coincide con su Dígito Verificador
        if (dvEsperado != dv) { return false; }

        // Si todo sale bien, eliminar errores (decretar que es válido)

        return true;
    }

    render() {
        return (
            (this.state.ingresarCliente) ?
                (<Popup
                    contentStyle={this.Estilos.EstiloPopup2}
                    modal
                    defaultOpen
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                >
                    <div className="content">
                        {this.GetHtmlInfoCliente()}
                    </div>
                </Popup>)
                :(
            <Popup contentStyle={this.Estilos.EstiloPopup}
                dismissible={false}
                modal
                defaultOpen
                closeOnDocumentClick={false}
                closeOnEscape={false}
                >
                <div className="content">
                    {
                        (this.state.redirigir) ? (<Redirect to={this.UrlRedirijir} />) : (<div></div>)
                    }
            
                                <div className="row" style={this.Estilos.DivContent}>
                                    <center className="row" style={this.Estilos.DivsContent}>
                                        <div style={this.Estilos.CambioContent}>
                                            <p class='h4'>Finalizando Venta</p>
                                            {
                                                (this.RedondearVuelto(this.Vuelto) === 0)?
                                                (null)
                                                :
                                                (<p class='h6'>Vuelto: {GetPrecioFormateado(this.RedondearVuelto(this.Vuelto))}</p>)
                                            }
                                        </div>
                                    </center>
                                    <div className="row " style={this.Estilos.DivsContent}>
                                    {
                                        (this.state.isFacturacionActiva) ? (
                                                <div hidden="hidden" style={this.Estilos.BtnDoc}>
                                                    <button disabled={this.state.isDocsBloqueado} className="btn btn-secondary btn-lg" id="btnBoleta" style={this.Estilos.btnDocumento} onClick={() => { this.ClickBoleta() }}> Boleta</button>
                                                    <button disabled={this.state.isDocsBloqueado} className="btn btn-secondary btn-lg" id="btnFactura" style={this.Estilos.btnDocumento} onClick={() => { this.ClickMostrarFactura() }}>Factura</button>
                                                    <div style={this.Estilos.BtnDoc}>
                                                        
                                                    </div>
                                                </div>
                                        ) : (
                                                
                                                <div hidden="hidden" style={this.Estilos.BtnDoc}>
                                                    <button name="blokCambios" id="btnPagar" onClick={() => { this.ClickPagarDos() }} className="btn " style={this.Estilos.BtnOpcionesPay}
                                                            data-toggle="tooltip" data-placement="right" title="Pagar"><i className="fa fa-money fa-2x" aria-hidden="true"></i></button>
                                                        {(this.state.BtnPagarClick) ?
                                                            (<button className="btn btn-success" style={this.Estilos.BtnOpcionesListo} onClick={() => { this.ClickListo() }}>
                                                                <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i>
                                                            </button>) : (<button disabled className="btn btn-success" style={this.Estilos.BtnOpcionesListo} onClick={() => { this.ClickListo() }}>
                                                                <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i>
                                                            </button>)}
                                                </div>
                                                )
                                    }
                                                                   
                                </div>
                                <center>
                                {
                                    (this.state.cargandoIsSelected) ? (
                                        <div style={this.Estilos.FondoDefault}>
                                            <center>
                                                <div className="spinner-border text-dark mt-md-3" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                                <p class='mt-md-2'> Porfavor espere la generación del Documento</p>
                                            </center>
                                        </div>
                                    ) : (null)
                                    }
                                    </center>
                                </div>
                
                        
                        
                        <br/>
                        <center>
                            
                            {
                                (this.state.urlDisponible) ? (
                                    <a target="_blank" href={this.state.urlDocumentoSii}>Ver documento generado</a>
                                ): (null)
                            }
                        </center>


                    
                </div>
            </Popup>)
        );
    }
    //<button className="btn btn-light" onClick={() => { this.ClickImprimir() }} style={this.Estilos.BtnOpciones}>Imprimir boleta</button>

}
