import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { TecladoNumerico } from './TecladoNumerico';

export class SetPropina extends Component {
    static displayName = SetPropina.name;
    Orden = {};

    constructor(props) {
        super(props);
        this.state = {
            redirigir: false
        }
        this.Orden = props.orden;
        this.ActualizarEstado = this.props.ActualizarEstado;
        
    }

    componentDidMount() {

    }
    //inicio sobreescribir funciones
    ActualizarEstado() {

    }

    ActualizarEstadoPagar() {

    }
    //inicio sobreescribir funciones

    Volver() {
        this.props.ActualizarEstadoPagar({ activarSetPropina: false });
    }

    ClickPropinaPorc(porcentaje) {
        try {
            var neworden = this.props.orden;
            neworden.propinaPorc = porcentaje;

            //this.props.ActualizarEstado({ orden: neworden });
            this.ActualizarEstado({ orden: neworden });

            this.props.ActualizarEstadoPagar({ activarSetPropina: false, orden: neworden });
        } catch (err) {
            console.log(err);
        }
        finally {
            this.Volver();
        }
    }

    ClickRemoverPropina() {
        var neworden = this.props.orden;
        neworden.propinaPorc = 0;
        neworden.propinaCant = 0;
        neworden.propinaDiff = 0;

        //this.props.ActualizarEstado({ orden: neworden });
        this.ActualizarEstadoPagar({ orden: neworden });
        this.Volver();
    }

    //-------CLICKS

    //-------FIN CLICKS


    //--------ESTILOS
    Estilos = {
        FullAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '70%' },
            col2: { 'width': '30%' }
        },
        BtnMedio: {
            'width': '49%',
            'margin': '1px',
            'height': '30%'
        },
        EstiloPopup: {
            'width': '50%',
            'height': '50%',
            'alignContent': 'center'
        },
        BtnOpciones: {
            //'width': '32%',
            //'margin': '1px',height: 50px;
            'height': '50px',
            'width': '30%',
            'font-size': '80%',
            'margin-right': '1%',
            'margin-left': '2%',
            'background': 'rgb(118, 158, 142)'
        },
        BtnOpcionesRap: {
            //'width': '32%',
            //'margin': '1px',height: 50px;
            'height': '50px',
            'width': '15%',
            'font-size': '80%',
            'margin-left': '5%',
            'margin-right': '10%',
            'background': 'rgb(118, 158, 142)'
        },

    }
    //--------FIN ESTILOS


    GetHtmlBorrarPropina() {
        if (this.Orden.propinaPorc > 0 || this.Orden.propinaCant > 0 || this.Orden.propinaDiff > 0) {
            return (
                <button className="btn btn-danger" onClick={() => { this.ClickRemoverPropina() }} style={this.Estilos.FullAncho}>Remover propina</button>
                );
        }
        return null;
    }


    render() {
        return (
            <Popup contentStyle={this.Estilos.EstiloPopup}
                modal
                onClose={() => { this.props.ActualizarEstadoPagar({ activarSetPropina: false }) }}
                defaultOpen
                closeOnDocumentClick>
                <div>
                    <h3>Opciones</h3>
                    <button name="OpcPrincipales" id="btnPorcentaje" className="btn btn-dark" style={this.Estilos.BtnOpciones} onClick={() => { this.setState({ propinaPorc: true, propinaCant: false, propinaClicked: false, propinaDif: false }) }}>Porcentaje</button>
                    <button name="OpcPrincipales" id="btnCantidad" className="btn btn-dark" style={this.Estilos.BtnOpciones} onClick={() => { this.setState({ propinaCant: true, propinaPorc: false, propinaClicked: false, propinaDif: false }) }}> Cantidad</button>
                    <button name="OpcPrincipales" id="btnDiferencia" className="btn btn-dark" style={this.Estilos.BtnOpciones} onClick={() => { this.setState({ propinaDif:true, propinaCant: false, propinaPorc: false, propinaClicked: false }) }}>Diferencia</button>

                    {
                        (this.state.propinaPorc) ? (<div>
                            <TecladoNumerico orden={this.props.orden} isPropinaPorc={true} ActualizarEstadoPagar={this.props.ActualizarEstadoPagar} ActualizarEstado={this.props.ActualizarEstado} CerrarModalMaster={this.props.CerrarModalMaster} />
                        </div>)
                            : (<div></div>)
                    }

                    {
                        (this.state.propinaCant) ? (<div>
                            <TecladoNumerico propinaCant={true} orden={this.props.orden} ActualizarEstadoPagar={this.props.ActualizarEstadoPagar} ActualizarEstado={this.props.ActualizarEstado} CerrarModalMaster={this.props.CerrarModalMaster} />
                        </div>)
                            : (<div></div>)
                    }

                    {
                        (this.state.propinaDif) ? (<div>
                            <TecladoNumerico propinaDif={true} orden={this.props.orden} ActualizarEstadoPagar={this.props.ActualizarEstadoPagar} ActualizarEstado={this.props.ActualizarEstado} CerrarModalMaster={this.props.CerrarModalMaster} />
                        </div>)
                            : (<div></div>)
                    }
                    <hr />
                    <h3>Opciones rapidas</h3>
                    <center>
                    <button className="btn btn-dark" style={this.Estilos.BtnOpcionesRap} onClick={() => { this.ClickPropinaPorc(5) }}>5%</button>
                    <button className="btn btn-dark" style={this.Estilos.BtnOpcionesRap} onClick={() => { this.ClickPropinaPorc(10) }}> 10%</button>
                    <button className="btn btn-dark" style={this.Estilos.BtnOpcionesRap} onClick={() => { this.ClickPropinaPorc(15) }}>15%</button>
                    {this.GetHtmlBorrarPropina()}
                        </center>
                    <hr />
                    <center>
                        <button className="btn btn-success" style={this.Estilos.FullAncho} onClick={() => { this.Volver() }}><i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
                        </center>
                </div>
            </Popup>
        );
    }
}
