import React, { Component } from 'react'
import { GetUrlApi } from './Globales/VariableGlobales'
import Popup from "reactjs-popup"
import Swal from 'sweetalert2'
import { Row } from 'reactstrap'



export class QRHten extends Component {
    static displayName = QRHten.name
    constructor(props) {
        super(props)
        this.state = {
            tbMedio: {},
            qrSource: '',
            idTransaction: '',
            qrType: 'DYNAMIC'
        }
        if (this.props.finalizarVenta)
            this.finalizarVenta = this.props.finalizarVenta
        
        //if (this.props.CerrarModal)
         //   this.CerrarModalMaster = this.props.CerrarModalMaster
         

    }

    componentDidMount() { 
        const machQrType = localStorage.getItem('machQrType')
        this.setState({ qrType: machQrType })
        console.log('Mounted qrhten')
        const img = this.props.htenResponse.qrCode.image?this.props.htenResponse.qrCode.image:''
        const id = this.props.htenResponse.payment?this.props.htenResponse.payment.id:''

        this.setState({ tbMedio: this.props.medio, qrSource: img, idTransaction: id })
        // this.setState({ tbMedio: this.props.medio })
        console.log('htenResponse', this.props.htenResponse)
    }

    render() {
        return (
            <Popup
                onClose={() => { this.CerrarModal() }}
                contentStyle={ this.Estilos.PopUpStyleQr }
                defaultOpen
                modal
                closeOnDocumentClick>
                <div class='container'> 
                    <div class='row justify-content-md-center h1'>
                        Escanea el código
                    </div>
                    
                </div>
                <div class='container'>
                    <div class="row justify-content-md-center">
                        <p class='h5'>{( this.state.idTransaction )} </p>
                    </div>
                    <div class="row justify-content-md-center h4">
                        { this.state.qrType==='DYNAMIC'?
                            ( this.state.qrSource === ''? 'Hubo un problema con código QR. Cancela e intenta nuevamente':<img src={ this.state.qrSource } class="rounded" alt="..."/>) 
                            :'Favor escanear el código QR'
                        }
                    </div>
                    <div class="row justify-content-md-center">
                        <p><button type='button' class='btn btn-secondary' onClick={ () => { this.clickCancelar() } }> <i class="fa-solid fa-xmark"></i> Cancelar</button></p>
                        {this.state.qrType==='DYNAMIC'? 
                            <p className='ml-3'><button type='button' class='btn btn-link' onClick={ () => { this.clickImprimirQr() } }> <i class="fa-solid fa-print"></i> </button></p>
                            :null
                        }
                    </div>
                    
                </div>
            </Popup>
        )
    }

    clickCancelar() {
        this.props.qrCancel()
    }
    async ClickPagar(MedioPago) {
        // swal('Procesando Pago');
        // const pago = this.state.pagos.filter(pago => pago.id === MedioPago.id)
        // pago.map((localpago) => {
        //     localpago.processed = true
        // })
        const procesado = await this.fncProcesarPago(MedioPago)
        if(procesado) {
            await this.state.pagos.map((pago) => {
                if(pago.id === MedioPago.id)
                    pago.processed = true
            })
            this.setState({ pagos: this.state.pagos })    
        }
    }


    CerrarModal() { }

    CerrarModal2() { }

    CerrarModalMaster() { }

    Estilos = {
        PopUpStyleQr:
        {
            'width': '40%',
            'height': '77%',
            'background-color': '#ecf0f5',
            'border-radius': '25px',
        },
    }

    async fncProcesarPago(medio) {
        let retorno = false
        const total = medio.total
        if (medio.isTarjeta && this.isHten()) {
            // Llamar a HTEN PAGOS
            console.log("MEDIO ", medio);
            let endpoint = "compraDebito"
            let tipoTarjetaJson = "DB"
            let callHten = false;
            console.log('medio.medioEquivalente', medio.medioEquivalente)
            switch (medio.medioEquivalente) {
                case "TARJETADEBITO":
                    retorno = false
                    tipoTarjetaJson = 'DB'
                    callHten = true
                    break
                case "TARJETACREDITO":
                    retorno = false
                    endpoint = "compraCredito"
                    await Swal.fire({
                        title: 'Es tarjeta NO BANCARIA?',
                        text: "Si es una tarjeta comercial presione en SI.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, es NB!',
                        cancelButtonText: 'No, es CR!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            tipoTarjetaJson = "NB"
                        } else {
                            tipoTarjetaJson = "CR"
                        }
                    });
                    callHten = true
                    break
                case "BINES":
                    endpoint = "consultaCompra"
                    break
                case "QR_ESTATICO":
                    endpoint = "pagoFpay_static"
                    callHten = true
                    break  
                case "QR_DINAMICO":
                    endpoint = "pagoFpay_dynamic"
                    callHten = true
                    break
                case 'MACH-BCI':
                    endpoint = 'wallet/payment'
                    callHten = true
                    break
                default:
                    retorno = true
                    break
            }
            
            console.log("endpoint ", endpoint);
            if (callHten) {
                const processHten = await this.callToHten(endpoint, tipoTarjetaJson, null, total)
                if(processHten) {
                    medio.NumTarjeta = JSON.stringify(this.state.htenJsonResponse)
                    retorno = true
                } else {
                    retorno = false
                }
                
            }
            if ((this.state.htenApproved === false)) {

                this.setState({ htenApproved: null })
                retorno = false
            }
        }
        else {
            retorno = true
        }
        return retorno
    }
    isHten() {
        return JSON.parse(sessionStorage.getItem('userProfile')).Sucursal.hten;
    }
    async callToHten(endpoint, tipoTarjetaJson, htenResponse, total) {
        const ipHten = localStorage.getItem('ipHten');
        const userData = JSON.parse(sessionStorage.getItem('userProfile'));
        const idOrden = this.IdOrden;
        var myBody = {};
        myBody.numeroEmpleado = userData.id + "";
        myBody.montoOperacion = total + ""; // this.GetBalance() + "";
        myBody.montoPropina = "-1";
        myBody.montoVuelto = "-1";
        myBody.codigoMoneda = "CL";
        myBody.numeroBoleta = this.NumeroOrdenNew + "";
        myBody.tipoTarjeta = tipoTarjetaJson;

        const controller = new AbortController();
        const signal = controller.signal;
        let isDynamic = false;
        switch (endpoint) {
            case "pagoFpay_static":
                myBody.montoPropina = "0";
                myBody.qrType = "1";
                endpoint = "pagoFpay";
                Swal.fire({
                    title: 'Leer el QR estático',
                    text: 'Avise al cliente que lea el QR y haga el pago con la APP mobile. Este mensaje se cerrará cuando se reciba respuesta del pago o en 3 minutos por timeout.',
                    icon: 'warning',
                    showCloseButton: false,
                    showCancelButton: true,
                    showConfirmButton: false,
                    focusConfirm: false,
                    cancelButtonText: 'Cancelar...'
                }).then((result) => {
                    controller.abort();
                    this.setState({ isMedioDePagoDisabled: false });
                    this.quitarPago(this.state.pagos.length - 1);
                });

                break;
            case "pagoFpay_dynamic":
                myBody.montoPropina = "0";
                myBody.qrType = "0";
                endpoint = "pagoFpay";
                isDynamic = true;
                break;
        }

        if (htenResponse != null) {
            myBody.mensaje0110 = htenResponse.mensaje0110;
        }
        
        if (localStorage.getItem('htenDemo') != null && localStorage.getItem('htenDemo') === "SI") {
            myBody.ultimos4 = "demo";
            if (endpoint === "pagoFpay") {
                myBody.numeroEmpleado = "DEMO";
            }
        }
        console.log("Body ", myBody);
        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'post',
            dataType: 'jsonp',
            signal: signal,
            async: false,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then(respuesta => respuesta.json()).then(async (data) => {
            console.log(data);
            //this.processResponse(data, endpoint);

            // Si es QR Dinamico debo mostrar el QR y luego ejecutar la consulta. El resultado da pagado o rechazado.
            
            if (endpoint === "pagoFpay") {
            
                if (isDynamic) {
                    console.log(this.state.htenJsonResponse);

                    Swal.fire({
                        title: 'Por favor realice el pago con su APP mobile.',
                        html: data.glosaRespuesta,
                        showCloseButton: false,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText:
                            '<i class="fa fa-thumbs-up"></i> Listo!',
                        confirmButtonAriaLabel: 'Vayamos a leer el resultado!',
                        cancelButtonText:
                            '<i class="fa fa-thumbs-down"> Cancelar</i>',
                        cancelButtonAriaLabel: 'Thumbs down'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // llamar a pago con propina en -1 para consultar estado
                            const controller2 = new AbortController();
                            const signal2 = controller2.signal;
                            Swal.fire({
                                title: 'Esperando respuesta de pago QR',
                                text: 'Este mensaje se cerrará cuando se reciba respuesta del pago o en 3 minutos por timeout.',
                                icon: 'warning',
                                showCloseButton: false,
                                showCancelButton: true,
                                showConfirmButton: false,
                                focusConfirm: false,
                                cancelButtonText: 'Cancelar...'
                            }).then((result) => {
                                controller2.abort();
                                this.setState({ isMedioDePagoDisabled: false });
                                this.quitarPago(this.state.pagos.length - 1);
                            });
                            myBody.montoPropina="-1"
                            fetch('http://' + ipHten + '/api/' + endpoint, {
                                method: 'post',
                                dataType: 'jsonp',
                                signal: signal2,
                                async: false,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myBody),
                            }).then(respuesta => respuesta.json()).then(async (data) => {
                                if (this.processResponse(data, endpoint)) {

                                    this.setState({ cerrarModalDeshabilitado: true })
                                    await Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        animation: false,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'success',
                                    }).then((result) => {
                                        localStorage.setItem("ultimaOrden", this.IdOrden)
                                        localStorage.setItem("tipoUltimaOrden", "pago")
                                        
                                        //if (this.GetBalance() === 0) {
                                        //    this.setState({ isMedioDePagoDisabled: "disabled" });
                                        // S}
                                        return true;
                                    })
                                    return true;
                                } else {
                                    await Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago NO ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        animation: false,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'warning',
                                    });
                                    this.setState({ isMedioDePagoDisabled: false });
                                    this.quitarPago(this.state.pagos.length - 1);

                                    return false;
                                }
                            });

                        } else {
                            this.setState({ isMedioDePagoDisabled: false });
                            this.quitarPago(this.state.pagos.length - 1);
                            return false;
                        }
                    });
                    return false;
                }
            }

            if (endpoint !== "consultaCompra") {
                if (this.processResponse(data, endpoint)) {
                    this.setState({ cerrarModalDeshabilitado: true });
                    
                    await Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta+'</strong>',
                        text: 'El pago ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'success',
                    }).then(() => {
                        localStorage.setItem("ultimaOrden", this.IdOrden)
                        localStorage.setItem("tipoUltimaOrden", "pago")
                        // if (this.GetBalance() == 0) {
                        //     this.setState({ isMedioDePagoDisabled: "disabled" });
                        //                         
                        //if (endpoint === "compraDebito") {
                        //    this.AgregarPago(this.GetBalance(), null, null, null, null, null, this.state.htenResponse, null).then(() => { });
                        //    this.ClickPagosListos();
                        //}
                        return true;
                    });
                    return true
                } else {
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta+'</strong>',
                        text: 'El pago NO ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    });
                    return false;
                }
            } else {
                console.log("consulta ", data);
                console.log("consulta json ", this.state.htenJsonResponse);
                if (this.processResponse(data, endpoint)) {
                    console.log("consulta 1 ", data);
                    console.log("consulta 1 json ", this.state.htenJsonResponse);
                    if (!this.alertShowed) {
                        this.alertShowed = true;
                        console.log(this.state.htenJsonResponse);
                        Swal.fire({
                            title: '<strong>TARJETA LEÍDA</strong>',
                            text: 'Tarjeta:' + this.state.htenJsonResponse.marcaTarjeta + ' bin:' + this.state.htenJsonResponse.seisPrimerosDigitosTarjeta,
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: 'CONTINUAR PARA PAGAR',
                            animation: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            closeOnClickOutside: false,
                            icon: 'success',
                        }).then(() => {
                            let newEndpoint = 'compraCredito';
                            if (this.state.htenJsonResponse.tipoTarjeta == "DB") {
                                newEndpoint = 'compraDebito';
                            }

                            myBody.tipoTarjeta = this.state.htenJsonResponse.tipoTarjeta;
                            myBody.mensaje0110 = this.state.htenJsonResponse.mensaje0110;

                            fetch('http://' + ipHten + '/api/' + newEndpoint, {
                                method: 'post',
                                dataType: 'jsonp',
                                async: false,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myBody),
                            }).then(respuesta => respuesta.json()).then(async(data) => {
                                if (this.processResponse(data, newEndpoint)) {

                                    this.setState({ cerrarModalDeshabilitado: true });
                                    await Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'success',
                                    }).then(() => {
                                        localStorage.setItem("ultimaOrden", this.IdOrden)
                                        localStorage.setItem("tipoUltimaOrden", "pago")
                                        if (this.GetBalance() == 0) {
                                            this.setState({ isMedioDePagoDisabled: "disabled" });
                                        }

                                        if (newEndpoint === "compraDebito") {
                                            this.AgregarPago(this.GetBalance(), null, null, null, null, null, this.state.htenResponse, null).then(() => { });
                                            this.ClickPagosListos();
                                        }
                                        return true;
                                    });
                                } else {
                                    Swal.fire({
                                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                                        text: 'El pago NO ha sido autorizado',
                                        showDenyButton: false,
                                        showCancelButton: false,
                                        confirmButtonText: 'CONTINUAR',                                        
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        closeOnClickOutside: false,
                                        icon: 'warning',
                                    });
                                    return false;
                                }
                            });

                        });
                    }
                } else {
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta + '</strong>',
                        text: 'El pago NO ha sido autorizado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    });
                    return false;
                }
            }
        }).catch(async (reason) => {
            console.log(reason);
            // this.setState({ htenApproved: false })
            if (reason.code !== 20) {
                await Swal.fire({
                    title: '<strong>ERROR</strong>',
                    text: 'No logramos conectar con HTEN ' + reason,
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'error',
                })
            }
            return false;
        })

        console.log(resul, 'result')
        return resul

    }
    processResponse(data, endpoint) {
        console.log("llega rta de HTEN ", data)
        if (data.constructor === ({}).constructor) {
            console.log("is_object")
            if (endpoint === "pagoFpay") {
                let fpayObj = {};
                try {
                    fpayObj = JSON.parse(data.glosaRespuesta);
                } catch (e) {
                    const codRta = parseInt(data.codigoRespuesta);
                    if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                        this.setState({ htenApproved: true })
                        return true;
                    } else {
                        this.setState({ htenApproved: false })
                        return false;
                    }
                }
                data.voucher = fpayObj.gateway.voucher.join("\n");
                data.payload = data.glosaRespuesta;
                data.glosaRespuesta = fpayObj.state;
            }
            this.setState({ htenJsonResponse: data });
            this.setState({ htenResponse: JSON.stringify(data) });

            const codRta = parseInt(data.codigoRespuesta);
            if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                this.setState({ htenApproved: true })
                return true;
            } else {
                this.setState({ htenApproved: false })
                return false;
            }
        }
        const jsonObj = JSON.parse(data);
        this.setState({ htenResponse: JSON.stringify(jsonObj) });
        this.setState({ htenJsonResponse: jsonObj });
        const codRta = parseInt(jsonObj.codigoRespuesta);
        if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
            this.setState({ htenApproved: true })
            return true;
        } else {
            this.setState({ htenApproved: false })
            return false;
        }
    }
    clickBtnFinish() {
        this.props.finalizarVenta(this.state.pagos)
    }
    finalizarVenta() {

    }
    async clickImprimirQr() {
        var myBody = {}
        myBody.command = "C:\\fbhposprint\\Printer.exe"
        myBody.parameter = "print://QrAgenteHten?" + this.state.idTransaction
        myBody.path = "C:\\fbhposprint\\"
        
        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).catch(() => {
            // this.setState({ cargando: false });
            
        });
        // this.setState({ cargando: false });
    }
}
