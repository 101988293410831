import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';
import swal from 'sweetalert';
export class EntradaCajero extends Component {
    static displayName = EntradaCajero.name;
    NumCaja = GetUserCookie().CajaID;
    Numero;
    NumeroEfectivo = '';
    constructor() {
        super();
        this.state = {
            gavetas: [],
            numGavetas: 0,
            cajas: [],
            efectivo: 0,
            cargandoEnvio: false,
            volverAlIncio: false,
            mostrarTeclado: false,
            montoEfectivoUltimoCierre: 0,
            msgErr: 'OK'
        }
        this.Numero = '';
        this.TraerGavetas(this.NumCaja);

        this.keyBoardControl = this.keyBoardControl.bind(this);

    }

    keyBoardControl(e) {

        var val = document.getElementById('cantidadEfectivo').value;
        if (val.length >= 6 && val.length <= 9) {
            document.getElementById('cantidadEfectivo').style.fontSize = "1.5em";
        } else if (val.length > 10 && val.length <= 20) {
            document.getElementById('cantidadEfectivo').style.fontSize = "1.5em";

        }
        else if (val.length > 20 && val.length) {
            document.getElementById('cantidadEfectivo').style.fontSize = "1.5em";

        } else if (val.length < 6) {
            document.getElementById('cantidadEfectivo').style.fontSize = "1.5em";
        }


        switch (e.key) {
            case 'Enter':
               // setTimeout(() => { this.ClickGuardarEfectivo() }, 1000);
                break;
            case 'C':
            case 'c':
            case 'Space':
            case 'Escape':
                this.ClickCE();
                break;
            case 'Backspace':
                this.BorrarChar();
                break;
            case '-':
            case '#':
            case '*':
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                this.NumeroEfectivo += e.key;
                document.getElementById('cantidadEfectivo').value += e.key;
                document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
                break;
        }

        /*
        if (e.key === 'Enter') {
            console.log(this.Numero);

            setTimeout(() => { this.ClickGuardarEfectivo() }, 1000);
            //this.ClickSelectCantidad();

            //this.props.CerrarModalMaster();
            //var modales = document.getElementById("TecladoNumericoD");
            //modales.style.visibility = "hidden";
        }
        else if (e.key === '1') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '2') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '3') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '4') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value =Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '5') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '6') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '7') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '8') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === '9') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;
            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

       } else if (e.key === '0') {
            this.NumeroEfectivo += e.key;
            document.getElementById('cantidadEfectivo').value += e.key;

            document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');

        } else if (e.key === 'c' || e.key === 'C' || e.code === 'Space') {
            this.ClickCE();
        } */
    }

    BorrarChar() {
        this.NumeroEfectivo = document.getElementById('cantidadEfectivo').value.replace(/\./g, '');
        this.NumeroEfectivo = this.NumeroEfectivo.slice(0, -1);
        document.getElementById('cantidadEfectivo').value = Number(this.NumeroEfectivo).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[\.]/, '');
    }

    ClickCE() {
        document.getElementById('cantidadEfectivo').value = "";
        this.Numero = '';
        this.NumeroEfectivo = '';
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuario.id !== 1 && user.tipoUsuario.id !== 6) {
            this.setState({ volverAlIncio: true });
        }
        else {
            if (document.getElementById('btnTerminar'))
                document.getElementById('btnTerminar').disabled = true;
            this.TraerGaveta();
        }
        this.TraerEfectivoSobrante();
        document.addEventListener("keyup", this.keyBoardControl, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.keyBoardControl, false);
    }

    async TraerEfectivoSobrante() {

        try {
            var SucursalUserLogeado = GetUserCookie().SucursalID;
            var sobrante = await fetch(GetUrlApi() + '/api/Gavetas/TraerEfectivoSobrante?SucursalID=' + SucursalUserLogeado);
            if (!sobrante.ok)
                throw { mensaje: 'problema al traer Efectivo' };

            var datos = await sobrante.json();
            this.setState({ montoEfectivoUltimoCierre: datos });
        } catch (err) {
            console.log(err.mensaje);
        }

    }

    async TraerGavetas(numCaja) {
        try {
            const UsuarioID = GetUserCookie().id;
            const SucursalID = GetUserCookie().SucursalID;
            const CajaID = GetUserCookie().CajaID;
            const categorias = await fetch(GetUrlApi() + `/api/Gavetas?sucursalID=` + SucursalID + `&usuarioID= ` + UsuarioID);
            if (!categorias.ok)
                throw { mensaje: 'problema al traer las gavetas' };
            var datos = await categorias.json();
            const msgResponse = datos.msg
            datos = datos.gavetas.filter(p => p.caja.sucursalID === SucursalID && !p.isOcupada && p.cajaID === CajaID);
            await this.setState({ gavetas: datos, numGavetas: datos.length, msgErr: msgResponse });
        } catch (err) {
            console.log(err.mensaje);
        }
    }

    async TraerGaveta() {
        var SucursalID = GetUserCookie().SucursalID;
        var CajaID = GetUserCookie().CajaID;
        var UsuarioID = GetUserCookie().id;
        var respuesta = await fetch(GetUrlApi() + '/api/Gavetas/ObtenerGavetaAbierta?SucursalID=' + SucursalID + '&CajaID=' + CajaID + '&UsuarioID=' + UsuarioID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var data = await respuesta.json();
            if (data) {
                //swal('El usuario ya tiene una gaveta abierta').then(() => {
                //  //  this.setState({ volverAlIncio: true });
                //});
            }

        }
    }

    AgregarNumero(num) {
        //this.Numero += num;
        document.getElementById('cantidadEfectivo').value = document.getElementById('cantidadEfectivo').value + num;

        this.Numero += num;

        var num = this.formatNumber(this.Numero);
        if (this.NumeroEfectivo != '') {
            var num2 = this.formatNumber(this.NumeroEfectivo);

            document.getElementById('cantidadEfectivo').value = num2;

        } else {
            document.getElementById('cantidadEfectivo').value = num;

        }
    }

    formatNumber(n) {
        n = String(n).replace(/\D/g, "");
        return n === '' ? n : Number(n).toLocaleString();
    }



    CerrarPopup() {
        try {
            var modales = document.getElementsByclassName("popup-content");
            if (modales.length > 0)
                modales[modales.length - 1].style.visibility = "hidden";
        } catch (err) {
            console.log('ex en cierre de modal');
        }
    }

    //-------CLICKS
    async ClickGuardarEfectivo() {
        document.getElementById('btnGuardar').disabled = true;
        var cantidad = document.getElementById('cantidadEfectivo').value;
        cantidad = cantidad.replace(/\./g, "");
        if (cantidad === '')
            cantidad = 0;
        await this.setState({ efectivo: cantidad });
        this.ClickTerminar();
    }

    async ClickTerminar() {
        document.getElementById('btnGuardar').disabled = true;
        const gavetaSeleccionada = document.getElementById('cboGavetas').value;
        // const CajaSeleccionada = document.getElementById('cboCajas').value;
        this.setState({ cargandoEnvio: true });
        var data = {};
        data.MontoInicial = this.state.efectivo;
        data.Monto = this.state.efectivo;
        data.CajaID = GetUserCookie().CajaID;
        data.GavetaID = gavetaSeleccionada;
        data.UsuariosID = GetUserCookie().id;
        //data.montoEfectivoUltimoCierre = this.state.montoEfectivoUltimoCierre; // aqui hice un cambio para que no se pierda de vista
        // document.cookie = CajaSeleccionada +';expires=Tue, 19 Jan 2038 03:14:07 UTC;';
        var respuesta = await fetch(GetUrlApi() + '/api/AperturaCajas', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            if (this.state.montoEfectivoUltimoCierre.toString() != this.state.efectivo)
            {
                var SucursalID = GetUserCookie().SucursalID;
                var respuesta = await fetch(GetUrlApi() + '/api/Mensajeria/GetEnviarMensaje?ModuloPosID=1&SucursalPosID=' + SucursalID + '&UsuarioPosID=' + data.UsuariosID + '&GavetaID=' + data.GavetaID + '&CajaID=' + data.CajaID + '&ArqueoCaja=0&OrdenId=0', {
                method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                },
                });
                if (respuesta.ok) {
                }
                else {
                    //alert('error');
                }
            }
            

            document.getElementById('btnGuardar').disabled = false;
            Logout();
            this.setState({ cargandoEnvio: false, volverAlIncio: true });

        }
        else {
            alert('error');
        }
    }

    ClickCantidadEfectivo() {
        this.setState({ mostrarTeclado: true });
    }

    ClickBorrarTeclado() {
        var dato = document.getElementById('cantidadEfectivo').value

        document.getElementById('cantidadEfectivo').value = dato.slice(0, -1);
    }
    //-------FIN CLICKS


    //--------ESTILOS
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: {
                'width': '33%'

            },
            col2: {
                'width': '33%',
                'background-color': 'white',
                'border-radius': '25px',
                'border': '2px solid #73AD21',
                'padding': '20px'
            },
            col3: { 'width': '50%' }
        },
        EstiloPopup: {

        }
    }
    //--------FIN ESTILOS

    TraerOpciones() {
        const totalIteraciones = this.state.gavetas.cantGavetas;
        if (totalIteraciones) {
            for (var i = 0; i < totalIteraciones; i++) {
                return <option>Gaveta numero {i + 1}</option>
            }
        }
    }

    //INCIO HTML

    ElementBlur(e) {
        document.getElementById(e).blur();
    }
    GetInputCantidad() {
        return (<input id="cantidadEfectivo" type={this.Numero} onClick={() => { this.ElementBlur('cantidadEfectivo') } } /*onClick={() => { this.ClickCantidadEfectivo() }}*/ className="form-control" pattern="[0-9]{20}" />);
    }
    //FIN HTML

    render() {
        return (
            <div>
                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }

                <center> <h1 className="LetraBlanca">Conteo de dinero entrada de caja</h1></center>
                <hr />
                <div className="row">
                    <div style={this.Estilos.Columnas.col1} className="column">
                    </div>
                    <div style={this.Estilos.Columnas.col2}>
                        
                        { (this.state.msgErr != 'OK')?(<p class="text-danger">{ this.state.msgErr }</p>):null } 
                        <p>Seleccione la gaveta a ingresar</p>
                        <select id="cboGavetas" className="form-control">
                            {
                                this.state.gavetas.map((item) => {
                                    return (
                                        <option value={item.id}>{item.descripcion}</option>
                                    );

                                })
                            }
                        </select>
                        <hr />
                        <p>Ingrese el fondo de caja (Monto en Gaveta: {GetPrecioFormateado(this.state.montoEfectivoUltimoCierre)})</p>
                        {this.GetInputCantidad()}
                        <hr />
                        <button id="btnGuardar" onClick={() => { this.ClickGuardarEfectivo() }} style={this.Estilos.FullAncho} className="btn btn-success">Guardar</button>

                        {
                            (this.state.cargandoEnvio) ? (<p>Guardando...</p>) : (<div></div>)
                        }
                        <hr />
                        <button className="btn btn-danger" style={this.Estilos.FullAncho} onClick={() => { this.setState({ cargandoEnvio: false, volverAlIncio: true }) }} >Volver</button>

                    </div>
                    <div style={this.Estilos.Columnas.col1}>



                    </div>

                </div>

            </div>
        );
    }
}
