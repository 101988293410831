import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import ReactToExcel from 'react-html-table-to-excel';
import { GetUserCookie } from './Globales/Utilidades';
import { Logout } from './Globales/Utilidades';

import swal from 'sweetalert';

export class ResumenInformeVentas extends Component {
    static displayName = ResumenInformeVentas.name;

    constructor() {
        super();
        this.state = {
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false,
            cargadatos:false,
            Sucursales: {},
            Categorias: {},
            Descuentos: {},
            Impuestos: {},
            Pagos: {},
            Transacciones: {},
            CategoriasTotales: {},
            DescuentosTotales: {},
            PagosTotales: {},
            TransaccionesTotales: {},
            SucTotales: {},
            VerTabla: false,
            SucursalesOpt: [],
            SucSelected: 0,
            verImpuesto: false,
            TotalBrutoDescuento:0
        }
        this.TraerSucursales();
    }
    
    Estilos = {
        FondoDefault: {
            'backgroundColor': '#ecf0f5',
            'heigth': '100%',
        },
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '100%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '250px',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '100%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        BordesCard:
        {
            'borderRadius': '25px'
        },
        TarjetaEstadisticas2: {
            'width': '250px',
            'backgroundColor': 'white',
            'height': '350px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '50px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '40px',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '100%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            'textAlign': 'left'
        },
        TdStyleValues: {
            'textAlign': 'center'
        },
        AnchoBox: {
            'width': '350px'
        },
        AnchoBoxGraf: {
            'width': '100%',
            'borderRadius': '25px'

        },
        AvgStyle:
        {
            'color': 'red'
        },
        AvgStylePlus:
        {
            'color': 'Green'
        },
        myCustomScrollbar: {
            'position': 'relative',
            'height': '300px',
            'overflow': 'auto',
            'display': 'block'
        },
        myCustomScrollbar2: {
            'position': 'relative',
            'height': '500px',
            'overflow': 'auto',
            'display': 'block'
        },
        CardSizeRanking:
        {
            'width': '480px',
            'height': '700px',
            'borderRadius': '25px'
        },
        headerColor:
        {
            'background-color': 'rgb(222, 222, 222)'
        },
        TableHeaderFijo: {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)'
        },
        MarcarBorde:
        {
            'fontColor': '#fff',
            'height': '40px'
        },
        ThStyle:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)'
        },    
        TitlethStyle:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': '#fff',
            'text-align':'center'
        },
        BtnExcel:
        {
            'width': '50px',
            'height': '50px',
            'border-radius': '8px',
            'background': '#6e9887',
            'color': '#fff'
        }
    }

    async TraerSucursales() {
        var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/GetSucursales', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ SucursalesOpt: datos });
        }
    }

    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('fechaFiltro').value = today;
        document.getElementById('fechaFiltroHasta').value = today;
        document.getElementById('fechaini').value = today;
        document.getElementById('fechafin').value = today;
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

    componentDidMount() {
        var user = GetUserCookie();
        if (user.tipoUsuario.id != 1) {
            Logout();
            this.Redirigir('/');
        } else {
            this.cargarFechas();
            this.TraerTotalVentasPorFecha()
            const width = window.screen.availWidth;

            const height = window.screen.availHeight;

            if (width < 1000) {

                this.HacerResponsive();

            }
        }
    }



    HacerResponsive() {

        var elements = Array.from(document.getElementsByTagName("*"));
        elements.forEach((item) => {
            item.style.width = '100%';
        });

    }
    async TraerTotalVentasPorFecha() {
        try {
            this.setState({cargadatos:true});
            var data = {};
            var sucID = document.getElementById('SucId').value;
            document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;
            document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
            data.fechaIni = document.getElementById('fechaFiltro').value;
            data.TipoComparativo = "Semana";
            data.fechaFin = document.getElementById('fechaFiltroHasta').value;
            data.Sucursal = sucID;
            var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/PostResumenVenta', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                throw { mensaje: 'problema al traer las ventas' };

            } else { 
                var datos = await respuesta.json();
                var descuentoAplicado = datos.totSucursal.descuentosTotales;
                var totalGrupo = datos.totCat.precioBrutoTotal;
                var totalBrutoDescuento = totalGrupo + descuentoAplicado;
                console.log(descuentoAplicado);
                console.log(totalGrupo);
                this.setState({
                    Sucursales: datos.sucursal,
                    Categorias: datos.categorias,
                    Descuentos: datos.descuentos,
                    Impuestos: datos.impuesto,
                    Pagos: datos.pagos,
                    Transacciones: datos.transacciones,
                    CategoriasTotales: datos.totCat,
                    DescuentosTotales: datos.totDsctos,
                    PagosTotales: datos.totPagos,
                    TransaccionesTotales: datos.totTranssac,
                    SucTotales: datos.totSucursal,
                    TotalBrutoDescuento: totalBrutoDescuento,
                    VerTabla: true, cargadatos: false, verImpuesto:datos.verImpuesto
                })
            console.log(datos);
            }
        } catch (err) {
            swal('error en TraerTotalVentasPorFecha ' + err);
        }
    }
    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;

    }

    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }
    ExportarExcel() {
        //Creamos un Elemento Temporal en forma de enlace
        var tmpElemento = document.createElement('a');
        // obtenemos la información desde el div que lo contiene en el html
        // Obtenemos la información de la tabla
        var data_type = 'data:application/vnd.ms-excel';
        var tabla_div = document.getElementsByName('SucTable');
        var tabla_html = "\n";
        tabla_div.forEach(function (n)
        {
            tabla_html = tabla_html+ n.outerHTML.replace(/ /g, '%20') + "\n";
        })
        //var tabla_html = tabla_div.outerHTML.replace(/ /g, '%20');
        tmpElemento.href = data_type + ', ' + tabla_html;
        //Asignamos el nombre a nuestro EXCEL
        tmpElemento.download = 'ResumendeVenta.xls';
        // Simulamos el click al elemento creado para descargarlo
        tmpElemento.click();
    }



    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <div>
                    <table style={this.Estilos.FullAncho} >
                        <thead>
                            <th colSpan="3">Resumen de Ventas</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                            </tr>
                            <tr>
                                <td>
                                    <select id="SucId" className="form-control">
                                        <option value="0">Todas</option>
                                        {
                                            this.state.SucursalesOpt.map((item, index) => {
                                                return (<option value={item.id}>{item.descripcion}</option>);
                                            })
                                        }
                                    </select>
                                </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde()}} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta()}} className="form-control" type="date" />
                                </td>
                            </tr>
                            <tr></tr>
                        </tbody>
                    </table>
                </div>



            <hr />
            <div className="row">
                <div className="col-lg-5">
                    <input type="text" id="fechaini"  hidden ></input>
                        <input type="text" id="fechafin" hidden></input>
                </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.TraerTotalVentasPorFecha() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br/>
                <div>
                    <center>
                    {
                        (this.state.cargadatos) ? (
                            <div style={this.Estilos.FondoDefault}>
                                <center>
                                    <div className="spinner-border text-dark" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                    <p> Cargando...</p>
                                </center>
                            </div>
                        ) : (null)
                    }
                </center></div>
                <br />

                <div style={this.Estilos.Bordes}>
                    {
                        (this.state.VerTabla) ? (
                            <div className="container" style={this.Estilos.FullAncho} >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                                <div className="card-body">
                                                   
                                                <div className="card-title text-left"> <button className="btn" style={this.Estilos.BtnExcel} onClick={() => { this.ExportarExcel() }}><i class="fa fa-file-excel-o fa-2x" aria-hidden="true"></i></button></div>
                                                <table border="0" className="table table-responsive" id="SucTable" name="SucTable" > 
                                                    <thead>
                                                        <tr><th colSpan="10" style={this.Estilos.TitlethStyle}> Sucursales</th></tr>
                                                            <tr>
                                                            <th style={this.Estilos.ThStyle}>Sucursal</th>
                                                            <th style={this.Estilos.ThStyle}>Transact</th>
                                                            <th style={this.Estilos.ThStyle}>Clientes</th>
                                                            <th style={this.Estilos.ThStyle}>Productos</th>
                                                            <th style={this.Estilos.ThStyle}>Descuentos</th>
                                                            <th style={this.Estilos.ThStyle}>Cancel</th>
                                                            <th style={this.Estilos.ThStyle}>Ventas Bruto</th>
                                                            <th style={this.Estilos.ThStyle}>Ventas Neto</th>
                                                            <th style={this.Estilos.ThStyle}>Avg Transav</th>
                                                            <th style={this.Estilos.ThStyle}>Avg Clientes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.Sucursales.map((item, i) => {

                                                                return(
                                                                    <tr>
                                                                        <td className="text-rigth">{item.sucursal}</td>
                                                                        <td className="text-rigth">{item.transacciones}</td>
                                                                        <td className="text-rigth">{item.clientes}</td>
                                                                        <td className="text-rigth">{GetPrecioFormateado(item.productos)}</td>
                                                                        <td className="text-rigth">{GetPrecioFormateado(item.descuentos)}</td>
                                                                        <td className="text-rigth">{GetPrecioFormateado(item.cancelados)}</td>
                                                                        <td className="text-rigth">{GetPrecioFormateado(item.ventasBruto)}</td>
                                                                        <td className="text-rigth">{GetPrecioFormateado(item.ventasNeto)}</td>
                                                                        
                                                                        <td className="text-rigth">{GetPrecioFormateado(item.avgTransacciones)}</td>
                                                                        <td className="text-rigth">{GetPrecioFormateado(item.avgClientes)}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                        <td><b>Totales</b></td>
                                                        <td><b>{this.state.SucTotales.transaccionesTotales}</b></td>
                                                        <td><b>{this.state.SucTotales.clientesTotales}</b></td>
                                                        <td><b>{GetPrecioFormateado(this.state.SucTotales.productosTotales)}</b></td>
                                                        <td><b>{GetPrecioFormateado(this.state.SucTotales.descuentosTotales)}</b></td>
                                                        <td><b>{GetPrecioFormateado(this.state.SucTotales.canceladosTotales)}</b></td>
                                                        <td><b>{GetPrecioFormateado(this.state.SucTotales.ventasBrutoTotales)}</b></td>
                                                        <td><b>{GetPrecioFormateado(this.state.SucTotales.ventasNetoTotales)}</b></td>
                                                        <td><b>{GetPrecioFormateado(this.state.SucTotales.avgTransaccionesTotales)}</b></td>
                                                        <td><b>{GetPrecioFormateado(this.state.SucTotales.avgClientesTotales)}</b></td>
                                                    </tfoot>
                                                    <tr border="0"></tr>
                                                    <tr border="0"></tr>


                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                            <div className="card-body">
                                                
                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                    <table border="0" className="table  table-hover" id="SucTable" name="SucTable" >
                                                        <thead>
                                                            <tr><th colSpan="12" style={this.Estilos.TitlethStyle}>Grupos</th></tr>
                                                                <tr>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Grupo</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio Bruto</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio Neto</th>
                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.Categorias.map((item, i) => {

                                                                        return (<tr>
                                                                            <td className="text-rigth" colSpan="3">{item.categoriaName}</td>
                                                                            <td className="text-rigth" colSpan="3">{item.cantidad}</td>
                                                                            <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.valorTotal)}</td>
                                                                            <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.precioNeto)}</td>
                                                                            


                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                            <td colSpan="3"><b>Totales</b></td>
                                                            <td colSpan="3"><b>{this.state.CategoriasTotales.cantidadTotal}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.CategoriasTotales.precioBrutoTotal)}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.CategoriasTotales.precioNetoTotal)}</b></td>
                                                           </tr>
                                                            <tr>
                                                                <td colSpan="3"><b>Total Bruto Con Descuento</b></td>
                                                                <td colSpan="3"><b></b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.TotalBrutoDescuento)}</b></td>
                                                                <td colSpan="3"><b></b></td>
                                                            </tr>
                                                        </tfoot>
                                                        <tr border="0"></tr>
                                                        <tr border="0"></tr>


                                                        </table>
                                                    </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                            <div className="card-body">
                                                
                                                <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                    <table border="0"  className="table  table-hover" id="SucTable" name="SucTable" >
                                                        <thead>
                                                            <tr><th colSpan="12" style={this.Estilos.TitlethStyle}>Descuentos</th></tr>
                                                            <tr>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Descuento</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio Bruto</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Precio Neto</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.Descuentos.map((item, i) => {

                                                                    return (<tr>
                                                                        <td className="text-rigth" colSpan="3">{item.tipoDescuento}</td>
                                                                        <td className="text-rigth" colSpan="3">{item.cantidad}</td>
                                                                        <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.precioBruto)}</td>
                                                                        <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.precioNeto)}</td>
                                                                        


                                                                    </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                            <td colSpan="3"><b>Totales</b></td>
                                                            <td colSpan="3"><b>{this.state.DescuentosTotales.cantidadTotales}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.DescuentosTotales.precioBrutoTotales)}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.DescuentosTotales.precioNetoTotales)}</b></td>
                                                            </tr>
                                                            
                                                        </tfoot>
                                                        <tr border="0"></tr>
                                                        <tr border="0"></tr>


                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(this.state.verImpuesto) ? (<br />) : (null)}
                                {(this.state.verImpuesto) ? (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                                <div className="card-body">

                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                        <table border="0" className="table  table-hover" id="SucTable" name="SucTable">
                                                            <thead>
                                                                <tr><th colSpan="12" style={this.Estilos.TitlethStyle}>Impuestos</th></tr>

                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Impuesto</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Importe</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Bruto</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Neto</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-rigth" colSpan="3">IVA</td>
                                                                    <td className="text-rigth" colSpan="3">{GetPrecioFormateado(this.state.Impuestos.ivaImporte)}</td>
                                                                    <td className="text-rigth" colSpan="3">{GetPrecioFormateado(this.state.Impuestos.bruto)}</td>
                                                                    <td className="text-rigth" colSpan="3">{GetPrecioFormateado(this.state.Impuestos.neto)}</td>



                                                                </tr>

                                                            </tbody>
                                                            <tfoot>
                                                                <td colSpan="3"><b>Totales</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.Impuestos.ivaImporte)}</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.Impuestos.bruto)}</b></td>
                                                                <td colSpan="3"><b>{GetPrecioFormateado(this.state.Impuestos.neto)}</b></td>


                                                            </tfoot>
                                                            <tr border="0"></tr>
                                                            <tr border="0"></tr>


                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ): (null)}

                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                            <div className="card-body">
                                                
                                                <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                    <table border="0"  className="table  table-hover" id="SucTable" name="SucTable" >
                                                        <thead>
                                                            <tr><th colSpan="21" style={this.Estilos.TitlethStyle}>Transacciones</th></tr>

                                                            <tr>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Tipo</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Transact</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Clientes</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Ventas Bruto</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Ventas Neto</th>
                                                               
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">AVG Transact</th>
                                                                <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">AVG Clientes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.Transacciones.map((item, i) => {

                                                                    return (<tr>
                                                                        <td className="text-rigth" colSpan="3">{item.tipoDoc}</td>
                                                                        <td className="text-rigth" colSpan="3">{item.cantidadTransac}</td>
                                                                        <td className="text-rigth" colSpan="3">{item.cantidadClientes}</td>
                                                                        <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.ventasBruto)}</td>
                                                                        <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.ventasNeto)}</td>
                                                                        <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.avgTransac)}</td>
                                                                        <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.avgClientes)}</td>
                                                                    </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <td colSpan="3"><b>Totales</b></td>
                                                            <td colSpan="3"><b>{this.state.TransaccionesTotales.cantidadTransacTotales}</b></td>
                                                            <td colSpan="3"><b>{this.state.TransaccionesTotales.cantidadClientesTotales}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.TransaccionesTotales.ventasBrutoTotales)}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.TransaccionesTotales.ventasNetoTotales)}</b></td>
                                                            
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.TransaccionesTotales.avgTransacTotales)}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.TransaccionesTotales.avgClientesTotales)}</b></td>

                                                        </tfoot>
                                                        <tr border="0"></tr>
                                                        <tr border="0"></tr>


                                                    </table>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card" style={this.Estilos.AnchoBoxGraf}>
                                            <div className="card-body">
                                                

                                                    <div className="table table-responsive" style={this.Estilos.myCustomScrollbar}>
                                                    <table border="0" className="table  table-hover" id="SucTable" name="SucTable">
                                                        <thead>
                                                            <tr><th colSpan="18" style={this.Estilos.TitlethStyle}>Pagos</th></tr>

                                                                <tr>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Tipo</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cantidad</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Débito</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Crédito</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Cobrado</th>
                                                                    <th scope="col" style={this.Estilos.TableHeaderFijo} colSpan="3">Propina</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.Pagos.map((item, i) => {

                                                                        return (<tr>
                                                                            <td className="text-rigth" colSpan="3">{item.tipoPago}</td>
                                                                            <td className="text-rigth" colSpan="3">{item.cantidad}</td>
                                                                            <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.debito)}</td>
                                                                            <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.credito)}</td>
                                                                            <td className="text-rigth" colSpan="3">{GetPrecioFormateado(item.cobrado)}</td>
                                                                        < td className ="text-rigth" colSpan="3">{GetPrecioFormateado(item.propina)}</td>
                                                                        </tr>
                                                                        );
                                                                    })
                                                                }
                                                        </tbody>
                                                        <tfoot>
                                                            <td colSpan="3"><b>Totales</b></td>
                                                            <td colSpan="3"><b>{this.state.PagosTotales.cantidadTotales}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.PagosTotales.debitoTotales)}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.PagosTotales.creditoTotales)}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.PagosTotales.cobradoTotales)}</b></td>
                                                            <td colSpan="3"><b>{GetPrecioFormateado(this.state.PagosTotales.propinaTotales)}</b></td>
                                                           
                                                        </tfoot>
                                                        <tr border ="0"></tr>
                                                        <tr border ="0"></tr>

                                                        </table>
                                                    </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>

                               
                                </div>
                            ): (null)}</div> 
            </div>

        );
    }

}