import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { PopupMotivoAnulacion } from './PopupMotivoAnulacion';
import { GetUrlApi } from './Globales/VariableGlobales';
import Swal from "sweetalert2";

export class CancelarProd extends Component {
    static displayName = CancelarProd.name;


    constructor(props) {
        super(props);
        this.state = {
            activarModif: false,
            activarPopAnular:false,
        }
    }

    componentDidMount() {
        console.log(this.props.productos);
    }

    AbrirModificadores(indiceSeleccionado) {
        this.props.ActualizarEstado({ activarModif: true, activarCancelar: false, parametros: { indiceInicio: indiceSeleccionado}});
    }

    ClickMas(indiceProd) {
        const productos = this.props.productos;
        //console.log(productos);
        //console.log('el ultimo elemento es ');
        //console.log(productos);
        let isRegalo =  productos[indiceProd].IsUsoRegalo;
        if (isRegalo>0){
            Swal.fire({
                title: "La cantidad del producto regalo no puede ser mayor a 1.",
                icon: "error"
            });
            return;
        }
        productos[indiceProd].cantidad = Number(productos[indiceProd].cantidad) + 1;
        var orden = this.props.orden;
        orden.carroProductos = productos;
        this.props.ActualizarEstado({ carroProductos: productos, orden: orden });
    }

    ClickMenos(indiceProd) {
        const productos = this.props.productos;
        const cantidad = productos[indiceProd].cantidad;
        if (cantidad > 1) {
            productos[indiceProd].cantidad = cantidad - 1;
            this.props.ActualizarEstado({ carroProductos: productos });
        }
    }
    

    //--------ESTILOS

    Estilos = {
        FullAncho: {
            'width': '50%'
        },
        MitadAncho: {
            'width': '50%'
        },
        TercioAncho: {
            'width': '33%'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '85%'
        },
        EstiloPopup: {
            'width': '64%',
            'height': '60%',
            'border-radius': '2%',
            'marginLeft': '12%'
        },
        
    }
    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivoAnulacions', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ Motivos: datos });
        }
        else {
            alert('error al traer las mesas');
        }
    }

    //--------ESTILOS
    
    

    render() {
        return (
            <Popup
                contentStyle={this.Estilos.EstiloPopup}
                modal
                defaultOpen
                onClose={() => { this.props.CerrarModalMaster() }}
                closeOnDocumentClick>
                <div style={this.Estilos.Escroleable}>
                    {
                        (this.state.activarPopAnular) ? (
                            <PopupMotivoAnulacion DesactivarModales={this.DesactivarModales}
                                Orden={this.state.orden}
                                ClickBorrarOrden={this.ClickBorrarOrden}
                            />
                        ) : (null)
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Descripcion</th>
                                <th scope="col" hidden="hidden">Aumentar</th>
                                <th scope="col" hidden="hidden">Disminuir</th>
                                <th scope="col" hidden="hidden">Mensaje Cocina/Bar</th>
                                <th scope="col" hidden="hidden">Anular</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.props.productos.map((item, i) => {
                                    return <tr key={i}>
                                        <td>{item.cantidad}</td>
                                        <td>{item.desprod} </td>
                                        <td>
                                            <center><a onClick={(e) => { e.preventDefault(); this.ClickMas(i) }} href>
                                            <i className="fa fa-plus-circle fa-2x text-dark" aria-hidden="true"></i>
                                            </a> </center></td>
                                        <td>
                                            <center>
                                                <a onClick={(e) => { e.preventDefault(); this.ClickMenos(i) }} href>
                                                    <i className="fa fa-minus-circle fa-2x text-danger" aria-hidden="true"></i>
                                            </a> </center></td>
                                        <td  hidden="hidden">
                                            <center>  <a onClick={(e) => { e.preventDefault(); this.AbrirModificadores(i) }} href=""><i className="fa fa-commenting-o fa-2x" aria-hidden="true"></i></a> </center>
                                        </td>
                                        <td>
                                            <center> <a onClick={(e) => { e.preventDefault(); this.props.DesactivarModales(); this.props.ClickEliminar(i, this.props.productos, 8000) }} href="">
                                                <i className="fa fa-trash-o fa-2x text-danger" aria-hidden="true"></i>
                                            </a>  </center></td>
                                    </tr>
                                })
                            }
                        </tbody>

                    </table>

                </div>
                <center>
                    <button style={this.Estilos.FullAncho} className="btn btn-success" onClick={() => { this.props.CerrarModalMaster() }}> <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>    </center>
            </Popup>
        );
    }
}
