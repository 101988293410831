import React, { Component } from 'react'
import { GetUrlApi } from './Globales/VariableGlobales'
import Popup from "reactjs-popup"
import Swal from 'sweetalert2'
import { Row } from 'reactstrap'
import { QRHten } from './QRHten'


export class ProcesarDevolucion extends Component {
    static displayName = ProcesarDevolucion.name
    constructor(props) {
        super(props)
        this.state = {
            pagos: [],
            isQR: false,
            medioSelected: {},
            indexSelected: -1,
            htenResult: {},
            qrSignal: {},
            qrController: {},
            visibleFinishBtn: false,
            isProcessing: false,
            isFinishing: false,
            codigoAutorizacion: ''
        }
        if (this.props.finalizarVenta)
            this.finalizarVenta = this.props.finalizarVenta
        
        // this.finalizar = this.finalizarVenta.bind(this);
        // this.setState({ pagos: this.props.pagos })
        //if (this.props.CerrarModal)
         //   this.CerrarModalMaster = this.props.CerrarModalMaster
         

    }

    componentDidMount() { 
        console.log('Mounted')
        this.setState({ pagos: this.props.pagos })
        if(this.props.pagos.length === 1) {
            document.getElementById("btnCancel").disabled = true
            this.ClickPagar(this.props.pagos[0], 0)
        }
    }

    render() {
        return (
            
            <Popup
                onClose={() => { this.clickBtnBack() }}
                contentStyle={this.Estilos.PopUpStyle}
                defaultOpen
                modal
                closeOnDocumentClick>
                    <p class='text-center h3'><strong>Procesando Devoluciones con TBK</strong></p>
                    <center>
                        
                    </center>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g==" crossOrigin="anonymous" referrerpolicy="no-referrer" />
                    <div class='container'>
                        <table className='table table-sm'>
                            <thead>
                                <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>Medio de Pago</th>
                                    <th scope='col'>Monto</th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody>
                            {( 
                            
                                this.state.pagos.map((pago, i) => {
                                    return (
                                        <tr>
                                            <th scope='row'>{ i + 1 }</th>
                                            <td>{ pago.descripcion }</td>
                                            <td>{ this.GetPrecioFormateado(pago.montoProp) }</td>
                                            <td>{ pago.processed? 
                                            (<span class='btn btn-success'><i class="fa-solid fa-check fa-lg"></i></span>)
                                            :
                                            (this.isProcessing(pago, i))?<div className="spinner-border text-dark" role="status"><span className="sr-only"></span></div>:<button type='button' class='btn btn-info' onClick={() => {this.ClickPagar(pago, i)}}> <i class="fa-solid fa-hand-holding-dollar fa-lg"></i></button> } </td>
                                        </tr>
                                    )
                                })
                            )}
                            </tbody>
                        </table>
                        <div class='row justify-content-md-center'>
                            { this.state.visibleFinishBtn? 
                                (!this.state.isFinishing)?
                                    (<button type='button' class='btn btn-primary' onClick={ () => { this.clickBtnFinish() } }><i class="fa fa-check"></i> Finalizar</button>): 
                                    (<div class='container'><div class='row justify-content-md-center'><div className="spinner-border text-dark" role="status"><span className="sr-only"></span></div> Finalizando...</div></div>)
                                :(null) }
                        </div>
                        <div class='row justify-content-md-center'>
                            { this.state.visibleFinishBtn?(null):<button id='btnCancel' type='button' class='btn btn-secondary' onClick={ () => { this.clickBtnBack() } }><i class="fa-solid fa-arrow-left"></i> Volver </button> }
                        </div>
                    </div>
                    { (this.state.isQR)? 
                        (<QRHten
                            medio={ this.state.medioSelected }
                            htenResponse= { this.state.htenResponse }
                            qrCancel= { this.qrCancel.bind(this) } 
                        >

                        </QRHten>):(null)
                        }
            </Popup>
        );
    }

    async ClickPagar(MedioPago, index) {
        // swal('Procesando Pago');
        // const pago = this.state.pagos.filter(pago => pago.id === MedioPago.id)
        // pago.map((localpago) => {
        //     localpago.processed = true
        // })
        console.log(MedioPago)
        document.getElementById("btnCancel").disabled = true
        this.setState({ medioSelected: MedioPago, indexSelected: index, isProcessing: true })
        const procesado = await this.fncProcesarPago(MedioPago)
        if(procesado) {
            await this.state.pagos.map((pago, i) => {
                if(pago.idMedio === MedioPago.idMedio && i === index)
                    pago.processed = true
                    
            })
            this.setState({ pagos: this.state.pagos })   
            this.checkFinishButton() 
        }
        document.getElementById("btnCancel").disabled = false
        this.setState({ isProcessing: false })
    }
    isProcessing(medio, index){
        const result = (medio.idMedio === this.state.medioSelected.idMedio && this.state.isProcessing && this.state.indexSelected === index)?true:false
        return result
    }

    cerrarModal() { }

    CerrarModal2() { }

    CerrarModalMaster() { }

    Estilos = {
        PopUpStyle:
        {
            'width': '65%',
            'height': '65%',
            'background-color': '#ecf0f5',
            'border-radius': '25px',
        },
    }

    async fncProcesarPago(m) {
        let retorno = false
        let hasDebit = false
        const ipHten = localStorage.getItem('ipHten')
        let imprimeHTEN = false
        const userData = JSON.parse(sessionStorage.getItem('userProfile'))
        const htenResponse = JSON.parse(m.numTarjeta)
        this.setState({codigoAutorizacion: htenResponse.codigoAutorizacion})
        if (Object.keys(htenResponse).length == 0) {
            return
        }

        let myBody = {}
        let isqr = false
        let payment = { id: 0 }
        if(htenResponse.entity === 'BCI') {
            payment = htenResponse.payment
            myBody = {
                    "amount": payment.amount,
                    "employeeNumber": 12345678,
                    "posTransactionId": "ABCD-1234-5678",
                    "entity": "BCI"
                }
            isqr = true
        }
        else {
        
            if (htenResponse.tipoTarjeta === 'DB' || htenResponse.tipoTarjeta === 'DEBITO')
                hasDebit = true;
            
            myBody.numeroEmpleado = userData.id + "";
            myBody.montoAnulacion = m.montoProp + ''; // htenResponse.monto + "";
            myBody.montoPropina = "-1";
            myBody.montoVuelto = "-1";
            myBody.codigoMoneda = "CL";
            myBody.numeroBoleta = this.props.orderId;
            myBody.tipoTarjeta = (htenResponse.tipoTarjeta==='CREDITO')?'CR':htenResponse.tipoTarjeta;
            myBody.numeroUnicoTransaccionOriginal = htenResponse.numeroUnicoTransaccion + "";
        }
        // if (localStorage.getItem('htenDemo') != null && localStorage.getItem('htenDemo') == "SI") {
        //     myBody.ultimos4 = "demo";
        // }
        // if(hasDebit)
        //    break;
        if(!hasDebit){
            // if(m.AnulacionHten){
            const response = await this.callToHten(myBody, ipHten, isqr, payment.id)
            if (response !== "ERROR") {
                imprimeHTEN = true;
                m.numTarjeta = response;
                console.log("Fin de anulacion ");
                retorno = true
            }
            //}
        }
        

        
            
        return retorno
    }
    isHten() {
        return JSON.parse(sessionStorage.getItem('userProfile')).Sucursal.hten;
    }
    async callToHten(myBody, ipHten, isQr, idQr) {
        const endpoint = isQr? 'wallet/payment/' + idQr + '/refund':'anularCompra'
        return await new Promise(async (resolve) => {
              
            await fetch('http://' + ipHten + '/api/' + endpoint, {
                method: 'post',
                dataType: 'jsonp',
                async: false,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(respuesta => {
                console.log("Respuesta Anul ", respuesta);
                return respuesta.json();
            }).then(async (data) => {
                console.log(data);
                this.setState({ cargando: false });
                if (await this.processResponse(data)) {
                    Swal.fire({
                        title: '<strong>' + isQr?'Anulación Autorizada': (this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta) + '</strong>',
                        text: 'El pago ha sido anulado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'success',
                    }).then(() => {
                        resolve(this.state.htenJsonResponse);
                    });
                } else {
                    Swal.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRta + '</strong>',
                        text: 'La anulación NO ha sido autorizada - Se hace devolución forzada',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    }).then(() => {
                        resolve("ERROR");
                    });
                }
            }).catch((reason) => {
                console.log(reason);
                this.setState({ cargando: false });
                Swal.fire({
                    title: '<strong>ERROR</strong>',
                    text: 'No logramos conectar con HTEN ' + reason + ' - Llame a soporte técnico',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'error',
                }).then(() => {
                    resolve("ERROR");
                });
            });
        });   

    }
    processResponse(data, endpoint) {
        console.log("llega rta de HTEN ", data)
        let jsonObj = {}
        if (data.constructor === ({}).constructor) {
            jsonObj = data;
        } else {
            jsonObj = JSON.parse(data);
        }
        let dataFinal = jsonObj;
        if (data.constructor === ({}).constructor) {
            console.log("is_object")
            if (endpoint === "pagoFpay") {
                let fpayObj = {};
                try {
                    fpayObj = JSON.parse(data.glosaRespuesta);
                } catch (e) {
                    const codRta = parseInt(data.codigoRespuesta);
                    if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                        this.setState({ htenApproved: true })
                        return true;
                    } else {
                        this.setState({ htenApproved: false })
                        return false;
                    }
                }
                data.voucher = fpayObj.gateway.voucher.join("\n");
                data.payload = data.glosaRespuesta;
                data.glosaRespuesta = fpayObj.state;
            }
            if(dataFinal.entity === 'BCI') {
                const refunded = dataFinal.refundedPayment
    
                dataFinal.glosaRespuesta = dataFinal.statusDescription
                dataFinal.codigoRespuesta = dataFinal.statusCode
                dataFinal.monto = refunded.amount
                // dataFinal.voucher = dataFinal.entityResponse
            }
            dataFinal.codigoAutorizacion = this.state.codigoAutorizacion
            this.setState({ htenJsonResponse: data });
            this.setState({ htenResponse: dataFinal });
            const codRta = dataFinal.codigoRespuesta? parseInt(dataFinal.codigoRespuesta):dataFinal.statusCode
            if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
                this.setState({ htenApproved: true })
                return true;
            } else {
                this.setState({ htenApproved: false })
                return false;
            }
        }
        // const jsonObj = JSON.parse(data);
        this.setState({ htenResponse: JSON.stringify(jsonObj) });
        this.setState({ htenJsonResponse: jsonObj });
        const codRta = parseInt(jsonObj.codigoRespuesta);
        if ((codRta >= 0 && codRta <= 9) || codRta === 500000) {
            this.setState({ htenApproved: true })
            return true;
        } else {
            this.setState({ htenApproved: false })
            return false;
        }
    }
    clickBtnFinish() {
        this.setState({ isFinishing: true })
        this.props.finalizarVenta(this.state.pagos)
    }
    clickBtnBack() {
        this.props.cerrarModal(this.state.pagos)
    }
    finalizarVenta() {

    }
    async checkFinishButton() {
        let isAllProcessed = true
        await this.state.pagos.map(item => {
            if(!item.processed) {
                isAllProcessed = false
            }
        })
        this.setState({ visibleFinishBtn: isAllProcessed })

        if(isAllProcessed && this.state.pagos.length === 1) {
            this.clickBtnFinish()
        }
    }
    GetPrecioFormateado(precio) {
        try {
            return '$' + precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } catch (err) {
            return '$ 0';
        }
    }

    async qrCancel() {
        const controller = this.state.qrController
        const data = this.state.htenResponse
        controller.abort()
        await this.cancelQrStatus(data.payment.id, data.entity)
        this.setState({ isQR: false })
    }
    async getStatusQr(id, entity){
        const endpoint = 'wallet/payment/' + id + '?entity=' + entity
        let retorno = false
        const ipHten = localStorage.getItem('ipHten')
        // this.setState({ qrController: controller, qrSignal: signal })

        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'get',
            dataType: 'jsonp',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(respuesta => respuesta.json()).then(async (data) => {
            console.log(data, 'retry statusqr')
            const statusResult = data.payment?data.payment.status:'NOT FOUND'
            if(statusResult === 'APPROVED')
                retorno = true
            else
                retorno = false
        })

        

        return retorno

    }
    async qrStatus(id, entity) {
        let ret = false
        const controller = new AbortController()
        const signal = controller.signal
        const ipHten = localStorage.getItem('ipHten')
        const endpoint = 'wallet/payment/' + id + '?entity=' + entity
        this.setState({ qrController: controller, qrSignal: signal })

        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'get',
            dataType: 'jsonp',
            signal: this.state.qrSignal,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(respuesta => respuesta.json()).then(async (data) => {
            console.log('qrStatus data', data)
            return data
        })
        if(resul.statusCode === -1) {
            const retryStatus = await this.getStatusQr(id, entity)
            if(!retryStatus){
                this.cancelQrStatus(id, entity)
                await Swal.fire({
                    title: '<strong>' + resul.statusCode + '-' + resul.statusDescription + '</strong>',
                    text: 'Ocurrió un error, verifique si se hizo cobro en su cuenta',
                    footer: 'ID Transacción:<strong> ' + id + '</strong>',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'warning',
                }).then(async () => { })
            }
            else{
                ret = true
                Swal.fire({
                    title: '<strong> Autorizado </strong>',
                    text: 'El pago ha sido autorizado',
                    footer: 'ID Transacción:<strong> ' + id + '</strong>',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'success',
                }).then(() => { })
            }
            
        }
        else if(resul.payment.status === 'APPROVED'){
            ret = true
            this.setState({ htenJsonResponse: resul });
            Swal.fire({
                title: '<strong> Autorizado </strong>',
                text: 'El pago ha sido autorizado',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'success',
            }).then(() => { })
        } else if(resul.payment.status === 'REJECTED') {
            Swal.fire({
                title: '<strong>No Autorizado</strong>',
                text: 'El pago NO ha sido autorizado',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'warning',
            })
        } else if(resul.payment.status === 'ERROR') {
            Swal.fire({
                title: '<strong>Error</strong>',
                text: 'Ocurrió un error, verifique si se hizo cobro en su cuenta',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'warning',
            });
        } else {
            Swal.fire({
                title: '<strong>Problemas con el pago</strong>',
                text: 'Ocurrió un problema con el pago, verifique si se le hizo cobro',
                footer: 'ID Transacción:<strong> ' + id + '</strong>',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'warning',
            });
        }
        this.deleteQr(id)
        this.setState({ isQR: false })
        document.getElementById("btnCancel").disabled = false
        return ret
    }

    async cancelQrStatus(id, entity) {
        let ret = false
        const ipHten = localStorage.getItem('ipHten')
        const endpoint = 'wallet/payment/' + id + '/cancel'
        const body = { entity: entity }
        document.getElementById("btnCancel").disabled = false
        const resul = await fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'post',
            dataType: 'jsonp',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then(respuesta => respuesta.json()).then(async (data) => {
            return data
        })
        this.setState({ isProcessing: false })
        this.deleteQr(id)
        return ret
    }
    async registerQr(id, content){
        let ret = false
        const qrContent = { transactionId: id, content: content }
        const respuesta =await fetch(GetUrlApi() + '/api/orden/registerPrintQR', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:  JSON.stringify(qrContent)
        })
        return ret
    }
    async deleteQr(id){
        let ret = false
        const qrContent = { transactionId: id, content: '' }
        const respuesta =await fetch(GetUrlApi() + '/api/orden/registerPrintQRDelete', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:  JSON.stringify(qrContent)
        })
        return ret
    }
    async checkPagos(){
        this.state.pagos.map((pago, i) => {
            switch (pago.medioEquivalente) {
                case "CANJEPUNTOS":
                    this.ClickPagar(pago)
                    break
            }
        })
    }
}
