import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Arqueo } from './Arqueo';
import { ArqueoCiego } from './ArqueoCiego';
import swal from 'sweetalert';
import { Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import Swal2 from 'sweetalert2'

export class SalidaCajero extends Component {
    static displayName = SalidaCajero.name;
    IdGavetaActual = 0;
    NumCaja = GetUserCookie().CajaID;
    UltimaAperturaCaja = {};
    constructor() {
        super();
        this.state = {
            activarArqueo: false,
            activarCierreCiego: false,
            activarSalidaRapida: false,
            activarArqueosPendientes: false,
            volverAlIncio: false,
            mostrarAlertaOrdenesAbiertas: false,
            activarSeleccionarGaveta: false,
            activarNoTieneGaveta: false,
            idGavetaSeleccionada: 0,
            gavetaSeleccionada: {
                usuarios: {}
            },
            //estado inicial botones
            arqueoDisabled: true,
            arqueoCiegoDisabled: true,
            //fin estado inicial botones
        }
        //this.TraerCaja();
        this.TraerUltAperturaCaja().then(() => {
            this.TraerGavetas();
        });

    }

    async TraerUltAperturaCaja() {
        var respuesta = await fetch(GetUrlApi() + '/api/AperturaCajas/GetAperturaCaja2?id=' + this.NumCaja + '&userId=' + GetUserCookie().id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            const idUsuarioLogeado = GetUserCookie().id;
            const tipoTipoUser = GetUserCookie().tipoUsuario.id;
            const isPefilPermitido = tipoTipoUser !== 1 || tipoTipoUser !== 2 || tipoTipoUser !== 7;
            if (!isPefilPermitido) {
                Logout();
                this.setState({ volverAlIncio: true });
            }
            else if (tipoTipoUser === 2) {
                this.setState({ activarCierreCiego: true, activarArqueo: false });
            }
            else if (idUsuarioLogeado !== datos.usuariosID && tipoTipoUser !== 1) {
                Logout();
                this.setState({ volverAlIncio: true });
            }
            else {
                this.setState({ activarCierreCiego: false, activarArqueo: false });
            }
            this.setState({ IdGaveta: datos.gavetaID });
            //else if (idUsuarioLogeado !== datos.usuariosID ){

            //}
            //if (idUsuarioLogeado !== datos.usuariosID && !isPefilPermitido) {
            //    Logout();
            //    this.setState({ volverAlIncio: true });
            //}
            //if (tipoTipoUser !== 1 && tipoTipoUser !== 2 && tipoTipoUser !== 7) {
            //    Logout();
            //    this.setState({ volverAlIncio: true });
            //}
            this.UltimaAperturaCaja = datos;
            //this.TraerOrdenesAbiertas();
        }
        else {
            //alert('error en traer ordenes');
            //Logout();
            //this.setState({ volverAlIncio: true });
        }
    }

    Redirigir(url) {
        this.props.history.push(url);
    }

    async TraerOrdenesAbiertas() {
        var respuesta = await fetch(GetUrlApi() + '/api/Orden/GetOrdenesAbiertas?idGaveta=' + this.UltimaAperturaCaja.gavetaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            if (datos.length > 0) {
                swal({
                    title: "Notificacíon",
                    text: "Todavia quedan ordenes abiertas",
                    icon: "warning",
                    dangerMode: true,
                })
            }

            //this.setState({ mostrarAlertaOrdenesAbiertas:true });
        }
        else {
            alert('error');
        }
    }

    async TraerCaja() {
        var respuesta = await fetch(GetUrlApi() + '/api/Cajas/' + this.NumCaja, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.IdGavetaActual = datos.idUltimaGavetaFk;
        }
        else {
            alert('error');
        }
    }


    DesactivarOpciones() {
        this.setState({
            activarArqueo: false,
            activarCierreCiego: false,
            activarSalidaRapida: false
        });
    }
    Estilos = {
        FullAncho: {
            'width': '25%%',
            'heigth': '50px',
            'margin-right': '1%'
        },
        BtnSalir: {
            'height': '44px',
            'width': '20%',
            'border-color': 'rgb(110, 152, 135)',
            'border-radius': '10%'
        },
        MitadAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '16%' },
            col2: { 'width': '50%' }
        },
        BarraNavOpt:
        {
            'width': '100%',
        },
        EstiloPopup: {

        },
        Contenedor: {
            'width': '60%',
            'backgroundColor': 'white',
            'borderRadius': '25px',
            'border': '2px solid #73AD21',
            'padding': '25px'
        },
        BarraLateral: {
            'backgroundColor': 'white',
            'height ': '100%',
            'width': '100%',
            'margin-left': '0%'
        },
        BotonesOpt:
        {
            'height': '55px',
            'margin-right': '2%',
            'width': '140px',
            'background': '#97c1b0',
            'color': 'white'
        },
        PopUpStyle:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '45%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%',
            'height': '53%'
        },
        PopUpStyleNoTieneGaveta:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '45%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '2%',
            'height': '16%'
        },
        MarginTr:
        {
            'margin-bottom': '5%',
            'margin-right': '2%',
            'margin-left': '5%',
            'margin-top': '5%',
            'overflow': 'auto'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid',
            'cursor': 'pointer'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnStyles:
        {
            'width': '120px',
            'height': '100%',
            'background-color': '#769e8e'
        },
        PStyle:
        {
            'margin-bottom': '0px'
        },
        SpanStyle2:
        {
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid',
        },
        MarginGavSelected:
        {
            'margin-left': '2%',
            'margin-right': '1%',
            'border-radius': '8px',
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 5px',
            'margin-bottom': '1%'
        }
    }



    async TraerVentas() {
        try {
            const ventas = await fetch(GetUrlApi() + '/api/ProductosOrden');
            if (!ventas.ok)
                throw { mensaje: 'problema al traer las gavetas' };

            var datos = await ventas.json();
            datos = datos.filter(datos => datos.idCierreCajaFk === null);
            var cont = 0;
            datos.forEach(function (item) {
                cont += item.total;
            });
            await this.setState({ diferencia: cont });

            //console.log(datos);
        } catch (err) {
            console.log(err.mensaje);
        }
    }


    async ClickSalidaRapida() {
        swal({
            title: "Confirme salida rapida",
            text: "Si confirma la gaveta permanecera bloqueada hasta que se realice el arqueo",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("El arqueo de esta gaveta permanecerá pendiente", {
                    icon: "success",
                }).then(async () => {
                    var data = {};
                    data.TotCargo = 0;
                    data.Reembolsos = 0;
                    data.CatTotal = 0;
                    data.CajaID = GetUserCookie().CajaID;
                    data.GavetaID = this.IdGavetaActual;
                    data.diferencia = 0;
                    data.TipoDeArqueo = 2;

                    var respuesta = await fetch(GetUrlApi() + '/api/CierreCajas/SalidaRapida', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });
                    if (respuesta.ok) {
                        console.log('llamada a cierre cajas exitosa');
                    }
                    else {
                        alert('error');
                    }
                    Logout();
                    this.setState({ volverAlIncio: true });
                });
            } else {
                this.setState({ volverAlIncio: true });
            }
        });
    }

    async CerrarPopupGavetas() {
        await this.setState({ activarSeleccionarGaveta: false });
        if (this.state.idGavetaSeleccionada === 0) {
            this.setState({ activarSeleccionarGaveta: true });
        }
    }

    GetHtmlPopupSeleccionarPendiente() {

        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.PopUpStyle}
                onClose={() => { this.CerrarPopupGavetas() }}
                closeOnDocumentClick>

                <center>
                    <h3>Seleccione Gaveta</h3>
                    <table className="row" style={this.Estilos.FullAncho}>
                        <tr style={this.Estilos.MarginTr}>

                            {
                                this.state.gavetas.map((item) => {
                                    return (
                                        <td style={this.Estilos.SpanStyle} rowspan="3" onClick={() => { this.SelectGavetaPendiente(item.id, item) }}>
                                            <button className="btn btn-info" style={this.Estilos.BtnStyles}>
                                                <i className="fa fa-bookmark-o fa-2x"></i>
                                            </button>

                                            <p style={this.Estilos.PStyle}><span style={this.Estilos.SpanStyle}>{item.descripcion}</span></p>
                                            <p style={this.Estilos.PStyle}> <span style={this.Estilos.SpanStyle}>Entrada {new Date(item.fechaUltApertura).toLocaleDateString()}</span> </p>
                                            <p style={this.Estilos.PStyle}> <span style={this.Estilos.SpanStyle}>{new Date(item.fechaUltApertura).toLocaleTimeString()}</span></p>
                                            <p style={this.Estilos.PStyle}> <span style={this.Estilos.SpanStyle2}>{item.usuarios.nombre} </span></p>
                                            <p style={this.Estilos.PStyle}> <span style={this.Estilos.SpanStyle2}>{item.usuarios.apellido}</span></p>

                                        </td>
                                    );
                                })
                            }
                        </tr>
                        <tr >

                            <td rowspan="3" colSpan="2">
                                <div></div>
                            </td>
                        </tr>

                    </table>
                    <button className="btn btn-danger" onClick={() => { Logout(); this.setState({ volverAlIncio: true }) }} data-toggle="tooltip" data-placement="right" title="Volver" style={this.Estilos.BtnSalir}>
                        <i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    GetHtmlNoTieneGaveta() {

        return (
            <Popup
                defaultOpen
                modal
                contentStyle={this.Estilos.PopUpStyleNoTieneGaveta}
                onClose={() => { this.CerrarPopupGavetas() }}
                closeOnDocumentClick>
                <center>
                    <h3>No hay gaveta asignada</h3>
                    <button className="btn btn-danger" onClick={() => { Logout(); this.setState({ volverAlIncio: true }) }} data-toggle="tooltip" data-placement="right" title="Volver" style={this.Estilos.BtnSalir}>
                        <i className="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }
    async TraerGavetas() {
        try {
            const numCaja = GetUserCookie().CajaID;
            const userId = GetUserCookie().id;
            const categorias = await fetch(GetUrlApi() + '/api/Gavetas/GetGavetasPendientes?cajaID=' + numCaja + '&userID=' + userId);
            if (!categorias.ok)
                throw { mensaje: 'problema al traer las gavetas' };

            var datos = await categorias.json();
            if (GetUserCookie().tipoUsuario.id !== 1) {//si no es admin solo muestra las gavetas que abrio el mismo usuario
                datos = datos.filter(p => p.usuarios.id === GetUserCookie().id);
            }

            //await this.setState({ gavetas: datos });
            if (datos.length > 0) {
                const tipoUsuario = GetUserCookie().tipoUsuario.id;

                this.setState({ activarSeleccionarGaveta: true, gavetas: datos });
            } else {
                this.setState({ activarNoTieneGaveta: true });
            }
        } catch (err) {
            console.log(err.mensaje);
        }
    }
    async loadTbkClosing() {
        Swal2.fire({
            title: '<strong>Cierre Transbank</strong>',
            text: 'Favor mantener PINPAD conectado.',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            animation: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            closeOnClickOutside: false,
            icon: 'info',
        }).then((result) => {
                this.callToHten('cierreBatch')
                return;
        })
    }
    processResponse(data) {
        let jsonObj = {}
        if (data.constructor === ({}).constructor) {
            jsonObj = data;
        } else {
            jsonObj = JSON.parse(data);
        }
        let dataFinal = jsonObj;
        const tempJson = jsonObj //dataFinal[Object.keys(dataFinal)[0]];
        if (tempJson.voucherDuplicado) {
            if (typeof tempJson.datosTransaccion.glosaRespuesta === 'undefined' || tempJson.datosTransaccion.glosaRespuesta === null) {
                if (tempJson.datosTransaccion.codigoRespuesta >= 0 && tempJson.datosTransaccion.codigoRespuesta <= 9) {
                    tempJson.datosTransaccion.glosaRespuesta = "APROBADO";
                } else {
                    tempJson.datosTransaccion.GlosaRespuesta = "RECHAZADO";
                }
            }
            dataFinal.glosaRespuesta = tempJson.datosTransaccion.glosaRespuesta;
            dataFinal.codigoRespuesta = tempJson.datosTransaccion.codigoRespuesta;
            dataFinal.monto = tempJson.datosTransaccion.monto;
            dataFinal.voucher = tempJson.voucherDuplicado
        }
        this.setState({ htenResponse: data });
        this.setState({ htenJsonResponse: dataFinal });
        const codRta = parseInt(dataFinal.codigoRespuesta);
        if (codRta >= 0 && codRta <= 9) {
            return true;
        } else {
            return false;
        }
    }
    async callToHten(endpoint) {
        const ipHten = localStorage.getItem('ipHten');
        const userData = JSON.parse(sessionStorage.getItem('userProfile'));
        var myBody = {};
        let title = "Ultima transacción";
        if (endpoint !== "obtenerUltimaTransaccion") {
            myBody.numeroEmpleado = userData.id + "";
            title = "CIERRE TBK";
        }       

        if (localStorage.getItem('htenDemo') != null && localStorage.getItem('htenDemo') == "SI") {
            myBody.ultimos4 = "demo";
        }

        console.log("Body ", myBody);
        const exitStatus = fetch('http://' + ipHten + '/api/' + endpoint, {
            method: 'post',
            dataType: 'jsonp',
            async: false,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then(respuesta => respuesta.json()).then((data) => {
            console.log(data);
            let icon = 'error';
            if (this.processResponse(data)) {
                icon = 'success'
            }
            
            if (endpoint === "cierreBatch") {
                this.registerTbkClosing(data)
            }
            console.log("resp llevar ", data);
            console.log("resp llevar 2 ", this.state.htenJsonResponse);
            Swal2.fire({
                title: '<strong>'+title+'</strong>',
                text: this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: icon,
            }).then(async (result) => {
                if (icon === "error") {
                    return;
                }

                if (endpoint === "cierreBatch") {
                    return;
                }

                // guardar el voucher en medio de pago de la orden (patch), para imprimir.

                let orderId = localStorage.getItem("ultimaOrden")
                let order = { }
                if (orderId === "") {
                    Swal2.fire({
                        title: '<strong>ERROR</strong>',
                        text: 'No se puede leer el ultimo id de orden.',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'VOLVER',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'error',
                    }).then((result) => {
                            return;
                    });
                }
                let baseUrl = "MediosPorOrden"
                let url =baseUrl+"/GetMediosPorOrdenOrderId?orderId="
                if (localStorage.getItem("tipoUltimaOrden") === "nc") {
                    baseUrl = "MediosPorNc"
                    url = baseUrl + "/GetMediosPorNCOrderId?orderId="
                    order = await this.getNc(orderId)
                    order.numFolio = order.folioBsale
                } else{
                    order = await this.getOrder(orderId)
                }
                    
                fetch(GetUrlApi() + '/api/' + url + orderId, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(data => data.json()).then((resultado) => {
                    console.log(resultado);
                    for (let i = 0; i < resultado.length; i++) {
                        console.log(resultado[i]);
                        const medio = resultado[i];
                        // if (((medio.totalReal?medio.totalReal.toString():0) === this.state.htenJsonResponse.monto || medio.total.toString() === this.state.htenJsonResponse.monto) && medio.numTarjeta !== "") {
                        if(medio.numTarjeta !== ""){
                            let myData = {
                                numTarjeta: JSON.stringify(this.state.htenJsonResponse),
                            }
                            fetch(GetUrlApi() + '/api/' + baseUrl +'/'+medio.id, {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(myData),
                            }).then(async data => {
                                // Enviar a imprimir
                                console.log("ENVIAR !!!! ");
                                const tipoOrden = localStorage.getItem('tipoUltimaOrden')?localStorage.getItem('tipoUltimaOrden'):'pago'
                                this.ReimprimirHten(order.numFolio, tipoOrden);
                            }).catch(error => {
                                swal({
                                    title: "Error al querer actualizar para imprimir",
                                    icon: "error",
                                    text: "Por favor contacte al administrador del sistema " + error,
                                    dangerMode: true,
                                });
                            });

                        }
                    }
                }).catch((err) => {
                    console.log(err);
                });

            });
        }).catch((reason) => {
            console.log(reason);
            Swal2.fire({
                title: '<strong>ERROR</strong>',
                text: 'No logramos conectar con HTEN ' + reason,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'error',
            });
        });
    }
    async registerTbkClosing(data) {
        const apertura = await this.getApertura()
        const stringConstructor = "test".constructor;
        const objectConstructor = ({}).constructor;
        let newData = {}
        if(data.constructor === stringConstructor){
            newData = JSON.parse(data)
        } else if(data.constructor === objectConstructor) {
            newData = data
        }
        newData.aperturaCajaId = apertura
        newData.user_created = GetUserCookie().username
        const token = sessionStorage.getItem('jwtToken')
        const result = await fetch(GetUrlApi() + '/TbkClosing/create', {
            method: 'post',
            body: JSON.stringify(newData),            
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer " + token + ""
            }
        }).then(res => res.json()).then(
            (result) => { 
                return result
            }
        )

    }
    async getApertura() {
        const cajaID = GetUserCookie().CajaID
        var result = await fetch(GetUrlApi() + '/tbkClosing/getApertura?cajaId=' + cajaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return result.json()
    }
    async SelectGavetaPendiente(idGavetaSelect, objetoGaveta) {
        const gavetaSelect = idGavetaSelect;
        const gavetaActual = this.state.IdGaveta;
        //if (idGavetaSelect === gavetaActual && !objetoGaveta.isArqueoPendiente) {
        //    objetoGaveta.omitirCierreCiego = true;
        //}
        var respuesta = await fetch(GetUrlApi() + '/api/AperturaCajas/GetAperturaCajaPendiente?idCaja=' + GetUserCookie().CajaID + '&idGaveta=' + idGavetaSelect, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            this.UltimaAperturaCaja = await respuesta.json();
        }
        this.setState({ idGavetaSeleccionada: idGavetaSelect, activarSeleccionarGaveta: false, gavetaSeleccionada: objetoGaveta, arqueoDisabled: false, arqueoCiegoDisabled: false });
        const isHten = JSON.parse(sessionStorage.getItem('userProfile')).Sucursal.hten
        if(isHten)
            this.loadTbkClosing()
    }

    render() {
        return (
            <div>

                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                {
                    (this.state.activarSeleccionarGaveta) ? (this.GetHtmlPopupSeleccionarPendiente()) : (null)
                }
                {
                    (this.state.activarNoTieneGaveta) ? (this.GetHtmlNoTieneGaveta()) : (null)
                }
                <center> <h1 className="LetraBlanca">Salida de cajero</h1> </center>
                <hr />


                <div className="row">
                    {
                        (this.state.gavetaSeleccionada.descripcion) ?
                            (
                                <div className="col-sm-3" style={this.Estilos.MarginGavSelected}>

                                    <center>
                                        <div className="row">
                                            <div className="col-sm-6" style={this.Estilos.SpanStyle2}><strong> {this.state.gavetaSeleccionada.descripcion}</strong></div>
                                            <div className="col-sm-6" style={this.Estilos.SpanStyle2}> {this.state.gavetaSeleccionada.usuarios.nombre} </div>
                                            <div className="col-sm-6" style={this.Estilos.SpanStyle2}>{this.state.gavetaSeleccionada.usuarios.apellido}</div>

                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12" style={this.Estilos.SpanStyle2}>Apertura {new Date(this.state.gavetaSeleccionada.fechaUltApertura).toLocaleDateString()} -
                                                {new Date(this.state.gavetaSeleccionada.fechaUltApertura).toLocaleTimeString()}
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            ) :
                            (<div className="col-sm-3" ></div>)
                    }

                    {
                        (this.state.gavetaSeleccionada.descripcion) ?
                            (
                                <div style={this.Estilos.BarraLateral} className="col-sm-8">
                                    <div className="row">
                                        {
                                            (GetUserCookie().tipoUsuario.id === 1 || GetUserCookie().tipoUsuario.id === 6) ? (
                                                <div style={this.Estilos.FullAncho} ><button disabled={this.state.arqueoDisabled} onClick={() => { this.DesactivarOpciones(); this.setState({ activarArqueo: true }) }} className="btn " style={this.Estilos.BotonesOpt}>Arqueo De Caja</button> </div>
                                            ) : (null)
                                        }
                                        {/* <div style={this.Estilos.FullAncho}><button disabled={this.state.arqueoCiegoDisabled} onClick={() => { this.DesactivarOpciones(); this.setState({ activarCierreCiego: true }) }} className="btn " style={this.Estilos.BotonesOpt}>Cierre Ciego</button></div> */}
                                        {/* <div style={this.Estilos.FullAncho}><button onClick={() => { this.DesactivarOpciones(); this.ClickSalidaRapida() }} className="btn " style={this.Estilos.BotonesOpt}>Salida Rapida</button></div> */}
                                        <div style={this.Estilos.FullAncho}><button onClick={() => { this.DesactivarOpciones(); Logout(); this.setState({ volverAlIncio: true }) }} className="btn " style={this.Estilos.BotonesOpt}>Volver</button></div>

                                    </div>
                                </div>) : (<div style={this.Estilos.BarraLateral} className="col-sm-8">

                                </div>)}

                </div>

                <div className="row">
                    {
                        (this.state.activarArqueo) ? (<Arqueo ultimaAperturaCaja={this.UltimaAperturaCaja} idGaveta={this.IdGavetaActual} GavetaID={this.state.idGavetaSeleccionada} gavetaSeleccionada={this.state.gavetaSeleccionada} GavetaName={this.state.gavetaSeleccionada.descripcion} />) : (null)
                    }
                    {
                        (this.state.activarCierreCiego) ? (<ArqueoCiego ultimaAperturaCaja={this.UltimaAperturaCaja} Redirigir={this.Redirigir.bind(this)} />) : (null)
                    }


                </div>

            </div>
        );
    }
}
