import React, { Component } from 'react';
import Logo from '../Imagenes/LogoBL.png';

export default class LogoLeanPos extends Component {
    constructor(props) {
        super(props);
    }

    Estilos = {
        myWidth: {
            'width': '6rem',
            'paddingLeft': '1rem',
        }
    }

    render() {
        return (
            <img className={this.props.myClass} style={this.Estilos.myWidth} src={Logo} />
        );
    }
}