import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';
import UserProfile from './Globales/PerfilUsuario';
import { AddMedioPago } from './AddMedioPago';
import { EditarMedioPago } from './EditarMedioPago';
import { AdministracionModulosSistema } from './Administracion/AdministracionModulosSistema';
import { GetUserCookie } from './Globales/Utilidades';


export class Config extends Component {
    static displayName = Config.name;
    MedioSeleccionado = {};

    constructor(props) {
        super(props);
        this.state = {
            volverAlIncio: false,
            mostrarGenerales: true,
            mostrarMedios: false,
            mostrarOptSesion: false,
            mediosDePago: [],
            cargando: false,
            popupAgregar: false,
            popupEditar: false,
            configuracion: {},
            cajas: [],
            TipoUsuario: [],
            Sucursales:[]
        }
        this.TraerCajas();
        this.TraerTipoUsuario();
    }

    componentDidMount() {
        try {
            if (!GetUserCookie()) {
                this.setState({ volverAlIncio: true });
            } else {
                var user = GetUserCookie();
                if (GetUserCookie().tipoUsuario.id != 1 && !user.IsSuperAdmin) {
                    this.setState({ volverAlIncio: true });
                }
                this.TraerConfiguracion();
                this.TraerMediosDePago();
                this.TraerSucursales();
                const width = window.screen.availWidth;
                const height = window.screen.availHeight;

                if (width < 1000) {
                    this.HacerResponsive();
                }
            }
            
        }
        catch (err) {
            this.setState({ volverAlIncio: true });
        }
        

    }

    async TraerMediosDePago() {

       // await this.setState({ cargando: true });
        var respuesta = await fetch(GetUrlApi() + '/api/tbMedio', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ mediosDePago: datos, cargando: false });
        }
        else {
            alert('error');
        }
    }

    async TraerSucursales() {

        // await this.setState({ cargando: true });
        var respuesta = await fetch(GetUrlApi() + '/api/Sucursals', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();

            this.setState({ Sucursales: datos, cargando: false });
        }
        else {
            alert('error');
        }
    }

    DesactivarSelecciones() {
        this.setState({
            mostrarGenerales: false,
            mostrarMedios: false,
            mostrarOptSesion: false,
        });
    }

    async TraerConfiguracion() {
        const idConfig = GetUserCookie().Sucursal.configPOS.id;
        var respuesta = await fetch(GetUrlApi() + '/api/ConfigPOS/' + idConfig, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            try {
                document.getElementById('horaComienzo').value = datos.horaComienzoDia;
                document.getElementById('horaAlmuerzo').value = datos.horaAlmuerzo;
                document.getElementById('horaCena').value = datos.horaCena;
            } catch(err) {

            }
            this.setState({ configuracion: datos });
        }
        else {
            alert('error');
        }
    }
    async TraerTipoUsuario() {

        var respuesta = await fetch(GetUrlApi() + '/api/TipoUsuarios/', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();

            this.setState({ TipoUsuario: datos });
        }
        else {
            alert('error');
        }
    }

    ClickAgregarMedio() {
        this.setState({ popupAgregar: true });
    }

    async ClickBorrar(id) {
        swal({
            title: "Confirmar",
            text: "¿Seguro que desea borrar este medio de pago?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                var medio = this.state.mediosDePago.find(p => p.id === id);
                medio.isHabilitado = false;
                var respuesta = await fetch(GetUrlApi() + '/api/tbmedio/' + medio.id, {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(medio)
                });
                if (respuesta.ok) {
                    this.TraerMediosDePago();
                    swal('Borrado con exito', {
                        icon: "success",
                    });
                }
                else {
                    swal('error al borrar');
                }
            }
        });

    }

    ClickEditar(idMedio) {
        var medioSeleccionado = this.state.mediosDePago.find(p => p.id === idMedio);
        if (medioSeleccionado) {
            this.MedioSeleccionado = medioSeleccionado;
            this.setState({ popupEditar: true });
        }
        else {
            swal("El id del medio de pago seleccionado no está disponible");
        }

    }
    HacerResponsive() {
        var elements = Array.from(document.getElementsByTagName("*"));

        elements.forEach((item) => {
            item.style.width = '100%';
        });
    }

    async TraerCajas() {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/Cajas/');
            if (!categorias.ok)
                throw { mensaje: 'problema al traer las gavetas' };

            var datos = await categorias.json();
            await this.setState({ cajas: datos });
        } catch (err) {
            console.log(err.mensaje);
        }
    }

    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        btnOpciones: {
            'width': '100%',
            'height':'80px'
        },
        MitadAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: {
                'width': '50%',
                'backgroundColor': 'white',
                'borderRadius': '25px',
                'border': '2px solid #73AD21',
                'padding': '25px'
            }
        },
        Contenedor: {
            'width': '60%',
            'backgroundColor': 'white',
            'borderRadius': '25px',
            'border': '2px solid #73AD21',
            'padding': '25px'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
    }

    async ClickGuardarConfig() {
        const idConfig = GetUserCookie().Sucursal.configPOS.id;
        var data = this.state.configuracion;
        data.horaComienzoDia = document.getElementById('horaComienzo').value ;
        data.horaAlmuerzo = document.getElementById('horaAlmuerzo').value;
        data.horaCena = document.getElementById('horaCena').value;
        //document.cookie = document.getElementById('cboCajas').value + ';expires=Tue, 19 Jan 2038 03:14:07 UTC;';
        //this.CambiarCajaUser();
        var respuesta = await fetch(GetUrlApi() + '/api/ConfigPOS/' + idConfig, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        if (respuesta.ok) {
            swal("Configuaración guardada con exito", {
                icon: "success",
            });
        }
        else {
            swal('error', {
                icon: "error",
            });
        }
    }



    //FUNCIONES HTML
    GetHtmlGenerales() {

        return (
            <div>
                <h3><strong> Configuracion General </strong></h3>
                <br />
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Hora de comienzo de día</td>
                            <td>
                                <input id="horaComienzo" className="form-control" type="text" />
                                
                            </td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td>Hora de almuerzo</td>
                            <td>
                                <input id="horaAlmuerzo"  className="form-control" type="text" />
                            </td>
                        </tr>
                        <tr>
                            <td>Hora de cena</td>
                            <td>
                                <input id="horaCena" className="form-control" type="text" />
                            </td>
                        </tr>
                        
                    </tbody>
                </table>



                <center>
                    <button onClick={() => { this.ClickGuardarConfig() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i className="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

                <hr />
                <AdministracionModulosSistema
                    configuracion={this.state.configuracion}
                />
            </div>);
    }


    GetHtmlMedios() {

        return (<div>
            <h3><strong> Configuracion medios de pago</strong>

            </h3>
            <button onClick={() => { this.ClickAgregarMedio() }} type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                <i style={this.Estilos.Space} className="fa fa-plus-circle" aria-hidden="true"></i>
                Agregar medio de pago
                        </button>
            <br/>
            <br/>
            <table className="table">
                <thead>
                    <tr>
                        <td hidden>Codigo</td>
                        <td>Descripción</td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.mediosDePago.map((item) => {
                            return (
                                <tr>
                                    <td hidden>{item.id}</td>
                                    <td>{item.desmed}</td>
                                    <td>
                                        <button style={this.Estilos.BtnOpt} onClick={() => { this.ClickEditar(item.id) }} data-toggle="tooltip" data-placement="right" title="Editar" >
                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                        

                                        
                                    </td>
                                    <td>
                                        <button style={this.Estilos.BtnOpt1} onClick={() => { this.ClickBorrar(item.id)}} data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                            <i className="fa fa-trash " aria-hidden="true"></i></button>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>

            </table>
        </div>);
    }

    async ClickListoOptInicio() {
        var tipos = document.getElementsByName('tipoUser');
        tipos.forEach((item,i) => {
            var data = this.state.TipoUsuario[i];
            const nuevaUrl = document.getElementById('tipoUser' + i).value;
         //   console.log(document.getElementById('cboSucursal').value);
            console.log('la sucursal de la cookie es ' + GetUserCookie().SucursalID);
            data.sucursalID = (document.getElementById('cboSucursal')) ? (Number( document.getElementById('cboSucursal').value)) : (GetUserCookie().SucursalID);
            const indiceEditar = data.configTipoUsuario.indexOf(data.configTipoUsuario.find(p => p.sucursalID === data.sucursalID));
            data.configTipoUsuario[indiceEditar].urlInicio = nuevaUrl;
            data.urlPantallaInicio = document.getElementById('tipoUser' + i).value;
            var respuesta = fetch(GetUrlApi() + '/api/TipoUsuarios/' + data.id, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            
        });
        swal('Configuración guardada con éxito', {
            icon: "success",
        });
        this.componentDidMount();
        this.setState();
    }

    GetHtmlOptSesion() {
        return (<div>
            <h3>
                <strong> Configuración de Pantalla de inicio</strong>
            </h3>
            {

                (GetUserCookie().IsSuperAdmin) ? (
                    <select id="cboSucursal" className="form-control">
                        {
                            this.state.Sucursales.map((sucursal) => {
                                if (sucursal.id === GetUserCookie().SucursalID) {
                                    return (<option selected value={sucursal.id}>{sucursal.descripcion}</option>);
                                } else {
                                        return (<option value={sucursal.id}>{sucursal.descripcion}</option>);
                                }
                                
                            })
                        }
                    </select>
                ): (null)
            }
            
            <table className="table">
                <thead>
                    <tr>
                        <th>Descripcion</th>
                        <th>Direccion</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.TipoUsuario.map((item,i) => {
                            const prefijo = 'tipoUser' + i;
                            const sucID = GetUserCookie().SucursalID;
                            const urlInicio = item.configTipoUsuario.find(p => p.sucursalID === sucID).urlInicio;
                            return (

                                <tr>
                                    <td>{item.descripcion}</td>
                                    <td><input name="tipoUser" id={prefijo} type="text" defaultValue={urlInicio} /> </td>
                                </tr>
                            );
                        })
                    }
                </tbody>

            </table>
            <button name="blokCambios" id="btnListo" className="btn btn-success" onClick={() => { this.ClickListoOptInicio() }} 
                data-toggle="tooltip" data-placement="right" title="Listo">
                <i className="fa fa-check-square-o fa-2x" aria-hidden="true"></i></button>
        </div>);
    }
    //FIN FUNCIONES HTML

    render() {
        return (
            <div>

                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                {
                    (this.state.popupAgregar) ? (
                        <AddMedioPago actualizarEstado={this.setState.bind(this)} recargar={this.TraerMediosDePago.bind(this)} />
                    ): (null)
                }
                {
                    (this.state.popupEditar) ? (
                        <EditarMedioPago actualizarEstado={this.setState.bind(this)} recargar={this.TraerMediosDePago.bind(this)} medioSeleccionado={this.MedioSeleccionado} />
                    ): (null)
                }
                {
                    (this.state.cargando) ? (
                        <div style={this.Estilos.FondoDefault}>
                            <center>
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                <p> Cargando ventas...</p>
                            </center>
                        </div>
                    ) : (null)
                }
                <center> <h1 className="LetraBlanca">Administración</h1> </center>
                <hr />

                <div className="row">
                    <div style={this.Estilos.Columnas.col1} className="column">
                        <div style={this.Estilos.BarraLateral}>
                            <div>
                                <center><h3><strong> Opciones</strong></h3></center>
                            </div>
                            <div><button onClick={() => { this.DesactivarSelecciones(); this.setState({ mostrarGenerales: true }) }} className="btn btn-light" style={this.Estilos.btnOpciones}>Configuraciones Generales</button> </div>
                            <div><button onClick={() => { this.DesactivarSelecciones(); this.setState({ mostrarMedios: true }) }} className="btn btn-light" style={this.Estilos.btnOpciones}>Medios de pago</button></div>
                            <div><button onClick={() => { this.DesactivarSelecciones(); this.setState({ mostrarOptSesion:true }) }} className="btn btn-light" style={this.Estilos.btnOpciones}>Pantalla de inicio</button></div>
                            <div><button onClick={() => { this.setState({ volverAlIncio: true }) }} className="btn btn-light" style={this.Estilos.btnOpciones}>Volver</button></div>
                        </div>
                    </div>

                    <div style={this.Estilos.Columnas.col2}>

                        {
                            (this.state.mostrarGenerales) ? (this.GetHtmlGenerales()) : (null)
                        }
                        {
                            (this.state.mostrarMedios) ? (this.GetHtmlMedios()) : (null)
                        }
                        {
                            (this.state.mostrarOptSesion) ? (this.GetHtmlOptSesion()) : (null)
                        }
                        
                    </div>
                    <div style={this.Estilos.Columnas.col1}>

                    </div>
                </div>


            </div>
        );
    }
}
