import React, { Component } from 'react'
import { GetUrlApi } from './Globales/VariableGlobales'
import Popup from "reactjs-popup"
import swal from 'sweetalert'
import { Button } from 'reactstrap'
import { GetUserCookie } from './Globales/Utilidades'
import { GetUrlBlinamicApi } from './Globales/VariableGlobales'
import { GetPrecioFormateado } from './Globales/Utilidades';

export class OfertaConsultar extends Component {
    orden = {}
    TotalOrden = 0
    ClientData = []
    constructor(props) {
        super(props)
        this.state = {
            paymentMethodId: 0,
            MediosPagoVisibles: []
        }
        this.setOrder = this.setOrder.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.TraerMediosDePago()
    }
    paymentMethodId = 0
    render() {
        return (
            <Popup
                // onClose={() => { this.CerrarModal() }}
                contentStyle={this.Estilos.EstiloPopup}
                defaultOpen
                modal
                closeOnDocumentClick
                onClose={() => { this.closeModal() }}>
                { this.state.MediosPagoVisibles.map((item, i) => {
                    const id = item.desmed + i;
                    console.log(item.medioEquivalente)
                    //if (item.medioEquivalente=="CANJEPUNTOS"){
                    //    try{ 
                    //        this.state.ProgramasPuntosDocumentos.map((currentValue,x)=> {
                    //            if (currentValue.codigo == 1 && this.IsBoletaSelected) //boleta 
                    //            {
                    //                isVisibleCanje=true; 
                    //            }
                    //            if(currentValue.codigo == 2 && this.IsFacturaSelected){
                    //                isVisibleCanje=true; 
                    //            }
                    //         });
                    //         if (isVisibleCanje){
                    //            return <button style={this.Estilos.BtnMedio} className="btn btn-dark" onClick={() => { this.ClickMedioPago(item.id, item.desmed, item.tieneNumOperacion, item) }} name="btnMedio" key={id}> {item.desmed} </button>
                    //         }
                    //    }catch(e){
                     //       console.log(e);
                    //    }
                    //}
                    // console.log(this.dataClientePuntos)
                    return item.offerDisc>0?<button style={this.Estilos.BtnMedio} className="btn btn-dark" onClick={() => { this.consultarOferta(item.id) }} name="btnMedio" key={id}> {item.desmed} <small class="text-warning">{item.offerDisc>0?'(' + GetPrecioFormateado(item.offerDisc*-1) + ')':''}</small><small><i class="fa fa-bookmark"></i></small></button> : <button style={this.Estilos.BtnMedio} className="btn btn-dark" onClick={() => { this.consultarOferta(item.id) }} name="btnMedio" key={id}> {item.desmed}</button> 
                }) }
            </Popup>
        )
    }
    setOrder() {
        this.props.setOrder(this.props.orden)
    }
    closeModal() {
        this.props.closeModal()
    }

    consultarOferta(payID) {
        this.setState( {paymentMethodId: payID})
        this.state.paymentMethodId = payID
        // console.log(this.props.orden.carroProductos) //ok
        // console.log(this.props.ClientData.id) //ok
        // console.log(GetUserCookie().Sucursal.sucursalIDBlinamic) //ok
        this.setMontOfertaFidelizacion()
        this.closeModal()
    }
    enabledOffer() {
        this.props.orden.enabledOffer = true
        this.props.orden.offerPaymentMethodId = this.state.paymentMethodId
    }
    disabledOffer() {
        this.props.orden.enabledOffer = false
        this.props.orden.offerPaymentMethodId = 0
    }
    async setMontOfertaFidelizacion(){
        this.resetOffer()
        // console.log('consultar oferta')
        let productsOrder = []
        this.props.orden.carroProductos.forEach(function(item) {
            productsOrder.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred })
        })
        const venta = {
            total: this.props.TotalOrden,
            clientId: this.props.ClientData.id,
            sucursalId: GetUserCookie().Sucursal.sucursalIDBlinamic,
            paymentMethodId: this.state.paymentMethodId,
            products: productsOrder
        }
        let token =sessionStorage.getItem('jwtToken')
        const offerProducts = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOferta?venta=' + encodeURIComponent(JSON.stringify(venta)), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer " + token + ""
            }
        }).then(res => res.json()).then(
            (result) => { return result.result.products }
        )
        this.props.orden.carroProductos.forEach(function (prod) {
            const carroInOffer = prod.inOffer?prod.inOffer:false
            offerProducts.forEach(function(offerProd) {
                const isValid = offerProd.isValid?offerProd.isValid:true
                if (prod.desprod.split(';')[0] === offerProd.productCode && isValid && !carroInOffer) {
                    prod.valueOffer = prod.precpred * prod.cantidad - offerProd.valueOffer
                    offerProd.isValid=false
                    prod.inOffer = true
                }
            }) 
        })
        
        if(offerProducts.length > 0)
            this.enabledOffer()
        else
            this.disabledOffer()
            
        this.setOrder()
    } 
    resetOffer() {
        this.props.orden.carroProductos.forEach(function (item) {
            item.valueOffer = 0
            item.inOffer = false
        })
    }
    async TraerMediosDePago() {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/TbMedio')
            if (!categorias.ok) {
                throw 'problema al traer los medios de pago'
            }
            const datos = await categorias.json()
            const MediosPagoVisibles = datos.filter(t => t.isHabilitado);
            this.setState({ MediosPagoVisibles: MediosPagoVisibles })
            this.TraerMedioPagoOffer()
        } catch (err) {
            console.log(err);
        }
    }

    async TraerMedioPagoOffer() {
        try {
            this.resetOffer()
            let productsOrder = []
            let token =sessionStorage.getItem('jwtToken')
            this.props.orden.carroProductos.forEach(function(item) {
                productsOrder.push({ productId: item.id, code: item.desprod.split(';')[0], umount: item.cantidad, valueSale: item.precpred })
            })
            const venta = {
                total: this.props.TotalOrden,
                clientId: this.props.ClientData.id,
                sucursalId: GetUserCookie().Sucursal.sucursalIDBlinamic,
                paymentMethodId: this.state.paymentMethodId,
                products: productsOrder
            }
            const result = await fetch(GetUrlBlinamicApi() + '/api/Ofertas/consultarOfertaMedios?venta=' + encodeURIComponent(JSON.stringify(venta)), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => { return result.result }
            )
            let mediosPagoVisibles = this.state.MediosPagoVisibles
            await mediosPagoVisibles.map((med, index2) => {
                med.offerDisc = 0
                result.methods.map((item, index) => {
                    med.offerDisc = med.id === item.paymentMethodId? item.offerDisc:med.offerDisc
                })
            })
            this.setState({ MediosPagoVisibles: mediosPagoVisibles })            
        } catch (err) {
            console.log(err);
        }
        
    }

    async BuscaTipoDocumentosProgramasPuntos(idPrograma){
        try{ 
            let SwProgramaOfertas = sessionStorage.getItem('SwitchPuntosOfertas');
            if (SwProgramaOfertas === 'N'){
                return;
            }

            let token =sessionStorage.getItem('jwtToken');
 
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/getTipoDocumentos?IdPrograma=' + idPrograma, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.length > 0){
                        this.setState({ProgramasPuntosDocumentos : result});
                }
                    return;
                });
        }
        catch (e){
           console.log(e);
        }
    }

    Estilos = {
        InputSize:
        {
            'width': '80%',
            'height': '60px',
            'font-size': '4em',
            'text-align': 'center'
        },
        EstiloPopupAut: {
            'width': '30%',
            'height': '70%',
            'border-radius': '8px',
            'marginLeft': '25%'
            //'margin': 'initial'
        },
        FuenteAdvertencia: {
            'color': 'red'
        },
        btnDescuento: {
            'width': '33%',
            'fontSize': '19px'
        },
        TercioAncho: {
            'width': '33%'
        },
        FullAncho: {
            'width': '50%',
            'margin-top': '-3%',
            'marginLeft': '-6%'
        },
        Escroleable: {
            'overflow-y': 'scroll',
            'height': '100%'
        },
        AlturaListaProductos: {
            'height': '42%'
        },
        EstiloPopup: {
            'width': '47%',
            'height': '50%',
            'border-radius': '2%'
        },
        MarginTr:
        {
            'margin-bottom': '10%'
        },
        SpanStyle:
        {
            'background-color': '#E1E6EA',
            'border-radius': '5px',
            'text-align': 'center',
            'font-family': 'Arial, sans-serif',
            'margin-rigth': '10%',
            'width': '120px',
            'text-decoration': 'solid',
            'fontSize': '20px'
        },
        BtnStyles:
        {
            'width': '143px',
            'height': '60px',
            'background-color': '#769e8e',
            'fontSize': '28px',
        },
        BtnStylesM:
        {
            'width': '120px',
            'height': '50px',
            'background-color': 'rgb(207, 240, 227)'
        },
        BtnDanger:
        {
            'width': '50%',
            'margin-top': '-3%',
            'background-color': '#f80303ad'
        },
        BtnArt:
        {
            'width': '40%',
            'height': '60px',
            'marginRight': '2%',
            'margin-bottom': '2%',
            'background-color': 'rgb(118, 158, 142)',
            'borderColor': '#17a2b8',
            'font-size': '80%',
            'marginLeft': '8%'
        },
        BtnTecladoDescuento:
        {
               'width': '70px',
               'height': '65px',
               'background': '#c6d6cf',
               'fontSize': '40px',
               'border-radius': '25%'
        },
        BtnTecladoDescuentoSinBack:
        {
               'width': '70px',
               'height': '65px',
               //'background': '#c6d6cf',
               'font-size': '25px',
               'border-radius': '25%'
        },
        InputDescuento:
        {
                'width': '50%',
                'height': '60px',
                'borderRadius': '10px',
                'fontSize': '35px',
                'background': '#dcf3f3',
                'textAlign': 'center'
        },
        BtnMedio: {
            'width': '45%',
            'font-size': '20px',
            'margin': '1px',
            'height': '65px',
            'borderColor': '#d8e1de',
            'background': 'rgb(90, 88, 88)',
            'color': 'white',
        }
    }

}