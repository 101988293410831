import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import swal from 'sweetalert';


export class AddMedioPago extends Component {
    static displayName = AddMedioPago.name;

    constructor(props) {
        super(props);
        this.state = {
            volverAlIncio: false,
            
        }
    }
    componentDidMount() {
        document.getElementById('isGarantiaEventoFalse').checked=true;
        document.getElementById('isCCFalse').checked=true;
       

    }

   

    

    HacerResponsive() {
        var elements = Array.from(document.getElementsByTagName("*"));

        elements.forEach((item) => {
            item.style.width = '100%';
        });
    }

    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        btnOpciones: {
            'width': '100%',
            'height': '80px'
        },
        MitadAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: {
                'width': '50%',
                'backgroundColor': 'white',
                'borderRadius': '25px',
                'border': '2px solid #73AD21',
                'padding': '25px'
            }
        },
        Contenedor: {
            'width': '60%',
            'backgroundColor': 'white',
            'borderRadius': '25px',
            'border': '2px solid #73AD21',
            'padding': '25px'
        },
        BarraLateral: {
            'backgroundColor': 'white',
            'height ': '100%',
            'width': '100%'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
    }

    //FUNCIONES CLICK
    async ClickGuardar() {
        var txtDesc = document.getElementById('txtDesc').value;
        var categoria = document.getElementById('cboCategoria').value;

        var reglas = document.getElementById('cboReglas').value;
        var medio = {};
        medio.desmed = txtDesc;

        medio.IsHabilitado = true;
        if (reglas === '1') {
            medio.DaVuelto = true;
            medio.AplicaExcedente = false;
            medio.TieneNumOperacion = false;
        }
        else {
            medio.DaVuelto = false;
        }
        if (reglas === '2') {
            medio.IsTarjeta = true;
            medio.TieneNumOperacion = true;
        }
        else {
            medio.IsTarjeta = false;
            medio.TieneNumOperacion = false;
        }

        if (reglas === '3') {
            medio.AplicaExcedente = true;
            medio.TieneNumOperacion = true;
        }
        else {
            medio.AplicaExcedente = false;
        }

        medio.IsCuentaCasa = document.getElementById('isCC').checked;
        medio.Geningr = 0;
        medio.Estmed = 0;
        
        medio.OrdenMedios = 0;
        medio.IsGarantiaEvento = document.getElementById('isGarantiaEvento').checked;
        medio.isCheque = categoria==='5';

       
        medio.equivBsale = Number(categoria);
        var respuesta = await fetch(GetUrlApi() + '/api/tbmedio', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(medio)
        });
        if (respuesta.ok) {
            swal("Medio de pago creado con éxito", {
                icon: "success",
            })
            this.props.recargar();
            this.props.actualizarEstado({ popupAgregar: false });
        }
        else {
            swal('No se pudo guardar');
        }
    }
    //FIN FUNCIONES CLICK

    //FUNCIONES HTML
    
    //FIN FUNCIONES HTML

    render() {
        return (
            <Popup
                modal
                onClose={() => { this.props.actualizarEstado({ popupAgregar: false }) }}
                defaultOpen
                closeOnDocumentClick>
                <div>
                    <center><h3>Ingrese la información requerida</h3></center>
                    <br/>
                    <form class="needs-validation" novalidate>
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom01">Descripción</label>
                                <input id="txtDesc" type="text" className="form-control" />
                                <div class="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom02">Seleccione el medio de facturación</label>
                                <select id="cboCategoria" className="form-control">
                                    <option value="1">EFECTIVO</option>
                                    <option value="2">TARJETA DE CREDITO</option>
                                    <option value="5">CHEQUE</option>
                                    <option value="6">TARJETA DEBITO</option>
                                    <option value="9">GIFTCARD</option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="validationCustomUsername">Seleccione las reglas de vuelto y exceso</label>
                                <div class="input-group">
                                    <select id="cboReglas" className="form-control">
                                        <option value="1">EFECTIVO</option>
                                        <option value="2">TARJETA CREDITO/DEBITO</option>
                                        <option value="3">CUPÓN</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom03">¿Es cuenta de la casa?</label>
                                <div class="input-group">
                                    Si &nbsp;<input type="radio" id="isCC" placeholder="City" name="cboIsCC" />&nbsp;&nbsp;
                                    No &nbsp;<input type="radio" id="isCCFalse" placeholder="City" name="cboIsCC" />&nbsp;&nbsp;
                                </div>
                                
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="validationCustom04">¿Es garantía por evento?</label>
                                <div class="input-group">
                                    Si &nbsp;<input type="radio" id="isGarantiaEvento" placeholder="City" name="cboIsGaran" />&nbsp;&nbsp;
                                    No &nbsp;<input type="radio" id="isGarantiaEventoFalse" placeholder="City" name="cboIsGaran" />&nbsp;&nbsp;
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                
                            </div>
                        </div>
                    </form>
                    <center>
                        <button onClick={() => { this.ClickGuardar() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                            <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                    </center>
                    
                </div>
            </Popup>
        );
    }
}
