import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import swal from 'sweetalert';
import ReactToExcel from 'react-html-table-to-excel';
export class AnalisisVentas extends Component {
    static displayName = AnalisisVentas.name;

    constructor() {
        super();
        this.state = {
            fechaDesdeIngresada: false,
            fechaHastaIngresada: false,
            cargadatos: false,
           
            resultado: [],
            VerTabla: false
        }
    }

    Estilos = {
        Columna1: {
            'width': '20%',
            'backgroundColor': '#333'
        },
        Columna2: {
            'width': '80%',
            'backgroundColor': '#ecf0f5'
        },
        LetraMenu: {
            'Color': 'white'
        },
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        ColumnaChart1: {
            'width': '40%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        ColumnaChart2: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        },
        FondoBlanco: {
            'backgroundColor': 'white'
        },
        FondoGris: {
            'backgroundColor': '#ecf0f5'
        },
        FondoGrisVentas: {
            'backgroundColor': '#ecf0f5',
            'marginLeft': '-10px',
            'overflowX': 'hidden'
        },
        TarjetaEstadisticas: {
            'width': '40%',
            'backgroundColor': 'white',
            'height': '150px',
            'borderRadius': '25px',
            'float': 'left',
            'marginRight': '40px',
            'marginBottom': '40px'
        },
        QuitarMarginLeft: {
            'marginLeft': '0',
        },
        EstiloPaginaCompleta: {
            'overflowX': 'hidden'
        }, FullAlto: {
            'width': '100%',
            'height': '60%'
        }, Bordes: {
            'width': '60%',
            'backgroundColor': '#ecf0f5',
            'marginLeft': '0px'
        }, BordesTabla: {
            'width': '800px',
            'backgroundColor': '#fff',
            'marginLeft': '0px'
        },
        TdStyleTitle: {
            
            'textAlign': 'center'
        },
        TdStyleValues: {
            'textAlign': 'center'
        }

    }
    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('fechaFiltro').value = today;
        document.getElementById('fechaFiltroHasta').value = today;
        document.getElementById('fechaini').value = today;
        document.getElementById('fechafin').value = today;
    }

    componentDidMount() {
        this.cargarFechas();
    }
    async TraerTotalVentasPorFecha() {
        try {
            this.setState({ cargadatos: true });
            var data = {};
            document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;
            document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
            data.fechaIni = document.getElementById('fechaFiltro').value;
            data.fechaFin = document.getElementById('fechaFiltroHasta').value;

            var respuesta = await fetch(GetUrlApi() + '/api/Reporteria/AnalisisVentasDiarias', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!respuesta.ok) {
                throw { mensaje: 'problema al traer las ventas' };

            } else {
                var datos = await respuesta.json();
                console.log(datos);
                this.setState({ resultado: datos.analisisDia, VerTabla: true, cargadatos: false })
                console.log(datos);
            }
        } catch (err) {
            swal('error en TraerTotalVentasPorFecha ' + err);
        }
    }
    GetTotales()
    {
        try {
            var res = this.state.resultado[0].sumaTotalDias;
            return res;
        } catch (e) {
            return 0;
        }

    }
    GetTotalesCant() {
        try {
            var res = this.state.resultado[0].cantidadDias;
            return res;
        } catch (e) {
            return 0;
        }

    }
    async ChangeDesde() {
        await this.setState({ fechaDesdeIngresada: true });
        document.getElementById('fechaini').value = document.getElementById('fechaFiltro').value;

    }

    async ChangeHasta() {
        await this.setState({ fechaHastaIngresada: true });
        document.getElementById('fechafin').value = document.getElementById('fechaFiltroHasta').value;
    }
    render() {
        return (
            <div style={this.Estilos.Columna2} className="column">
                <div>
                    <table style={this.Estilos.FullAncho} >
                        <thead>
                            <th colSpan="3">Sucursales</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sucursal</td>
                                <td>Fecha desde </td>
                                <td>Fecha hasta</td>
                            </tr>
                            <tr>
                                <td> <input className="form-control" type="text" /> </td>
                                <td><input id="fechaFiltro" onChange={() => { this.ChangeDesde() }} className="form-control" type="date" /> </td>
                                <td>
                                    <input id="fechaFiltroHasta" onChange={() => { this.ChangeHasta() }} className="form-control" type="date" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
 


            <hr />
            <div className="row">
                    <div className="col-lg-5">
                        <input type="text" id="fechaini" hidden ></input>
                        <input type="text" id="fechafin" hidden></input>
                    </div>
                    <div className="col-lg-6"><button className="btn btn-success" onClick={() => { this.TraerTotalVentasPorFecha() }}>GENERAR REPORTE</button>  </div>

                </div>
                <br />
            <div>
                <center>
                    {
                        (this.state.cargadatos) ? (
                            <div style={this.Estilos.FondoDefault}>
                                <center>
                                    <div className="spinner-border text-dark" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                    <p> Cargando...</p>
                                </center>
                            </div>
                        ) : (null)
                    }
                </center></div>

                <br />
              <center>
                    <div style={this.Estilos.Bordes}>
                        {  (this.state.VerTabla) ? (
                            <center>
                            <table border="1" style={this.Estilos.BordesTabla} id="table-to-xls">
                                <thead>
                                    <th colSpan="3"></th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>Ventas Netas</th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>Impuesto Tot</th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>Ventas</th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>%</th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>Conteo</th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>Orden Prom.</th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>Orden Min.</th>
                                    <th colSpan="3" style={this.Estilos.TdStyleTitles}>Orden Max.</th>
                                </thead>
                                <tbody>
                                        {
                                            
                                        this.state.resultado.map((item, i) => {

                                                return (<tr>
                                                    <td style={this.Estilos.TdStyleTitles} colSpan="3">{item.fecha}</td>
                                                    <td style={this.Estilos.TdStyleValues} colSpan="3">{GetPrecioFormateado(item.ventasNetas)}</td>
                                                    <td style={this.Estilos.TdStyleValues} colSpan="3"> {GetPrecioFormateado(item.impuestoTotal)}</td>
                                                    <td style={this.Estilos.TdStyleTitles} colSpan="3">{GetPrecioFormateado(item.ventas)}</td>
                                                    <td style={this.Estilos.TdStyleValues} colSpan="3">0</td>
                                                    <td style={this.Estilos.TdStyleValues} colSpan="3"> {item.conteo}</td>
                                                    <td style={this.Estilos.TdStyleTitles} colSpan="3">{item.promOrden}</td>
                                                    <td style={this.Estilos.TdStyleValues} colSpan="3">{GetPrecioFormateado(item.minOrden)}</td>
                                                    <td style={this.Estilos.TdStyleValues} colSpan="3"> {GetPrecioFormateado(item.maxOrden)}</td>

                                                </tr>
                                                );
                                        })
                                        }
                                        <tr>
                                            <td style={this.Estilos.TdStyleTitles} colSpan="3"><b>Total:</b></td>
                                            <td style={this.Estilos.TdStyleValues} colSpan="3">{GetPrecioFormateado(this.GetTotales())}</td>
                                            <td style={this.Estilos.TdStyleValues} colSpan="3"> {GetPrecioFormateado(0)}</td>
                                            <td style={this.Estilos.TdStyleTitles} colSpan="3">{GetPrecioFormateado(this.GetTotales())}</td>
                                            <td style={this.Estilos.TdStyleValues} colSpan="3">0</td>
                                            <td style={this.Estilos.TdStyleValues} colSpan="3"> {this.GetTotalesCant()}</td>
                                            <td style={this.Estilos.TdStyleTitles} colSpan="3">0</td>
                                            <td style={this.Estilos.TdStyleValues} colSpan="3">0</td>
                                            <td style={this.Estilos.TdStyleValues} colSpan="3">0</td>

                                        </tr>
                                </tbody>
                            </table>
                                <br/>
                            <ReactToExcel
                                className="btn btn-primary"
                                table="table-to-xls"
                                filename="AnalisisVentaDiario"
                                sheet="sheet 1"
                                buttonText="Exportar"
                            />
                       </center> ) : (null)}</div> </center>

            </div>



        );
    }

}