import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetUrlBlinamicApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, GetUserCookie, GetNumero } from './Globales/Utilidades';
//import { Pinpad } from './Pinpad';
import swal from 'sweetalert';
import Swal from "sweetalert2";
import ReactToExcel from 'react-html-table-to-excel';
import Popup from "reactjs-popup";
import MySpinner from './MySpinner';
import { Card, Button, CardBody, CardHeader, Table, Form, CardFooter } from 'reactstrap';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';
import { validate, res } from 'react-email-validator';
import Swal2 from 'sweetalert2';
import { ProcesarDevolucion } from './ProcesarDevolucion';
 
import $ from 'jquery';

export class NotaCreditoDos extends Component {
    static displayName = NotaCreditoDos.name;

    Devoluciones = [];
    ResultadoBusqueda = [];
    CarroProductosOriginal = [];
    Count = 0;
    ExisteCanje = false;
    EfectivoCaja = 0;
    imprimeHTEN = false;
    hasDebit = false
    constructor() {
        super();
        this.state = {
            PedirCliente: true,
            ConsultaClientes: [],
            CrearCliente: false,
            DataCliente: {
                id: '', nombre: '', rut: '', telefono: '', email: '', fechaUltServicio: '', montoUltServicio: '0', fechaUltCompra: '', montoUltCompra: '0', estrellas: '',
                ciudad: '', comuna: '', direccion: '', girocomercial: '', razonSocial: '',
            },
            tieneDatosCliente: false,
            PedirTipoDocumento: false,
            PedirDocumento: false,
            PedirMotivoDevolucion: false,
            cargandoIsSelected: false,
            TipoDocumento: 0,
            NumeroDocumento: 0,
            activarPopUpConsultaDocumento: false,
            activarPopUpDireccionRazonSocial: false,
            activarFormasPago:false,
            tieneDatosBoleta: false,
            MotivosDevolucion: [],
            MotivoDevolucion: "",
            MotivoDevolucionID: 0,
            importeTarjeta: 0,
            htenVoid: [],
            boleta: {
                boletaB64: "",
                carroProductos: [],
                clientesModel: [],
                codigoCliente: "",
                codigoVendedor: "0",
                detalleBoleta: [],
                fecha: "",
                notaVenta: "",
                nroCpte: "",
                numeroOrden: "",
                numeroOrdenServicio: "",
                numeroReserva: "",
                TotalPrecioUnitario: 0,
                TotalSubTotal: 0,
                mediosPagoOrigen:[]
            },
            notaCredito: {
                NumeroComprobante: 0,
                MotivosDevolucionID: 0,
                MotivoLibre: '',
                ProductosDevolucion: [],
                ClienteNotasDeCredito: []
            },
            MostrarConfirmoSeleccion:false,
            Ambiente: localStorage.getItem('ambiente'),
            sucursales:[],
            ValidaEfectivo : localStorage.getItem('validaEfectivoCaja'),
            DiasMaxDevolucion : localStorage.getItem('diasMaxDevolucion'),
            CrearClienteEmpresa:false,
            visibleProcesarDevolucion: false,
            pagosHtenDevolucion: [],
            productsInOthersNC:[],
            ShowProductsInOthersNC:false
        }
        this.TraerMotivos();
    
    }
async GetSucursales(){
    await fetch(GetUrlApi() + "/api/Sucursals", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((sucs) => sucs.json())
        .then((data) => {
          data = data.filter(p => p.empresaID ===GetUserCookie().EmpresaID ); 
          if (data.length === 0){
                Swal.fire({
                  title: "El usuario no tiene sucursales asociadas.",
                  icon: "error"
              });
              return;
          }
          this.setState({ sucursales: data });
        
        });
}
    async  TraerComunas() {
        var region=1;
        try{
            if (this.state.CrearClienteEmpresa){
                region = document.getElementById('ddlRegionEmpresa').value;
            }
            else {
                region = document.getElementById('ddlRegion').value;
            }
        }catch(e){
            region=1;
        }
       
   
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetComunas?RegionID=' + region + '&ProvinciaID=0', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var comuns = await respuesta.json();
            this.setState({ Comunas: comuns });

        }
    }
    async  TraerRegiones() {
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetRegiones', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var regions = await respuesta.json();
            this.setState({ Regiones: regions });

        }
    }

    async   GetEfectivoCaja  (){
        let cajaID =GetUserCookie().CajaID;
        let gavetaID =GetUserCookie().idGaveta;
        

        try {
            var respuesta = await fetch(GetUrlApi() + '/api/NotasDeCredito/GetEfectivoCaja?CajaID=' + cajaID +'&GavetaID=' +gavetaID, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(
                (result) => {
                    
                   this.EfectivoCaja = result;
                });

        }
        catch(e){
            return 0;
        }
    }

    //Formateos

    cargarFechas() {
        var date = new Date();
        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        var today = year + "-" + month + "-" + day;
        document.getElementById('FechaDevolucion').value = today;
    }

    formateaRutDos() {
        var rut = document.getElementById('rutAddCliente').value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            document.getElementById('rutAddCliente').value = rutSinPuntosD;
            return rutPuntos;
        }
    }

    componentDidMount() {
        this.cargarFechas();

        this.TraerRegiones();
        this.TraerComunas();
        this.GetEfectivoCaja();
        $('#RutCliente').focus();
        if (this.state.Ambiente=='MAISA'){
            this.GetSucursales();
        }
    }

    formateaRut() {
        var rut = document.getElementById('RutCliente').value;

        if (rut.length >= 10 || rut.length >= 9) {
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            var rutSinPuntosD = rutPuntos.replace(/\./g, "");
            document.getElementById('RutCliente').value = rutSinPuntosD;
            return rutPuntos;
        }
    }

    LimpiarVentana() {
        this.setState({
            PedirCliente: true,
            ConsultaClientes: [],
            CrearCliente: false,
            DataCliente: {
                id: '', nombre: '', rut: '', telefono: '', email: '', fechaUltServicio: '', montoUltServicio: '0', fechaUltCompra: '', montoUltCompra: '0', estrellas: '',
                ciudad: '', comuna: '', direccion: '', girocomercial: '', razonSocial: '',
            },
            tieneDatosCliente: false,
            PedirTipoDocumento: false,
            PedirDocumento: false,
            PedirMotivoDevolucion: false,
            cargandoIsSelected: false,
            TipoDocumento: 0,
            NumeroDocumento: 0,
            activarPopUpConsultaDocumento: false,
            activarPopUpDireccionRazonSocial: false,
            activarFormasPago:false,
            tieneDatosBoleta: false,
            MotivoDevolucion: "",
            MotivoDevolucionID: 0,
            importeTarjeta: 0,
            htenVoid: [],
            boleta: {
                boletaB64: "",
                carroProductos: [],
                clientesModel: [],
                codigoCliente: "",
                codigoVendedor: "0",
                detalleBoleta: [],
                fecha: "",
                notaVenta: "",
                nroCpte: "",
                numeroOrden: "",
                numeroOrdenServicio: "",
                numeroReserva: "",
                TotalPrecioUnitario: 0,
                TotalSubTotal: 0
            },
            MostrarConfirmoSeleccion: false,
            ConfirmoSeleccion:false,
            CheckListo:false,
            Ambiente: localStorage.getItem('ambiente'),
            CrearClienteEmpresa:false,
            productsInOthersNC:[],
            ShowProductsInOthersNC:false

        })

        this.CarroProductosOriginal = [];
        this.Devoluciones = [];
        this.ResultadoBusqueda = [];
        $('#RutCliente').focus();
        document.getElementById("chkSelectAll").hidden = false;
        document.getElementById("chkSelectAll").checked = false;
    }


    LimpiarCliente() {
        this.setState({
            PedirCliente: true,
            ConsultaClientes: [],
            CrearCliente: false,
            DataCliente: {
                id: '', nombre: '', rut: '', telefono: '', email: '', fechaUltServicio: '', montoUltServicio: '0', fechaUltCompra: '', montoUltCompra: '0', estrellas: '',
                ciudad: '', comuna: '', direccion: '', girocomercial: '', razonSocial: '',
            },
            tieneDatosCliente: false,
            MostrarConfirmoSeleccion: false

        })

    }
    LimpiarDocumento() {
        this.setState({
            PedirTipoDocumento: true,
            PedirDocumento: false,
            TipoDocumento: 0,
            NumeroDocumento: 0,
            activarPopUpConsultaDocumento: false,
            activarPopUpDireccionRazonSocial: false,
            activarFormasPago:false,
            tieneDatosBoleta: false,
            MostrarConfirmoSeleccion: false,
            boleta: {
                boletaB64: "",
                carroProductos: [],
                clientesModel: [],
                codigoCliente: "",
                codigoVendedor: "0",
                detalleBoleta: [],
                fecha: "",
                notaVenta: "",
                nroCpte: "",
                numeroOrden: "",
                numeroOrdenServicio: "",
                numeroReserva: "",
                TotalPrecioUnitario: 0,
                TotalSubTotal: 0
            }

        })
        this.CarroProductosOriginal = [];
        this.Devoluciones = [];

    }
    LimpiarMotivoDevolucion() {
        this.setState({
            PedirMotivoDevolucion: true,
            MotivoDevolucion: "",
            MotivoDevolucionID: 0,
            importeTarjeta: 0

        })

    }
    //Fin Formateos

    //Desactivar Modales
    DesactivarModales() {
        if (this.state.DataCliente.id!='') {
            this.setState({
                PedirCliente: false,
                CrearCliente: false,
                CrearClienteEmpresa:false
            });
            setTimeout(() => {
                if ((this.state.DataCliente.direccion === '' || this.state.DataCliente.razonSocial === '' || this.state.DataCliente.girocomercial==='')) {
                    this.setState({ activarPopUpDireccionRazonSocial: true });
                    document.getElementById('direccionAddCliente').value = this.state.DataCliente.direccion;
                    document.getElementById('razonSocialAddCliente').value = this.state.DataCliente.razonSocial;
                    document.getElementById('giroAddCliente').value = this.state.DataCliente.girocomercial;
                }
            }, 500);
        } else {
            this.setState({
                PedirCliente: true,
                CrearCliente: false,
                CrearClienteEmpresa:false
            });
        }
        this.setState({  activarFormasPago:false});

    }

    DesactivarAddCliente() {
        this.setState({
            CrearCliente: false
        });
    }

    DesactivarAddClienteExtra() {
        this.setState({
            activarPopUpDireccionRazonSocial: false
        });
    }
    DesactivarConsultaDoc(){
        this.setState({PedirTipoDocumento:false});
    }
    DesactivarConsultaCliente(){
        this.setState({PedirCliente:false});
    }
    DesactivarMotivoDevol(){
        this.setState({PedirMotivoDevolucion:false});
    }
    
    DesactivarAddRazonSoc(){
        this.setState({activarPopUpDireccionRazonSocial: false})
    }

    DesctivarBuscarDoc (){
        this.setState({PedirDocumento:false});
    }
    //Fin Desactivar Modales

    checkRut(rut) {
        // Despejar Puntos
        var valor = rut.replaceAll('.', '');
        // Despejar Guión
        valor = valor.replace('-', '');

        // Aislar Cuerpo y Dígito Verificador

        const cuerpo = valor.slice(0, -1);
        let dv = valor.slice(-1).toUpperCase();

        // Formatear RUN
        rut = cuerpo + '-' + dv

        // Si no cumple con el mínimo ej. (n.nnn.nnn)
        if (cuerpo.length < 7) { return false; }

        // Calcular Dígito Verificador
        let suma = 0;
        let multiplo = 2;

        // Para cada dígito del Cuerpo
        let index = 0;
        for (let i = 1; i <= cuerpo.length; i++) {

            // Obtener su Producto con el Múltiplo Correspondiente
            index = multiplo * valor.charAt(cuerpo.length - i);

            // Sumar al Contador General
            suma = suma + index;

            // Consolidar Múltiplo dentro del rango [2,7]

            if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

        }

        // Calcular Dígito Verificador en base al Módulo 11
        const dvEsperado = 11 - (suma % 11);

        // Casos Especiales (0 y K)
        dv = (dv == 'K') ? 10 : dv;
        dv = (dv == 0) ? 11 : dv;

        // Validar que el Cuerpo coincide con su Dígito Verificador
        if (dvEsperado != dv) { return false; }

        // Si todo sale bien, eliminar errores (decretar que es válido)

        return true;
    }
    //Funciones De Datos


    async AgregarClienteAdic() {

        const razonSocial = document.getElementById('razonSocialAddCliente').value;
        const direccion = document.getElementById('direccionAddCliente').value;
        const giro = document.getElementById('giroAddCliente').value; 
        const clienteId = this.state.DataCliente.id;
        const Empresa = GetUserCookie().EmpresaID;

        if (razonSocial !== '' &&
            direccion !== ''
        ) {
                await fetch(GetUrlApi() + '/api/Clientes/AgregarDatosClienteEmpresaNC?RazonSocial=' + razonSocial + '&Direccion=' + direccion + '&Giro=' + giro + '&ClienteId=' + clienteId + '&Empresa=' + Empresa, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((respuesta) => respuesta.json(), (error) => {
                if (error) {
                    swal({
                        title: "ERROR !",
                        text: "Error al enviar datos",
                        icon: "error",
                        button: "Entendido !",
                    });
                }
            }).then((data) => {
                if (data) {
                    swal({
                        title: "¡Todo ok!",
                        text: "El cliente fue actualizado correctamente.",
                        icon: "success",
                        button: "OK",
                    }).then(() => {
                        var DataCliente = this.state.DataCliente;
                        DataCliente.razonSocial = razonSocial;
                        DataCliente.direccion = direccion;
                        this.setState({ DataCliente })
                        setTimeout(() => {
                            this.DesactivarAddClienteExtra();
                        }, 500);
                    });

                } else {
                    swal({
                        title: "ERROR !",
                        text: "No se pudo actualizar el cliente",
                        icon: "error",
                        button: "Entendido !",
                    });
                }
            });
        } else {
            swal({
                title: "ERROR !",
                text: "Todos los campos son obligatorios!",
                icon: "error",
                button: "Entendido !",
            });
        }


        //if (razonSocial !== '' &&
        //    direccion !== ''
        //) {
        //    await fetch(GetUrlApi() + '/api/Clientes/AgregarDatosClienteNC?RazonSocial=' + razonSocial + '&Direccion=' + direccion + '&Giro=' + giro + '&ClienteId=' + clienteId, {
        //        method: 'post',
        //        headers: {
        //            'Content-Type': 'application/json',
        //        },
        //    }).then((respuesta) => respuesta.json(), (error) => {
        //        if (error) {
        //            swal({
        //                title: "ERROR !",
        //                text: "Error al enviar datos",
        //                icon: "error",
        //                button: "Entendido !",
        //            });
        //        }
        //    }).then((data) => {
        //        if (data) {
        //            swal({
        //                title: "¡Todo ok!",
        //                text: "El cliente fue actualizado correctamente.",
        //                icon: "success",
        //                button: "OK",
        //            }).then(() => {
        //                var DataCliente = this.state.DataCliente;
        //                DataCliente.razonSocial = razonSocial;
        //                DataCliente.direccion = direccion;
        //                this.setState({ DataCliente })
        //                setTimeout(() => {
        //                    this.DesactivarAddClienteExtra();
        //                }, 500);
        //            });

        //        } else {
        //            swal({
        //                title: "ERROR !",
        //                text: "No se pudo actualizar el cliente",
        //                icon: "error",
        //                button: "Entendido !",
        //            });
        //        }
        //    });
        //} else {
        //    swal({
        //        title: "ERROR !",
        //        text: "Todos los campos son obligatorios!",
        //        icon: "error",
        //        button: "Entendido !",
        //    });
        //}


    }

    async AgregarCliente() {
        this.setState({showSpinner:true});
        let nombreCl = document.getElementById('nombreAddCliente').value;
        let apellidoCl = document.getElementById('apellidoAddCliente').value;
        let rutCl = document.getElementById('rutAddCliente').value;
        let telefonoCl = this.state.phoneAdded;
        let emailCl = document.getElementById('emailAddCliente').value;
        let direccion = document.getElementById('inpDireccion').value;
        let region = document.getElementById('ddlRegion').value;
        let comuna = document.getElementById('ddlComuna').value;
        let t = document.getElementById("ddlComuna");
        let descComuna = t.options[t.selectedIndex].text
        var infoCliente = {};
        if (rutCl !== '' &&
            nombreCl !== ''  && 
            apellidoCl !== '' &&
            telefonoCl !== '' &&
            emailCl !== '' && 
            direccion !==''
        ) {

            if (!this.checkRut(rutCl)) {
                swal({
                    title: "ERROR !",
                    text: "RUT Inválido!",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }

            validate(emailCl);
            if (!res) {
                swal({
                    title: "ERROR !",
                    text: "Dirección de email incorrecta!",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }



            infoCliente.Rut= rutCl;
            infoCliente.Nombre= nombreCl;
            infoCliente.Apellidos = apellidoCl;
            infoCliente.Telefono = telefonoCl;
            infoCliente.Email = emailCl;
            infoCliente.Comuna = descComuna
            infoCliente.ComunaID = comuna;
            infoCliente.Direccion =direccion;
            infoCliente.SucursalID = GetUserCookie().SucursalID;
            infoCliente.Usuario=GetUserCookie().id;


            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/CrearCliente', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(infoCliente)
            });
            if (respuesta.ok) {
                await this.BuscarClienteRut(rutCl, nombreCl);
                await swal({
                    title: "¡Todo ok!",
                    text: "El cliente fue agregado correctamente.",
                    icon: "success",
                    button: "OK",
                });
                this.setState({showSpinner:false});
                setTimeout(() => {
                    this.DesactivarModales();
                }, 700);
            }
            else {
                swal({
                    title: "ERROR !",
                    text: "No se pudo guardar el cliente",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
            }

            /*

            await fetch(GetUrlApi() + '/api/Clientes/AgregarCliente?Nombre=' + nombreCl + '&Apellido=' + apellidoCl + '&Rut=' + rutCl + '&Telefono=' + telefonoCl + '&Email=' + emailCl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(
                (respuesta) => respuesta.json(),
                (error) => {
                    if (error) {
                        swal({
                            title: "ERROR !",
                            text: "Error al enviar datos " + error,
                            icon: "error",
                            button: "Entendido !",
                        });
                    }
                }).then((data) => {
                    if (data) {
                        swal({
                            title: "¡Todo ok!",
                            text: "El cliente fue guardado correctamente.",
                            icon: "success",
                            button: "OK",
                        }).then(() => {
                            this.BuscarClienteRut(rutCl, nombreCl);
                            setTimeout(() => {
                                this.DesactivarModales();
                            }, 700);
                        });
                    } else {
                        swal({
                            title: "ERROR !",
                            text: "No se pudo guardar el cliente",
                            icon: "error",
                            button: "Entendido !",
                        });
                    }
                });*/
        } else {
            swal({
                title: "ERROR !",
                text: "Todos los campos son obligatorios!",
                icon: "error",
                button: "Entendido !",
            });
        }
        this.setState({showSpinner:false});
    }

    async AgregarClienteEmpresa() {
        this.setState({showSpinner:true});
        let nombreCl = document.getElementById('nombreAddClienteEmpresa').value;
        // let apellidoCl = document.getElementById('apellidoAddCliente').value;
        let rutCl = document.getElementById('rutAddClienteEmpresa').value;
        let telefonoCl = this.state.phoneAdded;
        let emailCl = document.getElementById('emailAddClienteEmpresa').value;
        let direccion = document.getElementById('inpDireccionEmpresa').value;

        // let programaCliente  = document.getElementById('ddlProgramaAddCliente').value;
        let region = document.getElementById('ddlRegionEmpresa').value;
        let comuna = document.getElementById('ddlComunaEmpresa').value;
        let t = document.getElementById("ddlComunaEmpresa");
        let descComuna = t.options[t.selectedIndex].text

        let razonSocial = document.getElementById('nombreAddClienteEmpresa').value;
        let giroComercial = document.getElementById('giroComercialAddClienteEmpresa').value;

        var infoCliente = {};
        if (rutCl !== '' &&
            razonSocial !== ''&& 
            telefonoCl !== '' &&
            emailCl !== ''
        ) {

            if (!this.checkRut(rutCl)) {
                swal({
                    title: "ERROR !",
                    text: "RUT Inválido!",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }

            validate(emailCl);
            if (!res) {
                swal({
                    title: "ERROR !",
                    text: "Dirección de email incorrecta!",
                    icon: "error",
                    button: "Entendido !",
                });
                this.setState({showSpinner:false});
                return;
            }


            infoCliente.Rut= rutCl;
            infoCliente.Nombre= nombreCl;
            infoCliente.Apellidos = "";
            infoCliente.Telefono = telefonoCl;
            infoCliente.Email = emailCl;
            infoCliente.Comuna = descComuna
            infoCliente.ComunaID = comuna;
            infoCliente.Direccion =direccion;
            infoCliente.SucursalID = GetUserCookie().SucursalID;
            infoCliente.SucursalIDBlinamic = GetUserCookie().Sucursal.sucursalIDBlinamic;
            infoCliente.Usuario=GetUserCookie().id;
            infoCliente.RazonSocial = razonSocial;
            infoCliente.GiroComercial = giroComercial;


            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/CrearClienteEmpresa', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(infoCliente)
            });

           

            if (respuesta.ok) {
                await this.BuscarClienteRut(rutCl, nombreCl);
                await swal({
                    title: "¡Todo ok!",
                    text: "El cliente fue agregado correctamente.",
                    icon: "success",
                    button: "OK",
                });
                this.setState({showSpinner:false});
                setTimeout(() => {
                    this.DesactivarModales();
                }, 700);
            }
        } else {
            swal({
                title: "ERROR !",
                text: "Todos los campos son obligatorios!",
                icon: "error",
                button: "Entendido !",
            });
            
        }
        this.setState({showSpinner:false});
    }

    async BuscarCliente() {
        document.getElementById('msgerror').style.display = 'block';
        let RutCliente = document.getElementById('RutCliente').value;
        let validaRut = RutCliente != '' ? this.checkRut(RutCliente) : true;
        if (validaRut) {
            let NombreCliente = document.getElementById('NombreCliente').value;
            if (RutCliente == '' && NombreCliente==''){
                swal('Ingrese al menos un parámetro de búsqueda');
                return;
            }

            var rutAux = RutCliente;
            // Despejar Puntos
            var valor = rutAux.replaceAll('.', '');
            // Despejar Guión
            valor = valor.replaceAll('-', '');
            // Aislar Cuerpo y Dígito Verificador
            const cuerpo = valor.slice(0, -1);
            let dv = valor.slice(-1).toUpperCase();
            // Formatear RUN
            rutAux = cuerpo + '-' + dv

            var myBody = {};
            myBody.rut = RutCliente;
            myBody.nombre = NombreCliente;
            console.log(myBody);
            let empresa = GetUserCookie().EmpresaID;
            var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + rutAux + '&NombreCliente=' + NombreCliente  + '&EmpresaID=' + empresa,  {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    if (result.length==0){
                         Swal.fire(
                            'Error!',
                            'No se encontró informacion de Clientes' ,
                            'error'
                          );
                          
                          this.setState({ ConsultaClientes: result});
                          return;
                    }
                    this.setState({ ConsultaClientes: result, TieneDatosCliente: true });
                }
            );
            //let datos = respuesta.json();
            console.log(this.state.ConsultaClientes);

        } else {
            //$("#msgerror").html("El Rut no es válido.");
            await Swal.fire(
                'Error!',
                'El RUT no es válido' ,
                'error'
              )
        }
    }

    async BuscarClienteRut(rut, NombreCliente) {
        let empresa = GetUserCookie().EmpresaID;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetClientes?RutCliente=' + rut + '&NombreCliente=' + NombreCliente + '&EmpresaID=' + empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                if (result.length > 0) {
                    this.DesplegarDatosCliente(result[0].id);
                    return;
                }
            }
        ).catch(error => {
            swal({
                title: "Error al recuperar datos del cliente",
                text: "Por favor ingrese a 'Identificar Cliente' y busque ingresando los datos recientemente guardados.",
                icon: "warning",
                button: "OK",
            });
        });
    }

    async DesplegarDatosCliente(idCliente) {
        //let RutCliente = document.getElementById('RutCliente').value;
        //let NombreCliente = document.getElementById('NombreCliente').value;
        let empresa = GetUserCookie().EmpresaID;
        var respuesta = await fetch(GetUrlApi() + '/api/Clientes/GetDataClientes?IdCliente=' + idCliente + '&EmpresaID=' + empresa, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                console.log(result);
                if (!this.state.tieneDatosBoleta) {
                    this.setState({
                        DataCliente: {
                            id: result.id,
                            nombre: result.nombre,
                            rut: result.rut,
                            telefono: result.telefono,
                            email: result.email,
                            fechaUltServicio: result.fechaUltServicio,
                            montoUltServicio: result.montoUltServicio,
                            fechaUltCompra: result.fechaUltCompra,
                            montoUltCompra: result.montoUltCompra,
                            estrellas: result.estrellas,
                            ciudad: result.ciudad,
                            comuna: result.comuna,
                            direccion: result.direccion,
                            girocomercial: result.giroComercial,
                            razonSocial: result.razonSocial,
                        },
                        tieneDatosCliente: true,
                        PedirTipoDocumento: true
                    });
                    setTimeout(() => {
                        if ((this.state.DataCliente.direccion === '' || this.state.DataCliente.razonSocial === '' || this.state.DataCliente.girocomercial === '') && this.state.Ambiente != 'MAISA') {
                            this.setState({ activarPopUpDireccionRazonSocial: true });
                            document.getElementById('direccionAddCliente').value = this.state.DataCliente.direccion;
                            document.getElementById('razonSocialAddCliente').value = this.state.DataCliente.razonSocial;
                            document.getElementById('giroAddCliente').value = this.state.DataCliente.girocomercial;
                            
                        }
                    }, 500);
                } else {
                    this.setState({
                        DataCliente: {
                            id: result.id,
                            nombre: result.nombre,
                            rut: result.rut,
                            telefono: result.telefono,
                            email: result.email,
                            fechaUltServicio: result.fechaUltServicio,
                            montoUltServicio: result.montoUltServicio,
                            fechaUltCompra: result.fechaUltCompra,
                            montoUltCompra: result.montoUltCompra,
                            estrellas: result.estrellas,
                            ciudad: result.ciudad,
                            comuna: result.comuna,
                            direccion: result.direccion,
                            girocomercial: result.giroComercial,
                            razonSocial: result.razonSocial,
                        },
                        tieneDatosCliente: true,
                    });
                    setTimeout(() => {
                        if ((this.state.DataCliente.direccion === '' || this.state.DataCliente.razonSocial === '' || this.state.DataCliente.girocomercial==='')) {
                            this.setState({ activarPopUpDireccionRazonSocial: true });
                            document.getElementById('direccionAddCliente').value = this.state.DataCliente.direccion;
                            document.getElementById('razonSocialAddCliente').value = this.state.DataCliente.razonSocial;
                            document.getElementById('giroAddCliente').value = this.state.DataCliente.girocomercial;
                        }
                    }, 500);
                }
             
            });
        //let datos = respuesta.json();
        console.log(this.state.DataCliente);
        //this.setState({ IsClienteObligatorio: false });
        this.DesactivarModales();
    }

    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivosDevolucions/ObtenerMotivos', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ MotivosDevolucion: datos });
        }
    }

    async BuscarBoleta() {
        debugger
        if (this.state.Ambiente=='MAISA'){
            let numdoc= document.getElementById('NumBoleta').value;
            let suc= document.getElementById('ddlSucursales').value;
            var val = suc + numdoc;
        }else{
            var val = document.getElementById('NumBoleta').value;
        }
   
        this.setState({productsInOthersNC: [],ShowProductsInOthersNC:false});


        var check = document.getElementById('EsFactura');
        var tipoDoc = this.state.TipoDocumento;
        if (tipoDoc == 0) {
            this.setState({ PedirTipoDocumento: true,PedirDocumento:false});
            return;
        }

        var respuesta = await fetch(GetUrlApi() + '/api/Orden/ObtenerBoleta?NumDoc=' + val + '&TipoDoc=' + tipoDoc, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        //    .then(res => res.json()).then(
        //    (result) => {

        //        console.log(result);
        //    }
        //);
        if (respuesta.ok) {
            var data = await respuesta.json();
            console.log("buscar boleta", data);
            if (data.carroProductos.length > 0) {
                var d1 = new Date();
                d1.setHours(0,0,0,0);
                
                const [day, month, year] = data.fechaVenta.split('-');
                var d2 = new Date(+year, month - 1, +day);
    
                var days = Number(this.state.DiasMaxDevolucion);
    
                var fechaMaxDevo = new Date(d2);
                fechaMaxDevo.setDate(fechaMaxDevo.getDate() + days);
                
                if (fechaMaxDevo < d1){
                    swal({
                        title: "No se puede generar Nota de Crédito por esta Venta ya que su fecha para devolución expiro.",
                        icon: "error",
                        dangerMode: true,
                    });
                    document.getElementById('NumBoleta').value = '';
                    return; 
                }
                
                if (data.mediosPagoOrigen.length === 0){
                    swal({
                        title: "Problemas al obtener datos de origen para esta Nota de Crédito, contacte con soporte.",
                        icon: "error",
                        dangerMode: true,
                    });
                    document.getElementById('NumBoleta').value = '';
                    return; 
                }
                this.setState({ mediosPorOrdenVoid: data.mediosPorOrden });
                if (data.nroCpte != null) {
                    this.CarroProductosOriginal = data.carroProductos;
                    if (this.state.MotivoDevolucionID != 0) {
                        this.setState({ boleta: data, tieneDatosBoleta: true, PedirDocumento: false, NumeroDocumento: data.nroCpte });

                        console.log(data);
                    } else {
                        try{
                            let rut = data.codigoCliente.split('-')[0];
                            
                            await this.TieneCanjePuntos(rut,data.nroCpte);
                            if (this.ExisteCanje){
                                let rutnc = this.state.DataCliente.rut.split('-')[0];
                               /*if (rut != rutnc){ //quitamos validacion de comprador vs cliente nc
                                    swal({
                                        title: "Advertencia",
                                        icon: "warning",
                                        text: "La venta se hizo con canje de puntos, y el rut ingresado no es el mismo de la Venta. Debe ingresar el rut del comprador para anular sus puntos.",
                                    }).then(() => {
                                        this.LimpiarVentana();
                                    });
                                }*/
                                this.setState({MotivoDevolucionID:4}); //devolucion de dinero
                            }

                        }catch(e){

                        }
                        this.setState({ boleta: data, tieneDatosBoleta: true, PedirDocumento: false, NumeroDocumento: data.nroCpte, PedirMotivoDevolucion: true });
                        console.log(data);
                    }
                    this.setState({ hasHten: false });
                    this.state.mediosPorOrdenVoid.map((m) => {
                        // Decode HTEN data to send a void 
                        if (m.numTarjeta != null) {
                            this.setState({ hasHten: true });
                            this.setState({ MostrarConfirmoSeleccion: true });
                        }
                    });

                } else {
                    swal("Documento no encontrado");
                    console.log(data);

                }
            } else {
                if (data.productosInOthersNC.length >0) {
                    this.setState({productsInOthersNC: data.productosInOthersNC, ShowProductsInOthersNC:true});
                }
                else{
                    swal("El documento no tiene productos para realizar la nota de crédito");
                }
                
               //swal("El documento no tiene productos para realizar la nota de crédito");
                document.getElementById('NumBoleta').value = '';
                return;
            }



        } else {

            console.log(respuesta);
        }
    }

    async TieneCanjePuntos (rutcliente, nrocpte){
        let token = await sessionStorage.getItem('jwtToken');
        try{ 
            var respuesta = await fetch(GetUrlBlinamicApi() + '/api/ProgramaPuntos/getExisteCanje?RutCliente='+ rutcliente +'&nrocpte=' + nrocpte, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization" : "Bearer " + token + ""
                }
            }).then(res => res.json()).then(
                (result) => {
                    
                    if (result.data){
                        this.ExisteCanje = true;
                     }
                     else{
                        this.ExisteCanje = false;
                     }
                    return;
                });
        }
       catch (e){
            this.ExisteCanje = false;
                   //console.log(e);
       }
       return;
    }

    ConfirmarSeleccionMotivo()
    {
        var idMotivo = document.getElementById("MotivoDevolucion").value;
        if (idMotivo === "0") {
            swal('No puedes ingresar Nota de Crédito sin Motivo de Devolución');
            this.setState({ cargandoIsSelected: false });

            return;
        } else {
            let findMotivo = this.state.MotivosDevolucion.find(t => t.id == idMotivo);
            
            this.setState({ MotivoDevolucionID: idMotivo, MotivoDevolucion: findMotivo.motivo, PedirMotivoDevolucion:false});
        }
    }

    CheckFP (idTr){

    }

    AgregarADevolucion(IdTr) {
        var indiceTr = this.Devoluciones.indexOf(IdTr);
        console.log(indiceTr);
        if (indiceTr !== -1) {
            this.Devoluciones.splice(indiceTr, 1);
            document.getElementById("chkSelectAll").checked = false;
        } else {
            this.Devoluciones.push(IdTr);
            var table = document.getElementById("TablaDeDatos");
            if(table.rows.length == this.Devoluciones.length){
                document.getElementById("chkSelectAll").checked = true;
            }
        }
        this.setState({ MostrarConfirmoSeleccion: true });

        console.log(this.Devoluciones);
    }

    clickConfirmar() {

    }

    ConfirmarSeleccion() {

        console.log(this.Devoluciones);
        console.log(this.ResultadoBusqueda);
        var array = this.Devoluciones;
        
        var arrCarro = this.state.boleta.carroProductos;
        
        var arrCopia = [];
        console.log(arrCarro);
        if (array.length == 0){
            swal('Debes seleccionar al menos un producto.');
            return;
        }
        this.ResultadoBusqueda.map(function (item) {
            console.log(item);
            var indiceTr = array.indexOf(item);
            console.log(indiceTr);

            if (indiceTr >= 0){
                var trID = item.replace('Tr', '');
                arrCopia.push(arrCarro[trID]);
            }
           /* if (indiceTr == -1) {
                var trID = item.replace('Tr', '');
                console.log("Quitar " + trID);
                //var found = arrCarro.find(element => element.plu == trID);
                var found = arrCarro[trID];
              
                console.log(found);

                
                
                var indiceTr = arrCarro.indexOf(found);
                console.log(indiceTr);
                if (indiceTr !== -1) {
                    if (indiceTr !== -1) {
                        arrCarro.splice(indiceTr, 1);
                    }
                }
                console.log(arrCarro);
            }*/

        });

        arrCarro = arrCopia;
        this.state.boleta.carroProductos = arrCarro;
        let idFila = "";

        var table = document.getElementById("TablaDeDatos");
        for (var i = 0, row; row = table.rows[i]; i++) {

            idFila = 'InputTd' + i;
            document.getElementById(idFila).readOnly = true;

            //iterate through rows 
            //rows would be accessed using the "row" variable assigned in the for loop 
           // for (var j = 0, col; col = row.cells[j]; j++) {
                //iterate through columns 
                //columns would be accessed using the "col" variable assigned in the for loop 
                //console.log("Posicion :" + j + " Valor: " + row.cells[j]);

                //console.log(row.cells[j]);

                let cantidad = row.cells[1].lastChild.value;
                let CodigoProducto = row.cells[2].innerText;
                let indexFound = this.state.boleta.carroProductos.findIndex(t => t.desprod.includes(CodigoProducto));
                if (indexFound!=-1) {

                    let cantidadProductoPermitida = this.state.boleta.carroProductos[indexFound].cantidad;
                    let indexFoundCPO = this.CarroProductosOriginal.findIndex(t => t.desprod.includes(CodigoProducto));


                    if (parseInt(cantidad) > cantidadProductoPermitida) {
                        swal('No puedes ingresar Una Cantidad Mayor a la registrada en el documento');
                        return;
                    }
                    else{
                        let cant = cantidad ? cantidad : cantidadProductoPermitida;
                        this.state.boleta.carroProductos[indexFound].cantidad = cant;
                    }

                }
                //if (cantidad != "") {
                //    this.state.boleta.carroProductos[indexFound].cantidad = cantidad;
                //} else {
                //    this.state.boleta.carroProductos[indexFound].cantidad = this.CarroProductosOriginal[indexFoundCPO].cantidad;

                //}


           // }
        }

      
        
        var totalPrecioUnitario = 0;
        var totalSubtotal = 0;
        let idx = 0;
        arrCarro.forEach(function (element) {
            totalPrecioUnitario += parseInt(element.precpred);
            totalSubtotal += parseInt(element.valorTotal);
            idx +=1;
        });
        this.state.boleta.totalPrecioUnitario = totalPrecioUnitario;
        this.state.boleta.totalSubTotal = totalSubtotal;


        //si nc queda con precio 0, setea al ultimo producto 1 peso
         
        if (totalSubtotal===0){
            arrCarro[idx-1].valorTotal=1;
            this.state.boleta.carroProductos = arrCarro;
            this.state.boleta.totalSubTotal = 1;
        }
        
        var subtotal = this.state.boleta.totalSubTotal; 
        // if (this.state.Ambiente == 'MAISA'){
        let sumamedios=0;
        let sumaprop = 0;
        this.state.boleta.mediosPagoOrigen.forEach(function (item) {
            sumamedios += item.monto;
        })
        this.state.boleta.mediosPagoOrigen.forEach(function (item) {
            
            var proporcion = 0;
            var montoProp = 0;
            proporcion = (item.monto * 100.00) / sumamedios; //% proporcion   
            montoProp = (subtotal * proporcion) / 100.00; // monto Proporcionado
            item.montoProp = Math.round(montoProp);
            sumaprop += Math.round(montoProp);
        })
        let existsCh = 0;
        this.state.boleta.mediosPagoOrigen.forEach(function (item) {
            if (item.tbMedioID==16){
                existsCh=1;
            }
        });
        
        if (existsCh == 1 && sumaprop != sumamedios && this.state.MotivoDevolucionID==="4"){
            swal({
                title: "Atención",
                icon: "error",
                text: "Venta contiene Cheques, no puede devolverse de forma parcial",
                dangerMode: true
                }).then(() => {
                    this.LimpiarVentana();
                });
            return;
        }
        //if (existsCh==1){
            //   this.setState({activarFormasPago:true});
            //   return;
        //}
            
        if (this.state.MotivoDevolucionID==="4"){
            this.setState({activarFormasPago:true});
            return;
        }
    
        // }
        this.setState({ boleta: this.state.boleta, ConfirmoSeleccion: true, CheckListo: true })

        document.getElementById("chkSelectAll").hidden = true;
    }

    async CallToVoidHten(myBody, ipHten, isQr, idQr) {
        const endpoint = isQr? 'wallet/payment/' + idQr + '/refund':'anularCompra'
        return await new Promise(async (resolve) => {
              
            await fetch('http://' + ipHten + '/api/' + endpoint, {
                method: 'post',
                dataType: 'jsonp',
                async: false,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(respuesta => {
                console.log("Respuesta Anul ", respuesta);
                return respuesta.json();
            }).then(async (data) => {
                console.log(data);
                this.setState({ cargando: false });
                if (await this.processResponse(data)) {
                    Swal2.fire({
                        title: '<strong>' + isQr?'Anulación Autorizada': (this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRespuesta) + '</strong>',
                        text: 'El pago ha sido anulado',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'success',
                    }).then(() => {
                        resolve(this.state.htenJsonResponse);
                    });
                } else {
                    Swal2.fire({
                        title: '<strong>' + this.state.htenJsonResponse.codigoRespuesta + '-' + this.state.htenJsonResponse.glosaRta + '</strong>',
                        text: 'La anulación NO ha sido autorizada - Se hace devolución forzada',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'CONTINUAR',
                        animation: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                        icon: 'warning',
                    }).then(() => {
                        resolve("ERROR");
                    });
                }
            }).catch((reason) => {
                console.log(reason);
                this.setState({ cargando: false });
                Swal2.fire({
                    title: '<strong>ERROR</strong>',
                    text: 'No logramos conectar con HTEN ' + reason + ' - Llame a soporte técnico',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'CONTINUAR',
                    animation: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    closeOnClickOutside: false,
                    icon: 'error',
                }).then(() => {
                    resolve("ERROR");
                });
            });
        });        
    }

    async clickEnviarSeleccion() {
        if(this.isHten() ) {
            this.setState({ pagosHtenDevolucion: [] })
            let devToProcess = []
            var idMotivo = this.state.MotivoDevolucionID
            const isMoneyDevol = this.state.MotivosDevolucion.filter((m) => m.id === parseInt(idMotivo))
            if (isMoneyDevol[0].motivo === "Devolucion de Dinero") {
                const ipHten = localStorage.getItem('ipHten');
                const userData = JSON.parse(sessionStorage.getItem('userProfile'));
                console.log("userData ", userData);
                console.log("userData type ", typeof userData);
                console.log("Medios ", this.state.mediosPorOrdenVoid);
                for (let pos = 0; pos < this.state.boleta.mediosPagoOrigen.length; pos++) {
                // this.state.boleta.mediosPagoOrigen.map(async (item, index) => {
                    const item = this.state.boleta.mediosPagoOrigen[pos]
                    const SelectID = "Sel_" + pos;
                    const val = $('#' + SelectID).val()
                    console.log('mdp', item)
                    console.log('select value', val)
                    for (let z = 0; z < this.state.mediosPorOrdenVoid.length; z++) {
                        const m = this.state.mediosPorOrdenVoid[z]
                        item.numTarjeta = m.numTarjeta
                        // item.numeroOrden = m.numeroOrden
                        if(val === "2" && item.tbMedioID === m.tbMedioID && m.numTarjeta != null && (m.tbMedioID === 3 || m.tbMedioID === 38)){
                            devToProcess.push(item)
                            break
                        }
                        else if(val === "2" && item.tbMedioID === m.tbMedioID && m.numTarjeta != null && m.tbMedioID === 2) {
                            await Swal2.fire({
                                title: '<strong>ATENCIÓN</strong>',
                                text: 'Los pagos con débito se deben anular desde el portal de TBK.',
                                showDenyButton: false,
                                showCancelButton: false,
                                confirmButtonText: 'CONTINUAR',
                                animation: false,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                closeOnClickOutside: false,
                                icon: 'warning',
                            });
                            break
                        }
                    }
                }
                // })
            }
            if(devToProcess.length > 0){
                this.setState({ pagosHtenDevolucion: devToProcess })
                this.setState({ visibleProcesarDevolucion: true })
            } else
                this.EnviarSeleccion()
            
        }
        else
            this.EnviarSeleccion()
    }
    finishHtenDev(medios) {
        for (let pos = 0; pos < medios.length; pos++) {
            const medioDev = medios[pos]
            for (let z = 0; z < this.state.mediosPorOrdenVoid.length; z++) {
                const m = this.state.mediosPorOrdenVoid[z]
                if(medioDev.tbMedioID === m.tbMedioID) {
                    m.numTarjeta =  medioDev.numTarjeta
                }
            }
        }
        this.CerrarModalProcesarPago()
        this.EnviarSeleccion()
    }
    async EnviarSeleccion() {
        console.log('EnviarSeleccion')
        this.setState({ cargandoIsSelected: true });
        var tipoDoc = this.state.TipoDocumento;
        var carroProductos = this.state.boleta.carroProductos;
        var DataObj = this.state.notaCredito;

        if (!this.state.tieneDatosBoleta) {
            swal('No puedes ingresar Nota de Crédito sin Boleta');
            this.setState({ cargandoIsSelected: false });
            return;
        }
        if (!this.state.tieneDatosCliente) {
            swal('No puedes ingresar Nota de Crédito sin Cliente');
            this.setState({ cargandoIsSelected: false });

            return;
        }
        var fechaDevolucion = document.getElementById("FechaDevolucion").value;

        if (fechaDevolucion === "") {
            swal('No puedes ingresar Nota de Crédito sin Fecha');
            this.setState({ cargandoIsSelected: false });

            return;
        }
        var idMotivo = this.state.MotivoDevolucionID;
        if (idMotivo === "0") {
            swal('No puedes ingresar Nota de Crédito sin Motivo de Devolución');
            this.setState({ cargandoIsSelected: false });

            return;
        }
        //var MotivoLibre = document.getElementById("MotivoLibre").value;


        var table = document.getElementById("TablaDeDatos");
        for (var i = 0, row; row = table.rows[i]; i++)
        {
            //iterate through rows 
            //rows would be accessed using the "row" variable assigned in the for loop 
            for (var j = 0, col; col = row.cells[j]; j++)
            {
                //iterate through columns 
                //columns would be accessed using the "col" variable assigned in the for loop 
                //console.log("Posicion :" + j + " Valor: " + row.cells[j]);

                //console.log(row.cells[j]);

                let cantidad = row.cells[1].lastChild.value;
                let CodigoProducto = row.cells[2].innerText;
                let indexFound = carroProductos.findIndex(t => t.desprod.includes(CodigoProducto));
                let indexFoundCPO = this.CarroProductosOriginal.findIndex(t => t.desprod.includes(CodigoProducto));

                if (cantidad!="") {
                    carroProductos[indexFound].cantidad = cantidad;
                } else {
                    carroProductos[indexFound].cantidad = this.CarroProductosOriginal[indexFoundCPO].cantidad;

                }

            }
        }

        // Busco totales para ver como devolvemos a HTEN
        var tablaTotales = document.getElementById("TablaTotales");

        console.log("totales ", tablaTotales.rows);
        console.log("totales ", tablaTotales.rows[0]);
        let total_a_devolver = tablaTotales.rows[0].cells[6].innerText.replaceAll("$", "").replaceAll(".", "").replaceAll(",", "");


        // Si funciona la nota de credito, hago la devol en TBK.
        const isMoneyDevol = this.state.MotivosDevolucion.filter((m) => m.id === parseInt(idMotivo))
        // Chequear que sea de HTEN
        let imprimeHTEN = false;
        let hasDebit = false;
        /* if(this.isHten() ) {
            if (isMoneyDevol[0].motivo === "Devolucion de Dinero") {
                const ipHten = localStorage.getItem('ipHten');
                const userData = JSON.parse(sessionStorage.getItem('userProfile'));
                console.log("userData ", userData);
                console.log("userData type ", typeof userData);
                console.log("Medios ", this.state.mediosPorOrdenVoid);
                for (let pos = 0; pos < this.state.boleta.mediosPagoOrigen.length; pos++) {
                // this.state.boleta.mediosPagoOrigen.map(async (item, index) => {
                    const item = this.state.boleta.mediosPagoOrigen[pos]
                    const SelectID = "Sel_" + pos;
                    const val = $('#' + SelectID).val()
                    console.log('mdp', item)
                    console.log('select value', val)
                    
                    for (let z = 0; z < this.state.mediosPorOrdenVoid.length; z++) {
                        const m = this.state.mediosPorOrdenVoid[z]
                        if(val === "2" && item.tbMedioID === m.tbMedioID && m.numTarjeta != null){
                            // const mPago = this.state.mediosPorOrdenVoid.filter(mp => mp.tbMedioID === item.tbMedioID && mp.numTarjeta != null )
                            //mPago.map(async (m) => {
                            const htenResponse = JSON.parse(m.numTarjeta);
                            if (Object.keys(htenResponse).length == 0) {
                                return
                            }
        
                            let myBody = {}
                            let isqr = false
                            let payment = { id: 0 }
                            if(htenResponse.entity === 'BCI') {
                                payment = htenResponse.payment
                                myBody = {
                                        "amount": payment.amount,
                                        "employeeNumber": 12345678,
                                        "posTransactionId": "ABCD-1234-5678",
                                        "entity": "BCI"
                                    }
                                isqr = true
                            }
                            else {
                            
                                if (htenResponse.tipoTarjeta === 'DB' || htenResponse.tipoTarjeta === 'DEBITO')
                                    hasDebit = true;
                                
                                myBody.numeroEmpleado = userData.id + "";
                                myBody.montoAnulacion = item.montoProp + ''; // htenResponse.monto + "";
                                myBody.montoPropina = "-1";
                                myBody.montoVuelto = "-1";
                                myBody.codigoMoneda = "CL";
                                myBody.numeroBoleta = this.state.boleta.numeroOrden + "";
                                myBody.tipoTarjeta = (htenResponse.tipoTarjeta==='CREDITO')?'CR':htenResponse.tipoTarjeta;
                                myBody.numeroUnicoTransaccionOriginal = htenResponse.numeroUnicoTransaccion + "";
                            }
                            // if (localStorage.getItem('htenDemo') != null && localStorage.getItem('htenDemo') == "SI") {
                            //     myBody.ultimos4 = "demo";
                            // }
                            // if(hasDebit)
                            //    break;
                            if(!hasDebit){
                                // if(m.AnulacionHten){
                                const response = await this.getResponseHten(myBody, ipHten, isqr, payment.id)
                                if (response !== "ERROR") {
                                    imprimeHTEN = true;
                                    m.numTarjeta = response;
                                    console.log("Fin de anulacion ");
                                }
                                //}
                            }
                            // })
                        }
                    }
                }
                // })
            }
        } else {
            for (let pos = 0; pos < this.state.mediosPorOrdenVoid.length; pos++) {
                const m = this.state.mediosPorOrdenVoid[pos];
            //  const mapPromise = this.state.mediosPorOrdenVoid.map(async (m) => {
            // Decode HTEN data to send a void 
                if (m.numTarjeta == null) {
                    continue;
                }
                const responseMedio = JSON.parse(m.numTarjeta);
                if (Object.keys(responseMedio).length == 0) {
                    break
                }
                if (responseMedio.tipoTarjeta === "DB")
                {
                    hasDebit = true;
                    break
                }
            }
        } */
        

        console.log("Medios Anul ", this.state.mediosPorOrdenVoid);
        let montoEfectivo = 0;
        let objFormasPago=[];
        if (this.state.Ambiente=='MAISA' && idMotivo == 4){


            let tbformas = document.getElementById('TbFormasPago');
            try {
                for (var i = 1, row; row = tbformas.rows[i]; i++)
                {
                    try
                    {
                     
                        let desc = row.cells[1].innerText;
                        let montoCh = GetNumero(row.cells[2].innerText);
                        let fecCh = row.cells[4].innerText;
                        let numCh = row.cells[5].innerText;
                        let bcoCh = row.cells[6].innerText;
                        let tipoDev =  row.cells[7].lastChild.value;
                        let objfp = this.state.boleta.mediosPagoOrigen[i-1];
                        if (objfp.tbMedioID===16){
                            
                            if (objfp.monto != objfp.montoProp && tipoDev == 2){
                                swal('No puedes seleccionar este tipo de devolución para el Cheque N° ' + numCh);
                                this.setState({ cargandoIsSelected: false });
                                return;
                            }
                            var d1 = new Date();
                            d1.setHours(0,0,0,0);
                            
                            const [day, month, year] = objfp.fechaCheque .split('-');
                            var d2 = new Date(+year, month - 1, +day);
            
                            if (d2 < d1 && tipoDev == 2){
                                swal('No puedes seleccionar este tipo de devolución, debido a la fecha del Cheque N° ' + numCh);
                                this.setState({ cargandoIsSelected: false });
                                return;
                            }
                            if (d2 >= d1 && tipoDev == 1){
                                swal('No puedes seleccionar devolución en efectivo, debido a la fecha del Cheque N° ' + numCh);
                                this.setState({ cargandoIsSelected: false });
                                return;
                            }
                            
                        }
                        if (objfp.tbMedioID === 30 && tipoDev == 2){ //giftcard
                            swal('No puedes seleccionar este tipo de devolución para Giftcard.');
                            this.setState({ cargandoIsSelected: false });
                            return;
                        }
                        //var forma = this.state.boleta.mediosPagoOrigen.find(p => p.numCheque === Number(numCh) && p.fechaCheque === fecCh && p.descBanco === bcoCh && p.descripcion === desc);
                        objfp.tipoDev = tipoDev;
                        if(tipoDev==1){
                            montoEfectivo = montoEfectivo + objfp.montoProp;
                        }
                        objFormasPago.push(objfp);
                    }
                    catch(e){  
                        console.log(e);
                    }
                }
                if (this.state.ValidaEfectivo === 'true'){
                    if (montoEfectivo > this.EfectivoCaja){
                        swal({
                            title: "Atención",
                            icon: "error",
                            text: "El monto efectivo a devolver por esta Nota de Crédito es superior al monto Efectivo disponible en Caja",
                            dangerMode: true
                            }) 
                            this.setState({ cargandoIsSelected: false });
                             return;
                    }
                }
              
            }
            catch(e){   }
          
        }
        else{
            objFormasPago = this.state.boleta.mediosPagoOrigen;
        }
          
      /*
            let countMPTot = this.state.boleta.mediosPagoOrigen.length;
            let countMPAdd = 0;
            this.state.boleta.mediosPagoOrigen.forEach(function (item) {
                if (item.tbMedioID!=16){
                    objFormasPago.push(item);
                    countMPAdd = countMPAdd + 1;
                }
            });
            
            if (countMPAdd < countMPTot){ // hay cheques 
                let tbcheques = document.getElementById('TbFormasPago');
                try {
                    for (var i = 1, row; row = tbcheques.rows[i]; i++)
                    {
                        try
                        {
                         
    
                            let montoCh = GetNumero(row.cells[2].innerText);
                            let fecCh = row.cells[3].innerText;
                            let numCh = row.cells[4].innerText;
                            let bcoCh = row.cells[5].innerText;
                            let tipoDev =  row.cells[6].lastChild.value;
    
                            var chequeFP = this.state.boleta.mediosPagoOrigen.find(p => p.numCheque === Number(numCh) && p.fechaCheque === fecCh && p.descBanco === bcoCh);
                            chequeFP.tipoDev = tipoDev;
                            objFormasPago.push(chequeFP);
                        }
                            catch(e){   }
                    }
                }
                catch(e){   }
              }*/
     
       
        this.setState({activarFormasPago:false});
        console.log(this.state.boleta);
        console.log(this.state.tieneDatosCliente);
        console.log(fechaDevolucion);
        console.log(idMotivo);

        var cliente = {};


        cliente.Rut = this.state.DataCliente.rut;
        cliente.Nombre = this.state.DataCliente.nombre;
        cliente.Telefono = this.state.DataCliente.telefono;
        cliente.Email = this.state.DataCliente.email;
        cliente.NotasDeCreditoID = 0;

        DataObj.NumeroComprobante = this.state.boleta.nroCpte;
        DataObj.MotivosDevolucionID = idMotivo;
        DataObj.FechaDevolucion = fechaDevolucion;
        
        DataObj.ProductosDevolucion = this.state.boleta.carroProductos;
        DataObj.ClienteNotasDeCredito = cliente;
        DataObj.MotivoLibre = "";
        DataObj.tipoDoc = tipoDoc;
        let mp_list = [];
        // Agrego medios de pago por nc
        console.log("Lista de medios de pago ", this.state.mediosPorOrdenVoid);
        await this.state.mediosPorOrdenVoid.map((m) => {
            const mpFinal = {}
            mpFinal.tbMedioID = m.tbMedioID;
            mpFinal.total = m.totalReal;
            mpFinal.numTarjeta = JSON.stringify(m.numTarjeta);

            mp_list.push(mpFinal);
        });
        DataObj.MediosPorNcs = mp_list;

        console.log("Nota de credito a guardar ", DataObj);

        let data = {};
        data.NotaDeCredito = DataObj;
        data.SucursalDevolucionId = GetUserCookie().Sucursal.sucursalIDBlinamic;
        data.CajaExternaID= GetUserCookie().CajaExternaID; //GetUserCookie().CajaID;
        data.CajaID = GetUserCookie().CajaID;
        data.UsuarioID = GetUserCookie().id;
        data.ambiente = this.state.Ambiente;
        data.mediosDePago = objFormasPago;


        //para informar de descuento en compra con convenio
        let EncabezadoNCConvenioProductosVenta = {};
        //let dataInformarNCConvenio = {};
        let dataInformarProductosNCConvenio = [];
        EncabezadoNCConvenioProductosVenta.rut = this.state.DataCliente.rut;
        EncabezadoNCConvenioProductosVenta.OrdenID = this.state.boleta.numeroOrden;
        EncabezadoNCConvenioProductosVenta.NroCpte = this.state.boleta.nroCpte;


        this.state.boleta.carroProductos.forEach(function (datos) {
            let dataInformarProductosNCConvenioAux = {};
            dataInformarProductosNCConvenioAux.codigoPlu = datos.plu;
            dataInformarProductosNCConvenioAux.cantidad = datos.cantidad;
            dataInformarProductosNCConvenio.push(dataInformarProductosNCConvenioAux);

        });
        EncabezadoNCConvenioProductosVenta.unDetalleNCConvenioProductosVenta = dataInformarProductosNCConvenio;

        //fin para informar de descuento en compra con convenio

        let mensajes = "";
        let ResultadoMsje = false;

        if (hasDebit) {
            await Swal2.fire({
                title: '<strong>ATENCIÓN</strong>',
                text: 'Los pagos con débito se deben anular desde el portal de TBK.',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                animation: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                closeOnClickOutside: false,
                icon: 'warning',
            });
        }
        //data.numCaja = UserProfile.getUser().;
        var respuesta = await fetch(GetUrlApi() + '/api/NotasDeCredito/ProcesarNotaDeCredito', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(res => res.json()).then(
            (result) => {
                console.log("Resultado Nota Crédito");
                console.log(result);
                ResultadoMsje = result;
                if (ResultadoMsje.resultado) {
                    localStorage.setItem("ultimaOrden", ResultadoMsje.id);
                    localStorage.setItem("tipoUltimaOrden", "nc");
                    this.ClickImprimirHten(ResultadoMsje.folioBsale);
                    // this.LimpiarVentana();
 
                    if (this.state.Ambiente=='MAISA'){
                        swal({
                            title: "Nota de Crédito",
                            text: "Nota de crédito generada correctamente",
                            icon: "success",
                            button: "Entendido !",
                        }).then(() =>
                        {
                            this.InsertarDescuentoConvenioPorNC(EncabezadoNCConvenioProductosVenta);

                            this.LimpiarVentana();
                        });     
                    }
                    if (GetUserCookie().Sucursal.configPOS.openUrlRespuesta) {
                        window.open(ResultadoMsje.urlRespuesta, '_blank');
                        this.LimpiarVentana();
                    }                    
                    //alert("Retorno True");
                } else {
                    //alert("Retorno False");
                    swal({
                        title: "Hubo problemas al generar la Nota de Crédito",
                        text: ResultadoMsje.descripcion,
                        icon: "error",
                        button: "Entendido !",
                    }).then(() => {
                        this.LimpiarVentana();
                    });
                }
                
            }
        ).catch((reason) => {
            console.log(reason);
        });
        //console.log(respuesta.json());
        

        //console.log(DataObj);

    }
    async getResponseHten(myBody, ipHten, isqr, id){
        return await this.CallToVoidHten(myBody, ipHten, isqr, id);
    }
    isHten() {
        return JSON.parse(sessionStorage.getItem('userProfile')).Sucursal.hten;
    }
    processResponse(data) {
        console.log('processResponse', data)
        let jsonObj = {}
        if (data.constructor === ({}).constructor) {
            jsonObj = data;
        } else {
            jsonObj = JSON.parse(data);
        }
        let dataFinal = jsonObj;
        
        if(dataFinal.entity === 'BCI') {
            const refunded = dataFinal.refundedPayment

            dataFinal.glosaRespuesta = dataFinal.statusDescription
            dataFinal.codigoRespuesta = dataFinal.statusCode
            dataFinal.monto = refunded.amount
            // dataFinal.voucher = dataFinal.entityResponse
        }
        console.log('processResponse.dataFinal', dataFinal)
        this.setState({ htenResponse: data });
        this.setState({ htenJsonResponse: dataFinal });
        
        const codRta = dataFinal.codigoRespuesta? parseInt(dataFinal.codigoRespuesta):dataFinal.statusCode
        if (codRta >= 0 && codRta <= 9) {
            return true;
        } else {
            return false;
        }
    }

    async ClickImprimirHten(id) {
        const usaCustomCloudService = localStorage.getItem('usaCustomCloudService')
        if (usaCustomCloudService === "true") {
            this.setState({ cargando: true });
            const userData = sessionStorage.getItem('userProfile');
            const empresa = userData.Sucursal.direccionDeli.split(".")[0];
            const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
            const printer = "printer_" + userData.caja.descripcion;
            var myBody = {
                "company_id": empresa,
                "store_id": sucursal,
                "order_id": "",
                "printer_id": printer,
                "additional_data": "print://ImpresionVoucherNC?" + id,
                "exec_path": "C:\\fbhposprint\\"
            };

            await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myBody),
            }).then(data => data.json()).then(data => {
                console.log(data);
                swal({
                    title: "Enviado a imprimir con código " + data.hten_unique_id,
                    icon: "success",
                    text: "Guarde ese código para consultar estado.",
                }).then(() => {
                    this.setState({ cargando: false });
                });
            }).catch(error => {
                swal({
                    title: "Error al querer imprimir",
                    icon: "error",
                    text: "Por favor contacte al administrador del sistema " + error,
                    dangerMode: true,
                }).then(() => {
                    this.setState({ cargando: false });
                });
            });
        } else {
            this.ClickImprimirDirectoHten(id);
        }
    }

    async ClickImprimirDirectoHten(id) {
        this.setState({ cargando: true });
        var myBody = {};
        myBody.command = "C:\\fbhposprint\\Printer.exe";
        myBody.parameter = "print://ImpresionVoucherNC?" + id;
        myBody.path = "C:\\fbhposprint\\";
        console.log(myBody);

        var respuesta = await fetch('http://localhost:4741/fbhpos', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then(respuesta => respuesta.json()).then((data) => {
            console.log("respuesta a imprimir ", data);
        }).catch((reason) => {
            console.log(reason);
            return false;
        });
        this.setState({ cargando: false });
    }

    InsertarDescuentoConvenioPorNC(EncabezadoNCConvenioProductosVenta)
    {
        let token = sessionStorage.getItem('jwtToken')

        const respuesta2 = fetch(GetUrlBlinamicApi() + '/api/Convenio/SetVentaConvenioNC', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token + ""
            }
            , body: JSON.stringify(EncabezadoNCConvenioProductosVenta)
        }).then(res => res.json()).then(
            (result) =>
            {
                if (result.data.length > 0)
                {

                }
            }
        );
    }

    PreciosPorCantidad(index){

        var table = document.getElementById("TablaDeDatos");
        let carroCopia = this.state.boleta.carroProductos;
        let boletaCopia = this.state.boleta;
        let subtotal = 0;

        for (var i = 0, row; row = table.rows[i]; i++) {
            let cantidad = row.cells[1].lastChild.value;
            let CodigoProducto = row.cells[2].innerText;
            let indexFound = this.state.boleta.carroProductos.findIndex(t => t.desprod.includes(CodigoProducto));
            if (indexFound!=-1) {

                let cantidadProductoPermitida = this.CarroProductosOriginal[indexFound].cantidad;//this.state.boleta.carroProductos[indexFound].cantidad;

                if (parseInt(cantidad) > cantidadProductoPermitida) {                    

                    let inputCantidad = "InputTd" + indexFound;
                        document.getElementById(inputCantidad).value = cantidadProductoPermitida;

                        document.getElementById(inputCantidad).focus();                        
                      // carroCopia[indexFound].cantidad =  parseInt(cantidadProductoPermitida); 
                        carroCopia[indexFound].valorTotal = this.state.boleta.carroProductos[indexFound].precioVentaTotal;
                        carroCopia[indexFound].descuentoAplicado = this.state.boleta.carroProductos[indexFound].descuentoProductoTotal;
                        subtotal = subtotal + carroCopia[indexFound].valorTotal;

                        boletaCopia.carroProductos = carroCopia;

                    swal({
                        title: "La cantidad ingresada para el producto " + CodigoProducto + " es mayor a la registrada en el documento",
                        icon: "warning",
                    });
                }else{
                    cantidad = cantidad ? cantidad : cantidadProductoPermitida;
                   // carroCopia[indexFound].cantidad =  parseInt(cantidad); 
                    if(parseInt(cantidad) === cantidadProductoPermitida){
                        carroCopia[indexFound].valorTotal = this.state.boleta.carroProductos[indexFound].precioVentaTotal;
                        carroCopia[indexFound].descuentoAplicado = this.state.boleta.carroProductos[indexFound].descuentoProductoTotal;
                    }else{
                        carroCopia[indexFound].valorTotal = parseInt(cantidad) * this.state.boleta.carroProductos[indexFound].precioVentaUnitario;
                        carroCopia[indexFound].descuentoAplicado = parseInt(cantidad) * this.state.boleta.carroProductos[indexFound].descuentoUnitario;
                    }                    
                    
                    subtotal = subtotal + carroCopia[indexFound].valorTotal;

                    boletaCopia.carroProductos = carroCopia;                    
                }
            }
        }
        
        boletaCopia.totalSubTotal = subtotal;
        this.setState({boleta : boletaCopia});      
    }

    SelectAllProductos(){
        let checked = document.getElementById("chkSelectAll").checked;
        var table = document.getElementById("TablaDeDatos");     
        var idFila = "";
        var idTr = "";

        if (checked){
            this.Devoluciones = [];
            for (var i = 0, row; row = table.rows[i]; i++) {
                idFila = 'Td' + i;
                idTr = 'Tr' + i;
                document.getElementById(idFila).checked = true;                
                this.Devoluciones.push(idTr);
            }
            this.setState({ MostrarConfirmoSeleccion: true });                   
        }else{
            for (var i = 0, row; row = table.rows[i]; i++) {
                idFila = 'Td' + i;
                idTr = 'Tr' + i;
                document.getElementById(idFila).checked = false;                
            }    
            this.Devoluciones = [];
            this.setState({ MostrarConfirmoSeleccion: false });
        }
    }
    
    // Fin Funciones de Datos
    Estilos = {
        Columna1: {
            'width': '55%'
        },
        Columna2: {
            'width': '45%'
        },
        Contenedor: {
            'width': '100%',
            'background-color': 'white',
            //'padding': '1.9%',
            'marginLeft': '0.5%'
        },
        ThStyleS: {
            'width': '3%',
            // 'paddingRight': '3%'
        },
        ThStyleSM: {
            'width': '7%',
            // 'paddingRight': '3%'
        },
        ThStyleM: {
            'width': '15%',
            // 'paddingRight': '0%'
        },
        ThStyleM1: {
            'width': '15%',
            // 'paddingLeft': '3%'
        },
        ThStyleM2: {
            'width': '15%',
            // 'paddingLeft': '5%'
        },
        ThStyleLG: {
            'width': '45%',
            // 'paddingRight': '0%'
        },
        // TdStyleSM: {
        //     'width': '7%',
        //     'textAlign': 'right',
        //     'paddingRight': '3%'
        // },        
        // TdStyleM1: {
        //     'width': '15%',
        //     'paddingLeft': '3%'
        // },
        // TdStyleM2: {
        //     'width': '15%',
        //     'paddingLeft': '5%'
        // },        
        InputDcto: {
            'width': '60%'
        }, LabelMargin: {
            'marginLeft': '5%'
        },
        SeparacionRow: {
            'paddingTop': '4%'
        },
        ButtonStyle1: {
            'height': '100%',
            'width': '38%',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '15%',
            'background': '#b4d1ea',
            'height': '43px'
        },
        ButtonStyle: {
            'height': '100%',
            'width': '45%',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '15%',
            'background': '#b4d1ea',
            'height': '43px'
        },
        ButtonStyleLimpiar: {
            'height': '100%',
            'width': '45%',
            'borderColor': 'rgb(110, 152, 135)',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '15%',
            //'background': '#b4d1ea',
            'height': '43px',
            'background': 'rgb(253, 255, 252)'
        },
        ButtonStylePeq: {
            'height': '100%',
            'width': '45%',
            'borderRadius': '15%',
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'background': 'rgb(222, 235, 249)',
            'fontSize': '18px'
        },
        ButtonStyleConfirmar: {
            'height': '48px',
            'width': '25%',
            //'borderColor': 'rgb(110, 152, 135)',
            //'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '7%',
            'background': 'rgb(138 187 222)',
            'marginTop': '1%',
            'color': 'white'
        },
        ButtonStyleReConfirmar: {
            'height': '48px',
            'width': '25%',
            //'borderColor': 'rgb(110, 152, 135)',
            //'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '7%',
            'background': 'rgb(228 122 69)',
            'marginTop': '1%',
            'color': 'white'
        },
        StyleTableData: {
            'marginBottom': '10.5%',
            'border': '1px solid #a9a9a9',
            'borderRadius': '8px',
            'background': 'rgb(214 226 245)',
            'marginLeft': '13.5%'
        },
        PopUpStyleConsultaStock:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '98%',
            'height': '80%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'border-radius': '8px'
        },
        DivIframe: {
            'overflow': 'auto',
            'width': '100%',
            'height': '100%'
        },
        IFrame: {
            'overflow': 'auto!important'
        },
        PopUpStyleOrdenService:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '97%',
            'height': '85%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        PopUpStyleTipoDocumento:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'height': '25%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        PopUpStylePedirDocumento:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '56%',
            'height': '40%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '0px',
            'borderRadius': '8px'
        },
        StyleDivOrdenService: {
            'overflow': 'auto',
            'width': '100%',
            'height': '100%'
        },
        MarginDivCliente: {
            'marginTop': '6%'
        },
        MarginCCliente: {
        },
        MarginDivClienteD: {
            'marginTop': '2%'
        },
        BtnConsultaCliente: {
            'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'border-radius': '5px',
            'margin-left': '5px',
            'background-color': '#8abbde',
            'height': '55px',
            'color': 'white'
        },
        BtnConsultaClienteD: {
        },
        ColorTrOSPrimary: {
            background: '#e8eef3'
        },
        ColorTrOSSecond: {
            background: '#e8f3f2'

        },
        ThStyle0:
        {
            'position': 'sticky',
            'top': '0',
            'background-color': 'rgb(222, 222, 222)',
            'width': '10%'
        },
        ThStyle1:
        {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': 'rgb(222, 222, 222)',
            'width': '15%'
        },
        ThStyle2:
        {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': 'rgb(222, 222, 222)',
            'width': '20%'
        },
        ThStyle3:
        {
            'position': 'sticky',
            'top': '0',
            'backgroundColor': 'rgb(222, 222, 222)',
            'width': '30%'
        },
        PopUpStyleAddCliente: {
            'background': 'rgb(255, 255, 255)',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'borderRadius': '8px'
        },
        PopUpStyleProductsNC: {
            'background': 'rgb(255, 255, 255)',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'borderRadius': '8px',
            'width': '35%'
        },
        SeparacionAddCliente: {
        },
        BotonCerrarOSListDs: {
        },
        DivContentDataCliente: {
            //'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'width': '74%',
            //'height': '300px',
            //'marginTop': '5.5%',
            //'marginbottom': '1.5%',
            //'border': '1px solid rgb(169, 169, 169)',
            //'borderRadius': '8px',
            'fontSize': '20px',
            'marginLeft': '14.5%',
            'textAlign': 'left',
            'marginBottom': '2%'
            //'background': 'rgb(253, 243, 243)'
        }, BtnBoleta: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'backgroundColor': 'rgb(116 177 123)',
            'height': '60px',
            'color': 'white',
            'width': '60%'

        }, BtnFactura: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'backgroundColor': 'rgb(133 169 195)',
            'height': '60px',
            'color': 'white',
            'width': '60%'
        }, BtnVerDocumentos: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'backgroundColor': 'rgb(133 169 195)',
            'height': '60px',
            'color': 'white',
            'width': '60%',
            'marginTop': '4%'


        }, BtnBuscarDocumento: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'backgroundColor': 'rgb(116 177 123)',
            'height': '60px',
            'color': 'white',
            'width': '60%',
            'marginTop': '4%'
        }, BtnConfirmarMotivo: {
            'boxShadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '257px',
            'backgroundColor': 'rgb(116 177 123)',
            'height': '60px',
            'color': 'white',
            'width': '60%',
            'marginTop': '2%'
        },
        MotivoAnulacion:
        {
            'marginLeft': '25%',
            'marginTop': '1%'
        },
        BtnOpcionesNC: {
            //'box-shadow': 'rgb(102, 102, 102) 2px 2px 10px',
            'borderRadius': '5px',
            'marginLeft': '5px',
            'backgroundColor': '#8abbde',
            'height': '55px',
            'color': 'white',
            'marginBottom':'2%'
        },
        InputCantidad:
        {
            'width': '60px',
           'textAlign': 'center'
        },
        TitleTextColorStyle:
        {
            'color':'orange'
        }
    }

    //Modales
    GetHtmlPopupDireccionRazonSocial() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card veryTop"
                defaultOpen
                modal
                onClose={() => { this.DesactivarAddRazonSoc() }}
                closeOnDocumentClick={false}>
              
                <CardHeader className="bg-info">Datos Faltantes</CardHeader>
                <form>
                    <CardBody id="altaAdicCliente" className="form form-group">
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2 col-12">
                                <input className="form-control" type="text" placeholder="RAZÓN SOCIAL" id="razonSocialAddCliente" />
                            </div>

                        </div>
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 col-12">
                                <input className="form-control" type="text" placeholder="DIRECCIÓN" id="direccionAddCliente" />
                            </div>
                        </div>
                        <div className="row mb-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 col-12">
                                <input className="form-control" type="text" placeholder="GIRO" id="giroAddCliente" />
                            </div>
                        </div>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveClienteAdic" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarClienteAdic() }} ><strong>Agregar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </form>
            </Popup>
        );
    }

    GetHtmlPopupConsultaCliente() {
        
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleOrdenService}
                defaultOpen
                modal
                onClose={() => { this.DesactivarConsultaCliente() }}
                closeOnDocumentClick={false}>
                <button className="d-inline-flex close justify-content-end" onClick={() => { this.DesactivarConsultaCliente() }}>
                    &times;
                </button>
                {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}

                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <div className="row text-center">
                        <div className="col-sm-12" style={this.Estilos.TitleTextColorStyle}>
                            <h3>
                                Nota de Crédito
                            </h3>
                        </div>
                        <div className="col-sm-12">
                            <h4>
                                Seleccione Cliente.
                            </h4>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                            <p class="text-danger" id="msgerror"></p>
                            <div className="d-inline-flex col-lg-4 col-md-4 col-sm-4 col-4" style={this.Estilos.MarginCCliente}>
                                <input className="form-control" type="text"
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            this.BuscarCliente();
                                        } else {
                                            this.KeyUpRutValidator();
                                        }
                                    }
                                    }
                                    onKeyPress={(e) => {
                                        this.onlyNumbersWithK(e);
                                    }
                                    }
                                    onChange={() => { this.LimpiarMensajeValidarRut() }}

                                    id="RutCliente" placeholder="RUT" maxLength="13" minLength="9" />
                            </div>
                            <div className="d-inline-flex col-lg-4 col-md-4 col-sm-4 col-4">
                                <input className="form-control" type="text" onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        this.BuscarCliente();
                                    }
                                }} id="NombreCliente" placeholder="NOMBRE" />
                            </div>
                            <div className="d-inline-flex col-lg-2 col-md-2 col-sm-2 col-2 text-center">
                                <button className="btn btn-primary mr-4" onClick={() => { this.BuscarCliente() }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </button>
                                <button className="btn btn-info" style={this.Estilos.BtnConsultaClienteD} onClick={() => { this.ConsultaTipoClient() }}>
                                    <i className="fa fa-plus fa-1x">
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <table className="table table-sm table-striped table-hover table-dark">
                        <thead>
                            <tr className="d-flex">
                                <th className="col-lg-2 col-md-2 col-sm-1 col-1">Cliente</th>
                                <th className="col-lg-2 col-md-2 col-sm-2 col-2">Rut</th>
                                <th className="col-lg-3 col-md-3 col-sm-2 col-2">Nombre</th>
                                <th className="col-lg-3 col-md-3 col-sm-3 col-3">Email</th>
                                <th className="col-lg-2 col-md-2 col-sm-2 col-2">Telefono</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.ConsultaClientes.map((item, index) => {
                                    return (<tr className="d-flex" key={index} onClick={() => { this.DesplegarDatosCliente(item.id) }}>
                                        <td className="col-lg-2 col-md-2 col-sm-1 col-1">{item.tipoCliente}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-2 col-2 text-truncate">{item.rut}</td>
                                        <td className="col-lg-3 col-md-3 col-sm-2 col-2 text-truncate">{item.nombre}</td>
                                        <td className="col-lg-3 col-md-3 col-sm-3 col-3 text-truncate">{item.email}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-2 col-2 text-truncate">{item.telefono}</td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Popup>
        );
    }

    KeyUpRutValidator() {
        let cadena = $("#RutCliente").val();
        cadena = cadena.replace(/[.]/gi, "").replace("-", "");
        if (cadena.length > 9) {
            cadena = cadena.substr(0, 9);
        }
        let concatenar = "";
        let i = cadena.length - 1;
        for (; i >= 0;) {
            concatenar = cadena[i] + concatenar;
            if (i + 1 == (cadena.length) && i > 0) {
                concatenar = "-" + concatenar;
            }
            if (concatenar.length == 9 && cadena.length > 7) {
                concatenar = "." + concatenar;
            }
            if (concatenar.length == 5 && cadena.length > 4) {
                concatenar = "." + concatenar;
            }
            i--;
        }
        $("#RutCliente").val(concatenar);
    }

    onlyNumbersWithK(e) {
        if (e.which == 75 || e.which == 107 || e.which == 48 || e.which == 49 || e.which == 50 || e.which == 51 || e.which == 52 || e.which == 53
            || e.which == 54 || e.which == 55 || e.which == 56 || e.which == 57) {
            return;
        } else {
            e.preventDefault();
        }
    }

    LimpiarMensajeValidarRut() {
        $("#msgerror").html("");
    }
    upperCaseInput(inputInfo){
        let valueRut = document.getElementById(inputInfo).value;
        document.getElementById(inputInfo).value=valueRut.toUpperCase();

    }
    checkRutAddClient(rut){
        let valueRut = document.getElementById(rut).value;
        if(!this.checkRut(valueRut)){
            swal({
                title: "ERROR !",
                text: "RUT Inválido!",
                icon: "error",
                button: "Entendido !",
            });
            return;
        }
        if(valueRut.indexOf("-") == -1){
            valueRut = valueRut.substring(0,valueRut.length-1) + "-" + valueRut.substring(valueRut.length,valueRut.length-1);
            document.getElementById(rut).value=valueRut;
        }
    }

     isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }


      checkMail (email){
        this.upperCaseInput(email);
        let valueMail = document.getElementById(email).value;
        if (!this.isValidEmail(valueMail)) {
            swal({
                title: "ERROR !",
                text: "Email Inválido!",
                icon: "error",
                button: "Entendido !",
            });
            document.getElementById(email).value = '';
          }
      }
    GetHtmlPopupAddCliente() {


        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                <CardHeader className="bg-info">Alta de cliente</CardHeader>
                <Form>
                    <CardBody id="altaCliente" className="form form-group">
                        <div className="row mb-3" >
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Nombre: </label>
                                <input className="form-control" type="text" id="nombreAddCliente"  
                                onBlur = {(val)=>
                                        {this.upperCaseInput('nombreAddCliente');}}/>
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                 <label>Apellidos: </label>
                                <input className="form-control" type="text"  id="apellidoAddCliente" 
                                onBlur = {(val)=>
                                    {this.upperCaseInput('apellidoAddCliente');}}/>
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>RUT: </label>
                                <input className="form-control" type="text" placeholder="RUT (12345678-9)"
                                    onChange={(val) => {
                                        this.formateaRutDos();
                                    }}
                                    onBlur = {(val)=>{
                                        this.checkRutAddClient('rutAddCliente');
                                    }}
                                    id="rutAddCliente" maxLength="9" minLength="9" />
                            </div>
                        </div>
                        <div className="row" >
                           
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                            <label>Teléfono: </label>
                                <PhoneInput
                                    className="form-control"
                                    value={this.state.phoneAdded}
                                    onChange={phoneAdded => this.setState({ phoneAdded })}
                                    //onlyCountries={['cl', 'ar']}
                                    id="telefonoAddCliente"
                                    country={'cl'}
                                />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Email: </label>
                                <input className="form-control" type="text" placeholder="EMAIL" id="emailAddCliente" 
                                   onBlur = {(val)=>
                                    {this.checkMail('emailAddCliente');}} 
                                    />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>     
                                    <label>Region: </label>
                                    <select name="ddlRegion" id="ddlRegion" className="form-control" onChange={() => { this.TraerComunas() }}>
                                    {this.state.Regiones.map((reg) => {                   //recorro el state y muestro datos
                                        return (
                                            <option key={reg.id} value={reg.id}>
                                            {reg.nombre}
                                            </option>
                                        );
                                    })}
                                    </select>     
                            </div>  
                        </div>

                        <div className="row mb-3">
                                <div className="form-group col" style={this.Estilos.SeparacionAddCliente} >     
                                            <label>Comuna: </label>
                                            <select name="ddlComuna" id="ddlComuna"className="form-control">
                                            {this.state.Comunas.map((com) => {                   //recorro el state y muestro datos
                                                return (
                                                    <option key={com.id} value={com.id}>
                                                    {com.nombre}
                                                    </option>
                                                );
                                            })}
                                            </select>     
                                </div>   
                                <div className="form-group col-7" style={this.Estilos.SeparacionAddCliente}>
                                        <label>Dirección: </label>
                                        <input className="form-control" type="text" placeholder=" " id="inpDireccion" />
                                </div>
                        </div>
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveCliente" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarCliente() }} ><strong>Agregar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }
    GetHtmlPopupAddClienteEmpresa() {
        
   
        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>
                    {this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}
                <CardHeader className="bg-info">Alta de empresa</CardHeader>
                <Form>
                    <CardBody id="altaCliente" className="form form-group">
                        <div className="row mb-3"  >
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Razon social: </label>
                                <input className="form-control" type="text"   id="nombreAddClienteEmpresa" 
                                    onBlur = {(val)=>
                                    {this.upperCaseInput('nombreAddClienteEmpresa');}} />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                 <label>RUT: </label>
                                <input className="form-control" type="text" placeholder="RUT (12345678-9)"
                                    onChange={(val) => {
                                        this.formateaRut('rutAddClienteEmpresa');
                                    }}onBlur = {(val)=>{
                                        this.checkRutAddClient('rutAddClienteEmpresa');
                                    }}
                                    id="rutAddClienteEmpresa" maxLength="9" minLength="9" />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Giro comercial: </label>
                                <input className="form-control" type="text"   id="giroComercialAddClienteEmpresa" 
                                onBlur = {(val)=>
                                    {this.upperCaseInput('giroComercialAddClienteEmpresa');}} />
                            </div>
                        </div>
                        <div className="row" >
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                            <label>Teléfono: </label>
                                <PhoneInput
                                    className="form-control"
                                    value={this.state.phoneAdded}
                                    onChange={phoneAdded => this.setState({ phoneAdded })}
                                    //onlyCountries={['cl', 'ar']}
                                    id="telefonoAddClienteEmpresa"
                                    country={'cl'}
                                />
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>
                                <label>Email: </label>
                                <input className="form-control" type="text" placeholder="cliente@blinamic.cl" id="emailAddClienteEmpresa" 
                                onBlur = {(val)=>
                                {this.upperCaseInput('emailAddClienteEmpresa');}} />
                                
                            </div>
                            <div className="form-group col" style={this.Estilos.SeparacionAddCliente}>     
                                    <label>Region: </label>
                                    <select name="ddlRegionEmpresa" id="ddlRegionEmpresa" className="form-control" onChange={() => { this.TraerComunas() }}>
                                    {this.state.Regiones.map((reg) => {                   //recorro el state y muestro datos
                                        return (
                                            <option key={reg.id} value={reg.id}>
                                            {reg.nombre}
                                            </option>
                                        );
                                    })}
                                    </select>     
                                </div>  
                        </div>

                       

                        <div className="row mb-3" >   
                                <div className="form-group col" style={this.Estilos.SeparacionAddCliente} >     
                                            <label>Comuna: </label>
                                            <select name="ddlComunaEmpresa" id="ddlComunaEmpresa"className="form-control">
                                            {this.state.Comunas.map((com) => {                   //recorro el state y muestro datos
                                                return (
                                                    <option key={com.id} value={com.id}>
                                                    {com.nombre}
                                                    </option>
                                                );
                                            })}
                                            </select>     
                                        </div>    
                                        <div className="form-group col-5" style={this.Estilos.SeparacionAddCliente}>
                                        <label>Dirección: </label>
                                        <input className="form-control" type="text" placeholder=" " id="inpDireccionEmpresa"
                                            onBlur = {(val)=>
                                            {this.upperCaseInput('inpDireccionEmpresa');}} />
                                    </div>
                                </div>
                        <div className="row mb-3"> 
                        
                        </div>       
                        <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.DesactivarModales() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveCliente" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.AgregarClienteEmpresa() }} ><strong>Agregar</strong></button>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </Popup>
        );
    }
    GetHtmlPopupTipoDocumento() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleTipoDocumento}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick={false}>
                <button className="d-inline-flex close justify-content-end" onClick={() => {this.DesactivarConsultaDoc() }}>
                    &times;
                </button>
                <div id="ContainWeb" >
                    <div className="row">
                        <div className="col-sm-12"  >
                            <h4>Seleccione Tipo de Documento</h4>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-6"  ><button style={this.Estilos.BtnBoleta} onClick={() => { this.setState({ TipoDocumento: 39, PedirTipoDocumento: false, PedirDocumento:true }) }}>Boleta </button> </div>
                        <div className="col-sm-6"  ><button style={this.Estilos.BtnFactura} onClick={() => { this.setState({ TipoDocumento: 33, PedirTipoDocumento: false, PedirDocumento:true }) }}>Factura </button> </div>

                    </div>

                </div>

            </Popup>
        );
    }

    ClickVerBoleta() {
        this.setState({ activarPopUpConsultaDocumento: true });
    }

    VerFormasDePago(){
        this.setState({activarFormasPago:true});
    }

    GetHtmlPopupVerBoletas() {

        //let url = "https://todobikes.blinamic.cl/ReportData/ReporteDocumentosElectronicos";
        //let url = "https://todobikes.blinamic.cl/ReportData/ReporteDocumentosElectronicos";

        let url = "https://todobikes.blinamic.cl/ReportData/ReporteDocumentosElectronicos";

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleConsultaStock}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick>

                <div id="ContainWeb" style={this.Estilos.DivIframe}>
                    <iframe id="Example2"
                        style={this.Estilos.IFrame}
                        name="Example2"
                        title="Example2"
                        width="100%"
                        height="800px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src={url}>
                    </iframe>
                </div>

            </Popup>
        );
    }

    GetHtmlPopupPedirDocumento() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStylePedirDocumento}
                defaultOpen
                modal
                onClose={() => { this.DesctivarBuscarDoc() }}
                closeOnDocumentClick={false}>
                <button className="d-inline-flex close justify-content-end" onClick={() => { this.DesctivarBuscarDoc() }}>
                    &times;
                </button>
                <div id="ContainWeb" >
                    <div className="row">
                        <div className="col-sm-12"  >
                            <h4>Ingrese Folio del Documento  </h4>
                        </div>

                    </div>
                    <div className="row">
                        {(this.state.Ambiente=='MAISA')?
                          <div className="col-sm-12"  >
                            <label>Seleccione Sucursal de la Venta</label>
                          <select name="ddlSucursales" id="ddlSucursales" className="form-control" style={this.Estilos.InputDcto}>
                                        {this.state.sucursales.map((com) => {                   //recorro el state y muestro datos
                                        let sucid = GetUserCookie().Sucursal.sucursalIDBlinamic;
                                            if (sucid == com.id){
                                                return (
                                                    <option key={com.id} value={com.id} selected>
                                                    {com.id}  -  {com.descripcion}
                                                    </option>
                                                );
                                            }
                                            else{
                                                return (
                                                    <option key={com.id} value={com.id}>
                                                    {com.id}  -  {com.descripcion}
                                                    </option>
                                                );
                                            }
                                           
                                        })}
                                        </select>    
                        </div>
                        :null}
                      </div>
                      <div className="row"> <br>
                      </br></div>
                      <div className="row">
                        <div className="col-sm-12"  >
                            <input id="NumBoleta" type="text" placeholder="N° de Documento (TRX)" style={this.Estilos.InputDcto}></input>
                        </div>
                        </div>
                    <div className="row" textAlign="center">
                        <div className="col-sm-6"  hidden="hidden"><button style={this.Estilos.BtnVerDocumentos} onClick={() => { this.ClickVerBoleta()  }}>Buscar Documentos </button> </div>
                        <div className="col-sm-12"  ><button style={this.Estilos.BtnBuscarDocumento} onClick={() => { this.BuscarBoleta() }}>Buscar Documento </button> </div>

                    </div>
                </div>

            </Popup>
        );
    }

    GetHtmlPopupPedirMotivoDevolucion() {

        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleTipoDocumento}
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick={false}>
                <button className="d-inline-flex close justify-content-end" onClick={() => { this.DesactivarMotivoDevol() }}>
                    &times;
                </button>
                <div id="ContainWeb" >
                    <div className="row">
                        <div className="col-sm-12"  >
                            <h4>Seleccione Motivo de Devolución</h4>
                        </div>

                    </div>
                    { (this.ExisteCanje)?
                        (<div className="row">
                        <div className="col-sm-12"  >
                            <label>*Venta realizada con canje de puntos, solo disponible para devolución de dinero.</label>
                        </div>

                        </div>):(null)
                    }
                    
                    <div className="row">
                        <div className="col-sm-6" style={this.Estilos.MotivoAnulacion}>
                            <select id="MotivoDevolucion" className="form-control">
                                <option value="0">--Seleccionar--</option>
                                {
                                    this.state.MotivosDevolucion.map((item, index) => {
                                        if (this.ExisteCanje) {
                                            if (item.id==4){ //devolucion dinero
                                                return (<option value={item.id}>{item.motivo}</option>);
                                            }
                                        }
                                        else{
                                            return (<option value={item.id}>{item.motivo}</option>);
                                        }
                                       
                                    })
                                }
                            </select>
                        </div>
                       
                    </div>
                    <div className="row">
                        <div className="col-sm-6"  ><button style={this.Estilos.BtnConfirmarMotivo} onClick={() => { this.ConfirmarSeleccionMotivo() }}>Confirmar Motivo</button> </div>

                    </div>
                </div>

            </Popup>
        );
    }

    GetHtmlPopupMediosPagoOrden() {


        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleAddCliente}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick={false}>
                <CardHeader className="bg-info">Seleccione tipo de Devolución :</CardHeader>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <table id="TbFormasPago" className="table table-sm table-striped table-hover table-dark table-responsive" style={this.Estilos.DivProd} >
                        <thead>
                            <tr>
                                <th></th>
                                
                                <th >Forma de Pago Original</th>
                                <th >Monto Origen</th>
                                <th> *Prorrateo Aprox.</th>
                                <th >Fecha Cheque </th>
                                <th >Num. Cheque </th>
                                <th >Banco </th>
                                <th >Tipo de Devolucion</th>
                              
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.boleta.mediosPagoOrigen.map((item, index) => {
                                    var IdTr = "Tr" +index;
                                    var InputTd = "InputTd" + index;
                                    var SelectID = "Sel_" + index;
                                    //if (item.tbMedioID==16){
                                        if (item.tbMedioID!=8 && item.tbMedioID!=30){
                                        return (<tr key={index}>
                                            <td></td>
                                            <td >{item.descripcion}</td>
                                            <td >{GetPrecioFormateado(item.monto)}</td>
                                            <td >{GetPrecioFormateado(item.montoProp)}</td>
                                            <td >{item.fechaCheque}</td>
                                            <td >{item.numCheque}</td>
                                            <td >{item.descBanco}</td>
                                            <td ><select id={SelectID}>
                                                    <option id="1" value='1'>Efectivo </option>
                                                    <option id="2" value='2'>FP. Origen</option>
                                             </select>
                                            </td>
                                        </tr>);
                                        }
                                        else{
                                            return (<tr key={index}>
                                                <td></td>
                                                <td >{item.descripcion}</td>
                                                <td >{GetPrecioFormateado(item.monto)}</td>
                                                <td >{GetPrecioFormateado(item.montoProp)}</td>
                                                <td >{item.fechaCheque}</td>
                                                <td >{item.numCheque}</td>
                                                <td >{item.descBanco}</td>
                                                <td ><select id={SelectID}>
                                                        <option id="1" value='1' selected>Efectivo </option>
                                                 </select>
                                                </td>
                                            </tr>);
                                        }
                                      //  }
                                })
                            }
                        </tbody>
                    </table>
                    <CardFooter>
                        <div> <label title='Ej: Si selecciona efectivo en todos los medios, solo se registrara un medio de pago agrupado (Efectivo)' >*Prorrateo aproximado sugerido, es solo aplicable a algunos medios de pago</label></div>
                    </CardFooter>
                    <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.LimpiarVentana() }}><strong>Cancelar</strong></button>
                            </div>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button id="saveConfirmNC" style={this.Estilos.BotonCerrarOSListDs} className="btn btn-success form-control" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.clickEnviarSeleccion() }} ><strong>Confirmar</strong></button>
                            </div>
                        </div>
                </div>
            </Popup>
        );
    }



    GetHtmlPopupProductsInOthersNC() {


        return (
            <Popup
                contentStyle={this.Estilos.PopUpStyleProductsNC}
                className="card"
                defaultOpen
                modal
                onClose={() => { this.DesactivarModales() }}
                closeOnDocumentClick={false}>
                <CardHeader className="bg-info">Atención :</CardHeader>
                <div style={this.Estilos.StyleDivOrdenService} className="p-2">
                    <span>La venta solicitada no puede generar Nota de Crédito ya que los productos asociados ya fueron devueltos en : </span>
                    <table id="TbProductsInOthersNC" className="table table-sm table-striped table-hover table-dark table-responsive" style={this.Estilos.DivProd} >
                        <thead>
                            <tr>
                                <th></th>
                                
                                <th >ID NC</th>
                                <th >Folio NC</th>
                                <th> Sku</th>
                                <th >Cantidad </th>
                                <th >Fecha </th> 
                              
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.productsInOthersNC.map((item, index) => {
                                    var IdTr = "Tr" +index;
                                    var InputTd = "InputTd" + index;
                                    var SelectID = "Sel_" + index;
                                 
                                        return (<tr key={index}>
                                            <td></td>
                                            <td >{item.id}</td>
                                            <td >{item.trx}</td>
                                            <td >{item.plu}</td>
                                            <td >{item.cantidad}</td>
                                            <td >{item.fecha}</td> 
                                         
                                        </tr>);
                                     
                                })
                            }
                        </tbody>
                    </table>
                    <CardFooter>
                       
                    </CardFooter>
                    <div className="row pt-2 justify-content-center" style={this.Estilos.SeparacionAddCliente}>
                            <div className="d-inline-flex ml-2 mr-2 mb-2">
                                <button style={this.Estilos.BotonCerrarOSListDs} className="btn btn-danger" onClick={() => { this.LimpiarVentana() }}><strong>Aceptar</strong></button>
                            </div>
                             
                        </div>
                </div>
            </Popup>
        );
    }
    //Fin Modales
    ClickAddCliente() {
        this.setState({ CrearCliente: true });
        setTimeout(function () {
            $('#nombreAddCliente').focus();
        }, 50);
    }
    ClickAddClienteEmpresa() {
        this.setState({ CrearClienteEmpresa: true });
        setTimeout(function () {
            $('#nombreAddClienteEmpresa').focus();
        }, 50);
    }
    ConsultaTipoClient (){
        Swal.fire({
            title: 'Seleccione Tipo de Cliente',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Persona',
            denyButtonText: 'Empresa' 
          }).then((result) => {
            if (result.isConfirmed) {
                this.ClickAddCliente();
            } else if (result.isDenied) {
                this.ClickAddClienteEmpresa();
            } 
          })
    }
    getHtmlProcesarDevolucion() {
        return (
            <ProcesarDevolucion
                pagos={ this.state.pagosHtenDevolucion }
                finalizarVenta= { this.finishHtenDev.bind(this) } 
                cerrarModal = {this.CerrarModalProcesarPago.bind(this) }
                orderId = { this.state.boleta.numeroOrden + "" }
            ></ProcesarDevolucion>
        )
    }
    CerrarModalProcesarPago() {
        console.log('CerrarModalProcesarPago')
        this.setState({ visibleProcesarDevolucion: false })
        // this.setState({ pagos: newPagos })
    }
    render() {
        const preventMinus = (e) => {
            if (e.charCode <= 48 || e.charCode >= 57) {
                e.preventDefault();
            }
        };
        return (
            <div style={this.Estilos.Contenedor} >
                <center>
                    {(this.state.PedirCliente) ? (
                        this.GetHtmlPopupConsultaCliente()

                    ) : (null)}

                    {(this.state.CrearCliente) ? (
                        this.GetHtmlPopupAddCliente()
                    ) : (null)}

                    {(this.state.CrearClienteEmpresa) ? (
                    this.GetHtmlPopupAddClienteEmpresa()
                    ) : (null)}

                    {(this.state.PedirTipoDocumento) ? (
                        this.GetHtmlPopupTipoDocumento()

                    ) : (null)}

                    {(this.state.PedirDocumento) ? (
                        this.GetHtmlPopupPedirDocumento()

                    ) : (null)}

                    {(this.state.activarPopUpConsultaDocumento) ? (
                        this.GetHtmlPopupVerBoletas()

                    ) : (null)}

                    {(this.state.PedirMotivoDevolucion) ? (
                        this.GetHtmlPopupPedirMotivoDevolucion()

                    ) : (null)}

                    {(this.state.activarPopUpDireccionRazonSocial) ? (
                        this.GetHtmlPopupDireccionRazonSocial()

                    ) : (null)}
                      {(this.state.ShowProductsInOthersNC) ? (
                        this.GetHtmlPopupProductsInOthersNC()

                    ) : (null)}
                 {(this.state.activarFormasPago) ? (
                        this.GetHtmlPopupMediosPagoOrden()

                    ) : (null)}
                    { (this.state.visibleProcesarDevolucion) ? (this.getHtmlProcesarDevolucion()) : (null) }
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="col-lg-12">
                                <button style={this.Estilos.BtnOpcionesNC} onClick={() => { this.LimpiarVentana() }}> Nueva Nota de Crédito</button>
                                <button style={this.Estilos.BtnOpcionesNC} onClick={() => { this.LimpiarCliente() }}> Cambiar Cliente</button>
                                <button style={this.Estilos.BtnOpcionesNC} onClick={() => { this.LimpiarDocumento() }}> Cambiar Documento</button>
                                <button style={this.Estilos.BtnOpcionesNC} onClick={() => { this.LimpiarMotivoDevolucion() }}> Cambiar Motivo Devolución</button>
                                <button hidden="hidden" style={this.Estilos.BtnOpcionesNC} onClick={() => { this.VerFormasDePago() }}> Ver Formas de Pago</button>

                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h4> Datos Clientes</h4>
                                </div>
                            </div>
                            <div className="row">
                                {(this.state.tieneDatosCliente) ?
                                    (
                                        <div style={this.Estilos.DivContentDataCliente}>

                                            <div className="row">
                                                <div className="col-6"><strong>Nombre:</strong> {this.state.DataCliente.nombre}</div>
                                                <div className="col-6"><strong>Rut:</strong> {this.state.DataCliente.rut}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Teléfono:</strong> {this.state.DataCliente.telefono}</div>
                                                <div className="col-6"><strong>Email:</strong> {this.state.DataCliente.email}</div>

                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Fecha Ultimo Servicio:</strong> {this.state.DataCliente.fechaUltServicio}</div>
                                                <div className="col-6"><strong>Monto Ultimo Servicio:</strong> {GetPrecioFormateado(this.state.DataCliente.montoUltServicio)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Fecha Ultima Compra:</strong> {this.state.DataCliente.fechaUltCompra}</div>
                                                <div className="col-6"><strong>Monto Ultima Compra: </strong>{GetPrecioFormateado(this.state.DataCliente.montoUltCompra)}</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><strong>Estrellas:</strong> {this.state.DataCliente.estrellas}</div>
                                                <div className="col-6"><strong>Motivo Devolución:</strong>{this.state.MotivoDevolucion}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Fecha Devolucion:</strong> <input type="date" id="FechaDevolucion" placeholder="Fecha Devolución" style={this.Estilos.InputDcto}></input></div>
                                                <div className="col-6"><strong>Pago con tarjeta: </strong>{GetPrecioFormateado(this.state.importeTarjeta)}</div>
                                            </div>


                                        </div>
                                    ) :
                                    (
                                        <div style={this.Estilos.DivContentDataCliente}>

                                            <div className="row">
                                                <div className="col-6"><strong>Nombre:</strong> </div>
                                                <div className="col-6"><strong>Rut:</strong></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Teléfono:</strong></div>
                                                <div className="col-6"><strong>Email:</strong> </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Fecha Ultimo Servicio:</strong> </div>
                                                <div className="col-6"><strong>Monto Ultimo Servicio:</strong> </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Fecha Ultima Compra:</strong> </div>
                                                <div className="col-6"><strong>Monto Ultima Compra: </strong></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6"><strong>Estrellas:</strong> </div>
                                                <div className="col-6"><strong>Motivo Devolución:</strong>{this.state.MotivoDevolucion}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><strong>Fecha Devolución:</strong> <input type="date" id="FechaDevolucion" placeholder="Fecha Devolución" style={this.Estilos.InputDcto} readOnly></input></div>
                                            </div>

                                        </div>

                                    )}



                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <h4> Detalle del Documento</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-9" style={this.Estilos.StyleTableData}>
                                    <table border="0" id="TablaDeDatos">
                                        <thead>
                                            <th style={this.Estilos.ThStyleS}><input type="checkbox" onChange={() => this.SelectAllProductos()} id='chkSelectAll'></input></th>
                                            <th style={this.Estilos.ThStyleSM}>Cantidad</th>
                                            <th style={this.Estilos.ThStyleM} >Código</th>
                                            <th style={this.Estilos.ThStyleLG} >Detalle</th>
                                            <th style={this.Estilos.ThStyleM} >Precio Unitario</th>
                                            <th style={this.Estilos.ThStyleM1}>Descuento</th>
                                            <th style={this.Estilos.ThStyleM2}>Subtotal</th>
                                        </thead>
                                        <tbody>
                                            {

                                                (this.state.tieneDatosBoleta) ? (
                                                    this.state.boleta.carroProductos.map((item, index) => {
                                                        var subTotal = item.cantidad * item.precpred;
                                                        var ProdDescripcion = item.desprod.split(';');
                                                        //var IdTr = "Tr" + ProdDescripcion[0];
                                                        //var ProdDescripcion = item.desprod.split(';');
                                                        var IdTr = "Tr" +index;
                                                        var InputTd = "InputTd" + index;
                                                        var IdTd = "Td" + index;
                                                        var idx = this.ResultadoBusqueda.indexOf(IdTr);
                                                        if (idx==-1) {
                                                            this.ResultadoBusqueda.push(IdTr);
                                                        }
                                                        if (this.state.hasHten && this.state.MotivoDevolucion === 'Devolucion de Dinero' ) {
                                                            // this.Devoluciones.push(IdTr);
                                                        }
                                                        return (
                                                            <tr id={IdTr}>
                                                                {
                                                                    // (this.state.hasHten) ?
                                                                    //     (this.state.CheckListo) ?
                                                                    //         (<td></td>)
                                                                    //         :
                                                                    //         (<td><input type="checkbox" checked={this.state.hasHten && this.state.MotivoDevolucion === 'Devolucion de Dinero'} disabled={this.state.hasHten && this.state.MotivoDevolucion === 'Devolucion de Dinero'?'disabled':false} onClick={() => this.AgregarADevolucion(IdTr, InputTd)}></input> </td>)
                                                                    //     :
                                                                        (<td><input type="checkbox" onChange={() => this.AgregarADevolucion(IdTr,InputTd)} id={IdTd}></input> </td>)
                                                                }
                                                                {
                                                                (this.state.ConfirmoSeleccion) ?
                                                                     (<td onChange={() => this.PreciosPorCantidad(index)} className=""><input id={InputTd}  type="number" style={this.Estilos.InputCantidad} placeholder={item.cantidad} value={item.cantidad}  min="1" onKeyPress={preventMinus}/></td>):
                                                                     (<td onChange={() => this.PreciosPorCantidad(index)} className=""><input id={InputTd}  type="number" style={this.Estilos.InputCantidad} placeholder={item.cantidad}  min="1" onKeyPress={preventMinus}/></td>)
                                                                }
                                                                <td>{ProdDescripcion[0]}</td>
                                                                <td>{ProdDescripcion[2]}</td>
                                                                <td>{GetPrecioFormateado(item.precpred)}</td>
                                                                <td>{GetPrecioFormateado(item.descuentoAplicado) }</td>
                                                                <td>{GetPrecioFormateado(item.valorTotal)}</td>
                                                                <td hidden="hidden">
                                                                    <center>
                                                                        <a onClick={() => { this.QuitarLineas(IdTr) }} href="javascript:void(0)">
                                                                            <i className="fa fa-minus-circle fa-2x text-danger" aria-hidden="true"></i>
                                                                        </a>
                                                                    </center>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })

                                                ) : (null)

                                            }
                                        </tbody>
                                    </table>
                                    <table border="0" id="TablaTotales">
                                        <thead>
                                            <th style={this.Estilos.ThStyleS}></th>
                                            <th style={this.Estilos.ThStyleSM}></th>
                                            <th style={this.Estilos.ThStyleM} ></th>
                                            <th style={this.Estilos.ThStyleLG} ></th>
                                            <th style={this.Estilos.ThStyleM} > </th>
                                            <th style={this.Estilos.ThStyleM1}></th>
                                            <th style={this.Estilos.ThStyleM2}></th>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.tieneDatosBoleta) ?
                                                    (
                                                        <tr>
                                                            <td></td>
                                                            <td><b>Totales</b></td>                                                                                                                     
                                                            <td></td>
                                                            <td></td>
                                                            <td>{GetPrecioFormateado(this.state.boleta.totalPrecioUnitario)}</td>
                                                            <td></td>
                                                            <td>{GetPrecioFormateado(this.state.boleta.totalSubTotal)}</td>
                                                        </tr>

                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <td></td>
                                                            <td><b>Totales</b></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{GetPrecioFormateado(0)}</td>
                                                            <td></td>
                                                            <td>{GetPrecioFormateado(0)}</td>
                                                        </tr>
                                                        )

                                            }


                                            <tr>

                                                <td colSpan="7" className="aling-right">
                                                    <center>
                                                        {
                                                            (this.state.cargandoIsSelected) ? (
                                                                <center>
                                                                    <div >
                                                                        <center>
                                                                            <div className="spinner-border text-dark" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <p> Cargando...</p>
                                                                        </center>
                                                                    </div>
                                                                </center>
                                                            ) :
                                                                (this.state.MostrarConfirmoSeleccion) ?
                                                                    (
                                                                        (this.state.ConfirmoSeleccion) ?
                                                                            (
                                                                                <button id="BotonEnviarNota" style={this.Estilos.ButtonStyleReConfirmar} onClick={() => { this.EnviarSeleccion() }} >Confirmar envío Nota de Crédito</button>

                                                                            ) : (
                                                                                <button id="BotonConfirmarSeleccion" style={this.Estilos.ButtonStyleConfirmar} onClick={() => { this.ConfirmarSeleccion() }} >Confirmar</button>
                                                                            )
                                                                    ):

                                                                    (null)
                                                                }
                                                    </center>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>


                                </div>

                            </div>


                        </div> 
                       




                    </div>






                </center>

            </div>
            
            
            
            );

    }


}