import React, { Component } from 'react';
import { Route } from 'react-router';
import UserProfile from './Globales/PerfilUsuario';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetUrlBlinamicApi } from './Globales/VariableGlobales';
import { Logout } from './Globales/Utilidades';
import { messaging } from "../init-fcm";
import { setTimeout } from "timers";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Input,
} from "reactstrap";
import LogoLeanPos from "./LogoLeanPos";
import MySpinner from "./MySpinner";
import Swal from "sweetalert2";

export class LoginPin extends Component {
  //login para
  static displayName = LoginPin.name;

  CodigoOperacion;
  TextoIngresado = "";
  constructor() {
    super();
    this.state = {
      redirigirRevisar: false,
      showSpinner: false,
      sucursales: {},
      localStore: "",
      latitud: 0,
      longitud: 0,
      Ambiente: localStorage.getItem('ambiente'),
      sucursalID: 0,
      cajaID: 0,
      lineaTienda: "",
      isHidden: true,
      ServicioApiLocal: localStorage.getItem('servicioApiLocal') ,
      validateArchive : false
    };

    this.keyBoardControl = this.keyBoardControl.bind(this);
  }

  keyBoardControl(e) {
    var pinboxElement = document.getElementById("PINboxD");
    if (pinboxElement == null) {
      return;
    }

    // eslint-disable-next-line default-case
    switch (e.key) {
      case "Escape":
        this.ClickClear();
        break;
      case "Backspace":
        this.DeleteChar();
        break;
      case "Enter":
        this.ClickLogin();
        break;
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
      case "0":
        this.TextoIngresado += e.key;
        pinboxElement.value = this.TextoIngresado;
        //this.validateTextoIngresado();
        break;
    }
  }

  componentDidMount() {
    if (!UserProfile.getUser().username === "") {
      this.setState({ redirigirRevisar: true });
    } else {
      Logout();
    }
    document.addEventListener("keyup", this.keyBoardControl, false);

    if (this.state.Ambiente === 'MAISA') {
      this.ParametrosCaja();
      this.setState({isHidden: false})
    }    
  }

  componentWillMount() {
   /* fetch(GetUrlApi() + "/api/Sucursals", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((sucs) => sucs.json())
      .then((data) => {
        this.setState({ sucursales: data });
      });*/
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyBoardControl, false);
  }

  Estilos = {
    estiloSpan: {
      pointerEvents: "none",
    },
    AlertaPinInvalido: {
      display: "none",
    },
    mySpinnerBg: {
      position: "absolute",
      backgroundColor: "#000000a1",
      zIndex: "9999",
      right: "0",
    },
    mySpinner: {
      top: "45%",
      position: "relative",
    },
    myInput: {
      WebkitTextSecurity: "disc",
    },
  };

  DeleteChar() {
    document.getElementById("PINboxD").value = this.TextoIngresado.slice(0, -1);
    this.TextoIngresado = document.getElementById("PINboxD").value;
  }

  handleInput(e) {
    this.TextoIngresado += e.target.value;
    document.getElementById("PINboxD").value = this.TextoIngresado;
  }

  validateTextoIngresado() {
    if (this.TextoIngresado.length <= 6)
      document.getElementById(
        this.TextoIngresado.length
      ).style.backgroundColor = "#6e9887";
  }

  ClickClear() {
    this.Texto = "";
    this.TextoIngresado = "";
    sessionStorage.removeItem('userProfile');
        UserProfile.resetUser();
    this.setState({sucursales:{}});    
    document.getElementById("PINboxD").value = "";
  }

  CerrarSession() {
    sessionStorage.removeItem("userProfile");
  }

  async GetLocation(preguntar) {
    const sucursales = this.state.sucursales;
    return new Promise(function (resolve, reject) {
      const getFixed = (v, n) => {
        const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
        const a = v.toString().match(reg)[0];
        const dot = a.indexOf(".");
        if (dot === -1) {
          // integer, insert decimal dot and pad up zeros
          return a + "." + "0".repeat(n);
        }
        const b = n - (a.length - dot) + 1;
        return b > 0 ? a + "0".repeat(b) : a;
      };
      navigator.geolocation.getCurrentPosition((position) => {
        const posStore = {
          latitud: getFixed(position.coords.latitude, 3),
          longitud: getFixed(position.coords.longitude, 3),
        };
        console.log("latitud de tienda: ", posStore.latitud);
        console.log("longitud de tienda: ", posStore.longitud);
        // Buscar la sucursal por coordenadas
        let sucBlinamic = 0;
        for (let i = 0; i < sucursales.length; i++) {
          // La long y lat puede variar +1 o -1
          if (
            (posStore.latitud === getFixed(sucursales[i].latitud, 3) ||
              posStore.latitud === getFixed(sucursales[i].latitud - 0.001, 3) ||
              posStore.latitud ===
                getFixed(sucursales[i].latitud + 0.001, 3)) &&
            (posStore.longitud === getFixed(sucursales[i].longitud, 3) ||
              posStore.longitud ===
                getFixed(sucursales[i].longitud - 0.001, 3) ||
              posStore.longitud === getFixed(sucursales[i].longitud + 0.001, 3))
          ) {
            sucBlinamic = sucursales[i].sucursalIDBlinamic;
            break;
          }
        }
        resolve(sucBlinamic);
      }, preguntar);
    });
  }

  askStore() {
    this.setState({ showSpinner: true });

    let iopt = "{ ";
    for (let i = 0; i < this.state.sucursales.length; i++) {
      const suc = '"' + this.state.sucursales[i].sucursalIDBlinamic + '" : ';
      const value =
        '"( Tienda ' +
        this.state.sucursales[i].nroTienda +
        ") " +
        this.state.sucursales[i].descripcion +
        '"';
      iopt += suc + value;
      if (i < this.state.sucursales.length - 1) {
        iopt += ",";
      }
    }

    iopt += " }";
    Swal.fire({
      title: "No pudimos detectar la ubicación de la sucursal.",
      input: "select",
      inputOptions: JSON.parse(iopt),
      inputPlaceholder: "Indique la sucursal",
      showCancelButton: false,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            resolve();
          } else {
            resolve("Debes indicar una sucursal :)");
          }
        });
      },
    }).then((data) => {
      this.setState({ showSpinner: false });
      this.setState({ localStore: parseInt(data.value) });
      this.DoLogin();
    });
  }

  async ClickLogin() {
    this.setState({ showSpinner: true });
    
    if (this.TextoIngresado === '') {
      this.LoginRefused();
      return;
    }
    
    var respuesta = await fetch(GetUrlApi() + '/api/Usuarios/' + this.TextoIngresado + '?pin=true', {
      method: 'get',
      headers: {
          'Content-Type': 'application/json',
      }
    }); 
    
    if (respuesta.ok){
      var datos = await respuesta.json();
      const nombre = datos.nombre + ' ' + datos.apellido;
      var usuarioSesion = UserProfile.getUser();
      usuarioSesion.id = datos.id;
      usuarioSesion.username = datos.nombre + " " + datos.apellido;
      usuarioSesion.tipoUsuario = datos.tipoUsuario;
      usuarioSesion.SucursalID = datos.sucursalID;
      usuarioSesion.CajaID = datos.cajaID;
      usuarioSesion.caja = datos.caja;
      usuarioSesion.Sucursal = datos.sucursal;
      usuarioSesion.IsSuperAdmin = datos.isSuperAdmin;
      usuarioSesion.Empresa = datos.empresa;
      usuarioSesion.EmpresaID = datos.empresaID;
     
      await fetch(GetUrlApi() + "/api/Sucursals", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((sucs) => sucs.json())
        .then((data) => {
          data = data.filter(p => p.empresaID ===usuarioSesion.EmpresaID ); 
          if (data.length === 0){
                Swal.fire({
                  title: "El usuario no tiene sucursales asociadas.",
                  icon: "error"
              });
              this.LoginRefused();
              return;
          }
          this.setState({ sucursales: data });
        
        });

        UserProfile.setUser(usuarioSesion);
        const newCookie = JSON.stringify(usuarioSesion);
        sessionStorage.setItem('userProfile', newCookie);
        this.AutenthicateJWT(usuarioSesion.id,usuarioSesion.username);
        this.BuscaSwitchPuntosOfertas();
        
        //Maisa
        if (this.state.Ambiente == 'MAISA'){
          this.DoAutoLogin();
          return;
        }
        
      }
      else{
        this.LoginRefused();
        return;
      }
           
        this.GetLocation(() => {
            this.askStore();
        }).then((localStore) => {
            if (localStore !== 0) {
                this.setState({ localStore });
                this.DoLogin();
            } else {
                this.askStore();
            }

        });
    }

    async DoLogin() {
        try {
          this.setState({ showSpinner: false });
          var usuarioSesion =UserProfile.getUser();
          if (usuarioSesion.Sucursal.sucursalIDBlinamic != this.state.localStore) {
            Swal.fire({
                title: "El usuario no pertenece a esta sucursal",
                icon: "error"
            });
            this.LoginRefused();
            return;
        }
          await this.GetParamConfiguracionPOS(usuarioSesion.Sucursal.id);
          const url = usuarioSesion.tipoUsuario.configTipoUsuario.find(p => p.sucursalID === usuarioSesion.Sucursal.id).urlInicio;
          this.props.history.push(url);
        }
         catch{
          this.LoginRefused();
         }
       
    }

    async DoAutoLogin(){
      try {
        
        this.setState({ showSpinner: false });
        var usuarioSesion = UserProfile.getUser();
        //si no existe el archivo de configuracion ingreso con sucursal y caja del usuario
        if (this.state.sucursalID==0){
          this.setState({sucursalID:usuarioSesion.SucursalID});          
        }        
        
        var cajas = await fetch(GetUrlApi() + "/api/Cajas/GetCajasPorSucursal/?sucursalID=" + this.state.sucursalID, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        
        if (cajas.ok) {
          var data = await cajas.json();
          // se comenta validacion por caja
          var tieneCaja = data.filter(d => d.sucursalID === usuarioSesion.SucursalID) // && d.cajaID === usuarioSesion.CajaID);

          //si no existe el archivo de configuracion le asigno el id caja externa del usuario
          if (this.state.cajaID == 0 && !this.state.validateArchive){
            this.setState({cajaID:tieneCaja[0].cajaExternaID});
          }
          if (this.state.validateArchive){
            /* if (this.state.cajaID != usuarioSesion.caja.cajaExternaID){
              Swal.fire({
                title: "El usuario no tiene acceso en esta Caja.",
                icon: "error"
              }).then(() => {
                this.LoginRefused();
              });     
              return;  
            } */
          }          
          //si la caja registrada en el archivo de configuración no existe en blinamic le asigno el id caja externa del usuario
          let existeCaja = data.filter(d => d.cajaExternaID === this.state.cajaID);
          if (existeCaja.length === 0 && !this.state.validateArchive){
            this.setState({cajaID:tieneCaja[0].cajaExternaID});
          }

          if (tieneCaja.length === 0){         
            Swal.fire({
              title: "El usuario no tiene acceso en esta Sucursal.",
              icon: "error"
            }).then(() => {
              this.LoginRefused();
                                  
            }); 
            return;             
          }else{
            
            var validaCaja = await fetch(GetUrlApi() + "/api/Cajas/ValidaCajaFolios/?tipoDocumento=B&sucursalID=" + this.state.sucursalID + "&cajaID=" + this.state.cajaID, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            });
            
            if (validaCaja.ok) {
              var datos = await validaCaja.json();
              
              if (datos.codError != 0) {
                Swal.fire({
                  title: datos.descError,
                  icon: "error"
                }).then(() => {
                  this.LoginRefused();
                  return;                       
                });
              }else {
                //Seteo el ID de caja del archivo de configuración (El ID sucursal se mantiene, ya que si entró en este punto es porque es la misma que tiene el usuario)
                if (parseInt(this.state.cajaID) != 0) {
                  var cajaInterno = data.filter(d => d.cajaExternaID === parseInt(this.state.cajaID));

                  usuarioSesion.CajaID = parseInt(cajaInterno[0].cajaID);
                  usuarioSesion.caja.id = parseInt(cajaInterno[0].cajaID);
                  usuarioSesion.CajaExternaID = parseInt(this.state.cajaID);
                  UserProfile.setUser(usuarioSesion);
                  const newCookie = JSON.stringify(usuarioSesion);
                  sessionStorage.setItem('userProfile', newCookie);
                }
                await this.GetParamConfiguracionPOS(usuarioSesion.Sucursal.id);
                const url = usuarioSesion.tipoUsuario.configTipoUsuario.find(p => p.sucursalID === usuarioSesion.Sucursal.id).urlInicio;
                this.props.history.push(url);
              }          
            }            
          }
        }        
      }
        catch{
        this.LoginRefused();
      }
    }
     
    async GetParamConfiguracionPOS (sucID){

  
      try{ 
          var respuesta = await fetch(GetUrlApi() + '/api/ConfigPOS/ParametrosConfiguracionPOS?SucID=' + sucID, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json'
              }
          }).then(res => res.json()).then(
              (result) => {
                  if(result.length>0){
                    localStorage.setItem('identificarClienteForzado', result[0].identificarClienteForzado);
                    localStorage.setItem('identificarClienteParaOfertas',  result[0].identificarClienteParaOfertas); 
                    localStorage.setItem('enviarComprobranteWhatsapp',  result[0].enviarComprobranteWhatsapp);
                    localStorage.setItem('altaClienteForzado',  result[0].altaClienteForzado);
                    localStorage.setItem('usaCustomCloudService',  result[0].usaCustomCloudService);
                    localStorage.setItem('arqueoModuloWhatsapp',  result[0].arqueoModuloWhatsapp);
                    localStorage.setItem('arqueoTipoMensajeWhatsapp',  result[0].arqueoTipoMensajeWhatsapp);
                    localStorage.setItem('arqueoNumeroCelularWhatsapp',  result[0].arqueoNumeroCelularWhatsapp); 
                    localStorage.setItem('envioArqueoWhatsapp',  result[0].envioArqueoWhatsapp);
                   // localStorage.setItem('ambiente',  result[0].ambiente);
                    localStorage.setItem('loginSeller',  result[0].loginSeller);
                    localStorage.setItem('servicioApiLocal',  result[0].servicioApiLocal);
                    localStorage.setItem('porcentMinimoSolicitudAutorizDesc',  result[0].porcentMinimoSolicitudAutorizDesc);
                    localStorage.setItem('identificarClienteGenericoEnModal',  result[0].identificarClienteGenericoEnModal);
                    localStorage.setItem('identificaryCargarClienteGenerico',  result[0].identificaryCargarClienteGenerico);
                    localStorage.setItem('descItem', result[0].descItem);
                    localStorage.setItem('descGlobal', result[0].descGlobal);
                    localStorage.setItem('itemXCantidad', result[0].itemXCantidad);
                    localStorage.setItem('validaEfectivoCaja', result[0].validaEfectivoCaja);
                    localStorage.setItem('maxDescItem', result[0].maxDescItem);
                    localStorage.setItem('maxDescGlobal', result[0].maxDescGlobal);
                    localStorage.setItem('diasUsoNC', result[0].diasUsoNC);
                    localStorage.setItem('diasMaxDevolucion',result[0].diasMaxDevolucion);
                    localStorage.setItem('machQrType', result[0].machQrType);
                    localStorage.setItem('usePoints',result[0].usePoints);
                  }
                  else{
                      Swal.fire({
                        title: "Problemas al obtener datos de configuración de la Sucursal",
                        icon: "error"
                    });
                    this.LoginRefused();
                  }
                  return;
              });
      }
     catch (e){
         console.log(e);
     }
    }
    async BuscaSwitchPuntosOfertas(){
      let tipo = 'SWPTOF';
      try{ 
          var respuesta = await fetch(GetUrlBlinamicApi() + '/api/PosAmbiente/RetornaDominio?Tipo=' + tipo, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json'
              }
          }).then(res => res.json()).then(
              (result) => {
                  if (result.length > 0){
                    let sw = result[0].descripcion;
                    sessionStorage.setItem('SwitchPuntosOfertas', sw);
                  }
                  else {
                    sessionStorage.setItem('SwitchPuntosOfertas', 'N');
                  }
                   
                  return;
              });
      }
     catch (e){
         sessionStorage.setItem('SwitchPuntosOfertas', 'N');
         console.log(e);
     }
  }
    async AutenthicateJWT (id, username){
 
            try{ 
                 
                    const response = await fetch(GetUrlBlinamicApi() + '/authenticate?Id=' + id + "&Username=" + username, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then((data) => { return data.json() });
                    //console.log(response);
                    if (response.token){
                        sessionStorage.setItem('jwtToken', response.token);
                     }
                     else {
                         sessionStorage.removeItem('jwtToken');
                     }
            }
           catch (e){
               console.log(e);
           }
        }
    
    LoginRefused() {
      this.setState({sucursalID:0, cajaID:0});
        if (document.getElementById('alertaPin') !== null) {
            document.getElementById('alertaPin').style.display = 'block';
            setTimeout(function () {
                if (document.getElementById('alertaPin') !== null)
                    document.getElementById('alertaPin').style.display = 'none';

            }, 3000);
            this.setState({ showSpinner: false });
            this.ClickClear();
        }
        if (this.state.Ambiente === 'MAISA') {
          this.ParametrosCaja();
          this.setState({isHidden: false})
        }    
    }

    async ParametrosCaja(){

      try {

        const url = this.state.ServicioApiLocal + 'Configuracion/GetConfigTienda';

        var result = await fetch(url, {
          method: 'get',
          //headers: {
            //'Content-Type': 'application/json',
          //}
        });
      
        if (result.ok) {
          
          var datos = await result.json();
          this.setState({ sucursalID: datos.LocalIdMonitor,  cajaID: datos.CajaIdMonitor, lineaTienda: datos.LineaTienda, validateArchive:true})

          var validaCaja = await fetch(GetUrlApi() + "/api/Cajas/ValidaCajaFolios/?tipoDocumento=B&sucursalID=" + datos.LocalIdMonitor + "&cajaID=" + datos.CajaIdMonitor, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          if (validaCaja.ok) {
            var datos = await validaCaja.json();
            
            if (datos.codError != 0) {
              Swal.fire({
                title: datos.descError,
                icon: "error"
              });
            }            
          }
        }else{
          Swal.fire({
            title: "El archivo de configuración no se encuentra o presentó problemas al intentar cargarlo.",
            icon: "error"
          });
        }
      }catch{
        this.setState({validateArchive:false});
        Swal.fire({
          title: "Existe un problema para obtener los datos de la caja.",
          icon: "error"
        });
      }      
    }

    render() {
        return (

            <div className="row d-flex justify-content-center" >
                {
                    (this.state.redirigirRevisar) ? (
                        <Redirect to="/revisar" />
                    ) : (null)
                }
                { this.state.showSpinner ? (<MySpinner></MySpinner>) : (null)}
                <Card className="shadow-lg col-12 col-sm-6 col-lg-4 mt-5">
                  <CardTitle className="mt-3 text-center" >
                    <div id='divDatosSucursal' className='row' hidden={this.state.isHidden}>
                      <div className='col-md-4'>
                        <h5>Sucursal: {this.state.sucursalID}</h5>
                      </div>  
                      <div className='col-md-4'>
                        <h5>Caja: {this.state.cajaID}</h5>
                      </div> 
                      <div className='col-md-4'>
                        <h5>Tienda: {this.state.lineaTienda}</h5>
                      </div>                    
                    </div>
                    <br></br>
                    <LogoLeanPos myClass="mx-auto d-block" />
                    <h5>Ingrese PIN - POS</h5>
                  </CardTitle>
                  <CardBody>
                    <center>
                      <Input
                        id="PINboxD"
                        type="tel"
                        value=""
                        bsSize="lg"
                        className="text-center"
                        style={this.Estilos.myInput}
                      />
                      <table id="tablaBotones">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan="3">
                              <img
                                height="100"
                                hidden="hidden"
                                width="300"
                                src={require("./Imagenes/logoFbhLeanRes.png")}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td hidden="hidden">
                              <center>
                                <span id="1" className="dot"></span>
                                <span id="2" className="dot"></span>
                                <span id="3" className="dot"></span>
                                <span id="4" className="dot"></span>
                                <span id="5" className="dot"></span>
                                <span id="6" className="dot"></span>
                              </center>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="1"
                                value="1"
                                id="1"
                              />
                            </td>
                            <td>
                              {" "}
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="2"
                                value="2"
                                id="2"
                              />
                            </td>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="3"
                                value="3"
                                id="3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="4"
                                value="4"
                                id="4"
                              />
                            </td>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="5"
                                value="5"
                                id="5"
                              />
                            </td>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="6"
                                value="6"
                                id="6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="7"
                                value="7"
                                id="7"
                              />
                            </td>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="8"
                                value="8"
                                id="8"
                              />
                            </td>
                            <td>
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="9"
                                value="9"
                                id="9"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                onClick={() => {
                                  this.ClickClear();
                                }}
                                type="button"
                                className="PINbutton clear"
                                name="-"
                                value="clear"
                                id="-"
                              />
                            </td>
                            <td>
                              {" "}
                              <input
                                onClick={(e) => {
                                  this.handleInput(e);
                                }}
                                type="button"
                                className="PINbutton"
                                name="0"
                                value="0"
                                id="0"
                              />
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  this.ClickLogin();
                                }}
                                className="PINbutton enter"
                                name="+"
                                id="+"
                              >
                                <span
                                  style={this.Estilos.estiloSpan}
                                  className="glyphicon glyphicon-ok"
                                ></span>
                                Enter
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        id="alertaPin"
                        style={this.Estilos.AlertaPinInvalido}
                        className="alert alert-danger"
                        role="alert"
                      >
                        PIN Inv&aacute;lido
                      </div>
                    </center>
                  </CardBody>
                </Card>
              </div>
      );
  }
}
