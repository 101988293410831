import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado, Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import swal from 'sweetalert';

import Popup from "reactjs-popup";
export class MantenedorMotivosNotaCredito extends Component {
    static displayName = MantenedorMotivosNotaCredito.name;

    StateInicial = {
        usuarios: [],

        activarAgregarMotivo: false,
        activarEditarMotivo: false,
        MotivosDevolucion: [],
        motivoSeleccionado: {}

        
    }
    constructor(props) {
        super(props);
        this.state = this.StateInicial;
        this.TraerMotivos();

    }

    //ObtenerMotivos

    async TraerMotivos() {
        var respuesta = await fetch(GetUrlApi() + '/api/MotivosDevolucions/ObtenerMotivos', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            console.log(datos);
            this.setState({ MotivosDevolucion: datos });
        }
    }

    Estilos = {
        ButtonStyleDerecha:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#f2f2f2',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%',
            'float': 'rigth'
        },
        ButtonStyleS:
        {
            'borderRadius': '10px',
            'height': '48px',
            'width': '55px',
            'border-color': 'cadetblue',
            'backgroundColor': '#28a745',
            'margin-top': '0.5%',
            'margin-right': '1%',
            'margin-left': '2%'
        },
        BtnAddUser:
        {
            'margin-bottom': '1%',
            'margin-left': '3%'
        },
        BtnOpt:
        {
            'marginRight': '10px',
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        BtnOpt1:
        {
            'border-radius': '5px',
            'background': '#7fc1a7',
            'color': 'white'
        },
        TableStyle:
        {
            'margin-left': '3%'
        },
        Space:
        {
            'padding-right': '5px'
        },
        StylePopUp:
        {
            'position': 'relative',
            'background': 'rgb(255, 255, 255)',
            'width': '50%',
            'margin': 'auto',
            'border': '1px solid rgb(187, 187, 187)',
            'padding': '5px',
            'border-radius': '7px'
        }
    }


    async CrearMotivo() {
        var motivo = document.getElementById('txtMotivo').value;
        if (motivo!='') {

            var respuesta = await fetch(GetUrlApi() + '/api/MotivosDevolucions/CrearMotivo?motivo='+motivo, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (respuesta.ok) {
                swal('Motivo creado con exito').then(() => {
                    this.Recargar();
                });
            }

        }
       
    }

    async ClickListoEditar() {
        var motivo = this.state.motivoSeleccionado;
        motivo.motivo = document.getElementById('txtMotivo').value;
        var respuesta = await fetch(GetUrlApi() + '/api/MotivosDevolucions/UpdateMotivo?id=' + motivo.id+"&motivo="+motivo.motivo, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            swal('Motivo editado con exito').then(() => {
                this.Recargar();
            });
        }
    }
        async ClickListoEliminar() {
        
    }


    async ClickInhabilitarMotivo(idMotivo) {
        swal({
            title: "¿Está seguro que desea dehabilitar este motivo?",
            text: "Si confirma el motivo no podra ser utilizado",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Motivo deshabilitado", {
                    icon: "success",
                }).then(async () => {
                    var respuesta = await fetch(GetUrlApi() + '/api/MotivosDevolucions/DeleteMotivo?id=' + idMotivo, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (respuesta.ok) {
                        this.Recargar();
                    }
                });
            }
        });
    }

    //------->inicio onclick
    ClickAgregarMotivo() {
        this.setState({ activarAgregarMotivo: true });
    }

    ClickEditarMotivo(Motivo) {
        this.setState({ motivoSeleccionado: Motivo, activarEditarMotivo: true });
    }
    Recargar() {
        this.setState(this.StateInicial);
        this.TraerMotivos();
    }


    GetHtmlPopupCrearMotivo() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.setState({ activarAgregarMotivo: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Crear Motivo</h3>
                    <table>
                        <tbody>
                           
                            <tr>
                                <td>Motivo</td>
                                <td>
                                    <input className="form-control" id="txtMotivo" type="text" />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <button onClick={() => { this.CrearMotivo() }} data-toggle="tooltip" data-placement="right" title="Listo" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    GetHtmlPopupEditarMotivo() {

        return (
            <Popup
                contentStyle={this.Estilos.StylePopUp}
                defaultOpen
                modal
                //contentStyle={}
                onClose={() => { this.setState({ activarEditarUsuario: false }) }}
                closeOnDocumentClick>
                <center>
                    <h3>Editar Motivo</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Motivo</td>
                                <td>
                                    <input className="form-control" id="txtMotivo" type="text" defaultValue={this.state.motivoSeleccionado.motivo} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={() => { this.ClickListoEditar() }} data-toggle="tooltip" data-placement="right" title="Agregar" style={this.Estilos.ButtonStyleS}>
                        <i class="fa fa-check-square-o fa-2x text-white" aria-hidden="true"></i></button>
                </center>

            </Popup>
        );
    }

    render() {
        return (
            <div  >
                {
                    (this.state.activarAgregarMotivo) ? (this.GetHtmlPopupCrearMotivo()) : (null)
                }
                {
                    (this.state.activarEditarMotivo) ? (this.GetHtmlPopupEditarMotivo()) : (null)
                }
                <div className="alert alert-info" role="alert">
                    <strong> Motivos de Devolución Notas de Crédito</strong>

                </div>

                <button
                    onClick={() => {this.ClickAgregarMotivo()   }}
                    type="button" className="btn btn-info" style={this.Estilos.BtnAddUser}>
                    <i style={this.Estilos.Space} class="fa fa-plus-circle" aria-hidden="true"></i>
                         Agregar motivo
                        </button>


                <table className="table" style={this.Estilos.TableStyle}>
                    <thead>
                        <tr>
                            <td><b>ID</b></td>
                            <td><b>Descripcion</b></td>
                            <td><b>Acciones</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.MotivosDevolucion.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>{item.motivo}</td>
                                        <td>
                                            <button style={this.Estilos.BtnOpt} onClick={() => { this.ClickEditarMotivo(item) }}  data-toggle="tooltip" data-placement="right" title="Editar" >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                            <button style={this.Estilos.BtnOpt1} onClick={() => { this.ClickInhabilitarMotivo(item.id)}}  data-toggle="tooltip" data-placement="right" title="Eliminar" >
                                                <i class="fa fa-trash " aria-hidden="true"></i></button>

                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>

            </div>
        );
    }




}