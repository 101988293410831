import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { GetUrlApi } from './Globales/VariableGlobales';
import { GetPrecioFormateado } from './Globales/Utilidades';
import { Pinpad } from './Pinpad';
import { ArqueoDetalleMedio } from './ArqueoDetalleMedio';
import swal from 'sweetalert';
import UserProfile from './Globales/PerfilUsuario';
import { Logout } from './Globales/Utilidades';
import { GetUserCookie } from './Globales/Utilidades';
import CurrencyFormat from "react-currency-format";
import Swal from 'sweetalert2';

export class ArqueoCiego extends Component {
    static displayName = ArqueoCiego.name;
    IdGaveta = 0;
    IDcboCkicked = 0;
    LastOptionClicked = 0;
    MediosPorCierre = [];
    TipoUsuario = null;
    NumCaja = GetUserCookie().CajaID;
    CargaInicialTerminada = false;
    GavetaDesc = "";
    PerfilUsuario = {};
    UltMedioClicked = '';
    ultimaAperturaCaja = {};
    constructor(props) {
        super(props);
        this.state = {
            gavetas: [],
            numCaja: 1,
            numGavetas: 0,
            efectivo: 0,
            cargandoEnvio: false,
            cargandoTransacciones: true,
            volverAlIncio: false,
            mediosPago: [],
            total: 0,
            diferencia: 0,//la diferencia,
            montoInicial: 0,
            cargandoCierre: false,
            mediosDePago: [],
            cargandoMedios: false,
            MediosVentas: [],
            redirijirEditar: false,
            preprocesar: false,
            IdGaveta: 0,
            isArqueoCiego: false,
            isCajaHabilitada: false,
            idMedioSeleccionado: 0,
            mostrarDetallesMedio: false,
            ordenes: []
        }
        this.ultimaAperturaCaja = props.ultimaAperturaCaja;
        this.AgregarNumero = this.AgregarNumero.bind(this);
        this.ClickBorrarTeclado = this.ClickBorrarTeclado.bind(this);
        this.EfectivoAgregado = this.EfectivoAgregado.bind(this);
        this.PerfilUsuario = UserProfile.getUser;



    }

    componentDidMount() {

        //if (this.PerfilUsuario.tipoUsuario !== 1) {
        //    this.setState({ mostrarMensajeNoHabilitado: true });
        //}
        this.TraerMediosDePago();
        this.TraerOrdenes();
        this.isGavetaSeleccionadaDisponible();
    }

    async isGavetaSeleccionadaDisponible() {
        var respuesta = await fetch(GetUrlApi() + '/api/AperturaCajas/GetAperturaCajaPendiente?idCaja=' + GetUserCookie().CajaID + '&idGaveta=' + this.props.GavetaID, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            console.log(await respuesta.json());
        }
    }
    async abrirGaveta() {
        const userData = GetUserCookie();
        const empresa = userData.Sucursal.direccionDeli.split(".")[0];
        const sucursal = userData.Sucursal.direccionDeli.split(".")[1];
        const printer = "printer_" + userData.caja.descripcion;
        const param = "print://abrirGaveta";
        var myBody = {
            "company_id": empresa,
            "store_id": sucursal,
            "order_id": param,
            "printer_id": printer,
            "additional_data": "",
            "exec_path": "C:\\fbhposprint\\"
        };

        const response = await fetch('http://cloudprintleanpos.blinamic.cl:10000/cloudprinter/print', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(myBody),
        }).then((data) => { return data.json() });
        console.log(response);
        if (!response) {
            await Swal.fire({
                title: "Error al querer abrir gaveta",
                icon: "error",
                text: "Por favor contacte al administrador del sistema ",
                dangerMode: true,
            });
            return;
        }
    }

    async  TraerOrdenes() {
        this.setState({ cargando: true });
        console.log(this.props.ultimaAperturaCaja);
        this.GavetaDesc = this.props.ultimaAperturaCaja.gaveta.descripcion;
        var url = GetUrlApi() + '/api/orden/GetOrdenCerrar?idGaveta=' + this.ultimaAperturaCaja.gavetaID;
        var respuestaOrdenes = await fetch(url);

        if (respuestaOrdenes.ok) {
            var datosOrdenes = await respuestaOrdenes.json();
            var mediosPorOrdens = [];
            datosOrdenes.forEach((item) => {
                item.mediosPorOrdens.forEach((value) => {
                    mediosPorOrdens.push(value);
                });


            });

            mediosPorOrdens.push({ datosOrdenes: 8, total: this.ultimaAperturaCaja.monto, CierreCajaID: null });
            
           await  this.setState({
                ordenes: datosOrdenes
                , isCajaHabilitada: !this.ultimaAperturaCaja.isCerrada
                , cargando: false
                , MediosVentas: mediosPorOrdens
                , IdGaveta: this.ultimaAperturaCaja.gavetaID
            });
            if (!this.state.isCajaHabilitada)
                swal('La gaveta seleccionada no está abierta').then((item) => {
                    Logout();
                    this.setState({ volverAlIncio:true }) ;
                });
            try {
                document.getElementById('btnTerminar').disabled = false;
                document.getElementById('btnTerminar').innerText = 'Terminar';
            } catch (err) {

            }
        }


        
        else {
           // alert('error en traer ordenes');
        }
    }

    async TraerMediosOrden() {
        var traerPendientes = false;
        var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {

            var datos = await respuesta.json();
            datos = datos.filter(p => p.idGavetaFk === this.state.IdGaveta && p.idCierreCajaFk === null);

            datos.push({ idMedioPagoFk: 1, total: this.state.montoInicial, idCierreCajaFk: null });

            this.setState({ MediosVentas: datos, cargandoTransacciones: false });
        }
        else {
            alert('error');
        }
    }

    async TraerMediosOrdenPendientes() {
        var traerPendientes = false;
        var respuesta = await fetch(GetUrlApi() + '/api/MediosPorOrden', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {

            var datos = await respuesta.json();
            datos = datos.filter(p => p.idGavetaFk === this.state.IdGaveta && p.isArqueoPendiente === true);

            datos.push({ idMedioPagoFk: 1, total: this.state.montoInicial, idCierreCajaFk: null });

            this.setState({ MediosVentas: datos, cargandoTransacciones: false, isCajaHabilitada: true });
        }
        else {
            alert('error');
        }
    }



    async TraerCaja() {
        var respuesta = await fetch(GetUrlApi() + '/api/Cajas/' + this.NumCaja, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (respuesta.ok) {
            var datos = await respuesta.json();
            this.setState({ IdGaveta: datos.idUltimaGavetaFk, isCajaHabilitada: datos.isHabilitada });
            this.IdGaveta = datos.idUltimaGavetaFk;
            this.CargaInicialTerminada = true;
        }
        else {
            alert('error');
        }
    }

    ActualizarMontoAcumulado() {
        var elemContar = document.getElementsByName('acumulado');
        var totalAcumulado = 0;
        elemContar.forEach((item) => {
            try {
                if (item.value)
                    totalAcumulado += Number(item.value);
            } catch (err) {

            }
        });
        this.setState({ total: totalAcumulado });
    }

    async TraerMediosDePago() {
        try {
            var categorias = await fetch(GetUrlApi() + '/api/TbMedio');
            if (!categorias.ok) {
                throw 'problema al traer los medios de pago';
            }
            var datos = await categorias.json();

            this.setState({ mediosDePago: datos, cargandoMedios: false });
        } catch (err) {
            console.log(err);
        }
    }

    async TraerVentas() {

        try {
            const ventas = await fetch(GetUrlApi() + '/api/Orden?basico=true');
            if (!ventas.ok)
                throw { mensaje: 'problema al traer las ordenes' };

            var datos = await ventas.json();
            datos = datos.filter(datos => datos.idCierreCajaFk === null && datos.idGavetaFk === this.state.IdGaveta && datos.pagado === true);
            var cont = 0;
            datos.forEach(function (item) {
                cont += item.total;
            });
            await this.setState({ diferencia: cont });

        } catch (err) {
            console.log(err.mensaje);
        }
    }

    //async TraerUltAperturaCaja() {
    //    try {
    //        const aperturas = await fetch(GetUrlApi() + '/api/AperturaCajas');
    //        if (!aperturas.ok)
    //            throw { mensaje: 'problema al traer las aperturas de caja' };

    //        var datos = await aperturas.json();
    //        datos = datos.filter(datos => datos.numCaja === this.state.numCaja && datos.numGaveta === this.state.IdGaveta);
    //        var cont = 0;//contiene el id mas alto
    //        datos.forEach(function (item) {
    //            if (item.id > cont) {
    //                cont = item.id;
    //            }
    //        });
    //        datos = datos.filter(datos => datos.id === cont);
    //        var contTotal = datos[0].monto;
    //        contTotal += this.state.diferencia;
    //        await this.setState({ diferencia: contTotal, montoInicial: datos[0].monto });
    //    } catch (err) {
    //        console.log(err.mensaje);
    //    }
    //}

    async TraerGavetas() {
        try {
            const categorias = await fetch(GetUrlApi() + '/api/Gavetas/');
            if (!categorias.ok)
                throw { mensaje: 'problema al traer las gavetas' };

            var datos = await categorias.json();
            var datos = datos.filter(p => p.isArqueoPendiente === true);
            await this.setState({ gavetas: datos });
        } catch (err) {
            console.log(err.mensaje);
        }
    }

    async TraerMediosPago() {
        try {
            const medios = await fetch(GetUrlApi() + '/api/tbmedios');
            if (!medios.ok)
                throw { mensaje: 'problema al traer los medios de pagos' };

            var datos = await medios.json();
            await this.setState({ mediosPago: datos[0] });
        } catch (err) {
            console.log(err.mensaje);
        }
    }

    CerrarPopup() {
        try {
            var modales = document.getElementsByclassName("popup-content ");
            if (modales.length > 0)
                modales[modales.length - 1].style.display = "none";
        } catch (err) {
            console.log('ex en cierre de modal');
        }
    }

    AgregarNumero(num, idElem) {
        document.getElementById(idElem).value = document.getElementById(idElem).value + num;
        this.ActualizarMontoAcumulado();
    }

    ClickBorrarTeclado(idElem) {
        try {
            var dato = document.getElementById(idElem).value;
            document.getElementById(idElem).value = dato.slice(0, -1);
            this.ActualizarMontoAcumulado();
        } catch (err) {

        }
    }
    //-------CLICKS

    ClickInputMedioPago(nombreMedio) {
        this.Efectivo = Number(document.getElementById(nombreMedio).value);
        document.getElementById(nombreMedio).value = '';
    }




    async ClickTerminar() {
        const gavetaSeleccionada = this.ultimaAperturaCaja.gavetaID;
        await this.setState({ cargandoCierre: true });
        document.getElementById('btnTerminar').disabled = true;
        const comentario = document.getElementById('txtComentario').value;
        this.setState({ cargandoEnvio: true });
        var data = {};
       
        data.Reembolsos = 0;
        data.CatTotal = this.state.total;
        data.CajaID = Number(GetUserCookie().CajaID); 
        data.GavetaID = this.ultimaAperturaCaja.gavetaID;
        data.AperturaCajaID = this.ultimaAperturaCaja.id;
        data.Comentario = comentario;
        data.diferencia = this.state.diferencia - this.state.total;
        data.TipoArqueoID = 2;
        data.montoInicial = this.state.montoInicial;
        var mediosCierre = document.getElementsByName('acumulado');

        mediosCierre.forEach((item, index) => {
            // console.log(item.getAttribute('idmonto'));
            var monto = item.value;
            monto = monto.replace("$", "");
            monto = monto.replaceAll(",", "");
            if (monto.length === 0) {
                monto = 0;
            }
            var newObj = {};
            newObj.id = 0;
            newObj.TbMedioID = Number(item.getAttribute('idmedio'));
            newObj.monto = Number(monto);
            newObj.CierreCajaID = 0;
            newObj.descMedio = item.id;
            this.MediosPorCierre.push(newObj);
        });
        data.MediosPorCierre = this.MediosPorCierre;
        console.log(data.MediosPorCierre);
        var respuesta = await fetch(GetUrlApi() + '/api/CierreCajas', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (respuesta.ok) {
            document.getElementById('btnTerminar').disabled = false;
            const data = await respuesta.json();
            //const idImprimir = data.id;
            //const url = "print://arqueo" + idImprimir + "/";
            Logout();
            //window.open(url, '_blank');
            this.setState({ cargandoEnvio: false, volverAlIncio: true });
        }
        else {
            document.getElementById('btnTerminar').disabled = false;
            alert('error');
        }
    }

    MontoAgregado(nombreElem) {
        var total = this.state.total;
        var efectivoAgregado = document.getElementById(nombreElem).value;
        efectivoAgregado = efectivoAgregado.replace("$", "");
        efectivoAgregado = efectivoAgregado.replaceAll(",", "");
        total += Number(efectivoAgregado);
        this.setState({ total: (total) });
        this.Efectivo = 0;
    }

    MontoAgregadoDos(value) {
        var total = this.state.total;
        var efectivoAgregado = value;
        total += Number(efectivoAgregado);
        this.setState({ total: (total) });
        this.Efectivo = 0;
    }

    EfectivoAgregado() {
        var total = this.state.total;
        var efectivoAgregado = document.getElementById('txtEfectivo').value;
        total += Number(efectivoAgregado);
        this.setState({ total: (total - this.Efectivo) });
        this.Efectivo = 0;
    }


    ClickCbo(id) {
        this.IDcboCkicked = id;
    }


    ClickEditar() {
        this.setState({ redirijirEditar: true });
    }

    ClickPreprocesar() {
        if (!this.state.preprocesar)
            this.setState({ preprocesar: true });
        else
            this.setState({ preprocesar: false });
    }




    //-------FIN CLICKS


    //--------ESTILOS
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        TercioAncho: {
            'width': '33%'
        },
        DosTercioAncho: {
            'width': '66%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Columnas: {
            col1: { 'width': '20%' },
            col2: { 'width': '50%' }
        },
        EstiloPopup: {

        },
        Contenedor: {
            'width': '80%',
            'background-color': 'white',
            'border-radius': '25px',
            'border': '2px solid #73AD21',
            'padding': '25px',
            'marginLeft': '12%'
        },
        BarraLateral: {
            'background-color': 'white',
            'height ': '100%',
            'width': '100%'
        },
        BotonAbrirGaveta: {
            'background-color': '#97c1b0',
            'color': 'white',
            'width': '12%',
            'height': '60px'
        },
        LetraRoja: {
            'color': 'red'
        },
        LetraVerde: {
            'color': 'green'
        }
    }

    ClickOption(newval) {
        this.LastOptionClicked = newval;
    }

    async ClickTdMedioPago(id, descMedio) {
        await this.setState({ mostrarDetallesMedio: false });
        this.UltMedioClicked = descMedio;
        this.setState({ idMedioSeleccionado: id, mostrarDetallesMedio: true });
    }

    

    

    GetTotal() {
        var inputs = document.getElementsByName('acumulado');
        var totalRes = 0;
        inputs.forEach((item) => {
            if (item.value) { 
            var efectivoAgregado = item.value;
            efectivoAgregado = efectivoAgregado.replace("$", "");
            efectivoAgregado = efectivoAgregado.replaceAll(",", "");
            totalRes += Number(efectivoAgregado);
        }
        });
        return totalRes;
    }

    GetNombre() {
        try {
            return 'Usuario: ' + this.ultimaAperturaCaja.usuarios.nombre;
        }
        catch (err) {
            return '';
        }
        return '';
    }

    GetDescGaveta() {
        try {
            return this.ultimaAperturaCaja.gaveta.descripcion;
        }
        catch (err) {
            return 'La gaveta está cerrada';
        }
        return 'La gaveta está cerrada';
    }


    AccionCajaCerrada() {
        //swal('No hay gaveta abierta');
        return (<p>La gaveta no esta abierta</p>);
    }

    render() {
        return (
            <div style={this.Estilos.Contenedor} >

                {
                    (this.state.volverAlIncio) ? (<Redirect to='/' />) : (null)
                }
                {
                    (this.state.redirijirEditar) ? (<Redirect to={this.DireccionEditar()} />) : (null)
                }
                <h1>Cierre Ciego</h1>
                <p>{this.GetNombre()}</p>
                <p> <strong> {this.GetDescGaveta()}</strong> </p>
                <p> <button className="btn" style={this.Estilos.BotonAbrirGaveta} onClick={() => {this.abrirGaveta()}}><span>Abrir gaveta</span></button></p>
                
                {
                    <div className="row">
                        <div style={this.Estilos.FullAncho}>
                            {
                                (this.state.isCajaHabilitada) ? (
                                    <div>
                                        <div>
                                            <table className="table">
                                                <thead></thead>
                                                <tbody>
                                                    {
                                                        this.state.mediosDePago.map((item, i) => {
                                                            var total = 0;
                                                            var valorIngresado = document.getElementById(item.desmed);
                                                            var mediosSeleccionados = this.state.MediosVentas.filter(p => p.idMedioPagoFk === item.codmed);
                                                            if (mediosSeleccionados.length > 0) {
                                                                mediosSeleccionados.forEach((value) => {
                                                                    total += value.total;
                                                                });

                                                            }
                                                            if (valorIngresado) {
                                                                valorIngresado = Number(valorIngresado.value);
                                                            }
                                                            else {
                                                                valorIngresado = 0;
                                                            }

                                                            return (
                                                                <tr key={'medioDePago' + i} onClick={() => { this.ClickTdMedioPago(item.codmed, item.desmed) }}>
                                                                    <td>
                                                                        <p>{item.desmed}</p>
                                                                    </td>
                                                                    <td>
                 

                                                                        <CurrencyFormat
                                                                            name="acumulado"
                                                                            id={item.desmed}
                                                                            placeholder="$0"
                                                                            className="form-control"
                                                                            thousandSeparator={true}
                                                                            prefix={"$"}
                                                                            decimalScale={0}
                                                                            fixedDecimalScale={true}
                                                                            onClick={() => { this.ClickInputMedioPago(item.desmed) }}
                                                                            onChange={() => { this.MontoAgregado(item.desmed) }}
                                                                            idmedio={item.id}
                                                                            //value={this.state.total}
                                                                           
                                                                        />
                                                                       
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            (this.state.preprocesar && (total - valorIngresado) === 0) ? (<p style={this.Estilos.LetraVerde}> {GetPrecioFormateado(total - valorIngresado)}</p>) : (null)
                                                                        }

                                                                        {
                                                                            (this.state.preprocesar && (total - valorIngresado) !== 0) ? (<p style={this.Estilos.LetraRoja}> {GetPrecioFormateado(total - valorIngresado)}</p>) : (null)
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    <tr>
                                                        <td>
                                                            <strong> <p>Total recaudado: </p> </strong>
                                                        </td>
                                                        <td>
                                                            <strong>{GetPrecioFormateado(this.GetTotal())}</strong>
                                                        </td>
                                                    </tr>
                                                    

                                                </tbody>
                                            </table>

                                            <p>Comentario</p>
                                            <textarea id="txtComentario" className="form-control" />
                                            <br />
                                            <button /*disabled={true}*/ id="btnTerminar" onClick={() => { this.ClickTerminar() }} style={this.Estilos.FullAncho} className="btn btn-success">Cargando datos</button>


                                        </div>
                                    </div>
                                ) : (
                                        <p>{this.AccionCajaCerrada()}</p> 
                                    )
                            }

                            {
                                (this.state.cargandoCierre) ? (<p>Cargando...</p>) : (null)
                            }

                        </div>
                        

                    </div>


                }
            </div>
        );
    }
}
