import React, { Component } from 'react';
import Popup from "reactjs-popup";

export class Pinpad extends Component {
    static displayName = Pinpad.name;
    NombreElemReferencia = '';//ID del elemento html al cual este teclado modifica

    constructor(props) {
        super(props);
        if (this.props.AgregarNumero && this.props.borrar) {
            this.NombreElemReferencia = this.props.nombre;
            this.Agregar = this.props.AgregarNumero;
            this.Borrar = this.props.borrar;
            this.Listo = this.props.listo;
        }
    }

    componentDidMount() {
        this.Borrar(this.NombreElemReferencia);
    }

    //--------------INICIO FUNCIONES HEREDADAS PROPS
    Agregar() {
        console.log('agregar no sobreescrito');
    }

    Borrar() {

    }

    Listo() {

    }
    //--------------FIN FUNCIONES HEREDADAS PROPS

    
    
    


    //--------ESTILOS
    Estilos = {
        FullAncho: {
            'width': '100%'
        },
        MitadAncho: {
            'width': '50%'
        },
        FullScreen: {
            'height ': '100%',
            'width': '100%'
        },
        Tabla: {
            'height ': '100%',
            'width': '100%',
            'BackgroundColor':'black'
        },
        EstiloPopup: {

        }
    }
    //--------FIN ESTILOS


    render() {
        return (<Popup trigger={this.props.children} >
            <table style={this.Estilos.FullAncho}>
                <tbody>
                    <tr>
                        <td> <button onClick={() => { this.Agregar(1, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 1 </button> </td>
                        <td><button onClick={() => { this.Agregar(2, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 2 </button></td>
                        <td><button onClick={() => { this.Agregar(3, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 3 </button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => { this.Agregar(4, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 4 </button></td>
                        <td><button onClick={() => { this.Agregar(5, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 5 </button></td>
                        <td><button onClick={() => { this.Agregar(6, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 6 </button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => { this.Agregar(7, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 7 </button></td>
                        <td><button onClick={() => { this.Agregar(8, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 8 </button></td>
                        <td><button onClick={() => { this.Agregar(9, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 9 </button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => { this.Borrar(this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> x </button></td>
                        <td><button onClick={() => { this.Agregar(0, this.NombreElemReferencia) }} style={this.Estilos.FullScreen}> 0 </button></td>
                        <td>  </td>
                    </tr>
                </tbody>
            </table>
        </Popup>);
    }
}
